import React, { useState } from 'react';
import { Box, Flex, Button, Avatar, Text } from '@chakra-ui/react';
import {Menu, MenuButton, MenuList,MenuItem,} from '@chakra-ui/react'
import ServiceSidebar from '../ServiceSidebar'
import { dashboardBackgroundColor } from '../../../Utils/UniversalColor';
import { Link } from 'react-router-dom';
import { storage } from '../../../dependencies/store/storage';

const SidebarServices = ({headingText,children}) => {
    const [sidebarWidth, setSidebarWidth] = useState(14);

    const handleSidebarWidthChange = (newWidth) => {
      setSidebarWidth(newWidth);
    };
  
    const handleLogout=()=>{
      storage.clear()
      window.location.href='/'
    }


return (
    <div style={{ display: 'flex' }}>
    <ServiceSidebar onWidthChange={handleSidebarWidthChange}/>
    <Box
      flex="1"
      // height="100vh"
      minHeight={'100vh'}
      marginLeft={`${sidebarWidth}rem`}
      transition="margin-left 0.3s ease" background={dashboardBackgroundColor}
    >
      <Flex boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} alignItems={'center'} justifyContent={'space-between'} padding={'10px'} m={'15px'} borderRadius={'10px'}>
        <Text fontSize={'14px'} pl={'15px'} fontFamily={'poppins'}>{headingText}</Text>
        <Menu>
          <MenuButton>
            <Avatar size={'xs'} name='Oshigaki Kisame' src='https://bit.ly/broken-link' />
          </MenuButton>
          <MenuList>
            <Link to='/admin/admin-profile'>
            <MenuItem>Profile</MenuItem>
            </Link>
            <MenuItem>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      {children}
    </Box>
  </div>
  )
}

export default SidebarServices
