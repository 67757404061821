import { ADMIN_RENEWAL_POLICES_LIST } from "../../../../../constants/ApplicationUrl"
import Api from "../../../../../dependencies/utils/Api"


export const AdminRenewalPolicyService = {

    adminRenewalListAll() {
        return Api.GET(ADMIN_RENEWAL_POLICES_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    }
}