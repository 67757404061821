import React from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const AddProduct = () => {
    const navigate=useNavigate()

    const handleBack = () => {
        navigate(-1);
      }

return (
    <Sidebar headingText={'Add Product'}>
        <Box w={'70%'} m={'auto'} mt={'70px'} background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
        <Flex mt={'30px'} justifyContent={'space-around'}>
                <Box>
                    <Text>Product Name</Text>
                    <Input border={'none'} borderBottom={'1px solid grey'} />
                </Box>      
                <Box>
                    <Text>Product Description</Text>
                    <Input border={'none'} borderBottom={'1px solid grey'} />
                </Box>
            </Flex>
            <Flex justifyContent={'center'} w={'70%'} m={'auto'} alignItems={'center'} gap={'70px'} mt={'50px'}>
                <Image w={'150px'} src='https://travelmedicare.com/public/Product/1567074373travel.png'/>            
                <Input style={{border:'none'}} type='file'/>
            </Flex>
          
            <Flex justifyContent={'center'} mt={'50px'}>
                <Button background={'#9c27b0'} color='white'>Add</Button>
            </Flex>
        </Box>
    </Sidebar>
  )
}

export default AddProduct
