import React from 'react'
import SidebarServices from '../SidebarServices'
import { Box, Button, Flex, Image, Input, Text,Modal, ModalOverlay,ModalContent,ModalHeader,ModalFooter,    ModalBody,ModalCloseButton, useDisclosure,} from '@chakra-ui/react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../Utils/UniversalColor'
import { useNavigate } from 'react-router-dom'
import Theme1 from '../../../assets/img/Theme1.png'


const ServiceThemes = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const navigate=useNavigate()


 const handleNavigate=(theme)=>{
  console.log(theme,'hit')
  navigate(`/service/add-theme/${theme}`)
}


const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};


return (
    <SidebarServices headingText={'Themes'}>
        <Box w={'90%'} m={'auto'} mt={'50px'}>
            <Flex justifyContent={'space-between'} mb={'25px'}>
            <Button size={'sm'} background={buttonBackgroundColor} _hover={{color:buttonHoverColor,background:buttonHoverBackground}} onClick={onOpen} color={buttonColor}>Request </Button>
            <Input background={'white'} w={'220'} placeholder='Search Themes' />
            </Flex>
            <Flex gap={'30px'} fontFamily={'Montserrat, Sans-serif'} fontSize={'14px'}>
                <Box w={'25%'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} textAlign={'center'} p={'15px'}>
                  <Box onClick={()=>handleNavigate('theme1')} cursor={'pointer'}>
                    <Image src={Theme1}/>
                    <Text mt={'15px'}>Theme 1</Text>
                    </Box>
                    <Text color='blue'>Preview</Text>
                </Box>
                <Box w={'25%'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} textAlign={'center'} p={'15px'}>
                    <Image src='https://pixydrops.com/insur-html/assets/images/landing-page/home-1-7.png'/>
                    <Text mt={'15px'}>Theme 2</Text>
                    <Text color='blue'>Preview</Text>
                </Box>
                <Box w={'25%'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} textAlign={'center'} p={'15px'}>
                    <Image src='https://pixydrops.com/insur-html/assets/images/landing-page/home-1-8.png'/>
                    <Text mt={'15px'}>Theme 3</Text>
                    <Text color='blue'>Preview</Text>
                </Box>
                <Box w={'25%'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} textAlign={'center'} p={'15px'}>
                    <Image src='https://pixydrops.com/insur-html/assets/images/landing-page/home-1-3.png'/>
                    <Text mt={'15px'}>Theme 4</Text>
                    <Text color='blue'>Preview</Text>
                </Box>
            </Flex>
        </Box>

        <Box>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontFamily={'poppins'}>Customized theme details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Ravi Sharma</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant='ghost'>Secondary Action</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
        </Box>
    </SidebarServices>
  )
}

export default ServiceThemes


