import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Flex, Text, Button, Tooltip } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { agaMgaAll } from '../MgaAgaAdvisor/dependencies/action';
import CustomInput from '../../../Utils/Input/Index';

const AdminAllAdvisors = () => {
  const dispatch = useDispatch();
  const [agaDataAll, setAgaDataAll] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(agaMgaAll())
      .then(res => {
        const { status, data } = res;
        setAgaDataAll(res);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredAgaData = agaDataAll.filter(data =>
    (data.firstName ? data.firstName.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.lastName ? data.lastName.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.email ? data.email.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.phone ? data.phone.toLowerCase() : '').includes(searchQuery.toLowerCase())
  );

  const handleViewAdvisor = (id) => {
    navigate(`/admin/advisor-dashboard/${id}`);
  };

  return (
    <Sidebar headingText={'All Advisors'}>
      <Box>
        <Box w={'90%'} m={'auto'} mt={'70px'}>
          <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
            <Box w={"200px"} mb={'25px'}>
              <CustomInput onChange={handleSearch} />
            </Box>
            <Box>
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '15%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                  <tr style={{ borderBottom: '1px solid #dddddd' }}>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Name</th>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Role</th>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Email </th>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Phone</th>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Created At</th>
                    <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Dashboard</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAgaData.map((data, index) => (
                    <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.firstName} {data.lastName}</td>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Advisor</td>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.email}</td>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.phone}</td>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.created_at}</td>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                        <Button background={'#4caf50'} color={'white'} size={'xs'} onClick={() => handleViewAdvisor(data.idUser)}>View</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default AdminAllAdvisors;
