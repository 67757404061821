import { ADVISOR_PROFILE_LIST} from "../../constants/actionConstants";

const initialState = {
  advisorProfile: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADVISOR_PROFILE_LIST:
      return { ...state, advisorProfile: action.payload };
    default:
      return state;
  }
};
