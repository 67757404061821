import {  AGA_MGA_GET_ALL, CUSTOMER_GET_ALL, isLoading } from "../../../../constants/actionConstants";
import {  AgaMgaAllService, customerAllService } from "./service";

export const customerAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await customerAllService.customerAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: CUSTOMER_GET_ALL,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };