import { toast } from "react-toastify";
import {
  STUDENTVISA_QUICKNOTE,
  SUPERVISA_QUICKNOTE,
  VISITOR_QUOTE_LIST,
  isLoading,
} from "../../../constants/actionConstants";
import {
  SupervisaQuicknoteList,
  supervisaQuicknoteService,
  StudentQuicknoteList,
  studentQuicknoteService,
  visitorQuicknoteService,
} from "./service";

export const SuperVisa = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await supervisaQuicknoteService.SupervisaQuicknoteList(
      params
    );
    console.log("Response data:", response);
    dispatch({
      type: SUPERVISA_QUICKNOTE,
      payload: response.data,
    });
    dispatch(isLoading(false));
    return response;
  } catch (error) {
    console.error(error);
    dispatch(isLoading(false));
    throw error;
  }
};

export const StudentVisa = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await studentQuicknoteService.StudentQuicknoteList(params);
    dispatch({
      type: STUDENTVISA_QUICKNOTE,
      payload: response.data,
    });
    dispatch(isLoading(false));
    return response.status;
  } catch (error) {
    console.error(error);
    dispatch(isLoading(false));
    throw error;
  }
};




export const VisitorVisa = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await visitorQuicknoteService.VisitorQuicknoteList(params);
    console.log("responsevistor",response)
    dispatch({
      type: VISITOR_QUOTE_LIST,
      payload: response.data,
    });
    dispatch(isLoading(false));
    return response.status;
  } catch (error) {
    console.error(error);
    dispatch(isLoading(false));
    throw error;
  }
};
