import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex,Box,Tooltip,Select, Button} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import CustomInput from '../../../../Utils/Input/Index';
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../../Utils/UniversalColor';
import { advisorRenewalListAll } from '../../Dependencies/action';
import { useDispatch } from 'react-redux';
import { formatDate } from '../../../../Utils/DateFormate';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const AdvisorRenewalPolicies = () => {
    const [product, setProduct] = useState('');
    const [fromDate, setFromDate] = useState('');
    const dispatch=useDispatch();
    const [toDate, setToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [quotations, setQuotations] = useState([]);
    const navigate=useNavigate()
    const [rowData, setRowData] = useState([]);
    const pagination = true;
    const gridRef = useRef(null);


    useEffect(() => {
       getRenewalPolicies()
      }, [dispatch]);

      useEffect(() => {
        const handleResize = () => {
          if (gridRef.current && gridRef.current.api) {
            if (window.innerWidth > 768) {
              gridRef.current.api.sizeColumnsToFit();
            } else {
              gridRef.current.api.resetColumnState();
            }
          }
        };
    
        // Initial column fitting based on screen size
        if (gridRef.current && gridRef.current.api) {
          handleResize();
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const getRenewalPolicies=()=>{
        dispatch(advisorRenewalListAll())
        .then((res) => {
          console.log(res,'res')
            const formattedData = res.map((quote, index) => {
              let status;
              switch (quote.status) {
                case 1:
                  status = 'Active';
                  break;
                case 2:
                  status = 'Pending Cancellation';
                  break;
                case 3:
                  status = 'Cancelled';
                  break;
                case 4:
                  status = 'Pending Void';
                  break;
                case 5:
                  status = 'Void';
                  break;
                case 6:
                  status = 'Policy Change Pending';
                  break;
                case 7:
                  status = 'Matured';
                  break;
                default:
                  status = 'Inactive';
              }
              let tripType;
              if (quote.quote.product === 'Vistors To Canada') {
                tripType = 'Visitor To Canada';
              } else if (quote.quote.product === 'Student To Canada') {
                tripType = 'Student To Canada';
              } else if (quote.quote.superVisa === 1) {
                tripType = quote.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
              } else {
                tripType = 'Single Trip';
              }
    
              return {
                employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
                designation: quote.quote.user.roles.name ,
                product: quote.quote.product,
                policyNo: quote.policyNo,
                dateOfIssue: formatDate(quote.createdAt),
                customerName: quote.quote.policyHolderName,
                tripType: tripType,
                policyAmount: `$ ${quote.quote.quoteAmount}`,
                status: status
              };
            });
            setRowData(formattedData);
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
      }

    
   const paginationPageSizeSelector = [10,20,50,100];
  
    const downloadCellRenderer = (params) => {
      const handleDownload = () => {
        navigate('/admin/download-documents')
        console.log('Download clicked for row:', params.data);
      };


      
    return(
      <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
      <FaDownload color={buttonBackgroundColor} onClick={handleDownload} style={{ cursor: 'pointer' }} />
    </div>
    )
    };
  
  
    const handleAction = (params)=>{
        const {data}=params
      const handleChange = (e) => {
        if(e.target.value==='endorsement'){
          navigate(`/customerPolicyEndorsement/${data.id}`)
        }
        if(e.target.value==='view'){
          navigate(`/single-policy-view/${data.id}`)
        }
        if(e.target.value==='send'){
          alert('Policy emailed successfully.')
        }
        console.log('Download clicked for row:', params.data);
      };
    return(
      <>
          {/* <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
           <Select onChange={(e) => handleChange(e)} size={'xs'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
            <option value="">Select</option>
            <option value="view">View</option>
            <option value="send">Email policy</option>
            <option value="endorsement">Endorsement</option>
            <option value="cancel">Re-issue</option>
            </Select>
          </Tooltip> */}
          </>
    )
    }
  
  const [colDefs, setColDefs] = useState([
    { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
    { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true },
    { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150 },
    { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 230 },
    { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
    { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300 },
    { field: "tripType", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "policyAmount", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
   
  ]);
  
    const actionCellRenderer = (params) => {
      const handleActionChange = (action) => {
        console.log('Selected Action:', action);
      };
  
      return (
        <select onChange={(e) => handleActionChange(e.target.value)}>
          <option value="view">View Quote</option>
          <option value="view">Send Quote</option>
          <option value="edit">Edit Quote</option>
          <option value="editTrip">Edit Trip Details</option>
          <option value="cancel">Cancel Quote</option>
        </select>
      );
    };
  
    const downloadPDF = () => {
      const doc = new jsPDF();
  
      doc.text("Quotations", 14, 16);
      const tableColumn = ["Employee Name", "Designation", "Product", "Policy No", "Date of Issue", "Customer Name", "Trip Type", "Policy Amount", "Status"];
      const tableRows = [];
  
      rowData.forEach(quotation => {
        const quotationData = [
          quotation.employeeName,
          quotation.designation,
          quotation.product,
          quotation.policyNo,
          quotation.dateOfIssue,
          quotation.customerName,
          quotation.tripType,
          quotation.policyAmount,
          quotation.status
        ];
        tableRows.push(quotationData);
      });
  
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        styles: { fontSize: 8 },
      });
  
      doc.save('quotations.pdf');
    };
  
    const downloadExcel = () => {
      const worksheet = XLSX.utils.json_to_sheet(rowData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
      XLSX.writeFile(workbook, 'quotations.xlsx');
    };
  
    const downloadCSV = () => {
      // Implement logic for downloading CSV
    };


  return (
    <Box background={'#f6f6f6'} h={'auto'} pb={'30px'}>
    <Box w={['90%','99%','80%']} m={'auto'} style={{ paddingTop: '80px',marginBottom: '90px' }}>
      <Text fontSize={['20px','14px','22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '20px' }}>My Renewal Policy List within 30 days</Text>
      <Flex>
        <Box>
          <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
        </Box>
        <Box>
          <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
        </Box>
      </Flex>
      <br />

      <Flex direction={['column','column','row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
          <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'sm'} leftIcon={<FaRegFilePdf />} style={{ color: 'white', backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button leftIcon={<SiMicrosoftexcel />} size={'sm'} style={{ color: buttonColor, backgroundColor:buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'sm'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip>

          </div>
          <div>
            {/* <label htmlFor="search">Search:</label> */}
              <CustomInput/>
            {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
          </div>
        </Flex>
        <Box pb={'70px'} pt={['15px','1px','1px']}>
        {rowData.length > 0 ? (
          <div className='ag-theme-quartz' style={{width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              domLayout='autoHeight'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              paginationPageSize={10}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}              
            />
          </div>
        ) : (
          <div className='ag-theme-quartz' style={{width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
            // onGridReady={(params) => {
            //   params.api.sizeColumnsToFit();
            // }}
          />
        </div>
        )}
        </Box>
    </Box>
  </Box>
  )
}

export default AdvisorRenewalPolicies
