import { Box, Button, Flex, Image, Input, Select, Text, Textarea, useTheme } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

const BecomePartner = () => {
    const latitude = 28.453432537750142;
    const longitude = 77.06834227116423;
    const [submitStatus, setSubmitStatus] = useState(null);
    const theme = useTheme();
  
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      permission: true, 
    });
  
    const [formErrors, setFormErrors] = useState({});
  
  
    useEffect(() => {
      document.title = 'Contact Us | Connect Today - Upscaller Awaits Your Contact. | Upscaller';
      const metaDescription = document.createElement('meta');
      metaDescription.name = 'Contact us - Upscaller Development Excellence';
      metaDescription.content = 'Get in touch with us for top-notch upscaler development services. Our expert team is ready to elevate your business. Contact Upscaller for customized solutions and seamless collaboration.';
      document.head.appendChild(metaDescription);
    
      return () => {
        document.head.removeChild(metaDescription);
      };
    }, []);
  
    const handleClick = () => {
      const googleMapsURL = `https://www.google.com/maps?q=${latitude},${longitude}`;
      window.open(googleMapsURL, '_blank');
    };
  
    const validateForm = () => {
      const errors = {};
  
      // Validate name
      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }
  
      // Validate email
      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Enter a valid email address';
      }
  
      // Validate phone
      if (!formData.phone.trim()) {
        errors.phone = 'Phone is required';
      } else if (!isValidPhone(formData.phone)) {
        errors.phone = 'Enter a valid phone number';
      }
  
      // Validate message
      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      }
  
      // Validate permission checkbox
      if (!formData.permission) {
        errors.permission = 'You must grant permission to submit the form';
      }
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0; // Return true if there are no errors
    };
  
    const isValidEmail = (email) => {
      // Use a regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const isValidPhone = (phone) => {
      // Use a regular expression to validate phone number format
      const phoneRegex = /^[0-9]{10}$/;
      return phoneRegex.test(phone);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      if (validateForm()) {
        try {
          console.log(formData,'data')
          const response = await axios.post(`${POST_GUEST_ENQUIRY}`, formData);
          setSubmitStatus('success');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            permission: true,
          });
        } catch (error) {
          console.error('Error:', error);
          setSubmitStatus('error');
        }
      } else {
        console.log('Form has validation errors');
      }
    };


return (
    <Box pt={'90px'} pb={'120px'}>
    <Box m={'auto'}>
    <Flex alignItems={'center'} justifyContent={'space-between'}>
     <Box>
      <Text fontSize={'28px'} pl={'40px'}> Travel Medicare <br /> Join-us</Text>
     </Box>
     <Box w={'40%'}  backgroundImage={''}>
      <Image src='https://quickinsure.co.in/static/images/sub-header/company-banner.svg'/>
     </Box>
    </Flex>
    </Box>


    <Flex fontFamily={'poppins'} w={'80%'} m={'auto'} mt={'120px'}>
      <Box w={'50%'}>
        {/* <Text fontWeight={'600'} fontSize={'18px'}>Fill Form To join us</Text> */}
        <Box w={'90%'} m={'auto'}>
        <Box w={['90%', '90%', '60%', '82%']} m='auto' p={'20px'} border={'1px solid grey'}>
                  <form onSubmit={handleSubmit}>
                    <Flex>
        <Box w={'50%'}> 
                    <label>
                      Name
                      <br />
                      <Input
                        type="text"
                        name="name"
                        w={'95%'}
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                      {formErrors.name && (
                          <Text color="red" fontSize="sm">
                          {formErrors.name}
                        </Text>
                      )}
                    </label>
                    </Box>
                    <Box w={'50%'}>
                    <label>
                      Phone
                      <br />
                      <Input
                        type="text"
                        mb={'10px'}
                        w={'95%'}
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                      {formErrors.phone && (
                          <Text color="red" fontSize="sm">
                          {formErrors.phone}
                        </Text>
                      )}
                    </label>
                    </Box>
                      </Flex>
                    <label>
                      Email <br />
                      <Input
                        mb={'15px'}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {formErrors.email && (
                        <Text color="red" fontSize="sm">
                          {formErrors.email}
                        </Text>
                      )}
                    </label>
                    <label>Join as  <br />
                      <Select>
                        <option value="">Select</option>
                        <option value="partner">Partner</option>
                        <option value="white-labeled">White Labeled</option>
                        <option value="affiliate">Affiliate</option>
                      </Select>
                    </label>
                    <label>
                      Message <br />
                      <Textarea
                        mb={'15px'}
                        type="text"
                        name="message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                      {formErrors.message && (
                        <Text color="red" fontSize="sm">
                          {formErrors.message}
                        </Text>
                      )}
                    </label>
                    <div>
                      <Button size={'sm'} mt={'10px'} backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} type='submit'>
                        Submit
                      </Button>
                      {submitStatus === 'success' && (
                        <Text color="green" pt={'10px'}>
                         Thank you, {formData.name.split(' ')[0]}! Your submission was successful.
                        </Text>
                      )}
                      {submitStatus === 'error' && (
                        <Text color="red" pt={'10px'}>
                          Submission failed. Please try again.
                        </Text>
                      )}
                    </div>
                  </form>
                </Box>
        </Box>
      </Box>
      <Box alignItems={'center'} w={'30%'} m={'auto'} mt={'-20px'}>
        <Image src='https://img.freepik.com/free-vector/father-shaking-hands-with-insurance-agent_74855-4412.jpg?t=st=1712053807~exp=1712057407~hmac=92e01cbf88c5019e4a5c1cdc644576e3e6f69b4dd8f681fa5790dfb54f568283&w=740'/>
      </Box>
    </Flex>


    <Box w={'80%'} m={'auto'} mt={'90px'}>
        {/* <Text fontWeight={'600'} fontSize={'18px'}>About Us</Text> */}
        <Text color={'#707070'} fontWeight={'400'} fontFamily={'Assistant,sans-serif'}>TravelMedicare is presented by Quickinsure Insurance Brokers Private Limited. It is an online insurance comparison portal, It also has offline sales force using state of art technology to help customers compare and choose from the basket of Insures in India. It is the only well-known online comparison portal focused only in General Insurance Products. Our vision is to be the leaders in simplifying and speeding up the process of buying insurance in India using technology and growth of internet of things in India. Our Vision is shared by committed and passionate team at Quickinsure at every level.</Text>
      </Box>
  </Box>
  )
}

export default BecomePartner
