import { toast } from "react-toastify";
import { ADVISORS_ALL_GET_TYPE, AGA_ASSING_EDIT_TYPE, ALL_AGA_TYPE_LIST, isLoading } from "../../../../constants/actionConstants";
import {   AsingAgaService } from "./service";

export const allAdvisorsGet = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AsingAgaService.allAdvisorsGet(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISORS_ALL_GET_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const allAGAGet = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AsingAgaService.allAGAGet(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ALL_AGA_TYPE_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const agaAssingEdit = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminProfile = await AsingAgaService.agaAssingEdit(params);
    const {status, message, data} = adminProfile

    if(status === 200 || 201) {
        dispatch({
            type: AGA_ASSING_EDIT_TYPE,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }
dispatch(isLoading(false));

    return adminProfile

}