import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../Utils/UniversalColor'
import { Link } from 'react-router-dom'

const Tickets = () => {



return (
    <Box pb={'120px'} background={'#f6f6f6'}>
      <Flex direction={['column','column','row']} pt={['120px','160px','160px']} justifyContent={'space-between'} alignItems={'center'} pb={['50px','120px','120px']} w={'90%'} m={'auto'}>
        <Text fontSize={['22px','28px','32px']} fontFamily={'poppins'} textAlign={'center'}>What can we help you with?</Text>
        <Flex>
          <Link to='/all-tickets'>
          <Button background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} size={'sm'}>All Tickets</Button>
          </Link>
        </Flex>
      </Flex>
      <Flex direction={['column','column','row']} w={'80%'} m={'auto'} fontFamily={'poppins'} justifyContent={'space-around'} gap={'20px'}>
        <Link to='/ticket-getting-started'>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}> Getting Started</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to create new account?</Text>
          <Text>Issues with account verification</Text>
          <Text>Reseting your password</Text>
          </Box>
        </Box>
        </Link>
        <Link to='/ticket-quotation-help'>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}>Quotations</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to get quotation.</Text>
          <Text>Issues with Quotation Creation.</Text>
          <Text>Quotation plans.</Text>
          </Box>
        </Box>
        </Link>
        <Link to={'/ticket-policies-help'}>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}>Policies</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to get new policy.</Text>
          <Text>Issues with policies creation.</Text>
          <Text>How to do endorsement in policy.</Text>
          </Box>
        </Box>
        </Link>
      </Flex>

      {/* <Flex w={'80%'} m={'auto'} fontFamily={'poppins'} justifyContent={'space-around'} mt={'35px'}>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}>Developers</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to create new account?</Text>
          <Text>Issues with account verification</Text>
          <Text>Reseting your password</Text>
          </Box>
        </Box>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}>Administers</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to create new account?</Text>
          <Text>Issues with account verification</Text>
          <Text>Reseting your password</Text>
          </Box>
        </Box>
        <Box background={'white'} cursor={'pointer'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px;'}} p={'25px 55px'} borderRadius={'10px'}>
          <Text fontWeight={'700'} fontSize={'18px'}>Advisors</Text>
          <Box fontSize={'14px'} lineHeight={'24px'} fontFamily={'Montserrat, Sans-serif'} mt={'10px'}>
          <Text>How to create new account?</Text>
          <Text>Issues with account verification</Text>
          <Text>Reseting your password</Text>
          </Box>
        </Box>
      </Flex> */}
    </Box>
  )
}

export default Tickets
