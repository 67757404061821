import { ADMIN_USERS_LIST, ASSING_MENUS } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminUserService = {

    adminUserList() {
        return Api.GET(ADMIN_USERS_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    assingMenus( params) {
        return Api.POST(ASSING_MENUS, params).then((response) => {
          console.log(response,'response')
          const { data, status } = response
          if (status === 200 || status === 201) {
            return { data, status }
          } else {
            const { data: { message } = {} } = response
            return { message, status }
          }
        })
      }


}