import { toast } from "react-toastify";
import { SupportTicketService } from "./service";
import { ALL_TICKET_GET_ID_TYPE, ALL_TICKET_GET_TYPE, isLoading } from "../../../constants/actionConstants";


export const supporTicketAllList = (params) => async (dispatch) => {
 
    dispatch(isLoading(true));
    try {
      const response = await SupportTicketService.supporTicketAllList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ALL_TICKET_GET_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const supporTicketAllById = (id,params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getPages = await SupportTicketService.supporTicketAllById(id, params); 
    const { status, message, data } = getPages;

    dispatch({
        type: ALL_TICKET_GET_ID_TYPE,
        payload: { message, data }
    });

    dispatch(isLoading(false));

    return getPages;
}