import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex,Box,Tooltip,Select, Button} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../../Utils/UniversalColor';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import CustomInput from '../../../../Utils/Input/Index';
import { customerRenewalListAll } from '../../Dependencies/action';
import { useDispatch } from 'react-redux';
import jsPDF from 'jspdf';

const CustomerRenewalPolicies = () => {
    const [product, setProduct] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [quotations, setQuotations] = useState([]);
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const pagination = true;
   const paginationPageSizeSelector = [10,20,50,100];
   const [rowData, setRowData] = useState([]);
   const gridRef = useRef(null);

   useEffect(() => {
     const handleResize = () => {
       if (gridRef.current && gridRef.current.api) {
         if (window.innerWidth > 768) {
           gridRef.current.api.sizeColumnsToFit();
         } else {
           gridRef.current.api.resetColumnState();
         }
       }
     };
 
     // Initial column fitting based on screen size
     if (gridRef.current && gridRef.current.api) {
       handleResize();
     }
 
     window.addEventListener('resize', handleResize);
     return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
    dispatch(customerRenewalListAll())
      .then((res) => {
        console.log("Fetched data:", res); // Log the fetched data
        const transformedData = transformData(res);
        console.log("Transformed data:", transformedData); // Log the transformed data
        setRowData(transformedData); // Update state with transformed data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);

  const transformData = (res) => {
    return res.map(item => ({
      employeeName: `${item.quote.user.firstName} ${item.quote.user.lastName}`,
      designation: item.quote.user.roles.name ,
      product: item.quote.product,
      policyNo: item.policyNo,
      dateOfIssue: item.createdAt,
      customerName: item.quote.policyHolderName,
      tripType: item.product,
      policyAmount: item.quote.quoteAmount,
      status: item.status === 1 ? 'Active' : 'Inactive',
    }));
  };

  
    const downloadCellRenderer = (params) => {
      const handleDownload = () => {
        navigate('/admin/download-documents')
        console.log('Download clicked for row:', params.data);
      };
    return(
      <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
      <FaDownload color={buttonBackgroundColor} onClick={handleDownload} style={{ cursor: 'pointer' }} />
    </div>
    )
    };
  
  
    const handleAction = (params)=>{
        const {data}=params
      const handleChange = (e) => {
        if(e.target.value==='endorsement'){
          navigate(`/customerPolicyEndorsement/${data.id}`)
        }
        if(e.target.value==='view'){
          navigate(`/single-policy-view/${data.id}`)
        }
        if(e.target.value==='send'){
          alert('Policy emailed successfully.')
        }
        console.log('Download clicked for row:', params.data);
      };
    return(
      <>
          <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
           <Select onChange={(e) => handleChange(e)} size={'xs'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
            <option value="">Select</option>
            <option value="view">View</option>
            <option value="send">Email policy</option>
            <option value="endorsement">Endorsement</option>
            <option value="cancel">Re-issue</option>
            </Select>
          </Tooltip>
          </>
    )
    }

    const [colDefs, setColDefs] = useState([
      { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
      { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true },
      { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150 },
      { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 330 },
      { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
      { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300 },
      { field: "tripType", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
      { field: "policyAmount", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
      { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
      { field: "Action", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: handleAction },
    ]);
  
    const actionCellRenderer = (params) => {
      const handleActionChange = (action) => {
        console.log('Selected Action:', action);
      };
  
      return (
        <select onChange={(e) => handleActionChange(e.target.value)}>
          <option value="view">View Quote</option>
          <option value="view">Send Quote</option>
          <option value="edit">Edit Quote</option>
          <option value="editTrip">Edit Trip Details</option>
          <option value="cancel">Cancel Quote</option>
        </select>
      );
    };  
  
    const downloadPDF = () => {
      // Create a new jsPDF instance
      const doc = new jsPDF();
    
      // Define table headers
      const headers = Object.keys(rowData[0]);
    
      // Define table rows
      const rows = rowData.map(row => Object.values(row));
    
      // Set properties for the PDF document
      doc.setFont('helvetica');
      doc.setFontSize(12);
    
      // Define x and y position for the table
      const y = 25; // Start y position for the table
    
      // Add table headers
      doc.text('Renewal Policies', 14, 10);
      doc.autoTable({
        startY: y,
        head: [headers],
        body: rows,
        theme: 'grid',
        styles: {
          font: 'helvetica',
          fontStyle: 'normal',
          fontSize: 10,
          cellPadding: 4,
        },
        columnStyles: {
          0: { fontStyle: 'bold' }, // Make the first column bold
        },
      });
    
      // Save the document
      doc.save('renewal_policies.pdf');
    };
    
  
    const downloadExcel = () => {
      const worksheet = quotations.map(({ id, ...rest }) => rest); // Remove 'id' property
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
      saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'quotations.xlsx');
    };
  
    const downloadCSV = () => {
      // Implement logic for downloading CSV
    };


return (
    <Box background={'#f6f6f6'} h={'auto'} pb={'30px'}>
    <Box w={['95%','95%','80%']} m={'auto'} style={{ paddingTop: '80px',marginBottom: '90px' }}>
      <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Renewal Policy List within 30 days</h3>
      <Flex>
        <Box>
          <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
        </Box>
        <Box>
          <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
        </Box>
      </Flex>
      <br />

      <Flex direction={['column','column','row']} justifyContent={'space-between'} mt={'15px'} mb={['15px','5px','5px']}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
          <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'xs'} leftIcon={<FaRegFilePdf />} style={{ color: 'white', backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button leftIcon={<SiMicrosoftexcel />} size={'xs'} style={{ color: buttonColor, backgroundColor:buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'xs'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip>
          </div>
          <div>
            {/* <label htmlFor="search">Search:</label> */}
              <CustomInput/>
            {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
          </div>
        </Flex>
      <div
        className="ag-theme-quartz"
        style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
      >
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
      </div>

    </Box>
  </Box>
  )
}

export default CustomerRenewalPolicies
