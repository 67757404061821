import './styles.css';
import React, { useState, useEffect, useRef } from 'react';
import {Menu,MenuButton,MenuList,MenuItem,MenuItemOption,MenuGroup,MenuOptionGroup,MenuIcon,MenuCommand,MenuDivider,Text,Box, Flex, Select} from '@chakra-ui/react';
import { CSSTransition } from 'react-transition-group';
import { MdChevronLeft, MdChevronRight, MdMenu } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { storage } from '../../dependencies/store/storage';
import { IoIosArrowDown } from 'react-icons/io';
import { AddIcon, ArrowRightIcon, AtSignIcon, DragHandleIcon, NotAllowedIcon, RepeatIcon } from '@chakra-ui/icons';


const HoverButton = () => {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const { t, i18n } = useTranslation(); 
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language); 
  };


  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height + 20);
  }

  const handleLogout = ()=>{
    storage.clear()
    window.location.href='/'
  }

  return (
    <>
      <Menu className="dropdown" closeOnSelect={false}>
        <MenuButton className='Link_btns' cursor={'pointer'} as={Text} rightIcon={<MdChevronRight />}>
        <Flex gap={'3px'} alignItems={'center'}> 
            <Text _hover={{color:'teal'}}>My TM</Text>
            <IoIosArrowDown />
        </Flex>
        </MenuButton>
        <MenuList style={{ height: menuHeight }} className="dropdown">
          <CSSTransition
            in={activeMenu === 'main'}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="main-menu">
            <Link to='/advisor-quotes'>
              <MenuItem icon={<ArrowRightIcon/>} _hover={{color:'teal'}}>
                <Text>My Quotes</Text>
              </MenuItem>
                </Link>
                <Link to='/advisor-policies'>
                <MenuItem icon={<AtSignIcon/>} _hover={{color:'teal'}}>
                    My Policies
                </MenuItem>
                </Link>
                <Link to='/advisor-commission'>
                  <MenuItem icon={<DragHandleIcon/>} _hover={{color:'teal'}}>
                      Commission
                  </MenuItem>
                  </Link>
                  <Link to='/support'>
                  <MenuItem icon={<AddIcon />} _hover={{color:'teal'}}>
                    Support
                </MenuItem>
                  </Link>
                  <MenuItem icon={<NotAllowedIcon/>} _hover={{color:'teal'}} onClick={handleLogout}>
                     Logout
                  </MenuItem>
            </div>
          </CSSTransition>
        </MenuList>
      </Menu>
    </>
  );
}

export default HoverButton