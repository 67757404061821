import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Sidebar'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Checkbox, Flex, Input, InputGroup, InputRightAddon, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react'
import Api from '../../../../../dependencies/utils/Api'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { ENDORSEMENT_VOID_POLICY, GET_POLICY_BY_POLICY_ID } from '../../../../../constants/ApplicationUrl'



const AdminCustomerPolicyEndorsement = () => {
  const params = useParams()
  const navigate=useNavigate()
    const {id} = params
    const [policyData,setPolicyData]=useState([])
    const [availableNames, setAvailableNames] = useState([]);
    const [showBoardingPass, setShowBoardingPass] = useState(false);
    const [showRefundBackdatedTab,setShowRefundBackdatedTab]=useState(false)
    const [showFinancialFields,setShowFinancialFields]=useState(false)
    const [showNonFinancialFields,setShowNonFinancialFields]=useState(false)
    const [showInsuredCorrection,setShowInsuredCorrection]=useState('0')
    const [editable, setEditable] = useState({
      email: false,
      city: false,
      postalCode: false,
      address:false,
      phone:false
    });
    const [insuredDetails, setInsuredDetails] = useState([{ nameOfInsured: '', documentType: '', file: '', remarks: '' }]);
    const [midTermDetails,setMidTermDetails]=useState([{
      policyNo:'',
      passport:'',
      boardingPass:'',
      remarks:'',
      cancellationDate:'',
      endorsementType:'',
      type:'',
      idUser:'',
      idInsured:'',
      insuredName:'',
      premiumAmount:'',
    }])
    const [cancellationDetails,setCancellationDetails]=useState({
      cancellationDate:'',
      passportCopy:null,
      boardingPassCopy:null,
      remarks:''
    })
    const [formData, setFormData] = useState({
      email: '',
      city: '',
      postalCode: '',
      address: '',
      phone: '',
      remarks: '',
      policyNo: '',
      endorsementType: '',
      type: '',
      idUser: 123,
      beneficiaryDetailsRequests: []
    });
    const [extendDateForm,setExtendDateForm]=useState({
      idUser: 0,
      endorsType:'',
      type:'',
      correctionFields:'',
      uploadDoc:'',
      policyNo:'',
      remark:'',
      departDate:'',
      docType:''
    })
    const [correctInsuredDetailsForm,setCorrectInsuredDetailsForm]=useState({
      insuredNameOld:'',
      insuredName:'',
      insuredDob:'',
      proof:'',
      remarks:"",
      policyNo:'',
      endorsementType:'',
      type:'',
      idUser:'',
      idInsured:''
    })
    
    const handleExtendDateChange = (e) => {
      const { name, value, files } = e.target;
      setExtendDateForm(prevState => ({
          ...prevState,
          [name]: files ? files[0] : value
      }));
  };

    const handleCorrectionInputChange = (field, value) => {
      setFormData(prev => ({ ...prev, [field]: value }));
    };

    const handleCorrectionInsuredChange = (e)=>{
      const { name, value, files } = e.target;
      setCorrectInsuredDetailsForm(prevState => ({
          ...prevState,
          [name]: files ? files[0] : value
      }));
    }
  
    const handleBeneficiaryChange = (index, field, value) => {
      const updatedBeneficiaries = formData.beneficiaryDetailsRequests.map((beneficiary, idx) =>
        idx === index ? { ...beneficiary, [field]: value } : beneficiary
      );
      setFormData(prev => ({ ...prev, beneficiaryDetailsRequests: updatedBeneficiaries }));
    };

    const handleCorrectionTypeChange = (e) => {
      const selectedType = e.target.value;
      if (selectedType === 'non-financial') {
        setShowNonFinancialFields(true);
      }else{
        setShowNonFinancialFields(false)
      }
      if(selectedType === 'financial'){
        setShowFinancialFields(true)
      }else{
        setShowFinancialFields(false)
      }
    };

    const handleCorrectionMenuTypeChange = (e) => {
      const selectedType = e.target.value;
      setShowInsuredCorrection(selectedType)
      if (selectedType === '0') {
        setShowInsuredCorrection('0');
      }
      if(selectedType === 'financial'){
        setShowInsuredCorrection('1')
      }
    };

    const handleCancellationDateChange = (e) => {
        const { name, value } = e.target;
        const selectedDate = new Date(e.target.value);
        const today = new Date();
        if (selectedDate < today) {
            setShowBoardingPass(true);
        } else {
            setShowBoardingPass(false);
        }
        setCancellationDetails({ ...cancellationDetails,'cancellationDate': value });
    };

    const handleRefundDateChange = (e) => {
      const selectedDate = new Date(e.target.value);
      const today = new Date();
      if (selectedDate < today) {
          setShowRefundBackdatedTab(true);
      } else {
          setShowRefundBackdatedTab(false);
      }
  };

    const handleAddNew = () => {
      const isAnyDetailEmpty = insuredDetails.some(
          (traveler) => {
            if (!traveler.nameOfInsured) {
              alert("You must select the Insured Person");
              return true;
            }
            if (!traveler.file) {
              alert("You must upload the Document");
              return true;
            }
            if (!traveler.remarks) {
              alert("Enter the remark");
              return true;
            }
            return false;
          }
        ); 
    
        if (isAnyDetailEmpty) {
          return; // Exit the function if any detail is empty
        }
      if (insuredDetails.length < availableNames.length) {
        setInsuredDetails([...insuredDetails, { nameOfInsured: '',documentType: '',file: '', remarks: '' }]);
      }else{
        alert('All the Insured Persons were selected')
      }
    };

    const handleAddNewMidTerm = () => {
      const isAnyDetailEmpty = midTermDetails.some(
          (traveler) => {
            if (!traveler.insuredName) {
              alert("You must select the Insured Person");
              return true;
            }
            // if (!traveler.passportCopy) {
            //   alert("You must upload Your Passport copy");
            //   return true;
            // }
            // if (!traveler.boardingPassCopy) {
            //   alert("You must upload the Visa Rejection Document");
            //   return true;
            // }
            if (!traveler.remarks) {
              alert("Enter the remark");
              return true;
            }
            return false;
          }
        ); 
    
        if (isAnyDetailEmpty) {
          return; // Exit the function if any detail is empty
        }
        
        if (midTermDetails.length < availableNames.length) {
          setMidTermDetails([...midTermDetails, { insuredName: '',premiumAmount:'', passportCopy: '', boardingPassCopy: '', remarks: '' }]);
        }else{
          alert('All the Insured Persons were selected')
        }
    };

    const handleRemove = (index) => {
      const updatedInsuredDetails = [...insuredDetails];
      updatedInsuredDetails.splice(index, 1);
      setInsuredDetails(updatedInsuredDetails);
    };

    const handleMidTermRemove = (index) =>{
      const updatedInsuredDetails = [...midTermDetails];
      updatedInsuredDetails.splice(index, 1);
      setMidTermDetails(updatedInsuredDetails);
    }

    const handleInputChange = (e, index, field) => {
      const { name, value, files } = e.target;
      if (files && files.length > 0) {
        console.log('File:', files[0]);
        const file = files[0];
        const updatedInsuredDetails = [...insuredDetails];
        updatedInsuredDetails[index][field] = file; // Assign the file object directly
        setInsuredDetails(updatedInsuredDetails);
      } else {
        const updatedInsuredDetails = [...insuredDetails];
        updatedInsuredDetails[index][field] = value;
        setInsuredDetails(updatedInsuredDetails);
      }
    };
    
    const handleCancellationInputChange = (e) => {
        const { name, files } = e.target;

        if (files && files.length > 0) {
          // If files are present, update state with the file object
          const file = files[0];
          setCancellationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: file, // Store the file object itself
            // [`${name}`]: file.name, // Store the file name
          }));
        } else {
          // If no files, update state with input value
          const { value } = e.target;
          setCancellationDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
          }));
        }
        // const {name,value } = e.target;
        // setCancellationDetails({ ...cancellationDetails,[name]: value });
    };

    const handleRefundInputChange = (e, index, field) => {
        const { value } = e.target;
        const updatedmidTermDetails = [...midTermDetails];
        updatedmidTermDetails[index][field] = value;
        setMidTermDetails(updatedmidTermDetails);
    };

    const handleSelectChange = (e, index) => {
      const { value } = e.target;
      const selectedInsuredNames = insuredDetails.map((detail, idx) => (idx !== index ? detail.nameOfInsured : null));
      const isNameAlreadySelected = selectedInsuredNames.includes(value);

      if (isNameAlreadySelected) {
          alert("This Insured Person is already selected.");
          const updatedInsuredDetails = [...insuredDetails];
          updatedInsuredDetails[index].nameOfInsured = '';
          setInsuredDetails(updatedInsuredDetails);
          return;
      }
      const updatedInsuredDetails = [...insuredDetails];
      updatedInsuredDetails[index].nameOfInsured = value;
      setInsuredDetails(updatedInsuredDetails);
    };

    const handleRefundSelectChange = (e, index) =>{
        const {value}=e.target;
        const updatedMidTermDetails = [...midTermDetails]
        updatedMidTermDetails[index].insuredName=value;
        setMidTermDetails(updatedMidTermDetails)
    }

    const handleCorrectionSelectChange = () =>{
      console.log(event.target.value)
      const selectedName = event.target.value;
      const selectedInsured = availableNames.find(insured => insured.insuredName === selectedName);
      setCorrectInsuredDetailsForm(prevState => ({
        ...prevState,
        insuredNameOld: event.target.value,
        insuredName:event.target.value,
        insuredDob: selectedInsured ? selectedInsured.insuredDob : '',
        idInsured:selectedInsured ? selectedInsured.idInsured:''
    }));
    }
    
    useEffect(()=>{
      getPolicyDetails()
    },[])
    
    const getPolicyDetails = () =>{
      Api.GET(`${GET_POLICY_BY_POLICY_ID}/${id}`).then((res)=>{
        setPolicyData([res.data])
        setAvailableNames(res.data.quote.listOfInsured);
        const formattedBeneficiaries = res.data.quote.listOfInsured.map(item => ({
          beneficiaryName: item.beneficiaryName,
          beneficiaryRelation: item.beneficiaryRelationToInsured,
          beneficiaryDOB: item.beneficiaryDob,
        }));
        setFormData(prev => ({
          ...prev,
          email: res.data.quote.policyHolderEmail,
          city: res.data.quote.policyHolderCity,
          postalCode: res.data.quote.policyHolderPostalCode,
          address: res.data.quote.policyHolderAddress,
          phone: res.data.quote.policyHolderPhone,
          remarks: '',
          policyNo: res.data.policyNo,
          endorsementType: 'correction',
          type: 'correction',
          idUser: res.data.quote.idUser,
          beneficiaryDetailsRequests: res.data.quote.listOfInsured.map(insured => ({
            beneficiaryName: insured.beneficiaryName || '',
            beneficiaryRelation: insured.beneficiaryRelationToInsured || '',
            beneficiaryDOB: insured.beneficiaryDob || '',
        }))
        }));
      })
    }

    const handleVoid = () => {
      try {
        const formData = new FormData();
        formData.append('idUser', policyData[0].quote.user.idUser);
        formData.append('policyNo', policyData[0].policyNo);
        formData.append('endorsementType', 'Void');
        formData.append('type', '');
    
        // Append policyVoidRequests as an array of objects
        insuredDetails.forEach((detail, index) => {
          const requestKey = `policyVoidRequests[${index}]`;
          formData.append(`${requestKey}.nameOfInsured`, detail.nameOfInsured);
          formData.append(`${requestKey}.documentType`, detail.documentType);
          formData.append(`${requestKey}.file`, detail.file);
          formData.append(`${requestKey}.remarks`, detail.remarks);
        });
    
        console.log('Form Data:', formData);
    
        Api.POSTDATA(`${ENDORSEMENT_VOID_POLICY}`, formData).then((res) => {
          console.log('Response:', res);
        });
      } catch (err) {
        console.error('Error:', err);
      }
    };
    
    const handleRefundMidTerm = ()=>{
      console.log(midTermDetails,'mid term details')
    }

    const handleCancellationSubmit = ()=>{
      const formData = new FormData();
      formData.append('passport',cancellationDetails.passportCopy)
      formData.append('boardingPass',cancellationDetails.boardingPassCopy)
      formData.append('policyNo',policyData[0].policyNo)
      formData.append('remarks',cancellationDetails.remarks)
      formData.append('cancellationDate',cancellationDetails.cancellationDate,)
      formData.append('idUser',policyData[0].quote.user.idUser)
      formData.append('type',showBoardingPass===true?'Cancellation Backdated':'Cancellation')
      for (let [key, value] of formData.entries()) {
        console.log(key, value,'kyes');
       }    
       try{
         Api.POSTDATA(`http://192.168.1.7:8080/endorsement/cancelPolicy`,formData).then((res)=>{
           console.log(res)
          })
        }
        catch{
          console.log(err)
        }
    }

    const handleCheckboxChange = (field) => {
      setEditable(prev => ({ ...prev, [field]: !prev[field] }));
    };

    const handleNonFinancialCorrection = ()=>{
      const requiredFields = [formData.email, formData.city, formData.postalCode, formData.address, formData.phone, formData.remarks, formData.policyNo, formData.endorsementType, formData.type];
    if (!validateRequiredFields(requiredFields)) {
        alert("Please fill in all required fields.");
        return;
    }
    if (!validateEmail(formData.email)) {
        alert("Please enter a valid email address.");
        return;
    }
    if (!validateMobile(formData.phone)) {
        alert("Please enter a valid 10-digit mobile number.");
        return;
    }
      Api.POST(`http://192.168.1.7:8080/endorsement/correctionOfNonFinancialFeild`,formData).then((res)=>{
        console.log(res,'correction response')
        alert('Correction Request Sent to Admin')
      })
    }

    const handleExtendDateSubmit = () =>{
      if (!extendDateForm.departDate){
          alert("Please select extend date.");
          return;
      }
      if (!extendDateForm.docType){
        alert("Please Select document type.");
        return;
    }
      if (!extendDateForm.uploadDoc){
          alert("Please upload document");
          return;
      }
      if (!extendDateForm.remark){
        alert("Please add remark.");
        return;
    }
      const formData = new FormData();
      formData.append('idUser',policyData[0].quote.user.idUser)
      formData.append('policyNo',policyData[0].policyNo)
      formData.append('remark',extendDateForm.remark)
      formData.append('uploadDoc',extendDateForm.uploadDoc)
      formData.append('departDate',extendDateForm.departDate)
      formData.append('endorsType','Policy Change')
      formData.append('type','Policy Change')
      for (let [key, value] of formData.entries()) {
        console.log(key, value,'kyes');
       }   
       try{
        Api.POSTDATA(`http://192.168.1.7:8080/endorsement/correctionOfDateOfDeparture`,formData).then((res)=>{
          console.log(res)
          alert('Request sent to Admin')
          setExtendDateForm({
            remark:'',
            uploadDoc:'',
            departDate:''
          })
         })
       }
       catch{
         console.log(err)
       }    
      }

    const handleCorrectionInsuredDetailsSubmit = () =>{
        const formData = new FormData();
        formData.append('idUser',policyData[0].quote.user.idUser)
        formData.append('policyNo',policyData[0].policyNo)
        formData.append('remarks',correctInsuredDetailsForm.remarks)
        formData.append('endorsementType','Corrrection')
        formData.append('type','Policy Change')
        formData.append('insuredName',correctInsuredDetailsForm.insuredName)
        formData.append('insuredDob',correctInsuredDetailsForm.insuredDob)
        formData.append('proof',correctInsuredDetailsForm.proof)
        formData.append('idInsured',correctInsuredDetailsForm.idInsured)
        for (let [key, value] of formData.entries()) {
          console.log(key, value,'kyes');
        }
        console.log(correctInsuredDetailsForm,'frm')   
        try{
          Api.POSTDATA(`http://192.168.1.7:8080/endorsement/correctionOfInsuredDetails`,formData).then((res)=>{
            console.log(res)
            // alert('Request sent to Admin')

          })
        }
        catch{
          console.log(err)
        }
      }

const handleBack =()=>{
  navigate(-1)
}

return (
    <Sidebar headingText={'Customer Dashboard'}>
       <Flex w={'90%'} m={'auto'} justifyContent={'start'}>
         <Button size={'sm'} background={'#9c27b0'} mb={'20px'} ml={'20px'} color='white' mt={'10px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
        </Flex>
        <>
    {
      policyData.length>0 && policyData.map((ele)=>(
      <Box w={'80%'} m={'auto'} background={'white'} pt={'20px'} pb={'120px'}>
        <Flex w={'95%'} m={'auto'} justifyContent={'space-between'} alignItems={'center'} mt={'20px'}> 
            <Flex color={'teal'} ></Flex>
            <Text>Change Request for Policy ({ele.policyNo})</Text>
        </Flex>
        <Box w={'95%'} m={'auto'} mt={'2px'}>
          <Flex p={'10px'} justifyContent={'space-around'} border={'1px solid grey'} borderRadius={'5px'}>
              <Box w={'22%'}>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy No:</Text>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Created date:</Text>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Amount:</Text>
              </Box>
              <Box w={'22%'}>
                  <Text mt={'5px'}t color={'#3C4858'} fontWeight={'300'}>{ele.policyNo}</Text>
                  <Text mt={'5px'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.createdAt}</Text>
                  <Text mt={'5px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>${ele.quote.quoteAmount}</Text>
              </Box>
              <Box w={'22%'}>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                  <Text mt={'10px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Duration:</Text>
              </Box>
              <Box w={'22%'}>
                  <Text mt={'5px'}t color={'#3C4858'} fontWeight={'300'}>{ele.quote.firstDateOfCover}</Text>
                  <Text mt={'5px'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.lastDateOfCover}</Text>
                  <Text mt={'5px'} text5ign={'left'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.duration}</Text>
              </Box>
            </Flex>
        </Box>
        <Flex w={'95%'} m={'auto'} mt={'35px'} justifyContent={'center'}>

        <Tabs variant='soft-rounded' w={'95%'} m={'auto'} alignItems={'center'} justifyContent={'center'} textAlign={'center'}>
            <TabList gap={'30px'} justifyContent={'center'}>
              <Tooltip bg='white' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Void refers to the cancellation of a policy prior to its effective start date.'> 
              <Tab style={{ borderRadius:'0px',backgroundColor: "#9c27b0",color:'white',fontWeight: 600,padding:'5px 50px', borderRadius:'5px',fontSize:'14px'}}>VOID</Tab>
              </Tooltip>
              <Tooltip bg='white' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Void refers to the cancellation of a policy prior to its effective start date.'> 
              <Tab style={{ borderRadius:'0px',backgroundColor: "#9c27b0",color:'white',fontWeight: 600,padding:'0px 40px', borderRadius:'5px',fontSize:'14px'}}>Cancellation</Tab></Tooltip>
              <Tooltip bg='white' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Void refers to the cancellation of a policy prior to its effective start date.'> 
              <Tab style={{ borderRadius:'0px',backgroundColor: "#9c27b0",color:'white',fontWeight: 600,padding:'0px 40px', borderRadius:'5px',fontSize:'14px'}}>Refund mid-term</Tab></Tooltip>
              <Tooltip bg='white' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Correction refers to the correction in any financial/non-financial fields of policy.'> 
              <Tab style={{ borderRadius:'0px',backgroundColor: "#9c27b0",color:'white',fontWeight: 600,padding:'0px 40px', borderRadius:'5px',fontSize:'14px'}}>Correction</Tab></Tooltip>
            </TabList>
            <TabPanels>
              <TabPanel textAlign={'center'}>
                <Text mt={'20px'} fontSize={'26px'} fontWeight={'600'} textAlign={'centre'}>ENDORSEMENT VOID</Text>
                <Text fontWeight={'500'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>This endorsement for voiding policy from inception date</Text>
                <Box padding={'20px'} border={'1px solid grey'} mt={'10px'}>
                  <Flex justifyContent={'space-between'} pt={'10px'}>
                    <Text fontSize={'12px'}>Type: <span style={{color:'teal'}}>SVVTC</span></Text>
                    <Box textAlign={'right'} fontSize={'12px'} >
                      <Text>Allowed Files types are pdf, jpg and png and Each file must not exceed 1MB <span style={{color:'red'}}>*</span></Text>
                      <Text>Clear image to be uploaded to avoid rejection <span style={{color:'red'}}>*</span></Text>
                    </Box>
                  </Flex>
                  <Flex justifyContent={'end'} mt={'25px'}>
                    <Button background={'#9c27b0'} color={'white'} size={'xs'} onClick={handleAddNew}>Add New</Button>
                  </Flex>
                  <Flex fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} justifyContent={'space-around'} fontSize={'12px'} textAlign={'left'} className='InsuredDetailsContainer' direction='column' mt={'25px'}>
                    {insuredDetails.map((details, index) => (
                        <Flex key={index} justifyContent='space-around' mt={index > 0 ? '15px' : '0'}>
                          <Box>
                            <Text>Name of Insured *</Text>
                            <Select
                                  border={'1px solid grey'}
                                  mt={'5px'}
                                  width={'180px'}
                                  size={'sm'}
                                  onChange={(e) => handleSelectChange(e, index)}
                                  >
                                  <option value="">Select</option>
                                  {availableNames.map((insured) => (
                                      <option key={insured.idInsurred} value={insured.insuredName}>
                                          {insuredDetails.nameOfInsured || insured.insuredName}
                                      </option>
                                        ))}
                              </Select>
                          </Box>
                          <Box>
                              <Text>Document Type*</Text>
                              <Select border={'1px solid grey'} size={'sm'} mt={'5px'} onChange={(e) => handleInputChange(e, index,'documentType')}>
                                <option value="">Select</option>
                                <option value="passport">Passport copy</option>
                                <option value="letterOfEmbassy">Letter of embassy</option>
                                <option value="boardingPass">Boarding pass</option>
                              </Select>
                          </Box>
                          <Box>
                              <Text>File*</Text>
                              <Input onChange={(e) => handleInputChange(e,index,'file')} border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                          </Box>
                          <Box>
                              <Text>Remarks*</Text>
                              <Input onChange={(e) => handleInputChange(e, index, 'remarks')} border={'1px solid grey'} mt={'2px'} w={'180px'} size={'sm'} type="text" />
                          </Box>
                          <Box>
                            {index > 0 && <IoMdClose style={{ cursor: 'pointer' }} onClick={() => handleRemove(index)} />}
                        </Box>
                        </Flex>
                      ))}
                    </Flex>
                  {/* <Flex className='InsuredDetails' mt={'25px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} justifyContent={'space-around'} fontSize={'12px'} textAlign={'left'}>
                    <Box>
                      <Text>Name of Insured *</Text>
                      {
                          ele.quote.listOfInsured.map((insuredName)=>(
                          <Select border={'1px solid grey'} mt={'5px'} width={'180px'} size={'sm'}>  
                           <option value="">Select</option>
                           <option value={insuredName.insuredName}>{insuredName.insuredName}</option>
                          </Select>
                        ))
                      }
                    </Box>
                    <Box mt={'5px'}>
                      <Text>Copy Of Passport *</Text>
                        <Input border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                    </Box>
                    <Box mt={'5px'}>
                      <Text>Visa Rejection Document *</Text>
                        <Input border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                    </Box>
                    <Box>
                      <Text>Remarks*</Text>
                        <Input border={'1px solid grey'} mt={'2px'} w={'180px'} size={'sm'} type="text" />
                    </Box>
                  </Flex> */}
                  <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                  <Button background={'#9c27b0'} color={'white'} size={'sm'} onClick={handleVoid}>Submit</Button>
                  </Flex>
                </Box>
              </TabPanel>




              <TabPanel>
              <Text mt={'20px'} fontSize={'26px'} fontWeight={'600'} textAlign={'centre'}>ENDORSEMENT CANCELLATION</Text>
                <Text fontWeight={'500'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>This endorsement type for cancellation of whole policy from a required date</Text>
                <Box padding={'20px'} border={'1px solid grey'} mt={'10px'}>
                  <Flex justifyContent={'space-between'} pt={'10px'}>
                    <Text fontSize={'12px'} color={'red'}>*The minimum premium is $20 per policy.</Text>
                    <Box textAlign={'right'} fontSize={'12px'} >
                      <Text>Allowed Files types are pdf, jpg and png and Each file must not exceed 1MB <span style={{color:'red'}}>*</span></Text>
                      <Text>Clear image to be uploaded to avoid rejection <span style={{color:'red'}}>*</span></Text>
                    </Box>
                  </Flex>
                  <Text mt={'20px'} fontFamily={'Open Sans,sans-serif'}>Type : <span style={{color:'teal'}}>{showBoardingPass===true?'Backdated cancellation':' Cancellation'}</span></Text>
                  <Flex fontWeight={'600'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'} justifyContent={'center'} gap={'50px'} mt={'25px'}>
                    <Box>
                        <Text>Cancellation Date *</Text>
                        <Input name='cancellationDate' onChange={handleCancellationDateChange}  border={'1px solid grey'} w={'180px'} size={'sm'} type='date'/>
                    </Box>
                    <Box>
                        <Text>Remarks*</Text>
                        <Input name='remarks' onChange={handleCancellationInputChange} border={'1px solid grey'} size={'sm'} type='text'/>
                    </Box>
                  </Flex>
                  {showBoardingPass && (
                        <Box>
                          <Flex fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} textAlign={'left'} mt={'25px'} justifyContent={'center'}>
                          <Box>
                              <Text>Passport copy *</Text>
                              <Input name='passportCopy' onChange={handleCancellationInputChange} border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                          </Box>
                          <Box>
                              <Text>Boarding Passes Copy *</Text>
                              <Input name='boardingPassCopy' onChange={handleCancellationInputChange} border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                          </Box>
                          </Flex>
                        </Box>
                    )}
                  <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                  <Button onClick={handleCancellationSubmit} background={'#9c27b0'} color={'white'} size={'sm'}>Submit</Button>
                  </Flex>
                </Box>
              </TabPanel>




              <TabPanel>
              <Text mt={'20px'} fontSize={'26px'} fontWeight={'600'} textAlign={'centre'}>ENDORSEMENT REFUND</Text>
                <Text fontWeight={'500'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>This endorsement for deleting one or more insured from a ongoing policy if you have more than one insured in the policy</Text>
                <Box padding={'20px'} border={'1px solid grey'} mt={'10px'}>
                <Flex justifyContent={'space-between'} pt={'10px'}>
                    <Text fontSize={'12px'} color={'red'}>*The minimum premium is $20 per policy.</Text>
                    <Box textAlign={'right'} fontSize={'12px'} >
                      <Text>Allowed Files types are pdf, jpg and png and Each file must not exceed 1MB <span style={{color:'red'}}>*</span></Text>
                      <Text>Clear image to be uploaded to avoid rejection <span style={{color:'red'}}>*</span></Text>
                    </Box>
                  </Flex>
                  <Flex textAlign={'left'} mt={'25px'} alignItems={'center'} justifyContent={'space-around'}>
                    <Text fontWeight={'600'} fontSize={'13px'}>Type : {showRefundBackdatedTab===true?'Backdated cancellation':'Cancellation'}</Text>
                    <Box>
                      <Text fontWeight={'600'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>Cancellation Date*</Text>
                      <Input onChange={handleRefundDateChange} w={'250px'} type='date' size={'sm' }/>
                    </Box>
                  </Flex>
                  <Flex justifyContent={'end'}>
                    <Button background={'#9c27b0'} onClick={handleAddNewMidTerm} color={'white'} size={'xs'}>Add New</Button>
                  </Flex>
                  {midTermDetails.map((details, index) => (
                    <Box pt={'10px'} boxShadow='rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' pb={'15px'} mt={'20px'}>
                        <Flex fontWeight={'600'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'} justifyContent={'center'} gap={'50px'} key={index} mt={index > 0 ? '15px' : '0'}>
                          <Box>
                            <Text>Name of Insured *</Text>
                            <Select
                                  border={'1px solid grey'}
                                  mt={'5px'}
                                  width={'180px'}
                                  size={'sm'}
                                  onChange={(e) => handleRefundSelectChange(e, index)}
                                  >
                                  <option value="">Select</option>
                                  {availableNames.map((insured) => (
                                      <option key={insured.idInsurred} value={insured.insuredName}>
                                          {insured.insuredName}
                                      </option>
                                        ))}
                              </Select>
                            </Box>
                            <Box>
                              <Text>Premium Amount</Text>
                              <InputGroup border={'1px solid grey'} size='sm' mt={'2px'}>
                                  <Input  onChange={(e) => handleRefundInputChange(e, index, 'premiumAmount')} w={'100px'} size={'sm'} type="text" />
                                <InputRightAddon>$</InputRightAddon>
                              </InputGroup>
                            </Box>
                            <Box>
                              <Text>Remarks*</Text>
                              <Input onChange={(e) => handleRefundInputChange(e, index, 'remarks')} border={'1px solid grey'} mt={'2px'} w={'180px'} size={'sm'} type="text" />
                            </Box>
                            </Flex>
                          {showRefundBackdatedTab && (
                              <Box>
                              <Flex fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} textAlign={'left'} mt={'25px'} justifyContent={'center'}>
                              <Box>
                                  <Text>Passport copy *</Text>
                                  <Input onChange={(e) => handleRefundInputChange(e, index, 'passportCopy')} border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                              </Box>
                              <Box>
                                  <Text>Boarding Passes Copy *</Text>
                                  <Input onChange={(e) => handleRefundInputChange(e, index, 'boardingPassCopy')} border={'none'} mt={'5px'} w={'180px'} size={'xs'} type="file" />
                              </Box>
                              </Flex>
                                
                            </Box>
                            )
                          }
                          <Flex justifyContent={'end'} paddingRight={'30px'} fontSize={'26px'} color={'teal'}>
                            <Tooltip label='Click to remove user'>
                              <Box>
                            {index > 0 && <IoMdClose style={{ cursor: 'pointer' }} onClick={() =>handleMidTermRemove(index)} />}
                              </Box>
                            </Tooltip>
                          </Flex>
                          </Box>
                      ))}
                  
                  <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                  <Button onClick={handleRefundMidTerm} background={'#9c27b0'} color={'white'} size={'sm'}>Submit</Button>
                  </Flex>
                </Box>
              </TabPanel>



              <TabPanel>
              <Text mt={'20px'} fontSize={'26px'} fontWeight={'600'} textAlign={'centre'}>ENDORSEMENT CORRECTIONS</Text>
                <Text fontWeight={'500'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>This endorsement type for various corrections like Name,Dob,extension in arrival date etc</Text>
                <Box padding={'20px'} border={'1px solid grey'} mt={'10px'}>
                  <Flex justifyContent={'space-between'} pt={'10px'}>
                    <Text fontSize={'12px'} color={'red'}>*The minimum premium is $20 per policy.</Text>
                    <Box textAlign={'right'} fontSize={'12px'} >
                      <Text>Allowed Files types are pdf, jpg and png and Each file must not exceed 1MB <span style={{color:'red'}}>*</span></Text>
                      <Text>Clear image to be uploaded to avoid rejection <span style={{color:'red'}}>*</span></Text>
                    </Box>
                  </Flex>
                  <Text mt={'20px'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'}>Type: <span style={{color:'teal'}}>{showFinancialFields===true?'Policy Change':"Correction"}</span></Text>
                  <Flex fontWeight={'600'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'} justifyContent={'start'} gap={'50px'} mt={'15px'} mb={'15px'}>
                    <Box>
                        <Text>Type of Corrections*</Text>
                        <Select onChange={handleCorrectionTypeChange} w={'180px'} size={'xs'}>
                          <option value="">Select</option>
                          <option value="financial">Financial Field</option>
                          <option value="non-financial">Non-Financial Field</option>
                        </Select>
                    </Box>
                  </Flex>
                  {
                        policyData.map((ele)=>{
                          return(
                            <>
                  {
                    showFinancialFields===true?(
                        <Box>
                           <Box justifyContent={'start'}>
                           <Text fontWeight={'600'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'} fontSize={'12px'}> Finance Type of Correction</Text>
                           <Select w={'180px'} size={'xs'} onChange={handleCorrectionMenuTypeChange}>
                              {/* <option value="">Select</option> */}
                              <option value="0">Correction Insured Details</option>
                              <option value="1">Extend departure date</option>
                           </Select>
                          
                          </Box>
                           {
                            showInsuredCorrection==='0'? <Box className='ChangeInsuredDetails'>
                              <Flex gap={'40px'} fontWeight={'600'} mt={'10px'} textAlign={'left'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}>
                                <Box>
                                  <Text>Name of Insured*</Text>
                                  <Select border={'1px solid grey'} mt={'5px'} width={'180px'} size={'xs'}onChange={handleCorrectionSelectChange}>
                                  <option value="">Select</option>
                                  {availableNames.map((insured) => (
                                        <option 
                                            key={insured.idInsured} 
                                            value={insured.insuredName}
                                        >
                                            {insured.insuredName}
                                        </option>
                                    ))}
                                  {/* {availableNames.map((insured) => (
                                      <option key={insured.idInsurred} value={insured.insuredName}>
                                          {correctInsuredDetailsForm.nameOfInsured || insured.insuredName}
                                      </option>
                                        ))} */}
                                  {/* {availableNames.map((insured) => (
                                      <option key={insured.idInsurred} value={insured.nameOfInsured}>
                                          {insured.nameOfInsured}
                                      </option>
                                        ))} */}
                              </Select>
                                </Box>
                                <Box>
                                  <Text>Correction in Name*</Text>
                                  <Input onChange={handleCorrectionInsuredChange} value={correctInsuredDetailsForm.insuredName} name='insuredName' border={'1px solid grey'} mt={'5px'} w={'150px'} size={'xs'}/>
                                </Box>
                                <Box>
                                  <Text>DOB*</Text>
                                  <Input value={correctInsuredDetailsForm.insuredDob} onChange={handleCorrectionInsuredChange} name='insuredDob' type='date' border={'1px solid grey'} mt={'5px'} w={'150px'} size={'xs'}/>
                                </Box>
                                <Box>
                                  <Text>Proof*</Text>
                                  <Input onChange={handleCorrectionInsuredChange} name='proof' type='file' border={'none'} mt={'5px'} w={'150px'} size={'xs'}/>
                                </Box>
                              </Flex>
                              <Flex mt={'20px'}>
                                <Box>
                              <Text fontSize={'12px'} fontWeight={'600'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'}>Remarks*</Text>
                              <Input onChange={handleCorrectionInsuredChange} name='remarks' border={'1px solid grey'} size={'xs'} w={'180px'}/>
                                </Box>
                              </Flex>
                              <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                              <Button onClick={handleCorrectionInsuredDetailsSubmit} background={'#9c27b0'} color={'white'} size={'sm'}>Submit</Button>
                            </Flex>                            
                              </Box>
                              :
                              <Box className='extendDate'>
                                  <Flex mb={'15px'} gap={'40px'} fontWeight={'600'} mt={'20px'} textAlign={'left'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}>
                                      <Box>
                                        <Text>Departure Date*</Text>
                                        <Input min={ new Date().toISOString().split('T')[0]} type='date' name='departDate' value={extendDateForm.departDate} onChange={handleExtendDateChange} w={'180px'} size={'xs'}/>
                                      </Box>
                                        <Box>
                                          <Text>Document Type</Text>
                                          <Select name='docType' value={extendDateForm.docType} onChange={handleExtendDateChange} size={'xs'}>
                                            <option value="">Select</option>
                                            <option value="passport">Passport Copy</option>
                                            <option value="visa">Visa Rejection</option>
                                          </Select>
                                        </Box>
                                        <Box>
                                        <Text>Upload Document*</Text>
                                        <Input name="uploadDoc" onChange={handleExtendDateChange} size={'xs'} type='file' border={'none'} w={'180px'}/>
                                      </Box>
                                  </Flex>
                                  <Flex mt={'20px'}>
                                   <Box>
                                  <Text fontSize={'12px'} fontWeight={'600'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'}>Remarks*</Text>
                                  <Input name="remark" value={extendDateForm.remark} onChange={handleExtendDateChange} border={'1px solid grey'} size={'xs'} w={'180px'}/>
                                    </Box>
                                  </Flex>
                                  <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                                    <Button onClick={handleExtendDateSubmit} background={'#9c27b0'} color={'white'} size={'sm'}>Submit</Button>
                                  </Flex>     
                                </Box>
                           }
                        </Box>
                    ): showNonFinancialFields===true?                     
                      <Box className='NonFinancialFields'>
                      <Box fontWeight={'600'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'} justifyContent={'start'} mt={'15px'} mb={'15px'}>
                        <Flex gap={'50px'} mt={'15px'}>
                          <Box>
                            <Flex justifyContent={'space-between'}>
                            <Text>Email*</Text>
                                <Checkbox isChecked={editable.email} onChange={() => handleCheckboxChange('email')} size={'sm'}>Edit</Checkbox>
                            </Flex>
                            <Input value={formData.email} onChange={e => handleCorrectionInputChange('email', e.target.value)} border={'1px solid grey'} isDisabled={!editable.email} w={'180px'} size={'xs'}/>
                          </Box>
                          <Box>
                          <Flex justifyContent={'space-between'}>
                            <Text>City</Text>
                                <Checkbox isChecked={editable.city} onChange={() => handleCheckboxChange('city')} size={'sm'}>Edit</Checkbox>
                            </Flex>
                            <Input value={formData.city} onChange={e => handleCorrectionInputChange('city', e.target.value)} border={'1px solid grey'} isDisabled={!editable.city} w={'180px'} size={'xs'}/>
                          </Box>
                          <Box>
                          <Flex justifyContent={'space-between'}>
                            <Text>Postal Code*</Text>
                                <Checkbox isChecked={editable.postalCode} onChange={() => handleCheckboxChange('postalCode')} size={'sm'}>Edit</Checkbox>
                            </Flex>
                            <Input value={formData.postalCode} onChange={e => handleCorrectionInputChange('postalCode', e.target.value)} border={'1px solid grey'} isDisabled={!editable.postalCode} w={'180px'} size={'xs'}/>
                          </Box>
                        </Flex>
                        <Flex gap={'50px'} mt={'15px'}>
                          <Box>
                            <Flex justifyContent={'space-between'}>
                            <Text>Address*</Text>
                                <Checkbox isChecked={editable.address} onChange={() => handleCheckboxChange('address')} size={'sm'}>Edit</Checkbox>
                            </Flex>
                            <Input value={formData.address} onChange={e => handleCorrectionInputChange('address', e.target.value)}  border={'1px solid grey'} isDisabled={!editable.address} w={'180px'} size={'xs'}/>
                          </Box>
                          <Box>
                          <Flex justifyContent={'space-between'}>
                            <Text>Phone*</Text>
                                <Checkbox isChecked={editable.phone} onChange={() => handleCheckboxChange('phone')} size={'sm'}>Edit</Checkbox>
                            </Flex>
                            <Input value={formData.phone} onChange={e => handleCorrectionInputChange('phone', e.target.value)} border={'1px solid grey'} isDisabled={!editable.phone} w={'180px'} size={'xs'}/>
                          </Box>
                        </Flex>
                      </Box>
                       <Text textAlign={'left'} mt={'20px'}>Insured Benificary Details</Text>
                      {
                        formData.beneficiaryDetailsRequests.map((item,index)=>{
                       return(  
                      <Flex textAlign={'left'} fontSize={'13px'} p={'15px'} background={'#f4f4f4'} gap={'50px'} mt={'15px'} fontFamily={'Open Sans,sans-serif'}>
                          <Box>
                            <Text>Beneficiary Name *</Text>
                            <Input value={item.beneficiaryName} background={'white'} border={'1px solid grey'} w={'180px'} size={'xs'} onChange={e => handleBeneficiaryChange(index, 'beneficiaryName', e.target.value)}/>
                          </Box>
                          <Box>
                            <Text>Beneficiary Relation *</Text>
                            <Input value={item.beneficiaryRelation} background={'white'} border={'1px solid grey'} w={'180px'} size={'xs'} onChange={e => handleBeneficiaryChange(index, 'beneficiaryRelation', e.target.value)}/>
                          </Box>
                          <Box>
                            <Text>Beneficiary DOB *</Text>
                            <Input type='date' value={item.beneficiaryDOB} background={'white'} border={'1px solid grey'} w={'180px'} size={'xs'} onChange={e => handleBeneficiaryChange(index, 'beneficiaryDob', e.target.value)}/>
                          </Box>
                        </Flex>
                        )
                      })
                    }
                        <Flex justifyContent={'start'}>
                          <Box mt={'10px'} ml={'10px'}>
                          <Text fontSize={'12px'} fontWeight={'600'} fontFamily={'Open Sans,sans-serif'} textAlign={'left'}>Remarks*</Text>
                          <Input value={formData.remarks} onChange={e => handleCorrectionInputChange('remarks', e.target.value)} border={'1px solid grey'} size={'xs'} w={'180px'}/>
                          </Box>
                          </Flex>
                          <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                        <Button background={'#9c27b0'} color={'white'} size={'sm'} onClick={handleNonFinancialCorrection}>Submit</Button>
                        </Flex>
                       </Box>:
                       <Box>
                        <Text color={'teal'} fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}>Please select Correction type.</Text>
                       </Box>
                    }
                    </>
                  )})
                }
                  {/* <Flex justifyContent={'center'} mt={'35px'} mb={'30px'}>
                  <Button background={'teal'} color={'white'} size={'sm'}>Submit</Button>
                  </Flex> */}
                </Box>
              </TabPanel>


            </TabPanels>
          </Tabs>
        </Flex>
      </Box>
      ))
    }
  </>

    </Sidebar>
  )
}

export default AdminCustomerPolicyEndorsement
