import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  FormLabel,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  useTheme,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { advisorLoginList } from "../Dependencies/action";
import { storage } from "../../../dependencies/store/storage";
import { useTranslation } from "react-i18next";



const AdvisorLogin = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useDispatch();
  const theme=useTheme()
  const [isHCaptchaLoaded, setIsHCaptchaLoaded] = useState(false);
 const {t}=useTranslation()
  
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });

  const handleClick = () => setShow(!show);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

 const handleLogin = async () => {
     const { username, password } = values;
     console.log(values)
     let newErrors = { ...errors };
     if (username.length === 0) {
       newErrors.username = "Please enter valid email";
     }
     if (password.length === 0) {
       newErrors.password = "Password do not match!";
     }

    dispatch(advisorLoginList(values)).then(res => {
      const {status, data} = res
      if(status === 200) {
        storage.setUser(data)
        navigate("/advisor-profile");
        // window.location.reload();
      }
    })
  }

  return (
    <Box m="auto">
      <Flex direction={["column", "column", "row", "row"]} pt={"60px"}>
        <Box
          w={["95%", "95%", "50%", "50%"]}
          p={"70px"}
          h={"100vh"}
          display={["none", "none", "block"]}
          color={"white"}
          background={"#272939"}
          backgroundImage={
            "https://travelmedicare.com/public/users/images/bg-02.jpg"
          }
          backgroundSize={"cover"}
          backgroundPosition={"center"}
        >
          {/* <Image src='https://experiortravel.com/public/users/images/bg-02.jpg'/> */}
        </Box>
        <Box
          p={5}
          w={["99%", "95%", "50%", "50%"]}
          pt={"40px"}
          textAlign={"center"}
        >
          <Flex justifyContent={"center"}>
            <Image
              w={"130px"}
              src={"https://travelmedicare.com/public/users/images/logo.png"}
            />
          </Flex>
          <Text
            fontFamily={"poppins"}
            mt={["30px", "1px", "10px", "30px"]}
            pb={["20px", "20px", "80px", "20px"]}
          >
            {t('Advisor Sign In')}
          </Text>
          <FormControl isRequired w={["90%","70%","70%"]} m={"auto"}>
            <FormLabel>{t('Email')}</FormLabel>
            <Input
              type="email"
              placeholder="Enter email"
              name="username"
              onChange={handleChange}
            />
            <Text color="red.500">{errors.username}</Text>
            <FormLabel pt={"10px"}>{t('Password')}</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                placeholder="Enter password"
                name="password"
                onChange={handleChange}
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? (t("Hide")) : (t("Show"))}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text color="red.500">{errors.password}</Text>
            <Link to='/advisor-forget-password'>
            <Text
              textAlign={"left"}
              fontSize={"14px"}
              color={"blue"}
              mt={"5px"}
            >
              {t('Forgot Password?')}
            </Text>
            </Link>
            <Button
              mt={4}
              width="100%"
              onClick={handleLogin}
              backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}}
            >
              {t('Login')}
            </Button>
          </FormControl>
          <Text mt={"35px"}>{t('Dont have an account?')}</Text>
          <Flex justifyContent={"center"} mt={"15px"}>
            <Link to="/advisorRegister">
              <Button backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}}>
                {t('Register Here')}
              </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default AdvisorLogin;
