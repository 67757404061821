import { ADMIN_PAGES_EDIT_BYID, ADMIN_PAGES_GET_BY_ID, ALL_PAGES_CONTENT_DETAILS } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminPagesService = {
    adminPagesEdit(id,params) {
        return Api.PUTDATA(`${ADMIN_PAGES_EDIT_BYID}/${id}`,params).then((response) => {
            console.log("advisorlogin",response)
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    adminPagesAll() {
        return Api.GET(ALL_PAGES_CONTENT_DETAILS).then((response) => {
            // console.log(response,'responserviceadvisor')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    adminPagesById(id, params) {
        return Api.GET(`${ADMIN_PAGES_GET_BY_ID}/${id}`, params).then((response) => {
            console.log(response,'response')
            const { data, status } = response;
            if (status === 200) {
                return { data, status };
            } else {
                const { data: { message } = {} } = response;
                return { message, status };
            }
        });
    }
}