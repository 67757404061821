import React from 'react'
import SidebarServices from '../../SidebarServices'

const MyTheme = () => {



return (
    <SidebarServices headingText={'My Theme'}>
      
    </SidebarServices>
  )
}

export default MyTheme
