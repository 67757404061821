import { ADMIN_RENEWAL_POLICES_LIST_TYPE, isLoading } from "../../../../../constants/actionConstants";
import { AdminRenewalPolicyService } from "./service";


export const adminRenewalListAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminRenewalPolicyService.adminRenewalListAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_RENEWAL_POLICES_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };