import { toast } from "react-toastify";
import { AsingMgaToAgsService } from "./service";
import { ALL_MGA_TYPE_LIST, MGA_TO_AGA_TYPE, isLoading } from "../../../../constants/actionConstants";

export const allMgaGet = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AsingMgaToAgsService.allMgaGet(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ALL_MGA_TYPE_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

export const assingAgaToMga = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminProfile = await AsingMgaToAgsService.assingAgaToMga(params);
    const {status, message, data} = adminProfile

    if(status === 200 || 201) {
        dispatch({
            type: MGA_TO_AGA_TYPE,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }
dispatch(isLoading(false));

    return adminProfile

}