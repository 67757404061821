import { ADMIN_COMMISION_STATUS, COMMISION_LIST, COMMISION_SETTINGS_EDIT, COMMISION_SETTINGS_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminCommisionService = {

    adminCommisionList() {
        return Api.GET(COMMISION_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    adminCommisionSettingsList() {
        return Api.GET(COMMISION_SETTINGS_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    adminCommisionSettingsEdit(id,params) {
        return Api.PUT(`${COMMISION_SETTINGS_EDIT}/${id}`,params).then((response) => {
            console.log(response,'ANUSER')
            const { data, status} = response
            if(status === 200 ||201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    adminCommisionStatus(params) {
        return Api.POST(ADMIN_COMMISION_STATUS, params).then((response) => {
            console.log("advisorlogin",response)
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    
}