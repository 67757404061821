import { Box, Button, Flex, Grid, GridItem, Text, Modal, ModalOverlay, ModalContent, ModalHeader,ModalBody, ModalCloseButton, useDisclosure, Input, Textarea, } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../Utils/UniversalColor'
import { Link, useNavigate } from 'react-router-dom'
import { CREATE_TICKET_SUPPORT } from '../../../constants/ApplicationUrl'
import Api from '../../../dependencies/utils/Api'
import { toast } from 'react-toastify';
import { supporTicketAllList } from '../dependencies/action'
import { useDispatch } from 'react-redux'





const AllTickets = () => {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { id } = JSON.parse(localStorage.getItem("code_dev"))
    const [formData, setFormData] = useState({});
    const [ticketData, setTicketData] = useState([])
    const dispatch = useDispatch()

    const handleNavigate = (referenceNo) => {
        navigate(`/view-ticket/${referenceNo}`);
    }

    const handleSubmit = () => {
        const { subject, description, policyNumber, quoteNumber, files } = formData;        
        const formDataToSend = new FormData();
        formDataToSend.append('subject', subject);
        formDataToSend.append('description', description);
        formDataToSend.append('policyNo', policyNumber);
        formDataToSend.append('quoteNo', quoteNumber);
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
              formDataToSend.append('file', files[i]);
            }
          }
        const { id } = JSON.parse(localStorage.getItem("code_dev"));
        formDataToSend.append('userId', id);
        setTimeout(() => {
            Api.POSTDATA(`${CREATE_TICKET_SUPPORT}`, formDataToSend).then((res) => {
                console.log(res);
                if(res.status===200 || res.status===201){
                    toast.success("Ticket created successfully!");
                    onClose()
                    getAllTickets()
                }else{
                    alert('Something went wrong.')
                }
            });
        }, 2000);
    };

    useEffect(() => {
        getAllTickets()
    }, [dispatch]);

    const getAllTickets=()=>{
        dispatch(supporTicketAllList())
            .then((res) => {
                console.log("Admin profile data fetched successfully", res);
                setTicketData(res)
                console.log("teamdara", res)})
            .catch((error) => {
                console.error("Error fetching admin profile data:", error);
            });
    }
    const handleFileChange = (event) => {
        setFormData({ ...formData, files: event.target.files });
    };

    return (
        <Box pb={'120px'}>
            <Box pt={['120px','80px','80px']} background={'rgb(247, 247, 247)'}>
                <Box w={'90%'} m={'auto'} pb={'10px'}>
                    <Link to='/'>
                        <Text color={'blue'} fontFamily={'poppins'}>Home</Text>
                    </Link>
                    <Flex direction={['column','column','row']} justifyContent={'space-between'}>
                        <Text fontSize={['22px','26px','26px']}>Your Tickets Dashboard</Text>
                        <Flex mt={['10px','10px','0px']}>
                        <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{ background: buttonHoverBackground, color: buttonHoverColor }} onClick={onOpen}>Create Ticket</Button>
                        </Flex>
                    </Flex>
                </Box>
            </Box>
            <hr />
            <Box w={'90%'} m={'auto'} mt={'60px'}>
                <Grid templateColumns={['repeat(1, 1fr)','repeat(2, 1fr)','repeat(3, 1fr)']} gap={'40px'}>
                    {ticketData.map((ticket) => (
                        <GridItem key={ticket.id} borderRadius={'5px'} _hover={{ background: '#f6f6f6' }} cursor={'pointer'}onClick={() => handleNavigate(ticket.referenceNo)}>
                            <Box border={'1px solid rgb(221, 221, 221)'} p={'15px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
                                <Flex justifyContent={'space-between'}>
                                    <Text color={'blue'} fontSize={'12px'} fontFamily={'Montserrat, Sans-serif'}>{ticket.referenceNo}</Text>
                                    <Button size={'xs'} background={'#34AEBB'} color={'white'}>
                                        {ticket.status === 1 ? 'Open' : ticket.status === 2 ? 'In Progress' : 'Resolved'}
                                    </Button>
                                </Flex>
                                <Text mt={'15px'} fontWeight={'600'}>{ticket.title}</Text>
                                <Flex justifyContent={'space-between'} mt={'10px'} fontFamily={'Montserrat, Sans-serif'}>
                                    <Text>Issue <br /> <span style={{ fontSize: '13px' }}>{ticket.subject}</span></Text>
                                    <Text fontSize={'14px'}>Created At <br /> <span style={{ fontSize: '13px' }}>{ticket.createdAt}</span></Text>
                                </Flex>
                                <Text mt={'10px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>Desc <br /> <span style={{ fontSize: '13px' }}>{ticket.description}</span></Text>
                            </Box>
                        </GridItem>
                    ))}
                </Grid>
            </Box>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontFamily={'Montserrat, sans-serif'} fontSize={'16px'}>Fill Ticket Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    </ModalBody>
                    <Box w={'80%'} m={'auto'} pb={'15px'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'}>
                        <Text>Subject <span style={{ color: 'red' }}>*</span></Text>
                        <Input size={'sm'} onChange={(e) => setFormData({ ...formData, subject: e.target.value })} />
                        <Text mt={'7px'}>Description <span style={{ color: 'red' }}>*</span></Text>
                        <Textarea size={'sm'} onChange={(e) => setFormData({ ...formData, description: e.target.value })}></Textarea>
                        <Text mt={'7px'}>Policy Number</Text>
                        <Input size={'sm'} onChange={(e) => setFormData({ ...formData, policyNumber: e.target.value })} />
                        <Text mt={'7px'}>Quote Number</Text>
                        <Input size={'sm'} onChange={(e) => setFormData({ ...formData, quoteNumber: e.target.value })} />
                        <Text mt={'7px'}>Files <span style={{ color: 'red' }}>*</span></Text>
                        <input type="file" onChange={handleFileChange} multiple />
                        <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} mt={3} onClick={handleSubmit}> Submit</Button>
                    </Box>
                    {/* <ModalFooter>
                        <Button size={'sm'} colorScheme='blue' mr={3}> Submit</Button>
                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default AllTickets
