import React, { useEffect, useState } from 'react';
import { primaryColor } from '../../../Utils/UniversalColor';
import { Flex, Box, Text, Input, Textarea, Toast, Button, Checkbox, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { advisorLicneseDetailsEdit, advisorPersonalDetails, advisorProfile, advisorRefrence } from '../Dependencies/action';
import { isLoading } from '../../../constants/actionConstants';
import { toast } from 'react-toastify';
import axios from 'axios';
import { ADVISOR_CORPORATION_DETAILS, ADVISOR_LICENCE_DETAILS } from '../../../constants/ApplicationUrl';

const AdvisorUnapprovedProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [referenceData, setReferenceData] = useState();
  const [commonData, setCommonData] = useState("")
  const dispatch = useDispatch();
  const [formData, setFormData] = useState("")

  useEffect(() => {
    dispatch(advisorProfile())
      .then((res) => {
        setCommonData(res);
        setFormData({
          id: id,
          firstName: res?.firstName || '',
          lastName: res?.lastName || '',
          email: res?.email || '',
          phone: res?.phone || '',
          address: res?.address || '',
          website: res?.website || ""
        });
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);

      });
  }, [dispatch]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveData = (data) => {
    switch (activeTab) {
      case 'personal':
        setPersonalData(data);
        break;
      case 'license':
        setLicenseData(data);
        break;
      case 'corporation':
        setCorporationData(data);
        break;
      case 'reference':
        setReferenceData(data);
        break;
      default:
        break;
    }
  };


  const renderForm = () => {
    switch (activeTab) {
      case 'personal':
        return <PersonalDetailsForm activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'license':
        return <LicenseDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'corporation':
        return <CorporationDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'reference':
        return <ReferenceDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      default:
        return null;
    }
  };

  return (
    <>
        <Box paddingTop={'120px'}>
          <Flex w={'80%'} m={'auto'}> 
            <Box w={'20%'} m={'auto'} h={'520px'}>
              <Flex direction={'column'} gap={'12px'} className="tablist">
                <button style={{border:'1px solid grey', width: "200px", backgroundColor: activeTab === 'personal' ? "teal" : "#eee", color: activeTab === 'personal' ? '#ffffff' : 'black', padding: '10px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('personal')}>1. Personal Details</button>

                <button style={{ width: "200px", backgroundColor: activeTab === 'license' ? "teal" : "#eee", color: activeTab === 'license' ? '#ffffff' : 'black', padding: '10px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('license')}>2. License Details</button>
                <button style={{ width: "200px", backgroundColor: activeTab === 'corporation' ? "teal" : "#eee", color: activeTab === 'corporation' ? '#ffffff' : 'black', padding: '10px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('corporation')}>3. Corporation Details</button>
                <button style={{ width: "200px", backgroundColor: activeTab === 'reference' ? "teal" : "#eee", color: activeTab === 'reference' ? '#ffffff' : 'black', padding: '10px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('reference')}>4. Reference Details</button>
              </Flex>
            </Box>
            <Box w={'70%'}>
            {renderForm()}
          <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
            </Box>
          </Flex>
        </Box>
    </>
  );
};

const Tab = ({ active, onClick, children }) => {
  return (
    <button className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'personal':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'license':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'corporation':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    case 'reference':
      return <pre>{JSON.stringify(data.referenceData, null, 2)}</pre>;
    default:
      return null;
  }
};

const PersonalDetailsForm = ({ onSave, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  const [profileData, setProfileData] = useState("")
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: '',
    fax: '',
  });
  useEffect(() => {
    dispatch(advisorProfile())
      .then((res) => {
        setProfileData(res);
        setFormData({
          id: id,
          firstName: res?.firstName || '',
          lastName: res?.lastName || '',
          email: res?.email || '',
          phone: res?.phone || '',
          address: res?.address || '',
          website: res?.website || ""
        });
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);

      });
  }, [dispatch]);

  const [formData, setFormData] = useState({
    id: id,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: "",
    fax: "",
  });
  const [emailError, setEmailError] = useState('');
  const handleSave = () => {
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      console.error("Form has errors. Cannot save.");
      return;
    }
    dispatch(advisorPersonalDetails(formData))
      .then((res) => {
        console.log("Advisor personal details updated successfully", res);
        onSave(formData); // Optionally, execute onSave callback
        setActiveTab('license');
      })
      .catch((error) => {
        console.error("Error updating advisor personal details:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'email') {
      const isValidEmail = validateEmail(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValidEmail ? '' : 'Invalid email format',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value ? '' : 'This field is required',
      }));
    }
  };


  const validateEmail = (email) => {
    // Basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'100%'} m={'auto'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Personal Details</Text>
      <hr />
      <Flex mt={'15px'} fontSize={'13px'} justifyContent={'space-around'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>First Name <span style={{color:'red'}}>*</span></Text>
          <Input size={'sm'} name="firstName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.firstName} onChange={handleInputChange} placeholder='First Name' />
          {errors.firstName && <Text color="red">{errors.firstName}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Last Name <span style={{color:'red'}}>*</span></Text>
          <Input size={'sm'} name="lastName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.lastName}
            onChange={handleInputChange} placeholder='Last Name' />
          {errors.lastName && <Text color="red">{errors.lastName}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Email <span style={{color:'red'}}>*</span></Text>
          <Input size={'sm'} name="email" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.email} readOnly
            onChange={handleInputChange} placeholder='Email' />
        </Box>
      </Flex>
      <Flex justifyContent={'space-around'} mt={'20px'} fontSize={'13px'}  fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Website</Text>
          <Input size={'sm'} name="website" value={formData.website} backgroundColor={'#f1f1f1'} color={'grey'} onChange={handleInputChange} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>Phone <span style={{color:'red'}}>*</span></Text>
          <Input size={'sm'} backgroundColor={'#f1f1f1'} color={'grey'} name="phone" value={formData.phone}
            onChange={handleInputChange} placeholder='Phone Number' />
          {errors.phone && <Text color="red">{errors.phone}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'}>Fax</Text>
          <Input size={'sm'} name="fax" onChange={handleInputChange} value={formData.fax} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Flex pl={'40px'} mt={'20px'} fontSize={'13px'}  fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Address <span style={{color:'red'}}>*</span></Text>
          <Textarea size={'sm'} name="address" onChange={handleInputChange} value={formData.address} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
          {errors.address && <Text color="red">{errors.address}</Text>}
        </Box>
      </Flex>
      <Flex justifyContent={'end'}>
      <Button onClick={handleSave} size={'sm'} background={'teal'} color={'white'}>Save Personal Details</Button>
      </Flex>
    </Box>
  );
};


const LicenseDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  const { advisorProfile } = useSelector((state) => state.advisorProfile);
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    licenceNo: commonData.licenceNo || '',
    eoPolicyNo: commonData.eopolicyNo || '',
    licenceExpiry: commonData.licenceExpiryDate || '',
    eoPolicyExpiry: commonData.eopolicyExpiryDate || '',
    eoDocUpload: null,
    providerDocUpload: null
  });


  const handleSave = async () => {
    dispatch(isLoading(true));
    try {
      const formData = new FormData();
      Object.keys(licenseDetails).forEach((key) => {
        if (key !== 'eoDocUpload' && key !== 'providerDocUpload') {
          formData.append(key, licenseDetails[key]);
        }
      });
      formData.append('eoDocUpload', licenseDetails.eoDocUpload);
      formData.append('providerDocUpload', licenseDetails.providerDocUpload);
      const response = await axios.post(`${ADVISOR_LICENCE_DETAILS}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("License details updated successfully");
        setActiveTab("corporation")
        // Reset form or do any other necessary actions after success
      } else {
        toast.error("Failed to update license details");
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while updating license details");
    }

    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file,
      }));
    } else {
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleDownload = (fileObject) => {
    if (!fileObject || !fileObject.file) {
      console.error("File object is invalid");
      return;
    }
    const blobUrl = URL.createObjectURL(fileObject.file);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileObject.file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  };

return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'100%'} m={'auto'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>License Details</Text>
      <hr />
      <Flex mt={'15px'} fontSize={'13px'} justifyContent={'space-around'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>License No. *</Text>
          <Input size={'sm'} name="licenceNo" value={licenseDetails.licenceNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter License No.' />
        </Box>
        <Box>
          <Text mb={'8px'}>License Expiry Date *</Text>
          <Input size={'sm'} name="licenceExpiry" value={licenseDetails.licenceExpiry} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>E&O Policy No. *</Text>
          <Input size={'sm'} name="eoPolicyNo" value={licenseDetails.eoPolicyNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter EO Policy No.' />
        </Box>
        <Box>
          <Text mb={'8px'}>E&O Policy Exp. Date *</Text>
          <Input size={'sm'} name="eoPolicyExpiry" value={licenseDetails.eoPolicyExpiry} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Box mt={'20px'} pl={'20px'}>
        <Text fontWeight="bold" fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>Uploaded PDF Files:</Text>
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr fontFamily={'Open Sans,sans-serif'}>
              <Th fontFamily={'Open Sans,sans-serif'}>Serial No.</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>PDF</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>Action</Th>
              <Th fontFamily={'Open Sans,sans-serif'}>Created Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {commonData && (
              <>
                <Tr>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}>1</Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.provider}</Td>
                  <Td fontFamily={'Open Sans,sans-serif'}>
                    <Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.provider)}>Download</Button></Td>
                </Tr>
                <Tr>
                  <Td>2</Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} color={"black"}>{commonData.eoInsurance}</Td>
                  <Td fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.eoInsurance)}>Download</Button></Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent={'end'} mt={'10px'}>
      <Button size={'sm'} background={'teal'} color={'white'} onClick={handleSave}>Save License Details</Button>
      </Flex>
    </Box>
  );
};

const CorporationDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  console.log("corp", commonData)
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    corpName: commonData.corporationName || '',
    eoCorpName: commonData.eocorporationName || '',
    corpEOPolicyExpiryDate: commonData.corporationeoPolicyexpiryDate || '',
    corpLicenceExpiryDate: commonData.corporationLicenceExpirydate || '',
    binNoOfCorp: commonData.binnoofCorporation || '',
    firm: commonData.firm || false,
    corpLicenceDoc: null, 
    corpPaperDoc: null
  });

  const handleSave = async () => {
    dispatch(isLoading(true));
    try {
      const formData = new FormData();
      Object.keys(licenseDetails).forEach((key) => {
        if (key !== 'corpLicenceDoc' && key !== 'corpPaperDoc') {
          formData.append(key, licenseDetails[key]);
        }
      });
      formData.append('corpLicenceDoc', licenseDetails.corpLicenceDoc);
      formData.append('corpPaperDoc', licenseDetails.corpPaperDoc);
      const response = await axios.post(`${ADVISOR_CORPORATION_DETAILS}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("License details updated successfully");
        setActiveTab("reference")
      } else {
        toast.error("Failed to update license details");
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while updating license details");
    }

    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;

    if (files && files.length > 0) {
      // If files are present, update state with the file object
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file, // Store the file object itself
        // [`${name}`]: file.name, // Store the file name
      }));
    } else {
      // If no files, update state with input value
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setLicenseDetails((prevDetails) => ({
      ...prevDetails,
      firm: checked, // Set firm value to true if checked, false otherwise
    }));
  };
  
  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Corporation Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box mt={'10px'} pl={'20px'}>
          <Text mb={'8px'}>Firm/Corporation/Prop *</Text>
          <input type="checkbox" name="firm" checked={licenseDetails.firm} onChange={handleCheckboxChange} />
        </Box>
        <Box>
          <Text mb={'8px'}>E&O updated with Corporation name *</Text>
          <Input size={'sm'} name="eoCorpName" value={licenseDetails.eoCorpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>Corporation E&O Expiry Date *</Text>
          <Input size={'sm'} name="corpEOPolicyExpiryDate" value={licenseDetails.corpEOPolicyExpiryDate} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com' />
        </Box>
      </Flex>
      <Flex justifyContent={'space-around'} mt={'20px'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Box>
          <Text mb={'8px'}>Corporation Name *</Text>
          <Input size={'sm'} name="corpName" value={licenseDetails.corpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'}>BIN No of the Corporation *</Text>
          <Input size={'sm'} name="binNoOfCorp" value={licenseDetails.binNoOfCorp} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='+91 5254655682' />
        </Box>
        <Box>
          <Text mb={'8px'}>Date Of License Expiry *</Text>
          <Input size={'sm'} name="corpLicenceExpiryDate" value={licenseDetails.corpLicenceExpiryDate} onChange={handleChange} type="date" backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Box mt={'30px'} pl={'20px'} w={'90%'} m={'auto'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
        <Text fontWeight="bold" mt={'30px'}>Uploaded PDF Files:</Text>
        <Table variant="simple" mt={2}>
          <Thead>
            <Tr>
              <Th>Serial No.</Th>
              <Th>PDF</Th>
              <Th>Action</Th>
              <Th>Created Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {commonData && (
              <>
                <Tr>
                  <Td>1</Td>
                  <Td color={"black"}>{commonData.corporationLicenceDoc}</Td>
                  <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.corporationLicenceDoc)}>Download</Button></Td>
                </Tr>
                <Tr>
                  <Td>2</Td>
                  <Td color={"black"}>{commonData.corporationPaperDoc}</Td>
                  <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.corporationPaperDoc)}>Download</Button></Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Box>
      <Flex justifyContent={'end'} mt={'10px'}>
      <Button size={'sm'} background={'teal'} color={'white'} onClick={handleSave}>Save Corporation Details</Button>
      </Flex>
    </Box>
  );
};

const ReferenceDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  console.log("token,user", token, id)
  const dispatch = useDispatch();
  const [referenceDetails, setReferenceDetails] = useState({
    reference_name_1: commonData?.refrenceDetails.reference_name_1 || "",
    reference_email_1: commonData?.refrenceDetails.reference_email_1 || "",
    reference_contact_1: commonData?.refrenceDetails.reference_contact_1 || "",
    reference_address_1: commonData?.refrenceDetails.reference_address_1 || "",
    reference_name_2: commonData?.refrenceDetails.reference_name_2 || "",
    reference_email_2: commonData?.refrenceDetails.reference_email_2 || "",
    reference_contact_2: commonData?.refrenceDetails.reference_contact_2 || "",
    reference_address_2: commonData?.refrenceDetails.reference_address_2 || "",
    reference_name_3: commonData?.refrenceDetails.reference_name_3 || "",
    reference_email_3: commonData?.refrenceDetails.reference_email_3 || "",
    reference_contact_3: commonData?.refrenceDetails.reference_contact_3 || "",
    reference_address_3: commonData?.refrenceDetails.reference_address_3 || "",
  });;
  const handleSave = () => {
    const requestData = {
      idUser: id,
      reference_name_1: referenceDetails.reference_name_1,
      reference_email_1: referenceDetails.reference_email_1,
      reference_contact_1: referenceDetails.reference_contact_1,
      reference_address_1: referenceDetails.reference_address_1,


      reference_name_2: referenceDetails.reference_name_2,
      reference_email_2: referenceDetails.reference_email_2,
      reference_contact_2: referenceDetails.reference_contact_2,
      reference_address_2: referenceDetails.reference_address_2,


      reference_name_3: referenceDetails.reference_name_3,
      reference_email_3: referenceDetails.reference_email_3,
      reference_contact_3: referenceDetails.reference_contact_3,
      reference_address_3: referenceDetails.reference_address_3,


    };

    // Dispatch the action to post the data
    dispatch(advisorRefrence(requestData));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReferenceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'}>
      <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Reference Details</Text>
      <hr />
      <Flex flexDirection="column" fontSize={'12px'} color={'grey'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'}>
        {[1, 2, 3].map((index) => (
          <Flex key={index} mt={'25px'} justifyContent={'space-around'}>
            <Box>
              <Text mb={'8px'}>{`Reference Name ${index}`}</Text>
              <Input size={'sm'} name={`reference_name_${index}`} value={referenceDetails[`reference_name_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Email ${index}`}</Text>
              <Input size={'sm'} name={`reference_email_${index}`} value={referenceDetails[`reference_email_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Contact ${index}`}</Text>
              <Input size={'sm'} name={`reference_contact_${index}`} value={referenceDetails[`reference_contact_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Address ${index}`}</Text>
              <Input size={'sm'} name={`reference_address_${index}`} value={referenceDetails[`reference_address_${index}`]} onChange={handleChange} />
            </Box>
          </Flex>
        ))}
      </Flex>
      <Flex mt={'15px'} justifyContent={'end'}>
      <Button size={'sm'} background={'teal'} color={'white'} onClick={handleSave}>Save Reference Details</Button>
      </Flex>
    </Box>
  );
};

export default AdvisorUnapprovedProfile;
