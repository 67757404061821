import { toast } from "react-toastify";

import { ADVISOR_GENERATEQUOTE, ADVISOR_LOGIN, ADVISOR_PROFILE, ADVISOR_SIGNUP, CUSTOMER_LOGIN,CUSTOMER_SIGNUP, isLoading,ADVISOR_CALCULATEVTC, ADVISOR_GETQUOTES, ADVISOR_PROFILE_LIST, ADVISOR_SINGLE_QUOTE, CUSTOMER_CALCULATEVTC, ADVISOR_REFERNCE_DETAILS_TYPE, ADVISOR_GETPOLICIES_TYPE, ADVISOR_VOID_POLICY, ADVISOR_CANCEL_POLICY, ADVISOR_CORRECTION_POLICY, ADVISOR_CORRECTION_POLICY_EXTEND, ADVISOR_DELETE_QUOTE, ADVISOR_UPDATE_QUOTE, ADVISOR_SAVE_POLICY, ADVISOR_CORRECTION_POLICY_NON_FINANCIAL, ADVISOR_RENEWAL_POLICES_LIST_TYPE, FORGET_PASSWORD_TYPE } from "../../../constants/actionConstants";

import { AdvisorLoginService, AdvisorSignupService, CustomerLoginService, CustomerSignupService} from "./service";

export const advisorSignupList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try{
    let res = await AdvisorSignupService.advisorSignupList(params);
    const {status, data} = res
    if(status===200 || status===201){
        dispatch({
            type: ADVISOR_SIGNUP,
            payload: data,
            });
            toast('Advisor Created Successfully.')
        }else{
            alert(data.message)
            }
            dispatch(isLoading(false));
    return status
}catch(err){
    console.log(err,'err')
}
}

export const advisorLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdvisorLoginService.advisorLoginList(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: ADVISOR_LOGIN,
            payload: data
        })
        toast.success("Advisor Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}

export const advisorProfile = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdvisorLoginService.advisorProfile(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISOR_PROFILE_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

export const advisorGenerateQuote = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let generateQuote = await AdvisorLoginService.advisorGenerateQuote(params);
    const {status, message, data} = generateQuote
    console.log(generateQuote,'quote genertated')
    if(status === 200) {
        dispatch({
            type: ADVISOR_GENERATEQUOTE,
            payload: message,data
        })
        toast.success("Quote Generated Successfully", {
            position: toast.POSITION.BOTTOM_LEFT
          });
    }else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return generateQuote
}

export const advisorGetQuotes = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getQuotes = await AdvisorLoginService.advisorGetQuotes(params);
    const {status, message, data} = getQuotes
    if(status === 200) {
        dispatch({
            type: ADVISOR_GETQUOTES,
            payload: message,data
        })
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return getQuotes
}

export const advisorSingleGetQuotes = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getQuotes = await AdvisorLoginService.advisorSingleGetQuotes(id, params); 
    const { status, message, data } = getQuotes;

    dispatch({
        type: ADVISOR_SINGLE_QUOTE,
        payload: { message, data }
    });

    dispatch(isLoading(false));

    return getQuotes;
}

export const advisorCalculateVtc = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let calculateVtc = await AdvisorLoginService.advisorCalculateVtc(params);
    const {status, message, data} = calculateVtc
    console.log(calculateVtc)
    if(status === 200) {
        dispatch({
            type: ADVISOR_CALCULATEVTC,
            payload: message,data
        })
        toast.success("Premium calculated pls check", {
            position: "bottom-left"
         });
    }else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return calculateVtc
}

export const advisorRefrence = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let licenseDetails = await AdvisorLoginService.advisorRefrence(params);
    const {status, message, data} = licenseDetails
    if(status === 200) {
        dispatch({
            type: ADVISOR_REFERNCE_DETAILS_TYPE,
            payload: message,data
        })
        toast.success(" Successfully Saved Details")
    }else {
        toast(message)
    }
    dispatch(isLoading(false));
    return licenseDetails
}

export const advisorPersonalDetails = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try{
        let licenseDetails = await AdvisorLoginService.advisorPersonalDetails(params);
        const {status, message, data} = licenseDetails
        if(status === 200) {
            dispatch({
                type: AVSIOR_PERSONAL_DETAILS_EDIT_TYPE,
                payload: message,data
            })
            toast.success(" Successfully")
        }else {
            toast.success(message)
        }
      dispatch(isLoading(false));
        return licenseDetails
    }
    catch{
        console.error(error);
        dispatch(isLoading(false));
        throw error;
    }
   

}

export const customerCalculateVtc = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let calculateVtc = await AdvisorLoginService.customerCalculateVtc(params);
    const {status, message, data} = calculateVtc
    if(status === 200) {
        dispatch({
            type: CUSTOMER_CALCULATEVTC,
            payload: message,data
        })
        toast.success("Premium calculated pls check", {
             position: "bottom-left"
          });
        // toast.success("Premium calculated pls check")
    }else {
        toast.error(message, {
            position: "bottom-left"
         });
    }
    dispatch(isLoading(false));
    return calculateVtc

}

export const advisorGetPolicyDetails = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let getPolicies = await AdvisorLoginService.advisorGetPolicyDetails(id);
    const {status, message, data} = getPolicies
    if(status === 200) {
        dispatch({
            type: ADVISOR_GETPOLICIES_TYPE,
            payload: message,data
        })
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return getPolicies
}

export const advisorHandleVoid = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let VoidPolicy = await AdvisorLoginService.advisorHandleVoid(id);
    const {status, message, data} = VoidPolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_VOID_POLICY,
            payload: message,data
        })
        toast.success(data)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return VoidPolicy
}


export const advisorHandleCancellation = (id) => async (dispatch) => {
    dispatch(isLoading(true));
    let CancelPolicy = await AdvisorLoginService.advisorHandleCancellation(id);
    const {status, message, data} = CancelPolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_CANCEL_POLICY,
            payload: message,data
        })
        toast.success(data)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return CancelPolicy
}


export const advisorHandleCorrectionInsuredDetails = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let CorrectionPolicy = await AdvisorLoginService.advisorHandleCorrectionInsuredDetails(params);
    const {status, message, data} = CorrectionPolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_CORRECTION_POLICY,
            payload: message,data
        })
        toast.success(data)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return CorrectionPolicy
}

export const advisorHandleCorrectionNonFinancial = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let CorrectionPolicy = await AdvisorLoginService.advisorHandleCorrectionNonFinancial(params);
    const {status, message, data} = CorrectionPolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_CORRECTION_POLICY_NON_FINANCIAL,
            payload: message,data
        })
        toast.success(data)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return CorrectionPolicy
}

export const advisorHandleCorrectionExtendDate = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let CorrectionPolicy = await AdvisorLoginService.advisorHandleCorrectionExtendDate(params);
    const {status, message, data} = CorrectionPolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_CORRECTION_POLICY_EXTEND,
            payload: message,data
        })
        toast.success(data)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return CorrectionPolicy
}


export const advisorDeleteQuote = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let DeleteQuote = await AdvisorLoginService.advisorDeleteQuote(params);
    const {status, message, data} = DeleteQuote
    if(status === 200) {
        dispatch({
            type: ADVISOR_DELETE_QUOTE,
            payload: message,data
        })
        toast.success('Quote Deleted Successfully.')
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return DeleteQuote
}

export const advisorUpdateQuote = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let UpdateQuote = await AdvisorLoginService.advisorUpdateQuote(params);
    const {status, message, data} = UpdateQuote
    if(status === 200) {
        dispatch({
            type: ADVISOR_UPDATE_QUOTE,
            payload: message,data
        })
        // toast.success(data.message)
    }else {
        toast.error('Something went wrong')
    }
    dispatch(isLoading(false));
    return UpdateQuote
}

export const advisorSavePolicy = (params) => async   (dispatch) => {
    dispatch(isLoading(true));
    let SavePolicy = await AdvisorLoginService.advisorSavePolicy(params);
    const {status, message, data} = SavePolicy
    if(status === 200) {
        dispatch({
            type: ADVISOR_SAVE_POLICY,
            payload: message,data
        })
        toast.success(data.message)
    }else {
        toast.error(message)
    }
    dispatch(isLoading(false));
    return SavePolicy
}


export const advisorRenewalListAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdvisorLoginService.advisorRenewalListAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISOR_RENEWAL_POLICES_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };




  export const advisorClaimReport = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdvisorLoginService.advisorClaimReport(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: ADVISOR_LOGIN,
            payload: data
        })
        toast.success("Advisor Claim Submitted Succesfully")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}


export const advisorLoginForgetPassword = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdvisorLoginService.advisorLoginForgetPassword(params);
    const {status, message, data} = loginList
    if(status === 200) {
        dispatch({
            type: FORGET_PASSWORD_TYPE,
            payload: data
        })
        toast.success("Reset Link Has Been Sent To Your Mail, Thanks")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}