import { CheckCircleIcon } from "@chakra-ui/icons";
import { Button, Input, Select } from "@chakra-ui/react";
import React, { useState } from "react";

function Plan() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [navigateToContactPage, setNavigateToContactPage] = useState(false);
  const [generateQuote, setGenerateQuote] = useState(false);

  const handleSelect = (plan) => {
    setSelectedPlan(plan);
  };
  const handleToContactDetails = () => {
    setNavigateToContactPage(true);
  };
  const handleBackToPlan = () => {
    setNavigateToContactPage(false);
  };
  const GenerateQuoteEvent = () => {
    setGenerateQuote(true);
  };

  return (
    <>
      {!navigateToContactPage && !generateQuote && (
        <div
          style={{
            margin: "auto",
            width: "80%",
            maxHeight: "200%",
            padding: "18px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 400,
              marginBottom: "20px",
              borderBottom: "3px solid #088183",
            }}
          >
            Select Plan
          </p>
          <div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "white",
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "8px",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor:
                    selectedPlan === "STANDARD" ? "#24b6b7" : "white",
                  color: "white",
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "14px",
                  borderRight: "2px solid white",
                  border:
                    selectedPlan === "STANDARD"
                      ? "1px solid #24b6b7"
                      : "1px solid black",
                }}
              >
                <p
                  style={{
                    paddingBottom: "6px",
                    fontSize: "24px",
                    color: selectedPlan === "STANDARD" ? "white" : "black",
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                >
                  STANDARD
                </p>
                <p
                  style={{
                    paddingBottom: "6px",
                    color: selectedPlan === "STANDARD" ? "white" : "black",
                    fontSize: "24px",
                    fontFamily: "poppins",
                    fontWeight: 500,
                  }}
                >
                  3008.00 CAD
                </p>
                <Button
                  onClick={() => handleSelect("STANDARD")}
                  style={{
                    backgroundColor: "#11a09b",
                    borderRadius: "24px",
                    padding: "5px 20px",
                    color: "white",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {selectedPlan === "STANDARD" ? "SELECTED" : "SELECT"}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor:
                    selectedPlan === "ENHANCED" ? "#24b6b7" : "white",
                  color: "white",
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "14px",
                  border:
                    selectedPlan === "ENHANCED"
                      ? "1px solid #24b6b7"
                      : "1px solid black",
                  borderRight: "2px solid white",
                }}
              >
                <p
                  style={{
                    paddingBottom: "6px",
                    fontSize: "24px",
                    color: selectedPlan === "ENHANCED" ? "white" : "black",
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                >
                  ENHANCED
                </p>
                <p
                  style={{
                    paddingBottom: "6px",
                    color: selectedPlan === "ENHANCED" ? "white" : "black",
                    fontSize: "24px",
                    fontFamily: "poppins",
                    fontWeight: 500,
                  }}
                >
                  3270.00 CAD
                </p>
                <Button
                  onClick={() => handleSelect("ENHANCED")}
                  style={{
                    backgroundColor: "#11a09b",
                    borderRadius: "24px",
                    padding: "5px 20px",
                    color: "white",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {selectedPlan === "ENHANCED" ? "SELECTED" : "SELECT"}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor:
                    selectedPlan === "PREMIUM" ? "#24b6b7" : "white",
                  color: "white",
                  width: "100%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "14px",
                  border:
                    selectedPlan === "PREMIUM"
                      ? "1px solid #24b6b7"
                      : "1px solid black",
                  borderRight: "2px solid white",
                }}
              >
                <p
                  style={{
                    paddingBottom: "6px",
                    fontSize: "24px",
                    color: selectedPlan === "PREMIUM" ? "white" : "black",
                    fontFamily: "poppins",
                    fontWeight: 700,
                  }}
                >
                  PREMIUM
                </p>
                <p
                  style={{
                    paddingBottom: "6px",
                    color: selectedPlan === "PREMIUM" ? "white" : "black",
                    fontSize: "24px",
                    fontFamily: "poppins",
                    fontWeight: 500,
                  }}
                >
                  3927.00 CAD
                </p>
                <Button
                  onClick={() => handleSelect("PREMIUM")}
                  style={{
                    backgroundColor: "#11a09b",
                    borderRadius: "24px",
                    padding: "5px 20px",
                    color: "white",
                    fontSize: "22px",
                    fontWeight: "600",
                  }}
                >
                  {selectedPlan === "PREMIUM" ? "SELECTED" : "SELECT"}
                </Button>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gap: "8px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                paddingTop: "4px",
                border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <p style={{ fontWeight: 700, fontSize: "20px" }}>POLICY LIMIT</p>
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
            <div
              style={{
                display: "grid",
                gap: "8px",
                gap: "8px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                paddingTop: "4px",
                border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <p style={{ fontWeight: 700, fontSize: "20px" }}>DEDUCTIBLE</p>
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
            <div
              style={{
                display: "grid",
                gap: "8px",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                paddingTop: "4px",
                border: "1px solid black",
                justifyContent: "center",
                alignItems: "center",
                padding: "16px",
              }}
            >
              <p style={{ fontWeight: 700, fontSize: "20px" }}>
                PAYMENT FREQUENCY
              </p>
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>{" "}
              <Select
                style={{ backgroundColor: "#e8e8e8" }}
                placeholder="Select option"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "64px",
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                borderBottom: "2px solid #0000004a",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "25%",
                  fontSize: "19px",
                  fontWeight: 600,
                  fontFamily: "poppins",
                }}
              >
                User Name
              </p>
              <p style={{ width: "25%", fontSize: "19px", fontWeight: 600 }}>
                Plan Name
              </p>
              <p
                style={{
                  width: "25%",
                  fontSize: "19px",
                  fontWeight: 600,
                  fontFamily: "poppins",
                }}
              >
                With Pre-existing
              </p>
              <p style={{ width: "25%", fontSize: "19px", fontWeight: 600 }}>
                Without Pre-existing
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "8px",
                borderBottom: "1px solid #0000004a",
                backgroundColor: "#d3d3d33b",
              }}
            >
              <p
                style={{
                  width: "25%",
                  fontSize: "19px",
                  fontWeight: 600,
                }}
              >
                User 1
              </p>
              <p style={{ width: "25%", fontSize: "19px", fontWeight: 600 }}>
                {selectedPlan}
              </p>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                }}
              >
                <input style={{ marginRight: "4px" }} type="radio" />
                <p>0</p>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "25%",
                }}
              >
                <input style={{ marginRight: "4px" }} type="radio" />
                <p>0</p>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <Button
              onClick={handleBackToPlan}
              style={{
                backgroundColor: "red",
                color: "white",
                padding: "4px 10px",
                fontFamily: "poppins",
              }}
            >
              Previous
            </Button>
            <Button
              onClick={handleToContactDetails}
              style={{
                backgroundColor: "rgb(36, 182, 183)",
                color: "white",
                padding: "4px 10px",
                fontFamily: "poppins",
              }}
            >
              Proceed
            </Button>
          </div>
        </div>
      )}
      {navigateToContactPage && !generateQuote && (
        <div
          style={{
            margin: "auto",
            width: "80%",
            maxHeight: "200%",
            padding: "18px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 400,
              marginBottom: "20px",
              borderBottom: "2px solid #088183",
            }}
          >
            Trip Details
          </p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "20px",
              margin: "auto",
              padding: "18px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner*
              </span>
              <Select style={{ width: "100%" }} placeholder="Select">
                <option value="male">q</option>
                <option value="female">c</option>
                <option value="female">dd</option>
              </Select>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner Date Of Birth*
              </span>
              <Input
                style={{ width: "100%" }}
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner Email*
              </span>
              <Input style={{ width: "100%" }} placeholder="Enter Email*" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner Address*
              </span>
              <Input style={{ width: "100%" }} placeholder="Address" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner Postal Code*
              </span>
              <Input style={{ width: "100%" }} placeholder="Basic usage" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner City*
              </span>
              <Input style={{ width: "100%" }} placeholder="Basic usage" />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ marginBottom: "8px", fontFamily: "poppins" }}>
                Policy Owner Phone*
              </span>
              <Input style={{ width: "100%" }} placeholder="Basic usage" />
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "center",
                alignItems: "end",
              }}
            >
              <button
                // onClick={handleToTravellerDetails}
                style={{
                  backgroundColor: "#d9534f",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  borderRadius: "4px",
                  height: "50%",
                }}
              >
                Previous
              </button>
              <button
                onClick={GenerateQuoteEvent}
                style={{
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  borderRadius: "4px",
                  height: "50%",
                }}
              >
                Get Quote
              </button>
            </div>
          </div>
        </div>
      )}
      {generateQuote && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "auto",
            width: "80%",
            maxHeight: "200%",
            padding: "18px",
            marginTop: "20px",
            marginBottom: "40px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
          }}
        >
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "18px",
              fontWeight: 400,
              marginBottom: "20px",
            }}
          >
            Your quote has been created successfully.Please login and check your
            quote details before proceed to payment.
          </p>
          <div style={{ display: "flex" }}>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 600,
                fontFamily: "poppins",
                marginRight: "8px",
              }}
            >
              Quotation No:{" "}
            </p>
            <p style={{ fontSize: "20px" }}>2024QTM90221</p>
          </div>

          <Button
            onClick={handleBackToPlan}
            style={{
              backgroundColor: "#24b6b7",
              width: "fit-content",
              color: "white",
              padding: "4px 10px",
              fontFamily: "poppins",
              marginTop: "20px",
            }}
          >
            Click Here To Login
          </Button>
        </div>
      )}
      {!generateQuote && (
        <div
          style={{
            margin: "auto",
            width: "80%",
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            alignItems: "flex-start",
            marginBottom: "40px",
          }}
        >
          <div style={{ display: "flex" }}>
            <CheckCircleIcon w={6} h={6} />
            <p
              style={{
                marginLeft: "6px",
                fontSize: "18px",
                fontWeight: 500,
                paddingBottom: "10px",
                fontFamily: "poppins",
              }}
            >
              Our Product Qualifies as per Government of Canada Guidelines for
              Min of CAD 100,000/-of Sum Insured for min duration of 365 days
              from Canadian Insurance Company.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <CheckCircleIcon w={6} h={6} />
            <p
              style={{
                marginLeft: "6px",
                fontSize: "18px",
                fontWeight: 500,
                paddingBottom: "10px",
                fontFamily: "poppins",
              }}
            >
              No Cancellation Penalty, Pay only for period of stay.
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <CheckCircleIcon w={6} h={6} />
            <p
              style={{
                marginLeft: "6px",
                fontSize: "18px",
                fontWeight: 500,
                paddingBottom: "10px",
                fontFamily: "poppins",
              }}
            >
              Age from 15 days to 90 Years & 3 Product Choices Standard,
              Enhanced and Premium.
            </p>
          </div>{" "}
          <div style={{ display: "flex" }}>
            <CheckCircleIcon w={6} h={6} />
            <p
              style={{
                marginLeft: "6px",
                fontSize: "18px",
                fontWeight: 500,
                paddingBottom: "10px",
                fontFamily: "poppins",
              }}
            >
              Just 90 days stability condition up to age 74.
            </p>
          </div>{" "}
          <div style={{ display: "flex" }}>
            <CheckCircleIcon w={6} h={6} />
            <p
              style={{
                marginLeft: "6px",
                fontSize: "18px",
                fontWeight: 500,
                paddingBottom: "10px",
                fontFamily: "poppins",
              }}
            >
              Covers you for any unforeseen Emergency Medical, Physician &
              Hospitalization expenses, Diagnostics, Prescriptions and Dental
              etc.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
export default Plan;
