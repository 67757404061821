import React, { useState } from 'react'


const SuperAdminPermessionPanel = () => {
    const [permissions, setPermissions] = useState({
        admin1: { component1: true, component2: true, component3: true, component4: false, component5: false },
        admin2: { component1: true, component2: true, component3: true, component4: true, component5: false }
      });
    
      const handlePermissionChange = (admin, component, value) => {
        setPermissions(prevPermissions => ({
          ...prevPermissions,
          [admin]: {
            ...prevPermissions[admin],
            [component]: value
          }
        }));
      };

      
  return (
    <div>
       <div style={{padding:"100px",display:"grid"}}>
      <h2>Super Admin Panel</h2>
      <label>
        Admin 1 Permissions:
        <input type="checkbox" checked={permissions.admin1.component1} onChange={(e) => handlePermissionChange('admin1', 'component1', e.target.checked)} /> Component 1
        <input type="checkbox" checked={permissions.admin1.component2} onChange={(e) => handlePermissionChange('admin1', 'component2', e.target.checked)} /> Component 2
        <input type="checkbox" checked={permissions.admin1.component3} onChange={(e) => handlePermissionChange('admin1', 'component3', e.target.checked)} /> Component 3
        <input type="checkbox" checked={permissions.admin1.component4} onChange={(e) => handlePermissionChange('admin2', 'component4', e.target.checked)} /> Component 4
      </label>
      <label>
        Admin 2 Permissions:
        <input type="checkbox" checked={permissions.admin2.component1} onChange={(e) => handlePermissionChange('admin2', 'component1', e.target.checked)} /> Component 1
        <input type="checkbox" checked={permissions.admin2.component2} onChange={(e) => handlePermissionChange('admin2', 'component2', e.target.checked)} /> Component 2
        <input type="checkbox" checked={permissions.admin2.component3} onChange={(e) => handlePermissionChange('admin2', 'component3', e.target.checked)} /> Component 3
        <input type="checkbox" checked={permissions.admin2.component4} onChange={(e) => handlePermissionChange('admin2', 'component4', e.target.checked)} /> Component 4
      </label>
    </div>
    </div>
  )
}

export default SuperAdminPermessionPanel
