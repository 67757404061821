import React, { useEffect, useState } from 'react'
import SideBox from '../../../Utils/SideBox/SideBox'
import Sidebar from '../Sidebar';
import { Flex, Box, Select, Button, space, Input } from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import { pendingEndoresementList } from '../AdminDashboard/dependencies/action';
import { useNavigate } from 'react-router-dom';



const AdminPendingEndorsement = () => {
    const [rowData,setRowData] = useState([]);
    const dispatch = useDispatch()
    const navigate=useNavigate()
    const pagination = true;
    const paginationPageSizeSelector = [10, 20, 30, 50, 1000];

    const downloadCellRenderer = (params) => {
        const {data}=params
        console.log(data,'data')
        const handleEndorsement = () => {
          navigate(`/admin/policyEndorsements/${data.id}`)
          console.log('Download clicked for row:', params.data);
        };
      return(
        <div>
          <Button _hover={{color:'white',background:'#9c27b0'}} background={'#9c27b0'} color={'white'} size={'xs'} onClick={handleEndorsement}>View</Button>
      </div>
      )
      };


const [colDefs, setColDefs] = useState([
    { field: "employeeName", headerName: "Employee Name", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "requestedDate", headerName: "Requested Date", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "transactionNo", headerName: "Transaction No", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "policyNo", headerName: "Policy No", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "endorsementType", headerName: "Endorsement Type", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "cancellationDate", headerName: "Cancellation Date" , wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true},
    { field: "refundAmount", headerName: "Refund Amount", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "remarks", headerName: "Remarks", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
    { field: "action",cellRenderer: downloadCellRenderer,wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:180,},
]);
    
useEffect(() => {
    dispatch(pendingEndoresementList())
        .then((res) => {
            console.log(res,'endorsement data')
            // setPendingEndData(res.data)
            const formattedData = res.data.map((quote, index) => ({
              id: quote.idPolicy,
              // employeeName: quote.policyDetailsEntity.user.firstName ,
              // designation: quote.quote.user.roles.name,
              requestedDate: quote.requestedDate,
              policyNo: quote.policyNo,
              endorsementType: quote.endorsementType,
              cancellationDate: quote.cancellationDate,
              refundAmount: quote.refundAmount,
              remarks:quote.remarks,
            }));
            setRowData(formattedData);
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
}, [dispatch]);


const downloadPDF = () => {
    const MyDoc = (
        <Document>
            <Page>
                <Text>Quotations</Text>
            </Page>
        </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
};

const downloadExcel = () => {
    // const worksheet = XLSX.utils.json_to_sheet(rowData);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    // XLSX.writeFile(workbook, 'quotations.xlsx');
};

const downloadCSV = () => {
};


    return (
        <div>
            <Sidebar headingText={'Pending Endorsement'} >
                <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
                    <Flex gap={"20px"}>
                        <Box >
                            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Roles</label> <br />
                            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
                                <option value="">Advisors</option>
                                <option value="VTC">AGA</option>
                                <option value="VTC">MGA</option>
                                <option value="VTC">Customer</option>


                            </select>
                        </Box>
                        <Box>
                            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
                            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} >
                                <option value="">Select User</option>


                            </select>
                        </Box>
                        <Box>
                            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>From Date</label> <br />
                            <Input type="date" style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} />


                        </Box>
                        <Box>
                            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>To Date</label> <br />
                            <Input type="date" style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} />


                        </Box>

                        <Box mt={'20px'}>
                            <Button size={'sm'} background={'#9c27b0'} color='white'>Search</Button>
                        </Box>
                        <Box mt={'20px'} ml={'15px'}>
                            <Button size={'sm'} background={'#9c27b0'} color='white'>Reset</Button>
                        </Box>
                    </Flex>
                    <br />
                </div>
                <Box w={'95%'} m={'auto'} background={'white'} p={'10px'} mb={'30px'}>
                    <Flex justifyContent={'space-between'} mt={'15px'}>
                        <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                        </div>
                        <div>
                            <label htmlFor="search">Search:</label>
                            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                        </div>
                    </Flex>
                    <div
                        className="ag-theme-quartz"
                        style={{ width: '100%', overflow: 'hidden' }}
                    >
                        <AgGridReact
                           rowData={rowData}
                           columnDefs={colDefs}
                           domLayout='autoHeight'
                           width='100%'
                           paginationPageSizeSelector={paginationPageSizeSelector}
                           pagination={pagination}
                           paginationPageSize={10}
                           onGridReady={(params) => {
                             params.api.sizeColumnsToFit();
                           }}
                        />
                    </div>
                </Box>
            </Sidebar>
        </div>
    )
}

export default AdminPendingEndorsement;
