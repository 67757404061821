import { combineReducers } from 'redux';
import loaderReducer from './loader.reducer';
import productReducer from './product.Reducer';
import customerGetQuoteReducer from './customerGetQuote.reducer';
import userReducer from './user.reducer';
import superVisaReducer from './supervisaQuote.Reducer';
import studentinsurancequickqouteReducer from './studentinsurancequickqoute.Reducer';
import visitorquickqouteReducer from './visitorquickqoute.Reducer';
import advisorProfileReducer from './advisorProfile.Reducer';
import superAdminLoginReducer from './superAdminLogin.Reducer';
import advisorSingleQuoteReducer from './advisorSingleQuote.Reducer';
import themeReducer from './theme.Reducer';




const appReducer = combineReducers({
  loader: loaderReducer,
  products: productReducer,
  customerQuoteList:customerGetQuoteReducer,
  superVisaQuote:superVisaReducer,
  user: userReducer,
  studentQuote:studentinsurancequickqouteReducer,
  vistorQuote:visitorquickqouteReducer,
  advisorProfile:advisorProfileReducer,
  superAdminLogin:superAdminLoginReducer,
  advisorSingleQuote:advisorSingleQuoteReducer,
  theme:themeReducer
});

export default appReducer;