import React, { useEffect, useState } from "react";
import {
    Box,
    Flex,
    FormControl,
    Input,
    Button,
    FormLabel,
    Image,
    Text,
    InputGroup,
    InputRightElement,
    useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storage } from "../../../dependencies/store/storage";
import { admincreateLoginList, menuList } from "../dependencies/action";
import Sidebar from "../../Admin/Sidebar";

const CreateAdmin = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const [menu, setMenu] = useState([]);
    const [selectedMenus, setSelectedMenus] = useState([]);

    useEffect(() => {
        dispatch(menuList())
            .then((res) => {
                console.log("menu list fetched successfully");
                setMenu(res);
            })
            .catch((error) => {
                console.error("Error fetching advisor profile data:", error);
            });
    }, [dispatch]);

    console.log("menu data", menu);

    const [show, setShow] = useState(false);
    const [values, setValues] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const handleClick = () => setShow(!show);
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            if (checked) {
                setSelectedMenus([...selectedMenus, value]);
            } else {
                setSelectedMenus(selectedMenus.filter((menu) => menu !== value));
            }
        } else {
            setValues({ ...values, [name]: value });
        }
        setErrors({ ...errors, [name]: "" });
    };
    
    const handleLogin = async () => {
        const { firstName, lastName, email, confirmPassword, password } = values;
        console.log(values);
        let newErrors = { ...errors };
        if (firstName.length === 0) {
            newErrors.username = "Please enter valid email";
        }
        if (lastName.length === 0) {
            newErrors.lastName = "Please enter valid email";
        }
        if (email.length === 0) {
            newErrors.email = "Please enter valid email";
        }

        if (password.length === 0) {
            newErrors.password = "Password do not match!";
        }
        if (confirmPassword.length === 0) {
            newErrors.confirmPassword = "Password do not match!";
        }
        if (selectedMenus.length === 0) {
            newErrors.menus = "Please select at least one menu";
        }

        dispatch(admincreateLoginList({ ...values, menus: selectedMenus })).then((res) => {
            const { status, data } = res;
            if (status === 200 || status === 201) {
                storage.setUser(data);
                alert("admin created");
                //   window.location.reload();
            }
        });
    };
    const handleBack = () => {
        navigate(-1);
      }

return (
        <Sidebar headingText={"Create Admin"}>
            <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' w={'70%'} m={'auto'} background={'white'} mt={'50px'} padding={'20px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
                <Flex direction={["row", "row", "column", "column"]} pt={"10px"}>
                    <Box  border={'2px solid black'}
                        p={5}
                        pt={"40px"}
                        textAlign={"center"}
                    >
                        <Flex justifyContent={"center"}>
                            <Image
                                w={"130px"}
                                src={"https://travelmedicare.com/public/users/images/logo.png"}
                            />
                        </Flex>
                        <Text
                            fontFamily={"poppins"}
                            mt={["30px", "1px", "10px", "30px"]}
                            pb={["20px", "20px", "80px", "20px"]}
                        ></Text>
                        
                        <FormControl isRequired w={["80%"]} m={"auto"}>
                            <Flex gap={'20px'} w={'100%'}>
                            <Box w={'47%'}>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                type="firstName"
                                placeholder="Enter First Name"
                                name="firstName"
                                onChange={handleChange}
                                />
                                </Box>
                                <Box w={'47%'}>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                type="lastName"
                                placeholder="Enter First Name"
                                name="lastName"
                                onChange={handleChange}
                                />
                                </Box>
                                </Flex>
                            

                            <FormLabel mt={'10px'}>Email</FormLabel>
                            <Input
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                onChange={handleChange}
                            />
                            <Text color="red.500">{errors.email}</Text>
                            <FormLabel mt={"10px"}>Password</FormLabel>
                            <InputGroup size="md">
                                <Input
                                    pr="4.5rem"
                                    type={show ? "text" : "password"}
                                    placeholder="Enter password"
                                    name="password"
                                    onChange={handleChange}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                        {show ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                            <FormLabel pt={"10px"}>Confirm Password</FormLabel>
                            <InputGroup size="md">
                                <Input
                                    pr="4.5rem"
                                    type={show ? "text" : "password"}
                                    placeholder="Enter password"
                                    name="confirmPassword"
                                    onChange={handleChange}
                                />

                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                                        {show ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>
                            <Text color="red.500">{errors.confirmPassword}</Text>
                            <FormLabel pt={"10px"} mt={'15px'} fontSize={'14px'} fontFamily={'poppins'}>Select Menu</FormLabel>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' }}>
    {menu.map((menuItem, index) => (
        <FormControl key={index} display="flex" alignItems="center">
            <input
                type="checkbox"
                id={`menu-${index}`}
                name={`menu-${index}`}
                value={menuItem} // Set the value to the menu item itself
                onChange={handleChange}
            />
            <FormLabel htmlFor={`menu-${index}`} ml={2} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}>
                {menuItem}
            </FormLabel>
        </FormControl>
    ))}
</div>
                           
                            <Button
                                mt={4}
                                width="100%"
                                onClick={handleLogin}
                                color={"white"}
                                backgroundColor={"teal"}
                            >
                                Create Admin
                            </Button>
                        </FormControl>
                       
                    </Box>
                </Flex>
            </Box>
        </Sidebar>
    );
};

export default CreateAdmin;
