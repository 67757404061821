import { toast } from "react-toastify";
import { ADMIN_PROFILE_EDIT_TYPE, ADMIN_PROLFILE, GET_TEAM_USER_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminProfileService, AdvisorTeamService } from "./service";

export const teamList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdvisorTeamService.teamList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: GET_TEAM_USER_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };