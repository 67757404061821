import { ADMIN_PROFILE_EDIT, ADMIN_PROFILE_LIST, TEAM_USER_GET } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdvisorTeamService = {
teamList() {
    return Api.GET(TEAM_USER_GET).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
}
}