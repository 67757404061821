import React, { useEffect, useState } from 'react'
import Sidebar from '../../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { advisorGetPolicyDetails } from '../../../../Advisor/Dependencies/action'
import { formatDate } from '../../../../../Utils/DateFormate'



const ViewDeclinedTranscationList = () => {
    const params= useParams()
    const {id}=params
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [policyData,setPolicyData]=useState([])
    
  
    const handleBack = () => {
      navigate(-1);
    }


    useEffect(()=>{
        dispatch(advisorGetPolicyDetails(id)).then((res)=>{
            console.log(res)
            setPolicyData([res.data])
        })
    },[])
    console.log(policyData,'policyData')


return (
    <Sidebar headingText={'Declined Transcation List'}>
       <Box w='95%' m='auto' mt={'70px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text fontSize={'20px'}>Policy Details</Text>

          <Flex justifyContent={'space-around'}>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy No:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Created date:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Amount:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Duration:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{'2024-04-27'}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{'$0156  '}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Holder Name:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{'2024-04-27'}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{'$0156  '}</Text>
            </Box>
          </Flex>



          {policyData && policyData.map((ele)=>(
        <Box background={'white'} mt={'50px'} p={'20px'}> 
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Card Holder Name</th>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Date	</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Premium Type</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Amount CAD</th>
                        <th style={{textAlign:'left',padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation ID</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation Description</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                {ele.paymentDetails.map((item)=>(
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        {/* <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{item.cardHolderFirstName} {item.cardHolderLastName}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{formatDate(item.createdAt)}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{'value'}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{'value'}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{item.amount} $</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{item.transactionId}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{item.transactionDescription}</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Update</td> */}
                    </tr>
                ))}
                </tbody>
                 </table>
          </Box>
           ))}
        </Box>
    </Sidebar>
  )
}

export default ViewDeclinedTranscationList
