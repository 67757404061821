import React from 'react'
import Sidebar from '../Sidebar'
import { Box, Flex,Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { FaUsers } from "react-icons/fa";
import { TbUserSquareRounded } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { IoDocumentsOutline } from "react-icons/io5";


const AdminServices = () => {




return (
    <Sidebar headingText={'Services'}>
        <Box>
        <Flex w={'95%'} m={'auto'} mt={'50px'} justifyContent={'space-between'}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/white-labeled'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Labeled</Text>
                <Text fontWeight={'600'}>{'3'}</Text>
              </Box>
            </Flex>
            <hr />
          </Link>
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'  cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/partners'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUsersGroup />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Partners</Text>
                <Text fontWeight={'600'}>{'6'}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to="/admin/affiliates">
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <FaUsers />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Affiliate</Text>
                <Text fontWeight={'600'}>{'4'}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          
        </Flex>
        <Flex w={'95%'} m={'auto'} mt={'30px'} justifyContent={'space-between'}>
        <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to={'/admin/mga/aga/advisor'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUserSquareRounded />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>coming soon</Text>
                <Text fontWeight={'600'}>{'1'}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'13px'} fontFamily={'poppins'}>coming soon</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'30%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>coming soon</Text>
                <Text fontWeight={'600'}>{20}</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
        </Flex>
        </Box>
    </Sidebar>
  )
}

export default AdminServices
