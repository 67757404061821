
import { ADMIN_ALL_QOUTES_GET_TYPE, isLoading } from "../../../../constants/actionConstants";
import {  AdminAllQuoteService } from "./service";

export const adminQouteList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminAllQuoteService.adminQouteList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_ALL_QOUTES_GET_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
}
