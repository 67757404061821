import { ADVISOR_CALCULATE_VTC, ADVISOR_DELETE_QUOTE, ADVISOR_GENERATE_QUOTE, ADVISOR_GET_POLICY_DETAILS, ADVISOR_GET_QUOTES, ADVISOR_HANDLE_CANCELLATION, ADVISOR_HANDLE_CORRECTION_EXTEND, ADVISOR_HANDLE_CORRECTION_INSURED_DETAILS, ADVISOR_HANDLE_CORRECTION_NON_FINANCIAL, ADVISOR_HANDLE_VOID, ADVISOR_LOGIN, ADVISOR_PERSONAL_DETAILS, ADVISOR_PROFILE, ADVISOR_REFRENCE_DETAILS, ADVISOR_RENEWAL_POLICES_LIST, ADVISOR_SAVE_POLICY, ADVISOR_SIGNUP, ADVISOR_SINGLE_QUOTE, ADVISOR_UPDATE_QUOTE, CLAIM_REPORT_ADVISOR, CUSTOMER_CALCULATE_VTC, CUSTOMER_LOGIN, CUSTOMER_SIGNUP, FORGET_PASSWORD } from "../../../constants/ApplicationUrl";
import { SupervisaQuicknoteList, advisorHandleCancellation, advisorHandleCorrectionExtendDate, advisorHandleCorrectionInsuredDetails, advisorHandleCorrectionNonFinancial } from "./action";
import Api from "../../../dependencies/utils/Api";
import axios from "axios";

export const AdvisorSignupService = {
    async advisorSignupList(params) {
        try {
            const response = await Api.POST(ADVISOR_SIGNUP, params);
            const {status} = response;
            console.log(response,'resss')
            if (status) {
                return response;
            } else {
                return status;
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};


export const AdvisorLoginService = {
    advisorLoginList(params) {
        return Api.POST(ADVISOR_LOGIN, params).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorProfile() {
        return Api.GET(ADVISOR_PROFILE).then((response) => {
            console.log(response,'advisorprofile')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorCalculateVtc(params) {
        return Api.POST(ADVISOR_CALCULATE_VTC, params)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
            }
          })
          .catch((error) => {
            // Handle API request errors here
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },


      advisorDeleteQuote(id) {
        return Api.DELETE(`${ADVISOR_DELETE_QUOTE}${id}`, id)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
            }
          })
          .catch((error) => {
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },

      advisorUpdateQuote(params) {
        return Api.PUT(ADVISOR_UPDATE_QUOTE, params)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
            }
          })
          .catch((error) => {
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },
      

      customerCalculateVtc(params) {
        return Api.POST(CUSTOMER_CALCULATE_VTC, params)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
            }
          })
          .catch((error) => {
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },
      

    advisorGenerateQuote(params) {
        return Api.POST(ADVISOR_GENERATE_QUOTE,params).then((response) => {
            const { data, status} = response
            if(status === 200 || status=== 201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorGetQuotes(params) {
        return Api.GET(ADVISOR_GET_QUOTES,params).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorSingleGetQuotes(id, params) {
        return Api.GET(`${ADVISOR_SINGLE_QUOTE}/${id}`, params).then((response) => {
            console.log(response,'response')
            const { data, status } = response;
            if (status === 200) {
                return { data, status };
            } else {
                const { data: { message } = {} } = response;
                return { message, status };
            }
        });
    },
  
    advisorRefrence(params) {
        return Api.POST(ADVISOR_REFRENCE_DETAILS,params).then((response) => {
            console.log(response,'response')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    advisorPersonalDetails(params) {
        return Api.PUTDATA(ADVISOR_PERSONAL_DETAILS,params).then((response) => {
          console.log(response,"responsefaraz")
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    advisorGetPolicyDetails(id) {
        return Api.GET(`${ADVISOR_GET_POLICY_DETAILS}/${id}`,id).then((response) => {
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    advisorHandleVoid(params) {
        return Api.POST(ADVISOR_HANDLE_VOID, params)
          .then((response) => {
            const { data, status } = response;
            console.log(response,'resss')
            if (status === 200) {
                
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
              
            }
          })
          .catch((error) => {
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },

    advisorHandleCancellation(params) {
        return Api.POST(ADVISOR_HANDLE_CANCELLATION, params)
          .then((response) => {
            const { data, status } = response;
            if (status === 200) {
              return { data, status };
            } else {
              const { data: { message } = {} } = response;
              return { message, status };
              
            }
          })
          .catch((error) => {
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
          });
      },

    advisorHandleCorrectionInsuredDetails(params) {
    return Api.POST(ADVISOR_HANDLE_CORRECTION_INSURED_DETAILS, params)
        .then((response) => {
        const { data, status } = response;
        if (status === 200) {
            return { data, status };
        } else {
            const { data: { message } = {} } = response;
            return { message, status };
            
        }
        })
        .catch((error) => {
        console.error("Error in advisorCalculateVtc:", error);
        return { message: "An error occurred while processing your request.", status: 500 };
        });
    },


    advisorHandleCorrectionNonFinancial(params) {
      return Api.POST(ADVISOR_HANDLE_CORRECTION_NON_FINANCIAL, params)
          .then((response) => {
          const { data, status } = response;
          if (status === 200) {
              return { data, status };
          } else {
              const { data: { message } = {} } = response;
              return { message, status };
              
          }
          })
          .catch((error) => {
          console.error("Error in advisorCalculateVtc:", error);
          return { message: "An error occurred while processing your request.", status: 500 };
          });
      },

    advisorHandleCorrectionExtendDate(params) {
        return Api.POST(ADVISOR_HANDLE_CORRECTION_EXTEND, params)
            .then((response) => {
            const { data, status } = response;
            if (status === 200) {
                return { data, status };
            } else {
                const { data: { message } = {} } = response;
                return { message, status };
                
            }
            })
            .catch((error) => {
            console.error("Error in advisorCalculateVtc:", error);
            return { message: "An error occurred while processing your request.", status: 500 };
            });
        },

    advisorSavePolicy(params) {
      return Api.POST(ADVISOR_SAVE_POLICY, params)
          .then((response) => {
          const { data, status } = response;
          if (status === 200) {
              return { data, status };
          } else {
              const { data: { message } = {} } = response;
              return { message, status };
              
          }
          })
          .catch((error) => {
          console.error("Error in advisorCalculateVtc:", error);
          return { message: "An error occurred while processing your request.", status: 500 };
          });
      },
      advisorRenewalListAll() {
        return Api.GET(ADVISOR_RENEWAL_POLICES_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    advisorClaimReport(params) {
      return Api.POST(CLAIM_REPORT_ADVISOR, params).then((response) => {
          const { data, status} = response
          if(status === 200) {
              return {data, status}
          }else {
              const {data: {message} = {}} = response
              return {message, status}
          }
      })
  },
  advisorClaimReport(params) {
    return Api.POST(CLAIM_REPORT_ADVISOR, params).then((response) => {
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
advisorLoginForgetPassword(params){
  return Api.POST(FORGET_PASSWORD,params).then((response)=>{
    const {data,status} =response;
    if(status === 200 || status === 201){
      return {data,status}
    }else{
      const {data: {message} = {}} = response
      return {message, status}
    }
  })
}
}