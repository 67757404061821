import React, { useState } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Flex, Box, Text, Select, Button, RadioGroup, Radio, useToast
} from '@chakra-ui/react';

const HealthQuestions = ({ isOpen, onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    question1: '',
    question2: '',
    question3: '',
    question4a: '',
    question4b: '',
    question4c: '',
    question4d: '',
    question5a: '',
    question5b: '',
    question5c: '',
    question5d: '',
    confirmation: false,
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };
  const handleConfirmationChange = (value) => {
    setFormData(prevState => ({ ...prevState, confirmation: value === 'yes' }));
  };
  const handleQuestionersSubmit = () => {
    const {question1,question2,question3,question4a,question4b,question4c,question4d,question5a,question5b,question5c,question5d}=formData;
    if(!question1 || !question2 || !question3 || !question4a || !question4b || !question4c || !question4d || !question5a || !question5b || !question5c || !question5d){
      alert("Please answer all questions.")
      return;
    }
    if (!formData.confirmation) {
      toast({
        title: "Confirmation Required",
        description: "Please confirm that you agree to the condition.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const collectedData = [
      {
        main: "1. Was your most recent heart bypass, heart angioplasty (including stent placement) or heart valve surgery more than 10 years prior to your effective date?",
        selected: formData.question1,
        sub: [],
      },
      {
        main: "2. Do you have both diabetes (for which you are taking medication) and a heart condition?",
        selected: formData.question2,
        sub: [],
      },
      {
        main: "3. Have you ever had or are awaiting a bone marrow or organ transplant?",
        selected: formData.question3,
        sub: [],
      },
      {
        main: "4. In the 24 months prior to your effective date have you been diagnosed with or treated for:",
        selected: 0,
        sub: [
          { subQues: "a) Congestive heart failure?", selected: formData.question4a },
          { subQues: "b) A lung condition with prednisone (or other oral steroid medication) or home oxygen?", selected: formData.question4b },
          { subQues: "c) Kidney or liver failure?", selected: formData.question4c },
          { subQues: "d) Peripheral vascular disease?", selected: formData.question4d },
        ],
      },
      {
        main: "5. In the 12 months prior to your effective date have you been diagnosed with or been treated for:",
        selected: 0,
        sub: [
          { subQues: "a) A heart attack?", selected: formData.question5a },
          { subQues: "b) Stroke, transient ischemic attack (TIA) or mini-stroke?", selected: formData.question5b },
          { subQues: "c) Cancer (excluding basal or squamous cell skin cancer or breast cancer treated only with hormone therapy)?", selected: formData.question5c },
          { subQues: "d) Internal bleeding?", selected: formData.question5d },
        ],
      },
    ];
    onSubmit(collectedData);
    setFormData({
      question1: '',
      question2: '',
      question3: '',
      question4a: '',
      question4b: '',
      question4c: '',
      question4d: '',
      question5a: '',
      question5b: '',
      question5c: '',
      question5d: '',
      confirmation: false,
    })
    
    // onClose()
  };

  return (
    <>
      <Modal mt='40px' scrollBehavior={'inside'} isOpen={isOpen} onClose={onClose} isCentered zIndex={'999999999999'} size={'xl'}>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px) hue-rotate(90deg)' />
        <ModalContent>
          <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>Health Information</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction={'column'} gap={'15px'} fontSize={'13px'} fontWeight={'600'}>
              <Flex>
                <Box w={'80%'}>
                  <Text>1. Was your most recent heart bypass, heart angioplasty (including stent placement) or heart valve surgery more than 10 years prior to your effective date?</Text>
                </Box>
                <Box w={'20%'}>
                  <Select name="question1" value={formData.question1} onChange={handleChange} size={'xs'}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </Box>
              </Flex>
              <Flex>
                <Box w={'80%'}>
                  <Text>2. Do you have both diabetes (for which you are taking medication) and a heart condition?</Text>
                </Box>
                <Box w={'20%'}>
                  <Select name="question2" value={formData.question2} onChange={handleChange} size={'xs'}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </Box>
              </Flex>
              <Flex>
                <Box w={'80%'}>
                  <Text>3. Have you ever had or are awaiting a bone marrow or organ transplant?</Text>
                </Box>
                <Box w={'20%'}>
                  <Select name="question3" value={formData.question3} onChange={handleChange} size={'xs'}>
                    <option value="">Select</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </Box>
              </Flex>
              <Box>
                <Text pb={'10px'} color={'blue'}>4. In the 24 months prior to your effective date have you been diagnosed with or treated for :</Text>
                <Flex direction={'column'} gap={'15px'} pl={'20px'}>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>a) Congestive heart failure?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question4a" value={formData.question4a} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>b) A lung condition with prednisone (or other oral steroid medication) or home oxygen?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question4b" value={formData.question4b} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>c) Kidney or liver failure?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question4c" value={formData.question4c} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>d) Peripheral vascular disease?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question4d" value={formData.question4d} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
              <Box>
                <Text pb={'10px'} color={'blue'}>5. In the 12 months prior to your effective date have you been diagnosed with or been treated for:</Text>
                <Flex direction={'column'} gap={'15px'} pl={'20px'}>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>a) A heart attack?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question5a" value={formData.question5a} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>b) Stroke, transient ischemic attack (TIA) or mini-stroke?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question5b" value={formData.question5b} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>c) Cancer (excluding basal or squamous cell skin cancer or breast cancer treated only with hormone therapy)?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question5c" value={formData.question5c} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                  <Flex>
                    <Box w={'80%'}>
                      <Text>d) Internal bleeding?</Text>
                    </Box>
                    <Box w={'20%'}>
                      <Select name="question5d" value={formData.question5d} onChange={handleChange} size={'xs'}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </Select>
                    </Box>
                  </Flex>
                </Flex>
              </Box>
              <Flex alignItems={'start'} mt={'20px'} gap={'8px'}>
                <Box mt={'9px'}>
                  <RadioGroup name="confirmation" value={formData.confirmation ? 'yes' : ''} onChange={handleConfirmationChange}>
                    <Radio value="yes"></Radio>
                  </RadioGroup>
                </Box>
                <Text>
                  If any question on the medical declaration is answered yes, there is no coverage for any sickness, injury or medical condition that existed prior to the effective date, whether or not stable. (Please confirm that you agree to this condition).
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex gap="50px" justifyContent='center' mt={'20px'}>
              <Button size={'sm'} colorScheme="blue" onClick={handleQuestionersSubmit}>Save</Button>
              <Button size={'sm'} onClick={onClose} mr={3}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default HealthQuestions;
