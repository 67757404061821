import React, { useEffect, useRef, useState } from 'react'
import {Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack, Textarea, useToast,Tab,TabList,Tabs,TabPanels,TabPanel,Tooltip} from "@chakra-ui/react";
import Api from '../../../dependencies/utils/Api';
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import RadioOption from '../../../Utils/RadioCard';
import { useDispatch } from 'react-redux';
import { add, format, isSameMonth } from "date-fns";
import { advisorCalculateVtc, advisorGenerateQuote, advisorUpdateQuote } from '../../Advisor/Dependencies/action';
import { BeneficiaryDetailsModal, FamilyModal, VTCDetailsModal, getMaxSelectableDate, getMinSelectableDate, validateEmail, validateMobileNumber } from '../../Advisor/AdvisorQuote/Validation';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import { useNavigate, useParams } from 'react-router-dom';
import { ADVISOR_SAVE_POLICY, GET_QUOTE_BY_QUOTATION_NO } from '../../../constants/ApplicationUrl';
import HealthQuestions from '../../../Utils/HealthQuestions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { formatDatePicker } from '../../../Utils/DateFormate';

const AdvisorEditQuote = () => {
    const [data,setData]=useState([])
    const [policyLimit, setPolicyLimit] = useState('');
    const [studentPolicyLimit, setStudentPolicyLimit] = useState('');
    const [vtcPlanType,setVtcPlanType]=useState('')
    const [studentPlanType,setStudentPlanType]=useState('')
    const [dedecutibles,setDeductibles]=useState ()
    const toast=useToast()
    const [waitingPeriod, setWaitingPeriod] = useState("");
    const [studentWaitingPeriod, setStudentWaitingPeriod] = useState("");
    const [duration,setDuration]=useState('')
    const [superVisa,setSuperVisa]=useState(false)
    const [familyCoverage,setFamilyCoverage]=useState(false)
    const [policyType, setPolicyType] = useState('VTC');
    const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
    const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
    const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
    const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('0')
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const [selectedCostOption, setSelectedCostOption] = useState();
    const [message,setMessage]=useState('')
    const [amount,setAmount]=useState(0)
    const [quoteId,setQuoteId]=useState(0)
    const [costsData,setCostsData]=useState([])
    const [showCosts,setShowCosts]=useState(true)
    const params = useParams()
    const [vtcData,setVtcData]=useState([])
    const [isFamilyModel,setIsFamilyModel]=useState(false)
    const {id}=params
    const [questions,setQuestions]=useState([])
    const [isQuestionerModalOpen, setIsQuestionerModalOpen] = useState(false);
    const [questionerTravelerIndex, setQuestionerTravelerIndex] = useState(null);
    const [currentTravelerIndex, setCurrentTravelerIndex] = useState(null);
    const [currentDob, setCurrentDob] = useState('');
    const [validationTimeout, setValidationTimeout] = useState(null);
    const [adjustment,setAdjustment]=useState(0)
    const [amountWithoutAdjustment,setAmountWithoutAdjustment]=useState(0)
    const handleQuestionersSubmit = (collectedData) => {
      console.log('Submitting Health Questions');
      console.log('Current Traveler Index:', currentTravelerIndex);
      console.log('Current DOB:', currentDob);
  
      setAddTravelers(prevState => {
        console.log('Previous State:', prevState);
        const updatedState = [...prevState];
        if (currentTravelerIndex !== null && currentDob !== '') {
          updatedState[currentTravelerIndex] = {
            ...updatedState[currentTravelerIndex],
            dob: currentDob, // Ensure currentDob is not empty
          };
          console.log('Updated State:', updatedState);
        }
        return updatedState;
      });
  
      setQuestions(collectedData);
      setIsQuestionerModalOpen(false); // Close the modal
    };

    const handleQuestionersClose = () => {
      if (questionerTravelerIndex !== null) {
        setAddTravelers(prevState => {
          const updatedState = [...prevState];
          updatedState[questionerTravelerIndex] = { ...updatedState[questionerTravelerIndex], dob: '' }; // Reset dob
          return updatedState;
        });
        setQuestionerTravelerIndex(null); // Reset the index
      }
      setIsQuestionerModalOpen(false);
    };

    const [addTravelers, setAddTravelers] = useState([
      {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
        selectedKey:'',
        idInsured:'',
        costWithPreExisting:'',
        costWithoutPreExisting:''
      },
    ]);
    console.log(amount,'amount')

    const resetCosts = () => {
      setShowCosts(false)
      setAmount(0)
      setMessage('')
      setSelectedCostOption('')
      setAdjustment('0')
      setAmountWithoutAdjustment('0')
      const updatedTravelers = addTravelers.map(traveler => {
          let updatedTraveler = { ...traveler };
              updatedTraveler.costWithPreExisting = '';
              updatedTraveler.costWithoutPreExisting = '';
          return updatedTraveler;
      });
      setAddTravelers(updatedTravelers);
    };
  
    const handleCostOptionChange = (index, value, ele) => {
      setSelectedCostOption(prevSelectedCostOption => ({
          ...prevSelectedCostOption,
          [index]: value
      }));
      const selectedValues = Object.values(selectedCostOption)
      .filter((val, idx) => idx !== index)
      .map(val => parseFloat(val));
  
        const totalSelectedValue = selectedValues.reduce((acc, curr) => {
          return acc + (isNaN(curr) ? 0 : curr);
        }, 0);
        const totalValue = totalSelectedValue + (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
        const message = selectedOption === 'withPreExisting' ? vtcData.messageOfPreExisting : vtcData.messageOfWithoutPreExisting;
        console.log(message, 'selected', ele, value, index);
        setMessage(message);
        setAmount(totalValue);
        setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
          if (idx === index) {
              return {
                  ...traveler,
                  selectedKey: value === ele.costWithPreExisting ? '2' : '3'
              };
          }
          return traveler;
      }));
    };

    const [errors, setErrors] = useState({
      firstDate: "",
      lastDate: "",
      vtcDepartureDate: "",
      policyOwnerEmail:'',
      policyOwnerAddress:'',
      policyOwnerPostalCode:'',
      policyOwnerCity:'',
      policyOwnerPhone:'',
      cardHolderFirstName: '',
      cardHolderLastName: '',
      cardHolderEmail: '',
      cardHolderPostal: '',
      cardHolderPhone: '',
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      amount: ''
    });

    const [formData, setFormData] = useState({
      firstDate: '',
      lastDate: '',
      clientCountry: 'canada',
      clientProvince: '',
      vtcDepartureDate: "",
      studentDepartureDate: "",
    });

    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });

    useEffect(() => {
      const isAnyAgeBetween27to69 = addTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 27 && age <= 69;
      });
    
      if (!isAnyAgeBetween27to69) {
        setFamilyCoverage(false)
      }
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
      if(!isAllAgeGreaterThanOne) {
        setFamilyCoverage(false)
      }
      if(addTravelers.length<3){
        setFamilyCoverage(false)
      }
      const sameMonth = () => {
        const liesDate = new Date(formData.firstDate);
        const selectedDate = new Date(formData.vtcDepartureDate);
        const validDate = isSameMonth(selectedDate, liesDate);
        return validDate;
      };
      setWaitingPeriod(sameMonth() ? "48 Hour" : "8 days");
    }, [formData.firstDate, formData.vtcDepartureDate,addTravelers]);    
  
    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      // setErrors({ ...errors, [e.target.name]: " " });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
  
    useEffect(() => {
      if (!formData.vtcDepartureDate) {
        setWaitingPeriod("NA");
      } else {
        const today = new Date(formData.firstDate);
        const thirtyDaysAgo = new Date(today);
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const selectedDate = new Date(formData.vtcDepartureDate);
        const firstDate = new Date(formData.firstDate);
        const isSameDate = selectedDate.toDateString() === firstDate.toDateString();      
        const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
        if (isSameDate) {
          setWaitingPeriod("NA");
        } else {
          setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
        }
      }
      if(!formData.studentDepartureDate){
        setWaitingPeriod("NA");
      }else{
        // const today = new Date();
        // const thirtyDaysAgo = new Date();
        // thirtyDaysAgo.setDate(today.getDate() - 30);
        // const selectedDate = new Date(formData.vtcDepartureDate);
        // const firstDate = new Date(formData.firstDate)
        // const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
        // if (selectedDate>=firstDate) {
        //   setWaitingPeriod("NA");
        // } else {
        //   setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
        // }
        const formateSelected = format(formData.studentDepartureDate,'yyyy-MM-dd')
        const selectedDate = formateSelected
        if (selectedDate === formData.firstDate) {
          setStudentWaitingPeriod("NA");
        } else {
          setStudentWaitingPeriod("5 days");
        }
        console.log(selectedDate,'departure date',formData.firstDate)
      }
    }, [formData.vtcDepartureDate,formData.studentDepartureDate]);

    const handleChange = (dateOrEvent, fieldName) => {
      resetCosts();
      let name, value;
      if (fieldName) {
        name = fieldName;
        value = dateOrEvent
      } else {
        const event = dateOrEvent;
        name = event.target.name;
        value = event.target.value;
      }
      setFormData({ ...formData, [name]: value });
      // setFormData((prevData) => ({ ...prevData, [name]: value }));

      if (name === 'firstDate' || name === 'lastDate') {
        setStudentPolicyOption('Single Trip')
        setSuperVisa(false);
      }
 
      if (formData.lastDate && name === 'firstDate') {
        const endDate = new Date(formData.lastDate);
        const startDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
  
        if (endDate < startDate) {
          alert('Last date cannot be earlier than the first date');
          setFormData((prevData) => ({ ...prevData, lastDate: '' }));
          setDuration('');
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert('Duration cannot exceed 1 year');
          setFormData((prevData) => ({ ...prevData, lastDate: '' }));
          setDuration('');
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
          setDuration(durationInDays);
        }
      }
      
      if (name === 'lastDate') {
        console.log(value)
        // if(value!==null){
          // if(value.length===10){
            
        if (!formData.firstDate) {
          alert('Please select the first date of cover first');
          setFormData((prevData) => ({ ...prevData, lastDate: '' }));
          setDuration('');
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
  
        if (endDate < startDate) {
          alert('Last date cannot be earlier than the first date');
          setFormData({ ...formData, [name]: value });
          // setFormData((prevData) => ({ ...prevData, lastDate: '' }));
          setDuration('');
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert('Duration cannot exceed 1 year');
          setFormData({ ...formData, [name]: value });
          // setFormData((prevData) => ({ ...prevData, lastDate: '' }));
          setDuration('');
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
          setDuration(durationInDays);
        }        
      
      }
      if (name === 'vtcDepartureDate') {
        if (!formData.firstDate) {
          alert('Please select the first date of cover');
          setFormData((prevData) => ({ ...prevData, vtcDepartureDate: '' }));
          return;
        }
      }
  
      if (name === 'studentDepartureDate') {
        if (!formData.firstDate) {
          alert('Please select the first date of cover');
          setFormData((prevData) => ({ ...prevData, studentDepartureDate: '' }));
          return;
        }
      }
    }
   
    // const handleChange = (dateOrEvent, fieldName) => {
    //   resetCosts()
    //   let name, value;
    //   if (fieldName) {
    //     name = fieldName;
    //     value = dateOrEvent;
    //   } else {
    //     const event = dateOrEvent;
    //     name = event.target.name;
    //     value = event.target.value;
    //   }
    //   setFormData((prevData) => ({ ...prevData, [name]: value }));
    //   if (name === "firstDate" || name === "lastDate") {
    //     setSuperVisa(false);
    //   }
    //   if(formData.lastDate && name ==="firstDate"){
    //     const endDate = new Date(formData.lastDate);
    //     const startDate = new Date(value);
    //     const diffInMilliseconds = endDate - startDate;
    //     const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    //     if (endDate < startDate) {
    //       alert("Last date cannot be earlier than the first date");
    //       setFormData((prevData) => ({ ...prevData, lastDate: "" }));
    //       setDuration("");
    //     } else if (diffInMilliseconds > oneYearInMilliseconds) {
    //       alert("Duration cannot exceed 1 year");
    //       setFormData((prevData) => ({ ...prevData, lastDate: "" }));
    //       setDuration("");
    //     } else {
    //       const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
    //       setDuration(durationInDays);
    //     }
    //     console.log('ravi')
    //   }
    //   if (name === "lastDate") {
    //     if (!formData.firstDate) {
    //       alert("Please select the first date of cover first");
    //       setFormData((prevData) => ({ ...prevData, lastDate: "" }));
    //       setDuration("");
    //       return;
    //     }
    //     const startDate = new Date(formData.firstDate);
    //     const endDate = new Date(value);
    //     const diffInMilliseconds = endDate - startDate;
    //     const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    //     if (endDate < startDate) {
    //       alert("Last date cannot be earlier than the first date");
    //       setFormData((prevData) => ({ ...prevData, lastDate: "" }));
    //       setDuration("");
    //     } else if (diffInMilliseconds > oneYearInMilliseconds) {
    //       alert("Duration cannot exceed 1 year");
    //       setFormData((prevData) => ({ ...prevData, lastDate: "" }));
    //       setDuration("");
    //     } else {
    //       const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
    //       setDuration(durationInDays);
    //     }
    //   }
    //   if (name === "vtcDepartureDate") {
    //     if (!formData.firstDate) {
    //       alert("Please select the first date of cover");
    //       setFormData((prevData) => ({ ...prevData, vtcDepartureDate: "" }));
    //       return;
    //     }
    //   }
    //   if (name === "studentDepartureDate") {
    //     if (!formData.firstDate) {
    //       alert("Please select the first date of cover");
    //       setFormData((prevData) => ({ ...prevData, studentDepartureDate: "" }));
    //       return;
    //     }
    //   }
    // };
  
    const handleSuperVisaChange = (e) => {
      resetCosts()
      setPolicyLimit()
        const { checked } = e.target;
        if (checked) {
          setPolicyType("Supervisa");
        } else {
          setPolicyType("VTC");
        }
        setSuperVisa(checked);
          const startDate = new Date(formData.firstDate);
          const endDate = new Date(startDate);
          endDate.setFullYear(startDate.getFullYear() + 1);
          endDate.setDate(startDate.getDate()-1);
          setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
          setDuration('365')
    };
    
    const handleFamilyCoverageChange = (e) => {
      resetCosts()
      if(addTravelers.length<3){
        alert('Select Atleast 3 member of family')
        return;
      }
      const eldestMember = addTravelers.reduce((eldest, traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        if (!eldest || age > eldest.age) {
            return { age, traveler };
        }
        return eldest;
      }, null);
      if (!eldestMember || eldestMember.age < 27 || eldestMember.age > 69) {
          alert("The eldest family member's age should be between 27 and 69 years for family coverage.");
          return;
      }
      const hasChildMember = addTravelers.some((traveler) => {
          const dob = new Date(traveler.dob);
          const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
          return age >= 1 && age <= 26;
      });
      if (!hasChildMember) {
          alert('You must select atleast one child up to 21 or 26 if fully dependent on parents');
          return;
      }
      const dependentChildren = addTravelers.filter((traveler) => {
          const dob = new Date(traveler.dob);
          const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
          return age >= 21 && age <= 26;
      });
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
      if(!isAllAgeGreaterThanOne) {
        alert("family member must be greater than one year");
        return;
      }
      const olderThan26Count = addTravelers.reduce((count, traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        if (age > 26) {
          return count + 1;
        }
        return count;
      }, 0);
      if (olderThan26Count > 2) {
        alert('There should not be more than two family members whose age is more than 26 years.');
        return;
      }
      if (dependentChildren.length > 0 && olderThan26Count<=2) {
        if(familyCoverage===false){
          setIsFamilyModel(true)
        }else{
          console.log('already selected')
        }
      }
      const { checked } = e.target;
      setFamilyCoverage(checked);
    }

    const handleFamilyModel = ()=>{
      setIsFamilyModel(false)
      // setFamilyCoverage(false)
    }
  
    const handlePolicyLimitChange = (value) => {
        resetCosts()
        setPolicyLimit(value);
    };
  
    const handleStudentPolicyLimitChange = (value) => {
      resetCosts()
      setStudentPolicyLimit(value);
    };
  
    const handleStudentPolicyOption = (e) => {
      resetCosts()
      const { value } = e.target;
      setStudentPolicyOption(value);
      setPolicyType("Studentvisa");
      const startDate = new Date(formData.firstDate || data[0].firstDateOfCover);
      const endDate = new Date(startDate);
      endDate.setFullYear(startDate.getFullYear() + 1);
      setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
      setDuration('365')
    };
  
    const handlePlanTypeChange = (value) => { 
      resetCosts()
      setDeductibles()
      setVtcPlanType(value);
    };
  
    const handleStudentPlanTypeChange = (value) => { 
      resetCosts()
      setStudentPlanType(value);
    };

    const handleDeductiblesChange = (value) => { 
      if(!vtcPlanType){
        alert("Please select plan type.")
        return;
      }
      resetCosts()
      setDeductibles(value);
    };
  
    const handleAddTraveller = () => {
      resetCosts()
        setAddTravelers([
          ...addTravelers,
          {
            name: "",
            gender: "",
            dob: "",
            relation: "",
            benificiaryDob: "",
            benificiaryName: "",
            idInsured:''
            // costWithoutPreExisting:"",
            // costWithPreExisting:""
          },
        ]);
      
    };
  
    const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
      resetCosts()
      setVtcData([])
          let name, value;
          if (fieldName) {
            name = fieldName;
            value = dateOrEvent;
          } else {
            const event = dateOrEvent;
            name = event.target.name;
            value = event.target.value;
          }
      const today = new Date();
      const selectedDate = new Date(value);
      const age = today.getFullYear() - selectedDate.getFullYear();
      const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      };
      if(name === "dob" || name === "benificiaryDob") {
        if(policyType==='STC'){
          const age = calculateAge(value);
          if(age>65){
            alert('Age should between 15 days to 65 years')
            return;
          }
        }
        const dateValue = new Date(value);
          const today = new Date();
          const selectedDate = new Date(value);
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - 15);
          if (selectedDate >= minDate) {
              alert("Users must be at least 15 days older than today.");
              return;
          }
          const age = today.getFullYear() - selectedDate.getFullYear();
          const monthDiff = today.getMonth() - selectedDate.getMonth();
          const dayDiff = today.getDate() - selectedDate.getDate();
  
          if (age > 75 || (age === 75 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
              setCurrentTravelerIndex(index);
              setCurrentDob(value);
              setIsQuestionerModalOpen(true);
              // return;
          }
          value = dateValue.toISOString().split('T')[0]; 
      }
      setAddTravelers((prevState) => {
          const updatedState = [...prevState];
          updatedState[index] = { ...updatedState[index], [name]: value };
          if (name === "dob") {
            const dob = new Date(value);
            const today = new Date();
            const age = today.getFullYear() - dob.getFullYear();
            updatedState[index] = {
                ...updatedState[index],
                age: age,
            };
        }
          return updatedState;
      });
    };
  
    const handleRemoveTraveller = (index) => {
      var result = confirm("Are you sure you want to delete?");
      resetCosts()
      const updatedTravelers = [...addTravelers];
      updatedTravelers.splice(index, 1);
      setAddTravelers(updatedTravelers);
    };
  
    const validateFormData = (formData, vtcPlanType, addTravelers) => {
      if (!formData.firstDate) {
        return "First date of cover must be provided.";
      }
      if (!formData.lastDate) {
        return "Last date of cover must be provided.";
      }
      const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === "" ||
          traveler.relation === ""
      );
      if (isAnyBeneficiaryDetailEmpty) {
        return "Please fill in all beneficiary details.";
      }
      return null;
    };
  
    const handleVtcCalculate = async () => {
      for (const traveler of addTravelers) {
        if (!traveler.dob ) {
          alert("Please enter DOB for all travelers");
          return;
        }
      }
      if(!policyLimit){
        alert("Please choose policy limit")
        return;
      }
      if(!vtcPlanType){
        alert("Please choose plan type")
        return;
      }
      if(!dedecutibles){
        alert("Please choose Deductibles")
        return;
      }
      if(!formData.vtcDepartureDate){
        alert("Please choose date of Departure")
        return;
      }
      if(superVisa){
        setPolicyType('Supervisa')
      }else{
        setPolicyType('VTC')
      }
      const validationError = validateFormData(formData, vtcPlanType, addTravelers);
      if (validationError) {
        alert(validationError);
        return;
      }
      if(superVisa){
        setPolicyType('Supervisa')
      }else{
        setPolicyType('VTC')
      }
      setFormData(prevFormData => ({
        ...prevFormData,
          studentDepartureDate: ''        
      }));
      setStudentPolicyLimit('')
      setStudentPlanType('')
      setIsVtcModalOpen(true);
    };
  
    const handleStudentCalculate = async () =>{
      setPolicyType('Studentvisa')
      setFormData(prevFormData => ({
        ...prevFormData,
          vtcDepartureDate: ''        
      }));
    //   const isAnyBeneficiaryDetailEmpty = addTravelers.some(
    //     (traveler) =>
    //       traveler.benificiaryName === "" ||
    //       traveler.benificiaryDob === "" ||
    //       traveler.relation === ""
    //   );
    //   if (isAnyBeneficiaryDetailEmpty) {
    //     alert('Please fill in all beneficiary details.')
    //     return
    //   }
      setIsStudentModalOpen(true);
    }
  
    const handleStudentModalSubmit = () => {
      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      const firstDate = new Date(formData.firstDate);
      firstDate.setHours(0, 0, 0, 0);
      if (firstDate < tomorrow) {
        alert("First date of cover must be greater than today.");
        return;
      }
      setIsStudentModalOpen(false); 
      const updatedTravelers = addTravelers.map((traveler) => ({
        insuredName: traveler.name,
        insuredGender: traveler.gender,
        insuredDOB: traveler.dob,
        insuredRelation: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDob: traveler.benificiaryDob,
        selectedKey:traveler.selectedKey,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
      })); 
      const dataToLog = {
          firstDateOfCover: formatDatePicker(new Date(formData.firstDate)) || data[0].firstDateOfCover ,
          lastDateOfCover: formatDatePicker(new Date(formData.lastDate)) || data[0].lastDateOfCover,
          planType:studentPlanType|| data[0].listOfInsured[0].insuredPlan,
          insuredDetailsRequests: updatedTravelers,
          policyType:policyType,
          tripType:studentPolicyOption
      };
      console.log(dataToLog,"student form submit data")
      dispatch(advisorCalculateVtc(dataToLog)).then(res => {
        const {status, data} = res
        console.log(data,'data')
        setShowCosts(true)
        setVtcData(res.data)
        setCostsData(data.listOfInsuredQutation)
        const response = data.listOfInsuredQutation;
        setAddTravelers(prevAddTravelers => {
          const updatedTravelers = prevAddTravelers.map(traveler => {
            const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
            if (responseTraveler) {
              return {
                ...traveler,
                costWithPreExisting: responseTraveler.costWithPreExisting,
                costWithoutPreExisting: responseTraveler.costWithoutPreExisting,
                selectedKey: 2,
              };
            }
            return traveler;
          });
          const totalCostWithPreExisting = updatedTravelers.reduce((total, traveler) => {
            return total + (traveler.costWithPreExisting || 0);
          }, 0);
          setTotalCost(totalCostWithPreExisting,res);
          return updatedTravelers;
        });
        const setTotalCost = (totalCost,res) => {
          setAdjustment(res.data.adjustment===null?0:res.data.adjustment)
          setAmount(totalCost+Number(res.data.adjustment))
          setAmountWithoutAdjustment(totalCost); 
        };
        // const newTravelers = data.listOfInsuredQutation.map((quote, index) => ({
        //   ...addTravelers[index],
        //   costWithPreExisting: quote.costWithPreExisting,
        //   costWithoutPreExisting: quote.costWithoutPreExisting,
        //   selectedKey:2
        // }));
        // setAddTravelers(newTravelers)     
       })
    };

    console.log(showCosts,policyType,'show costs policy type')
    function validateFamilyMembers(updatedTravelers) {
      if (updatedTravelers < 3) {
        alert('Select Atleast 3 members of the family');
        return false;
      }
    
      const isAnyDetailEmpty = updatedTravelers.some(
        (traveler) =>
          traveler.name === "" ||
          traveler.gender === "" ||
          traveler.dob === "" ||
          traveler.relation === "" ||
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === ""
      );
    
      if (isAnyDetailEmpty) {
        alert("Please fill in all family member details before selecting family coverage.");
        return false;
      }
    
      const isAnyAgeBetween27to69 = updatedTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 27 && age <= 69;
      });
    
      if (!isAnyAgeBetween27to69) {
        alert("At least one family member's age should be between 27 to 69 years for family coverage.");
        return false;
      }
    
      const isAllAgeGreaterThanOne = updatedTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
    
      if (!isAllAgeGreaterThanOne) {
        alert("Family member must be greater than one year");
        return false;
      }
      return true; // All validations passed
    }
   
    const handleVtcModalSubmit = async () => {
      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      const firstDate = new Date(formData.firstDate);
      firstDate.setHours(0, 0, 0, 0);
      if (firstDate < tomorrow) {
        alert("First date of cover must be greater than today.");
        return;
      }
      if(!formData.firstDate){
        alert("Please enter first date of cover")
        return;
      }
      if(!formData.lastDate){
        alert("Please enter last date of cover")
        return;
      }
      if(!dedecutibles){
        alert("Please choose Deductibles")
        return;
      }
      if(!formData.vtcDepartureDate){
        alert("Please choose date of Departure")
        return;
      }
      setIsVtcModalOpen(false);    
      const updatedTravelers = addTravelers.map((traveler) => ({
        insuredName: traveler.name,
        insuredGender: traveler.gender,
        insuredDOB: traveler.dob,
        insuredRelation: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDob: traveler.benificiaryDob,
        selectedKey:traveler.selectedKey,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
      }));
        const dataToLog = {
            firstDateOfCover: formatDatePicker(new Date(formData.firstDate)) || data[0].firstDateOfCover,
            lastDateOfCover: formatDatePicker(new Date(formData.lastDate)) || data[0].lastDateOfCover,
            deductible:parseInt(dedecutibles) ||parseInt(data[0].deductible) ,
            policyLimit:parseInt(policyLimit)  || data[0].policyLimit,
            planType:vtcPlanType || data[0].listOfInsured[0].insuredPlan,
            departureDate: formatDatePicker(new Date(formData.vtcDepartureDate)) || data[0].departureFromCountryOfOrigin,
            supervisa: superVisa || data[0].superVisa===1?true:false,
            insuredDetailsRequests: updatedTravelers,
            policyType:policyType || data[0].product,
            familyPolicy:familyCoverage || data[0].familyCoverage===1?true:false,
            paymentFrequency:superVisa?parseInt(policyTypeSuperVisa):0 || data[0].paymentFrequency
        };
        console.log(dataToLog,"Form submit data")
        dispatch(advisorCalculateVtc(dataToLog)).then(res => {
          const {status, data} = res
          if(status===200){
            console.log(data,'datavtcccc')
            setShowCosts(true)
            setVtcData(res.data)
            setCostsData(data.listOfInsuredQutation)
            const newTravelers = data.listOfInsuredQutation.map((quote, index) => ({
              ...addTravelers[index],
              costWithPreExisting: quote.costWithPreExisting,
              costWithoutPreExisting: quote.costWithoutPreExisting,
            }));
            setAddTravelers(newTravelers)
          }
        })
    };
  
    const handleStudentModalClose = () => {
      setIsStudentModalOpen(false);
    };
    const handleVtcModalClose = () => {
      setIsVtcModalOpen(false);
    };
    const validateFormDatas = (formData, vtcPlanType, addTravelers) => {
      if (!formData.firstDate) {
        return "First date of cover must be provided.";
      }
      if (!formData.lastDate) {
        return "Last date of cover must be provided.";
      }
      const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === "" ||
          traveler.relation === ""
      );
      if (isAnyBeneficiaryDetailEmpty) {
        return "Please fill in all beneficiary details.";
      }
      return null;
    };
    const handleUpdateQuote = async () =>{
      if(!amount){
        alert("Please select costing")
        return;
      }
      setShowCosts(false)
      const validationError = validateFormDatas(formData, vtcPlanType, addTravelers);
      const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      const firstDate = new Date(formData.firstDate);
      firstDate.setHours(0, 0, 0, 0);
      if (firstDate < tomorrow) {
        alert("First date of cover must be greater than today.");
        return;
      }
    if(!policyHolder.policyOwnerName){
      alert("Please seclet policy Owner")
      return;
    }
    if (validationError) {
      alert(validationError);
      return;
    }
    if(!formData.clientProvince){
      alert("Please choose province.")
      return;
    }
    if(!formData.firstDate){
      alert("Please select first date of cover")
      return;
    }
    if(!formData.lastDate){
      alert("Please select last date of cover")
      return;
    }
      const { policyOwnerAddress, policyOwnerEmail,policyOwnerCity,policyOwnerPhone,policyOwnerPostalCode} = policyHolder;
      const newErrors = { ...errors };
        if (policyOwnerAddress.length === 0) {
          alert('Please enter valid address')
          return;
        }
        if (policyOwnerEmail.length === 0 || !validateEmail(policyOwnerEmail)) {
          alert('Please enter a valid email');
          return;
        }
        if (policyOwnerCity.length === 0) {
          alert('Please enter valid City')
          return
        }
        if (policyOwnerPhone.length === 0 || !validateMobileNumber(policyOwnerPhone)) {
          alert('Please enter valid Phone');
          return;
        }
        if (policyOwnerPostalCode.length === 0) {
          alert('Please enter valid Postal Code')
          return
        }
        const updatedTravelers = addTravelers.map(traveler => ({
          nameOfInsured: traveler.name,
          dateOfBirth: traveler.dob,
          gender: traveler.gender,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryRelationToInsured: traveler.relation,
          beneficiaryDOB: traveler.benificiaryDob,
          selectedKey: traveler.selectedKey,
          idInsured: traveler.idInsured,
          costWithPreExisting: traveler.costWithPreExisting,
          costWithoutPreExisting: traveler.costWithoutPreExisting
        }));
  
        const dataToSend = {
          idQuote:quoteId,
          quotationNo:id,
          email: policyHolder.policyOwnerEmail, 
          firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
          lastDateOfCover:formatDatePicker(new Date(formData.lastDate)),
          policyEndDate: formatDatePicker(new Date(formData.lastDate)),
          duration:parseInt(duration),
          country:1,
          paymentFrequency: policyTypeSuperVisa,
          province:formData.clientProvince ,
          departureFromCountryOfOrigin:formatDatePicker(new Date(formData.vtcDepartureDate)),
          waitingPeriod:waitingPeriod,
          familyCoverage:familyCoverage===true?1:0,
          policyLimit:parseInt(policyLimit),
          deductile:dedecutibles,
          policyOwner:policyHolder.policyOwnerName,
          policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
          policyOwnerEmail:policyHolder.policyOwnerEmail,
          policyOwnerAddress:policyHolder.policyOwnerAddress,
          policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
          policyOwnerCity:policyHolder.policyOwnerCity ,
          policyOwnerPhone:policyHolder.policyOwnerPhone,
          planType:vtcPlanType,
          supervisa: superVisa,
          insuredTravellerDetailsRequest: updatedTravelers,
          policyType: policyType,
          amount: amount,
      };
      console.log(dataToSend,'dataTo Send')
        dispatch(advisorUpdateQuote(dataToSend)).then((res)=>{
          const {status, data} = res
          if(status===200 || status === 201){
            navigate(`/advisor-view-quote/${id}`)
            alert("Quote updated successfully")
          }
          console.log(res)
        })  
    }
    useEffect(()=>{
        getData()
    },[])

    const getData =()=>{
        Api.GET(`${GET_QUOTE_BY_QUOTATION_NO}/${id}`).then((res)=>{
            setData([res.data])
            const insuredData = res.data.listOfInsured.map(insured => ({
              name: insured.insuredName || "",
              gender: insured.insuredGender || "",
              dob: insured.insuredDob || "",
              relation: insured.beneficiaryRelationToInsured || "",
              benificiaryDob: insured.beneficiaryDob || "",
              benificiaryName: insured.beneficiaryName || "",
              selectedKey: insured.selectedKey || '',
              idInsured: insured.idInsured || '',
              costWithPreExisting:insured.costWithPreExisting || '',
              costWithoutPreExisting:insured.costWithoutPreExisting || '',
              planType:res.data.listOfInsured[0].insuredPlan
            }));

            const initialSelectedCostOptions = {};
            insuredData.forEach((traveler, index) => {
              initialSelectedCostOptions[index] = traveler.selectedKey === 2 ? traveler.costWithPreExisting : traveler.costWithoutPreExisting;
            });
        
            console.log(initialSelectedCostOptions, 'initial selected cost options', insuredData);
        
            setSelectedCostOption(initialSelectedCostOptions);
            const updatedFormData={
              firstDate:res.data.firstDateOfCover,
              lastDate: res.data.lastDateOfCover,
              clientProvince: res.data.policyHolderProvince,
              vtcDepartureDate: res.data.departureFromCountryOfOrigin,
              studentDepartureDate: res.data.departureFromCountryOfOrigin,
            }
            const updatedPolicyHolderData={
              policyOwnerName: res.data.policyHolderName,
              policyOwnerDob:res.data.policyHolderDob,
              policyOwnerEmail:res.data.policyHolderEmail,
              policyOwnerAddress: res.data.policyHolderAddress,
              policyOwnerPostalCode:res.data.policyHolderPostalCode,
              policyOwnerCity: res.data.policyHolderCity,
              policyOwnerPhone: res.data.policyHolderPhone
            }
            setFamilyCoverage(res.data.familyCoverage)
            setAmount(res.data.amount)
            setPolicyHolder(updatedPolicyHolderData)
            setFormData(updatedFormData)
            setDuration(res.data.duration)
            setWaitingPeriod(res.data.waitingPeriod)
            setAddTravelers(insuredData);
            setQuoteId(res.data.idQuote)
            setStudentPolicyLimit(`${res.data.policyLimit}`)
            setPolicyLimit(`${res.data.policyLimit}`)
            setStudentPlanType(`${res.data.listOfInsured[0].insuredPlan}`)
            setVtcPlanType(`${res.data.listOfInsured[0].insuredPlan}`)
            setDeductibles(`${res.data.deductible}`)
            setSuperVisa(res.data.superVisa===1?true:false)
            setCostsData(res.data.listOfInsured)
            setPolicyType(res.data.product)
            setPolicyTypeSuperVisa(res.data.paymentFrequency)
        })
    }
    console.log(policyTypeSuperVisa,'policy type suepe')

    const getMaxDate = () => {
      const today = new Date();
      today.setDate(today.getDate() + 180); // Add 180 days
      return today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
    };

    function formatDate(date) {
      // Ensure date is a valid Date object
      if (!(date instanceof Date) || isNaN(date)) {
        console.error('Invalid date object:', date);
        return ''; // Or throw an error, depending on your error handling strategy
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const datePickerRefs = {
      firstDate: useRef(null),
      lastDate: useRef(null),
      vtcDeparture: useRef(null),
      studentDeparture: useRef(null),
      // Add more refs for additional date pickers if needed
    };

    const handleDatePickerIconClick = (pickerRef) => {
      if (pickerRef.current) {
        pickerRef.current.setOpen(true); // Open the DatePicker
      }
    };

return (
    <>
    <Box pt={['110px','60px','60px']} pb={'20px'} backgroundColor={'grey'}>
     <Tabs boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' backgroundColor={'white'} isFitted variant='enclosed' w={['95%','80%','80%']} m={'auto'}>
          <TabList border={'2px solid grey'} borderTop={'none'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              paddingTop: '5px',
              color: 'black',
              fontSize: '16px',
              fontWeight: '800',
              fontFamily: 'poppins',
            }}>
            <Tab>Edit Quote</Tab>
            {/* <Tooltip bg='white' hasArrow arrowShadowColor='teal' placement='bottom' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Click here to proceed and purchase the policy.'>  */}
            <Tab isDisabled>Confirm & Pay</Tab>
            {/* </Tooltip> */}
          </TabList>
          <TabPanels>
          <TabPanel>
          {data && data.map(ele=>(  
            <Box pt={'10px'} border={''}>
            <Box w={'95%'} m={'auto'}>
                    <Flex direction={['column','column','row']} justifyContent={'space-around'} fontSize={'12px'} gap={['10px','15px','5px']} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>First Date of Cover</Text>
                            {/* <div className="date-picker-container">
                              <DatePicker
                                selected={formData.firstDate}
                                // onChange={date => handleChange(date, 'firstDate')}
                                // onRawChange={event => handleChange(event.target.value, 'firstDate')}
                                //   onBlur={event => {
                                //     handleChange(event.target.value, 'firstDate');
                                //   }}
                                onChange={date => handleChange(date, 'firstDate')}
                                onBlur={event => handleChange(event.target.value, 'firstDate')}
                                onRawChange={event => handleChange(event.target.value, 'firstDate')}
                                dateFormat="yyyy-MM-dd"
                                minDate={new Date().toISOString().split('T')[0]}
                                maxDate={getMaxDate()}
                                className="custom-date-picker"
                                placeholderText='yyyy-mm-dd'
                                ref={datePickerRefs.firstDate}
                                showYearDropdown
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                              />
                              <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.firstDate)} />
                            </div> */}
                            <div className="date-picker-container">
                                <DatePicker
                                    selected={formData.firstDate}
                                    onChange={date => handleChange(date, 'firstDate')}                                
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-date-picker"
                                    placeholderText='yyyy-mm-dd'
                                    minDate={new Date().toISOString().split("T")[0]}
                                    maxDate={getMaxDate()}
                                    showYearDropdown
                                    showMonthDropdown
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    ref={datePickerRefs.firstDate}
                                />
                                <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.firstDate)}  />
                              </div>
                            </Box>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>Last Date of Cover</Text>
                              {/* <div className="date-picker-container">
                                <DatePicker
                                  selected={formData.lastDate}
                                  // // onChange={date => handleChange(date, 'lastDate')}
                                  // onRawChange={event => handleChange(event.target.value, 'lastDate')}
                                  // onBlur={event => {
                                  //   handleChange(event.target.value, 'lastDate');
                                  // }}
                                  onChange={date => handleChange(date, 'lastDate')}
                                  onBlur={event => handleChange(event.target.value, 'lastDate')}
                                  onRawChange={event => handleChange(event.target.value, 'lastDate')}
                                  dateFormat="yyyy-MM-dd"
                                  className="custom-date-picker"
                                  placeholderText='yyyy-mm-dd'
                                  ref={datePickerRefs.lastDate}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                />
                                <FaCalendarAlt className="date-icon"onClick={() => handleDatePickerIconClick(datePickerRefs.lastDate)} />
                              </div> */}
                              <div className="date-picker-container">
                              <DatePicker
                                  selected={formData.lastDate}
                                  onChange={date => handleChange(date, 'lastDate')}                                
                                  dateFormat="yyyy-MM-dd"
                                  className="custom-date-picker"
                                  placeholderText='yyyy-mm-dd'
                                  minDate={formData.firstDate}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                  ref={datePickerRefs.lastDate}
                              />
                              <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.lastDate)} />
                            </div>
                            </Box>
                            <Tooltip hasArrow label='No. of Days policy will be active.' bg='gray.300' color='black' placement='top-start'>
                            <Box w={['auto','7%','7%']}>
                            <Text fontWeight={'700'}>Duration</Text>
                            <Input size='xs' backgroundColor={'#eaeaea'} value={`${duration} days`} border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                            </Tooltip>
                            <Box w={['auto','20%','10%']}>
                            <Text fontWeight={'700'}>Country</Text>
                                <Text>Canada</Text>
                            </Box>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>Province</Text>
                            <Select name="clientProvince" value={formData.clientProvince} onChange={handleChange} size={'xs'} border={'1px solid #d7d7d7'}>
                                <option value="">Select</option>
                                <option value="Alberta">Alberta</option>
                                <option value="British Columbia">British Columbia</option>
                                <option value="Manitoba">Manitoba</option>
                                <option value="New Brunswick">New Brunswick</option>
                                <option value="Newfoundland & Labrador">Newfoundland & Labrador</option>
                                <option value="Northwest Territories">Northwest Territories</option>
                                <option value="Nova Scotia">Nova Scotia</option>
                                <option value="Nunavut">Nunavut</option>
                                <option value="Ontario">Ontario</option>
                                <option value="Prince Edward Island">Prince Edward Island</option>
                                <option value="Quebec">Quebec</option>
                                <option value="Saskatchewan">Saskatchewan</option>
                                <option value="Yukon">Yukon</option>
                            </Select>
                            </Box>
                        </Flex>
            {addTravelers.map((item, index) => {
                    return (            
                    <Box m={'auto'} mt={'7px'} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                        <Box mt={'5px'} backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'}>
                        <Flex direction={['column','column','row']} gap={['10px','20px','20px']}>
                            <Box w={['auto','20%','30%']}>
                            <Text fontWeight={'700'}>Name Of Insured</Text>
                            <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="name"
                            value={item.name}
                            onChange={(e) => handleChangeTraveler(index, e)}                     
                            />            
                            </Box>
                            <Box w={['auto','20%','30%']}>
                            <Text fontWeight={'700'}>Gender</Text>
                            <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                            value={item.gender}
                            name="gender"
                            onChange={(e) => handleChangeTraveler(index, e)}>
                                <option value="">Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </Select>
                            </Box>
                            <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                            <Box w={['auto','20%','20%']}>
                            <Flex justifyContent={'space-between'}>
                              <Text fontWeight={'600'} fontSize={'11px'}>Date of Birth* (Traveler {index+1})</Text>
                                <Text fontWeight={'600'} color={'teal'}>{item.age} yrs</Text>
                              </Flex>
                              <div className="date-picker-container">
                                  <DatePicker
                                    selected={item.dob}
                                    onChange={(date) => handleChangeTraveler(index, date,'dob')}
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-date-picker"
                                    maxDate={getMaxSelectableDate()}
                                    minDate={getMinSelectableDate()} 
                                    placeholderText='yyyy-mm-dd'
                                  />
                                  <FaCalendarAlt className="date-icon" />
                                </div>        
                            </Box>
                            </Tooltip>
                        </Flex>
                        <Flex direction={['column','column','row']} gap={['10px','20px','20px']} mt={'10px'}>
                            <Box w={['auto','20%','30%']}>
                            <Text fontWeight={'700'}>Beneficiary Name</Text>
                            <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="benificiaryName"
                            onChange={(e) => handleChangeTraveler(index, e)}
                            value={item.benificiaryName}/>            
                            </Box>
                            <Box w={['auto','20%','30%']}>
                            <Text fontWeight={'700'}>Relation</Text>
                            <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="relation"
                            onChange={(e) => handleChangeTraveler(index, e)}
                            value={item.relation}
                            />            
                            </Box>  
                            <Box w={['auto','20%','30%']}>
                            <Text fontWeight={'600'}>Beneficiary DOB</Text>
                            <div className="date-picker-container">
                              <DatePicker
                                 selected={item.benificiaryDob}
                                 onChange={(date) => handleChangeTraveler(index, date,'benificiaryDob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={getMaxSelectableDate()}
                                placeholderText='yyyy-mm-dd'
                              />
                              <FaCalendarAlt className="date-icon" />
                            </div>         
                            </Box>
                        </Flex>
                        </Box>
                        {/* // ))} */}
                        {index > 0 && (
                                <Flex justifyContent={'end'}>
                                    <IoMdCloseCircle
                                    style={{fontSize:'22px',color:buttonBackgroundColor}}
                                    onClick={() => handleRemoveTraveller(index)}
                                    // onClick={() => handleRemoveDependent(index)}
                                    />
                                </Flex>
                                )}
                        </Box>
                        );
              })}
                    <Flex fontFamily={'poppins'} fontSize={'12px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                        <Flex alignItems={'center'}>
                        <IoMdAdd style={{fontWeight:'bold'}}/>
                            </Flex>
                        <Text onClick={handleAddTraveller}>Add a Spouse Or Dependent</Text>
                    </Flex>
                        <hr />
                        {
                            policyType==='VTC' || policyType==="Supervisa"?<Box mt={'15px'}>
                            <Flex direction={['column','column','row']} gap={['10px','20px','60px']} fontSize={'13px'}>
                            <Flex w={['auto','20%','20%']} direction={'column'} alignItems={['start','center','start']}>
                            <Text fontSize={'12px'}>Departure from country of origin</Text>
                            <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="vtcDepartureDate"
                            onChange={handleChange}
                            value={formData.vtcDepartureDate}
                            max={formData.firstDate}
                            />       
                            </Flex>
                            <Box>
                            <Text>Waiting Period</Text>
                            <input
                            name="waiting"
                            value={waitingPeriod}
                            readOnly
                            />
                            </Box>  
                            <Flex direction={'column'} alignItems={['start','center','center']}>
                            <Text>Family Coverage</Text>
                            <input type='checkbox' size='md' name='1' colorScheme='green'
                            value={familyCoverage} onChange={handleFamilyCoverageChange} checked={familyCoverage?true:false}></input>
                            </Flex>   
                            <Flex direction={'column'} alignItems={['start','center','center']}>
                            <Text>Super Visa</Text>
                            {/* <Text>{ele.superVisa===0?'NO':"YES"}</Text> */}
                            <input type='checkbox' size='md' value={superVisa} onChange={handleSuperVisaChange} colorScheme='green' checked={superVisa} ></input>
                            </Flex>
                            {superVisa?(
                                    <>
                                
                                    <Box>
                                    <Text mb={'5px'} fontSize={'12px'}>Student Policy Option</Text>
                                        <Select size={'xs'} value={policyTypeSuperVisa} onChange={(event) =>setPolicyTypeSuperVisa(event.target.value)}>
                                        <option value="0">One lump sum</option>
                                        <option value="1">One lump sum</option>
                                        <option value="2">Monthly</option>
                                        </Select>
                                    </Box>
                                    </>
                                ):(<> <br /> </>)}
                            </Flex>
                            <Flex direction={['column','column','row']} justifyContent={'space-between'} gap={'50px'} mt={['5px','15px','15px']}>
                            <Box>
                                <Text fontSize={'14px'} mt={['5px','15px','15px']} mb={'7px'}>Policy Limit</Text>
                                <Stack spacing={[2,2,4]} direction='row' w={'40%'}>
                                {superVisa ? (
                                    <>
                                    <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                                    $100000
                                    </RadioOption>
                                    <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                                    $150000
                                    </RadioOption>
                                    <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                                    $300000
                                    </RadioOption>
                                    </>
                                ) : (
                                  <Flex direction={['column','column','row']} gap={[2,2,4]}>
                                     <Flex gap={[2,2,4]}>
                                    <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                                        $25000
                                    </RadioOption>
                                    <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                                        $50000
                                    </RadioOption>
                                    <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                                        $100000
                                    </RadioOption>
                                    </Flex>
                                    <Flex gap={[2,2,4]}>
                                    <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                                        $150000
                                    </RadioOption>
                                    <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                                        $300000
                                    </RadioOption>
                                    </Flex>
                                    </Flex>
                                )}
                                </Stack>
    
                            </Box>
                            <Box>
                                <Text fontSize={'14px'} mt={['1px','15px','15px']} mb={'7px'}>Select Plan Type</Text>
                                <Stack spacing={2} direction='row'>
                                <RadioOption value='Standard' isSelected={vtcPlanType === 'Standard'} onChange={handlePlanTypeChange}>
                                    Standard</RadioOption>
                                <RadioOption value='Enhanced' isSelected={vtcPlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                                    Enhanced</RadioOption>
                                <RadioOption value='Premium' isSelected={vtcPlanType === 'Premium'} onChange={handlePlanTypeChange}>
                                Premium </RadioOption>
                                </Stack>
                            </Box>
                            </Flex>
    
    
                            <Flex direction={['column','column','row']} gap={['10px','90px','90px']} mt={['10px','25px','25px']} mb={'25px'}>
                            <Box>
                                <Text fontSize={['10px','12px','14px']}>Deductible</Text>
                                {
                      vtcPlanType==='Premium'?<Stack spacing={[2,2,4]}>
                      <Flex gap={[2,2,4]} direction={['column','column','row']}>
                        <Flex gap={[2,2,4]}>
                        <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                        $500</RadioOption>
                        <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                        $1000</RadioOption>
                        <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                        $3000</RadioOption>
                        </Flex>
                        </Flex>
                      </Stack>:<Stack spacing={[2,2,4]}>
                    <Flex gap={[2,2,4]} direction={['column','column','row']}>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                       $0</RadioOption>
                      <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                       $100</RadioOption>
                      <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                       $250 </RadioOption>
                       </Flex>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                      </Flex>
                      </Flex>
                    </Stack>
                    }
                            </Box>
                            <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
                                <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleVtcCalculate}>Calculate Premium</button>
                            </Flex>
                            
                            </Flex>
                             </Box>:
                             <Box pt={'15px'}>
                             <Flex direction={['column','column','row']} gap={['10px','90px','60px']} fontSize={'13px'}>
                               <Flex direction={'column'} alignItems={'start'} gap={'5px'}>
                               <Text fontSize={'12px'} textAlign={'left'}>Departure from country of origin</Text>
                               {/* <Input type='date' background={'white'} w={'180px'} size='xs' border={'1px solid #d7d7d7'} 
                               name="studentDepartureDate"
                               onChange={handleChange}
                               max={formData.firstDate}
                               value={formData.studentDepartureDate || ele.departureFromCountryOfOrigin}/>        */}
                                <div className="date-picker-container">
                                  <DatePicker
                                    selected={formData.studentDepartureDate}
                                    onChange={date => handleChange(date, 'studentDepartureDate')}
                                    maxDate={formData.firstDate} 
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-date-picker"
                                    placeholderText='yyyy-mm-dd'
                                  />
                                  <FaCalendarAlt className="date-icon" />
                                </div>
                               </Flex>
                               <Box>
                               <Text>Waiting Period</Text>
                               <input
                                 name="waiting"
                                 value={studentWaitingPeriod || ele.waitingPeriod}
                               />                  </Box>  
                               <Flex direction={'column'} alignItems={'center'}>
                               <Text mb={'5px'}>Student Policy Option</Text>
                               <Select size={'xs'} value={studentPolicyOption} onChange={handleStudentPolicyOption}>
                                 <option value="Single Trip">International Student Policy -Single Trip</option>
                                 <option value="Annual">International Student Policy - Annual</option>
                               </Select>
                               </Flex>   
                             </Flex>
                             <Flex gap={'90px'} mt={'25px'} mb={'25px'}>
                             <Box>
                                   <Text fontSize={'14px'} mb={'7px'}>Policy Limit</Text>
                                   <Stack spacing={4} direction='row'>
                                     <RadioOption value='2000000' isSelected={studentPolicyLimit === '2000000'} onChange={handleStudentPolicyLimitChange}>
                                     $2000000</RadioOption>
                                   </Stack>
                                 </Box>
                             <Box>
                                 <Text fontSize={'14px'} >Select Plan Type</Text>
                                 <Stack spacing={4} direction='row'>
                                     <RadioOption value='Standard' isSelected={studentPlanType === 'Standard'} onChange={handleStudentPlanTypeChange}>
                                       Standard</RadioOption>
                                     <RadioOption value='Enhanced' isSelected={studentPlanType === 'Enhanced'} onChange={handleStudentPlanTypeChange}>
                                       Enhanced</RadioOption>
                                     <RadioOption value='Premium' isSelected={studentPlanType === 'Premium'} onChange={handleStudentPlanTypeChange}>
                                      Premium </RadioOption>
                                   </Stack>
                               </Box>
                               <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
                                   <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleStudentCalculate}>Calculate Premium</button>
                                   <Text fontSize={'12px'} color={'blue'}>See Benefit Comparison</Text>
                               </Flex>
                             </Flex>
                             </Box>
                        }
                        
                    <hr />
        <Flex direction={['column','column','row']}>
            <Box w={['95%','60%','60%']}>
        <TableContainer >
            <Table>
                <Thead>
                    <Tr gap={'50px'}>
                        <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                        <Th p={'7px'} fontSize={'10px'}>Plan  Name</Th>
                        <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                        <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                    </Tr>
                </Thead>
                <Tbody> 

                {showCosts && (policyType === 'VTC' || policyType === 'Supervisa') && addTravelers?.map((eles, index) => (
                    <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                        <Td p={'2px'}>{eles.name || eles.insuredName}</Td>
                        <Td p={'2px'}>{eles.planType}</Td>
                        <Td p={'2px'} display={'flex'}>
                            <input
                                type="radio"
                                name={`costOption-${index}`}
                                value={eles.costWithPreExisting}
                                disabled={eles.costWithPreExisting===0}
                                checked={selectedCostOption[index] === eles.costWithPreExisting}
                                onChange={() => handleCostOptionChange(index, eles.costWithPreExisting, eles)}
                            />
                            <Text pl={'10px'}>
                                {Math.round(eles.costWithPreExisting * 100) / 100 || eles.costWithPreExisting}
                            </Text>
                        </Td>
                        <Td p={'2px'}>
                            <Flex>
                                <input
                                    type="radio"
                                    name={`costOption-${index}`}
                                    value={eles.costWithoutPreExisting}
                                    disabled={eles.costWithoutPreExisting === 0 || eles.costWithoutPreExisting==='0.0'}
                                    checked={selectedCostOption[index] === eles.costWithoutPreExisting}
                                    onChange={() => handleCostOptionChange(index, eles.costWithoutPreExisting, eles)}
                                />
                                <Text pl={'10px'}>
                                    {Math.round(eles.costWithoutPreExisting * 100) / 100 || eles.costWithoutPreExisting}
                                </Text>
                            </Flex>
                        </Td>
                    </Tr>
                ))} 

                  { showCosts && (policyType === 'STC' || policyType === 'Studentvisa') && addTravelers?.map((ele, index) => (
                                          <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                              <Td p={'2px'}>Travler {index+1}</Td>
                                              <Td p={'2px'}>{ele.planType}</Td>
                                              <Td p={'2px'} display={'flex'}>
                                                  <Text pl={'10px'}>
                                                    {Math.round(ele.costWithPreExisting * 100) / 100}
                                                  </Text>
                                                </Td>
                                                <Td p={'2px'}>
                                                  <Flex>
                                                  <Text pl={'10px'}>NA</Text>
                                                    </Flex>
                                                </Td>
                                          </Tr>
                    ))}

                </Tbody>
            </Table>
            </TableContainer>
            </Box>
            <Box w={['95%','60%','40%']}>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th p={'7px'} fontSize={'10px'}>Net</Th>
                            <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                            <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                            <Th p={'7px'} fontSize={'10px'}>Total</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                            <Tr fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                <Td p={'2px'}> $ {(policyType === 'STC' || policyType === 'Studentvisa') ?amountWithoutAdjustment:amount}</Td>
                                <Td p={'2px'}>0.00 CAD	</Td>
                                <Td p={'2px'}>{policyType==='STC' || 'Studentvisa'?adjustment:'0'} CAD</Td>
                                <Td p={'2px'} fontWeight={'600'}>$ {Math.round(amount  * 100) / 100}</Td>
                            </Tr>
                    </Tbody>
                </Table>
            </TableContainer></Box>
        </Flex>
        <Text color={'red'} fontSize={'11px'}>{message}</Text>
                        <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                  <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'14px'} pb={'20px'} mt={'20px'}>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner*</Text>
                        <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName ||ele.policyHolderName} onChange={handlePolicyOwnerChange}>
                        <option value="">Select Policy Owner</option>
                        {(formData.firstDate && formData.lastDate) && (vtcPlanType || studentPlanType) ? addTravelers.map((traveler, index) => (
                          <option key={index} value={traveler.name}>
                            {traveler.name}
                          </option>
                        )) : null}
                        </Select>
                        </Box>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner Date Of Birth*</Text>
                        <Input type='date' size='xs' readOnly name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                        </Box>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner Email*</Text>
                        <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                        <Text color="red.500">{errors.policyOwnerEmail}</Text>        
                        </Box>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner Address*</Text>
                        <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                        <Text color="red.500">{errors.policyOwnerAddress}</Text>       
                        </Box>
                    </Flex>
                    <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'14px'} pb={'20px'} mt={['10px','20px','20px']}>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner Postal Code*</Text>
                        <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                        <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
                        </Box>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner City*</Text>
                        <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                        <Text color="red.500">{errors.policyOwnerCity}</Text>         
                        </Box>
                        <Box w={['80%','20%','20%']}>
                        <Text>Policy Owner Phone*</Text>
                        <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                        <Text color="red.500">{errors.policyOwnerPhone}</Text>
                        </Box>
                    </Flex>
                    <Flex justifyContent={'end'}>
                        <Button size={'sm'} onClick={handleUpdateQuote} color={buttonColor} backgroundColor={buttonBackgroundColor}>Update Quote</Button>
                    </Flex>
                    </Box>
            </Box>
            ))}
            </TabPanel>
            <TabPanel>
            </TabPanel>
            </TabPanels>
    </Tabs>
    </Box>
      <BeneficiaryDetailsModal isOpen={isStudentModalOpen} onClose={handleStudentModalClose} onSubmit={handleStudentModalSubmit} />
      <HealthQuestions isOpen={isQuestionerModalOpen} onClose={handleQuestionersClose} onSubmit={handleQuestionersSubmit}/>
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleVtcModalSubmit} />
      <FamilyModal isOpen={isFamilyModel} onClose={handleFamilyModel} />
    </>
  )
}

export default AdvisorEditQuote
