import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Plan from "./SelectPlan";

function TripDetails() {
  const [tripDetails, setTripDetails] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [modal, setModal] = useState(false);

  // handle traveller and trip details page
  const handleToTravellerDetails = () => {
    setTripDetails(true);
  };
  const handleBackEvent = () => {
    setTripDetails(false);
  };

  // navigate to Select plan Page
  const handlePlan = () => {
    setOpenPlan(true);
    closeModalEvent();
  };

  // Open and close Modal
  const openModalEvent = () => {
    setModal(true);
  };
  const closeModalEvent = () => {
    setModal(false);
  };

  return (
    <div style={{ width: "100%", padding: "20px" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            fontFamily: "poppins",
            fontSize: "22px",
            fontWeight: 700,
            marginBottom: "30px",
            alignItems: "flex-start",
          }}
        >
          Super Visa Insurance 
        </p>
        {!openPlan && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "20px",
                borderBottom: "2px solid #088183",
              }}
            >
              Traveller Details single
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "20px",
                margin: "auto",
                padding: "18px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                <Input style={{ width: "100%" }} placeholder="Basic usage" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Gender</span>
                <Select style={{ width: "100%" }} placeholder="Select Gender">
                  <option value="male">male</option>
                  <option value="female">Female</option>
                </Select>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Beneficiary Name</span>
                <Input style={{ width: "100%" }} placeholder="Basic usage" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Relation</span>
                <Input style={{ width: "100%" }} placeholder="Basic usage" />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                <Input
                  style={{ width: "100%" }}
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  gap: "8px",
                }}
              >
                <button
                  onClick={handleBackEvent}
                  style={{
                    backgroundColor: "red",
                    color: "#fff",
                    padding: "6px 12px",
                    fontWeight: 400,
                    cursor: "pointer",
                    borderRadius: "4px",
                    width: "fit-content",
                    alignSelf: "flex-end",
                  }}
                >
                  Previous
                </button>
                <button
                  onClick={openModalEvent}
                  style={{
                    backgroundColor: "#24b6b7",
                    color: "#fff",
                    padding: "6px 12px",
                    fontWeight: 400,
                    cursor: "pointer",
                    borderRadius: "4px",
                    width: "180px",
                    alignSelf: "flex-end",
                  }}
                >
                  Proceed & Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {!openPlan && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
              alignItems: "flex-start",
              marginBottom: "40px",
            }}
          >
            <div style={{ display: "flex" }}>
              <CheckCircleIcon w={6} h={6} />
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "18px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Our Product Qualifies as per Government of Canada Guidelines for
                Min of CAD 100,000/-of Sum Insured for min duration of 365 days
                from Canadian Insurance Company.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleIcon w={6} h={6} />
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "18px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                No Cancellation Penalty, Pay only for period of stay.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <CheckCircleIcon w={6} h={6} />
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "18px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Age from 15 days to 90 Years & 3 Product Choices Standard,
                Enhanced and Premium.
              </p>
            </div>{" "}
            <div style={{ display: "flex" }}>
              <CheckCircleIcon w={6} h={6} />
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "18px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Just 90 days stability condition up to age 74.
              </p>
            </div>{" "}
            <div style={{ display: "flex" }}>
              <CheckCircleIcon w={6} h={6} />
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "18px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Covers you for any unforeseen Emergency Medical, Physician &
                Hospitalization expenses, Diagnostics, Prescriptions and Dental
                etc.
              </p>
            </div>
          </div>
        )}

        {openPlan === true && <Plan />}

        {modal === true && (
          <Modal isOpen={openModalEvent} onClose={closeModalEvent}>
            <ModalOverlay />
            <ModalContent
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <div
                style={{
                  width: "800px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#48b7b7",
                  padding: "30px",
                  color: "white",
                  fontFamily: "poppins",
                }}
              >
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: 800,
                    paddingBottom: "4px",
                  }}
                >
                  To be eligible for coverage, on the effective date, you must :
                </p>
                <p style={{ paddingBottom: "4px" }}>
                  1. Be a visitor to Canada or a person in Canada under a valid
                  work or student visa, a Canadian or an immigrant not eligible
                  for benefits under a government health insurance plan; and
                </p>
                <p style={{ paddingBottom: "4px" }}>
                  2. be at least 15 days of age and less than 90 years of age
                  (less than 70 years of age for Premium plan); and
                </p>
                <p style={{ paddingBottom: "4px" }}>
                  3. not be travelling against the advice of a physician and/or
                  not have been diagnosed with a terminal illness; and
                </p>
                <p style={{ paddingBottom: "4px" }}>
                  4. not be experiencing new or undiagnosed signs or symptoms
                  and/or know of any reason to seek medical attention; and
                </p>
                <p style={{ paddingBottom: "4px" }}>
                  5. not require assistance with the activities of daily
                  living(dressing, bathing, eating, using the toilet or getting
                  in or out of a bed or chair).
                </p>
                <p style={{ fontSize: "20px", fontWeight: 700 }}>
                  I confirm that all travellers are eligible to purchase this
                  policy
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    color: "white",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    style={{
                      color: "black",
                      marginRight: "10px",
                      padding: "20px",
                    }}
                    onClick={handlePlan}
                  >
                    Yes
                  </Button>
                  <Button
                    style={{
                      color: "black",
                      marginRight: "10px",
                      padding: "20px",
                    }}
                    onClick={closeModalEvent}
                  >
                    No
                  </Button>
                </div>
              </div>
              <ModalCloseButton />
            </ModalContent>
          </Modal>
        )}
      </div>
    </div>
  );
}

export default TripDetails;
