import React, { useEffect, useState,forwardRef, useRef } from 'react';
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels, Flex, Text, Button,Input,Select,TableContainer,Table,Thead,Tr,Td,Th,Tbody,Radio,Stack,useToast, Tooltip, Textarea, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, GridItem, Grid} from '@chakra-ui/react';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import './styles.css'
import RadioOption from '../../../Utils/RadioCard';
import CustomInputs, {BeneficiaryDetailsModal, FamilyModal, VTCDetailsModal, getMaxSelectableDate, getMinSelectableDate, validateEmail, validateMobileNumber} from './Validation';
import { useDispatch } from 'react-redux';
import {advisorCalculateVtc, advisorGenerateQuote} from '../Dependencies/action'
import Api from '../../../dependencies/utils/Api';
import { useNavigate } from 'react-router-dom';
import { ADVISOR_SAVE_POLICY, API_URL, DOWNLOAD_BENEFIT_COMPARISON } from '../../../constants/ApplicationUrl';
import { saveAs } from 'file-saver';
import HealthQuestions from '../../../Utils/HealthQuestions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { format } from 'date-fns';
import { formatDatePicker } from '../../../Utils/DateFormate';
// import {formatDatePicker} from '../../../Utils/DateFormate/formatDatePicker'


const AdvisorGenerateQuote = () => {
  const [policyLimit, setPolicyLimit] = useState('');
  console.log(policyLimit)
  const [studentPolicyLimit, setStudentPolicyLimit] = useState('');
  const [vtcPlanType,setVtcPlanType]=useState('')
  const [studentPlanType,setStudentPlanType]=useState('')
  const [dedecutibles,setDeductibles]=useState ('')
  const toast=useToast()
  const [waitingPeriod, setWaitingPeriod] = useState("");
  const [studentWaitingPeriod, setStudentWaitingPeriod] = useState("");
  const [duration,setDuration]=useState('')
  const [superVisa,setSuperVisa]=useState(false)
  const [familyCoverage,setFamilyCoverage]=useState(false)
  const [policyType, setPolicyType] = useState('VTC');
  const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [isQuestionerModalOpen, setIsQuestionerModalOpen] = useState(false);
  const [vtcData,setVtcData]=useState([])
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState(0)
  const dispatch=useDispatch()
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [message,setMessage]=useState('')
  const [amount,setAmount]=useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const [questions,setQuestions]=useState([])
  const navigate=useNavigate()
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"));
  const [isFamilyModel,setIsFamilyModel]=useState(false)
  const [currentTravelerIndex, setCurrentTravelerIndex] = useState(null);
  const [currentDob, setCurrentDob] = useState('');
  const [adjustment,setAdjustment]=useState(0)
  const [amountWithoutAdjustment,setAmountWithoutAdjustment]=useState(0)
  const [msg,setMsg]=useState(0)
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: " ",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);

  const [travelerDob,setTravelerDob]=useState([
    {
      name: "",
      gender: "",
      dob: null,
      relation: "",
      benificiaryDob: null,
      benificiaryName: "",
      age:''
    },
  ])

  const [formData, setFormData] = useState({
    firstDate: '',
    lastDate: '',
    clientCountry: 'canada',
    clientProvince: '',
    vtcDepartureDate: "",
    studentDepartureDate: "",
  });

  const resetCosts = () => {
    setMsg(0)
    setAmount(0)
    setVtcData([])
    setMessage('')
    setSelectedCostOption('')
    setAdjustment('0')
  };

  const [policyHolder, setPolicyHolder] = useState({
    policyOwnerName: '',
    policyOwnerDob: '',
    policyOwnerEmail: '',
    policyOwnerAddress: '',
    policyOwnerPostalCode: '',
    policyOwnerCity: '',
    policyOwnerPhone: ''
  });

  const [errors, setErrors] = useState({
    firstDate: "",
    lastDate: "",
    vtcDepartureDate: "",
    policyOwnerEmail:'',
    policyOwnerAddress:'',
    policyOwnerPostalCode:'',
    policyOwnerCity:'',
    policyOwnerPhone:'',
    cardHolderFirstName: '',
    cardHolderLastName: '',
    cardHolderEmail: '',
    cardHolderPostal: '',
    cardHolderPhone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: ''
  });

  const handleCostOptionChange = (index, value, ele) => {
    console.log(index,value,ele,'details after click')
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [index]: value
    }));
    const adjustment=ele.adjustment
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
    const totalValue = totalSelectedValue + value;
    const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
    const selectedKey = (value === ele.costWithPreExisting) ? '2' : '3';
    // const calculatePreMessage = (totalValue) => {
    //   const dividedValue = totalValue / 12;
    //   const multipliedValue = dividedValue * 2;
    //   return multipliedValue;
    // };
    const mr = selectedOption === 'withPreExisting' ? vtcData.messageOfPreExisting : vtcData.messageOfWithoutPreExisting;
    console.log(mr,'mr')
    setMsg(mr===null>1?0:1)
    setAdjustment(adjustment)
    setAmount(totalValue);
    // const message = `Advance payment of $ ${Math.round(calculatePreMessage(totalValue))}+ $20(installment charges) would be charged at the time of policy purchase. Rest amount in 10 easy monthly installments of ${Math.round(totalValue/12)}+ $10 (installment charges) each`
    // setMessage(message)
    setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
      if (idx === index) {
        return { ...traveler, selectedKey };
      }
      return traveler;
    }));
  };

  useEffect(()=>{
    const calculatePreMessage = (amount) => {
      const dividedValue = amount / 12;
      const multipliedValue = dividedValue * 2;
      return multipliedValue;
    };
    const message = `Advance payment of $ ${Math.round(calculatePreMessage(amount))}+ $20(installment charges) would be charged at the time of policy purchase. Rest amount in 10 easy monthly installments of ${Math.round(amount/12)}+ $10 (installment charges) each`
    setMessage(message)
  },[handleCostOptionChange])
  
  const handlePolicyOwnerChange = (e) => {
    const { name, value } = e.target;
    setPolicyHolder({ ...policyHolder, [name]: value });
    // setErrors({ ...errors, [e.target.name]: " " });
    const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
    if (selectedPolicyHolder) {
      setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
    }
  };

  useEffect(() => {
      const isAnyAgeBetween27to69 = addTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 27 && age <= 69;
      });
    
      if (!isAnyAgeBetween27to69) {
        setFamilyCoverage(false)
      }
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
      if(!isAllAgeGreaterThanOne) {
        setFamilyCoverage(false)
      }
    if(addTravelers.length<3){
      setFamilyCoverage(false)
    }
    if (!formData.vtcDepartureDate) {
      setWaitingPeriod("NA");
    } else {
      const today = new Date(formData.firstDate);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      const selectedDate = new Date(formData.vtcDepartureDate);
      const firstDate = new Date(formData.firstDate);
      const isSameDate = selectedDate.toDateString() === firstDate.toDateString();      
      const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      if (isSameDate) {
        setWaitingPeriod("NA");
      } else {
        setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      }


      // const today = new Date(formData.firstDate);
      // const thirtyDaysAgo = new Date(today);
      // thirtyDaysAgo.setDate(today.getDate() - 30);
      // const selectedDate = new Date(formData.vtcDepartureDate);
      // const firstDate = new Date(formData.firstDate)
      // const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      // console.log(selectedDate, firstDate, ' selected date first date')
      // if (selectedDate === firstDate) {
      //   setWaitingPeriod("NA");
      // } else {
      //   setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      // }
    }
    if (!formData.studentDepartureDate) {
      setStudentWaitingPeriod("NA");
    } else {
      const formattedDate = format(formData.firstDate, 'yyyy-MM-dd');
      const formateSelected = format(formData.studentDepartureDate,'yyyy-MM-dd')
      const selectedDate = formateSelected
      if (selectedDate === formattedDate) {
        setStudentWaitingPeriod("NA");
      } else {
        setStudentWaitingPeriod("5 days");
      }
    }
  }, [formData.vtcDepartureDate,formData.studentDepartureDate,addTravelers]);


  const handleChange = (dateOrEvent, fieldName) => {
    resetCosts();
    let name, value;
    if (fieldName) {
      name = fieldName;
      value = dateOrEvent;
    } else {
      const event = dateOrEvent;
      name = event.target.name;
      value = event.target.value;
    }
    setFormData({ ...formData, [name]: value });
    // setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (name === "firstDate" || name === "lastDate") {
      setStudentPolicyOption('Single Trip')
      setSuperVisa(false);
    }
    if(formData.lastDate && name ==="firstDate"){
      const endDate = new Date(formData.lastDate);
      const startDate = new Date(value);
      const diffInMilliseconds = endDate - startDate;
      const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
      if (endDate < startDate) {
        alert("Last date cannot be earlier than the first date");
        setFormData((prevData) => ({ ...prevData, lastDate: "" }));
        setDuration("");
      } else if (diffInMilliseconds > oneYearInMilliseconds) {
        alert("Duration cannot exceed 1 year");
        setFormData((prevData) => ({ ...prevData, lastDate: "" }));
        setDuration("");
      } else {
        const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
        setDuration(durationInDays);
      }
      console.log('ravi')
    }
    if (name === "lastDate") {
      console.log(value,'value')
      if (!formData.firstDate) {
        alert("Please select the first date of cover first");
        setFormData((prevData) => ({ ...prevData, lastDate: "" }));
        setDuration("");
        return;
      }
      const startDate = new Date(formData.firstDate);
      const endDate = new Date(value);
      const diffInMilliseconds = endDate - startDate;
      const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
      if (endDate < startDate) {
        alert("Last date cannot be earlier than the first date");
        setFormData({ ...formData, lastDate: "" });
        // setFormData((prevData) => ({ ...prevData, lastDate: "" }));
        setDuration("");
      } else if (diffInMilliseconds > oneYearInMilliseconds) {
        alert("Duration cannot exceed 1 year");
        setFormData({ ...formData, lastDate: "" });
        // setFormData((prevData) => ({ ...prevData, lastDate: "" }));
        setDuration("");
      } else {
        const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
        setDuration(durationInDays);
      }
    
    }
    if (name === "vtcDepartureDate") {
      if (!formData.firstDate) {
        alert("Please select the first date of cover");
        setFormData((prevData) => ({ ...prevData, vtcDepartureDate: "" }));
        return;
      }else if (value > formData.firstDate) {
        alert('Departure date cannot be greater than the first date of cover');
        setFormData((prevData) => ({ ...prevData, vtcDepartureDate: '' }));
        return;
      }
    }
    if (name === "studentDepartureDate") {
      if (!formData.firstDate) {
        alert("Please select the first date of cover");
        setFormData((prevData) => ({ ...prevData, studentDepartureDate: "" }));
        return;
      }else if (value > formData.firstDate) {
        alert('Departure date cannot be greater than the first date of cover');
        setFormData((prevData) => ({ ...prevData, studentDepartureDate: '' }));
        return;
      }
    }
  };

  // const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
  //   console.log(index,dateOrEvent,fieldName,'ddddddd')
  //   setVtcData([]);
  //   let name, value;
  //   if (fieldName) {
  //     name = fieldName;
  //     value = dateOrEvent;
  //   } else {
  //     const event = dateOrEvent;
  //     name = event.target.name;
  //     value = event.target.value;
  //   }
  //   const age = today.getFullYear() - selectedDate.getFullYear();
  //   if (name === 'dob' || name === 'benificiaryDob') {
  //     const today = new Date();
  //     const selectedDate = new Date(value);
  //     const minDate = new Date();
  //     minDate.setDate(minDate.getDate() - 15);

  //     if (selectedDate >= minDate) {
  //       alert('Users must be at least 15 days older than today.');
  //       return;
  //     }

  //     const age = today.getFullYear() - selectedDate.getFullYear();
  //     const monthDiff = today.getMonth() - selectedDate.getMonth();
  //     const dayDiff = today.getDate() - selectedDate.getDate();

  //     if (age > 75 || (age === 75 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
  //       setCurrentTravelerIndex(index);
  //       setCurrentDob(value);
  //       setIsQuestionerModalOpen(true);
  //       return;
  //     }
  //   }

  //   setAddTravelers(prevState => {
  //     const updatedState = [...prevState];
  //     updatedState[index] = { ...updatedState[index], [name]: value };
  //     return updatedState;
  //   });

  //   setTravelerDob((prevState) => {
  //         const updatedState = [...prevState];
  //         updatedState[index] = { ...updatedState[index], [name]: value, age: age };
  //         return updatedState;
  //     }); 
  // };
  
  const handleSuperVisaChange = (e) => {
    resetCosts();
    if (!formData.firstDate) {
      alert("Please select the first date of cover first");
      return;
    }
      const { checked } = e.target;
      if (checked) {
        setPolicyType("Supervisa");
      } else {
        setPolicyType("VTC");
      }
      setSuperVisa(checked);
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear() + 1);
        endDate.setDate(startDate.getDate());
        setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
        setDuration('365')
  };
  
  const handleFamilyCoverageChange = (e) => {
    resetCosts();
    if(addTravelers.length<3){
      alert('Select Atleast 3 member of family')
      return;
    }
    const eldestMember = addTravelers.reduce((eldest, traveler) => {
      const dob = new Date(traveler.dob);
      const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
      if (!eldest || age > eldest.age) {
          return { age, traveler };
      }
      return eldest;
    }, null);
    if (!eldestMember || eldestMember.age < 27 || eldestMember.age > 69) {
        alert("The eldest family member's age should be between 27 and 69 years for family coverage.");
        return;
    }
    const hasChildMember = addTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1 && age <= 26;
    });
    if (!hasChildMember) {
        alert('You must select atleast one child up to 21 or 26 if fully dependent on parents');
        return;
    }
    const dependentChildren = addTravelers.filter((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 21 && age <= 26;
    });
    const olderThan26Count = addTravelers.reduce((count, traveler) => {
      const dob = new Date(traveler.dob);
      const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
      if (age > 26) {
        return count + 1;
      }
      return count;
    }, 0);
    if (olderThan26Count > 2) {
      alert('There should not be more than two family members whose age is more than 26 years.');
      return;
    }
    if (dependentChildren.length > 0 && olderThan26Count<=2) {
      if(familyCoverage===false){
        setIsFamilyModel(true)
      }else{
        console.log('already selected')
      }
    }
    const { checked } = e.target;
    setFamilyCoverage(checked);
  }

  // console.log(addTravelers,'addTravelers')

  const handleFamilyModel = ()=>{
    setIsFamilyModel(false)
    // setFamilyCoverage(false)
  }

  const handlePolicyLimitChange = (value) => {
    resetCosts();
      setPolicyLimit(value);
  };

  const handleStudentPolicyLimitChange = (value) => {
    resetCosts();
    setStudentPolicyLimit(value);
  };
  const handleStudentPolicyOption = (e) => {
    resetCosts();
    const { value } = e.target;
    setStudentPolicyOption(value);
    setPolicyType("Studentvisa");
    if (!formData.firstDate) {
      alert("Please select the first date of cover first");
      return;
    }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear()+1);
        endDate.setDate(startDate.getDate());
        setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
        setDuration('365')
  };
  const handlePlanTypeChange = (value) => { 
    resetCosts();
    setDeductibles()
    setVtcPlanType(value);
  };
  const handleStudentPlanTypeChange = (value) => { 
    resetCosts();
    setDeductibles()
    setStudentPlanType(value);
  };
  const handleDeductiblesChange = (value) => { 
    if(!vtcPlanType){
      alert("Please select plan type.")
      return;
    }
    resetCosts();
    setDeductibles(value);
  };
  const handleAddTraveller = () => {
    resetCosts();
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };
  const handleAddTravellerDob = () => {
    resetCosts();
    handleAddTraveller()
      setTravelerDob([
        ...travelerDob,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };
  const handleQuestionersSubmit = (collectedData) => {
    setTravelerDob(prevState => {
      console.log('Previous State:', prevState);
      const updatedState = [...prevState];
      if (currentTravelerIndex !== null && currentDob !== '') {
        updatedState[currentTravelerIndex] = {
          ...updatedState[currentTravelerIndex],
          dob: currentDob, // Ensure currentDob is not empty
        };
        console.log('Updated State:', updatedState);
      }
      return updatedState;
    });

    setQuestions(collectedData);
    setIsQuestionerModalOpen(false); // Close the modal
  };
  const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
        let name, value;
        if (fieldName) {
          name = fieldName;
          value = dateOrEvent;
        } else {
          const event = dateOrEvent;
          name = event.target.name;
          value = event.target.value;
        }
        console.log(value,name,'valuesss')
    const today = new Date();
    const selectedDate = new Date(value);
    const age = today.getFullYear() - selectedDate.getFullYear();
    if(name === "dob" || name === "benificiaryDob") {
      const dateValue = new Date(value);
        const today = new Date();
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
          alert("Users must be at least 15 days older than today.");
          return;
        }
        const age = today.getFullYear() - selectedDate.getFullYear();
        const monthDiff = today.getMonth() - selectedDate.getMonth();
        const dayDiff = today.getDate() - selectedDate.getDate();
        
        console.log(formatDatePicker(selectedDate),'selectedDate')
        // if (age > 75 || (age === 75 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
        //     setCurrentTravelerIndex(index);
        //     setCurrentDob(value);
        //     setIsQuestionerModalOpen(true);
        //     // return;
        // }
        value = dateValue.toISOString().split('T')[0]; 
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
    setTravelerDob((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      if (name === "dob") {
        const dob = new Date(value);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        updatedState[index] = {
            ...updatedState[index],
            age: age,
        };
    }
      return updatedState;
  }); 
  };

  // const handleQuestionersSubmit = (collectedData)=>{
  //   console.log('hit',currentDob,currentTravelerIndex)
  //   setTravelerDob(prevState => {
  //     const updatedState = [...prevState];
  //     if (currentTravelerIndex !== null) {
  //       updatedState[currentTravelerIndex] = {
  //         ...updatedState[currentTravelerIndex],
  //         dob: currentDob
  //       };
  //     }
  //     return updatedState;
  //   });
  //   setQuestions(collectedData)
  // }

  // console.log(travelerDob,'travelerDob',currentDob,currentTravelerIndex)

  const handleRemoveTraveller = (index) => {
    resetCosts();
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };
  const handleRemoveTravellerDob = (index) => {
    resetCosts();
    handleRemoveTraveller(index)
    const updatedTravelers = [...travelerDob];
    updatedTravelers.splice(index, 1);
    setTravelerDob(updatedTravelers);
  };
  const validateFormData = (formData, vtcPlanType, addTravelers) => {
    if (!formData.firstDate) {
      return "First date of cover must be provided.";
    }
    if (!formData.lastDate) {
      return "Last date of cover must be provided.";
    }
    return null;
  };
  const validateFormDatas = (formData, vtcPlanType, addTravelers) => {
    if (!formData.firstDate) {
      return "First date of cover must be provided.";
    }
    if (!formData.lastDate) {
      return "Last date of cover must be provided.";
    }
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      return "Please fill in all beneficiary details.";
    }
    return null;
  };
  const handleVtcCalculate = async () => {
    setMessage('')
    for (const traveler of travelerDob) {
      if (!traveler.dob) {
        alert("Please enter DOB for all travelers");
        return;
      }
    }
    if(policyLimit.length===0){
      alert("Please choose policy limit")
      return;
    }
    if(!vtcPlanType){
      alert("Please choose plan type")
      return;
    }
    if(!dedecutibles){
      alert("Please choose Deductibles")
      return;
    }
    if(!formData.vtcDepartureDate){
      alert("Please choose date of Departure")
      return;
    }
    if(superVisa){
      setPolicyType('Supervisa')
    }else{
      setPolicyType('VTC')
    }
    const validationError = validateFormData(formData, vtcPlanType, addTravelers);
    if (validationError) {
      alert(validationError);
      return;
    }
    setFormData(prevFormData => ({
      ...prevFormData,
        studentDepartureDate: ''        
    }));
    setStudentPolicyLimit('')
    setStudentPlanType('')
    setIsVtcModalOpen(true);
  };
  const handleStudentCalculate = async () =>{
    setPolicyType('Studentvisa')
    setMessage('')
    setAdjustment(0)
    const calculateAge = (dob) => {
      const today = new Date();
      const birthDate = new Date(dob);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };
    const isAnyTravelerOlderThan65 = travelerDob.some(traveler => {
      if (!traveler.dob) return false;
      const age = calculateAge(traveler.dob);
      return age > 65;
    });
    if (isAnyTravelerOlderThan65) {
      alert('One or more travelers are older than 65 years.');
      return;
    }
    if (!formData.firstDate) {
      alert('First date of cover must be provided.')
      return
    }
    if (!formData.lastDate) {
      alert('Last date of cover must be provided.')
      return
    }
    if (!formData.studentDepartureDate) {
      alert('Departure date must be provided.')
      return
    }
    if (!studentPlanType) {
      alert('Plan Type must be provided.')
      return
    }
    if (!studentPolicyLimit) {
      alert('Policy Limit must be provided.')
      return
    }
    setFormData(prevFormData => ({
      ...prevFormData,
        vtcDepartureDate: ''        
    }));
    setPolicyLimit('')
    setDeductibles('')
    setVtcPlanType('')
    setIsStudentModalOpen(true);
  }
  const handleStudentModalSubmit = () => {
    setMessage('')
    setIsStudentModalOpen(false); 
      const updatedTravelers = addTravelers.map((traveler) => ({
        insuredName: traveler.name,
        insuredGender: traveler.gender,
        insuredDOB: traveler.dob,
        insuredRelation: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDob: traveler.benificiaryDob,
      }));
    const dataToLog = {
        firstDateOfCover: formatDatePicker(new Date(formData.firstDate)) ,
        lastDateOfCover: formatDatePicker(new Date(formData.lastDate)),
        planType:studentPlanType,
        insuredDetailsRequests: updatedTravelers,
        policyType:'Studentvisa',
        tripType:studentPolicyOption
    };
    console.log(dataToLog,"Vtc form submit data")
    dispatch(advisorCalculateVtc(dataToLog)).then(res => {
      const {status, data} = res
      if (status === 200) {
        const response = data.listOfInsuredQutation;
        setAddTravelers(prevAddTravelers => {
          const updatedTravelers = prevAddTravelers.map(traveler => {
            const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
            if (responseTraveler) {
              return {
                ...traveler,
                costWithPreExisting: responseTraveler.costWithPreExisting,
                costWithoutPreExisting: responseTraveler.costWithoutPreExisting,
                selectedKey: 2,
              };
            }
            return traveler;
          });
          const totalCostWithPreExisting = updatedTravelers.reduce((total, traveler) => {
            return total + (traveler.costWithPreExisting || 0);
          }, 0);
          setTotalCost(totalCostWithPreExisting,res);
          return updatedTravelers;
        });
        const setTotalCost = (totalCost,res) => {
          setAdjustment(res.data.adjustment===null?0:res.data.adjustment)
          setAmount(totalCost+Number(res.data.adjustment))
          setAmountWithoutAdjustment(totalCost); 
        };
      }
      // if(status===200){
      //   const response = data.listOfInsuredQutation
      //   setAddTravelers(prevaddTravelers => {
      //     return prevaddTravelers.map(traveler => {
      //       const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
      //       if (responseTraveler) {
      //         return {
      //           ...traveler,
      //           costWithPreExisting: responseTraveler.costWithPreExisting,
      //           costWithoutPreExisting: responseTraveler.costWithoutPreExisting,
      //           selectedKey:2
      //         };
      //       }
      //       return traveler;
      //     });
      //   });
      //   // updateAddTravelers(data.listOfInsuredQutation)
      // }
      console.log(data,'data')
      setVtcData(data)
    })
  };
  const handleVtcModalSubmit = async () => {
    setMessage('')
    setIsVtcModalOpen(false); 
        const updatedTravelers = travelerDob.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
          lastDateOfCover: formatDatePicker(new Date(formData.lastDate)),
          deductible:dedecutibles,
          policyLimit:parseInt(policyLimit),
          planType:vtcPlanType,
          vtcDepartureDate: formatDatePicker(new Date(formData.vtcDepartureDate)),
          supervisa: superVisa,
          insuredDetailsRequests: updatedTravelers,
          policyType:policyType,
          familyPolicy:familyCoverage,
          paymentFrequency:superVisa?parseInt(policyTypeSuperVisa):0,
          tripType:studentPolicyOption
      };
      console.log(dataToLog,"Vtc form submit data")
      dispatch(advisorCalculateVtc(dataToLog)).then(res => {
        const {status, data} = res
        if(status===200){
          updateAddTravelers(data.listOfInsuredQutation)
          setVtcData(data)
        }
        console.log(data,'data')
      })
  };
  const updateAddTravelers = (response) => {
    setAddTravelers(prevaddTravelers => {
      return prevaddTravelers.map(traveler => {
        const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
        if (responseTraveler) {
          return {
            ...traveler,
            costWithPreExisting: responseTraveler.costWithPreExisting,
            costWithoutPreExisting: responseTraveler.costWithoutPreExisting
          };
        }
        return traveler;
      });
    });
  };
  const handleStudentModalClose = () => {
    setIsStudentModalOpen(false);
  };
  const handleVtcModalClose = () => {
    setIsVtcModalOpen(false);
  };
  const handleQuestionersClose = () => {
    setTravelerDob((prevState) => {
      if (prevState.length === 0) return prevState; // If no travelers, return as is
      
      const updatedState = [...prevState];
      const lastIndex = updatedState.length - 1;
  
      // Update the last traveler's dob to an empty string
      updatedState[lastIndex] = {
        ...updatedState[lastIndex],
        dob: "", // Set dob to an empty string or any desired default value
      };
  
      return updatedState;
    });
  
    setIsQuestionerModalOpen(false); // Close the modal
  };
  const handleGetQuote = async () =>{
    const validationError = validateFormDatas(formData, vtcPlanType, addTravelers);
    const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      const firstDate = new Date(formData.firstDate);
      firstDate.setHours(0, 0, 0, 0);
      if (firstDate < tomorrow) {
        alert("First date of cover must be greater than today.");
        return;
      }
    if(!policyHolder.policyOwnerName){
      alert("Please seclet policy Owner")
      return;
    }
    if (validationError) {
      alert(validationError);
      return;
    }
    if(!formData.clientProvince){
      alert("Please choose province.")
      return;
    }
    const { policyOwnerAddress, policyOwnerEmail,policyOwnerCity,policyOwnerPhone,policyOwnerPostalCode} = policyHolder;
    const newErrors = { ...errors };
      if (policyOwnerAddress.length === 0) {
        alert('Please enter valid address')
        return;
      }
      if (policyOwnerEmail.length === 0 || !validateEmail(policyOwnerEmail)) {
        alert('Please enter a valid email');
        return;
      }
      if (policyOwnerCity.length === 0) {
        alert('Please enter valid City')
        return
      }
      if (policyOwnerPhone.length === 0 || !validateMobileNumber(policyOwnerPhone)) {
        alert('Please enter valid Phone');
        return;
      }
      if (policyOwnerPostalCode.length === 0) {
        alert('Please enter valid Postal Code')
        return
      }
      const updatedTravelers = addTravelers.map((traveler) => ({
        nameOfInsured: traveler.name,
        gender: traveler.gender,
        dateOfBirth: traveler.dob,
        beneficiaryRelationToInsured: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDOB: traveler.benificiaryDob,
        selectedKey:traveler.selectedKey,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
      }));
      setErrors(newErrors);
      const dataToSend = {
        email: policyHolder.policyOwnerEmail,
        firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
        lastDateOfCover:formatDatePicker(new Date(formData.lastDate)),
        policyEndDate: formatDatePicker(new Date(formData.lastDate)),
        duration:parseInt(duration),
        country:1,
        province:formData.clientProvince,
        departureFromCountryOfOrigin: policyType === 'Studentvisa' ? formData.studentDepartureDate : formData.vtcDepartureDate,
        waitingPeriod:waitingPeriod,
        familyCoverage:familyCoverage===true?1:0,
        policyLimit:policyType==='Studentvisa'?studentPolicyLimit:policyLimit,
        deductible:dedecutibles,
        paymentFrequency:policyTypeSuperVisa,
        policyOwner:policyHolder.policyOwnerName,
        policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
        policyOwnerEmail:policyHolder.policyOwnerEmail,
        policyOwnerAddress:policyHolder.policyOwnerAddress,
        policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
        policyOwnerCity:policyHolder.policyOwnerCity,
        policyOwnerPhone:policyHolder.policyOwnerPhone,
        planType:policyType==='Studentvisa'?studentPlanType:vtcPlanType,
        supervisa: superVisa,
        insuredTravellerDetailsRequest: updatedTravelers,
        policyType: policyType,
        amount: amount,
        tripType:studentPolicyOption,
        healthQuestions:questions,
        message:message,
        adjustment:adjustment
    };
    dispatch(advisorGenerateQuote(dataToSend)).then(res => {
      const {status, data} = res
      if(status===200 || status === 201){
        alert("Quote generated successfully")
        navigate(`/advisor-view-quote/${data.quotationNo}`)
      }
      console.log(data,'data')
    })
  }
  const handleTabsChange=()=>{
    const allTravelersHaveSelectedKey = addTravelers.every(traveler => traveler.selectedKey);
    if (!allTravelersHaveSelectedKey && familyCoverage!==true) {
      alert("Please select costing for all travelers");
      return;
    }
    const atLeastOneHasSelectedKey = addTravelers.some(traveler => traveler.selectedKey);
    if (!atLeastOneHasSelectedKey && familyCoverage === true) {
      alert("Please select costing");
      return false;
    }
    if(!amount){
      alert("Please select costwithpre-existing or cost withoutpreexisting")
      return;
    }
    if(!formData.clientProvince){
      alert("Please choose province.")
      return;
    }
    setTabIndex(1)
  }
  const handlePreviousChange=()=>{
    setTabIndex(0)
  }
  const handleBenefitComparisonDownload = async () => {
    try {
      const response = await fetch(`${DOWNLOAD_BENEFIT_COMPARISON}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      saveAs(blob, 'BenefitComparison.pdf'); 
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };
  const handlePolicyTypeChange =(e)=>{
    resetCosts();
    console.log(e.target.value)
    setPolicyTypeSuperVisa(e.target.value)
  }
  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 180); // Add 180 days
    return today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
  };
  function formatDate(date) {
    // Ensure date is a valid Date object
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date object:', date);
      return ''; // Or throw an error, depending on your error handling strategy
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const datePickerRefs = {
    firstDate: useRef(null),
    lastDate: useRef(null),
    vtcDeparture: useRef(null),
    studentDeparture: useRef(null),
  };
  const handleDatePickerIconClick = (pickerRef) => {
    console.log('hit',pickerRef)
    if (pickerRef.current) {
      pickerRef.current.setOpen(true); // Open the DatePicker
    }
  };
  const datePickerRefsDob = useRef(travelerDob.map(() => React.createRef()));

  const handleDatePickerIconClickDob = (index) => {
    if (datePickerRefsDob.current[index] && datePickerRefsDob.current[index].current) {
      datePickerRefsDob.current[index].current.setFocus(); // Focus the input to open the DatePicker
    }
  };

console.log(addTravelers,'addtravelers')
console.log(amount,'dobs')
console.log(message,'formData')
console.log(policyTypeSuperVisa,'option')
console.log(selectedCostOption,'selected')
console.log(vtcData,'vtcData')
return (
    <div>
      <Box pt={['100px','60px','80px']} pb={'20px'} backgroundColor={'grey'}>
        <Tabs index={tabIndex} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' backgroundColor={'white'} isFitted variant='enclosed' w={['97%','80%','80%']} m={'auto'}>
          <TabList borderTop={'none'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              paddingTop: '5px',
              color: 'black',
              fontSize: '16px',
              fontWeight: '800',
              fontFamily: 'poppins',
            }}>
            <Tab>Quick Quote</Tab>
            <Tab>Details</Tab>
            <Tab>Confirm & Pay</Tab>
          </TabList>
          <TabPanels>
          <TabPanel>
             <Box>
                <Flex direction={['column','column','row']} justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={['5px','20px','20px']}>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>First Date of Cover</Text>
                             <div className="date-picker-container">
                                <DatePicker
                                    selected={formData.firstDate}
                                    onChange={date => handleChange(date, 'firstDate')}                                
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-date-picker"
                                    placeholderText='yyyy-mm-dd'
                                    minDate={new Date().toISOString().split("T")[0]}
                                    maxDate={getMaxDate()}
                                    showYearDropdown
                                    showMonthDropdown
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                    ref={datePickerRefs.firstDate}
                                />
                                <FaCalendarAlt className="date-icon"  onClick={() => handleDatePickerIconClick(datePickerRefs.firstDate)} />
                              </div>
                        </Box>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Last Date of Cover</Text>
                          <div className="date-picker-container">
                              <DatePicker
                                  selected={formData.lastDate}
                                  onChange={date => handleChange(date, 'lastDate')}                                
                                  dateFormat="yyyy-MM-dd"
                                  className="custom-date-picker"
                                  placeholderText='yyyy-mm-dd'
                                  minDate={formData.firstDate}
                                  showYearDropdown
                                  showMonthDropdown
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                  ref={datePickerRefs.lastDate}
                              />
                              <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.lastDate)} />
                            </div>
                        </Box>
                        <Tooltip hasArrow label='No. of Days policy will be active.' bg='gray.300' color='black' placement='top-start'>
                        <Box w={['auto','7%','9%']}>
                          <Text fontWeight={'700'}>Duration</Text>
                          <Input size='sm' backgroundColor={'#eaeaea'} value={`${duration} days`} border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        </Tooltip>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Client Country</Text>
                          <Input name="clientCountry" value={'Canada'} placeholder='Canada' size='sm' border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Province</Text>
                          <Select name="clientProvince" value={formData.clientProvince} onChange={handleChange} size={'sm'} border={'1px solid #d7d7d7'}>
                            <option value="">Select</option>
                            <option value="Alberta">Alberta</option>
                            <option value="British Columbia">British Columbia</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="New Brunswick">New Brunswick</option>
                            <option value="Newfoundland & Labrador">Newfoundland & Labrador</option>
                            <option value="Northwest Territories">Northwest Territories</option>
                            <option value="Nova Scotia">Nova Scotia</option>
                            <option value="Nunavut">Nunavut</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Prince Edward Island">Prince Edward Island</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Yukon">Yukon</option>
                          </Select>
                        </Box>
                </Flex>
              <Flex direction={['column','column','row']} gap={'20px'} justifyContent={'start'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
                <Grid gridTemplateColumns={'repeat(4,1fr)'}>        
              {travelerDob.map((item, index) => {
                  return (                
                  <GridItem pl={['2px','20px','10px']} mt={['3px','7px','7px']} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                      <Flex gap={['5px','20px','20px']}> 
                        <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                        <Box>
                          <Flex justifyContent={'space-between'}>
                          <Text fontWeight={'600'} fontSize={'11px'}>Date of Birth* (Traveler {index+1})</Text>
                            <Text fontWeight={'600'} color={'teal'}>{item.age} yrs</Text>
                          </Flex>
                          <div className="date-picker-container">
                              <DatePicker
                                 selected={item.dob}
                                 onChange={(date) => handleChangeTraveler(index, date,'dob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={getMaxSelectableDate()}
                                minDate={getMinSelectableDate()} 
                                placeholderText='yyyy-mm-dd'
                                showYearDropdown
                                autoComplete='off'
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                                ref={datePickerRefsDob.current[index]}
                              />
                              <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClickDob(index)} />
                            </div>
                          {/* <DatePicker
                              selected={item.dob}
                              onChange={(date) => handleChangeTraveler(index, date,'dob')}
                              dateFormat="yyyy-MM-dd"
                              customInput={<CustomInputs />}
                              maxDate={getMaxSelectableDate()}
                              minDate={getMinSelectableDate()} 
                            /> */}
                          {/* <DatePicker
                            selected={item.dob}
                            onChange={(date) => handleChangeTraveler(index, date,'dob')} */}
                            {/* // maxDate={getMaxSelectableDate()} // Replace with your max date logic */}
                            {/* // minDate={getMinSelectableDate()} // Replace with your min date logic */}
                          {/* // /> */}
                          {/* <Input w={'230px'} type='date' background={'white'} size='sm' border={'1px solid #d7d7d7'}name="dob"
                          value={item.dob} 
                          onChange={(e) => handleChangeTraveler(index, e)}
                          max={getMaxSelectableDate()}
                          min={getMinSelectableDate()} 
                          />             */}
                        </Box>
                          </Tooltip>
                      </Flex>
                      {index > 0 && (
                              <Flex justifyContent={'end'}>
                                <IoMdCloseCircle
                                  style={{fontSize:'22px',color:buttonBackgroundColor}}
                                  onClick={() => handleRemoveTravellerDob(index)}
                                />
                              </Flex>
                            )}
                    </GridItem>
                    );
                  })}

                <Flex gap={'10px'} fontFamily={'poppins'} fontSize={'12px'} cursor={'pointer'} color={'teal'} mt={['2px','5px','10px']} mb={'10px'} alignItems={'center'} ml={['5px','20px','20px']}>
                <Flex>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTravellerDob}>Add Traveller</Text>
              </Flex>
                </Grid>
                </Flex>
              <Tabs border={'1px solid grey'} background={'#f6f6f6'} pt={'10px'} variant='soft-rounded' mt={'10px'}>
          <TabList borderTop={'none'} ml={'40px'}>
            <Tab fontSize={'12px'}>VTC Product</Tab>
            <Tab fontSize={'12px'}>Student Travel</Tab>
          </TabList>
          <TabPanels>
            <TabPanel borderTop={'none'}>
              <Box>
                <Flex direction={['column','column','row']} gap={['10px','30px','60px']} fontSize={'13px'}>
                <Flex w={['auto','auto','20%']} direction={'column'} alignItems={'start'}>
                <Text fontSize={'12px'} textAlign={'left'}>Departure from country of origin</Text>
                <div className="date-picker-container">
                              <DatePicker
                                selected={formData.vtcDepartureDate}
                                onChange={date => handleChange(date, 'vtcDepartureDate')}
                                maxDate={formData.firstDate}  
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                placeholderText='yyyy-mm-dd'
                                ref={datePickerRefs.vtcDeparture}
                                showYearDropdown
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                              />
                              <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.vtcDeparture)} />
                            </div>
                {/* <DatePicker
                    selected={formData.vtcDepartureDate}
                    onChange={date => handleChange(date, 'vtcDepartureDate')}
                    maxDate={formData.firstDate}                    
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputs />} 
                  /> */}
                {/* <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'}
                 name="vtcDepartureDate"
                 onChange={handleChange}
                 value={formData.vtcDepartureDate}
                 max={formData.firstDate}
                 />        */}
                </Flex>
                <Box>
                <Text>Waiting Period</Text>
                <input
                  name="waiting"
                  value={waitingPeriod}
                  readOnly
                />
                  </Box>  
                <Flex direction={'column'} alignItems={['start','center','center']}>
                <Text>Family Coverage</Text>
                <input type='checkbox' size='md' name='1' colorScheme='green'
                 value={familyCoverage} onChange={handleFamilyCoverageChange} checked={familyCoverage}></input>
                </Flex>   
                <Flex direction={'column'} alignItems={['start','center','center']}>
                <Text>Super Visa</Text>
                <input type='checkbox' size='md' value={superVisa} onChange={handleSuperVisaChange} colorScheme='green' checked={formData.firstDate && superVisa} ></input>
                </Flex>
                {superVisa?(
                        <>
                      
                          <Box>
                          <Text fontSize={'12px'}>Payments Frequency</Text>
                            <Select background={'white'} size={'xs'} value={policyTypeSuperVisa} onChange={(e)=>handlePolicyTypeChange(e)}>
                              <option value="1">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                          </Box>
                        </>
                      ):(<> <br /> </>)}
                </Flex>

                <Flex direction={['column','column','row']} gap={['10px','50px','90px']} mt={['5px','15px','15px']}>
                  <Box w={'100%'}>
                    <Text fontSize={['10px','12px','14px']} mt={['5px','15px','15px']} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={[2,2,4]} direction='row' w={'40%'}>
                      {superVisa ? (
                        <>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                        </>
                      ) : (
                      <Flex direction={['column','column','row']} gap={[2,2,4]}>
                        <Flex gap={[2,2,4]}>
                          <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                            $25000
                          </RadioOption>
                          <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                            $50000
                          </RadioOption>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                            $100000
                          </RadioOption>
                           </Flex>
                            <Flex gap={[2,2,4]}>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                            $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                            $300000
                          </RadioOption>
                          </Flex>
                       </Flex>
                      )}
                    </Stack>

                  </Box>
                  <Box>
                    <Text fontSize={['10px','12px','14px']} mt={['5px','15px','15px']} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={[2,2,4]} direction='row'>
                      <RadioOption value='Standard' isSelected={vtcPlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={vtcPlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={vtcPlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                </Flex>
                <Flex direction={['column','column','row']} gap={['10px','50px','90px']} mt={'25px'} mb={'25px'}>
                <Box>
                    <Text fontSize={['10px','12px','14px']} >Deductible</Text>
                    {
                      vtcPlanType==='Premium'?<Stack spacing={[2,2,4]}>
                      <Flex gap={[2,2,4]} direction={['column','column','row']}>
                        <Flex gap={[2,2,4]}>
                        <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                        $500</RadioOption>
                        <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                        $1000</RadioOption>
                        <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                        $3000</RadioOption>
                        </Flex>
                        </Flex>
                      </Stack>:<Stack spacing={[2,2,4]}>
                    <Flex gap={[2,2,4]} direction={['column','column','row']}>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                       $0</RadioOption>
                      <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                       $100</RadioOption>
                      <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                       $250 </RadioOption>
                       </Flex>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                      </Flex>
                      </Flex>
                    </Stack>
                    }
                    
                  </Box>
                  <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
      <button style={{ backgroundColor: 'teal', color: 'white', padding: '5px', borderRadius: '10px', fontSize: '12px' }} onClick={handleVtcCalculate}>
        Calculate Premium
      </button>
      <Text fontSize={'12px'} color={'blue'} onClick={handleBenefitComparisonDownload} style={{ cursor: 'pointer' }}>
        See Benefit Comparison
      </Text>
    </Flex>
                
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel borderTop={'none'}>
              <Box>
              <Flex direction={['column','column','row']}  gap={['10px','50px','60px']} fontSize={'13px'}>
                <Flex w={['auto','auto','20%']} direction={'column'} alignItems={['start','start','start']} gap={'5px'}>
                <Text fontSize={'12px'}>Departure from country of origin</Text>
                <div className="date-picker-container">
                    <DatePicker
                      selected={formData.studentDepartureDate}
                      onChange={date => handleChange(date, 'studentDepartureDate')}
                      maxDate={formData.firstDate} 
                      dateFormat="yyyy-MM-dd"
                      className="custom-date-picker"
                      placeholderText='yyyy-mm-dd'
                      ref={datePickerRefs.studentDeparture}
                    />
                    <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.studentDeparture)} />
                  </div>
                {/* <DatePicker
                    selected={formData.studentDepartureDate}
                    onChange={date => handleChange(date, 'studentDepartureDate')}
                    maxDate={formData.firstDate}                    
                    dateFormat="yyyy-MM-dd"
                    customInput={<CustomInputs />} 
                  /> */}
                {/* <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} 
                name="studentDepartureDate"
                onChange={handleChange}
                value={formData.studentDepartureDate} max={formData.firstDate}/>        */}
                </Flex>
                <Box>
                <Text textAlign={'left'}>Waiting Period</Text>
                <input
                  name="waiting"
                  value={studentWaitingPeriod}
                />                  
                </Box>  
                <Flex direction={'column'} alignItems={['start','start','center']}>
                <Text mb={'5px'}>Student Policy Option</Text>
                <Select background={'white'} size={'xs'} value={studentPolicyOption} onChange={handleStudentPolicyOption}>
                  <option value="Single Trip">International Student Policy -Single Trip</option>
                  <option value="Annual">International Student Policy - Annual</option>
                </Select>
                </Flex>   
              </Flex>
              <Flex direction={['column','column','row']}  gap={['10px','50px','90px']} mt={'25px'} mb={'25px'}>
              <Box>
                    <Text fontSize={'14px'} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='2000000' isSelected={studentPolicyLimit === '2000000'} onChange={handleStudentPolicyLimitChange}>
                      $2000000</RadioOption>
                    </Stack>
                  </Box>
              <Box>
                  <Text fontSize={'14px'} >Select Plan Type</Text>
                  <Stack spacing={4} direction='row'>
                      <RadioOption value='Standard' isSelected={studentPlanType === 'Standard'} onChange={handleStudentPlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={studentPlanType === 'Enhanced'} onChange={handleStudentPlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={studentPlanType === 'Premium'} onChange={handleStudentPlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                </Box>
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
                    <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleStudentCalculate}>Calculate Premium</button>
                    <Text fontSize={'12px'} color={'blue'}>See Benefit Comparison</Text>
                </Flex>
              </Flex>
              </Box>
              </TabPanel>
            </TabPanels>
                </Tabs>
            <Flex direction={['column','column','row']} >
                <Box w={['95%','80%','50%']}>
              <TableContainer >
                <Table>
                    <Thead>
                        <Tr gap={'50px'}>
                            <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                            <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                            <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                            <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                      {policyType!=='Studentvisa' && vtcData?.listOfInsuredQutation?.map((ele, index) => (
                            <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                <Td p={'2px'}>Travler {index+1}</Td>
                                <Td p={'2px'}>{ele.planType}</Td>
                                <Td p={'2px'} display={'flex'}>
                                    <input
                                      type="radio"
                                      name={`costOption-${index}`}
                                      disabled={ele.costWithPreExisting===0}
                                      value={ele.costWithPreExisting}
                                      checked={selectedCostOption[index] === ele.costWithPreExisting}
                                      onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                                    />
                                    <Text pl={'10px'}>
                                      {Math.round(ele.costWithPreExisting * 100) / 100}
                                    </Text>
                                  </Td>
                                  <Td p={'2px'}>
                                    <Flex>
                                    <input
                                      type="radio"
                                      name={`costOption-${index}`}
                                      value={ele.costWithoutPreExisting}
                                      disabled={ele.costWithoutPreExisting===0}
                                      checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                                      onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                                      />
                                    <Text pl={'10px'}>
                                    {Math.round(ele.costWithoutPreExisting * 100) / 100}
                                    </Text>
                                      </Flex>
                                  </Td>
                            </Tr>
                        ))}
                      {policyType==='Studentvisa' && vtcData?.listOfInsuredQutation?.map((ele, index) => (
                        <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                            <Td p={'2px'}>Travler {index+1}</Td>
                            <Td p={'2px'}>{ele.planType}</Td>
                            <Td p={'2px'} display={'flex'}>
                                {/* <input
                                  type="radio"
                                  name={`costOption-${index}`}
                                  disabled={ele.costWithPreExisting===0}
                                  value={ele.costWithPreExisting}
                                  checked={selectedCostOption[index] === ele.costWithPreExisting}
                                  // onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                                /> */}
                                <Text pl={'10px'}>
                                  {Math.round(ele.costWithPreExisting * 100) / 100}
                                </Text>
                              </Td>
                              <Td p={'2px'}>
                                <Flex>
                                {/* <input
                                  type="radio"
                                  name={`costOption-${index}`}
                                  value={ele.costWithoutPreExisting}
                                  disabled={ele.costWithoutPreExisting===0}
                                  checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                                  onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                                  /> */}
                                <Text pl={'10px'}>NA</Text>
                                  </Flex>
                              </Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
                  </TableContainer>
                  </Box>
                  <Box w={['95%','80%','50%']}>
                  <TableContainer>
                      <Table>
                          <Thead>
                              <Tr>
                                  <Th p={'7px'} fontSize={'10px'}>Period</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Net</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Total</Th>
                              </Tr>
                          </Thead>
                          <Tbody>
                                  <Tr fontSize={'12px'} p={'2px'}>
                                      <Td p={'2px'}>{`${formatDate(formData.firstDate)}`} - {superVisa===true?`${formData.lastDate}`:`${formatDate(formData.lastDate)}`}</Td>
                                      <Td p={'2px'} fontWeight={'600'}>{policyType==='Studentvisa'?amountWithoutAdjustment:amount} CAD</Td>
                                      <Td p={'2px'}>0.00 CAD	</Td>
                                      <Td p={'2px'} fontWeight={'600'}>{adjustment} CAD	</Td>
                                      <Td p={'2px'} fontWeight={'600'}>{amount} CAD</Td>
                                  </Tr>
                          </Tbody>
                      </Table>
                  </TableContainer>
                  </Box>
              </Flex>
                  <Text color={'red'} textAlign={'left'} fontSize={'11px'}>{vtcData.length!==0 && policyTypeSuperVisa==2?message:''}</Text>
              <Flex justifyContent={'end'} mt={'15px'}>
              <Button background={'teal'} size={'sm'} _hover={{background:'teal'}} color={'white'} onClick={handleTabsChange}>Proceed</Button>
              </Flex>
             </Box>
            </TabPanel>
            <TabPanel border={'2px solid grey'} borderTop={'none'}>
              <Box w={'95%'} m={'auto'}>
          {addTravelers.length>0 && addTravelers.map((item, index) => {
              return (                
              <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                {/* {index+1} */}
                  <Flex gap={['10px','20px','20px']} direction={['column','column','row']}>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Name Of Insured</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                       name="name"
                       value={item.name}
                       onChange={(e) => handleChangeTraveler(index, e)}
                      />            
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Gender</Text>
                      <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                       onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </Box>
                    <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'600'}>Date of Birth</Text>
                      {/* {item.dob && (
                        <DatePicker
                          defaultValue={item.dob}
                          dateFormat="yyyy-MM-dd"
                          className="custom-date-picker"
                          readOnly
                          onChange={() => {}}
                        />
                      )} */}
                      <Input w={'80%'} className="custom-date-picker" type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'}name="dob"
                      value={item.dob} 
                      readOnly
                      />             
                    </Box>
                      </Tooltip>
                  </Flex>
                  <Flex gap={['10px','20px','20px']} mt={'10px'} direction={['column','column','row']}>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Beneficiary Name</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                      name="benificiaryName"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName}/>            
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Relation</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                      name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation}
                      />            
                    </Box>  
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'600'}>Beneficiary DOB</Text>
                      <div className="date-picker-container">
                              <DatePicker
                                 selected={item.benificiaryDob}
                                 onChange={(date) => handleChangeTraveler(index, date,'benificiaryDob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={getMaxSelectableDate()}
                                placeholderText='yyyy-mm-dd'
                                showYearDropdown
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                              />
                              <FaCalendarAlt className="date-icon" />
                            </div>
                      {/* <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob}
                      max={getMaxSelectableDate()}/>             */}
                    </Box>
                  </Flex>
                </Box>
                );
              })}
                <hr />
                <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                <Flex gap={['10px','20px','40px']} direction={['column','column','row']} fontSize={'14px'} pb={['5px','20px','20px']} mt={'20px'}>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner*</Text>
                  <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                    <option value="">Select Policy Owner</option>
                    {(formData.firstDate && formData.lastDate) && (vtcPlanType || studentPlanType) ? addTravelers.map((traveler, index) => (
                      <option key={index} value={traveler.name}>
                        {traveler.name}
                      </option>
                    )) : null}
                  </Select>
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Date Of Birth*</Text>
                  <Input readOnly type='date' size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Email*</Text>
                  <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                  <Text color="red.500">{errors.policyOwnerEmail}</Text>        
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Address*</Text>
                  <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                  <Text color="red.500">{errors.policyOwnerAddress}</Text>       
                </Box>
              </Flex>
              <Flex gap={['10px','20px','40px']} direction={['column','column','row']} fontSize={'14px'} pb={'20px'} mt={['5px','20px','20px']}>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Postal Code*</Text>
                  <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                  <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner City*</Text>
                  <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                  <Text color="red.500">{errors.policyOwnerCity}</Text>         
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Phone*</Text>
                  <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                  <Text color="red.500">{errors.policyOwnerPhone}</Text>
                </Box>
              </Flex>
                  <Flex gap={'30px'} justifyContent={'end'}>
                  <Button size={'sm'} _hover={{background:buttonBackgroundColor}} onClick={handlePreviousChange} color={buttonColor} backgroundColor={buttonBackgroundColor}>Previous</Button>
                    <Button size={'sm'} _hover={{background:buttonBackgroundColor}} onClick={handleGetQuote} color={buttonColor} backgroundColor={buttonBackgroundColor}>Get Quote</Button>
                  </Flex>
                  </Box>
            </TabPanel>
            <TabPanel>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <BeneficiaryDetailsModal isOpen={isStudentModalOpen} onClose={handleStudentModalClose} onSubmit={handleStudentModalSubmit} />
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleVtcModalSubmit} />
       <HealthQuestions isOpen={isQuestionerModalOpen} onClose={handleQuestionersClose} onSubmit={handleQuestionersSubmit}/>
       <FamilyModal isOpen={isFamilyModel} onClose={handleFamilyModel} />
    </div>
  );
};

export default AdvisorGenerateQuote;