import React from 'react'
import SideBox from '../../../../Utils/SideBox/SideBox'

const InsuranceCompanyReports = () => {
  return (
    <div>
      <SideBox>

        
      </SideBox>
    </div>
  )
}

export default InsuranceCompanyReports
