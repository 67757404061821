import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import Api from '../../../../dependencies/utils/Api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Flex, Select, Text,Tooltip } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { GET_CUSTOMER_BY_ID, GET_QUOTES_BY_EMAIL, SEND_QUOTE } from '../../../../constants/ApplicationUrl'
import { advisorDeleteQuote } from '../../../Advisor/Dependencies/action'


const AdminCustomerDashboard = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10,20,50,100];
  const params=useParams()
  const {id}=params
  const dispatch = useDispatch();
  const [quotesData,setQuotesData]=useState([])
  const [customerData,setCustomerData]=useState([])
  const handleAction = (params)=>{
    const {data}=params
  const handleChange = (e) => {
    if(e.target.value==='edit'){
      navigate(`/admin/editQuote/${data.quotationNo}`)
    }
    if(e.target.value==='view'){
      navigate(`/admin/advisor-view-quote/${data.quotationNo}`)
    }
    if(e.target.value==='send'){
      Api.POST(`${SEND_QUOTE}${data.quotationNo}`).then((res)=>{
        console.log(res,'quotes sending')
      })
      alert('Quote has been sent successfully')
    }
    if(e.target.value==='cancel'){
      var result = confirm("Are you sure you want to delete?");
      if(result){
        dispatch(advisorDeleteQuote(data.quotationNo)).then((res)=>{
          console.log(res)
          if(res.status===200){
            getCustomerData()
          }else{
            alert('Something went wrong')
          }
          
        })
      }
      alert('Quote Cancelled successfully')
    }
    console.log('Download clicked for row:', params.data);
  };
return(
  <>
      <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
       <Select onChange={(e) => handleChange(e)} size={'xs'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
        <option value="">Select</option>
        <option value="view">View</option>
        <option value="send">Email quote</option>
        <option value="edit">Edit quote</option>
        <option value="cancel">Cancel</option>
        </Select>
      </Tooltip>
      </>
)
  }

    const [colDefs, setColDefs] = useState([
      { field: "product",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:100},
      { field: "quotationNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:230},
      { field: "dateOfIssue",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:180 },
      { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300},
      { field: "customerEmail",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300},
      { field: "tripType",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
      { field: "amount",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
      { field: "Action",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer:handleAction, width:250},
    ]);    const [rowData, setRowData] = useState([

    ]);
    const [rowDataEmpty,setRowDataEmpty]=useState([])
    const navigate=useNavigate()
  
  
  

useEffect(()=>{
    getCustomerData()
},[])


const getCustomerData=()=>{
    Api.GET(`${GET_CUSTOMER_BY_ID}/${id}`).then((res)=>{
        console.log(res.data,'get customer by id')
        setCustomerData(res.data)
        getCustomerQuotes(res.data.email)
    })
}

const handleNavigate=()=>{
  navigate(`/admin/generate-quote/${id}`)
}


const getCustomerQuotes =(email) =>{
  Api.GET(`${GET_QUOTES_BY_EMAIL}${email}`).then((res)=>{
    if (res.status === 200) {
      if (Array.isArray(res.data)) {
        setQuotesData(res.data);
        const formattedData = res.data.map((quote, index) => ({
          id: index + 1,
          // employeeName: 'Ravi Sharma',
          // designation: 'Advisor',
          product: quote.product==="Vistors To Canada"?'VTC':'STC',
          quotationNo: quote.quotationNo,
          dateOfIssue: quote.createdAt,
          customerName: quote.policyHolderName,
          customerEmail:quote.policyHolderEmail,
          tripType: quote.tripType,
          amount: quote.quoteAmount,
        }));
        setRowData(formattedData);
      } else {
        console.error('Response data is not an array:', res.data);
      }
    }
  })
}



return (
    <Sidebar headingText={'Customer dashboard'}>
        <Box>
        <Flex boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'90%'} m={'auto'} p={'18px'} fontFamily={'poppins'} justifyContent={'space-around'}>
  <Link to={`/admin/customer-dashboard/${id}`}>
                <Text>Quotes</Text>
                </Link>
                <Link to={`/admin/customer-policies/${id}`}>
                <Text>Policies</Text>
                </Link>
                <Link to={`/admin/customer-renewal-policies/${id}`}>
                <Text>Renewal Policies</Text>
                </Link>
                <Link to={`/admin/customer-profile/${id}`}>
                <Text>Profile</Text>
                </Link>
            </Flex>
            <Flex w={'90%'} m={'auto'} mt={'70px'} justifyContent={'end'}>
            <Button onClick={handleNavigate} _hover={{color:'rgb(84, 34, 237)'}} size={'sm'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'}> Add new</Button>
          </Flex>
        <Box w={'90%'} m={'auto'} mt={'10px'} mb={'50px'} pb={'20px'}>
        {rowData.length > 0 ? (
          <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              // frameworkComponents={{ actionCellRenderer }}
              domLayout='autoHeight'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              paginationPageSize={10}
              onGridReady={(params) => {
                setTimeout(() => {
                  params.api.sizeColumnsToFit();
                }, 1000);
              }}              
            />
          </div>
        ) : (
          <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
          <AgGridReact
              rowData={rowDataEmpty}
              columnDefs={colDefs}
              domLayout='autoHeight'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              paginationPageSize={10}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
          />
        </div>
        )}

     </Box>
</Box>
    </Sidebar>
  )
}

export default AdminCustomerDashboard
