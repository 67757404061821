import React from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Image, Tooltip } from '@chakra-ui/react';
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

const Products = () => {
  const navigate=useNavigate()


const handleEdit=()=>{
  navigate('/admin/edit-product')
} 


const handleDelete=()=>{
  var result = confirm("Are you sure you want to delete?");
      if(result){
          alert("Product Deleted successffuly")
        }
      }


return (
  <Sidebar headingText="Products">
  <Box w={'90%'} m={'auto'} mt={'70px'}>
    <Flex justifyContent={'end'} mb={'5px'}>
      <Link to={'/admin/add-product'}>
        <Button size={'sm'} borderRadius={'20px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'}>Add New</Button>
      </Link>
    </Flex>
  <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>

    <Box>
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
                <col style={{ width: '20%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
            </colgroup>
            <thead>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <th style={{ textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Product Name</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Description</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Image</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Edit</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Delete </th>
                </tr>
            </thead>
            <tbody>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Visitors to canada	</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Travel Insurance for visitors to canada	</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                      <Image w={'80px'} src='https://travelmedicare.com/public/Product/1567074373travel.png'/>
                    </td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit cursor={'pointer'} onClick={handleEdit} />
                    </td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                      <Button size={'sm'} background={'red'} color={'white'} onClick={handleDelete}>Delete</Button>
                    </td>
                </tr>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Students travel</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>International Students to Canada</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                      <Image w={'80px'} src='https://travelmedicare.com/public/Product/1567074559travelinsurance.jpg'/>
                    </td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'20px',fontFamily:'Open Sans, sans-serif' }}>
                    <FaRegEdit cursor={'pointer'} onClick={handleEdit}/>
                    </td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                      <Button size={'sm'} background={'red'} color={'white'} onClick={handleDelete}>Delete</Button>
                    </td>
                </tr>
            </tbody>
             </table>
    </Box>

    </Box>
  </Box>



</Sidebar>
  )
}
export default Products
