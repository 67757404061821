import React from 'react'
import Sidebar from '../../../Sidebar'
import { FaDownload } from "react-icons/fa6";
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { DOWNLOAD_CLAIM_DOC, DOWNLOAD_POLICY_DOC, DOWNLOAD_POLICY_DOCUMENT, DOWNLOAD_TERMS_DOCUMENT, DOWNLOAD_WALLET_CARD } from '../../../../../constants/ApplicationUrl';
import { saveAs } from 'file-saver';



const PolicyDocs = () => {
    const navigate=useNavigate()
    const params=useParams()
    const {id}=params
    const {token}=JSON.parse(localStorage.getItem('code_dev'))
    const handleBack = () => {
        navigate(-1);
      }

const handleTermsDownload = async () => {
try {
    const response = await fetch(DOWNLOAD_TERMS_DOCUMENT, {
    method: 'GET',
    headers: {
        'Authorization': `Bearer ${token}`
    }
    });
    if (!response.ok) {
    throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    saveAs(blob, 'Terms&Eligibility.pdf');
} catch (error) {
    console.error('Failed to download PDF:', error);
}
};

const handleWalletCardDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_WALLET_CARD}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'WalletCard.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};

const handlePolicyDocDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOC}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'PolicyDoc.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};

const handleClaimDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_CLAIM_DOC}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'ClaimDoc.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};

const handlePolicyDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOCUMENT}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'Policy.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};



return (
    <Sidebar headingText={'Download Documents'}>
        <Box background='white' w={'80%'} m={'auto'} p={'20px'} mt={'50px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mt={'50px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text mt={'30px'} mb={'30px'} color={'grey'} fontSize={'22px'} fontFamily={'Open Sans,sans-serif'}>Download document for Policy : 2024PTM351215</Text>
            <Flex w={'90%'} m={'auto'} mt={'50px'} mb={'70px'} gap={'40px'}>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'}>Terms&Eligibility</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload cursor={'pointer'} onClick={handleTermsDownload}/>
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload cursor={'pointer'} onClick={handlePolicyDownload}/>
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Wallet Card</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload cursor={'pointer'} onClick={handleWalletCardDownload}/>
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Claim Doc</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload onClick={handleClaimDownload} cursor={'pointer'} />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy Doc</Text>
                    <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                    <FaDownload cursor={'pointer'} onClick={handlePolicyDocDownload}/>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    </Sidebar>
  )
}

export default PolicyDocs
