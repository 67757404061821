import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Button } from '@chakra-ui/react';
import { FaRegEdit } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { adminInsuredList } from './dependencies/action';
import { Link, useNavigate } from 'react-router-dom';
import ViewTable from './ViewTable';

const InsurancePlans = () => {
  const dispatch = useDispatch();
  const [insuredData, setInsuredData] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const navigate=useNavigate()
  useEffect(() => {
    dispatch(adminInsuredList())
      .then((res) => {
        console.log("Admin insured details fetched successfully", res);
        setInsuredData(res);
      })
      .catch((error) => {
        console.error("Error fetching admin profile data:", error);
      });
  }, [dispatch]);

  const handleViewClick = (plan) => {
    navigate(`/admin/view-table/${plan}`);
  };

  return (
    <Sidebar headingText={"Insurance Plans"}>
      <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background='white' w='95%' m='auto' mt={'90px'} p={'20px'}>
        <Tabs background='white' isFitted variant='enclosed'>
          <TabList mb='1em'>
            {insuredData.map((plan, index) => (
              <Tab key={index} fontSize={'14px'} fontFamily={'poppins'}>{plan.planName}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {insuredData.map((plan, index) => (
              <TabPanel key={index}>
                <Box>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <colgroup>
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '35%' }} />
                      <col style={{ width: '20%' }} />
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead>
                      <tr style={{ borderBottom: '1px solid #dddddd' }}>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Plan Name</th>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Sum Insured</th>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Status</th>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Edit</th>
                        <th style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Plan Table</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ borderBottom: '1px solid #dddddd' }}>
                        <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{plan.planName}</td>
                        <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{plan.planPrice}</td>
                        <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Active</td>
                        <td style={{ textAlign: 'left', padding: '10px', fontSize: '20px', fontFamily: 'Open Sans, sans-serif' }}>
                          <FaRegEdit />
                        </td>
                        <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                          <Button size={'sm'} background={'#9124a3'} color={'white'} onClick={() => handleViewClick(plan.planName)}>View</Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        
      </Box>
    </Sidebar>
  );
};

export default InsurancePlans;
