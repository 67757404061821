import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../Utils/UniversalColor';
import {Flex,Box,Tooltip,Select, Button} from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import Api from '../../../dependencies/utils/Api';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import CustomInput from '../../../Utils/Input/Index';
import { GET_POLICY_BY_USER, SEND_POLICY } from '../../../constants/ApplicationUrl';
import { sendPolicy } from '../../../Utils/Emails';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const CustomerPolicies = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10,20,50,100];
  const [quotations, setQuotations] = useState([]);
  const navigate=useNavigate()
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };

    // Initial column fitting based on screen size
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const downloadCellRenderer = (params) => {
    const handleDownload = () => {
      navigate(`/customer-policy-documents/${params.data.policyNo}`)
      console.log('Download clicked for row:', params.data);
    };
  return(
    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
    <FaDownload color={buttonBackgroundColor} onClick={handleDownload} style={{ cursor: 'pointer' }} />
  </div>
  )
  };

  const handleAction = (params) => {
    const { data } = params;
    const handleChange = (e) => {
      if (e.target.value === 'endorsement') {
        navigate(`/policy-endorsement/${data.id}`);
      }
      if (e.target.value === 'view') {
        navigate(`/single-policy-view/${data.id}`);
      }
      if (e.target.value === 'send') {
          sendPolicy(data.policyNo)
          alert('Policy emailed successfully.');
      }
      if (e.target.value === 'cancel') {
        console.log('Re-issue clicked for row:', data.id);
      }
    };
  
    return (
      <>
        <Tooltip hasArrow label='You can view, send, edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
          <Select onChange={(e) => handleChange(e)} size={'xs'} style={{ backgroundColor: '#96ffff', border: '1px solid black' }}>
            <option value="">Select</option>
            <option value="view">View</option>
            <option value="send">Email policy</option>
            {data.status === 'Active' && <option value="endorsement">Endorsement</option>}
            <option value="cancel">Re-issue</option>
          </Select>
        </Tooltip>
      </>
    );
  };
  
  const [colDefs, setColDefs] = useState([
    { field: "product",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:150,filter:true},
    { field: "policyNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:330,filter:true},
    { field: "dateOfIssue",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220,filter:true },
    { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300,filter:true},
    { field: "tripType",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name',filter:true },
    { field: "policyAmount",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name',filter:true },
    { field: "status",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name',filter:true },
    { field: "download",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer: downloadCellRenderer,width:140,wrapHeaderText: true},
    { field: "Action",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer:handleAction, width:250},
  ]);

  const [rowData, setRowData] = useState([]);

  const actionCellRenderer = (params) => {
    const handleActionChange = (action) => {
      console.log('Selected Action:', action);
    };

    return (
      <select onChange={(e) => handleActionChange(e.target.value)}>
        <option value="view">View Quote</option>
        <option value="view">Send Quote</option>
        <option value="edit">Edit Quote</option>
        <option value="editTrip">Edit Trip Details</option>
        <option value="cancel">Cancel Quote</option>
      </select>
    );
  };

  useEffect(() => {
    getAllCustomerPolicies()
  }, []);

  const getAllCustomerPolicies = ()=>{
    Api.GET(`${GET_POLICY_BY_USER}`).then((res) => {
      console.log(res, 'data');
      if (res.status === 200) {
        const formattedData = res.data.map((quote, index) => {
          let status;
          switch (quote.status) {
            case 1:
              status = 'Active';
              break;
            case 2:
              status = 'Pending Cancellation';
              break;
            case 3:
              status = 'Cancelled';
              break;
            case 4:
              status = 'Pending Void';
              break;
            case 5:
              status = 'Void';
              break;
            case 6:
              status = 'Policy Change Pending';
              break;
            case 7:
              status = 'Matured';
              break;
            default:
              status = 'Inactive';
          }
          let tripType;
          if (quote.quote.product === 'Vistors To Canada') {
              tripType = 'Visitor To Canada';
          } else if (quote.quote.product === 'Student To Canada') {
              tripType = 'Student To Canada';
          } else if (quote.quote.superVisa === 1) {
              tripType = quote.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
          } else {
              tripType = 'Single Trip';
          }

          return {
            id: quote.idPolicy,
            employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
            designation: quote.quote.user.roles.name,
            product: quote.quote.product === 'STC' ? 'STC' : 'VTC',
            policyNo: quote.policyNo,
            dateOfIssue: quote.quote.createdAt,
            customerName: quote.quote.policyHolderName,
            tripType: tripType,
            policyAmount: `${quote.quote.quoteAmount} $`,
            status: status,
          };
        });
        setRowData(formattedData);
      }
    });
  }

  // const downloadPDF = () => {
  //   const doc = new jsPDF();
  //   const tableColumnNames = Object.keys(rowData[0]); // Assuming rowData has at least one item
  //   const tableRows = rowData.map((row) => Object.values(row));
  
  //   doc.autoTable({
  //     head: [tableColumnNames],
  //     body: tableRows,
  //     theme: 'grid',
  //     styles: {
  //       font: 'helvetica',
  //       fontStyle: 'bold',
  //       fontSize: 10,
  //       cellPadding: 4,
  //       overflow: 'linebreak',
  //     },
  //     columnStyles: {
  //       // Specify column styles if needed
  //     },
  //   });
  
  //   doc.save('polices.pdf');
  // };
  const downloadPDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
  
    // Define table headers
    const headers = Object.keys(rowData[0]);
  
    // Define table rows
    const rows = rowData.map(row => Object.values(row));
  
    // Set properties for the PDF document
    doc.setFont('helvetica');
    doc.setFontSize(12);
  
    // Define x and y position for the table
    const y = 25; // Start y position for the table
  
    // Add table headers
    doc.text(' Policies', 14, 10);
    doc.autoTable({
      startY: y,
      head: [headers],
      body: rows,
      theme: 'grid',
      styles: {
        font: 'helvetica',
        fontStyle: 'normal',
        fontSize: 10,
        cellPadding: 4,
      },
      columnStyles: {
        0: { fontStyle: 'bold' }, // Make the first column bold
      },
    });
  
    // Save the document
    doc.save('policies.pdf');
  };
  
  const downloadExcel = () => {
    const worksheet = quotations.map(({ id, ...rest }) => rest); // Remove 'id' property
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    saveAs(new Blob([excelBuffer], { type: 'application/octet-stream' }), 'quotations.xlsx');
  };

  const downloadCSV = () => {
    // Implement logic for downloading CSV
  };

return (
    <Box background={'#f6f6f6'} h={'auto'} pb={'30px'}>
      <Box w={['90%','95%','80%']} m={'auto'} style={{ paddingTop: '120px', marginBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Policies</h3>
        <Flex direction={['column','column','row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
          <Flex direction={['column','column','row']} gap={'10px'}>
          <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'xs'} leftIcon={<FaRegFilePdf />} style={{ color:buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button leftIcon={<SiMicrosoftexcel />} size={'xs'} style={{ color: buttonColor, backgroundColor:buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            </Flex>
            <Flex direction={['column','column','row']} gap={'10px'}>
            <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'xs'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip>
            <Tooltip hasArrow label='Customer Renewal Polices' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <Link to='/customer-renewal-policies'>
            <Button size={'xs'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }}>Renewal</Button>
              </Link>
            </Tooltip>
            </Flex>
          </div>
          {/* <Box mb={['15px',0,0]}>
              <CustomInput/>
          </Box> */}
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden'}}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>

      </Box>
    </Box>
  );
};

export default CustomerPolicies;
