

import { ADMIN_ALL_POLICES, CLAIM_REPORT_ADVISOR_LIST } from "../../../../../constants/ApplicationUrl"
import Api from "../../../../../dependencies/utils/Api"
// import { ADMIN_PROFILE_EDIT } from "../../../../constants/ApplicationUrl"

export const AdminAllPolicesService = {
adminPolicesList() {
    return Api.GET(ADMIN_ALL_POLICES).then((response) => {
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
adminPolicesClaimList() {
    return Api.GET(CLAIM_REPORT_ADVISOR_LIST).then((response) => {
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
}



// adminProfileEdet(params) {
//     return Api.PUTDATA(ADMIN_PROFILE_EDIT,params).then((response) => {
//         // console.log(response,'response')
//         const { data, status} = response
//         if(status === 201) {
//             return {data, status}
//         }else {
//             const {data: {message} = {}} = response
//             return {message, status}
//         }
//     })
// },
}