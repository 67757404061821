
import { ADMIN_ALLPOLICES_GET_TYPE, CLAIM_REPORT_ADVISOR_LIST_TYPE, isLoading } from "../../../../../constants/actionConstants";
import { AdminAllPolicesService } from "./service";

export const adminPolicesList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminAllPolicesService.adminPolicesList(params);
      console.log(response,'responserviceadvisor')
      dispatch({
        type: ADMIN_ALLPOLICES_GET_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const adminPolicesClaimList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminAllPolicesService.adminPolicesClaimList(params);
      console.log(response,'responserviceadvisor')
      dispatch({
        type: CLAIM_REPORT_ADVISOR_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };