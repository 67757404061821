import React from 'react'
import Sidebar from '../Sidebar'

const Logs = () => {


return (
    <Sidebar headingText={'Logs'}>
      
    </Sidebar>
  )
}

export default Logs
