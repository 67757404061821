import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import './style.css';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import Api from '../../../../dependencies/utils/Api';
import { GET_MONTHLY_POLICIES } from '../../../../constants/ApplicationUrl';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const MonthlyPolicies = () => {
    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];
    const navigate = useNavigate();

    const downloadCellRenderer = (params) => {
        const handleDownload = () => {
            navigate(`/admin/download-documents/${params.data.policyNo}`);
            console.log('Download clicked for row:', params.data.policyNo);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
            </div>
        );
    };

    const viewTranscations = (params) => {
        const handleView = () => {
            navigate(`/admin/viewPolicyPaymentTranscation/${params.data.id}`);
            console.log('Download clicked for row:', params.data);
        };
        const handleDeclineView = () => {
            navigate(`/admin/viewDeclinedTranscationList/${params.data.id}`);
        };
        return (
            <div className='view'>
                <Button onClick={handleView} _hover={{ color: 'white' }} mb={'3px'} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
                <Button onClick={handleDeclineView} _hover={{ color: 'white' }} background={'red'} color={'white'} size={'xs'}>Decline</Button>
            </div>
        );
    };

    const handleAction = (params) => {
        const handleActions = () => {
            console.log('Download clicked for row:', params.data);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', direction: 'column', marginTop: '5px' }}>
                <Button background={'#9c27b0'} color={'white'} size={'xs'}>View</Button>
            </div>
        );
    };

    const handleEndorsement = (params) => {
        const handleActions = () => {
            navigate(`/admin/policyEndorsements/${params.data.id}`);
            console.log('Download clicked for row:', params.data);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', direction: 'column', marginTop: '5px' }}>
                <Button onClick={handleActions} background={'#00bcd4'} color={'white'} size={'xs'}>View</Button>
            </div>
        );
    };

    const [colDefs, setColDefs] = useState([
        { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
        { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true },
        { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 330 },
        { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
        { field: "effectiveDate", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
        { field: "expireDate", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
        { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300 },
        { field: "policyAmount", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
        { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
        { field: "endorsements", wrapHeaderText: true, headerClass: 'header', width: 175, cellRenderer: handleEndorsement, cellClass: 'cell-class-endorsement', autoHeight: true },
        { field: "download", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: downloadCellRenderer, width: 140, wrapHeaderText: true },
        { field: "paymentTranscations", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellRenderer: viewTranscations, autoHeight: true },
        { field: "Action", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: handleAction }
    ]);

    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [userFilter, setUserFilter] = useState('');

    useEffect(() => {
        Api.GET(`${GET_MONTHLY_POLICIES}`).then((res) => {
            console.log(res.data, 'responserviceadvisor');
            if (res.status === 200) {
                const formattedData = res.data.map((quote, index) => {
                    let status;
                    switch (quote.status) {
                        case 1:
                            status = 'Active';
                            break;
                        case 2:
                            status = 'Pending Cancellation';
                            break;
                        case 3:
                            status = 'Cancelled';
                            break;
                        case 4:
                            status = 'Pending Void';
                            break;
                        case 5:
                            status = 'Void';
                            break;
                        case 6:
                            status = 'Policy Change Pending';
                            break;
                        case 7:
                            status = 'Matured';
                            break;
                        default:
                            status = 'Inactive';
                    }

                    return {
                        id: quote.idPolicy,
                        employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
                        designation: quote.quote.user.roles.name,
                        product: quote.quote.product === 'Studentvisa' ? 'STC' : 'VTC',
                        policyNo: quote.policyNo,
                        dateOfIssue: quote.quote.createdAt,
                        effectiveDate: quote.quote.firstDateOfCover,
                        expireDate: quote.quote.lastDateOfCover,
                        tripType: quote.quote.product,
                        customerName: quote.quote.policyHolderName,
                        tripType: `$${quote.quoteAmount}`,
                        policyAmount: quote.quote.quoteAmount,
                        status: status,
                    };
                });
                setRowData(formattedData);
                setFilteredData(formattedData);
            }
        })
            .catch((error) => {
                console.error("Error fetching admin profile data:", error);
            });
    }, []);

    useEffect(() => {
        let data = rowData;

        if (searchTerm) {
            data = data.filter(row => row.employeeName.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (selectedProduct) {
            data = data.filter(row => row.product === selectedProduct);
        }

        if (selectedRole) {
            data = data.filter(row => row.designation === selectedRole);
        }

        if (selectedUser) {
            data = data.filter(row => row.employeeName === selectedUser);
        }
        if (userFilter) {
          data = data.filter(item => item.employeeName.includes(userFilter));
        }

        if (fromDate && toDate) {
            const from = new Date(fromDate);
            const to = new Date(toDate);
            data = data.filter(row => {
                const issueDate = new Date(row.dateOfIssue);
                return issueDate >= from && issueDate <= to;
            });
        }

        setFilteredData(data);
    }, [searchTerm, selectedProduct, selectedRole,userFilter, selectedUser, fromDate, toDate, rowData]);
    const handleFilterChange = (filterType, value) => {
      if (filterType === 'product') {
        setProductFilter(value);
      } else if (filterType === 'role') {
        setRoleFilter(value);
      } else if (filterType === 'user') {
        setUserFilter(value);
      }
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        const tableColumn = Object.keys(filteredData[0]).filter(header => !header.toLowerCase().includes('id')).map(column => column.charAt(0).toUpperCase() + column.slice(1));
        const tableRows = filteredData.map(row => Object.values(row).filter((_, index) => !Object.keys(row)[index].toLowerCase().includes('id')));

        doc.autoTable({ head: [tableColumn], body: tableRows });
        doc.save('monthly_policies.pdf');
    };

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Monthly Policies');
        XLSX.writeFile(workbook, 'monthly_policies.xlsx');
    };

    const downloadCSV = () => {
        const csvContent = [
            Object.keys(filteredData[0]).filter(header => !header.toLowerCase().includes('id')).join(','),
            ...filteredData.map(obj => Object.values(obj).filter((_, index) => !Object.keys(obj)[index].toLowerCase().includes('id')).join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        saveAs(blob, 'monthly_policies.csv');
    };

    return (
        <Sidebar headingText={'Monthly Policies'}>
            <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
                <Flex>
                    <Box>
                        <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Product</label> <br />
                        <select
                            style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }}
                            id="product-select"
                            value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}
                        >
                            <option value="">All Products</option>
                            <option value="VTC">VTC Product</option>
                            <option value="Student Travel">Student Travel</option>
                        </select>
                    </Box>
                    <Box>
                        <label htmlFor="role-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Role</label> <br />
                        <select
                            style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }}
                            id="role-select"
                            value={selectedRole}
                            onChange={(e) => setSelectedRole(e.target.value)}
                        >
                            <option value="">Select Roles</option>
                            <option value="AGA">AGA</option>
                            <option value="MGA">MGA</option>
                            <option value="Advisor">Advisor</option>
                            <option value="Customer">Customer</option>
                        </select>
                    </Box>
                    <Box>
            <label htmlFor="user-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} 
              id="user-select"
              onChange={(e) => handleFilterChange('user', e.target.value)}
            >
              <option value="">Select Users</option>
              {filteredData.map(employee => (
                <option key={employee.id} value={employee.employeeName}>{employee.employeeName}</option>
              ))}
            </select>
          </Box>
                    <Flex>
                        <Box>
                            <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
                            <input
                                style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }}
                                type="date"
                                id="from-date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </Box>
                        <Box>
                            <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>To Date:</label> <br />
                            <input
                                style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }}
                                type="date"
                                id="to-date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </Box>
                    </Flex>
                </Flex>
                <br />
            </div>

            <Box w={'95%'} m={'auto'} background={'white'} p={'10px'}>
                <Flex justifyContent={'space-between'} mt={'15px'}>
                    <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                    </div>
                    <div>
                        <label htmlFor="search">Search:</label>
                        <input
                            style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
                            type="text"
                            id="search"
                            placeholder="Search by name"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </Flex>
                <div
                    className="ag-theme-quartz"
                    style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
                >
                    <AgGridReact
                        rowData={filteredData}
                        columnDefs={colDefs}
                        domLayout='autoHeight'
                        width='100%'
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        pagination={pagination}
                        onGridReady={(params) => {
                            params.api.sizeColumnsToFit();
                        }}
                    />
                </div>
            </Box>
        </Sidebar>
    );
};

export default MonthlyPolicies;
