export const IS_LOADING = "IS_LOADING";
export const SET_USER = "SET_USER";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const PRODUCT_LIST = "PRODUCT_LIST";
export const CUSTOMER_SIGNUP = "CUSTOMER_SIGNUP";
export const CUSTOMER_LOGIN = "CUSTOMER_LOGIN";
export const CUSTOMER_PROFILE = "CUSTOMER_PROFILE";
export const CUSTOMER_EDIT_PROFILE = "CUSTOMER_EDIT_PROFILE";
export const ADVISOR_SIGNUP = "ADVISOR_SIGNUP";
export const ADVISOR_LOGIN = "ADVISOR_LOGIN";
export const SUPERVISA_QUICKNOTE = "SUPERVISA_QUICKNOTE";
export const STUDENTVISA_QUICKNOTE = "STUDENTVISA_QUICKNOTE";
export const CUSTOMER_QUOTE_LIST="CUSTOMER_QUOTE_LIST"
export const VISITOR_QUOTE_LIST='VISITOR_QUOTE_LIST'
export const ADVISOR_PROFILE_LIST = "ADVISOR_PROFILE_LIST";
export const ADVISOR_GENERATEQUOTE = "ADVISOR_GENERATEQUOTE";
export const ADVISOR_CALCULATEVTC = "ADVISOR_CALCULATEVTC";
export const CUSTOMER_CALCULATEVTC = "CUSTOMER_CALCULATEVTC";
export const ADVISOR_GETQUOTES = "ADVISOR_GETQUOTES";
export const SUPER_ADMIN_LOGIN="SUPER_ADMIN_LOGIN";
export const ADMIN_CREATE_LIST="ADMIN_CREATE_LIST";
export const MENU_LIST_TYPE="MENU_LIST_TYPE";
export const ADMIN_LOGIN_TYPE="ADMIN_LOGIN_TYPE";
export const ADVISOR_SINGLE_QUOTE="ADVISOR_SINGLE_QUOTE";
export const CUSTOMER_SINGLE_QUOTE="CUSTOMER_SINGLE_QUOTE";
export const PAGES_DETAIL="PAGES_DETAIL";
export const PAGES_DETAILS_EDIT="PAGES_DETAILS_EDIT";
export const PAGES_DETAILS_ALL="PAGES_DETAILS_ALL";
export const ADMIN_USER_GET_LIST="ADMIN_USER_GET_LIST";
export const AGA_MGA_GET_ALL="AGA_MGA_GET_ALL";
export const CUSTOMER_GET_ALL="CUSTOMER_GET_ALL";
export const ADMIN_PROLFILE="ADMIN_PROLFILE";
export const AVSIOR_PERSONAL_DETAILS_EDIT_TYPE="AVSIOR_PERSONAL_DETAILS_EDIT_TYPE";
export const AVSIOR_LICENSE_DETAILS_EDIT_TYPE="AVSIOR_LICENSE_DETAILS_EDIT_TYPE";
export const ADVISOR_CORPORATION_DETAILS_TYPE="ADVISOR_CORPORATION_DETAILS_TYPE";
export const ADVISOR_REFERNCE_DETAILS_TYPE="ADVISOR_REFERNCE_DETAILS_TYPE";
export const CUSTOMER_PROFILE_EDIT_TYPE="CUSTOMER_PROFILE_EDIT_TYPE";
export const ADMIN_PROFILE_EDIT_TYPE="ADMIN_PROFILE_EDIT_TYPE";
export const STUDENT_VISA_SINGLE_QUOTE_TYPE="STUDENT_VISA_SINGLE_QUOTE_TYPE";
export const ADMIN_INSURED_GETDETAILS_TYPE="ADMIN_INSURED_GETDETAILS_TYPE";
export const ADMIN_ALLPOLICES_GET_TYPE='ADMIN_ALLPOLICES_GET_TYPE';
export const ADMIN_ALL_QOUTES_GET_TYPE="ADMIN_ALL_QOUTES_GET_TYPE";
export const QUESTIONS_MGA_AGA_ADVISOR_EDIT_TYPE="QUESTIONS_MGA_AGA_ADVISOR_EDIT_TYPE";
export const ALL_ROLES_TYPE="ALL_ROLES_TYPE";
export const ADMIN_STATUS_EDIT="ADMIN_STATUS_EDIT";
export const ADVISORS_ALL_GET_TYPE="ADVISORS_ALL_GET_TYPE";
export const ALL_AGA_TYPE_LIST="ALL_AGA_TYPE_LIST";
export const ALL_MGA_TYPE_LIST="ALL_MGA_TYPE_LIST";
export const AGA_ASSING_EDIT_TYPE="AGA_ASSING_EDIT_TYPE";
export const MGA_TO_AGA_TYPE="MGA_TO_AGA_TYPE";
export const ALL_DEDUCTABLES_TYPE="ALL_DEDUCTABLES_TYPE";
export const CREATE_DEUCTIBLES_TYPE="CREATE_DEUCTIBLES_TYPE";
export const ASSING_MENUS_TYPE="ASSING_MENUS_TYPE";
export const PENDING_ENDORSEMENT_LIST_TYPE="PENDING_ENDORSEMENT_LIST_TYPE";
export const ADVISOR_GETPOLICIES_TYPE = "ADVISOR_GETPOLICIES_TYPE"
export const ADVISOR_HANDLE_VOID = "ADVISOR_HANDLE_VOID"
export const ADVISOR_VOID_POLICY = "ADVISOR_VOID_POLICY"
export const ADVISOR_CANCEL_POLICY = "ADVISOR_CANCEL_POLICY"
export const ADVISOR_CORRECTION_POLICY = "ADVISOR_CORRECTION_POLICY"
export const ADVISOR_CORRECTION_POLICY_EXTEND = "ADVISOR_CORRECTION_POLICY_EXTEND"
export const ADVISOR_DELETE_QUOTE="ADVISOR_DELETE_QUOTE"
export const ADVISOR_UPDATE_QUOTE = "ADVISOR_UPDATE_QUOTE"
export const ADVISOR_SAVE_POLICY = "ADVISOR_SAVE_POLICY"
export const ADVISOR_CORRECTION_POLICY_NON_FINANCIAL = "ADVISOR_CORRECTION_POLICY_NON_FINANCIAL"
export const COMMISION_LIST_TYPE="COMMISION_LIST_TYPE";
export const COMMISION_SETTINGS_LIST_TYPE="COMMISION_SETTINGS_LIST_TYPE";
export const COMMISION_SETTINGS_EDIT_TYPE="COMMISION_SETTINGS_EDIT_TYPE"
export const REFUND_SETTINGS_LIST_TYPE="REFUND_SETTINGS_LIST_TYPE";
export const REFUND_SETTINGS_EDIT_TYPE="REFUND_SETTINGS_EDIT_TYPE"
export const GET_TEAM_USER_TYPE="GET_TEAM_USER_TYPE";
export const CREATE_TICKET_SUPPORT_TYPE="CREATE_TICKET_SUPPORT_TYPE";
export const ALL_TICKET_GET_TYPE="ALL_TICKET_GET_TYPE";
export const ALL_TICKET_GET_ID_TYPE="ALL_TICKET_GET_ID_TYPE";
export const ALL_TICKET_GET_ADMIN_TYPE="ALL_TICKET_GET_ADMIN_TYPE";
export const SUPPORT_REPLY_MESSAGE_TYPE="SUPPORT_REPLY_MESSAGE_TYPE";
export const GET_ADVISOR_COMMISION_LIST_TYPE="GET_ADVISOR_COMMISION_LIST_TYPE";
export const ADMIN_COMMISION_STATUS_TYPE="ADMIN_COMMISION_STATUS_TYPE";
export const ADMIN_RENEWAL_POLICES_LIST_TYPE="ADMIN_RENEWAL_POLICES_LIST_TYPE";
export const ADVISOR_RENEWAL_POLICES_LIST_TYPE="ADVISOR_RENEWAL_POLICES_LIST_TYPE";
export const CUSTOMER_RENEWAL_POLICES_LIST_TYPE="CUSTOMER_RENEWAL_POLICES_LIST_TYPE";
export const CLAIM_REPORT_ADVISOR_TYPE="CLAIM_REPORT_ADVISOR_TYPE";
export const CLAIM_REPORT_ADVISOR_LIST_TYPE="CLAIM_REPORT_ADVISOR_LIST_TYPE";
export const FORGET_PASSWORD_TYPE="FORGET_PASSWORD_TYPE";
export const ADVISOR_COMM_TYPE ='ADVISOR_COMM_TYPE';
export const CUSTOMER_SAVE_POLICY ='CUSTOMER_SAVE_POLICY';
export const ADMIN_REFUND_REQUEST_TYPE='ADMIN_REFUND_REQUEST_TYPE';


export const isLoading = (data) => {
  return {
    type: IS_LOADING,
    payload: data,
  };
};
