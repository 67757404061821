import { toast } from "react-toastify";
import { PRODUCT_LIST, isLoading } from "../../../constants/actionConstants";
import { HmeService } from "./service";

export const serviceList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    // let categoryList = await HmeService.servceList(params);
    // const {status, message, data} = categoryList

    dispatch({
        type: PRODUCT_LIST,
        payload: [{id: 1}, {id: 2}]
    })

    dispatch(isLoading(false));

    // return status
}