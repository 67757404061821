import { SupportTicketAdminService} from "./service";
import { ALL_TICKET_GET_ADMIN_TYPE, isLoading } from "../../../../constants/actionConstants";

export const supporTicketAllAdminList = (params) => async (dispatch) => {
 
    dispatch(isLoading(true));
    try {
      const response = await SupportTicketAdminService.supporTicketAllAdminList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ALL_TICKET_GET_ADMIN_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };