import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Button, Input, Tab, TabList, Tabs, TabPanel, TabPanels } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { adminRefundRequestList } from './dependencies/action';
import { useDispatch } from 'react-redux';

const RefundRequests = () => {
  const pagination = true;
  const paginationPageSizeSelector = [2, 5, 1000];
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [refundData, setRefundData] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const dispatch = useDispatch();

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    dispatch(adminRefundRequestList())
      .then(res => {
        const { status, data } = res;
        setRefundData(res);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);

  const pendingData = refundData.filter(item => item.status === 0);
  console.log("pendingdata",pendingData)
  const successData = refundData.filter(item => item.status === 1);
  const failureData = refundData.filter(item => item.status === 2);

  const rowData = [
    pendingData,
    successData,
    failureData
  ];

  const colDefs = [
    { field: "policyNo", headerName: "Policy No", width: 280 },
    { field: "approvedDate", headerName: "Approved Date" },
    { field: "policy.quote.policyHolderName", headerName: "Insured Name/Policy Holder Name", width: 330 },
    { field: "endorsementTransNo", headerName: "Endorsement Transaction Number", width: 220 },
    { field: "endorsementType", headerName: "Endorsement Type", width: 220 },
    { field: "amount", headerName: "Refund Amount", width: 220 },
  ];

  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData[tabValue]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[tabValue][0] || {}).join(','), // Header row
      ...rowData[tabValue].map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredData = pendingData.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm)
      )
    );
    setRefundData(filteredData);
  };

  const handleProductChange = (e) => {
    setSelectedProduct(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  const filteredPendingData = pendingData.filter(item => 
    (selectedProduct ? item.product === selectedProduct : true) &&
    (selectedRole ? item.role === selectedRole : true)
  );

  const filteredSuccessData = successData.filter(item => 
    (selectedProduct ? item.product === selectedProduct : true) &&
    (selectedRole ? item.role === selectedRole : true)
  );

  const filteredFailureData = failureData.filter(item => 
    (selectedProduct ? item.product === selectedProduct : true) &&
    (selectedRole ? item.role === selectedRole : true)
  );

  const filteredRowData = [
    filteredPendingData,
    filteredSuccessData,
    filteredFailureData
  ];

  return (
    <Sidebar headingText={'Subscription Dues'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select"  value={selectedProduct}
              onChange={handleProductChange}>
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }}   value={selectedRole}
              onChange={handleRoleChange} id="product-select">
              <option value="">Select Roles</option>
              <option value="AGA">AGA</option>
              <option value="AGA">AGA</option>
              <option value="Advisor">Advisor</option>
              <option value="Customer">Customer</option>
            </select>
          </Box>
        </Flex>
      </div>

      <Box w={'90%'} m={'auto'} mt={'60px'}>
        <Tabs>
          <TabList>
            <Tab _selected={{ background: 'white' }}>Pending</Tab>
            <Tab _selected={{ background: 'white' }}>Success Transcations</Tab>
            <Tab _selected={{ background: 'white' }}>Failure</Tab>
          </TabList>
          <TabPanels background={'white'}>
            <TabPanel>
              <Flex justifyContent={'space-between'} mt={'15px'}>
                <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                </div>
                <div>
                  <label htmlFor="search">Search:</label>
                  <input
                    style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
                    type="text"
                    id="search"
                    placeholder="Search by name"
                    onChange={handleSearch}
                  />
                </div>
              </Flex>

              <div className="ag-theme-quartz" style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
                <AgGridReact
                  rowData={filteredPendingData} // Display pending refund requests
                  columnDefs={colDefs}
                  domLayout='autoHeight'
                  width='100%'
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  pagination={pagination}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </TabPanel>



            <TabPanel>
              <Flex justifyContent={'space-between'} mt={'15px'}>
                <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                </div>
                <div>
                  <label htmlFor="search">Search:</label>
                  <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                </div>
              </Flex>


              <div
                className="ag-theme-quartz"
                style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
              >
                <AgGridReact
                  rowData={filteredSuccessData}
                  columnDefs={colDefs}
                  domLayout='autoHeight'
                  width='100%'
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  pagination={pagination}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </TabPanel>


            <TabPanel>
              <Flex justifyContent={'space-between'} mt={'15px'}>
                <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                  <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                </div>
                <div>
                  <label htmlFor="search">Search:</label>
                  <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                </div>
              </Flex>


              <div
                className="ag-theme-quartz"
                style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
              >
                <AgGridReact
                  rowData={filteredFailureData}
                  columnDefs={colDefs}
                  domLayout='autoHeight'
                  width='100%'
                  paginationPageSizeSelector={paginationPageSizeSelector}
                  pagination={pagination}
                  onGridReady={(params) => {
                    params.api.sizeColumnsToFit();
                  }}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Sidebar>
  )
}

export default RefundRequests;
