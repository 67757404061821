export const API_URL = () => process.env.REACT_APP_API_BASE;

export const SENT_LOGIN_OTP = `${API_URL()}/api/client-login`
export const LOGIN_OTP_VERIFY = `${API_URL()}/api/client-login-verify`
export const SERVICE_LIST = `${API_URL()}/service-url`
export const CUSTOMER_SIGNUP=`${API_URL()}/users/createuser`
export const CUSTOMER_LOGIN = `${API_URL()}/api/v1/login`;
export const CUSTOMER_PROFILE = `${API_URL()}/users/getUser`;
export const ADVISOR_PROFILE = `${API_URL()}/users/getUser`;
export const ADVISOR_CALCULATE_VTC = `${API_URL()}/Quote/calculatePremium`;
export const CUSTOMER_CALCULATE_VTC = `${API_URL()}/Quote/calculatePremiumForCustomer`;
export const ADVISOR_GENERATE_QUOTE = `${API_URL()}/Quote/generateQuote`;
export const ADVISOR_GET_QUOTES = `${API_URL()}/Quote/getListOfQuotationBeanByUser`;
export const CUSTOMER_EDIT_PROFILE = `${API_URL()}/api/v1/users/updateCustomer`;
export const ADVISOR_SIGNUP = `${API_URL()}/users/createAdvisor`;
export const ADVISOR_LOGIN = `${API_URL()}/api/v1/advisorlogin`;
export const SUPERVISA_QUICKQUOTE = `${API_URL()}/quickQuoteOfSuperVisa`;
export const VISITORVISA_QUICKQUOTE = `${API_URL()}/quickQuote`;
export const STUDENTVISA_QUICKQUOTE = `${API_URL()}/quickQuoteOfStudent`;
export const CUSTOMERGETQUOTE=`${API_URL()}/Quote/getListOfQuotationBeanByUser`;
export const SUPERADMIN_LOGIN=`${API_URL()}/api/v1/superAdminLogin`;
export const ADMIN_CREATE=`${API_URL()}/users/createAdminUser`;
export const MENU_LIST=`${API_URL()}/menu/getListOfMenuName`;
export const ADMIN_LOGIN=`${API_URL()}/api/v1/adminLogin`;
export const ADVISOR_SINGLE_QUOTE=`${API_URL()}/Quote/getQuoteByQuotationNo`;
export const CUSTOMER_SINGLE_QUOTE=`${API_URL()}/Quote/getQuoteByQuotationNo`;
export const ALL_PAGES_CONTENT_DETAILS=`${API_URL()}/pages/getListOfPages`;
export const ADMIN_PAGES_GET_BY_ID=`${API_URL()}/pages/getPageById`;
export const ADMIN_PAGES_EDIT_BYID=`${API_URL()}/pages/updatePage`;
export const ADMIN_USERS_LIST=`${API_URL()}/users/getListofAdmin`;
export const AGA_MGA_ALL=`${API_URL()}/users/getListofAdvisors`;
export const CUSTOMER_LIST=`${API_URL()}/users/getListofCustomer`;
export const ADMIN_PROFILE_LIST=`${API_URL()}/users/getUser`;
export const ADVISOR_PERSONAL_DETAILS=`${API_URL()}/users/updateAdvisor`
export const ADVISOR_LICENSE_DETAILS_EDIT=`${API_URL()}/rest/v1/advisor/addLicenceDetails`;
export const ADVISOR_CORPORATION_DETAILS=`${API_URL()}/rest/v1/advisor/addCorporationDetails`;
export const ADVISOR_REFRENCE_DETAILS=`${API_URL()}/rest/v1/advisor/addReferencesDetails`;
export const CUSTOMER_PROFILE_EDIT=`${API_URL()}/users/updateCustomer`;
export const ADMIN_PROFILE_EDIT=`${API_URL()}/users/updateAdmin`;
export const STUDENT_VISA_SINGLE_QUOTE=`${API_URL()}/quickQuoteOfStudent`;
export const ADMIN_INUSRED_DETAILS=`${API_URL()}/insured/getListOfInsured`;
export const ADMIN_ALL_POLICES=`${API_URL()}/policy/getListOfPolicy`;
export const ADMIN_ALL_QUOTES=`${API_URL()}/Quote/getListOfQuotation`;
export const QUESTIONS_EDIT_MGAAGAADVISOR=`${API_URL()}/rest/v1/advisor/updateAdvisorApproval`;
export const ALL_ROLES=`${API_URL()}/users/getListOfAllRole`;
export const ADMIN_STATUS=`${API_URL()}/rest/v1/advisor/approveAdvisor`;
export const AADVISORS_ALL_GET=`${API_URL()}/users/getListofAdvisor`;
export const ALL_AGA_LIST=`${API_URL()}/users/getListofAGA`
export const AGA_ASSING_EDIT=`${API_URL()}/rest/v1/advisor/assignAGA`;
export const ALL_MGA_LIST=`${API_URL()}/users/getListofMGA`;
export const ASSING_AGA_MGA=`${API_URL()}/rest/v1/advisor/assignMGA`;
export const ALL_DEDUCTABLES=`${API_URL()}/Deductibles`;
export const CREATE_DEDUCTABLES=`${API_URL()}/Deductibles/createDeductible`;
export const ASSING_MENUS=`${API_URL()}/users/assignMenuToAdmin`;
export const PENDING_ENDORSEMENT_LIST=`${API_URL()}/endorsement/notCanceledOrApproved`
export const ADVISOR_GET_POLICY_DETAILS=`${API_URL()}/policy/getPolicyById`
export const ADVISOR_HANDLE_VOID=`${API_URL()}/endorsement/voidPolicy`
export const ADVISOR_HANDLE_CANCELLATION=`${API_URL()}/endorsement/cancelPolicy`
export const ADVISOR_HANDLE_CORRECTION_INSURED_DETAILS=`${API_URL()}/endorsement/correctionOfInsuredDetails`
export const ADVISOR_HANDLE_CORRECTION_EXTEND=`${API_URL()}/endorsement/correctionOfDateOfDeparture`
export const ADVISOR_DELETE_QUOTE=`${API_URL()}/Quote/cancelQuote?quotationNumber=`
export const ADVISOR_UPDATE_QUOTE=`${API_URL()}/Quote/updateQuote`
export const ADVISOR_SAVE_POLICY=`${API_URL()}/policy/saveVtcPolicy`
export const ADVISOR_HANDLE_CORRECTION_NON_FINANCIAL=`${API_URL()}/endorsement/correctionOfNonFinancialFeild`
export const COMMISION_LIST=`${API_URL()}/CommissionLedger/list`;
export const COMMISION_SETTINGS_LIST=`${API_URL()}/CommissionSettings`;
export const COMMISION_SETTINGS_EDIT=`${API_URL()}/CommissionSettings`;
export const REFUND_SETTINGS_LIST=`${API_URL()}/RefundChargesSettings/getAllRefundSetting`;
export const REFUND_SETTINGS_EDIT=`${API_URL()}/RefundChargesSettings`;
export const QUOTATION_DOCUMENT_DOWNLOAD = `${API_URL()}/file/downloadFileOfQuotation`;
export const DOWNLOAD_POLICY_DOCUMENT = `${API_URL()}/file/downloadFileOfPolicyDoc`;
export const DOWNLOAD_ENDORSEMENT_ATTACHEMENT = `${API_URL()}/file`;
export const DOWNLOAD_POLICY_DOC = `${API_URL()}/file/downloadFileOfPolicyDocument`;
export const ADVISOR_LICENCE_DETAILS=`${API_URL()}/rest/v1/advisor/addLicenceDetails`;
export const GET_QUOTE_BY_QUOTATION_NO=`${API_URL()}/Quote/getQuoteByQuotationNo`;
export const GET_POLICY_BY_USER=`${API_URL()}/policy/getListOfPoliciesOfUser`;
export const GET_POLICY_BY_POLICY_ID=`${API_URL()}/policy/getPolicyById`;
export const HANDLE_POLICY_REFUND_MID_TERM=`${API_URL()}/endorsement/refundMidTermEndorsement`;
export const GET_CUSTOMER_BY_ID=`${API_URL()}/users`;
export const GET_POLICY_BY_EMAIL=`${API_URL()}/policy/getListOfPoliciesOfUserEmail?email=`;
export const GET_QUOTES_BY_EMAIL=`${API_URL()}/Quote/getListOfQuotationBeanByUserEmail?email=`;
export const ENDORSEMENT_VOID_POLICY=`  ${API_URL()}/endorsement/voidPolicy`;
export const GET_ALL_QUOTATION=`${API_URL()}/Quote/getListOfQuotation`;
export const CANCEL_QUOTE=`${API_URL()}/Quote/cancelQuote?quotationNumber=`;
export const ADMIN_ENDORSEMENT_APPROVEL=`${API_URL()}/admin/approval/endorsementApproval`;
export const DOWNLOAD_TERMS_DOCUMENT=`${API_URL()}/file/downloadFileOfTermsAndElegiblity`;
export const DOWNLOAD_WALLET_CARD=`${API_URL()}/file/downloadFileOfWalletCard`;
export const DOWNLOAD_CLAIM_DOC=`${API_URL()}/file/downloadFileOfClaimDoc`;
export const GET_MONTHLY_POLICIES=`${API_URL()}/policy/getListOfMonthlyPolicy`;
export const POST_GUEST_ENQUIRY=`${API_URL()}/guest-users/createGuestUser`;
export const GENRATE_QUOTE_FOR_USER=`${API_URL()}/Quote/generateQuoteForUser`;
export const CREATE_PARTNER=`${API_URL()}/users/createPartner`;
export const PARTNER_LOGIN=`${API_URL()}/api/v1/partnerLogin`;
export const TEAM_USER_GET=`${API_URL()}/users/getTeamByUser`;
export const CREATE_TICKET_SUPPORT=`${API_URL()}/api/tickets/createTicket`;
export const ALL_TICKET_GET=`${API_URL()}/api/tickets/getTicketsByUserEmail`;
export const ALL_TICKET_GET_ID=`${API_URL()}/api/tickets/getTicketByReferenceNo`;
export const ALL_TICKET_GET_ADMIN=`${API_URL()}/api/tickets/getAllTickets`;
export const SEND_QUOTE=`${API_URL()}/api/email/sendByQuotation?quotationNumber=`;
export const SEND_POLICY=`${API_URL()}/api/email/sendByPolicy?policyNumber=`;
export const SUPPORT_REPLY_MESSAGE=`${API_URL()}/api/tickets/replyToTicket`;
export const GET_ADVISOR_COMMISION_LIST=`${API_URL()}/CommissionLedger/getCommissionOfAdvisor`;
export const ADMIN_COMMISION_STATUS=`${API_URL()}/CommissionLedger/updateCommissionSettlement`;
export const CUSTOMER_GENERATE_QUOTE = `${API_URL()}/Quote/generateQuoteForCustomer`;
export const ADMIN_RENEWAL_POLICES_LIST=`${API_URL()}/policy/getListOfRenewalPolicy`;
export const ADVISOR_RENEWAL_POLICES_LIST=`${API_URL()}/policy/getListOfRenewalPolicyOfUser`;
export const CUSTOMER_RENEWAL_POLICES_LIST=`${API_URL()}/policy/getListOfRenewalPolicyOfUser`;
export const DOWNLOAD_BENEFIT_COMPARISON=`${API_URL()}/file/downloadFileOfBenefitDocument`
export const CLAIM_REPORT_ADVISOR=`${API_URL()}/api/claimsReport/CreateClaims`;
export const CLAIM_REPORT_ADVISOR_LIST=`${API_URL()}/api/claimsReport/getAllClaimsDetails`;
export const FORGET_PASSWORD=`${API_URL()}/api/passwordreset/reset-password`;
export const ADVISOR_COMM=`${API_URL()}/CommissionLedger/getCommissionDetails`;
export const ADMIN_REFUND_REQUEST=`${API_URL()}/endorsement/getListOfPendingRefundRequest`;
export const ADMIN_UPDATE_QUOTE=`${API_URL()}/Quote/updateQuoteForUser`;
export const ADMIN_COMPARISON_QUOTES=`${API_URL()}/Quote/getListOfCompareQuotation`





// export const SENT_LOGIN_OTP = `${API_URL()}/api/client-login`
// export const LOGIN_OTP_VERIFY = `${API_URL()}/api/client-login-verify`
// export const SERVICE_LIST = `${API_URL()}/service-url`