import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Tooltip, Button, Input, ChakraProvider, FormControl, theme, FormLabel } from '@chakra-ui/react';
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../Utils/UniversalColor';
import { advisorDeleteQuote, advisorGetQuotes } from '../Dependencies/action';
import CustomInput from '../../../Utils/Input/Index';
import { useNavigate } from 'react-router-dom';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import Api from '../../../dependencies/utils/Api';
import { SEND_QUOTE } from '../../../constants/ApplicationUrl';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AdvisorMyQuote = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const dispatch = useDispatch();
  const [quotesData, setQuotesData] = useState([]);
  console.log("quotesData",typeof(quotesData))
  const [colDefs, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };

    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getAllQuotes();
    setColDefs([
      { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150, filter: true },
      { field: "designation", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 110, filter: true },
      { field: "product", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150, filter: true },
      { field: "quotationNo", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150, filter: true },
      { field: "dateOfQuote", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 120, filter: true },
      { field: "tripType", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 90, filter: true },
      { field: "customerName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150 },
      { field: "amount", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 80, filter: true },
      {
        field: "Action", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 180,
        cellRenderer: props => {
          return <>
            <Tooltip bg='white' placement='right-start' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='You can view, send ,edit and cancel quote here.'>
              <Select onChange={(e) => handleChange(e, props)} size={'xs'} style={{ backgroundColor: '#96ffff', border: '1px solid black' }}>
                <option value="">Select</option>
                <option value="view">View Quote</option>
                <option value="send">Send Quote</option>
                <option value="edit">Edit Quote</option>
                <option value="cancel">Cancel Quote</option>
              </Select>
            </Tooltip>
          </>;
        }
      }
    ]);
  }, []);

  const handleChange = (e, props) => {
    const { data } = props;
    if (e.target.value === 'view') {
      navigate(`/advisor-view-quote/${data.quotationNo}`)
    }
    if (e.target.value === 'send') {
      Api.POST(`${SEND_QUOTE}${data.quotationNo}`).then((res) => {
        console.log(res, 'quotes sending')
      })
      alert('Quote has been sent successfully')
    }
    if (e.target.value === 'edit') {
      navigate(`/advisor-edit-quote/${data.quotationNo}`)
    }
    if (e.target.value === 'cancel') {
      var result = confirm("Are you sure you want to delete?");
      if (result) {
        dispatch(advisorDeleteQuote(data.quotationNo)).then((res) => {
          console.log(res)
          if (res.status === 200) {
            getAllQuotes()
          } else {
            alert('Something went wrong')
          }

        })
      }
    }
  };

  const getAllQuotes = () => {
    dispatch(advisorGetQuotes()).then((res) => {
      if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
        setQuotesData(res.data);
        const formattedData = res.data.map((quote, index) => ({
          id: index + 1,
          employeeName: `${quote.user.firstName} ${quote.user.lastName}`,
          designation: quote.user.roles.name,
          product: quote.product,
          quotationNo: quote.quotationNo,
          dateOfQuote: quote.createdAt,
          tripType: quote.tripType,
          customerName: quote.policyHolderName,
          amount: `$ ${quote.quoteAmount}`,
        }));
        setRowData(formattedData);
      } else {
        alert('No quotes data available.');
      }
    }).catch((error) => {
      console.error('Error fetching quotes:', error);
      alert('Failed to fetch quotes.');
    });
  };
  


  const downloadPDF = () => {
    console.log("quotesData:", quotesData); // Check if quotesData is accessible and contains data
  
    if (!Array.isArray(quotesData) || quotesData.length === 0) {
      alert('No data available to download.');
      return;
    }
  
    // Mapping quotesData to rows
    const rows = quotesData.map(quote => ({
      employeeName: quote.user.firstName + ' ' + quote.user.lastName,
      designation: quote.user.roles.name,
      product: quote.product,
      quotationNo: quote.quotationNo,
      dateOfQuote: new Date(quote.createdAt).toLocaleDateString(), // Format date
      tripType: quote.tripType,
      customerName: quote.policyHolderName,
      amount: `$ ${quote.quoteAmount.toFixed(2)}` // Format amount
    }));
  
    console.log("rows:", rows); // Check if rows is populated correctly
  
    if (rows.length === 0) {
      alert('No data available to download.'); // Ensure a user-friendly message if no data
      return;
    }
  
    const doc = new jsPDF();
    doc.setFontSize(16);
    doc.text('Quotations', 14, 22);
  
    const columns = [
      { title: "Employee Name", dataKey: "employeeName" },
      { title: "Designation", dataKey: "designation" },
      { title: "Product", dataKey: "product" },
      { title: "Quotation No", dataKey: "quotationNo" },
      { title: "Date of Quote", dataKey: "dateOfQuote" },
      { title: "Trip Type", dataKey: "tripType" },
      { title: "Customer Name", dataKey: "customerName" },
      { title: "Amount", dataKey: "amount" }
    ];
  
    doc.autoTable({
      head: [columns.map(col => col.title)],
      body: rows.map(row => columns.map(col => row[col.dataKey])), // Transforming rows to array of arrays
      startY: 30,
      theme: 'striped',
      styles: { fontSize: 10 },
      columnStyles: {
        // Optionally, you can define specific styles for columns
        amount: { halign: 'right' } // Example: align amount column to the right
      },
      margin: { top: 25 } // Optional margin to improve PDF layout
    });
  
    doc.save('quotations.pdf');
  };
  
  
  
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };
  
  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','),
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  // Filter the data based on the search term
  const filteredRowData = rowData.filter(row =>
    row.employeeName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.product.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.quotationNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.dateOfQuote.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.tripType.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.amount.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box background={'#f6f6f6'} h={'auto'} minHeight={'100vh'}>
      <Box w={['90%', '99%', '80%']} m={'auto'} style={{ paddingTop: '90px', paddingBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '10px' }}>My Quotes</h3>
        <br />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={['5px', '15px', '15px']}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <Button size={'sm'} leftIcon={<FaRegFilePdf />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
              <Button leftIcon={<SiMicrosoftexcel />} size={'sm'} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            {/* <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}> */}
              {/* <Button size={'sm'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip> */}
          </div>
          <div>
            {/* <CustomInput /> */}
            {/* <Input
            variant="floating"
            borderRadius={5}
            size={'sm'}
            background={'white'}
              style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
             
            /> */}
            <ChakraProvider theme={theme}>
  <FormControl variant="floating" id="search">
  <FormLabel size={'sm'}>Search</FormLabel>
    <Input
      borderRadius={5}
      size={'sm'}
      background={'white'}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)} // Call handleChange function on input change
    />
   
  </FormControl>
</ChakraProvider>
          </div>
        </Flex>
        <Box pb={'70px'} pt={['15px', '1px', '1px']}>

            <div className='ag-theme-quartz' style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
              <AgGridReact
                ref={gridRef}
                rowData={filteredRowData}
                columnDefs={colDefs}
                domLayout='autoHeight'
                paginationPageSizeSelector={paginationPageSizeSelector}
                pagination={pagination}
                paginationPageSize={10}
                onGridReady={(params) => {
                  if (window.innerWidth > 768) {
                    params.api.sizeColumnsToFit();
                  }
                }}
              />
            </div>
        </Box>
      </Box>
    </Box>
  );
};

export default AdvisorMyQuote;
