import { toast } from "react-toastify";
import {  ADMIN_REFUND_REQUEST_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminRefundRequestService } from "./service";

export const adminRefundRequestList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminRefundRequestService.adminRefundRequestList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_REFUND_REQUEST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };