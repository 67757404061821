import {Button,Checkbox,Input,Select,Box,Flex,Text } from "@chakra-ui/react";
  import React, { useEffect, useRef, useState } from "react";
  import { useToast } from "@chakra-ui/react";
  import { buttonBackgroundColor, buttonColor} from "../../../Utils/UniversalColor";
  import Description from "./Description";
  import { useDispatch } from "react-redux";
  import { advisorCalculateVtc, advisorGenerateQuote } from "../../Advisor/Dependencies/action";
  import { useNavigate } from "react-router-dom";
  import { useLocation } from "react-router-dom";
import { ROLES } from "../../../constants/role";
import { CUSTOMER_GENERATE_QUOTE } from "../../../constants/ApplicationUrl";
import { storage } from "../../../dependencies/store/storage";
import { validateEmail, validateMobileNumber } from "../../Advisor/AdvisorQuote/Validation";
import Api from "../../../dependencies/utils/Api";
import { format } from "date-fns";
  
  
  function TripDetails() {
    const role = storage.getUserRole();
    const location = useLocation();
    const data1 = location.state?.data;
    const toast = useToast();
    const [waitingPeriod, setWaitingPeriod] = useState("NA");
    const [checked, setChecked] = useState(false);
    const dispatch=useDispatch()
    const [policyLimit,setPolicyLimit]=useState(data1.rowData.sumInsured)
    const [dedecutibles,setDeductibles]=useState(data1.fieldsData.deductible)
    const [policyType,setPolicyType]=useState(data1.policyType)
    const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
    const [PlanType,setPlanType]=useState('Standard')
    const [amount,setAmount]=useState(data1.amount)
    const [duration,setDuration]=useState(0)
    const navigate=useNavigate()
    const insuredDetailsRequests = data1.travelers;
    console.log(data1,'insured Details requests ')
    const initialTraveler = {
      name: "",
      gender: "",
      dob: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
    };

    const [addTravelers, setAddTravelers] = useState(insuredDetailsRequests);
    const formatDate = (isoDateString) => {
      const date = new Date(isoDateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');      
      return `${year}-${month}-${day}`;
    };

      useEffect(() => {
        setAddTravelers(
          insuredDetailsRequests.map((insured) => ({
            ...initialTraveler,
            dob: insured.insuredDOB,
            selectedKey:insured.selectedKey,
            costWithPreExisting:insured.costWithPreExisting,
            costWithoutPreExisting:insured.costWithoutPreExisting,
          }))
        );
      }, [insuredDetailsRequests]);

      const [formData, setFormData] = useState({
        firstDate: data1.fieldsData.policyStartDate,
        lastDate: formatDate(data1.fieldsData.policyEndDate),
        departureDate: "",
        departureMaxDate: "",
      });

      const [policyHolder, setPolicyHolder] = useState({
        policyOwnerName: '',
        policyOwnerDob: '',
        policyOwnerEmail: '',
        policyOwnerAddress: '',
        policyOwnerPostalCode: '',
        policyOwnerCity: '',
        policyOwnerPhone: ''
      });
  
      const handlePolicyOwnerChange = (e) => {
        const { name, value } = e.target;
        setPolicyHolder({ ...policyHolder, [name]: value });
        const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
        if (selectedPolicyHolder) {
          setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
        }
      };
      
      const [errors, setErrors] = useState({
        firstDate: "Enter First Date cover",
        lastDate: "",
        departureDate: "",
      });
  
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (formData.firstDate.length === 0) {
          setErrors({ ...errors, firstDate: "Enter First Date cover" });
        }
        if (formData.lastDate.length === 0) {
          setErrors({ ...errors, lastDate: "Enter last Date cover" });
        }
        if (formData.departureDate.length === 0) {
          setErrors({ ...errors, departureDate: "Enter Departure Date cover" });
        }
        if (name === "departureDate" && formData.firstDate.length === 0) {
          alert("Please first choose the first date of cover.");
          return;
        }
    
        if (name === "firstDate") {
          const startDate = new Date(value);
          const departureMaxDate = new Date(startDate);
          departureMaxDate.setDate(departureMaxDate.getDate());
          const formattedMaxDate = departureMaxDate.toISOString().split("T")[0];
          setFormData((prevData) => ({
            ...prevData,
            departureMaxDate: formattedMaxDate,
          }));
    
        
        }
      
        setErrors({ ...errors, [name]: "" });
      };
    
      useEffect(() => {
        if(policyType!=='Studentvisa'){
        if (!formData.departureDate) {
          setWaitingPeriod("NA");
        } else {
          const today = new Date(formData.firstDate);
          const thirtyDaysAgo = new Date(today);
          thirtyDaysAgo.setDate(today.getDate() - 30);
      
          const selectedDate = new Date(formData.departureDate);
      
          if (selectedDate >= today) {
            setWaitingPeriod("NA");
          } else {
            const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
            setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
          }
        }
      }
      if(policyType==='Studentvisa' && formData.departureDate){
        const formattedDate = format(formData.firstDate, 'yyyy-MM-dd');
        const formateSelected = format(formData.departureDate,'yyyy-MM-dd')
      //   const formattedDate = new Date(formData.firstDate);
      // const formateSelected = new Date(formData.departureDate);
      // console.log(formattedDate,formateSelected)
        const selectedDate = formateSelected
        if (selectedDate >= formattedDate) {
          setWaitingPeriod("NA");
        } else {
          setWaitingPeriod("5 days");
        }
        }
      }, [formData.departureDate, formData.firstDate]);
  
    const handleChangeTraveler = (index, e) => {
      const { name, value } = e.target;
      if (name === "dob" || name === "benificiaryDob") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
      setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
      });
    };
    
    const handleGetQuote = () =>{
      const isAnyBeneficiaryDetailEmpty = addTravelers.some(
        (traveler) =>
           traveler.name === "" ||
          traveler.gender === "" ||
          traveler.benificiaryName === "" ||
          traveler.benificiaryDob === "" ||
          traveler.relation === ""
      );
      if (isAnyBeneficiaryDetailEmpty) {
        alert("Please fill in all mandatory details.")
        return;
      }
      if(!formData.departureDate){
        alert("Please enter date of departure")
        return;
      }
      if(policyHolder.policyOwnerName==="" || policyHolder.policyOwnerAddress==="" || policyHolder.policyOwnerCity===""|| policyHolder.policyOwnerDob==="" || policyHolder.policyOwnerEmail==""){
        alert("Please enter mandatory fields.")
        return;
      }
      if (policyHolder.policyOwnerEmail === 0 || !validateEmail(policyHolder.policyOwnerEmail)){
        alert('Please enter a valid email');
        return;
      }
      if(policyHolder.policyOwnerPhone === 0 || !validateMobileNumber(policyHolder.policyOwnerPhone)){
        alert("Please enter a valid mobile.")
        return;
      }



      const startDate = new Date(formData.firstDate);
      const endDate = new Date(formData.lastDate);
      const diffInMilliseconds = endDate - startDate;
      const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)+1);
      setDuration(durationInDays);
      const updatedTravelers = addTravelers.map((traveler) => ({
        nameOfInsured: traveler.name,
        gender: traveler.gender,
        dateOfBirth: traveler.dob,
        beneficiaryRelationToInsured: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDOB: traveler.benificiaryDob,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
        selectedKey:traveler.selectedKey
      }));  
      const dataToSend = {
        email: policyHolder.policyOwnerEmail,
        firstDateOfCover: formData.firstDate,
        lastDateOfCover:formatDate(formData.lastDate),
        policyEndDate: formData.lastDate,
        duration:parseInt(duration),
        country:1,
        province:'Ontario',
        departureFromCountryOfOrigin:formData.departureDate,
        waitingPeriod:waitingPeriod,
        policyLimit:policyLimit,
        deductible:dedecutibles,
        paymentFrequency:policyTypeSuperVisa,
        policyOwner:policyHolder.policyOwnerName,
        policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
        policyOwnerEmail:policyHolder.policyOwnerEmail,
        policyOwnerAddress:policyHolder.policyOwnerAddress,
        policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
        policyOwnerCity:policyHolder.policyOwnerCity,
        policyOwnerPhone:policyHolder.policyOwnerPhone,
        planType:PlanType,
        insuredTravellerDetailsRequest: updatedTravelers,
        policyType:policyType,
        amount: amount,
        familyCoverage:checked===true?1:0
    };
        console.log(dataToSend,'dataTo Send')
        if(role === ROLES.CUSTOMER || role===ROLES.ADVISOR){
          dispatch(advisorGenerateQuote(dataToSend)).then(res => {
          const {status, data} = res
          if(status===200 || status===201){
            console.log(data,'data')
            alert('Quote Generated Successfully')
            navigate(`/customer-view-quote/${data.quotationNo}`)
          }else{
            alert("something went wrong")
          }
        })
        }else{
          Api.POST(CUSTOMER_GENERATE_QUOTE,dataToSend).then((res)=>{
            console.log(res)
            if(res.status===200 || res.status===201){
              storage.setUser(res.data)
              navigate(`/customer-view-quote/${data.quotationNo}`);
            }else{
              navigate('/customerLogin')
              alert('Please login First.')
              console.log('Somthing went worng.')
            }
          })
        }
        // dispatch(advisorGenerateQuote(dataToSend)).then(res => {
        //   const {status, data} = res
        //   console.log(data,'data')
        //   const tabNumber=1
        //   sendDataToParent({'amount':amount,'data':data,'tab':tabNumber});
        // })
    }
  
  
return (
      <Box padding={['2px','2px','10px']} style={{ width: "100%",paddingTop:'0px',textAlign:'left'}}>
        <Box w={['99%','90%','90%']} style={{background:'white', display: "flex",flexDirection: "column",alignItems: "center",margin:'auto'}}>
            <div
              style={{
                width: "100%",
                maxWidth: "800px",
                margin: "auto",
                padding: "18px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              }}
            >
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "18px",
                  fontWeight: 400,
                  marginBottom: "20px",
                  borderBottom: "2px solid #088183",
                }}
              >
                Traveller Details
              </p>
              {addTravelers.map((item, index) => {
                return (
                  <Box mb={'7px'} backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} fontSize={'13px'} fontFamily={'Open Sans, sans-serif'}>
                  <Flex key={index} direction={['column','column','row']} gap={['10px','20px','20px']}
                    style={{
                      margin: "auto",
                      padding: "18px",
                    }}
                  >
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                      <Input background={'white'} name="name" value={item.name}
                        onChange={(e) => handleChangeTraveler(index, e)} size='xs' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                    </Box>
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Gender</span>
                      <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                         value={item.gender}
                         name="gender"
                        onChange={(e) => handleChangeTraveler(index, e)}>
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                        </Select>
                    </Box>
                    <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                      <Input readOnly background={'white'} name="dob"
                        onChange={(e) => handleChangeTraveler(index, e)}
                        value={item.dob} size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                    </Box>
                    </Flex>
                    <Flex key={index} direction={['column','column','row']} gap={['10px','20px','20px']} p={'18px'} pt={'5px'} pb={'20px'}>
                      <Box w={['90%','90%','30%']}>
                        <span style={{ marginBottom: "8px" }}>
                          Beneficiary Name
                        </span>
                        <Input background={'white'} name="benificiaryName" placeholder="Name"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.benificiaryName} size='xs' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','90%','30%']}>
                        <span style={{ marginBottom: "8px" }}>Relation</span>
                        <Input background={'white'} name="relation"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.relation}
                          placeholder="Relation" size='xs' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','90%','30%']}>
                      <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                      <Input background={'white'} onChange={(e) => handleChangeTraveler(index, e)}
                        name="benificiaryDob"
                        value={item.benificiaryDob}
                        placeholder="Select Date and Time" size='xs' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} />
                      </Box>
                      </Flex>
                     </Box>
                );
              })}        
              <div
                style={{
                  gap: "20px",
                  margin: "auto",
                  padding: "18px",
                }}
              >
                <Flex direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'15px'}>
                <Box w={['90%','90%','30%']}>
                  <span style={{ marginBottom: "8px" }} fontSize='13px'>Departure from country of origin</span>
                  <Input name="departureDate" max={formData.departureMaxDate}
                    onChange={handleChange} size='xs' type='date' border={'1px solid #d7d7d7'} />
                </Box>
                <Flex direction={'column'}>
                  <span>Waiting Period</span>
                  <input
                    placeholder="Basic usage"
                    name="waiting"
                    value={waitingPeriod}
                  />
                </Flex>
                <Box >
                  {/* <Text>Family Coverage</Text>
                <Checkbox pt={'7px'} onChange={handleCheckbox} size="md" colorScheme="green"></Checkbox> */}
                </Box>
                </Flex>
                  <hr/>
                <Flex direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'20px'} mt={['10px','30px','30px']}>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner*</Text>
                  <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                      <option value="">Select Policy Owner</option>
                      {addTravelers.map((traveler, index) => (
                      <option key={index} value={traveler.name}>
                          {traveler.name}
                      </option>
                      ))}
                  </Select>
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Date Of Birth*</Text>
                  <Input type='date' size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Email*</Text>
                  <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                  <Text color="red.500">{errors.policyOwnerEmail}</Text>        
                  </Box>
                  </Flex>
                  <Flex direction={['column','column','row']} gap={['10px','20px','20px']} fontSize={'14px'} pb={'20px'} mt={['10px','20px','20px']}>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Postal Code*</Text>
                  <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                  <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner City*</Text>
                  <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                  <Text color="red.500">{errors.policyOwnerCity}</Text>         
                  </Box>
                  <Box w={['90%','90%','30%']}>
                  <Text>Policy Owner Phone*</Text>
                  <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                  <Text color="red.500">{errors.policyOwnerPhone}</Text>
                  </Box>
                  </Flex>
                  <Box pb='30px' w={['90%','90%','30%']}>
                  <Text fontSize={'12px'}>Policy Owner Address*</Text>
                  <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                  <Text color="red.500">{errors.policyOwnerAddress}</Text>       
                  </Box>
                  <Flex justifyContent={'end'}>
                  <Button size={'xs'} background={buttonBackgroundColor} color={buttonColor} onClick={handleGetQuote} > Get Quote</Button>
                  </Flex>
              </div>
            </div>
          <Description/>
        </Box>
      </Box>
    );
  }
  
  export default TripDetails;
  