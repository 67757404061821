import Home from "../../Components/Home";
import Aboutus from "../../Components/Aboutus";
import QuickQuote from "../../Components/QuickQuote";
import AdvisorLogin from "../../Components/Advisor/AdvisorLogin";
import AdvisorRegister from "../../Components/Advisor/AdvisorSignup";
import CustomerLogin from "../../Components/Customer/CustomerLogin";
import Enquiry from "../../Components/Enquiry";
import GetQuote from "../../Components/SuperVisa/GetQuoteFamilyCover";
import PlanCompare from "../../Components/SuperVisa/PlanComparison";
import ClaimProcedure from "../../Components/SuperVisa/ClaimProcedure";
import Terms from "../../Components/Terms&Conditions";
import Privacy from "../../Components/PrivacyPolicy";
import CustomerSignUp from "../../Components/Customer/CustomerSignup";
import GetQuoteComp from "../../Components/VisitorInsurance/GetQuoteFamilyCover";
import PolicyWording from '../../Components/SuperVisa/PolicyWording'
import VisitorPlanCompare from "../../Components/VisitorInsurance/PlanComparison";
import VisitorPolicyWording from "../../Components/VisitorInsurance/PolicyWording";
import VisitorClaimProcedure from "../../Components/VisitorInsurance/ClaimProcedure";
import CustomerProfile from "../../Components/Customer/CustomerProfile/index";
import CustomerHomePage from "../../Components/Customer/CustomerHomePage";
import StudentGetQuoteComp from "../../Components/StudentInsurance/GetQuoteSingle";
import StudentPlanCompare from "../../Components/StudentInsurance/PlanComparison";
import StudentPolicyWording from "../../Components/StudentInsurance/PolicyWording";
import StudentClaimProcedure from "../../Components/StudentInsurance/ClaimProcedure";
import StudentTravelContactUs from "../../Components/StudentInsurance/ContactUs";
import VisitorContactUs from "../../Components/VisitorInsurance/ContactUs";
import SuperVisaContactUs from "../../Components/SuperVisa/ContactUs";
import TripDetails from "../../Components/SuperVisa/GetQuoteSingle/Tripdetails";
import MyQuotation from "../../Components/Customer/MyQuoation/MyQuoation";
import CustomerPolicies from "../../Components/Customer/CustomerPolicies";
import AdvisorProfile from "../../Components/Advisor/AdvisorProfile";
import AdvisorMyQuote from "../../Components/Advisor/AdvisorMyQuote";
import AdvisorMyPolicy from "../../Components/Advisor/AdvisorMyPolicy";
import AdvisorCommission from "../../Components/Advisor/AdvisorCommission";
import AdvisorTeam from "../../Components/Advisor/AdvisorTeam";
import AdvisorGenerateQuote from "../../Components/Advisor/AdvisorQuote";
import NewCode from "../../Components/QuickQuote/index";
import SuperAdminPanel from "../../Components/SuperAdmin";
import GetQuickQuote from "../../Components/QuickQuote/GetQuote";
import AdvisorViewSingleQuote from "../../Components/Advisor/AdvisorViewSingleQuote";
import CustomerSingleQuoteView from "../../Components/Customer/CustomerSingleViewQuote";
import SuperAdminLogin from "../../Components/SuperAdmin/SuperAdminLogin";
import SuperAdminDashbaord from "../../Components/SuperAdmin";
import SuperAdminPermessionPanel from "../../Components/SuperAdmin/SuperAdminPermission";
import CreateAdmin from "../../Components/SuperAdmin/CreateAdmin";
import CustomerEditQuote from "../../Components/Customer/CustomerEditQuote";
import AdminDashboard from "../../Components/Admin/AdminDashboard";
import AdminProfile from "../../Components/Admin/AdminProfile";
import AdminUsers from "../../Components/Admin/AdminUsers";
import Customers from "../../Components/Admin/Customers";
import AssignMga from "../../Components/Admin/AssignMga";
import AssignAga from "../../Components/Admin/AssignAga";
import Products from "../../Components/Admin/Products";
import InsurancePlans from "../../Components/Admin/InsurancePlans";
import Deductibles from "../../Components/Admin/Deductibles";
import Quotes from "../../Components/Admin/Quotes";
import Policies from "../../Components/Admin/Policies";
import Commission from "../../Components/Admin/Commission";
import RefundRequests from "../../Components/Admin/RefundRequests";
import GuestEnquiry from "../../Components/Admin/GuestEnquiry";
import Pages from "../../Components/Admin/Pages";
import Reports from "../../Components/Admin/Reports";
import Logs from "../../Components/Admin/Logs";
import MgaAgaAdvisor from "../../Components/Admin/MgaAgaAdvisor";
import AdminAllPolicies from "../../Components/Admin/Policies/AdminAllPolicies";
import RenewalPolicies from "../../Components/Admin/Policies/RenewalPolicies";
import ClaimRequests from "../../Components/Admin/Policies/ClaimRequests";
import PolicyDocs from "../../Components/Admin/Policies/AdminAllPolicies/PolicyDocs";
import QutotesDocDownload from "../../Components/Admin/Quotes/QuotesDocDownload";
import AdminQuoteEdit from "../../Components/Admin/Quotes/AdminQuoteEdit";
import QuotePaymentTranscation from "../../Components/Admin/Quotes/QuotePaymentTranscation";
import AssignAdmin from "../../Components/Admin/AdminUsers/AssignAdmin";
import EditAdmin from "../../Components/Admin/AdminUsers/EditAdmin";
import EditProduct from "../../Components/Admin/Products/EditProduct";
import ViewPolicyPaymentTranscations from "../../Components/Admin/Policies/AdminAllPolicies/ViewPolicyPaymentTranscations";
import ViewDeclinedTranscationList from "../../Components/Admin/Policies/AdminAllPolicies/ViewDeclinedTranscationList";
import PolicyEndorsements from "../../Components/Admin/Policies/AdminAllPolicies/PolicyEndorsements";
import MonthlyPolicies from "../../Components/Admin/Policies/MonthlyPolicies";
import AdvisorPolicyEndorsement from "../../Components/Advisor/AdvisorMyPolicy/AdvisorPolicyEndorsement";
import AdviosrSinglePolicyView from "../../Components/Advisor/AdvisorMyPolicy/AdvisorSinglePolicyView";
import CustomerRenewalPolicies from "../../Components/Customer/CustomerPolicies/CustomerRenewalPolicies";
import AdvisorPolicyDocuments from "../../Components/Advisor/AdvisorMyPolicy/AdvisorPolicyDocuments";
import AdvisorEditQuote from "../../Components/Advisor/AdvisorEditQuote";
import InsuranceCompanyReports from "../../Components/Admin/Reports/InsuranceCompanyReports";
import ViewTable from "../../Components/Admin/InsurancePlans/ViewTable";
import AdminCustomerPolicies from "../../Components/Admin/Customers/AdminCustomerPolicies"
import AdminCustomerDashboard from "../../Components/Admin/Customers/CustomerDashboard";
import AdminCustomerProfile from "../../Components/Admin/Customers/AdminCustomerProfile";
// import AdminCustomerPolicies from "../../Components/Admin/Customers/AdminCustomerPolicies";
import AdminCustomerSinglePolicyView from "../../Components/Admin/Customers/AdminCustomerPolicies/AdminCustomerSinglePolicyView";
import AdminCustomerPolicyEndorsement from "../../Components/Admin/Customers/AdminCustomerPolicies/AdminCustomerPolicyEndorsement";
import AdminCustomerViewQuote from "../../Components/Admin/Customers/AdminCustomerViewQuote";
import AdminCustomerRenewalPolicies from "../../Components/Admin/Customers/CustomerRenewalPolicies";
import AddProduct from "../../Components/Admin/Products/AddProduct";
import SubscriptionDue from "../../Components/Admin/Policies/SubscriptionDue";
import TravelAccountingReport from "../../Components/Admin/Reports/TravelAccountingReport";
import AccountPayableReport from "../../Components/Admin/Reports/AccountPayableReport";
import SalesAndTpaReport from "../../Components/Admin/Reports/SalesAndTpaReport";
import UserCommissionReport from "../../Components/Admin/Reports/UserCommissionReport";
import CustomerPolicyDocuments from "../../Components/Customer/CustomerPolicies/CustomerPolicyDocuments";
import Home2 from "../../Components/Home/Home2";
import EditMgaAgaAdvisor from "../../Components/Admin/MgaAgaAdvisor/EditMGAAGAADVISOR";
import AdminAllAdvisors from "../../Components/Admin/AdminAllAdvisors";
import AdminAdvisorDashboard from "../../Components/Admin/AdminAllAdvisors/AdvisorDashboard";
import AdminAdvisorPolicies from "../../Components/Admin/AdminAllAdvisors/AdvisorPolicies";
import AdminAdvisorPolicySingleView from "../../Components/Admin/AdminAllAdvisors/AdvisorPolicySingleView";
import AdminAdvisorPolicyEndorsement from "../../Components/Admin/AdminAllAdvisors/AdvisorPolicyEndorsement";
import AdminAdvisorSingleViewQuote from "../../Components/Admin/AdminAllAdvisors/AdvisorSingleViewQuote";
import AdvisorUnapprovedProfile from "../../Components/Advisor/AdvisorUnapprovedProfile";
import AssingAgaToAdvisor from "../../Components/Admin/AssignAga/AssingAgaToAdvisor";
import AssingMgaToAga from "../../Components/Admin/AssignMga/AssingMgaToAga";
import CreateDeductibles from "../../Components/Admin/Deductibles/AddNewDeductibles";
import UpdateDeductables from "../../Components/Admin/Deductibles/EditDeductibles";
import AdminPendingEndorsement from "../../Components/Admin/AdminPendingEndoresemnt";
import CustomerPolicyEndorsement from "../../Components/Customer/CustomerPolicies/CustomerPolicyEndorsement";
import AdvisorForgetPassword from "../../Components/Advisor/AdvisorForgetPassword.jsx";
import CustomerForgetPassword from "../../Components/Customer/CustomerForgetPassword/index.jsx";
import CustomerSinglePolicyView from "../../Components/Customer/CustomerPolicies/CustomerSinglePolicyView/index.jsx";
import CommisionSettings from "../../Components/Admin/Commission/Commision Settings";
import RefundChargesSettings from "../../Components/Admin/Refund Charges Settings";
import AdminLogin from "../../Components/Admin/AdminLogin/index.jsx";
import AdminAdvisorGenerateQuote from "../../Components/Admin/AdminAllAdvisors/AdminAdvisorGenerateQuote/index.jsx";
import AdminServices from "../../Components/Admin/Services/index.jsx";
import AdminAdvisorRenewalPolicies from "../../Components/Admin/AdminAllAdvisors/AdminAdvisorRenewalPolicies/index.jsx";
import AdminWhiteLabel from "../../Components/Admin/Services/WhiteLabel/index.jsx";
import AdminAddWhiteLabelUser from "../../Components/Admin/Services/WhiteLabel/AddWhiteLabelUser/index.jsx";
import AdminAffiliateUsers from "../../Components/Admin/Services/Affiliates/index.jsx";
import AdminAddAffiliateUser from "../../Components/Admin/Services/Affiliates/AddAffiliateUser/index.jsx";
import AdminPartners from "../../Components/Admin/Services/Partners/index.jsx";
import ServiceUserLogin from "../../Components/Service/ServiceUserLogin/index.jsx";
import ServiceDashboard from "../../Components/Service/ServiceDashboard/index.jsx";
import ServiceThemes from "../../Components/Service/Themes/index.jsx";
import Tickets from "../../Components/Tickets/index.jsx";
import AllTickets from "../../Components/Tickets/AllTickets/index.jsx";
import ViewSingleTicket from "../../Components/Tickets/ViewSingleTicket/index.jsx";
import AdminTickets from "../../Components/Admin/AdminTickets/index.jsx";
import AdminViewSingleTicket from "../../Components/Admin/AdminTickets/AdminViewSingleTicket/index.jsx";
import CreateTheme from "../../Components/Service/Themes/CreateTheme/index.jsx";
import ServiceSupport from "../../Components/Service/Support/index.jsx";
import MyTheme from "../../Components/Service/Themes/MyTheme/index.jsx";
import TicketGettingStart from "../../Components/Tickets/AllTickets/TicketGettingStart/index.jsx";
import QuotationHelp from "../../Components/Tickets/AllTickets/QuotationHelp/index.jsx";
import PoliciesHelp from "../../Components/Tickets/AllTickets/PoliciesHelp/index.jsx";
import AdvisorRenewalPolicies from "../../Components/Advisor/AdvisorMyPolicy/AdvisorRenewalPolicies/index.jsx";
import BecomePartner from "../../Components/BecomePartner/index.jsx";
// import NewQuickQuote from "../../Components/QuickQuote/New.jsx";
import PageNotFound from "../../Components/NotFound/index.jsx";
import ReIssuePolicy from "../../Components/Advisor/ReIssuePolicy/index.jsx";
import AdminComparisonQuotes from "../../Components/Admin/ComparisonQuotes/index.jsx";





export const routes = [
  {
    path: "/home",
    element: <Home />,
    protected: false,
  },
  // {
  //   path: "/",
  //   element: <Home2 />,
  //   protected: false,
  // },
  {
    path: "/about-us",
    element: <Aboutus />,
    protected: false,
  },
  {
    path: "/quickquote",
    element: <QuickQuote />,
    protected: false,
  },
  {
    path: "/newcode",
    element: <NewCode />,
    protected: false,
  },
  {
    path: "/advisorLogin",
    element: <AdvisorLogin />,
    protected: false,
  },
  {
    path: "/advisorRegister",
    element: <AdvisorRegister />,
    protected: false,
  },
  {
    path: "/customerLogin",
    element: <CustomerLogin />,
    protected: false,
  },
  {
    path: "/customerRegister",
    element: <CustomerSignUp />,
    protected: false,
  },
  {
    path: "/customerProfile",
    element: <CustomerProfile />,
    protected: false,
  },

  {
    path: "/enquiry",
    element: <Enquiry />,
    protected: false,
  },
  {
    path: "/supervisa-getQuote",
    element: <GetQuote />,
    protected: false,
  },
  {
    path: "/supervisa-plan-compare",
    element: <PlanCompare />,
    protected: false,
  },
  {
    path: "/supervisa-policy-wording",
    element: <PolicyWording />,
    protected: false,
  },
  {
    path: "/supervisa-claim-procedure",
    element: <ClaimProcedure />,
    protected: false,
  },
  {
    path: "/supervisa-contact-us",
    element: <SuperVisaContactUs />,
    protected: false,
  },
  {
    path: "/visitor-getquote",
    element: <GetQuoteComp />,
    protected: false,
  },
  {
    path: "/visitor-plan-compare",
    element: <VisitorPlanCompare />,
    protected: false,
  },
  {
    path: "/visitor-policy-wording",
    element: <VisitorPolicyWording />,
    protected: false,
  },
  {
    path: "/visitor-claim-procedure",
    element: <VisitorClaimProcedure />,
    protected: false,
  },
  {
    path: "/visitor-contact-us",
    element: <VisitorContactUs />,
    protected: false,
  },
  {
    path: "/student-travel-getquote",
    element: <StudentGetQuoteComp />,
    protected: false,
  },
  {
    path: "/student-travel-plan-compare",
    element: <StudentPlanCompare />,
    protected: false,
  },
  {
    path: "/student-travel-policy-wording",
    element: <StudentPolicyWording />,
    protected: false,
  },
  {
    path: "/student-travel-claim-procedure",
    element: <StudentClaimProcedure />,
    protected: false,
  },
  {
    path: "/student-travel-contactus",
    element: <StudentTravelContactUs />,
    protected: false,
  },
  {
    path: "/terms_condition",
    element: <Terms />,
    protected: false,
  },
  {
    path: "/privacy-policy",
    element: <Privacy />,
    protected: false,
  },
  {
    path: "/customer-dashboard",
    element: <CustomerHomePage />,
    protected: false,
  },
  {
    path: "/get-quote-single",
    element: <TripDetails />,
    protected: false,
  },
  {
    path: "/my-quotes",
    element: <MyQuotation />,
    protected: false,
  },
  {
    path: "/customer-profile",
    element: <CustomerProfile />,
    protected: false,
  },
  {
    path: "/customer-policies",
    element: <CustomerPolicies />,
    protected: false,
  },
  {
    path: "/advisor-profile",
    element: <AdvisorProfile />,
    protected: false,
  },
  {
    path: "/advisor-unapproved-profile",
    element: <AdvisorUnapprovedProfile />,
    protected: false,
  },
  {
    path: "/advisor-quotes",
    element: <AdvisorMyQuote />,
    protected: false,
  },
  {
    path: "/advisor-policies",
    element: <AdvisorMyPolicy />,
    protected: false,
  },
  {
    path: "/advisor-commission",
    element: <AdvisorCommission />,
    protected: false,
  },
  {
    path: "/advisor-team",
    element: <AdvisorTeam />,
    protected: false,
  },
  {
    path: "/advisor-generate-quote",
    element: <AdvisorGenerateQuote />,
    protected: false,
  },
  {
    path: "/quick-quote",
    element: <NewCode />,
    protected: false,
  },
  {
    path: "/get-quick-quote",
    element: <GetQuickQuote />,
    protected: false,
  },
  {
    path: "/get-quick-quote-2",
    element: <GetQuickQuote />,
    protected: false,
  },
  {
    path: "/advisor-view-quote/:id",
    element: <AdvisorViewSingleQuote />,
    protected: false,
  },
  {
    path: "/customer-view-quote/:id",
    element: <CustomerSingleQuoteView />,
    protected: false,
  },
  {
    path: "/customer-view-quote/:id",
    element: <CustomerSingleQuoteView />,
    protected: false,
  },
  {
    path: "/customer-single-policy-view/:id",
    element: <CustomerSinglePolicyView />,
    protected: false,
  },
  {
    path: "/super-admin",
    element: <SuperAdminLogin />,
    protected: false,
  },
  {
    path: "/admin",
    element: <AdminLogin />,
    protected: false,
  },
  {
    path: "/super-admin-permission",
    element: <SuperAdminPermessionPanel/>,
    protected: false,
  },
  {
    path: "/super-admin-dashbaord",
    element: <SuperAdminDashbaord />,
    protected: false,
  },
  {
    path: "/admin-create",
    element: <CreateAdmin />,
    protected: false,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
    protected: false,
  },
  {
    path: "/admin/dashboard/pendingEndorsement",
    element: <AdminPendingEndorsement />,
    protected: false,
  },
  {
    path: "/admin/admin-profile",
    element: <AdminProfile />,
    protected: false,
  },
  {
    path: "/admin/admin-users",
    element: <AdminUsers />,
    protected: false,
  },
  {
    path: "/admin/customers",
    element: <Customers />,
    protected: false,
  },
  {
    path: "/admin/assign-aga",
    element: <AssignAga />,
    protected: false,
  },
  {
    path: "/admin/assign-aga/assing-aga-advisor/:id",
    element: <AssingAgaToAdvisor />,
    protected: false,
  },
  {
    path: "/admin/assign-mga/assing-mga-aga/:id",
    element: <AssingMgaToAga />,
    protected: false,
  },
  {
    path: "/admin/advisor-dashboard/:id",
    element: <AdminAdvisorDashboard />,
    protected: false,
  },
  {
    path: "/admin/advisor-policies/:id",
    element: <AdminAdvisorPolicies />,
    protected: false,
  },
  {
    path: "/admin/advisor-policy-single/:id",
    element: <AdminAdvisorPolicySingleView />,
    protected: false,
  },
  {
    path: "/admin/advisor-view-quote/:id",
    element: <AdminAdvisorSingleViewQuote />,
    protected: false,
  },
  {
    path: "/admin/policy-endorsement/:id",
    element: <AdminAdvisorPolicyEndorsement />,
    protected: false,
  },
  {
    path: "/admin/assign-mga",
    element: <AssignMga />,
    protected: false,
  },
  {
    path: "/admin/all-advisors",
    element: <AdminAllAdvisors />,
    protected: false,
  },
  {
    path: "/admin/products",
    element: <Products />,
    protected: false,
  },
  {
    path: "/admin/insurance-plans",
    element: <InsurancePlans />,
    protected: false,
  },
  {
    path: "/admin/view-table/:id",
    element: <ViewTable />,
    protected: false,
  },
  {
    path: "/admin/deductibles",
    element: <Deductibles />,
    protected: false,
  },
  {
    path: "/admin/deductibles/createDeductibles",
    element: <CreateDeductibles />,
    protected: false,
  },
  {
    path: "/admin/deductibles/updateDeductibles/:id",
    element: <UpdateDeductables />,
    protected: false,
  },
  {
    path: "/admin/deductibles",
    element: <InsurancePlans />,
    protected: false,
  },
  {
    path: "/admin/quotes",
    element: <Quotes />,
    protected: false,
  },
  {
    path: "/admin/policies",
    element: <Policies />,
    protected: false,
  },
  {
    path: "/admin/commission",
    element: <Commission />,
    protected: false,
  },
  {
    path: "/admin/commissionSettings",
    element: <CommisionSettings />,
    protected: false,
  },
  {
    path: "/admin/refundSettings",
    element: <RefundChargesSettings />,
    protected: false,
  },
  {
    path: "/admin/refund-requests",
    element: <RefundRequests />,
    protected: false,
  },
  {
    path: "/admin/guest-enquiry",
    element: <GuestEnquiry />,
    protected: false,
  },
  {
    path: "/admin/pages",
    element: <Pages />,
    protected: false,
  },
  {
    path: "/admin/reports",
    element: <Reports />,
    protected: false,
  },
  {
    path: "/admin/insuranceCompanyReports",
    element: <InsuranceCompanyReports />,
    protected: false,
  },
  {
    path: "/admin/logs",
    element: <Logs />,
    protected: false,
  },
  {
    path: "admin/mga/aga/advisor",
    element: <MgaAgaAdvisor />,
    protected: false,
  },
  {
    path: "admin/editAgaMgaAdvisor/:id",
    element: <EditMgaAgaAdvisor />,
    protected: false,
  },
  {
    path: "admin/all-policies",
    element: <AdminAllPolicies />,
    protected: false,
  },
  {
    path: "admin/montly-policies",
    element: <MonthlyPolicies />,
    protected: false,
  },
  {
    path: "admin/renewal-policies",
    element: <RenewalPolicies />,
    protected: false,
  },
  {
    path: "admin/claim-requests",
    element: <ClaimRequests />,
    protected: false,
  },
  {
    path: "admin/download-documents/:id",
    element: <PolicyDocs />,
    protected: false,
  },
  {
    path: "admin/download-quote-documents",
    element: <QutotesDocDownload />,
    protected: false,
  },
  {
    path: "admin/editQuote/:id",
    element: <AdminQuoteEdit />,
    protected: false,
  },
  {
    path: "admin/customer-renewal-policies/:id",
    element: <AdminCustomerRenewalPolicies />,
    protected: false,
  },
  {
    path: "admin/advisor-renewal-policies/:id",
    element: <AdminAdvisorRenewalPolicies />,
    protected: false,
  },
  {
    path: "admin/services",
    element: <AdminServices />,
    protected: false,
  },
  {
    path: "admin/white-labeled",
    element: <AdminWhiteLabel />,
    protected: false,
  },
  {
    path: "admin/affiliates",
    element: <AdminAffiliateUsers />,
    protected: false,
  },
  {
    path: "admin/partners",
    element: <AdminPartners />,
    protected: false,
  },
  {
    path: "admin/add-white-label-user",
    element: <AdminAddWhiteLabelUser />,
    protected: false,
  },
  {
    path: "admin/add-affiliate-user",
    element: <AdminAddAffiliateUser />,
    protected: false,
  },
  {
    path: "admin/generate-quote/:id",
    element: <AdminAdvisorGenerateQuote />,
    protected: false,
  },
  {
    path: "admin/quotePaymentTranscation/:id",
    element: <QuotePaymentTranscation />,
    protected: false,
  },
  {
    path: "admin/viewPolicyPaymentTranscation/:id",
    element: <ViewPolicyPaymentTranscations />,
    protected: false,
  },
  {
    path: "admin/viewDeclinedTranscationList/:id",
    element: <ViewDeclinedTranscationList />,
    protected: false,
  },
  {
    path: "admin/policyEndorsements/:id",
    element: <PolicyEndorsements />,
    protected: false,
  },
  {
    path: "policy-endorsement/:id",
    element: <AdvisorPolicyEndorsement />,
    protected: false,
  },
  {
    path: "single-policy-view/:id",
    element: <AdviosrSinglePolicyView />,
    protected: false,
  },
  {
    path: "advisor-policy-documents/:id",
    element: <AdvisorPolicyDocuments />,
    protected: false,
  },
  {
    path: "advisor-edit-quote/:id",
    element: <AdvisorEditQuote />,
    protected: false,
  },
  {
    path: "customer-forget-password",
    element: <CustomerForgetPassword />,
    protected: false,
  },
  {
    path: "advisor-forget-password",
    element: <AdvisorForgetPassword />,
    protected: false,
  },
  {
    path: "advisor-team",
    element: <AdvisorTeam />,
    protected: false,
  },
  {
    path: "customer-renewal-policies",
    element: <CustomerRenewalPolicies />,
    protected: false,
  },
  {
    path: "advisor-renewal-policies",
    element: <AdvisorRenewalPolicies />,
    protected: false,
  },
  {
    path: "customer-policy-endorsement/:id",
    element: <CustomerPolicyEndorsement />,
    protected: false,
  },
  {
    path: "customer-edit-quote/:id",
    element: <CustomerEditQuote />,
    protected: false,
  },
  {
    path: "customer-policy-documents/:id",
    element: <CustomerPolicyDocuments />,
    protected: false,
  },
  {
    path: "admin/assign-admin/:id",
    element: <AssignAdmin />,
    protected: false,
  },
  {
    path: "admin/customer-dashboard/:id",
    element: <AdminCustomerDashboard />,
    protected: false,
  },
  {
    path: "admin/customer-profile/:id",
    element: <AdminCustomerProfile />,
    protected: false,
  },
  {
    path: "admin/customer-policies/:id",
    element: <AdminCustomerPolicies/>,
    protected: false,
  },
  {
    path: "admin/customer-policy-single/:id",
    element: <AdminCustomerSinglePolicyView/>,
    protected: false,
  },
  {
    path: "admin/customer-policy-endorsement/:id",
    element: <AdminCustomerPolicyEndorsement/>,
    protected: false,
  },
  {
    path: "admin/customer-view-quote/:id",
    element: <AdminCustomerViewQuote/>,
    protected: false,
  },
  {
    path: "admin/edit-admin",
    element: <EditAdmin />,
    protected: false,
  },
  {
    path: "admin/travel-accounting-report",
    element: <TravelAccountingReport />,
    protected: false,
  },
  {
    path: "admin/account-payable-report",
    element: <AccountPayableReport />,
    protected: false,
  },
  {
    path: "admin/sales-and-tpa-report",
    element: <SalesAndTpaReport />,
    protected: false,
  },
  {
    path: "admin/commission-report",
    element: <UserCommissionReport />,
    protected: false,
  },
  {
    path: "admin/edit-product",
    element: <EditProduct />,
    protected: false,
  },
  {
    path: "admin/add-product",
    element: <AddProduct />,
    protected: false,
  },
  {
    path: "admin/subscription-due",
    element: <SubscriptionDue />,
    protected: false,
  },
  {
    path: "service/login",
    element: <ServiceUserLogin />,
    protected: false,
  },
  {
    path: "service/dashboard",
    element: <ServiceDashboard />,
    protected: false,
  },
  {
    path: "service/themes",
    element: <ServiceThemes />,
    protected: false,
  },
  {
    path: "service/add-theme/:id",
    element: <CreateTheme />,
    protected: false,
  },
  {
    path: "service/my-theme",
    element: <MyTheme />,
    protected: false,
  },
  {
    path: "service/support",
    element: <ServiceSupport />,
    protected: false,
  },
  {
    path: "support",
    element: <Tickets />,
    protected: false,
  },
  {
    path: "all-tickets",
    element: <AllTickets />,
    protected: false,
  },
  {
    path: "view-ticket/:id",
    element: <ViewSingleTicket />,
    protected: false,
  },
  {
    path: "admin/tickets",
    element: <AdminTickets />,
    protected: false,
  },
  {
    path: "admin/view-single-ticket/:id",
    element: <AdminViewSingleTicket />,
    protected: false,
  },
  {
    path: "ticket-getting-started",
    element: <TicketGettingStart />,
    protected: false,
  },
  {
    path: "ticket-quotation-help",
    element: <QuotationHelp />,
    protected: false,
  },
  {
    path: "ticket-policies-help",
    element: <PoliciesHelp />,
    protected: false,
  },
  {
    path: "join-us",
    element: <BecomePartner />,
    protected: false,
  },
  // {
  //   path: "new-quote",
  //   element: <NewQuickQuote />,
  //   protected: false,
  // },
  {
    path: "*",
    element: <PageNotFound />,
    protected: false,
  },
  {
    path: "re-issue-policy/:id",
    element: <ReIssuePolicy />,
    protected: false,
  },
  {
    path: "admin/comparision-quote",
    element: <AdminComparisonQuotes />,
    protected: false,
  }
];
; 