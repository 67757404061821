import React from 'react'
import { CheckCircleIcon } from "@chakra-ui/icons";
import {Flex} from '@chakra-ui/react'


const Description = () => {


return (
    <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              marginTop: "40px",
              alignItems: "flex-start",
              marginBottom: "40px",
            }}
          >
            <div style={{ display: "flex" }}>
              <Flex alignItems={'center'} pr={'15px'}>
              <CheckCircleIcon w={4} h={4} />
              </Flex>
              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Our Product Qualifies as per Government of Canada Guidelines for
                Min of CAD 100,000/-of Sum Insured for min duration of 365 days
                from Canadian Insurance Company.
              </p>
            </div>
            <div style={{ display: "flex" }}>
            <Flex alignItems={'center'} pr={'15px'}>
              <CheckCircleIcon w={4} h={4} />
              </Flex>              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                No Cancellation Penalty, Pay only for period of stay.
              </p>
            </div>
            <div style={{ display: "flex" }}>
            <Flex alignItems={'center'} pr={'15px'}>
              <CheckCircleIcon w={4} h={4} />
              </Flex>              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Age from 15 days to 90 Years & 3 Product Choices Standard,
                Enhanced and Premium.
              </p>
            </div>{" "}
            <div style={{ display: "flex" }}>
            <Flex alignItems={'center'} pr={'15px'}>
              <CheckCircleIcon w={4} h={4} />
              </Flex>              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Just 90 days stability condition up to age 74.
              </p>
            </div>{" "}
            <div style={{ display: "flex" }}>
            <Flex alignItems={'center'} pr={'15px'}>
              <CheckCircleIcon w={4} h={4} />
              </Flex>              <p
                style={{
                  marginLeft: "6px",
                  fontSize: "14px",
                  fontWeight: 500,
                  paddingBottom: "10px",
                  fontFamily: "poppins",
                }}
              >
                Covers you for any unforeseen Emergency Medical, Physician &
                Hospitalization expenses, Diagnostics, Prescriptions and Dental
                etc.
              </p>
            </div>
          </div>
  )
}

export default Description
