import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { serviceList } from './Dependences/action';
import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { FaArrowRight } from "react-icons/fa";
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../Utils/UniversalColor';
import { useTranslation } from 'react-i18next';
import Chatbot from '../../Utils/Chat/Chatbot';
import SideBox from '../../Utils/SideBox/SideBox';



function Home() {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.products);
  const {t}=useTranslation()

  useEffect(() => {
    // Initialize Google Translate widget
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };
}, []);

// Function to initialize Google Translate widget
window.googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
        {
            pageLanguage: 'en', // Default language
            includedLanguages: 'en,es,fr,hi,pa', // List of languages to include in the dropdown
            layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
            autoDisplay: false
        },
        'google_translate_element'
    );
};

  useEffect(() => {
    dispatch(serviceList());
  }, []);

  console.log('products', products);

  return (
    <Box>
        <Chatbot/>
        <SideBox/>
      <Flex pt={'50px'} pb={'80px'} backgroundImage={'https://static.pbcdn.in/cdn/images/home-v1/right-bg.png'} backgroundRepeat={'no-repeat'} backgroundPosition={'right'}>
        <Box textAlign={'left'} w={'40%'} m={'auto'} pt={'90px'} pb={'80px'}>
          <Text fontSize={'32px'} lineHeight={'45px'} fontFamily={'Montserrat'}>{t('Get the right protection to keep moving forward.')}</Text>
          <Text fontSize={'14px'} pt={'15px'} fontFamily={'poppins'}>{t('Simplifying the way you shop for insurance')}</Text>
          <Link to='/quick-quote'>
            <Flex>
              <Button boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;' h={'50px'} borderLeft={'2px solid green'} borderRight={'2px solid green'} borderRadius={'10px'} w={'30%'} mt={'30px'} color={primaryColor} backgroundColor={'#e3feff'}>{t('Get Quick Quote')}</Button>
            </Flex>
          </Link>
          <Flex mt={'50px'} w={'80%'}>
            <Flex w={'45%'} gap={'12px'}>
                <Image w={'40px'} src='https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740'/>
                <Text color={'#ff9b2c'} fontSize={'14px'} fontFamily={'poppins'}>{t('Quick, easy &')}<br />{t('hassle free')}</Text>
            </Flex>
            {/* </Link> */}
            <Flex>
                <Image w={'40px'} src='https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740'/>
                <Text color={'#776bfd'} fontSize={'14px'} fontFamily={'poppins'}>{t('support built in with')}<br /> {t('every policy for help')}</Text>
            </Flex>
          </Flex>
        </Box>
        {/* <Flex pt={'80px'} w={'45%'} h={'80vh'}>
          <Image zIndex={'9'} src={Hero}/>
        </Flex> */}
        <Box w={'45%'} m={'auto'} mt={'40px'}>
            <Flex justifyContent={'space-around'}>
                <div style={{ width: '40%' }}>
                <Link to='/supervisa-getQuote'>
                <Box cursor={'pointer'} backgroundColor={'white'} borderLeft={'2px solid #2d3cd9'} boxShadow='rgba(149,   157, 165, 0.2) 0px 8px 24px' p={'10px'} pt={'15px'} pb={'20px'} borderRadius={'7px'}>
                    <Image w={'50px'} src='https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740'/>
                    <Box fontFamily={'poppins'} pl={'7px'}>
                    <Text fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Super Visa')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('customers trust us & have bought their insurance on TravelMedicare')}</Text>
                    </Box>
                </Box>
                </Link>
                </div>
                <div style={{ width: '40%' }}>
                <Link to='/visitor-getquote'>
                <Box cursor={'pointer'} backgroundColor={'white'} borderLeft={'2px solid #2d3cd9'} boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px' p={'10px'} pt={'15px'} pb={'20px'} borderRadius={'7px'}>
                    <Image w={'40px'} pl={'5px'} src='https://img.freepik.com/free-vector/meeting-point-location-pin-design_25030-78591.jpg?t=st=1711019429~exp=1711023029~hmac=a46be01bea14599c1449e4f18a995999f8be3ca97e190f3f135fb53e11f8379e&w=740'/>
                    <Box fontFamily={'poppins'} pl={'7px'}>
                    <Text fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Visitors Insurance')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('customers trust us & have bought their insurance on TravelMedicare')}</Text>
                    </Box>
                </Box>
                </Link>
                </div>
            </Flex>
            <Flex pt={'50px'} justifyContent={'space-around'}>
                <Box cursor={'pointer'}  p={'10px'} pt={'15px'} w={'40%'} pb={'20px'} borderRadius={'7px'}>
                    {/* <Image w={'50px'} src='https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740'/>
                    <Box fontFamily={'poppins'} pl={'7px'}>
                    <Text fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>Super Visa</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>customers trust us & have bought their insurance on TravelMedicare</Text>
                    </Box> */}
                </Box>
                <Box cursor={'pointer'} borderLeft={'2px solid #2d3cd9'} w={'40%'} boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px' p={'10px'} pt={'15px'} pb={'20px'} borderRadius={'7px'}>
                <Link to='/student-travel-getquote'>
                    <Image w={'50px'} src='https://img.freepik.com/free-vector/academic-excellence-illustration_24877-52355.jpg?t=st=1711019454~exp=1711023054~hmac=6c7987360f9c7154270147fc984e9bc4655c5f3312fdda7ccd25235103e6cc2a&w=740'/>
                    <Box fontFamily={'poppins'} pl={'7px'}>
                    <Text fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Student Insurance')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('customers trust us & have bought their insurance on TravelMedicare')}</Text>
                    </Box>
                </Link>
                </Box>
            </Flex>
        </Box>
      </Flex>






<Box backgroundImage={'https://travelmedicare.com/public/users/images/slider/bg3.jpg'} backgroundSize={'cover'}>
    <Box background={'whitesmoke'}>
        <Box w={'49%'} m={'auto'} pt={'50px'} textAlign={'center'} lineHeight={'28px'}>
        <Heading fontSize={'32px'} fontFamily={'Montserrat'}>{t('Our Insurance Plans')}</Heading>
        <Text pt={'15px'} fontSize={'16px'} fontFamily={'poppins'}>{t('Explore Our Range of Insurance Policies for Peace of Mind')}</Text>
        </Box>
    <Box pb={'100px'} w={'80%'} m={'auto'} mt={'60px'} >
        <Flex justifyContent={'space-around'}>
            <Box borderRadius={'20px'} backgroundColor={'white'} w={'30%'}>
                <Box>
                    <Box boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;' borderTopRadius={'20px'} p={'20px'} fontWeight={'600'} fontFamily={'poppins'} backgroundColor={'#e8e8e8'} fontSize={'20px'}>
                        <Text>{t('Visitors To Canada Insurance Plan')}</Text>
                    </Box>
                    <Box boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;' fontFamily={'poppins'} pl={'25px'} pt={'10px'}>
                    <ul>
                        <li style={{paddingTop:'10px'}}>{t('Min of CAD 100,000 of Sum Insured for min duration of 365 days.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('No Cancellation Penalty.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('Age from 15 days to 90 Years & 3 Product')}</li>  
                        <li style={{paddingTop:'7px'}}>{t("Choices Standard, Enhanced and Premium.")}</li>
                    </ul>
                    <Flex justifyContent={'center'} mt={'15px'}>
                    <Button  mb={'15px'} backgroundColor={buttonBackgroundColor} color={buttonColor}>{t('Buy Now')}</Button>
                    </Flex>
                    </Box>
                </Box>
            </Box>
            <Box borderRadius={'20px'} backgroundColor={'white'} w={'30%'}>
                <Box>
                    <Box boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;' borderTopRadius={'20px'} p={'20px'} fontWeight={'600'} fontFamily={'poppins'} backgroundColor={'#e8e8e8'} fontSize={'20px'}>
                        <Text>{t('Student Medical Insurance Plan')}</Text>
                    </Box>
                    <Box boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;' fontFamily={'poppins'} pl={'25px'} pt={'10px'}>
                    <ul>
                        <li style={{paddingTop:'10px'}}>{t('Min of CAD 100,000 of Sum Insured for min duration of 365 days.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('No Cancellation Penalty.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('Age from 15 days to 90 Years & 3 Product')}</li>  
                        <li style={{paddingTop:'7px'}}>{t("Choices Standard, Enhanced and Premium.")}</li>
                    </ul>
                    <Flex justifyContent={'center'} mt={'15px'}>
                    <Button  mb={'15px'} backgroundColor={buttonBackgroundColor} color={buttonColor}>{t('Buy Now')}</Button>
                    </Flex>
                    </Box>
                    
                </Box>
            </Box>
            <Box borderRadius={'20px'} backgroundColor={'white'} w={'30%'}>
                <Box>
                    <Box boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;' borderTopRadius={'20px'} p={'20px'} fontWeight={'600'} fontFamily={'poppins'} backgroundColor={'#e8e8e8'} fontSize={'20px'}>
                        <Text>{t('Super Visa')}</Text>
                        <Text>{t('Insurance Plan')}</Text>
                    </Box>
                    <Box boxShadow='rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;' fontFamily={'poppins'} pl={'25px'} pt={'10px'}>
                    <ul>
                        <li style={{paddingTop:'10px'}}>{t('Min of CAD 100,000 of Sum Insured for min duration of 365 days.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('No Cancellation Penalty.')}</li>
                        <li style={{paddingTop:'7px'}}>{t('Age from 15 days to 90 Years & 3 Product')}</li>  
                        <li style={{paddingTop:'7px'}}>{t("Choices Standard, Enhanced and Premium.")}</li>
                    </ul>
                    <Flex justifyContent={'center'} mt={'15px'}>
                    <Button  mb={'15px'} backgroundColor={buttonBackgroundColor} color={buttonColor}>{t('Buy Now')}</Button>
                    </Flex>
                    </Box>
                    
                </Box>
            </Box>
            {/* <Box borderRadius={'20px'} backgroundColor={'white'} w={'30%'}>
                <Box>
                    <Box borderTopRadius={'20px'} p={'20px'} fontWeight={'600'} fontFamily={'poppins'} backgroundColor={'#e8e8e8'} fontSize={'20px'}>
                        <Text>{t('Student Medical Insurance Plan')}</Text>
                    </Box>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Standard')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} justifyContent={'center'} alignItems={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/dependents.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Enhanced')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} alignItems={'center'} justifyContent={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Premium')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} alignItems={'center'} justifyContent={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                </Box>
            </Box>
            <Box borderRadius={'20px'} backgroundColor={'white'} w={'30%'}>
                <Box>
                    <Box borderTopRadius={'20px'} p={'20px'} fontWeight={'600'} fontFamily={'poppins'} backgroundColor={'#e8e8e8'} fontSize={'20px'}>
                        <Text>{t('Super Visa Insurance Plan')}</Text>
                    </Box>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Standard')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} justifyContent={'center'} alignItems={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/dependents.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Enhanced')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} alignItems={'center'} justifyContent={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                    <Flex fontFamily={'Assistant,sans-serif'} justifyContent={'space-around'} p={'20px'}>
                        <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                        <Text fontSize={'20px'}>{t('Premium')}</Text>
                        <Flex fontSize={'20px'} color={primaryColor} alignItems={'center'} justifyContent={'center'}>
                        <FaArrowRight />
                        </Flex>
                    </Flex>
                </Box>
            </Box> */}
        </Flex>
    </Box>
    </Box>
    </Box>


<Box>
     <Box w={'49%'} m={'auto'} pt={'50px'} textAlign={'center'} lineHeight={'28px'}>
      <Heading fontSize={'32px'} fontFamily={'Montserrat'}>{t('Premiums for All')}</Heading>
      <Text pt={'15px'} fontSize={'16px'} fontFamily={'poppins'}>{t('Our Policies Are Based On')}</Text>
      </Box>
<Flex w={'80%'} m={'auto'} pt={'60px'} pb={'30px'} justifyContent={'space-around'}>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://www.freeiconspng.com/uploads/leadership-training-icon-1.png'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('The Plan Type')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Enhanced')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Premium')} = $25000, $50000, $100000</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/free-vector/umbrella-with-circle-check-mark_78370-4180.jpg?t=st=1711446036~exp=1711449636~hmac=d4d0e7ed926b69d9c717bd77d27ca87964b03a2ccac6827236758cf1c0dc9a25&w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Coverage')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
      <Text pt={'5px'}>{t('Hospitalization, Physician Charges,')}</Text>
      <Text pt={'5px'}>{t('Diagnostic Services, Prescriptions')}</Text>
      <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://www.shutterstock.com/image-vector/age-vector-icon-600nw-304741742.jpg'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Age Limits')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Enhanced')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Premium')} = Less then 70 Years of age</Text>
      </Box>
  </Box>
</Flex>

<Flex w={'80%'} m={'auto'} pt={'10px'} pb={'100px'} justifyContent={'space-around'}>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/premium-photo/photo-icons-alarm-clock-icon-green-technology-texture_873925-783472.jpg?w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Duration & waiting period')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Enhanced')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Premium')} = $25000, $50000, $100000</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/free-vector/check-mark-shield-circle_78370-4405.jpg?t=st=1711446282~exp=1711449882~hmac=75b7dff6a5abf3f247e6002c5658120fcc2831716551fb1b34a5ce33d8c60c6d&w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Pre-Existing Coverage')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
      <Text pt={'5px'}>{t('Hospitalization, Physician Charges,')}</Text>
      <Text pt={'5px'}>{t('Diagnostic Services, Prescriptions')}</Text>
      <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/premium-vector/av-green-letters-black-round-av-brand-name-initial-letter-icon_302321-318.jpg?w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>Exclusions</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Enhanced')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Premium')} = Less then 70 Years of age</Text>
      </Box>
  </Box>
</Flex>
</Box>
    </Box>
  );
}

export default Home;