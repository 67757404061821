import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import SideBox from "../../Utils/SideBox/SideBox";
import { primaryBackgroundColor } from "../../Utils/UniversalColor";

const SuperVisa = () => {
  return (
    <>
    <SideBox/>
    <Box style={{ backgroundColor: primaryBackgroundColor, paddingTop: "70px" }}>
      <Box fontSize={'12px'} w={['95%','95%','70%']} p={["0px 10px 10px 5px","0px 10px 15px 10px","0px 10px 15px 10px"]} mt={'8px'}
        style={{
          margin: "auto",
          marginTop: "7px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Flex
          style={{
            gap: "10px",
            justifyContent: "space-between",
            fontFamily: "poppins",
          }}
        >
          <Link to="/supervisa-getquote">
            <Box fontWeight={[400,400,600]} borderRadius={['10px','10px','50px']} p={["5px 5px","5px 10px","5px 10px"]} fontSize={['8px','8px','12px']} background={'white'} border={'2px solid #FBE39B'} color={'rgb(8, 129, 131)'} >
              Get Quote
            </Box>
          </Link>
          <Link to="/supervisa-plan-compare">
            <Box  fontWeight={[400,400,600]} borderRadius={['10px','10px','50px']} p={["5px 5px","5px 10px","5px 10px"]} fontSize={['8px','8px','12px']} background={'white'} border={'2px solid #FBE39B'} color={'rgb(8, 129, 131)'} >
              Plan Comparison
            </Box>
          </Link>
          <Link to="/supervisa-policy-wording">
            <Box  fontWeight={[400,400,600]} borderRadius={['10px','10px','50px']} p={["5px 5px","5px 10px","5px 10px"]} fontSize={['8px','8px','12px']} background={'white'} border={'2px solid #FBE39B'} color={'rgb(8, 129, 131)'} >
              Policy Wording
            </Box>
          </Link>
          <Link to="/supervisa-claim-procedure">
            <Box  fontWeight={[400,400,600]} borderRadius={['10px','10px','50px']} p={["5px 5px","5px 10px","5px 10px"]} fontSize={['8px','8px','12px']} background={'white'} border={'2px solid #FBE39B'} color={'rgb(8, 129, 131)'} >
              Claim Procedure
            </Box>
          </Link>
          <Link to='/supervisa-contact-us'>
          <Box fontWeight={[400,400,600]} borderRadius={['10px','10px','50px']} p={["5px 5px","5px 10px","5px 10px"]} fontSize={['8px','8px','12px']} background={'white'} border={'2px solid #FBE39B'} color={'rgb(8, 129, 131)'} >
            Contact us
          </Box>
            </Link>
        </Flex>
      </Box>
    </Box>
    </>
  );
};

export default SuperVisa;
