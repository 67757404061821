import React, { useEffect, useState } from 'react';
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels, Flex, Text, Button,Input,Select,TableContainer,Table,Thead,Tr,Td,Th,Tbody,Radio,Stack,useToast, Tooltip, Textarea} from '@chakra-ui/react';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import { IoMdCloseCircle } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import '../AdvisorQuote/styles.css'
import RadioOption from '../../../Utils/RadioCard';
import { BeneficiaryDetailsModal, VTCDetailsModal, getMaxSelectableDate, validateEmail, validateMobileNumber} from '../AdvisorQuote/Validation';
import { useDispatch } from 'react-redux';
import {advisorCalculateVtc, advisorGenerateQuote} from '../Dependencies/action'
import Api from '../../../dependencies/utils/Api';
import { useNavigate, useParams } from 'react-router-dom';
import { ADVISOR_SAVE_POLICY, API_URL, GET_QUOTE_BY_QUOTATION_NO } from '../../../constants/ApplicationUrl';
import { saveAs } from 'file-saver';



const ReIssuePolicy = () => {
  const [policyLimit, setPolicyLimit] = useState('');
  console.log(policyLimit)
  const [studentPolicyLimit, setStudentPolicyLimit] = useState('');
  const [vtcPlanType,setVtcPlanType]=useState('')
  const [studentPlanType,setStudentPlanType]=useState('')
  const [dedecutibles,setDeductibles]=useState ('')
  const toast=useToast()
  const [waitingPeriod, setWaitingPeriod] = useState("");
  const [studentWaitingPeriod, setStudentWaitingPeriod] = useState("");
  const [duration,setDuration]=useState('')
  const [superVisa,setSuperVisa]=useState(false)
  const [familyCoverage,setFamilyCoverage]=useState(false)
  const [policyType, setPolicyType] = useState('VTC');
  const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [vtcData,setVtcData]=useState([])
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState(0)
  const dispatch=useDispatch()
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [message,setMessage]=useState('')
  const [amount,setAmount]=useState(0)
  const [tabIndex, setTabIndex] = useState(0)
  const navigate=useNavigate()
  const params = useParams()
  const {id}=params
  const { token} = JSON.parse(localStorage.getItem("code_dev"));
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: " ",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);

  const [travelerDob,setTravelerDob]=useState([
    {
      name: "",
      gender: "",
      dob: " ",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
    },
  ])

  const [formData, setFormData] = useState({
    firstDate: '',
    lastDate: '',
    clientCountry: 'canada',
    clientProvince: '',
    vtcDepartureDate: "",
    studentDepartureDate: "",
  });

  const [policyHolder, setPolicyHolder] = useState({
    policyOwnerName: '',
    policyOwnerDob: '',
    policyOwnerEmail: '',
    policyOwnerAddress: '',
    policyOwnerPostalCode: '',
    policyOwnerCity: '',
    policyOwnerPhone: ''
  });

  const [errors, setErrors] = useState({
    firstDate: "",
    lastDate: "",
    vtcDepartureDate: "",
    policyOwnerEmail:'',
    policyOwnerAddress:'',
    policyOwnerPostalCode:'',
    policyOwnerCity:'',
    policyOwnerPhone:'',
    cardHolderFirstName: '',
    cardHolderLastName: '',
    cardHolderEmail: '',
    cardHolderPostal: '',
    cardHolderPhone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: ''
  });


  useEffect(()=>{
    getData()
},[])

const getData =()=>{
    Api.GET(`${GET_QUOTE_BY_QUOTATION_NO}/${id}`).then((res)=>{
        console.log(res,'res')
        const insuredData = res.data.listOfInsured.map(insured => ({
          name: insured.insuredName || "",
          gender: insured.insuredGender || "",
          dob: insured.insuredDob || "",
          relation: insured.beneficiaryRelationToInsured || "",
          benificiaryDob: insured.beneficiaryDob || "",
          benificiaryName: insured.beneficiaryName || "",
          selectedKey: insured.selectedKey || '',
          idInsured: insured.idInsured || ''
        }));
        const updatedFormData={
          firstDate:'',
          lastDate:'',
          clientProvince: res.data.policyHolderProvince,
          vtcDepartureDate: "",
          studentDepartureDate: "",
        }
        const updatedPolicyHolderData={
          policyOwnerName: res.data.policyHolderName,
          policyOwnerDob:res.data.policyHolderDob,
          policyOwnerEmail:res.data.policyHolderEmail,
          policyOwnerAddress: res.data.policyHolderAddress,
          policyOwnerPostalCode:res.data.policyHolderPostalCode,
          policyOwnerCity: res.data.policyHolderCity,
          policyOwnerPhone: res.data.policyHolderPhone
        }
        setPolicyHolder(updatedPolicyHolderData)
        setFormData(updatedFormData)
        setDuration(res.data.duration)
        setWaitingPeriod(res.data.waitingPeriod)
        setAddTravelers(insuredData);
        // setQuoteId(res.data.idQuote)
        setStudentPolicyLimit(`${res.data.policyLimit}`)
        setPolicyLimit(`${res.data.policyLimit}`)
        setStudentPlanType(`${res.data.listOfInsured[0].insuredPlan}`)
        setVtcPlanType(`${res.data.listOfInsured[0].insuredPlan}`)
        setDeductibles(`${res.data.deductible}`)
        setVtcData(res.data.listOfInsured)
        setSuperVisa(res.data.superVisa===1?true:false)
        // setCostsData(res.data.listOfInsured)
        setPolicyType(res.data.product)
    })
}

  const validateInput = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'cardHolderFirstName':
      case 'cardHolderLastName':
        if (!value.trim()) errorMessage = `${name} is required`;
        break;
      case 'cardHolderEmail':
        if (!value.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) errorMessage = 'Valid email is required';
        break;
      case 'cardHolderPostal':
        if (!value.trim() || !/^\d{5}$/.test(value)) errorMessage = 'Valid postal code is required';
        break;
      case 'cardHolderPhone':
        if (!value.trim() || !/^\d{10}$/.test(value)) errorMessage = 'Valid phone number is required';
        break;
      case 'cardNumber':
        if (name === 'cardNumber' && /^\d{16}$/.test(value)) {
          errorMessage = ''; // No error message for valid card number
        }
        break;
      case 'expiryDate':
        if (!value.trim()) errorMessage = 'Expiry date is required';
        break;
      case 'cvv':
        if (!value.trim() || !/^\d{3,4}$/.test(value)) errorMessage = 'Valid CVV is required';
        break;
      case 'amount':
        if (!value.trim() || isNaN(value)) errorMessage = 'Valid amount is required';
        break;
      default:
        break;
    }
    setErrors(prevErrors => ({ ...prevErrors, [name]: errorMessage }))
  };

  const validateAllFields = () => {
    let isValid = true;
    const requiredFields = ["cardHolderFirstName", "cardHolderLastName", "cardHolderEmail", "cardHolderPostal", "cardHolderPhone", "cardNumber", "expiryDate", "cvv", "amount"];
    requiredFields.forEach(field => {
      if (cardDetails[field].trim() === '') {
        setErrors(prevErrors => ({ ...prevErrors, [field]: `${field} is required` }));
        isValid = false;
      }
    });
    return isValid;
  };

  const handleCostOptionChange = (index, value, ele) => {
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [index]: value
    }));
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
    const totalValue = totalSelectedValue + value;
    const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
    const selectedKey = (value === ele.costWithPreExisting) ? '2' : '3';
    const message = selectedOption === 'withPreExisting' ? vtcData.messageOfPreExisting : vtcData.messageOfWithoutPreExisting;
    setMessage(message);
    setAmount(totalValue);
    setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
      if (idx === index) {
        return { ...traveler, selectedKey };
      }
      return traveler;
    }));
  };
  

  const handlePolicyOwnerChange = (e) => {
    const { name, value } = e.target;
    setPolicyHolder({ ...policyHolder, [name]: value });
    // setErrors({ ...errors, [e.target.name]: " " });
    const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
    if (selectedPolicyHolder) {
      setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
    }
  };

  useEffect(() => {
    if (!formData.vtcDepartureDate) {
      setWaitingPeriod("NA");
    } else {
      const today = new Date(formData.firstDate);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      const selectedDate = new Date(formData.vtcDepartureDate);
      const firstDate = new Date(formData.firstDate)
      const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      if (selectedDate>=firstDate) {
        setWaitingPeriod("NA");
      } else {
        setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      }
    }
    if (!formData.studentDepartureDate) {
      setStudentWaitingPeriod("NA");
    } else {
      const today = new Date(formData.firstDate);
      const selectedDate = new Date(formData.studentDepartureDate);
      if (selectedDate >= today) {
        setStudentWaitingPeriod("NA");
      } else {
        setStudentWaitingPeriod("5 days");
      }
    }
  }, [formData.vtcDepartureDate,formData.studentDepartureDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "firstDate" || name === "lastDate") {
      setSuperVisa(false);
    }
    setFormData({ ...formData, [name]: value });
    if (name === "lastDate") {
      if (!formData.firstDate) {
        alert("Please select the first date of cover first");
        setFormData({ ...formData, lastDate: "" });
        setDuration("");
        return;
      }
      const startDate = new Date(formData.firstDate);
      const endDate = new Date(value);
      const diffInMilliseconds = endDate - startDate;
      const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
  
      if (endDate < startDate) {
        alert("Last date cannot be earlier than the first date");
        setFormData({ ...formData, lastDate: "" });
        setDuration("");
      } else if (diffInMilliseconds > oneYearInMilliseconds) {
        alert("Duration cannot exceed 1 year");
        setFormData({ ...formData, lastDate: "" });
        setDuration("");
      } else {
        const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)+1);
        setDuration(durationInDays);
      }
    }
  
    if (name === "vtcDepartureDate") {
      if (!formData.firstDate) {
        alert("Please select the first date of cover");
        setFormData({ ...formData, vtcDepartureDate: "" });
        return;
    }
    }

    if (name === "studentDepartureDate") {
      if (!formData.firstDate) {
        alert("Please select the first date of cover");
        setFormData({ ...formData, studentDepartureDate: "" });
        return;
    }
    }
  };

  const handleSuperVisaChange = (e) => {
    if (!formData.firstDate) {
      alert("Please select the first date of cover first");
      return;
    }
      const { checked } = e.target;
      if (checked) {
        setPolicyType("Supervisa");
      } else {
        setPolicyType("VTC");
      }
      setSuperVisa(checked);
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear() + 1);
        endDate.setDate(startDate.getDate() - 1);
        setFormData({ ...formData, lastDate: endDate.toISOString().split('T')[0] }); 
        setDuration('365')
  };
  
  const handleFamilyCoverageChange = (e) => {
    if(addTravelers.length<3){
      alert('Select Atleast 3 member of family')
      return;
    }
    const isAnyDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.name === "" ||
        traveler.gender === "" ||
        traveler.dob === "" ||
        traveler.relation === "" ||
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === ""
    );
  
    // if (isAnyDetailEmpty) {
    //   alert("Please fill in all family member details before selecting family coverage.");
    //   return;
    // }
    const isAnyAgeBetween27to69 = addTravelers.some((traveler) => {
      const dob = new Date(traveler.dob);
      const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
      return age >= 27 && age <= 69;
    });
  
    if (!isAnyAgeBetween27to69) {
      alert("At least one family member's age should be between 27 to 69 years for family coverage.");
      return;
    }
    const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
      const dob = new Date(traveler.dob);
      const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
      return age >= 1;
    });
  
    if (!isAllAgeGreaterThanOne) {
      alert("family member must be greater than one year");
      return;
    }
    const { checked } = e.target;
    setFamilyCoverage(checked);
  }

  const handlePolicyLimitChange = (value) => {
      setPolicyLimit(value);
  };

  const handleStudentPolicyLimitChange = (value) => {
    setStudentPolicyLimit(value);
  };

  const handleStudentPolicyOption = (e) => {
    const { value } = e.target;
    setStudentPolicyOption(value);
    setPolicyType("Studentvisa");
  };

  const handlePlanTypeChange = (value) => { 
    setVtcPlanType(value);
  };

  const handleStudentPlanTypeChange = (value) => { 
    setStudentPlanType(value);
  };
  const handleDeductiblesChange = (value) => { 
    setDeductibles(value);
  };

  const handleAddTraveller = () => {
    if (addTravelers.length < 3) {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
    } else {
      console.log('Not more then three members')
    }
  };

  const handleAddTravellerDob = () => {
    handleAddTraveller()
    if (travelerDob.length < 3) {
      setTravelerDob([
        ...travelerDob,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
    } else {
      toast({ title: "you can not add more than 3 members." });
    }
  };

  const handleChangeTraveler = (index, e) => {
    const { name, value } = e.target;
    if (name === "dob" || name === "benificiaryDob") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
    setTravelerDob((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      return updatedState;
  });
  };

  const handleRemoveTraveller = (index) => {
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };

  const handleRemoveTravellerDob = (index) => {
    handleRemoveTraveller()
    const updatedTravelers = [...travelerDob];
    updatedTravelers.splice(index, 1);
    setTravelerDob(updatedTravelers);
  };

  const validateFormData = (formData, vtcPlanType, addTravelers) => {
    if (!formData.firstDate) {
      return "First date of cover must be provided.";
    }
    if (!formData.lastDate) {
      return "Last date of cover must be provided.";
    }
    return null;
  };

  const validateFormDatas = (formData, vtcPlanType, addTravelers) => {
    if (!formData.firstDate) {
      return "First date of cover must be provided.";
    }
    if (!formData.lastDate) {
      return "Last date of cover must be provided.";
    }
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      return "Please fill in all beneficiary details.";
    }
    return null;
  };

  const handleVtcCalculate = async () => {
    for (const traveler of addTravelers) {
      if (!traveler.dob || traveler.dob.trim() === "") {
        alert("Please enter DOB for all travelers");
        return;
      }
    }
    if(policyLimit.length===0){
      alert("Please choose policy limit")
      return;
    }
    if(!vtcPlanType){
      alert("Please choose plan type")
      return;
    }
    if(!dedecutibles){
      alert("Please choose Dedecutibles")
      return;
    }
    if(!formData.vtcDepartureDate){
      alert("Please choose date of Departure")
      return;
    }
    if(superVisa){
      setPolicyType('Supervisa')
    }else{
      setPolicyType('VTC')
    }
    const validationError = validateFormData(formData, vtcPlanType, addTravelers);
    if (validationError) {
      alert(validationError);
      return;
    }
    setFormData(prevFormData => ({
      ...prevFormData,
        studentDepartureDate: ''        
    }));
    setStudentPolicyLimit('')
    setStudentPlanType('')
    setIsVtcModalOpen(true);
  };

  const handleStudentCalculate = async () =>{
    setPolicyType('Studentvisa')
    if (!formData.firstDate) {
      alert('First date of cover must be provided.')
      return
    }
    if (!formData.lastDate) {
      alert('Last date of cover must be provided.')
      return
    }
    if (!formData.studentDepartureDate) {
      alert('Departure date must be provided.')
      return
    }
    if (!studentPlanType) {
      alert('Plan Type must be provided.')
      return
    }
    if (!studentPolicyLimit) {
      alert('Policy Limit must be provided.')
      return
    }
    setFormData(prevFormData => ({
      ...prevFormData,
        vtcDepartureDate: ''        
    }));
    setPolicyLimit('')
    setDeductibles('')
    setVtcPlanType('')
    // const isAnyBeneficiaryDetailEmpty = addTravelers.some(
    //   (traveler) =>
    //     traveler.benificiaryName === "" ||
    //     traveler.benificiaryDob === "" ||
    //     traveler.relation === ""
    // );
    // if (isAnyBeneficiaryDetailEmpty) {
    //   alert('Please fill in all beneficiary details.')
    //   return
    // }
    setIsStudentModalOpen(true);
  }

  const handleStudentModalSubmit = () => {
    setIsStudentModalOpen(false); 
      const updatedTravelers = addTravelers.map((traveler) => ({
        insuredName: traveler.name,
        insuredGender: traveler.gender,
        insuredDOB: traveler.dob,
        insuredRelation: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDob: traveler.benificiaryDob,
      }));
    const dataToLog = {
        firstDateOfCover: formData.firstDate ,
        lastDateOfCover: formData.lastDate,
        planType:studentPlanType,
        insuredDetailsRequests: updatedTravelers,
        policyType:'Studentvisa',
        tripType:studentPolicyOption
    };
    console.log(dataToLog,"Vtc form submit data")
    dispatch(advisorCalculateVtc(dataToLog)).then(res => {
      const {status, data} = res
      if(status===200){
        updateAddTravelers(data.listOfInsuredQutation)
      }
      console.log(data,'data')
      setVtcData(data)
    })
  };

  const handleVtcModalSubmit = async () => {
    setIsVtcModalOpen(false); 
        const updatedTravelers = travelerDob.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          firstDateOfCover: formData.firstDate,
          lastDateOfCover: formData.lastDate,
          deductible:dedecutibles,
          policyLimit:parseInt(policyLimit),
          planType:vtcPlanType,
          vtcDepartureDate: formData.vtcDepartureDate,
          supervisa: superVisa,
          insuredDetailsRequests: updatedTravelers,
          policyType:policyType,
          familyPolicy:familyCoverage,
          paymentFrequency:superVisa?parseInt(policyTypeSuperVisa):0,
          tripType:studentPolicyOption
      };
      console.log(dataToLog,"Vtc form submit data")
      dispatch(advisorCalculateVtc(dataToLog)).then(res => {
        const {status, data} = res
        if(status===200){
          updateAddTravelers(data.listOfInsuredQutation)
        }
        console.log(data,'data')
        setVtcData(data)
      })
  };

  const updateAddTravelers = (response) => {
    setAddTravelers(prevaddTravelers => {
      return prevaddTravelers.map(traveler => {
        const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
        if (responseTraveler) {
          return {
            ...traveler,
            costWithPreExisting: responseTraveler.costWithPreExisting,
            costWithoutPreExisting: responseTraveler.costWithoutPreExisting
          };
        }
        return traveler;
      });
    });
  };
  
  const handleStudentModalClose = () => {
    setIsStudentModalOpen(false);
  };

  const handleVtcModalClose = () => {
    setIsVtcModalOpen(false);
  };

  const handleGetQuote = async () =>{
    const validationError = validateFormDatas(formData, vtcPlanType, addTravelers);
    if(!policyHolder.policyOwnerName){
      alert("Please seclet policy Owner")
      return;
    }
    if (validationError) {
      alert(validationError);
      return;
    }
    if(!formData.clientProvince){
      alert("Please choose province.")
      return;
    }
    const { policyOwnerAddress, policyOwnerEmail,policyOwnerCity,policyOwnerPhone,policyOwnerPostalCode} = policyHolder;
    const newErrors = { ...errors };
      if (policyOwnerAddress.length === 0) {
        alert('Please enter valid address')
        return;
      }
      if (policyOwnerEmail.length === 0 || !validateEmail(policyOwnerEmail)) {
        alert('Please enter a valid email');
        return;
      }
      if (policyOwnerCity.length === 0) {
        alert('Please enter valid City')
        return
      }
      if (policyOwnerPhone.length === 0 || !validateMobileNumber(policyOwnerPhone)) {
        alert('Please enter valid Phone');
        return;
      }
      if (policyOwnerPostalCode.length === 0) {
        alert('Please enter valid Postal Code')
        return
      }
      const updatedTravelers = addTravelers.map((traveler) => ({
        nameOfInsured: traveler.name,
        gender: traveler.gender,
        dateOfBirth: traveler.dob,
        beneficiaryRelationToInsured: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDOB: traveler.benificiaryDob,
        selectedKey:traveler.selectedKey,
        costWithPreExisting:traveler.costWithPreExisting,
        costWithoutPreExisting:traveler.costWithoutPreExisting,
      }));
      setErrors(newErrors);
      const dataToSend = {
        email: policyHolder.policyOwnerEmail,
        firstDateOfCover: formData.firstDate,
        lastDateOfCover:formData.lastDate,
        policyEndDate: formData.lastDate,
        duration:parseInt(duration),
        country:1,
        province:formData.clientProvince,
        departureFromCountryOfOrigin: policyType === 'Studentvisa' ? formData.studentDepartureDate : formData.vtcDepartureDate,
        waitingPeriod:waitingPeriod,
        familyCoverage:familyCoverage===true?1:0,
        policyLimit:policyType==='Studentvisa'?studentPolicyLimit:policyLimit,
        deductible:dedecutibles,
        paymentFrequency:policyTypeSuperVisa,
        policyOwner:policyHolder.policyOwnerName,
        policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
        policyOwnerEmail:policyHolder.policyOwnerEmail,
        policyOwnerAddress:policyHolder.policyOwnerAddress,
        policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
        policyOwnerCity:policyHolder.policyOwnerCity,
        policyOwnerPhone:policyHolder.policyOwnerPhone,
        planType:policyType==='Studentvisa'?studentPlanType:vtcPlanType,
        supervisa: superVisa,
        insuredTravellerDetailsRequest: updatedTravelers,
        policyType: policyType,
        amount: amount,
        tripType:studentPolicyOption
    };
    dispatch(advisorGenerateQuote(dataToSend)).then(res => {
      const {status, data} = res
      if(status===200 || status === 201){
        navigate(`/advisor-view-quote/${data.quotationNo}`)
        alert("Quote generated successfully")
      }
      console.log(data,'data')
    })

  }
  const handleTabsChange=()=>{
    if(!amount){
      alert("Please select costwithpre-existing or cost withoutpreexisting")
      return;
    }
    if(!formData.clientProvince){
      alert("Please choose province.")
      return;
    }
    setTabIndex(1)
  }
  const handlePreviousChange=()=>{
    setTabIndex(0)
  }

  const handleBenefitComparisonDownload = async () => {
    try {
      const response = await fetch(`${API_URL()}/file/downloadFileOfBenefitDocument`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      saveAs(blob, 'BenefitComparison.pdf'); 
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };


return (
    <div>
      <Box pt={['100px','60px','60px']} pb={'20px'} backgroundColor={'grey'}>
        <Tabs index={tabIndex} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' backgroundColor={'white'} isFitted variant='enclosed' w={['97%','80%','80%']} m={'auto'}>
          <TabList border={'2px solid grey'} borderTop={'none'}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              paddingTop: '5px',
              color: 'black',
              fontSize: '16px',
              fontWeight: '800',
              fontFamily: 'poppins',
            }}>
            <Tab>Quick Quote</Tab>
            <Tab>Details</Tab>
            <Tab>Confirm & Pay</Tab>
          </TabList>
          <TabPanels>
          <TabPanel>
             <Box>
                <Flex direction={['column','column','row']} justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={['5px','20px','20px']}>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>First Date of Cover</Text>
                          <Input name="firstDate" value={formData.firstDate} onChange={handleChange} size='sm' type='date' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} max="3000-12-31"/>
                        </Box>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Last Date of Cover</Text>
                          <Input name="lastDate" value={formData.lastDate} onChange={handleChange} size='sm' type='date' border={'1px solid #d7d7d7'} />
                        </Box>
                        <Tooltip hasArrow label='No. of Days policy will be active.' bg='gray.300' color='black' placement='top-start'>
                        <Box w={['auto','7%','7%']}>
                          <Text fontWeight={'700'}>Duration</Text>
                          <Input size='sm' backgroundColor={'#eaeaea'} value={`${duration} days`} border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        </Tooltip>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Client Country</Text>
                          <Input name="clientCountry" value={formData.clientCountry} placeholder='Canada' size='sm' border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        <Box w={['auto','20%','20%']}>
                          <Text fontWeight={'700'}>Province</Text>
                          <Select name="clientProvince" value={formData.clientProvince} onChange={handleChange} size={'sm'} border={'1px solid #d7d7d7'}>
                            <option value="">Select</option>
                            <option value="Alberta">Alberta</option>
                            <option value="British Columbia">British Columbia</option>
                            <option value="Manitoba">Manitoba</option>
                            <option value="New Brunswick">New Brunswick</option>
                            <option value="Newfoundland & Labrador">Newfoundland & Labrador</option>
                            <option value="Northwest Territories">Northwest Territories</option>
                            <option value="Nova Scotia">Nova Scotia</option>
                            <option value="Nunavut">Nunavut</option>
                            <option value="Ontario">Ontario</option>
                            <option value="Prince Edward Island">Prince Edward Island</option>
                            <option value="Quebec">Quebec</option>
                            <option value="Saskatchewan">Saskatchewan</option>
                            <option value="Yukon">Yukon</option>
                          </Select>
                        </Box>
                </Flex>
              <Flex direction={['column','column','row']} gap={'20px'} justifyContent={'start'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
              {travelerDob.map((item, index) => {
                  return (                
                  <Box pl={['2px','20px','20px']} mt={['3px','7px','7px']} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                      <Flex gap={['5px','20px','20px']}> 
                        <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                        <Box>
                          <Text fontWeight={'600'}>Date of Birth</Text>
                          <Input w={'230px'} type='date' background={'white'} size='sm' border={'1px solid #d7d7d7'}name="dob"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.dob} 
                          max={getMaxSelectableDate()}
                          />            
                        </Box>
                          </Tooltip>
                      </Flex>
                      {index > 0 && (
                              <Flex justifyContent={'end'}>
                                <IoMdCloseCircle
                                  style={{fontSize:'22px',color:buttonBackgroundColor}}
                                  onClick={() => handleRemoveTravellerDob(index)}
                                />
                              </Flex>
                            )}
                    </Box>
                    );
                  })}
                <Flex gap={'10px'} fontFamily={'poppins'} fontSize={'12px'} cursor={'pointer'} color={'teal'} mt={['2px','5px','10px']} mb={'10px'} alignItems={'center'} ml={['5px','20px','20px']}>
                <Flex>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTravellerDob}>Add Traveller</Text>
              </Flex>
                </Flex>
        <Tabs border={'1px solid grey'} background={'#f6f6f6'} pt={'10px'} variant='soft-rounded' mt={'10px'}>
          <TabList borderTop={'none'} ml={'40px'}>
            <Tab fontSize={'12px'}>VTC Product</Tab>
            <Tab fontSize={'12px'}>Student Travel</Tab>
          </TabList>
          <TabPanels>
            <TabPanel borderTop={'none'}>
              <Box>
                <Flex direction={['column','column','row']} gap={['10px','30px','60px']} fontSize={'13px'}>
                <Flex w={['auto','auto','20%']} direction={'column'} alignItems={'start'}>
                <Text fontSize={'12px'} textAlign={'left'}>Departure from country of origin</Text>
                <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'}
                 name="vtcDepartureDate"
                 onChange={handleChange}
                 value={formData.vtcDepartureDate}
                 max={formData.firstDate}
                 />       
                </Flex>
                <Box>
                <Text>Waiting Period</Text>
                <input
                  name="waiting"
                  value={waitingPeriod}
                  readOnly
                />
                  </Box>  
                <Flex direction={'column'} alignItems={['start','center','center']}>
                <Text>Family Coverage</Text>
                <input type='checkbox' size='md' name='1' colorScheme='green'
                 value={familyCoverage} onChange={handleFamilyCoverageChange} checked={familyCoverage}></input>
                </Flex>   
                <Flex direction={'column'} alignItems={['start','center','center']}>
                <Text>Super Visa</Text>
                <input type='checkbox' size='md' value={superVisa} onChange={handleSuperVisaChange} colorScheme='green' checked={formData.firstDate && superVisa} ></input>
                </Flex>
                {superVisa?(
                        <>
                      
                          <Box>
                          <Text mb={'5px'} fontSize={'12px'}>Student Policy Option</Text>
                            <Select size={'xs'} value={policyTypeSuperVisa} onChange={(event) =>setPolicyTypeSuperVisa(event.target.value)}>
                              <option value="1">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                          </Box>
                        </>
                      ):(<> <br /> </>)}
                </Flex>

                <Flex direction={['column','column','row']} gap={['10px','50px','90px']} mt={['5px','15px','15px']}>
                  <Box w={'100%'}>
                    <Text fontSize={['10px','12px','14px']} mt={['5px','15px','15px']} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={[2,2,4]} direction='row' w={'40%'}>
                      {superVisa ? (
                        <>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                        </>
                      ) : (
                      <Flex direction={['column','column','row']} gap={[2,2,4]}>
                        <Flex gap={[2,2,4]}>
                          <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                            $25000
                          </RadioOption>
                          <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                            $50000
                          </RadioOption>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                            $100000
                          </RadioOption>
                           </Flex>
                            <Flex gap={[2,2,4]}>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                            $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                            $300000
                          </RadioOption>
                          </Flex>
                       </Flex>
                      )}
                    </Stack>

                  </Box>
                  <Box>
                    <Text fontSize={['10px','12px','14px']} mt={['5px','15px','15px']} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={[2,2,4]} direction='row'>
                      <RadioOption value='Standard' isSelected={vtcPlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={vtcPlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={vtcPlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                </Flex>
                <Flex direction={['column','column','row']} gap={['10px','50px','90px']} mt={'25px'} mb={'25px'}>
                <Box>
                    <Text fontSize={['10px','12px','14px']} >Deductible</Text>
                    <Stack spacing={[2,2,4]}>
                    <Flex gap={[2,2,4]} direction={['column','column','row']}>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                       $0</RadioOption>
                      <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                       $100</RadioOption>
                      <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                       $250 </RadioOption>
                       </Flex>
                      <Flex gap={[2,2,4]}>
                      <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                      </Flex>
                      </Flex>
                    </Stack>
                  </Box>
                  <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
      <button style={{ backgroundColor: 'teal', color: 'white', padding: '5px', borderRadius: '10px', fontSize: '12px' }} onClick={handleVtcCalculate}>
        Calculate Premium
      </button>
      <Text fontSize={'12px'} color={'blue'} onClick={handleBenefitComparisonDownload} style={{ cursor: 'pointer' }}>
        See Benefit Comparison
      </Text>
    </Flex>
                
                </Flex>
              </Box>
            </TabPanel>
            <TabPanel borderTop={'none'}>
              <Box>
              <Flex direction={['column','column','row']}  gap={['10px','50px','60px']} fontSize={'13px'}>
                <Flex w={['auto','auto','20%']} direction={'column'} alignItems={['start','start','start']} gap={'5px'}>
                <Text fontSize={'12px'}>Departure from country of origin</Text>
                <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} 
                name="studentDepartureDate"
                onChange={handleChange}
                value={formData.studentDepartureDate} max={formData.firstDate}/>       
                </Flex>
                <Box>
                <Text textAlign={'left'}>Waiting Period</Text>
                <input
                  name="waiting"
                  value={studentWaitingPeriod}
                />                  
                </Box>  
                <Flex direction={'column'} alignItems={['start','start','center']}>
                <Text mb={'5px'}>Student Policy Option</Text>
                <Select size={'xs'} value={studentPolicyOption} onChange={handleStudentPolicyOption}>
                  <option value="Single Trip">International Student Policy -Single Trip</option>
                  <option value="Annual">International Student Policy - Annual</option>
                </Select>
                </Flex>   
              </Flex>
              <Flex direction={['column','column','row']}  gap={['10px','50px','90px']} mt={'25px'} mb={'25px'}>
              <Box>
                    <Text fontSize={'14px'} mb={'7px'}>Policy Limit</Text>
                    <Stack spacing={4} direction='row'>
                      <RadioOption value='2000000' isSelected={studentPolicyLimit === '2000000'} onChange={handleStudentPolicyLimitChange}>
                      $2000000</RadioOption>
                    </Stack>
                  </Box>
              <Box>
                  <Text fontSize={'14px'} >Select Plan Type</Text>
                  <Stack spacing={4} direction='row'>
                      <RadioOption value='Standard' isSelected={studentPlanType === 'Standard'} onChange={handleStudentPlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={studentPlanType === 'Enhanced'} onChange={handleStudentPlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={studentPlanType === 'Premium'} onChange={handleStudentPlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                </Box>
                <Flex alignItems={'center'} justifyContent={'space-between'} gap={'40px'} mt={'15px'}>
                    <button style={{backgroundColor:'teal',color:'white',padding:'5px',borderRadius:'10px',fontSize:'12px'}} onClick={handleStudentCalculate}>Calculate Premium</button>
                    <Text fontSize={'12px'} color={'blue'}>See Benefit Comparison</Text>
                </Flex>
              </Flex>
              </Box>
              </TabPanel>
            </TabPanels>
                </Tabs>
            <Flex direction={['column','column','row']} >
                <Box w={['95%','80%','50%']}>
              <TableContainer >
                <Table>
                    <Thead>
                        <Tr gap={'50px'}>
                            <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                            <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                            <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                            <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                      {vtcData?.listOfInsuredQutation?.map((ele, index) => (
                            <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                <Td p={'2px'}>{ele.name}</Td>
                                <Td p={'2px'}>{ele.planType}</Td>
                                <Td p={'2px'} display={'flex'}>
                                    <input
                                      type="radio"
                                      name={`costOption-${index}`}
                                      value={ele.costWithPreExisting}
                                      checked={selectedCostOption[index] === ele.costWithPreExisting}
                                      onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                                    />
                                    <Text pl={'10px'}>
                                      {Math.round(ele.costWithPreExisting * 100) / 100}
                                    </Text>
                                  </Td>
                                  <Td p={'2px'}>
                                    <Flex>
                                    <input
                                      type="radio"
                                      name={`costOption-${index}`}
                                      value={ele.costWithoutPreExisting}
                                      checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                                      onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                                      />
                                    <Text pl={'10px'}>
                                    {Math.round(ele.costWithoutPreExisting * 100) / 100}
                                    </Text>
                                      </Flex>
                                  </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
                  </TableContainer>
                  </Box>
                  <Box w={['95%','80%','50%']}>
                  <TableContainer>
                      <Table>
                          <Thead>
                              <Tr>
                                  <Th p={'7px'} fontSize={'10px'}>Period</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Net</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                                  <Th p={'7px'} fontSize={'10px'}>Total</Th>
                              </Tr>
                          </Thead>
                          <Tbody>
                                  <Tr fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                      <Td p={'2px'}>{`${formData.firstDate} - ${formData.lastDate}`}</Td>
                                      <Td p={'2px'}>{Math.round(amount * 100) / 100}</Td>
                                      <Td p={'2px'}>0.00 CAD	</Td>
                                      <Td p={'2px'} textAlign={'center'}>0.00 CAD	</Td>
                                      <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{Math.round(amount * 100) / 100}</Td>
                                  </Tr>
                          </Tbody>
                      </Table>
                  </TableContainer>
                  </Box>
              </Flex>
              <Flex justifyContent={'end'} mt={'15px'}>
              <Button background={'teal'} size={'sm'} _hover={{background:'teal'}} color={'white'} onClick={handleTabsChange}>Proceed</Button>
              </Flex>
             </Box>
            </TabPanel>
            <TabPanel border={'2px solid grey'} borderTop={'none'}>
              <Box w={'95%'} m={'auto'}>
              {addTravelers.map((item, index) => {
              return (                
              <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} key={index} id={`Insured-details-${index}`} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                  <Flex gap={['10px','20px','20px']} direction={['column','column','row']}>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Name Of Insured</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                       name="name"
                       value={item.name}
                       onChange={(e) => handleChangeTraveler(index, e)}
                      />            
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Gender</Text>
                      <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                       onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
                    </Box>
                    <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'600'}>Date of Birth</Text>
                      <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'}name="dob"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.dob} 
                      max={getMaxSelectableDate()}
                      readOnly
                      />            
                    </Box>
                      </Tooltip>
                  </Flex>
                  <Flex gap={['10px','20px','20px']} mt={'10px'} direction={['column','column','row']}>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Beneficiary Name</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                      name="benificiaryName"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.benificiaryName}/>            
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'700'}>Relation</Text>
                      <Input background={'white'} size='xs' border={'1px solid #d7d7d7'}
                      name="relation"
                      onChange={(e) => handleChangeTraveler(index, e)}
                      value={item.relation}
                      />            
                    </Box>  
                    <Box w={['auto','auto','25%']}>
                      <Text fontWeight={'600'}>Beneficiary DOB</Text>
                      <Input type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} onChange={(e) => handleChangeTraveler(index, e)}
                      name="benificiaryDob"
                      value={item.benificiaryDob}
                      max={getMaxSelectableDate()}/>            
                    </Box>
                  </Flex>
                </Box>
                );
              })}
                <hr />
                <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                <Flex gap={['10px','20px','40px']} direction={['column','column','row']} fontSize={'14px'} pb={['5px','20px','20px']} mt={'20px'}>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner*</Text>
                  <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                    <option value="">Select Policy Owner</option>
                    {(formData.firstDate && formData.lastDate) && (vtcPlanType || studentPlanType) ? addTravelers.map((traveler, index) => (
                      <option key={index} value={traveler.name}>
                        {traveler.name}
                      </option>
                    )) : null}
                  </Select>
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Date Of Birth*</Text>
                  <Input readOnly type='date' size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Email*</Text>
                  <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
                  <Text color="red.500">{errors.policyOwnerEmail}</Text>        
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Address*</Text>
                  <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
                  <Text color="red.500">{errors.policyOwnerAddress}</Text>       
                </Box>
              </Flex>
              <Flex gap={['10px','20px','40px']} direction={['column','column','row']} fontSize={'14px'} pb={'20px'} mt={['5px','20px','20px']}>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Postal Code*</Text>
                  <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
                  <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner City*</Text>
                  <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
                  <Text color="red.500">{errors.policyOwnerCity}</Text>         
                </Box>
                <Box w={['auto','auto','20%']}>
                  <Text>Policy Owner Phone*</Text>
                  <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
                  <Text color="red.500">{errors.policyOwnerPhone}</Text>
                </Box>
              </Flex>
                  <Flex gap={'30px'} justifyContent={'end'}>
                  <Button size={'sm'} _hover={{background:buttonBackgroundColor}} onClick={handlePreviousChange} color={buttonColor} backgroundColor={buttonBackgroundColor}>Previous</Button>
                    <Button size={'sm'} _hover={{background:buttonBackgroundColor}} onClick={handleGetQuote} color={buttonColor} backgroundColor={buttonBackgroundColor}>Get Quote</Button>
                  </Flex>
                  </Box>
            </TabPanel>
            <TabPanel>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <BeneficiaryDetailsModal isOpen={isStudentModalOpen} onClose={handleStudentModalClose} onSubmit={handleStudentModalSubmit} />
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleVtcModalSubmit} />
    </div>
  );
};

export default ReIssuePolicy;