import React, { useState } from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, FormControl, FormLabel, Image, Input, InputGroup, InputRightElement, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

const EditAdmin = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState([]);

   

    console.log("menu data", menu);
    const handleLogin = async () => {
      const { firstName, email, confirmPassword, password,phone ,address} = values;
      console.log(values);
      let newErrors = { ...errors };
      if (firstName.length === 0) {
          newErrors.username = "Please enter valid email";
      }
      if (phone.length === 0) {
          newErrors.phone = "Please enter valid email";
      }
      if (email.length === 0) {
          newErrors.email = "Please enter valid email";
      }

      if (password.length === 0) {
          newErrors.password = "Password do not match!";
      }
      if (confirmPassword.length === 0) {
          newErrors.confirmPassword = "Password do not match!";
      }
      if (selectedMenus.length === 0) {
          newErrors.menus = "Please select at least one menu";
      }

      
      
  };

    const [show, setShow] = useState(false);
    const [values, setValues] = useState({
        firstName: "",
        phone: "",
        email: "",
        address:"",
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        phone: "",
        email: "",
        address:"",
        password: "",
        confirmPassword: "",
    });

    const handleClick = () => setShow(!show);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
        if (checked) {
            setSelectedMenus([...selectedMenus, value]);
        } else {
            setSelectedMenus(selectedMenus.filter((menu) => menu !== value));
        }
    } else {
        setValues({ ...values, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
};
  const handleBack = () => {
    navigate(-1);
  }

  

  return (
    <Sidebar headingText={'Edit Admin'}>
      <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' w={'70%'} m={'auto'} background={'white'} mt={'50px'} padding={'20px'}>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{ color: 'white' }}>Back</Button>
        <Flex direction={["row", "row", "column", "column"]} pt={"10px"}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'
            p={5}
            pt={"40px"}
            textAlign={"center"}
          >
            <Flex justifyContent={"center"}>
              <Text size={'xl'}>Personal Details</Text>
            </Flex>
            <Text
              fontFamily={"poppins"}
              mt={["30px", "1px", "10px", "30px"]}
              pb={["20px", "20px", "80px", "20px"]}
            ></Text>

            <FormControl isRequired w={["80%"]} m={"auto"}>
              <Flex gap={'20px'} w={'100%'}>
                <Box w={'47%'}>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="firstName"
                    placeholder="Enter First Name"
                    name="firstName"
                    onChange={handleChange}
                  />
                </Box>
                <Box w={'47%'}>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="phone"
                    placeholder="Enter Phone"
                    name="phone"
                    onChange={handleChange}
                  />
                </Box>
              </Flex>


              <FormLabel mt={'10px'}>Email</FormLabel>
              <Input
                type="email"
                placeholder="Enter email"
                name="email"
                onChange={handleChange}
              />
              <Text color="red.500">{errors.email}</Text>
  
              <FormLabel mt={'10px'}>Address</FormLabel>
              <Input
                type="address"
                placeholder="Enter address"
                name="email"
                onChange={handleChange}
              />
              <Text color="red.500">{errors.email}</Text>

              <FormLabel mt={"10px"}>Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  name="password"
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>

              <FormLabel pt={"10px"}>Confirm Password</FormLabel>
              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  name="confirmPassword"
                  onChange={handleChange}
                />

                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <Text color="red.500">{errors.confirmPassword}</Text>
              
             

              <Button
                mt={4}
                width="10%"
                onClick={handleLogin}
                color={"white"}
                backgroundColor={"teal"}
              >
                Submit
              </Button>
            </FormControl>

          </Box>
        </Flex>
      </Box>
    </Sidebar>
  )
}

export default EditAdmin
