import React, { useState } from 'react'
import Sidebar from '../../../Sidebar'
import { Box, Button,Flex,Image,Text,Input } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Api from '../../../../../dependencies/utils/Api'
import { CREATE_PARTNER } from '../../../../../constants/ApplicationUrl'

const AdminAddWhiteLabelUser = () => {
    const navigate=useNavigate()
    const [formData, setFormData] = useState({
        firstName:'',
        lastName:'',
        email:'',
        password:'',
        confirmPassword:"",
        address:'',
        phone:''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleBack = () => {
        navigate(-1);
      }

      const handleSubmit = (e) => {
        console.log(formData)
        Api.POST(`${CREATE_PARTNER}`,formData).then((res)=>{
            console.log(res)
            const {status,data}=res
            if(status===201){
                alert("User created Successfully")
            }else{
                alert(res.data.message)
            }
        })
      }


return (
    <Sidebar headingText={'Add New White label User'}>
      <Box w={'90%'} m={'auto'} mt={'20px'}>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
        </Box>

        <Box w={'80%'} m={'auto'} mt={'30px'}>
            <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} p={'15px'}>
                <Text>Add User</Text>
                {/* <Text fontSize='12px'>Complete your profile</Text> */}

                    <Box fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} p={'20px'} pb={'10px'}>
                        <Flex gap={'20px'} pb={'15px'}>
                            <Box>
                                <Text>First Name</Text>
                                <Input id="firstName" name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                                <Text>Last Name</Text>
                                <Input name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                                <Text>Email</Text>
                                <Input name="email"
                                    value={formData.email}
                                    onChange={handleChange} border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                                <Text>Phone</Text>
                                <Input name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                        </Flex>
                        <Flex gap={'20px'} mt={'10px'} mb={'10px'}>
                            <Box>
                                <Text>Address</Text>
                                <Input  name="address"
                                    value={formData.address}
                                    onChange={handleChange} placeholder='Address' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                                <Text>password</Text>
                                <Input  name="password"
                                    value={formData.password}
                                    onChange={handleChange} border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            <Box>
                                <Text>Confirm Password</Text>
                                <Input  name="confirmPassword"
                                    value={formData.confirmPassword}
                                    onChange={handleChange} border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                            </Box>
                            
                        </Flex>
                        <Flex justifyContent={'end'}>
                            <Button onClick={handleSubmit} size={'sm'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'}>Add</Button>
                        </Flex>
                    </Box>
                
            </Box>
            </Box>
    
    </Sidebar>
  )
}

export default AdminAddWhiteLabelUser
