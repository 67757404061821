import { ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import Loader from '../src/Components/Common/Loader/loadingAnimation';
import AppRoutes from "./routes";
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './Components/i18n';
import "react-toastify/dist/ReactToastify.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { extendTheme } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchThemeData } from "./dependencies/action";
import { getHomepageComponent } from "./dependencies/brandsData";
import { useTransition } from "react";

function App() {
  const stripePromise = loadStripe("pk_test_51PL25MSABmAl9teGeRko5Nbt9P3qxawimmQnjb4pBmwEgLC0M3xTHykYK0uH9iXtaFddCSpKeLFFnV1ZEgmW0ASm006QOatMJC");
  const dispatch = useDispatch();
 const {t} =useTranslation()
  // useEffect(() => {
  //   const brandId = 'brand1';
  //   dispatch(fetchThemeData(brandId));
  // }, [dispatch]);

  // const data = useSelector(state => state.theme);
  // const { theme, content, homepageComponent } = data || {};

  // if (!theme || !content) {
  //   // Provide default values if theme or content is undefined
  //   return <div>Loading...</div>;
  // }

   const component='Theme2'


  const brand = {
    logo: "/logos/logo1.png",
    homepageComponent: getHomepageComponent(component),
    theme:{
      colors: {
        primary: "#285E61",
        secondary: "#C7B198", 
      },
      fontFamily: {
        heading: "Open Sans, sans-serif",
        body: "Roboto, serif",
      },
      button:{
        buttonBackgroundColor : '#0B3948',
        buttonColor:'white',
        buttonHoverBackgroundColor:'#FFD470',
        buttonHoverColor:'black'
      }
    },
    content: {
      homepage: {
        title: (t("Get the right protection to keep moving forward.")),
        description: (t("With an utmost honest approach, we have developed ourselves with integrity as our primary principle, to provide the best experience to all our customers and to offer products and services which our clients can purchase and recommend with confidence.")),
        about:(t('With an utmost honest approach, we have developed ourselves with integrity as our primary principle, to provide the best experience to all our customers and to provide products and services which our clients can purchase and recommend with confidence.')),
        benefitsSectionHeading:(t('Your Gateway to Comprehensive Insurance Solutions')),
        benefitsSectionSubHeading:(t('At Travelmedicare we offer a broad range of insurance services to meet your specific needs. From health to property protection, our solutions provide reliable coverage and exceptional support. Trust us to safeguard your future with personalized care.'))
      },
      contact: {
        title: "At Travelmedicare, it is our constant endeavour to provide great customer experience. In case you require assistance, we have created multiple ways to reach out to us. We commit to resolving your queries to your satisfaction.",
        description: "Here's a little story about Brand One.",
        address:'7895 Tranmere Dr Unit #16 Suite 3 Mississauga ON L5S 1V9',
        phone:'1844-844-3272',
        email:'support@travelmedicare.com',
        website:'www.travelmedicare.com'
      },
      aboutUs: {
        title: "About Brand One",
        description: "Here's a little story about Brand One.",
      },
    },
};




  const themes = extendTheme({
    color: {
      primary: brand.theme.colors.primary,
      secondary: brand.theme.colors.secondary,
    },
    fonts: {
      heading: brand.theme.fontFamily.heading,
      body: brand.theme.fontFamily.body,
    },
    button: {
      buttonBackgroundColor: brand.theme.button.buttonBackgroundColor,
      buttonColor: brand.theme.button.buttonColor,
      buttonHoverBackgroundColor: brand.theme.button.buttonHoverBackgroundColor,
      buttonHoverColor: brand.theme.button.buttonHoverColor,
    },
    content: {
      homepage: {
        title: brand.content.homepage.title,
        description: brand.content.homepage.description,
        about: brand.content.homepage.about,
        benefitsSectionHeading:brand.content.homepage.benefitsSectionHeading,
        benefitsSectionSubHeading:brand.content.homepage.benefitsSectionSubHeading
      },
      contact:{
        title:brand.content.contact.title,
        address:brand.content.contact.address,
        email:brand.content.contact.email,
        phone:brand.content.contact.phone,
        website:brand.content.contact.website
      }
    },
  });

  // console.log(themes, 'theme');

  const HomePage = brand.homepageComponent;

  return (
    <ChakraProvider theme={themes}>
      <I18nextProvider i18n={i18n}>
        <Loader />
        <ToastContainer position="bottom-center" autoClose={5000} />
        <Elements stripe={stripePromise}>
          <AppRoutes HomePage={HomePage} />
        </Elements>
      </I18nextProvider>
    </ChakraProvider>
  );
}

export default App;
