import React, { useEffect, useState } from 'react';
import { Box, Tabs, TabList, Tab, TabPanel, TabPanels, Flex, Text, Button,Input,Select,TableContainer,Table,Thead,Tr,Td,Th,Tbody, Tooltip,Textarea, Image, InputGroup, InputRightElement} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { advisorSavePolicy, advisorSingleGetQuotes } from '../Dependencies/action';
import { useDispatch, useSelector } from 'react-redux';
import visa from '../../../assets/img/visa.png'
import masterCard from '../../../assets/img/masterCard.png'
import amex from '../../../assets/img/amex.png'
import JCB from '../../../assets/img/JCB.png'
import diners from '../../../assets/img/diners.png'
import discover from '../../../assets/img/discover.png'
import { DOWNLOAD_BENEFIT_COMPARISON } from '../../../constants/ApplicationUrl';


const AdvisorViewSingleQuote = () => {
    const { id } = useParams();
    const [quoteId,setQuoteId]=useState('')
    const [data,setData]=useState([]);
    const { token } = JSON.parse(localStorage.getItem("code_dev"));
    console.log(id,'id')
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const [policyType,setPolicyType]=useState('')
    const [firstDateOfCover,setFirstDateOfCover]=useState('')
    const [cardDetails,setCardDetails]=useState({
      cardHolderFirstName:'',
      cardHolderLastName:'',
      idQuote:'',
      quotationNo:'',
      cardHolderEmail:'',
      cardHolderAddress:'',
      cardHolderPostal:'',
      cardHolderPhone:'',
      cardNumber:'',
      expiryDate:'',
      cvv:'',
      amount:''
    })
    const [cardType, setCardType] = useState('');
    const [errors, setErrors] = useState({
      cardHolderFirstName: '',
      cardHolderLastName: '',
      cardHolderEmail: '',
      cardHolderPostal: '',
      cardHolderPhone: '',
      cardNumber: '',
      expiryDate: '',
      cvv: '',
      amount: ''
    });
    const [isPaymentChecked, setIsPaymentChecked] = useState(false);
    const handleCheckboxChange = (e) => {
      setIsPaymentChecked(e.target.checked);
    };


useEffect(() => {
    if (id) {
        dispatch(advisorSingleGetQuotes(id))
            .then(response => {
                console.log(response.data,'rrrrrrrrrrrrrrrr')
                const updatedCardData={
                  cardHolderFirstName: response.data.policyHolderName,
                  cardHolderEmail: response.data.policyHolderEmail,
                  cardHolderAddress: response.data.policyHolderAddress ,
                  cardHolderPostal: response.data.policyHolderPostalCode,
                  cardHolderPhone: response.data.policyHolderPhone,
                  amount:response.data.paymentFrequency===2?response.data.amount:response.data.quoteAmount
                }
                setFirstDateOfCover(response.data.firstDateOfCover)
                setPolicyType(response.data.product)
                setCardDetails(updatedCardData)
                setData([response.data]); 
                setQuoteId(response.data.idQuote)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
}, [dispatch, id]);

const handleCardChanges =(e)=>{
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
    if (name === 'cardNumber') {
      if (/^4/.test(value)) {
        setCardType('Visa');
      } else if (/^5[1-5]/.test(value)) {
        setCardType('MasterCard');
      } else if (/^3[47]/.test(value)) {
        setCardType('AmericanExpress');
      } else if (/^6(?:011|5[0-9]{2})/.test(value)) {
        setCardType('Discover');
      } else if (/^(?:2131|1800|35\d{3})/.test(value)) {
        setCardType('JCB');
      } else if (/^3(?:0[0-5]|[68][0-9])/.test(value)) {
        setCardType('DinersClub');
      } else {
        setCardType('');
      }
    }
    if(name==='expiryDate'){
      e.target.value = formatExpirationDate(e.target.value)
    }
  }

function clearNumber (value = '') {
    return value.replace(/\D+/g, '')
  }

const formatExpirationDate =(value)=> {
    const clearValue = clearNumber(value)
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
    }
    return clearValue
  }

  const handleSavePolicy = ()=>{
    const tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      const firstDate = new Date(firstDateOfCover);
      firstDate.setHours(0, 0, 0, 0);
      if (firstDate < tomorrow) {
        alert("First date of cover must be greater than today.");
        return;
      }
    if(!cardDetails.cardNumber){
      alert('Please enter card Number')
      return;
    }
    if(!cardDetails.expiryDate){
      alert('Please enter expiry date')
      return;
    }
    if(!cardDetails.cvv){
      alert('Please enter card cvv')
      return;
    }
    if(!isPaymentChecked){
      alert("Please check terms & Conditions")
      return;
    }
    const updateData={
      ...cardDetails,
      idQuote:quoteId,
      quotationNo:id,
    }
    console.log(updateData,'data sending')
    dispatch(advisorSavePolicy(updateData)).then((res)=>{
      console.log(res)
      navigate('/advisor-policies')
    })
  }

  const handleBenefitComparisonDownload = async () => {
    try {
      const response = await fetch(`${DOWNLOAD_BENEFIT_COMPARISON}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      saveAs(blob, 'BenefitComparison.pdf'); 
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };

return (
    <Box pt={['100px','60px','60px']} pb={'20px'} backgroundColor={'grey'}>
    <Tabs boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' backgroundColor={'white'} isFitted variant='enclosed' w={['95%','80%','80%']} m={'auto'}>
      <TabList border={'2px solid grey'} borderTop={'none'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          paddingTop: '5px',
          color: 'black',
          fontSize: '16px',
          fontWeight: '800',
          fontFamily: 'poppins',
        }}>
        <Tab>Quick Quote</Tab>
        <Tooltip bg='white' hasArrow arrowShadowColor='teal' placement='bottom' color={'black'} borderRadius={'5px'} fontFamily={'Open Sans,sans-serif'} p={'10px'} border='1px solid teal' label='Click here to proceed and purchase the policy.'> 
        <Tab>Confirm & Pay</Tab>
        </Tooltip>
      </TabList>
      <TabPanels>
        <TabPanel border={'2px solid grey'} borderTop={'none'}>
        {data && data.map(ele=>(  
                    <Box w={'95%'} m={'auto'}>
                    <Flex direction={['column','column','row']} justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'}>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>First Date of Cover</Text>
                            <Input color={'grey'} name="firstDate" value={ele.firstDateOfCover} size='xs' type='date' border={'1px solid #d7d7d7'} readOnly />
                            </Box>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>Last Date of Cover</Text>
                            <Input color={'grey'} name="lastDate" value={ele.lastDateOfCover} size='xs' type='date' border={'1px solid #d7d7d7'} />
                            </Box>
                            <Box w={['auto','7%','9%']}>
                            <Text fontWeight={'700'}>Duration</Text>
                            <Input color={'grey'} value={`${ele.duration} days`} size='xs' backgroundColor={'#eaeaea'} border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>Client country</Text>
                            <Input color={'grey'} name="clientCountry" value={'Canada'} placeholder='Canada' size='xs' border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                            <Box w={['auto','20%','20%']}>
                            <Text fontWeight={'700'}>Province</Text>
                            <Input color={'grey'} name="clientCountry" value={ele.policyHolderProvince} placeholder='Canada' size='xs' border={'1px solid #d7d7d7'} readOnly/>
                            </Box>
                        </Flex>

                {ele.listOfInsured.map(item=>(
                    <Box backgroundColor={'#f3f3f3'} border={'1px solid #80808026'} p={'20px'} m={'auto'} mt={'7px'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
                        <Flex direction={['column','column','row']} gap={['10px','20px','20px']}>
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'700'}>Name Of Insured</Text>
                            <Input color={'grey'} value={item.insuredName} readOnly background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="name"/>            
                            </Box>
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'700'}>Gender</Text>
                            <Input color={'grey'} value={item.insuredGender} readOnly background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="name"/>  
                            </Box>
                            {/* <Tooltip hasArrow label='Age must between 15 days and 89 years' bg='gray.300' color='black' placement='top-start'> */}
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'600'}>Date of Birth</Text>
                            <Input color={'grey'} type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} readOnly
                            value={item.insuredDob} 
                            />            
                            </Box>
                            {/* </Tooltip> */}
                        </Flex>
                        <Flex direction={['column','column','row']} gap={['10px','20px','20px']} mt={'10px'}>
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'700'}>Beneficiary Name</Text>
                            <Input color={'grey'} background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="benificiaryName" readOnly
                            value={item.beneficiaryName}/>            
                            </Box>
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'700'}>Relation</Text>
                            <Input color={'grey'} background={'white'} size='xs' border={'1px solid #d7d7d7'}
                            name="relation" value={item.beneficiaryRelationToInsured} readOnly
                            />            
                            </Box>  
                            <Box w={['auto','20%','25%']}>
                            <Text fontWeight={'600'}>Beneficiary DOB</Text>
                            <Input readOnly color={'grey'} type='date' background={'white'} size='xs' border={'1px solid #d7d7d7'} value={item.beneficiaryDob}
                            name="benificiaryDob"
                            />            
                            </Box>
                        </Flex>
                        </Box>
                    ))}                       
                    <hr />
                    <Flex direction={['column','column','row']} gap={['10px','20px','5%']} fontSize={'12px'} mt={'20px'} mb={'10px'}>
                        <Box w={['auto','20%','20%']}>
                            <Text>Arrival Date in Canada</Text>
                            <Input name="clientCountry" value={ele.firstDateOfCover} placeholder='Canada' size='xs' border={'1px solid #d7d7d7'} readOnly/>
                        </Box>
                        <Box>
                        <Text>Waiting Period</Text>
                            <input
                            name="waiting"
                            value={ele.waitingPeriod}
                            readOnly
                            />
                        </Box> 
                        <Box>
                        <Text>Product</Text>
                            <input
                            name="waiting"
                            value={ele.product}
                            readOnly
                            />
                        </Box> 
                        <Flex direction={'column'} alignItems={['start','start','center']}>
                        <Text>Family Coverage</Text>
                        <input type='checkbox' readOnly size='md' name='1' colorScheme='green' disabled checked={ele.familyCoverage===1?true:false}
                        ></input>
                        </Flex>  
                        <Flex direction={'column'} alignItems={['start','start','center']}>
                        <Text>Super Visa</Text>
                        <input type='checkbox' readOnly size='md' name='1' colorScheme='green' disabled checked={ele.superVisa===1?true:false}
                        ></input>
                        </Flex>  
                        { ele.superVisa===1?
                        <Box>
                          <Text fontSize={'12px'}>Payments Frequency</Text>
                            <Select color={'black'} disabled background={'white'} size={'xs'} value={ele.paymentFrequency}>
                              <option value="0">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                          </Box>:''
                          }
                          { policyType==='STC'?
                        <Box>
                          <Text fontSize={'12px'}>Student Policy Option</Text>
                            <Select color={'black'} disabled background={'white'} size={'xs'} value={ele.paymentFrequency}>
                              <option value="Single Trip">Single Trip</option>
                              <option value="Annual">Annual</option>
                            </Select>
                          </Box>:''
                          }
                    </Flex>
                    <Flex fontSize={'12px'} mt={'20px'} mb={'10px'} gap={'5%'}>
                        <Box>
                            <Text>Policy Limit</Text>
                            <Text w={'180px'} size='xs'> {ele.policyLimit}</Text>
                        </Box>
                        <Box>
                        <Text>Deductible</Text>
                        <Text w={'180px'} size='xs'>$ {ele.deductible}</Text>
                        </Box>  
                    </Flex>
                    <Button size={'xs'} mb={'20px'} backgroundColor={'teal'} color={'white'} fontFamily={'poppins'} _hover={{color:'white'}} onClick={handleBenefitComparisonDownload}>See Benefit Comparison</Button>
                    <hr />
                    <Flex direction={['column','column','row']}>
                    <Box w={['95%','50%','50%']}>
                    <TableContainer >
                    <Table>
                        <Thead>
                            <Tr gap={'50px'}>
                                <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                                <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                ele.listOfInsured.map((item)=>(
                                <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                    <Td p={'2px'}>{item.insuredName}</Td>
                                    <Td p={'2px'}>{item.insuredPlan}</Td>
                                    <Td p={'2px'} display={'flex'}>
                                    <Text pl={'10px'}>
                                    <input
                                      type="radio"
                                      disabled
                                      checked={item.selectedKey===2}
                                      />
                                      {item.costWithPreExisting}
                                      </Text>
                                    </Td>
                                    <Td p={'2px'}>
                                    <Text pl={'10px'}>
                                    <input
                                      type="radio"
                                      disabled
                                      checked={item.selectedKey===3}
                                      />
                                      {item.costWithoutPreExisting}</Text>
                                    </Td>
                                </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                    </TableContainer>
                    </Box>
                    <Box w={['95%','50%','50%']}>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th p={'7px'} fontSize={'10px'}>Period</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Net</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Total</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                    <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                        <Td p={'2px'}>{`${ele.firstDateOfCover} - ${ele.lastDateOfCover}`}</Td> 
                                        <Td p={'2px'}>{policyType==='STC'?ele.quoteAmount-ele.listOfInsured[0].adjAmt:ele.quoteAmount}$</Td>
                                        <Td p={'2px'}>0.00 CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'}>{policyType==='STC'?ele.listOfInsured[0].adjAmt:'0.00'} CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{ele.quoteAmount}$</Td>
                                    </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer></Box> 
                    </Flex>
                    <Text color={'red'} fontSize={'11px'} mt={'5px'}>{ele.message}</Text>
                        <Text textAlign={'center'} mt={'20px'}>Policy Holder Details</Text>
                    <Flex direction={['column','column','row']} fontWeight={'700'} gap={['10px','20px','40px']} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} pb={['5px','20px','20px']} mt={'20px'}>
                        <Box>
                        <Text pb={'3px'}>Policy Owner*</Text>
                        <Input color={'grey'} value={ele.policyHolderName} w={'220px'} size='xs' name="policyOwnerDob" border={'1px solid #d7d7d7'} />  
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Date Of Birth*</Text>
                        <Input color={'grey'} type='date' value={ele.policyHolderDob} readOnly w={'220px'} size='xs' border={'1px solid #d7d7d7'} />            
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Email*</Text>
                        <Input color={'grey'} value={ele.policyHolderEmail} readOnly w={'220px'} size='xs' border={'1px solid #d7d7d7'} />    
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Address*</Text>
                        <Input color={'grey'} value={ele.policyHolderAddress} w={'220px'} size='xs' border={'1px solid #d7d7d7'}/>     
                        <Text color="red.500"></Text>       
                        </Box>
                    </Flex>
                    <Flex direction={['column','column','row']} fontWeight={'700'} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'} gap={['10px','20px','40px']} pb={'20px'} mt={'10px'}>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Postal Code*</Text>
                        <Input color={'grey'} value={ele.policyHolderPostalCode} w={'220px'} size='xs' border={'1px solid #d7d7d7'}/>   
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner City*</Text>
                        <Input color={'grey'} value={ele.policyHolderCity} w={'220px'} size='xs' border={'1px solid #d7d7d7'} />   
                        </Box>
                        <Box>
                        <Text pb={'3px'}>Policy Owner Phone*</Text>
                        <Input color={'grey'} value={ele.policyHolderPhone} w={'220px'} size='xs' border={'1px solid #d7d7d7'} />            
                        </Box>
                    </Flex>
                    </Box>))}
        </TabPanel>



        <TabPanel> 
              <Box>
              <Text fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}><span style={{color:'red'}}>Note :</span>If you are using different Credit Card Make Sure to update Payor’s Card details including Name, Address, email, Cell No and Postal Code or else Card will be declined</Text>
              <Text fontWeight={'600'} mt={'15px'} fontFamily={'poppins'}>Primary Card Details :</Text>
              <Flex direction={['column','column','row']} w={'90%'} m={'auto'} mt={'20px'} gap={'30px'}>
                <Box w={['95%','50%','50%']} border={'1px solid #d5d5d5'}>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}> 
                      <Text>Customer Information</Text></Flex>
                    <Box p={'10px'}>
                      <Flex fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder First Name</Text>
                          <Input value={cardDetails.cardHolderFirstName} name='cardHolderFirstName' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Last Name</Text>
                          <Input value={cardDetails.cardHolderLastName} name='cardHolderLastName' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                      </Flex>
                      <Box w={'95%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Holder Email:</Text>
                          <Input value={cardDetails.cardHolderEmail} name='cardHolderEmail' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                        <Flex mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Postal</Text>
                          <Input value={cardDetails.cardHolderPostal} name='cardHolderPostal' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box> 
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Phone</Text>
                          <Input value={cardDetails.cardHolderPhone} name='cardHolderPhone' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                      </Flex>
                        <Box w={'95%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Holder Address</Text>
                          <Textarea value={cardDetails.cardHolderAddress} name='cardHolderAddress' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                    </Box>
                </Box>

                <Box w={['95%','40%','40%']} border={'1px solid #d5d5d5'}>
                  <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}> 
                      <Text>Payment Details</Text></Flex>
                      <Box w={'90%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Number</Text>
                          <InputGroup size="sm">
                          <Input
                            maxLength={16}
                            pr="4.5rem"
                            placeholder="Enter Card Number"
                            name='cardNumber' onChange={handleCardChanges}
                          />
                          <InputRightElement width="4.5rem">
                            <Image size='xs' src={cardType==='Visa'?visa:cardType==='MasterCard'?masterCard:cardType==='AmericanExpress'?amex:cardType==='JCB'?JCB:cardType==='DinersClub'?diners:cardType==='Discover'?discover:""} />
                          </InputRightElement>
                        </InputGroup>
                        </Box>
                      <Flex w={'90%'} m={'auto'} mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-between'}>
                        <Box w={'45%'}>
                        <Text fontSize={'12px'}>Expiry Date</Text>
                        <Input placeholder='Valid Thru' type='tel'pattern='[\d| ]{16,22}' maxLength='19' name='expiryDate' onChange={handleCardChanges} size={'sm'}/>
                        </Box> 
                        <Box w={'25%'}>
                          <Text fontSize={'12px'}>cvv</Text>
                          <Input maxLength={3} placeholder='cvc' name='cvv' onChange={handleCardChanges} size={'sm'}/>
                        </Box>
                      </Flex>
                    <Flex w={'90%'} m={'auto'} p={'5px 20px'} justifyContent={'space-between'} mt={'20px'} background={'teal'} color={'white'} borderRadius={'5px'}>
                    <Text>Final Payment</Text>
                    <Text>${Math.round(cardDetails.amount)}</Text>
                    </Flex>
                    <Flex w={'90%'} m={'auto'} fontSize={'12px'} gap={'10px'} mt={'10px'}>
                      <input type="checkbox" onChange={handleCheckboxChange} checked={isPaymentChecked}  />
                    <Text>By checking the box, I / We agree to the following terms and conditions</Text>
                    </Flex>
                      </Box>
                  </Flex>
            
              <Flex justifyContent={'center'} gap={'30px'} mt={'20px'}>
                <Button background={'teal'} color={'white'} onClick={handleSavePolicy}>Pay</Button>
              </Flex>
            </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Box>
  )
}

export default AdvisorViewSingleQuote
