
import { ALL_TICKET_GET, ALL_TICKET_GET_ID } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"


export const SupportTicketService = {
supporTicketAllList() {
    return Api.GET(ALL_TICKET_GET).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
supporTicketAllById(id, params) {
    return Api.GET(`${ALL_TICKET_GET_ID}/${id}`, params).then((response) => {
        console.log(response,'response')
        const { data, status } = response;
        if (status === 200) {
            return { data, status };
        } else {
            const { data: { message } = {} } = response;
            return { message, status };
        }
    });
}
}