import { Box, Flex, Text, Button } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <Box
      w="100%"
      h="100vh"
      bg="teal"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      color="white"
    >
      <Text fontSize="6xl" mb={4}>
        404
      </Text>
      <Text fontSize="2xl" mb={8}>
        Page Not Found
      </Text>
      <Flex>
        <Link to="/">
          <Button
            colorScheme="whiteAlpha"
            variant="outline"
            mr={4}
          >
            Home
          </Button>
        </Link>
        <Link to="/enquiry">
          <Button
            colorScheme="whiteAlpha"
            variant="outline"
          >
            Contact Us
          </Button>
        </Link>
      </Flex>
    </Box>
  );
}

export default PageNotFound;
