import React from 'react'
import SidebarServices from '../SidebarServices'
import Tickets from '../../Tickets'
import { Box } from '@chakra-ui/react'

const ServiceSupport = () => {




return (
    <SidebarServices headingText={'Support Center'}>
    </SidebarServices>
  )
}

export default ServiceSupport
