import { ADMIN_CREATE, ADMIN_LOGIN, MENU_LIST, SUPERADMIN_LOGIN } from "../../../constants/ApplicationUrl"
import Api from "../../../dependencies/utils/Api"

export const SuperAdminLoginService = {
    superadminLoginList(params) {
        return Api.POST(SUPERADMIN_LOGIN, params).then((response) => {
            console.log("SUPERADMINlogin",response)
            const { data, status} = response
            if(status === 200 || status===201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
}

export const AdminCreateLoginService = {
    admincreateLoginList(params) {
        return Api.POST(ADMIN_CREATE, params).then((response) => {
            console.log("admincreate",response)
            const { data, status} = response
            if(status === 200 || status===201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    menuList() {
        return Api.GET(MENU_LIST).then((response) => {
            // console.log(response,'responserviceadvisor')
            const { data, status} = response
            if(status === 200 ) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
}


export const AdminLoginService = {
    adminLoginList(params) {
        return Api.POST(ADMIN_LOGIN, params).then((response) => {
            console.log("ADMINlogin",response)
            const { data, status} = response
            if(status === 200 || status===201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
}


