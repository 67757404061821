import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import Sidebar from '../../../Sidebar'
import { useDispatch } from 'react-redux'
import { advisorGetPolicyDetails } from '../../../../Advisor/Dependencies/action'
import { FaRegEdit } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { RefundActionModel, RefundAttachementModel, RequestedCorrectionModal } from './Models'
import { format } from 'date-fns'



const PolicyEndorsements = () => {
    const params= useParams()
    const {id}=params
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [policyData,setPolicyData]=useState([])
    const [correction,setCorrection]=useState([])
    const [voids,setVoids]=useState([])
    const [cancellation,setCancellation]=useState([])
    const [refund,setRefund]=useState([])
    const [isRefundAttachementModelOpen, setIsRefundAttachementModelOpen] = useState({ isOpen: false, refundItem: null });
    const [isRefundActionModelOpen, setIsRefundActionModelOpen] = useState({ isOpen: false, refundItem: null });
    const [isRequestedCorrectionModalOpen,setIsRequestedCorrectionModalOpen]=useState({isOpen:false,correctionItem:null})
    console.log(cancellation,'cancellation')
    console.log(voids,'void')
    console.log(correction,'Correction')
    console.log(policyData,'policy data')

    const openRefundModalWithItem = (item) => {
        setIsRefundAttachementModelOpen({ isOpen: true, refundItem: item });
    };
    const openRefundActionModalWithItem = (item) => {
        setIsRefundActionModelOpen({ isOpen: true, refundItem: item });
    };
    const openRequestedModalWithItem = (item) => {
        setIsRequestedCorrectionModalOpen({ isOpen: true, correctionItem: item });
    };
    


useEffect(()=>{
   getPolicyDetails()
},[dispatch,id])


const getPolicyDetails = ()=>{
    dispatch(advisorGetPolicyDetails(id)).then((res)=>{
        const array=res.data.endorsementEntity
        const filteredData = array.filter(item => item.endorsementType === 'Correction');
            setCorrection(filteredData)
        const voidData= array.filter(item=>item.endorsementType === "Void")
            setVoids(voidData)
        const cancellationData = array.filter(item=>item.endorsementType === "Cancel")
            setCancellation(cancellationData)
        const refundData=array.filter(item=>item.endorsementType==='Refund')
            setRefund(refundData)
        console.log(filteredData,'data filterd')
        setPolicyData([res.data])
    })
}


  
const handleBack = () => {
    navigate(-1);
}


const handleActionModalSubmit = ()=>{
    getPolicyDetails()
}

return (
    <Sidebar headingText={'Policy Endorsements'}>
        <Box w='95%' m='auto' mt={'70px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mb={'10px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text fontSize={'20px'}>Policy Details</Text>
        {
            policyData && policyData.map((ele)=>(
                <>
                <Flex justifyContent={'space-around'}>
            <Box mt={'15px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy No:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Created date:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Amount:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Duration:</Text>
            </Box>
            <Box mt={'15px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{ele.policyNo}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{format(ele.createdAt,'yyyy-MM-dd')}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>$ {ele.quote.quoteAmount}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.duration} days</Text>
            </Box>
            <Box mt={'15px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Holder Name:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Last Date Of Cover:</Text>
            </Box>
            <Box mt={'15px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{ele.quote.policyHolderName}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.firstDateOfCover}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{ele.quote.lastDateOfCover}</Text>
            </Box>
                </Flex>

                <Box  mt={'25px'}> 
                <Tabs size='md' variant='enclosed'>
                        <TabList >
                            <Tab _selected={{ background:'white' }}>Void</Tab>
                            <Tab _selected={{  background:'white' }}>Refund Mid Term</Tab>
                            <Tab _selected={{ background:'white' }}>Cancellation</Tab>
                            <Tab _selected={{  background:'white' }}>Correction</Tab>
                        </TabList>

                        <TabPanels background={'white'}>


                            <TabPanel background={'white'}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Insured Name</th>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Date	</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation ID</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Doc Type</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amt</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                        voids && voids.map((voidItem)=>(
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.quote.policyHolderName}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{voidItem.requestedDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{voidItem.transactionNo}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$  {voidItem.refundAmount}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{ textTransform:'capitalize', textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{voidItem.transactionStatus}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaDownload onClick={() => openRefundModalWithItem(voidItem)}/></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{voidItem.adminRemarks}</td>
                                {
                                    (voidItem.transactionStatus===null || voidItem.transactionStatus==='pending')?<td style={{ color:'#9c27b0',textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                                    <FaRegEdit onClick={() => openRefundActionModalWithItem(voidItem)}/>
                                    </td>:''
                                }
                                
                            </tr>
                            ))
                        }
                        </tbody>
                            </table>
                        
                            </TabPanel>

                        
                       
                            <TabPanel background={'white'}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Insured Name</th>
                                <th style={{ textAlign: 'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Date</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Cancellation Date</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amount</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                refund && refund.map((refundItem)=>(
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{refundItem.requestedDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{refundItem.cancellationDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{refundItem.transactionNo}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{refundItem.refundAmount}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{refundItem.remarks}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{color:'#9c27b0', textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif',cursor:'pointer'}}><FaDownload onClick={() => openRefundModalWithItem(refundItem)}/></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{ color:'#9c27b0',textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaRegEdit onClick={() => openRefundActionModalWithItem(refundItem)}/></td>
                            </tr>
                            ))
                        }
                        </tbody>
                        </table>
                            </TabPanel>
                        



                            
                            <TabPanel background={'white'}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Policy Holder Name</th>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Requested Date	</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Cacnellation Date</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amount</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            cancellation && cancellation.map((cancelItem)=>(
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.quote.policyHolderName}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.requestedDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.cancellationDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.type}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.transactionNo}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>$ {cancelItem.refundAmount}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.remarks}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.transactionStatus}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaDownload onClick={() => openRefundModalWithItem(cancelItem)}/></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{cancelItem.adminRemarks}</td>
                                {
                                    (cancelItem.transactionStatus===null || cancelItem.transactionStatus==='pending')?
                                <td style={{color:'#9c27b0',textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaRegEdit  onClick={() => openRefundActionModalWithItem(cancelItem)}/></td>:''
                                }
                            </tr>))
                            }
                        </tbody>
                        </table>
                            </TabPanel>
                            


                            <TabPanel background={'white'}>
                            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Date</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No</th>
                                <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Type</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Correction Type</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Correction Request needed in</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Requested Correction</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>To/From Customer</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                <th style={{textAlign:'left',padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                correction && correction.map((correctionItem)=>(
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.requestedDate}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.transactionNo}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.endorsementType}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.type}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.correctionFields}</td>
                                <td style={{color:'#9c27b0',padding:'5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaEye onClick={()=>openRequestedModalWithItem(correctionItem)} /></td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.refundAmount}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>-</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{correctionItem.remarks}</td>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                <td style={{color:'#9c27b0', textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaDownload  onClick={() => openRefundModalWithItem(correctionItem)}/></td>
                                <td style={{padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}></td>
                                {
                                    (correctionItem.transactionStatus===null | correctionItem.transactionStatus==='pending')?
                                <td style={{color:'#9c27b0', textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}><FaRegEdit  onClick={() => openRefundActionModalWithItem(correctionItem)}/></td>:''}
                            </tr>))
                            }
                        </tbody>
                        </table>
                            </TabPanel>
                        </TabPanels>
                        </Tabs>


            
                </Box>
                </>
))
}
        </Box>
        <RefundAttachementModel isOpen={isRefundAttachementModelOpen.isOpen} onClose={()=>setIsRefundAttachementModelOpen({ ...isRefundAttachementModelOpen, isOpen: false })} refundItem={isRefundAttachementModelOpen.refundItem}/>
        <RefundActionModel isOpen={isRefundActionModelOpen.isOpen} onClose={()=>setIsRefundActionModelOpen({ ...isRefundActionModelOpen, isOpen: false })} refundItem={isRefundActionModelOpen.refundItem} onSubmit={handleActionModalSubmit}/>
        <RequestedCorrectionModal isOpen={isRequestedCorrectionModalOpen.isOpen} onClose={()=>setIsRequestedCorrectionModalOpen({ ...isRequestedCorrectionModalOpen, isOpen: false })} correctionItem={isRequestedCorrectionModalOpen.correctionItem}/>
    </Sidebar>
  )
}

export default PolicyEndorsements
