import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import Api from '../../../../dependencies/utils/Api'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Box, Flex, Select, Text,Tooltip } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { primaryColor } from '../../../../Utils/UniversalColor'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { advisorGetQuotes } from '../../../Advisor/Dependencies/action'
import { FaDownload } from 'react-icons/fa6'
import { GET_CUSTOMER_BY_ID, GET_POLICY_BY_EMAIL } from '../../../../constants/ApplicationUrl'
import { sendPolicy } from '../../../../Utils/Emails'

const AdminAdvisorPolicies = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10,20,50,100];
    const downloadCellRenderer = (params) => {
      const {data}=params;
        const handleDownload = () => {
          navigate(`/admin/download-documents/${data.policyNo}`)
          console.log('Download clicked for row:', params.data);
        };
      return(
        <div style={{ display: 'flex', justifyContent: 'center',marginTop:'10px'}}>
        <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
      </div>
      )
      };
    
      const handleAction = (params)=>{
          const {data}=params
        const handleChange = (e) => {
          if(e.target.value==='endorsement'){
            navigate(`/admin/advisor-policy-endorsement/${data.id}`)
          }
          if(e.target.value==='view'){
            navigate(`/admin/advisor-policy-single/${data.id}`)
          }
          if(e.target.value==='send'){
            sendPolicy(data.policyNo)
            alert('Policy emailed successfully.')
          }
          console.log('Download clicked for row:', params.data);
        };
      return(
        <>
            <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
             <Select onChange={(e) => handleChange(e)} size={'xs'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
              <option value="">Select</option>
              <option value="view">View</option>
              <option value="send">Email policy</option>
              {data.status === 'Active' && <option value="endorsement">Endorsement</option>}
              <option value="cancel">Re-issue</option>
              </Select>
            </Tooltip>
            </>
      )
      }
      const params=useParams()
      const {id}=params
      const dispatch = useDispatch();
      const [policiesData,setPoliciesData]=useState([])
      const [customerData,setCustomerData]=useState([])
      const [colDefs, setColDefs] = useState([
        { field: "product",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:150},
        { field: "policyNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:290},
        { field: "dateOfIssue",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
        { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300},
        { field: "tripType",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "policyAmount",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "status",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "download",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer: downloadCellRenderer,width:140,wrapHeaderText: true},
        { field: "Action",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer:handleAction, width:250},
      ]);
      const [rowData, setRowData] = useState([]);
      const [rowDataEmpty,setRowDataEmpty]=useState([])
      const navigate=useNavigate()
    
    useEffect(()=>{
      getCustomerData()
    },[])
    
    
    const getCustomerData=()=>{
      Api.GET(`${GET_CUSTOMER_BY_ID}/${id}`).then((res)=>{
          console.log(res.data.email,'email to send')
          setCustomerData(res.data)
          getCustomerPolicies(res.data.email)
      })
    }
    
    const getCustomerPolicies = (email) => {
      Api.GET(`${GET_POLICY_BY_EMAIL}${email}`).then((res) => {
        console.log(res, 'get customer response policies');
        if (res.status === 200) {
          setPoliciesData(res.data);
          // if (Array.isArray(res.data)) {
            const formattedData = res.data.map((quote, index) => {
              let status;
              switch (quote.status) {
                case 1:
                  status = 'Active';
                  break;
                case 2:
                  status = 'Pending Cancellation';
                  break;
                case 3:
                  status = 'Cancelled';
                  break;
                case 4:
                  status = 'Pending Void';
                  break;
                case 5:
                  status = 'Void';
                  break;
                case 6:
                  status = 'Policy Change Pending';
                  break;
                case 7:
                  status = 'Matured';
                  break;
                default:
                  status = 'Inactive';
              }
              return {
                id: quote.idPolicy,
                product: quote.product === 'Vistors To Canada' ? 'VTC' : 'STC',
                policyNo: quote.policyNo,
                dateOfIssue: quote.createdAt,
                customerName: quote.quote.policyHolderName,
                tripType: quote.quote.type,
                customerEmail: quote.policyHolderEmail,
                policyAmount: quote.quote.quoteAmount,
                status: status,
              };
            });
            setRowData(formattedData);
          } else {
            console.error('Response data is not an array:', res.data);
          }
        // }
      });
    };
    
    
   



return (
    <Sidebar headingText={'Advisor Dashboard'}>
        <Box>
  <Flex boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'90%'} m={'auto'} p={'18px'} fontFamily={'poppins'} justifyContent={'space-around'}>
  <Link to={`/admin/advisor-dashboard/${id}`}>
            <Text>Quotes</Text>
            </Link>
            <Link to={`/admin/advisor-policies/${id}`}>
            <Text>Policies</Text>
            </Link>
            <Link to={`/admin/advisor-renewal-policies/${id}`}>
            <Text>Renewal Policies</Text>
            </Link>
            <Link to={`/admin/editAgaMgaAdvisor/${id}`}>
            <Text>Profile</Text>
            </Link>
            </Flex>

  <Box w={'90%'} m={'auto'} mt={'80px'} mb={'50px'} pb={'20px'}>
  {rowData.length > 0 ? (
    <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefs}
        // frameworkComponents={{ actionCellRenderer }}
        domLayout='autoHeight'
        paginationPageSizeSelector={paginationPageSizeSelector}
        pagination={pagination}
        onGridReady={(params) => {
          setTimeout(() => {
            params.api.sizeColumnsToFit();
          }, 1000);
        }}              
      />
    </div>
  ) : (
    <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
    <AgGridReact
      rowData={rowDataEmpty}
      columnDefs={colDefs}
      domLayout='autoHeight'
      paginationPageSizeSelector={paginationPageSizeSelector}
      pagination={pagination}
      paginationPageSize={10}
      onGridReady={(params) => {
        params.api.sizeColumnsToFit();
      }}
    />
  </div>
  )}

</Box>
</Box>
    </Sidebar>
  )
}

export default AdminAdvisorPolicies
