import { toast } from "react-toastify";
import { ADMIN_COMMISION_STATUS_TYPE, COMMISION_LIST_TYPE, COMMISION_SETTINGS_EDIT_TYPE, COMMISION_SETTINGS_LIST_TYPE, PENDING_ENDORSEMENT_LIST_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminCommisionService } from "./service";

export const adminCommisionList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminCommisionService.adminCommisionList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: COMMISION_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  
export const adminCommisionSettingsList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminCommisionService.adminCommisionSettingsList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: COMMISION_SETTINGS_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const adminCommisionSettingsEdit = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
        let response  = await AdminCommisionService.adminCommisionSettingsEdit(id, params); // Corrected the parameter order
        const { data, status } = response;

        if (status === 200 || status === 201) {
            dispatch({
                type: COMMISION_SETTINGS_EDIT_TYPE,
                payload: { data } // Wrap the data in an object
            });
            toast.success("Settings updated successfully");
        } else {
            toast.error("Failed to update settings");
        }

        dispatch(isLoading(false));
        return response; // Return the response for further processing if needed
    } catch (error) {
        console.error("Error updating commission settings:", error);
        toast.error("Error updating settings");
        dispatch(isLoading(false));
        throw error; // Rethrow the error so that it can be handled by the caller
    }
};

export const adminCommisionStatus = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await AdminCommisionService.adminCommisionStatus(
      params
    );
    console.log("Response data action:", response);
    dispatch({
      type: ADMIN_COMMISION_STATUS_TYPE,
      payload: response,
    });
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(isLoading(false));
    throw error;
  }
};