import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex } from '@chakra-ui/react';
import { FaRegEdit } from "react-icons/fa";
import { allDeductables } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Deductibles = () => {
  const [deductiblesData, setDeductiblesData] = useState([]);
  const dispatch = useDispatch();
  const navigate=useNavigate()

  useEffect(() => {
    // Fetch Deductibles data
    dispatch(allDeductables())
      .then((res) => {
        setDeductiblesData(res);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);
  const handleAddNew=()=>{
    navigate(`/admin/deductibles/createDeductibles`);
  }
  const handleEdit=(item)=>{
    navigate(`/admin/deductibles/updateDeductibles/${item}`)
  }


  return (
    <Sidebar headingText="Deductibles List">

      <Box w={'90%'} m={'auto'} mt={'70px'}>
        <Flex justifyContent={'end'} mb={'5px'}>
          <Button onClick={handleAddNew} size={'sm'} borderRadius={'20px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'}>Add New</Button>
        </Flex>
        <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
              <col style={{ width: '7%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '30%' }} />
              <col style={{ width: '20%' }} />
              <col style={{ width: '10%' }} />
              <col style={{ width: '10%' }} />
            </colgroup>
            <thead>
              <tr style={{ borderBottom: '1px solid #dddddd' }}>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Amount</th>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Standard + Enhanced Discount (Age 0-79)</th>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Standard + Enhanced Discount (Age 80-89)</th>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Premium Discount (Age 0-69)</th>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Status</th>
                <th style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {deductiblesData.map((item, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                  <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>$ {item.amount}</td>
                  <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{item.standardDiscount}</td>
                  <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{item.enhancedDiscount}</td>
                  <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{item.premiumDiscount}</td>
                  <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                    <Button size={'sm'} background={item.status ? 'green' : 'red'} color={'white'}>{item.status ? 'Active' : 'Inactive'}</Button>
                  </td>
                  <td onClick={() =>handleEdit(item.id)} style={{ textAlign: 'left', padding: '8px', fontSize: '20px', fontFamily: 'Open Sans, sans-serif',cursor:'pointer'}}>
                    <FaRegEdit />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Sidebar>
  )
}

export default Deductibles;
