import { ALL_TICKET_GET_ADMIN } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"



export const SupportTicketAdminService = {
supporTicketAllAdminList() {
    return Api.GET(ALL_TICKET_GET_ADMIN).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
}
}