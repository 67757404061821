// RadioOption.js
import React from 'react';
import { Box } from '@chakra-ui/react';

const RadioOption = ({ value, isSelected, onChange, children,background }) => {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <Box
      as='label'
      cursor='pointer'
      borderWidth='1px'
      borderRadius='md'
      boxShadow='md'
      fontSize={['10px','12px','12px']}
      bg={isSelected ? (background ? background : 'teal') : 'white'}
      color={isSelected ? 'white' : 'gray.700'}
      borderColor={isSelected ? 'teal.600' : 'gray.300'}
      _hover={{
        bg: 'gray.100',
      }}
      onClick={handleChange}
      px={3}
      py={0}
    >
      {children}
    </Box>
  );
};

export default RadioOption;
