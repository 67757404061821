import { ALL_DEDUCTABLES, CREATE_DEDUCTABLES } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const allDeductiblesService = {
    allDeductables() {
        return Api.GET(ALL_DEDUCTABLES).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    createDeductibles( params) {
        return Api.POST(CREATE_DEDUCTABLES, params).then((response) => {
          console.log(response,'response')
          const { data, status } = response
          if (status === 200 || status === 201) {
            return { data, status }
          } else {
            const { data: { message } = {} } = response
            return { message, status }
          }
        })
      }
}