import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { primaryColor } from '../../../Utils/UniversalColor';
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { teamList } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { TiTick } from "react-icons/ti";
import { FaTimes } from 'react-icons/fa';



const AdvisorTeam = () => {
  const [teamData, setTeamData] = useState([])
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [colDefs, setColDefs] = useState([
    { field: "firstName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 200 },
    { field: "email", wrapHeaderText: true, headerClass: 'header', headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true,width:150 },
    { field: "phone", wrapHeaderText: true, headerClass: 'header', headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150 },
    { field: "commission", wrapHeaderText: true, headerClass: 'header', headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 100 },
    { field: "mgaAgaCommission", wrapHeaderText: true, headerClass: 'header', headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 150},
    { 
      field: "status", 
      headerName: "Status", 
      wrapHeaderText: true, 
      headerClass: 'header', 
      cellClass: 'cell-class', 
      autoHeight: true, 
      wrapText: true, 
      width: 100, 
      cellRenderer: (params) => {
        if (params.value === 1) {
          return (
            <Button size={'xs'} background={'#4caf50'} color={'white'}>
              <TiTick />
            </Button>
          );
        } else {
          return <i className="fas fa-times"></i>;
        }
      }
    },
    { field: "isApproved",
      headerName:"Apporved status",
       wrapHeaderText: true, 
       headerClass: 'header',
       cellClass: 'cell-class-name', 
       autoHeight: true, 
       wrapText: true, width: 300,
      cellRenderer:(params)=>{
        if(params.value ===1){
          return(
            <Button  size={'xs'} background={'#4caf50'} color={'white'}>
              <TiTick/>
            </Button>
          );

        }else{
          return <i className="fas fa-times"></i>
        }
      }
      },

  ]);
  const [rowData, setRowData] = useState([]);


  useEffect(() => {
    dispatch(teamList())
      .then((res) => {
        console.log("hhfhh", res);
        setTeamData(res)
        console.log("teamdara", res)
})
      .catch((error) => {
        console.error("Error fetching admin profile data:", error);
      });
  }, [dispatch]);




  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','), // Header row
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box>
      <div style={{ paddingTop: '80px', width: '80%', margin: 'auto', marginBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>Advisor List</h3>
        <Flex direction={['column','column','row']} justifyContent={'space-between'} mt={'15px'} mb={['15px','2px','2px']}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            {/* <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button> */}
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={teamData}
            columnDefs={colDefs}
            pagination={true}
            domLayout='autoHeight'
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>

      </div>
    </Box>
  )
}

export default AdvisorTeam
