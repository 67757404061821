import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import { pendingEndoresementList } from '../AdminDashboard/dependencies/action';
import { Flex, Box, Select, Button, space, Input } from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import { useDispatch } from 'react-redux';
import { adminCommisionList, adminCommisionStatus } from './dependencies/action';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';

const Commission = () => {
    const [rowData, setRowData] = useState([]);
    const [pendingEndData, setPendingEndData] = useState([])
    const dispatch = useDispatch()
    console.log("pendingE", pendingEndData)
    const pagination = true;
    const paginationPageSizeSelector = [10, 20, 30, 50, 1000];
    const [paymentTypes, setPaymentTypes] = useState({});
    const [selectedPaymentType, setSelectedPaymentType] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);


    useEffect(() => {
        dispatch(adminCommisionList())
            .then((res) => {
                setRowData(res)
                //   setPendingEndData(res);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [dispatch]);
    //   useEffect(() => {
    //   const slicedData = pendingEndData.slice(0, 10);
    //   setRowData(slicedData);
    //   }, [pendingE/ndData]);

    const downloadCellRenderer = (params) => {
        const handleDownload = () => {
            navigate('/admin/download-quote-documents')
            console.log('Download clicked for row:', params.data);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
            </div>
        )
    };

    const viewTranscations = (params) => {
        const handleView = () => {
            navigate(`/admin/quotePaymentTranscation/${params.data.quotationNo}`)
            console.log('Download clicked for row:', params.data);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                <Button onClick={handleView} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
            </div>
        )
    }

    const handleAction = (params) => {
        const handleChange = (value) => {

        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
                <select onChange={(e) => handleChange(e.target.value)}>
                    <option value="">Select</option>
                    <option value="edit">Edit Quote</option>
                    <option value="send">Send Quote</option>
                </select>
            </div>
        )
    }
    const [colDefs, setColDefs] = useState([
        { field: "userEntity.firstName", headerName: "Emp Name", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "userEntity.roles.name", headerName: "Emp Role", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "policyDetailsEntity.quote.product", headerName: "Product", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "policyDetailsEntity.quote.policyHolderName", headerName: "Customer Name", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "policyDetailsEntity.policyNo", headerName: "Policy No.", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "insuredName", headerName: "Insured Name.", headerClass: 'header', cellClass: 'cell-class', width: "130px" },
        { field: "type", headerName: "Type", headerClass: 'header', cellClass: 'cell-class', width: "70px" },
        { field: "policyDetailsEntity.quote.firstDateOfCover", headerName: "Issued At", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "policyDetailsEntity.quote.quoteAmount", headerName: "Premium/Correction Amt(CAD)", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "policyDetailsEntity.quote.user.commission", headerName: "Commision%", headerClass: 'header', cellClass: 'cell-class', width: "110px" },
        { field: "policyDetailsEntity.createdAt", headerName: "Payment Date", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        { field: "type", headerName: "Mode Of Payment", headerClass: 'header', cellClass: 'cell-class', width: "150px" },
        {
            field: "type",
            headerName: "Description",
            headerClass: 'header',
            cellClass: 'cell-class',
            width: "150px",
            cellRenderer: (params) => {
                const { data } = params;
                let description = "";
                switch (data.userEntity.roles.name) {
                    case "Advisor":
                        description = "Advisor Payment";
                        break;
                    case "AGA":
                        description = "AGA Payment";
                        break;
                    case "MGA":
                        description = "MGA Payment";
                        break;
                    default:
                        description = "Unknown Payment";
                        break;
                }
                return description;
            }
        },
        {
            field: "type",
            headerName: "Settled Status",
            headerClass: 'header',
            cellClass: 'cell-class',
            width: "150px",
            cellRenderer: (params) => {
                return <input type="checkbox" onClick={() => handleCheckboxClick(params)} />;
            }
        }

        // Add more columns as needed based on your response data
    ]);
    const handlePaymentTypeChange = (e) => {
        setPaymentTypes({ ...paymentTypes, [selectedRowId]: e.target.value });
    };

    const handleCheckboxClick = (params) => {
        setSelectedRowId(params.data.id);
    };

    const handlePayClick = () => {
        if (!selectedRowId) {
            alert('Please select a row and payment type');
            return;
        }

        const paymentType = paymentTypes[selectedRowId];
        if (!paymentType) {
            alert('Please select a payment type');
            return;
        }

        const payload = {
            commissionLedgerEntities: [{ id: selectedRowId }],
            paymentType: paymentType,
          };
      
          dispatch(adminCommisionStatus(payload))
            .then((response) => {
              console.log('Status updated successfully:', response);
            })
            .catch((error) => {
              console.error('Error updating status:', error);
            });
    };


    const onPageChanged = (page) => {
        setCurrentPage(page);
        const startIndex = (page - 1) * 10;
        const endIndex = Math.min(startIndex + 10, pendingEndData.length);
        setRowData(pendingEndData.slice(startIndex, endIndex));
    };


   
        
    
    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text('Admin Users', 30, 30);
        const tableColumn = ["Emp Name", "Emp Role", "Product", "Customer Name","Policy No.","Insured Name.","Type","Issued At","Premium/Correction Amt(CAD)","Commision%","Payment Date","header","Description"];
        const tableRows = [];
        rowData.forEach(user => {
          const userData = [
            `${user.userEntity.firstName} ${user.userEntity.lastName}`,
            user.userEntity.roles.name,
            user.policyDetailsEntity.quote.product,
            user.policyDetailsEntity.quote.policyHolderName,
            user.policyDetailsEntity.policyNo,
            user.insuredName,
            user.type,
            user.policyDetailsEntity.quote.firstDateOfCover,
            user.policyDetailsEntity.quote.quoteAmount,
            user.policyDetailsEntity.quote.user.commission,
            user.policyDetailsEntity.createdAt,
          ];
          tableRows.push(userData);
        });
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        doc.save('admin_users.pdf');
      };
    
    
      
    

    const downloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(rowData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
        XLSX.writeFile(workbook, 'quotations.xlsx');
    };

    const downloadCSV = () => {
        const csvContent = [
            Object.keys(rowData[0]).join(','), // Header row
            ...rowData.map(obj => Object.values(obj).map(val => "${val}").join(',')) // Data rows
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'quotations.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <Sidebar headingText={'Commission'} >
            <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
                <Flex gap={"20px"}>
                    <Box >
                        <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Roles</label> <br />
                        <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
                            <option value="">Advisors</option>
                            <option value="VTC">AGA</option>
                            <option value="VTC">MGA</option>
                            <option value="VTC">Customer</option>


                        </select>
                    </Box>
                    <Box>
                        <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
                        <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} >
                            <option value="">Select User</option>


                        </select>
                    </Box>
                    <Box>
                        <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>From Date</label> <br />
                        <Input type="date" style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} />


                    </Box>
                    <Box>
                        <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>To Date</label> <br />
                        <Input type="date" style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} />


                    </Box>

                    <Box mt={'20px'}>
                        <Button size={'sm'} background={'#9c27b0'} color='white'>Search</Button>
                    </Box>
                    <Box mt={'20px'} ml={'15px'}>
                        <Button size={'sm'} background={'#9c27b0'} color='white'>Reset</Button>
                    </Box>
                </Flex>
                <br />
            </div>
            <Box w={'95%'} m={'auto'} background={'white'} p={'10px'} mb={'30px'}>
                <Flex justifyContent={'space-between'} mt={'15px'}>
                    <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                    </div>
                    <div>
                        <label htmlFor="search">Search:</label>
                        <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                    </div>
                </Flex>
                <div
                    className="ag-theme-quartz"
                    style={{ width: '100%', overflow: 'hidden' }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        domLayout='autoHeight'
                        width='100%'
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        pagination={pagination}
                        paginationPageSize={10}
                        onGridReady={(params) => {
                            params.api.sizeColumnsToFit();
                        }}
                    />
                </div>

                <Box mt={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <label htmlFor="payment-type" style={{ marginRight: '10px', fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>
                        Payment Type:
                    </label>
                    <select
                        id="payment-type"
                        onChange={handlePaymentTypeChange}
                        value={paymentTypes[selectedRowId] || ''}
                        disabled={selectedRowId === null}
                        style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', fontSize: '12px' }}
                    >
                        <option value="">Select</option>
                        <option value="Cheque">Cheque</option>
                        <option value="Cash">Cash</option>
                        <option value="Bank">Bank Transfer</option>
                    </select>
                    <Button onClick={handlePayClick} background={'#4caf50'} color={'white'} size={'sm'} disabled={selectedRowId === null}>
                        Pay
                    </Button>
                </Box>
            </Box>
        </Sidebar>
    )
}

export default Commission