import React from 'react'
import Sidebar from '../../Sidebar'
import { useNavigate } from 'react-router-dom'
import { Box, Button } from '@chakra-ui/react'

const AdminPartners = () => {
    const navigate=useNavigate()

    const handleBack = () => {
        navigate(-1);
      }

return (
    <Sidebar headingText={'Partners'}>
        <Box w={'90%'} m={'auto'} mt={'20px'}>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
        </Box>
    </Sidebar>
  )
}

export default AdminPartners
