import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box,Flex,Tooltip } from '@chakra-ui/react';
import { FaRegEye } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import { allAGAGet, allAdvisorsGet } from '../AssignAga/dependencies/action';
import { useNavigate } from 'react-router-dom';



const AssignMga = () => {
  const [agaData, setAgaData] = useState([]);
  console.log("agaData",agaData)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch AGA data
    dispatch(allAGAGet())
        .then((res) => {
          setAgaData(res);
        })
        .catch((error) => {
            console.error("Error fetching data:", error);
        });
}, [dispatch]);
const handleViewClick = (data) => {
  navigate(`/admin/assign-mga/assing-mga-aga/${data}`);
};
return (
    <Sidebar headingText={'Assing MGA'}>
    <Box w={'90%'} m={'auto'} mt={'70px'}>
      <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <Box>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>AGA Name</th>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>MGA Name	</th>
                        <th style={{ padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Assign MGA</th>
                    </tr>
                </thead>
                <tbody>
              {agaData.map((data, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                  <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.firstName} {data.lastName} </td>
                  <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.parent ? data.parent.firstName : ''}</td>
                  <td style={{ padding: '10px', fontSize: '22px', fontFamily: 'Open Sans, sans-serif' }}>
                  <Flex onClick={() => handleViewClick(data.idUser)} color={'#4caf50'} justifyContent={'center'} alignItems='center'>
                      <FaRegEye />
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
                 </table>
        </Box>
        </Box>
      </Box>
    </Sidebar>
  )
}

export default AssignMga
