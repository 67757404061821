import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import Sidebar from '../../Sidebar';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { adminPolicesClaimList } from '../AdminAllPolicies/dependencies/action';
import { useDispatch } from 'react-redux';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const ClaimRequests = () => {
  const navigate = useNavigate();

  const [colDefs, setColDefs] = useState([
    { field: "policyNo", headerName: "Policy Number", wrapHeaderText: true, autoHeight: true, wrapText: true, width: 280, headerClass: 'header', cellClass: 'cell-class-name', },
    { field: "policyHolderAddress", headerName: "Address", wrapHeaderText: true, autoHeight: true, wrapText: true, headerClass: 'header', cellClass: 'cell-class-name', },
    { field: "incidentDate", headerName: "Incident Date", wrapHeaderText: true, autoHeight: true, wrapText: true, width: 150, headerClass: 'header', cellClass: 'cell-class-name', },
    { field: "phone", headerName: "Phone", wrapHeaderText: true, autoHeight: true, wrapText: true, width: 330, headerClass: 'header', cellClass: 'cell-class-name', },
    { field: "requestDate", headerName: "Request Date", wrapHeaderText: true, autoHeight: true, wrapText: true, width: 220, headerClass: 'header', cellClass: 'cell-class-name', },
    { field: "illnessDetails", headerName: "Illness Details", wrapHeaderText: true, autoHeight: true, wrapText: true, width: 300, headerClass: 'header', cellClass: 'cell-class-name', },
   
  ]);
  
  const [rowData, setRowData] = useState([]);
  console.log(rowData,"fdfdfdsff")
  const [productFilter, setProductFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [searchText, setSearchText] = useState("");
  const dispatch=useDispatch()

  // Mock data initialization (replace with actual data fetching logic)
  useEffect(() => {
    getAllData();
  }, [dispatch]);

  const getAllData = () => {
    dispatch(adminPolicesClaimList())
      .then(res => {
        setRowData(res.data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };
  

  // Function to filter data based on selected product and role
// Function to filter data based on search text
const filteredData = rowData.filter(item => {
  // Ensure item is not null or undefined before accessing its properties
  if (!item) return false;

  // Perform case-insensitive search across relevant fields
  const searchString = searchText.toLowerCase();
  return (
    (item.policyNo && item.policyNo.toLowerCase().includes(searchString)) ||
    (item.policyHolderAddress && item.policyHolderAddress.toLowerCase().includes(searchString)) ||
    (item.incidentDate && item.incidentDate.toLowerCase().includes(searchString)) ||
    (item.phone && item.phone.toLowerCase().includes(searchString)) ||
    (item.requestDate && item.requestDate.toLowerCase().includes(searchString)) ||
    (item.illnessDetails && item.illnessDetails.toLowerCase().includes(searchString))
  );
});


  // Download functions (unchanged)
  const downloadPDF = () => {
    const doc = new jsPDF();

    // Define columns and rows
    const columns = colDefs.map(col => col.headerName.toUpperCase());
    const rows = filteredData.map(row => colDefs.map(col => row[col.field]));

    // Add header and rows to the document
    doc.autoTable({
      head: [columns],
      body: rows,
      startY: 20, // Adjust position as needed
      theme: 'grid',
      margin: { top: 10 },
      styles: { overflow: 'linebreak', fontSize: 10, cellPadding: 2 },
    });

    doc.save('claim_requests.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Claim Requests');
    XLSX.writeFile(workbook, 'claim_requests.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(filteredData[0]).join(','), // Header row
      ...filteredData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'claim_requests.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Sidebar headingText={'Claim Requests'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          {/* <Box>
            <label htmlFor="product-select">Select Product</label> <br />
            <select
              value={productFilter}
              onChange={(e) => setProductFilter(e.target.value)}
            >
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box> */}
         
          {/* <Box>
            <label htmlFor="search">Search:</label><br />
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search by name"
            />
          </Box> */}
        </Flex>
        <br />
      </div>

      <Box w={'95%'} m={'auto'} background={'white'} p={'10px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
            <Button size='sm' background={'#eeeeee'} onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' background={'#eeeeee'} onClick={downloadExcel}>Excel</Button>
            <Button size='sm' background={'#eeeeee'} onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            pagination={true}
            paginationPageSize={5} // Adjust as needed
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </Box>
    </Sidebar>
  )
}

export default ClaimRequests;
