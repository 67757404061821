import React from 'react'
import {FormControl,ChakraProvider,Input,FormLabel,extendTheme} from '@chakra-ui/react'


const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)"
  };
  export const theme = extendTheme({
    components: {
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles
                }
              },
              "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
                ...activeLabelStyles
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: "absolute",
                backgroundColor: "white",
                pointerEvents: "none",
                mx: 3,
                px: 1,
                my: 1,
                transformOrigin: "left top"
              },
              Input: {
                border:'1px solid black',
              }
            }
          }
        }
      }
    }
  });




const CustomInput = ({ onChange }) => {
    const handleChange = (e) => {
      // Call the onChange function passed from the parent component with the input value
      onChange(e.target.value);
    };
return (
  
  <ChakraProvider theme={theme}>
  <FormControl variant="floating" id="search">
    <Input
      borderRadius={5}
      size={'sm'}
      background={'white'}
      placeholder=" "
      onChange={handleChange} // Call handleChange function on input change
    />
    <FormLabel size={'sm'}>Search</FormLabel>
  </FormControl>
</ChakraProvider>
  )
}

export default CustomInput
{/* <ChakraProvider >
      <FormControl variant="floating" id="search">
          <Input borderRadius={5} size={'sm'} background={'white'} placeholder=" " />
          <FormLabel size={'sm'}>Search</FormLabel>
        </FormControl>
        </ChakraProvider> */}