import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Text,useTheme } from '@chakra-ui/react'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor } from '../../../../Utils/UniversalColor'
import { FaArrowRight } from 'react-icons/fa'

const QuotationHelp = () => {
    const theme = useTheme();
    const navigate=useNavigate()





    const openWhatsApp = (number) => {
        const formattedNumber = number.replace(/\D/g, '');
        const url = `https://wa.me/${formattedNumber}`;
        window.open(url, '_blank');
      };

    const handleCallback =()=>{
        navigate('/enquiry')
        alert("Please fill out the form below. Our support team will give you a call.")
      }


return (
    <Box pb={'120px'}>
            <Box pt={'80px'} background={'rgb(247, 247, 247)'}>
                        <Box w={'90%'} m={'auto'} pb={'10px'}>
                            <Link to='/'>
                                <Text color={'blue'} fontFamily={'poppins'}>Home</Text>
                            </Link>
                            <Flex justifyContent={'space-between'}>
                                <Text fontSize={'26px'}>Getting Started</Text>
                            </Flex>
                        </Box>
                    </Box>
            <hr />

    <Box w={'90%'} m={'auto'} mt={'45px'}>
        <Flex justifyContent={'space-between'}>
        <Box w={'22%'} mt={['30px','30px','0px']} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} p={'20px'} color={'black'} borderRadius={'20px'} >
                <Text fontSize={'16px'} fontFamily={'sans-serif'}>Need Help?</Text>
                <Text>Choose how you like to connect with us.</Text>
                <Flex cursor={'pointer'} onClick={handleCallback} _hover={{background:'#f1f1f1'}} fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-between'} p={'10px'} pt={'20px'}>
                <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                    <Text>Request a call back</Text>
                    <Flex color={theme.button.buttonBackgroundColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
                    </Flex>
                <Flex _hover={{background:'#f1f1f1'}} cursor={'pointer'} fontFamily={'Assistant,sans-serif'} borderBottom={'1px solid rgba(42,63,85,.26)'} justifyContent={'space-between'} p={'10px'} pt={'15px'} onClick={() => openWhatsApp(theme.content.contact.phone)}>
                <Flex><Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg'/></Flex>
                    <Text>Chat With Us</Text>
                    <Flex color={theme.button.buttonBackgroundColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
                    </Flex>
                  <Flex _hover={{background:'#f1f1f1'}} fontFamily={'Assistant, sans-serif'} borderBottom={'1px solid rgba(42, 63, 85, .26)'} justifyContent={'space-between'} p={'10px'} pt={'15px'} onClick={() => openWhatsApp(theme.content.contact.phone)} cursor="pointer">
                <Flex>
                    <Image w={'30px'} src='https://quickinsure.co.in/static/images/individual.svg' />
                </Flex>
                <Box>
                    <Text fontSize={'12px'}>Connect on WhatsApp</Text>
                    <Text>{theme.content.contact.phone}</Text>
                </Box>
                <Flex fontSize={'20px'} color={theme.button.buttonBackgroundColor} justifyContent={'center'} alignItems={'center'}>
                    <FaArrowRight />
                </Flex>
            </Flex>
            </Box>

        <Box w={'75%'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' background={'white'} p={'20px'}>
        <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box fontWeight={'600'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'} as='span' flex='1' textAlign='left'>
                    How do I create an account on TravelMedicare?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel fontSize={'12px'} pb={4}>
                To create an account on TravelMedicare, go to the homepage and click on the "Sign Up" button. Fill in your personal details, create a password, and follow the prompts to complete the registration process. Once done, you'll receive a confirmation email.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box fontWeight={'600'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'} as='span' flex='1' textAlign='left'>
                    How do I reset my password on TravelMedicare?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel fontSize={'12px'} pb={4}>
                To reset your password, click on the "Forgot Password?" link on the login page. Enter your registered email address, and we'll send you a link to reset your password. Follow the instructions in the email to create a new password.
                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box fontWeight={'600'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'} as='span' flex='1' textAlign='left'>
                    How can I update my profile information on TravelMedicare?
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel fontSize={'12px'} pb={4}>
                To update your profile information, log in to your account and go to the "Profile" section. Here, you can edit your personal details, contact information, and preferences. Save your changes to update your profile.                </AccordionPanel>
            </AccordionItem>


            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box fontWeight={'600'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'} as='span' flex='1' textAlign='left'>
                    How do I purchase an insurance policy on TravelMedicare?                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel fontSize={'12px'} pb={4}>
                To purchase an insurance policy, log in to your account and navigate to the "Insurance Plans" section. Choose the plan that best suits your needs, provide the necessary details, and complete the payment process. You will receive a confirmation email with your policy details.                </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
                <h2>
                <AccordionButton>
                    <Box fontWeight={'600'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'} as='span' flex='1' textAlign='left'>
                    How can I cancel my insurance policy on TravelMedicare?                    </Box>
                    <AccordionIcon />
                </AccordionButton>
                </h2>
                <AccordionPanel fontSize={'12px'} pb={4}>
                To cancel your insurance policy, log in to your account and go to the "My Policies" section. Select the policy you wish to cancel and follow the instructions to submit a cancellation request. Our support team will process your request and notify you of the cancellation status.                </AccordionPanel>
            </AccordionItem>
            </Accordion>


        </Box>
        </Flex>
    </Box>


    </Box>
  )
}

export default QuotationHelp
