import { toast } from "react-toastify";
import { ADMIN_USER_GET_LIST, ASSING_MENUS_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminUserService } from "./service";

export const adminUserList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminUserService.adminUserList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_USER_GET_LIST,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const assingMenus = ( params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminSatatus = await AdminUserService.assingMenus( params);
    const { status, message, data } = adminSatatus
  
    if (status === 201 || status === 200) {
      dispatch({
        type: ASSING_MENUS_TYPE,
        payload: message, data
      })
      toast.success(" Successfully")
    } else {
      toast.success(message)
    }
  
    dispatch(isLoading(false));
  
    return adminSatatus
  }