import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Select, Text } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import { allMgaGet, assingAgaToMga } from '../dependencies/action';
import { useDispatch } from 'react-redux';

const AssingMgaToAga   = () => {
    const [selectedIdUser, setSelectedIdUser] = useState('');
    const [mgaData,setMgaData]=useState([]);
    console.log("mgaData",mgaData)
    const dispatch = useDispatch();
    const { id } = useParams();
    console.log("id",id)

    useEffect(() => {
        // Fetch AGA data
        dispatch(allMgaGet())
            .then((res) => {
                setMgaData(res);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [dispatch]);
    
    const handleUpdateClick = async () => {
        if (!selectedIdUser) {
            console.error("Please select an AGA");
            return;
        }
    
        const selectedAga = mgaData.find(elem => elem.idUser === parseInt(selectedIdUser, 10));
        console.log("selectedAga",selectedAga)
        if (!selectedAga) {
            console.error("Selected AGA not found in agaData");
            return;
        }
    
        const dataToSend = {
            idUser: id,
            parentId: selectedAga.idUser // Use the selected AGA's idUser as parentId
        };
    
        try {
            await dispatch(assingAgaToMga(dataToSend));
            console.log("Successfully assigned AGA to Advisor");
            // Redirect after successful assignment
            // redirectTo('/path/to/redirect');
        } catch (error) {
            console.error("Error assigning AGA to Advisor:", error);
            // Show error notification
        }
    };

    const redirectTo = (path) => {
        // Perform redirect
        window.location.href = path;
    };

    const handleSelectChange = (event) => {
        setSelectedIdUser(event.target.value);
    };

  return (
    <div>
      <Sidebar headingText={'Assing AGA To MGA'}>
      <Box>
                    <Box w={"60%"} background={'white'} marginLeft={"20px"} padding={'50px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
                        <Text mb={'8px'}>Select MGA</Text>
                        <Select onChange={handleSelectChange} value={selectedIdUser}>
                            <option value="">Select</option>
                            {mgaData.map(elem => (
                                <option key={elem.idUser} value={elem.idUser}>
                                    {elem.firstName} {elem.lastName}
                                </option>
                            ))}
                        </Select>
                        <br />
                        <Button onClick={handleUpdateClick} marginTop={'20px'}>Update</Button>
                    </Box>
                </Box>
      </Sidebar>
    </div>
  )
}

export default AssingMgaToAga  
