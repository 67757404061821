import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { adminPagesById, adminPagesEdit } from './dependencies/action';
import { toast } from 'react-toastify';

const ContentEditorModal = ({ onClose, initialContent,pageData,id}) => {
  console.log("id",id)
  const [data,setData]=useState([]);
  
  const [editorState, setEditorState] = useState({});
  const dispatch = useDispatch();
 
 


useEffect(() => {
  if (id) {
      dispatch(adminPagesById(id))
          .then(response => {
              setData(response.data); 
              setEditorState(response.data.pageText)
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
  }
}, [dispatch, id]);



  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', 'image'],
      ['clean'],
      ['color', 'background'],
      [{ 'align': [] }], 
      [{ 'script': 'sub'}, { 'script': 'super' }], 
      [{ 'indent': '-1'}, { 'indent': '+1' }], 
      [{ 'blockquote': '' }, { 'code-block': 'code' }], 
      [{ 'font': [] }], 
      [{ 'size': ['small', false, 'large', 'huge'] }] 
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image',
    'color', 'background', 'align', 'script',
    'indent', 'blockquote', 'code-block',
    'font', 'size'
  ];
  

  const handleEditorChange = (content) => {
    setEditorState(content);
  };

  const handleSave = async () => {
    try {
      const response = await dispatch(adminPagesEdit(id, { pageText: editorState })); 
      if (response.status === 200) {
        // toast.success("Page successfully updated");
        onClose(); 
      } else {
        toast.error("Failed to update page");
      }
    } catch (error) {
      console.error('Error updating page:', error);
      toast.error("Failed to update page");
    }
  };

 
 



  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <button className="close-button" onClick={onClose}><FaTimes /></button>
        </div>
      

        <div className="modal-content">
          <ReactQuill
            value={editorState}
            onChange={handleEditorChange}
            placeholder="Start typing here..."
            modules={modules}
            formats={formats}
            style={{ width: '100%', height: '400px' }}
          />
          
        </div>
       
      </div>
      <button style={{border:"2px solid blue"}} onClick={handleSave}>Save</button>
      <style>
        {`
          .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
          }

          .modal {
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            width: 60%;
             /* Adjust the width of the modal content */
          }

          .modal-header {
            display: flex;
            justify-content: flex-end;
          }

          .close-button {
            background: none;
            border: none;
            cursor: pointer;
          }

          .modal-content {
            margin-top: 10px;
          }
        `}
      </style>
    </div>
  );
};

export default ContentEditorModal;
