import React from 'react'
import Sidebar from '../../Sidebar'

const AccountPayableReport = () => {


return (
    <Sidebar headingText={'Account Payable Report'}>
      
    </Sidebar>
  )
}

export default AccountPayableReport
