import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import {Flex, Box,Select, Tooltip, Button} from '@chakra-ui/react'
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../Utils/UniversalColor';
import CustomInput from '../../../Utils/Input/Index';
import { useNavigate } from 'react-router-dom';
import {advisorGetQuotes} from '../../Advisor/Dependencies/action'
import Api from '../../../dependencies/utils/Api';
import { FaDownload } from 'react-icons/fa6';
import { API_URL, CANCEL_QUOTE, QUOTATION_DOCUMENT_DOWNLOAD, SEND_QUOTE } from '../../../constants/ApplicationUrl';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const MyQuotation = () => {
  const {token}=JSON.parse(localStorage.getItem('code_dev'))
  const pagination = true;
  const dispatch=useDispatch()
  const paginationPageSizeSelector = [10,20,50,100];
  const [quotesData,setQuotesData]=useState([])
  const [colDefs, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const navigate=useNavigate()
  const gridRef = useRef(null);


  const handleChange = (e, props) => {
    const { data } = props;
    if(e.target.value==='view'){
      navigate(`/customer-view-quote/${data.quotationNo}`)
    }
    if(e.target.value==='send'){
      Api.POST(`${SEND_QUOTE}${data.quotationNo}`).then((res)=>{
        if(res.status===200){
          alert('Quote has been sent succesfully')
        }else{
          alert("Something went wrong.")
        }
      })
    }
    if(e.target.value==='edit'){
      navigate(`/customer-edit-quote/${data.quotationNo}`);
    }
    if(e.target.value==='cancel'){
      var result = confirm("Are you sure you want to delete?");
      if(result){
        console.log(data.quotationNo)
        Api.DELETE(`${CANCEL_QUOTE}${data.quotationNo}`).then((res)=>{
          console.log(res)
          alert("Quote has been cancelled succeffuly")
          getAllQuotes()
        })
      }
    }
  };

  const downloadCellRenderer = (params) => {
    const { data } = params;
    const handleDownload = async() => {
      try {
        const response = await fetch(`${QUOTATION_DOCUMENT_DOWNLOAD}/${data.quotationNo}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, `${data.quotationNo}.pdf`);
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <FaDownload color={buttonBackgroundColor} onClick={handleDownload} style={{ cursor: 'pointer' }} />
      </div>
    );
  };

  useEffect(() => {
    getAllQuotes()
    setColDefs([
      { field: "product",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:150,filter:true},
      { field: "quotationNo",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:150,filter:true },
      { field: "dateOfQuote",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:150,filter:true },
      { field: "tripType",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:150,filter:true},
      { field: "customerName",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:150,filter:true},
      { field: "amount",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:100,filter:true},
      { field: "download",headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,cellRenderer: downloadCellRenderer,width:100,wrapHeaderText: true},
      { field: "Action",
       cellRenderer: props => {
        return <>
        <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
         <Select w={'120px'} onChange={(e) => handleChange(e, props)} size={'xs'} style={{backgroundColor:'#96ffff',border:'1px solid black'}}>     
          <option value="">Select</option>
          <option value="view">View Quote</option>
          <option value="send">Send Quote</option>
          <option value="edit">Edit Quote</option>
          <option value="cancel">Cancel Quote</option>
          </Select>
        </Tooltip>
        </>;
    } }
    ]);
  }, []);

  const getAllQuotes = () => {
    dispatch(advisorGetQuotes()).then((res) => {
      if (res.status === 200) {
        if (Array.isArray(res.data)) {
          setQuotesData(res.data);
          const formattedData = res.data.map((quote, index) => ({
            id: index + 1,
            // employeeName: 'Ravi Sharma',
            // designation: 'Advisor',
            product: quote.product,
            quotationNo: quote.quotationNo,
            dateOfQuote: quote.createdAt,
            tripType: quote.tripType,
            customerName: quote.policyHolderName,
            amount: `$ ${quote.quoteAmount}`,
          }));
          setRowData(formattedData);
        } else {
          console.error('Response data is not an array:', res.data);
        }
      }
    });
  };
  
  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumnNames = Object.keys(rowData[0]);
    // const tableColumnNames = Object.keys(rowData[0]).map(column => column.toUpperCase()); // Assuming rowData has at least one item
    const tableRows = rowData.map((row) => Object.values(row));

    doc.autoTable({
      head: [tableColumnNames],
      body: tableRows,
      theme: 'grid',
      styles: {
        font: 'helvetica',
        fontStyle: 'bold',
        fontSize: 10,
        cellPadding: 4,
        overflow: 'linebreak',
      },
      columnStyles: {
        // Specify column styles if needed
      },
    });

    doc.save('quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','), // Header row
      ...rowData.map((obj) => Object.values(obj).map((val) => `"${val}"`).join(',')), // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const actionCellRenderer = (params) => {
      const handleActionChange = (action) => {
        console.log('Selected Action:', action);
      };
  
      return (
        <select onChange={(e) => handleActionChange(e.target.value)}>
          <option value="view">View Quote</option>
          <option value="view">Send Quote</option>
          <option value="edit">Edit Quote</option>
          <option value="editTrip">Edit Trip Details</option>
          <option value="cancel">Cancel Quote</option>
        </select>
      );
  };

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Box background={'#f6f6f6'} h={'auto'} pb={'30px'}>
      <Box w={['90%','90%','80%']} m={'auto'} style={{ paddingTop: '120px', marginBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Quotes</h3>
        <Flex direction={['column','column','row']} mb={['10px','0px','0px']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
          <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'sm'} leftIcon={<FaRegFilePdf />} style={{ color: 'white', backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button leftIcon={<SiMicrosoftexcel />} size={'sm'} style={{ color: buttonColor, backgroundColor:buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
            <Button size={'sm'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip>
          </div>
          <div>
            {/* <label htmlFor="search">Search:</label> */}
              {/* <CustomInput/> */}
            {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
          </div>
        </Flex>
        {rowData.length > 0 ? (
          <div className='ag-theme-quartz' style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              paginationPageSize={10}
            domLayout='autoHeight'
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}            
            />
          </div>
        ) : (
          <div className='ag-theme-quartz' style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            // frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
        )}

      </Box>
    </Box>
  );
};

export default MyQuotation;