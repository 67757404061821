import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { buttonBackgroundColor, buttonColor, headingsColor } from '../../../Utils/UniversalColor'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import StudentTravel from '../index'

const StudentPolicyWording = () => {


return (
    <Box>
      <StudentTravel/>
      <Box w={'70%'} m={'auto'} mb={'40px'} p={'20px'}>
        <Box w={'80%'} m={'auto'} textAlign={'center'}>
      <Text pt={'25px'} fontSize={'22px'} fontFamily={'sans-serif'} color={headingsColor}>Visitor To Canada Travel Insurance</Text>
        <Button mt={'15px'} borderRadius={'20px'} color={buttonColor} backgroundColor={buttonBackgroundColor}>Download Document</Button>
        <Text fontWeight={'600'} fontSize={'12px'} mt={'15px'} fontFamily={'poppins'}>In the event of an emergency or if you require medical treatment you must contact the Assistance Company immediately at:</Text>
        <Flex fontWeight={'600'} justifyContent={'space-around'} mt={'15px'} fontSize={'12px'}> 
            <ul style={{ listStyleType: 'none' }}>
                <li style={{ paddingBottom:'15px'}}>1-844-879-8379</li>
                <li>toll-free from Canada and the USA</li>
            </ul>
            <ul style={{ listStyleType: 'none' }}>
                <li style={{ paddingBottom:'15px'}}>1-844-879-8379</li>
                <li>toll-free from Canada and the USA</li>
            </ul>
        </Flex>
        <Text fontSize={'14px'} fontWeight={'600'} mt={'15px'} textAlign={'center'}>email: assist@epicamericas.com</Text>
        <Text fontFamily={'poppins'} mt={'15px'} fontSize={'13px'}>It is your responsibility to ensure that the Assistance Company has been contacted prior to receiving treatment. Your benefits will be limited to 80% of eligible expenses to a maximum of $25,000 if you fail to do so, other than in extreme circumstances when treatment is required to resolve a life threatening medical crisis.</Text>
        </Box>
        <Accordion w={'80%'} m={'auto'} mt={'40px'} color={'black'} allowMultiple fontFamily={'NeueMontreal, sans-serif'}>
            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                        Important Notice - Please read carefully
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Flex>
                        <Text>Travel insurance is designed to cover losses arising from sudden and unforeseeable circumstances. It is important that you read and understand your policy as your coverage may be subject to certain limitations or exclusions.
                        Your policy may not cover medical conditions and/or symptoms that existed prior to your trip. Check to see how this applies in your policy and how it relates to your effective date.
                        In the event of an accident, injury or sickness, your prior medical history will be reviewed when a claim is reported.</Text>
                        <Text>Costs incurred in your country of origin are not covered
                        Your policy provides travel assistance; you are required to notify the Assistance Company prior to medical treatment. Your policy may limitbenefits should you not contact the Assistance Company before seeking medical treatment.
                        Please take the time to read this policy to ensure that it meets your needs and contact your agent if you have any questions. You may cancel this policy within 10 days of the purchase date for a full refund provided it is before the effective date. Other refunds available are described under Refunds in the General Provisions section of this policy</Text>
                    </Flex>
                    <Text textAlign={'center'} mt={'10px'} >THIS POLICY CONTAINS A CLAUSE WHICH MAY LIMIT THE AMOUNT PAYABLE.</Text>
                  <Text textAlign={'center'}> <b>NOTE:</b> Italicized words are defined terms whose definition appears in the definitions section of the policy. </Text>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                       Eligibility
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Text pb={'10px'} fontWeight={'600'}>To be eligible for coverage, on the effective date, you must:</Text>
                    <ol>
                        <li>be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</li>
                        <li>be at least 15 days of age and less than 90 years of age (less than 70 year of age for Premium plan); and</li>
                        <li> not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</li>
                        <li>not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</li>
                        <li>not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</li>
                    </ol>
                    
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                       Insuring Agreement
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Text pb={'10px'} fontWeight={'600'}>To be eligible for coverage, on the effective date, you must:</Text>
                    <ol>
                        <li>be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</li>
                        <li>be at least 15 days of age and less than 90 years of age (less than 70 year of age for Premium plan); and</li>
                        <li> not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</li>
                        <li>not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</li>
                        <li>not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</li>
                    </ol>
                    
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                       Duration of Coverage
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Text pb={'10px'} fontWeight={'600'}>To be eligible for coverage, on the effective date, you must:</Text>
                    <ol>
                        <li>be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</li>
                        <li>be at least 15 days of age and less than 90 years of age (less than 70 year of age for Premium plan); and</li>
                        <li> not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</li>
                        <li>not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</li>
                        <li>not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</li>
                    </ol>
                    
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                        Benefits
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Text pb={'10px'} fontWeight={'600'}>To be eligible for coverage, on the effective date, you must:</Text>
                    <ol>
                        <li>be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</li>
                        <li>be at least 15 days of age and less than 90 years of age (less than 70 year of age for Premium plan); and</li>
                        <li> not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</li>
                        <li>not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</li>
                        <li>not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</li>
                    </ol>
                    
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem padding={'5px'} mb={'10px'} borderBottom={'1px solid rgba(255, 255, 255, 0.2)'} border='1px solid grey' background={'whitesmoke'} _hover={{
                      borderColor: 'rgba(255, 255, 255, 0.2)',
                      bg:'whitesmoke',
                      transition:'border-color 0.2s ease',
                      borderRadius:'10px'
                    }}
                    _expanded={{
                      bg: 'red.500', // Change background color when item is expanded
                      borderRadius: '30px',
                      borderColor:'rgba(255, 255, 255, 0.2)'
                    }}>
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton>
                      <Box as="span" flex='1' textAlign='left' fontSize={'14px'}>
                       Exclusions
                    </Box>
                      {isExpanded ? (
                        <MinusIcon fontSize='12px' />
                      ) : (
                        <AddIcon fontSize='12px' />
                      )}
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} fontSize={'12px'} lineHeight={'22px'}>
                    <Text pb={'10px'} fontWeight={'600'}>To be eligible for coverage, on the effective date, you must:</Text>
                    <ol>
                        <li>be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</li>
                        <li>be at least 15 days of age and less than 90 years of age (less than 70 year of age for Premium plan); and</li>
                        <li> not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</li>
                        <li>not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</li>
                        <li>not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</li>
                    </ol>
                    
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
      </Box>
    </Box>
  )
}

export default StudentPolicyWording
