import { Box, Button, Text } from '@chakra-ui/react'
import React from 'react'
import SuperVisa from '../index'
import { buttonBackgroundColor, buttonColor, headingsColor } from '../../../Utils/UniversalColor'

const ClaimProcedure = () => {



return (
    <Box>
      <SuperVisa/>
      <Box w={['95%','70%','70%']} m={'auto'} mb={'40px'} pb={'40px'}>
        <Box w={['95%','80%','80%']} m={'auto'} textAlign={'center'}>
      <Text pt={'25px'} fontSize={'22px'} fontFamily={'sans-serif'} color={headingsColor}>Travel Medicare Claim Procedures</Text>
        <Text fontSize={'12px'} mt={'15px'} fontFamily={'poppins'}>In the event of an emergency or if you require medical treatment you must contact the Assistance Company immediately at:</Text>
        <Box border={'1px solid grey'} p={'10px'} mt={'25px'} mb={'25px'}>
            <Text fontSize={'22px'} fontFamily={'poppins'} fontWeight={'400'}>Epic Health Solutions</Text>
            <Text mt={'10px'} fontFamily={'Assistant'}>1-844-879-8479 toll-free from Canada and the USA +1-416-285-1722</Text>
            <Text fontFamily={'Assistant'} mt={'15px'}>e-mail: assist@epicamericas.com</Text>
        </Box>
        <Text fontSize={'14px'} textAlign={'left'}>It is your responsibility to ensure that the Assistance Company has been contacted prior to receiving treatment. Your benefits will be limited to 80% of eligible expenses to a maximum of $25,000 if you fail to do so, other than in extreme circumstances when treatment is required to resolve a life threatening medical crisis.</Text>
        <Text textAlign={'left'} mt={'15px'} fontWeight={'600'} fontFamily={'poppins'} color={headingsColor}>IMPORTATN INFORMATION</Text>
        <ul>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Contact the Assistance Company, Epic Health Solutions, at the numbers Above prior to receiving treatment, so there is no limitation applied to your potential claim payment. -Travel Medicare and Berkley Travel will not contact you to follow up on an open claim. If you have questions on your open claim, contact the Assistance Company at the numbers above.</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Please keep all hard copies of your medical records, receipts, and prescriptions given to you by your attending physician. The Assistance company may require additional information to review the eligiblity of your claim.</li> <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>The plan type purchased and the sum insured selected cannot be changed after the effective date indicated on your confirmation of insurance.</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Contact the Assistance Company, Epic Health Solutions, at the numbers Above prior to receiving treatment, so there is no limitation applied to your potential claim payment. -Travel Medicare and Berkley Travel will not contact you to follow up on an open claim. If you have questions on your open claim, contact the Assistance Company at the numbers above.</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Claims for loss or damage to hearing devices, eyeglasses, sunglasses, or prosthetic teeth, limbs or devices and resulting prescription thereof will be denied</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Travel insurance is designed to cover losses arising from sudden and foreseeable circumstances. Any claim for medical treatment that is not emergency medical treatment for the immediate relief of acute pain and suffering, including elective and cosmetic treatment, will be denied.</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>A claim for any medical treatment of an ongoing condition, regular care of a chronic condition, home health care, investigating testing, rehabilitation, convalescent, or ongoing care or medical treatment of an acute sickness or injury after the initial emergency has ended will be denied.</li>
            <li style={{fontSize:'14px',marginTop:'10px',textAlign:'left'}}>Most claims will be handled within 10 days of the Assistance Company receiving all required documentation. Missing or incomplete documentation is the most common cause of delays.</li>
        </ul>
        </Box>
      </Box>
    </Box>
  )
}

export default ClaimProcedure
