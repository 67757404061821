import React, { useEffect, useState } from 'react';
import { primaryColor } from '../../../../Utils/UniversalColor';
import { Flex, Box, Text, Input, Textarea, Toast, Button, Checkbox, Table, Thead, Tr, Th, Tbody, Td, Select } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import {  agaMgaAll, allRolesList, questionsandassingingEdit } from '../dependencies/action';
import { isLoading } from '../../../../constants/actionConstants';
import { advisorLicneseDetailsEdit, advisorPersonalDetails, advisorProfile, advisorRefrence} from "../../../Advisor/Dependencies/action"
import { toast } from 'react-toastify';
import axios from 'axios';
import Sidebar from '../../Sidebar';
import { useParams } from 'react-router-dom';
import { ADVISOR_CORPORATION_DETAILS, ADVISOR_LICENCE_DETAILS } from '../../../../constants/ApplicationUrl';
import { validateMobileNumber } from '../../../Advisor/AdvisorQuote/Validation';
import Api from '../../../../dependencies/utils/Api';

const EditMgaAgaAdvisor = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [referenceData, setReferenceData] = useState();
  const [questionData,setQuestionData]=useState()
  const [commonData, setCommonData] = useState([])
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = params;
 
useEffect(() => {
  dispatch(agaMgaAll())
    .then((res) => {
      const filteredData = res.find(item => item.idUser === parseInt(id));
      if (filteredData) {
        setCommonData([filteredData]);
        setFormData({
          id: id, 
          firstName: filteredData.firstName || '',
          lastName: filteredData.lastName || '',
          email: filteredData.email || '',
          phone: filteredData.phone || '',
          address: filteredData.address || '',
          website: filteredData.website || ""
        });
      }
    })
    .catch((error) => {
      console.error("Error fetching advisor profile data:", error);
    });
}, [dispatch, id]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveData = (data) => {
    switch (activeTab) {
      case 'personal':
        setPersonalData(data);
        break;
      case 'license':
        setLicenseData(data);
        break;
      case 'corporation':
        setCorporationData(data);
        break;
      case 'reference':
        setReferenceData(data);
        break;
        case 'question':
          setQuestionData(data);
          break;
      default:
        break;
    }
  };


  const renderForm = () => {
    switch (activeTab) {
      case 'personal':
        return <PersonalDetailsForm activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'license':
        return <LicenseDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'corporation':
        return <CorporationDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
      case 'reference':
        return <ReferenceDetailsForm commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />;
        case "question":
          return <Question commonData={commonData} activeTab={activeTab} setActiveTab={setActiveTab} onSave={handleSaveData} />
      default:
        return null;
    }
  };

  return (
    <>
      <Sidebar>
      <div style={{ padding: "10px" }}>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <br />
            <Box w={'80%'} m={'auto'}>
            <Flex className="tablist" gap={'40px'} pb={'20px'}>
              <button style={{fontSize:'14px', fontFamily:'Open Sans,sans-serif', width: "150px",padding: '5px 0px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: activeTab === 'personal' ? "#23b5b7" : "#ffffff", color: activeTab === 'personal' ? '#ffffff' : 'black',  borderRadius: '10px', border: 'none', cursor: 'pointer', }} onClick={() => setActiveTab('personal')}>1. Personal Details</button>

              <button style={{fontSize:'14px', fontFamily:'Open Sans,sans-serif', width: "150px",padding: '5px 0px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: activeTab === 'license' ? "#23b5b7" : "#ffffff", color: activeTab === 'license' ? '#ffffff' : 'black', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('license')}>2. License Details</button>

              <button style={{fontSize:'14px', fontFamily:'Open Sans,sans-serif', width: "150px",padding: '5px 0px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: activeTab === 'corporation' ? "#23b5b7" : "#ffffff", color: activeTab === 'corporation' ? '#ffffff' : 'black', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('corporation')}>3. Corporation Details</button>
              </Flex>
              <Flex className="tablist" gap={'40px'} pb={'50px'}>
              <button style={{fontSize:'14px', fontFamily:'Open Sans,sans-serif', width: "150px",padding: '5px 0px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: activeTab === 'reference' ? "#23b5b7" : "#ffffff", color: activeTab === 'reference' ? '#ffffff' : 'black', padding: '5px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('reference')}>4. Reference Details</button>
              <button style={{fontSize:'14px', fontFamily:'Open Sans,sans-serif', width: "150px",padding: '5px 0px', boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: activeTab === 'question' ? "#23b5b7" : "#ffffff", color: activeTab === 'question' ? '#ffffff' : 'black', padding: '5px 0px', borderRadius: '10px', border: 'none', cursor: 'pointer' }} onClick={() => setActiveTab('question')}>5. Question</button>
              </Flex>
              </Box>
          </div>
        </div>
        <div style={{ flex: 2 }}>
          {renderForm()}
          <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
        </div>
      </div>
      </Sidebar>
    </>
  );
};

const Tab = ({ active, onClick, children }) => {
  return (
    <button className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'personal':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'license':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'corporation':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    case 'reference':
      return <pre>{JSON.stringify(data.referenceData, null, 2)}</pre>;
      case 'question':
      return <pre>{JSON.stringify(data.referenceData, null, 2)}</pre>;
    default:
      return null;
  }
};

const PersonalDetailsForm = ({ onSave, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { token} = JSON.parse(localStorage.getItem("code_dev"))
  const [profileData, setProfileData] = useState([]);
  const params = useParams();
  const { id } = params;
  const [formData, setFormData] = useState({
    id: id,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: "",
    fax: "",
  });
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    website: '',
    fax: '',
  });

  
  useEffect(() => {
    dispatch(agaMgaAll())
      .then((res) => {
        const filteredData = res.find(item => item.idUser === parseInt(id));
        if (filteredData) {
          setProfileData([filteredData]);
          setFormData({
            id: id,
            firstName: filteredData.firstName || '',
            lastName: filteredData.lastName || '',
            email: filteredData.email || '',
            phone: filteredData.phone || '',
            address: filteredData.address || '',
            website: filteredData.website || ""
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);
      });
  }, [dispatch, id]);

  const handleSave = () => {
    const hasErrors = Object.values(errors).some((error) => error);
    if (hasErrors) {
      console.error("Form has errors. Cannot save.");
      return;
    }
    if (formData.phone.length === 0 || !validateMobileNumber(formData.phone)) {
      alert('Please enter valid Phone');
      return;
    }
    if(!formData.phone){
      alert('Please enter phone')
      return;
    }
    dispatch(advisorPersonalDetails(formData))
      .then((res) => {
        console.log("Advisor personal details updated successfully", res);
        onSave(formData); 
        setActiveTab('license');
      })
      .catch((error) => {
        console.error("Error updating advisor personal details:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'email') {
      const isValidEmail = validateEmail(value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: isValidEmail ? '' : 'Invalid email format',
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: value ? '' : 'This field is required',
      }));
    }
  };


  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };


  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
      <Text fontSize={'16px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Personal Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>First Name</Text>
          <Input size={'sm'} name="firstName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.firstName} onChange={handleInputChange} placeholder='First Name' />
          {errors.firstName && <Text color="red">{errors.firstName}</Text>}
        </Box>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>Last Name</Text>
          <Input size={'sm'} name="lastName" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.lastName}
            onChange={handleInputChange} placeholder='Last Name' />
          {errors.lastName && <Text color="red">{errors.lastName}</Text>}
        </Box>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>Email</Text>
          <Input size={'sm'} name="email" backgroundColor={'#f1f1f1'} color={'grey'} value={formData.email} readOnly
            onChange={handleInputChange} placeholder='Email' />
        </Box>
      </Flex>
      <Flex justifyContent={'space-around'} mt={'30px'}>
        <Box>
          <Text mb={'8px'} fontSize={'14px'}>Website</Text>
          <Input size={'sm'} name="website" value={formData.website} backgroundColor={'#f1f1f1'} color={'grey'} onChange={handleInputChange} placeholder='' />
        </Box>
        <Box>
          <Text mb={'8px'} fontSize={'14px'}>Phone</Text>
          <Input size={'sm'} backgroundColor={'#f1f1f1'} color={'grey'} name="phone" value={formData.phone}
            onChange={handleInputChange} placeholder='Phone Number' />
          {errors.phone && <Text color="red">{errors.phone}</Text>}
        </Box>
        <Box>
          <Text mb={'8px'} fontSize={'14px'}>Fax</Text>
          <Input size={'sm'} name="fax" onChange={handleInputChange} value={formData.fax} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Flex pl={'40px'} mt={'20px'}>
        <Box>
          <Text mb={'8px'} fontSize={'14px'}>Address</Text>
          <Textarea size={'sm'} name="address" onChange={handleInputChange} value={formData.address} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
          {errors.address && <Text color="red">{errors.address}</Text>}
        </Box>
      </Flex>
      <Flex justifyContent={'end'}>
      <Button _hover={{color:'white'}} size={'sm'} onClick={handleSave} background={'#9c27b0'} color={'white'}>Save Personal Details</Button>
      </Flex>
    </Box>
  );
};


const LicenseDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    licenceNo: commonData.licenceNo || '',
    eoPolicyNo: commonData.eopolicyNo || '',
    licenceExpiry: commonData.licenceExpiryDate || '',
    eoPolicyExpiry: commonData.eoInsurance || '',
    eoDocUpload: null,
    providerDocUpload: null
  });
  console.log(commonData,'common data')

  useEffect(() => {
    console.log(commonData,'common data')
    if (commonData.length === 1) {
      const data = commonData[0];
      setLicenseDetails({
        idUser: data.idUser || id,
        licenceNo: data.licenceNo || '',
        eoPolicyNo: data.eopolicyNo || '',
        licenceExpiry: data.licenceExpiryDate || '',
        eoPolicyExpiry: data.eopolicyExpiryDate || '',
        eoDocUpload: null,
        providerDocUpload: null
      });
    }
  }, [commonData, id]);
  const handleSave = async () => {
    if(!licenseDetails.licenceNo){
      alert("Licence No. is required.")
      return;
    }
    if(!licenseDetails.licenceExpiry){
      alert("Licence expiry date is required.")
      return
    }
    if(!licenseDetails.eoPolicyNo){
      alert('E&O Policy Number is required')
      return;
    }
    if(!licenseDetails.eoPolicyExpiry){
      alert('E&O Policy Expiry Date is required')
      return;
    }
    dispatch(isLoading(true));
    try {
      const formData = new FormData();
      Object.keys(licenseDetails).forEach((key) => {
        if (key !== 'eoDocUpload' && key !== 'providerDocUpload') {
          formData.append(key, licenseDetails[key]);
        }
      });
      formData.append('eoDocUpload', licenseDetails.eoDocUpload);
      formData.append('providerDocUpload', licenseDetails.providerDocUpload);
      const response = await axios.post(ADVISOR_LICENCE_DETAILS,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("License details updated successfully");
        setActiveTab("corporation")
        // Reset form or do any other necessary actions after success
      } else {
        toast.error("Failed to update license details");
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("An error occurred while updating license details");
    }

    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;

    if (files && files.length > 0) {
      // If files are present, update state with the file object
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file, // Store the file object itself
        // [`${name}`]: file.name, // Store the file name
      }));
    } else {
      // If no files, update state with input value
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleDownload = (fileObject) => {
    if (!fileObject || !fileObject.file) {
      console.error("File object is invalid");
      return;
    }

    const blobUrl = URL.createObjectURL(fileObject.file);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = fileObject.file.name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  };



  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
      <Text fontSize={'16px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>License Details</Text>
      <hr />
      <Flex mt={'25px'} justifyContent={'space-around'}>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>License No. *</Text>
          <Input size={'sm'} name="licenceNo" value={licenseDetails.licenceNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter License No.' />
        </Box>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>License Expiry Date *</Text>
          <Input size={'sm'} name="licenceExpiry" value={licenseDetails.licenceExpiry} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>E&O Policy No. *</Text>
          <Input size={'sm'} name="eoPolicyNo" value={licenseDetails.eoPolicyNo} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='Enter EO Policy No.' />
        </Box>
        <Box>
          <Text mb={'5px'} fontSize={'14px'}>E&O Policy Exp. Date *</Text>
          <Input size={'sm'} name="eoPolicyExpiry" value={licenseDetails.eoPolicyExpiry} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
        </Box>
      </Flex>
      <Box mt={'40px'} pl={'20px'}>
        <Text fontWeight="bold" fontSize={'14px'}>Uploaded PDF Files:</Text>
        <Table variant="simple" mt={4}>
          <Thead>
            <Tr>
              <Th>Serial No.</Th>
              <Th>PDF</Th>
              <Th>Action</Th>
              <Th>Created Date</Th>
            </Tr>
          </Thead>
          <Tbody>
  {commonData && (
    <>
      <Tr>
        <Td>1</Td>
        <Td color={"black"}>{commonData[0].provider}</Td>
        <Td><Button size={'sm'} onClick={() => handleDownload(commonData[0].provider)}>Download</Button></Td>
      </Tr>
      <Tr>
        <Td>2</Td>
        <Td color={"black"}>{commonData[0].eoInsurance}</Td>
        <Td><Button  size={'sm'} onClick={() => handleDownload(commonData[0].eoInsurance)}>Download</Button></Td>
      </Tr>
    </>
  )}
</Tbody>
        </Table>
      </Box>
      <Flex justifyContent={'end'}>
      <Button _hover={{color:'white'}} size={'sm'} background={'#9c27b0'} color={'white'} onClick={handleSave}>Save License Details</Button>
      </Flex>
    </Box>
  );
};

const CorporationDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
 const data = commonData && commonData.length > 0 ? commonData[0] : {};
 const [showFirm,setShowFirm]=useState(data.firm || false)
  
 if(data.firm){
    setShowFirm(true)
  }
 console.log(commonData,'comon dataaaa')
 const today = new Date().toISOString().split('T')[0];  
  const [licenseDetails, setLicenseDetails] = useState({
    idUser: id,
    corpName: data.corporationName || '',
    eoCorpName: data.eocorporationName || '',
    corpEOPolicyExpiryDate: data.corporationeoPolicyexpiryDate || '',
    corpLicenceExpiryDate: data.corporationLicenceExpirydate || '',
    binNoOfCorp: data.binnoofCorporation || '',
    firm: data.firm || false,
    corpLicenceDoc: null,
    corpPaperDoc: null
  });

  const handleYesNoChange = (e) => {
    const { value } = e.target;
    setShowFirm(value === 'true');
  };

  const handleSave = async () => {
    if(showFirm===true && !licenseDetails.corpName){
      alert("Please Enter Corporation Name")
      return;
    }
    if(showFirm===true && !licenseDetails.eoCorpName){
      alert("The E&O corporation name is required")
      return;
    }
    if(showFirm===true && !licenseDetails.corpEOPolicyExpiryDate)
      {
       alert('The Corporation E&O Policy Expiry date is required')
        return
      }
    if(showFirm===true && !licenseDetails.corpLicenceExpiryDate){
      alert('The corporation licence expiry date field is required.')
      return;
    }
    if (showFirm === true && (!licenseDetails.binNoOfCorp || licenseDetails.binNoOfCorp.length !== 9)) {
      alert('The BIN No of Corporation field is required and must be 9 digits.');
      return;
    }
    if(showFirm===true && !licenseDetails.corpLicenceDoc){
      alert('Corportation license doc is required')
      return;
    }
    if(showFirm===true && !licenseDetails.corpPaperDoc){
      alert("Corporation Doc is Required")
      return;
    }
    if(showFirm===false){
      const formData = new FormData();
      formData.append('idUser',id)
      formData.append('firm',showFirm)
      Api.POSTDATA(`${ADVISOR_CORPORATION_DETAILS}`,formData).then((res)=>{
        console.log(res)
        const {status,data}=res
        if(status===200){
          toast.success("Corporation details updated successfully");
        }else{
          console.log('error')
          alert("Something went wrong")
        }
      })
    }
    if(showFirm===true){
      dispatch(isLoading(true));
      try {
        const formData = new FormData();
        formData.append('idUser',id)
        formData.append('corpName',licenseDetails.corpName)
        formData.append('eoCorpName',licenseDetails.eoCorpName)
        formData.append('corpEOPolicyExpiryDate',licenseDetails.corpEOPolicyExpiryDate)
        formData.append('corpLicenceExpiryDate',licenseDetails.corpLicenceExpiryDate)
        formData.append('binNoOfCorp',licenseDetails.binNoOfCorp)
        formData.append('firm',showFirm)
        formData.append('corpLicenceDoc',licenseDetails.corpLicenceDoc)
        formData.append('corpPaperDoc',licenseDetails.corpPaperDoc)
        if (licenseDetails.corpLicenceDoc) {
          formData.append('corpLicenceDoc', licenseDetails.corpLicenceDoc);
        }
        if (licenseDetails.corpPaperDoc) {
          formData.append('corpPaperDoc', licenseDetails.corpPaperDoc);
        }
        Api.POSTDATA(`${ADVISOR_CORPORATION_DETAILS}`,formData).then((res)=>{
          console.log(res,'response')
          const {status,data}=res
          if(status===200){
            toast.success("Corporation details updated successfully");
          }else{
            console.log('error')
            alert("Something went wrong")
          }
        })
        // const response = await axios.post(ADVISOR_CORPORATION_DETAILS,
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //       'Authorization': `Bearer ${token}`,
        //     },
        //   }
        // );
        // if (response.status === 200) {
          
        // } else {
        //   toast.error("Failed to update corporation details");
        // }
      } catch (error) {
        console.error('Error:', error);
        toast.error("An error occurred while updating corportaion details");
      }
    }
    dispatch(isLoading(false));
  };

  const handleChange = (e) => {
    const { name, files } = e.target;
    if (files && files.length > 0) {
      const file = files[0];
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: file, // Store the file object itself
        // [`${name}`]: file.name, // Store the file name
      }));
    } else {
      // If no files, update state with input value
      const { value } = e.target;
      setLicenseDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setLicenseDetails((prevDetails) => ({
      ...prevDetails,
      firm: checked, // Set firm value to true if checked, false otherwise
    }));
  };
  
  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'}>
    <Text fontSize={'18px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Corporation Details</Text>
    <hr />
    <Flex mt={'25px'} justifyContent={'space-around'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
      <Box w={'230px'}>
        <Text mb={'8px'}>Firm/Corporation/Prop *</Text>
        <Select size={'sm'} name='firm' onChange={handleYesNoChange} value={showFirm.toString()}>
          <option value="">select</option>
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </Box>
        {
          showFirm===true?
          <Box>
            <Text mb={'8px'}>E&O updated with Corporation name *</Text>
            <Input w={'230px'} size={'sm'} name="eoCorpName" value={licenseDetails.eoCorpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
          </Box>:''
        }
        {
          showFirm===true?
      <Box>
        <Text mb={'8px'}>Corporation E&O Expiry Date *</Text>
        <Input w={'230px'} min={today} size={'sm'} name="corpEOPolicyExpiryDate" value={licenseDetails.corpEOPolicyExpiryDate} onChange={handleChange} type='date' backgroundColor={'#f1f1f1'} color={'grey'} placeholder='ravi@gmail.com' />
      </Box>:''}
    </Flex>
    {
          showFirm===true?
          <>
    <Flex justifyContent={'space-around'} mt={'20px'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
      <Box>
        <Text mb={'8px'}>Corporation Name *</Text>
        <Input w={'230px'} size={'sm'} name="corpName" value={licenseDetails.corpName} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' />
      </Box>
      <Box>
        <Text mb={'8px'}>BIN No of the Corporation *</Text>
        <Input w={'230px'} size={'sm'} name="binNoOfCorp" value={licenseDetails.binNoOfCorp} onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} />
      </Box>
      <Box>
        <Text mb={'8px'}>Date Of License Expiry *</Text>
        <Input w={'230px'} size={'sm'} name="corpLicenceExpiryDate" value={licenseDetails.corpLicenceExpiryDate} onChange={handleChange} type="date" backgroundColor={'#f1f1f1'} color={'grey'} placeholder='' min={today} />
      </Box>
    </Flex>
    <Box mt={'30px'} pl={'20px'} w={'90%'} m={'auto'} fontSize={'13px'} fontFamily={'Open Sans,sans-serif'}>
      <Text fontWeight="bold" mt={'30px'}>Uploaded PDF Files:</Text>
      <Table variant="simple" mt={2}>
        <Thead>
          <Tr>
            <Th>Serial No.</Th>
            <Th>Upload</Th>
            <Th>File Name</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {commonData && (
            <>
              <Tr>
                <Td>1</Td>
                <Td><input type="file" name="corpLicenceDoc" onChange={handleChange} /></Td>
                <Td color={"black"}>{commonData.corporationLicenceDoc}</Td>
                <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.corporationLicenceDoc)}>Download</Button></Td>
              </Tr>
              <Tr>
                <Td>2</Td>
                <Td><input type="file" name="corpPaperDoc" onChange={handleChange} backgroundColor={'#f1f1f1'} color={'grey'} /></Td>

                <Td color={"black"}>{commonData.corporationPaperDoc}</Td>
                <Td><Button fontSize={'12px'} size={'sm'} onClick={() => handleDownload(commonData.corporationPaperDoc)}>Download</Button></Td>
              </Tr>
            </>
          )}
        </Tbody>
      </Table>
    </Box></>:''}
    <Flex justifyContent={'end'} mt={'10px'}>
    {/* {
        approved === 1 ? '' : */}
        <Button size={'sm'} background={'teal'} color={'white'} onClick={handleSave}>Save Details</Button>
      {/* // } */}
      
    </Flex>
  </Box>
  );
};

const ReferenceDetailsForm = ({ onSave, commonData, activeTab, setActiveTab }) => {
  // Your reference details form inputs 
  // console.log(commonData.refrenceDetails, "xdsdffdfvffgvf")
  const { token, id } = JSON.parse(localStorage.getItem("code_dev"))
  console.log("token,user", token, id)
  const dispatch = useDispatch();
  const [referenceDetails, setReferenceDetails] = useState({
    reference_name_1: "",
    reference_email_1:  "",
    reference_contact_1: "",
    reference_address_1: "",
    reference_name_2: "",
    reference_email_2: "",
    reference_contact_2:  "",
    reference_address_2:  "",
    reference_name_3: "",
    reference_email_3:  "",
    reference_contact_3:  "",
    reference_address_3:  "",
  });;
  const handleSave = () => {
    // Create a JSON object from the form data
    const requestData = {

      idUser: id,
      reference_name_1: referenceDetails.reference_name_1,
      reference_email_1: referenceDetails.reference_email_1,
      reference_contact_1: referenceDetails.reference_contact_1,
      reference_address_1: referenceDetails.reference_address_1,


      reference_name_2: referenceDetails.reference_name_2,
      reference_email_2: referenceDetails.reference_email_2,
      reference_contact_2: referenceDetails.reference_contact_2,
      reference_address_2: referenceDetails.reference_address_2,


      reference_name_3: referenceDetails.reference_name_3,
      reference_email_3: referenceDetails.reference_email_3,
      reference_contact_3: referenceDetails.reference_contact_3,
      reference_address_3: referenceDetails.reference_address_3,


    };

    // Dispatch the action to post the data
    dispatch(advisorRefrence(requestData));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReferenceDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  return (
    <Box boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'80%'} m={'auto'} background={'white'}>
      <Text fontSize={'22px'} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Reference Details</Text>
      <hr />
      <Flex flexDirection="column">
        {[1, 2, 3].map((index) => (
          <Flex key={index} mt={'25px'} justifyContent={'space-around'}>
            <Box>
              <Text mb={'8px'}>{`Reference Name ${index}`}</Text>
              <Input name={`reference_name_${index}`} value={referenceDetails[`reference_name_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Email ${index}`}</Text>
              <Input name={`reference_email_${index}`} value={referenceDetails[`reference_email_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Contact ${index}`}</Text>
              <Input name={`reference_contact_${index}`} value={referenceDetails[`reference_contact_${index}`]} onChange={handleChange} />
            </Box>
            <Box>
              <Text mb={'8px'}>{`Reference Address ${index}`}</Text>
              <Input name={`reference_address_${index}`} value={referenceDetails[`reference_address_${index}`]} onChange={handleChange} />
            </Box>
          </Flex>
        ))}
      </Flex>
      <Button size={'sm'} background={'teal'} color={'white'} onClick={handleSave} mt={'15px'}>Save Reference Details</Button>
    </Box>
   
  );
};



const Question = ({ onSave, commonData, activeTab, setActiveTab }) => {
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const params = useParams();
  const { id } = params;
  const [formData, setFormData] = useState({
    idUser: parseInt(id),
    anyComplaint: "",
    criminalCase: "",
    defaultCommision: "",
    creaditScore: "",
    pendingCase: "",
    role: '',
    commision: ""
  });

  useEffect(() => {
    dispatch(agaMgaAll())
      .then((res) => {
        const filteredData = res.find((item) => item.idUser === parseInt(id));
        if (filteredData && filteredData.roles && filteredData.roles.name) {
          setProfileData([filteredData]);
          setFormData({
            idUser: parseInt(id),
            anyComplaint: filteredData.anyComplaint || "",
            criminalCase: filteredData.criminalCase || "",
            defaultCommision: filteredData.defaultCommision || "",
            creaditScore: filteredData.creaditScore || "",
            pendingCase: filteredData.pendingCase || "",
            role: filteredData.roles.idRole, // Set role based on filteredData's idRole
            commision: filteredData.commission || ""
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);
      });
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(allRolesList())
      .then((res) => {
        console.log("rolesofalltype", res);
        setRolesData(res);
      })
      .catch((error) => {
        console.error("Error fetching roles data:", error);
      });
  }, [dispatch]);

  const handleSave = () => {
    if (!formData.commision) {
      alert("Please set commission");
      return;
    }
    if (!formData.role) {
      alert("Please select user Role");
      return;
    }
    dispatch(questionsandassingingEdit(formData))
      .then((res) => {
        console.log("Question saved successfully.", res);
        onSave(formData);
      })
      .catch((error) => {
        console.error("Error updating advisor personal details:", error);
        if (error.response && error.response.data && error.response.data.message) {
          const apiErrors = error.response.data.message;
          // Update errors state with API errors
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...apiErrors
          }));
        }
      });
  };

  const handleInputChange = (name, value) => {
    let convertedValue = value;
    if (value === "Yes" || value === "No") {
      convertedValue = value === "Yes" ? 1 : 0;
    }
    if (name === "role" || name === "commision") {
      convertedValue = parseInt(value, 10);
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: convertedValue
    }));
  };

  const getRoleLabel = (idRole) => {
    switch (idRole) {
      case 2:
        return "AGA";
      case 1:
        return "MGA";
      case 3:
        return "Advisor";
      default:
        return "";
    }
  };

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'85%'} m={'auto'}>
      <Text fontSize={'22px'} style={{ color: 'purple', fontFamily: 'poppins', paddingBottom: '10px' }}>Questions</Text>
      <hr />
      <Flex fontSize={'14px'} fontFamily={'Open Sans, sans-serif'} gap={'25px'} mt={'25px'}>
        <Box>
          <Text mb={'8px'}>Any Complaints to Regulators in any Province or Jurisdiction?</Text>
          <Select w={'200px'} size={'sm'} value={formData.anyComplaint} onChange={(e) => handleInputChange("anyComplaint", e.target.value)}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </Box>
        <Box>
          <Text mb={'8px'}>Do you have any case/proceedings pending in any court of Law?</Text>
          <Select w={'200px'} size={'sm'} value={formData.pendingCase} onChange={(e) => handleInputChange("pendingCase", e.target.value)}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </Box>
        <Box>
          <Text mb={'8px'}>Are Default of Commission to any Insurance Company in Canada?</Text>
          <Select w={'200px'} size={'sm'} value={formData.defaultCommision} onChange={(e) => handleInputChange("defaultCommision", e.target.value)}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </Box>
      </Flex>
      <Flex fontSize={'14px'} fontFamily={'Open Sans, sans-serif'} gap={'25px'} mt={'25px'}>
        <Box>
          <Text mb={'8px'}>Do you have a credit score lower than 600?</Text>
          <Select w={'200px'} size={'sm'} value={formData.creaditScore} onChange={(e) => handleInputChange("creaditScore", e.target.value)}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </Box>
        <Box>
          <Text mb={'8px'}>Any Criminal Case Ever?</Text>
          <Select w={'200px'} size={'sm'} value={formData.criminalCase} onChange={(e) => handleInputChange("criminalCase", e.target.value)}>
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </Select>
        </Box>
        <Box>
          <Text mb={'8px'}>I Accept this Registration As?</Text>
          <Select w={'200px'} size={'sm'} value={formData.role} onChange={(e) => handleInputChange("role", e.target.value)}>
            <option value="">Select</option>
            {rolesData
              .filter(role => [2, 1, 3].includes(role.idRole)) // Filter by idRole
              .map(role => (
                <option key={role.idRole} value={role.idRole}>
                  {getRoleLabel(role.idRole)}
                </option>
              ))}
          </Select>
        </Box>
      </Flex>
      <br />
      <Box>
        <Text mb={'8px'} fontSize={'14px'} fontFamily={'Open Sans, sans-serif'}>Select Advisory Commission</Text>
        <Select size={'sm'} w={'200px'} value={formData.commision} onChange={(e) => handleInputChange("commision", e.target.value)}>
          <option value="">Select</option>
          {[...Array(31).keys()].map(num => (
            <option key={num} value={num}>{num}%</option>
          ))}
        </Select>
      </Box>
      <Flex justifyContent={'end'}>
        <Button background={'#9c27b0'} color={'white'} _hover={{ color: 'white' }} onClick={handleSave} mt={4}>Save Personal Details</Button>
      </Flex>
    </Box>
  );
}




export default EditMgaAgaAdvisor;

