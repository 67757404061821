import React, { useEffect, useState } from "react";
import { Box, Button, Flex, IconButton, Image, Select, Text, useDisclosure,Stack, Menu, MenuButton, MenuList, MenuItem, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
import { useSelector } from "react-redux";
import HeaderDropdown from "../../../Utils/Dropdown-hover/HoverButton";
import {buttonBackgroundColor,buttonColor,buttonHoverBackground,buttonHoverColor,
} from "../../../Utils/UniversalColor";
import { MdCall, MdMenu } from "react-icons/md";
import zIndex from "@mui/material/styles/zIndex";


function CustomerHeader() {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const role = storage.getUserRole();
  const { user } = useSelector((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner,setIsPartner]=useState(false)
  const  userStatus  = JSON.parse(localStorage.getItem("code_dev"))
  // const { isOpen, onToggle } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()


  const location = useLocation();
  let path = location.pathname.split("/");
  path = path && path[1];
  useEffect(() => {
    if (path === "admin") {
      setIsAdmin(true);
    }
    if(path === 'service'){
      setIsPartner(true)
    }
  }, [path]);


    const handleLogout = ()=>{
      storage.clear()
      window.location.href='/'
  }

  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    setSelectedLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
  };
 
return (
    <Box>
        {!role && (
          <>
           <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          alignItems={'center'}
          display={['none','none','flex']}
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"55%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            gap={"40px"}
            fontWeight={"600"}
            fontSize={"16px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            alignItems={'center'}
            mt={"10px"}
          >
            <Link to="/">
              <Box>{t("Home")}</Box>
            </Link>
            <Link to={"/enquiry"}>
              <Box>{t("Contact Us")}</Box>
            </Link>
          </Flex>
          <Flex gap={"40px"} alignItems={"center"}>
            <Link to={"/advisorLogin"}>
              <Button
                fontSize={"12px"}
                _hover={{background:buttonHoverBackground, color:buttonHoverColor}}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
                size={'sm'}
              >
                {t("Advisor")}
              </Button>
            </Link>
            <Link to={"/customerLogin"}>
              <Button
                _hover={{background:buttonHoverBackground, color:buttonHoverColor}}
                size={'sm'}
                fontSize={"12px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Customer Login")}
              </Button>
            </Link>
          </Flex>
        </Flex>


        {/* ------------------- mobile ---------------------- */}
          
          <Flex zIndex={"999"} background={"white"} position={"fixed"}
            w={"100%"} p={"10px"} justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            alignItems={"center"}
            display={['flex','none','none']}
          >
            <Box w={["48%","48%","18%"]}>
              <Link to={"/"}>
                <Image
                  w={"60%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Box>
            <IconButton onClick={onOpen} fontSize={'24px'} _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
            <Drawer style={{zIndex:'999999999999'}}
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Welcome</DrawerHeader>
                  <DrawerBody>
                   <Box p={'10px'}>
                  <Link to={'/'}>
                    <Box borderRadius={'5px'} mt={'9px'} _hover={{background:'white',color:'teal',border:'2px solid teal'}} background={'teal'} color={'white'} fontWeight={'600'} p={'10px'}>
                    <Text>Home</Text>
                    </Box>
                    </Link>
                    <Link to={'/enquiry'}>
                    <Box borderRadius={'5px'} mt={'9px'} _hover={{background:'white',color:'teal',border:'2px solid teal'}} background={'teal'} color={'white'} fontWeight={'600'} p={'10px'}>
                    <Text>Contact Us</Text>
                    </Box>
                    </Link>
                    <Link to={'/customerLogin'}>
                    <Box borderRadius={'5px'} mt={'9px'} _hover={{background:'white',color:'teal',border:'2px solid teal'}} background={'teal'} color={'white'} fontWeight={'600'} p={'10px'}>
                    <Text>Customer</Text>
                    </Box>
                    </Link>
                    <Link to={'/advisorLogin'}>
                    <Box borderRadius={'5px'} mt={'9px'} _hover={{background:'white',color:'teal',border:'2px solid teal'}} background={'teal'} color={'white'} fontWeight={'600'} p={'10px'}>
                    <Text>Advisor</Text>
                    </Box>
                    </Link>
                    </Box>
                  </DrawerBody>

                  <DrawerFooter>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Box>
          </Flex>
          
        </>
         )}

      {role === ROLES.CUSTOMER && !isAdmin && (
        <>
          <Flex
            zIndex={"9999999"}
            background={"white"}
            position={"fixed"}
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            display={['none','none','flex']}
          >
            <Box w={"18%"}>
              <Link to={"/"}>
                <Image
                  w={"70%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Flex
              w={"80%"}
              gap={"40px"}
              fontWeight={"600"}
              fontSize={"14px"}
              fontFamily={"poppins"}
              justifyContent={"center"}
              mt={"8px"}
            >
              <Link to="/customer-dashboard">
                <Box>{t("Get Quote")}</Box>
              </Link>
              <Link to={"/my-quotes"}>
                <Box>{t("My Quote")}</Box>
              </Link>
              <Link to={"/customer-policies"}>
                <Box>{t("My Policy")}</Box>
              </Link>
              <Link to={"/customer-profile"}>
                <Box>{t("My Account")}</Box>
              </Link>
            </Flex>
            <Flex  fontSize={'13px'} alignItems={'center'} w={'20%'} direction={'column'}>
                  <Flex gap={'10px'} alignItems={'center'}>
                  <MdCall />
                  <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
                  </Flex>
                  <Text fontSize={'13px'}>support@travelmedicare.com</Text>
                </Flex>
          </Flex>

          {/* ***************** Mobile ************************ */}
          
          <Flex zIndex={"9999999"} background={"white"}
                position={"fixed"}
                w={"100%"}
                p={"10px"}
                justifyContent={"space-between"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
              <Box w={["48%","48%","18%"]}>
                  <Link to={"/"}>
                    <Image
                      w={"70%"}
                      src="https://travelmedicare.com/public/users/images/logo.png"
                    />
                  </Link>
                </Box>
                <Menu>
                <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                </MenuButton>
                <MenuList>
                  <Link to={'/customer-dashboard'}>
                  <MenuItem>Get Quote</MenuItem>
                  </Link>
                  <Link to={'/my-quotes'}>
                  <MenuItem>My Quotes</MenuItem>
                  </Link>
                  <Link to={'/customer-policies'}>
                  <MenuItem>My Policies</MenuItem>
                  </Link>
                  <Link to={'/customer-profile'}>
                  <MenuItem>My Profile</MenuItem>
                  </Link>
                  <Link to={'/support'}>
                  <MenuItem>Support</MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
              </Flex>
        </>
      )}

      {role === ROLES.ADVISOR && !isAdmin && (
        <>
          <Flex
            zIndex={"9999999"}
            background={"white"}
            position={"fixed"}
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
             display={['none','none','flex']}
          >
            <Box w={"18%"}>
              <Link to={"/"}>
                <Image
                  w={"70%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Flex
              w={"80%"}
              gap={"40px"}
              fontWeight={"600"}
              fontSize={"14px"}
              fontFamily={"poppins"}
              justifyContent={"center"}
              mt={"8px"}
            >
              <Link to="/advisor-generate-quote">
                <Box>{t("Generate Quote")}</Box>
              </Link>
              <Link to={"/advisor-profile"}>
                <Box>{t("My Account")}</Box>
              </Link>
              <HeaderDropdown />
            </Flex>
            <Flex w={"20%"} gap={"10px"} alignItems={"center"}>
              <MdCall />
              <Text fontWeight={"600"} fontFamily={"poppins"}>
                1844-844-3272
              </Text>
            </Flex>
          </Flex>
        </>
      )}

      {role === ROLES.MGA && (
            <>
              <Flex
                zIndex={"999"}
                background={"white"}
                position={"fixed"}
                w={"100%"}
                p={"10px"}
                justifyContent={"space-between"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                display={['none','flex','flex']}
              >
                <Box w={"18%"}>
                  <Link to={"/"}>
                    <Image
                      w={"70%"}
                      src="https://travelmedicare.com/public/users/images/logo.png"
                    />
                  </Link>
                </Box>
                <Flex
                  w={"80%"}
                  gap={"40px"}
                  fontWeight={"500"}
                  fontSize={"14px"}
                  fontFamily={"poppins"}
                  justifyContent={"center"}
                  mt={"12px"}
                >
                  <Link to='/advisor-generate-quote'>
                    <Box className="Link_btns">{t('Generate Quote')}</Box>
                  </Link>
                  <Link to={'/advisor-profile'}>
                    <Box className="Link_btns">{t('Profile')}</Box>
                  </Link>
                  <Link to={'/advisor-team'}>
                    <Box className="Link_btns">{t('My Team')}</Box>
                  </Link>
                  <HeaderDropdown />
                </Flex>
                <Flex  fontSize={'13px'} alignItems={'center'} w={'20%'} direction={'column'}>
                  <Flex gap={'10px'} alignItems={'center'}>
                  <MdCall />
                  <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
                  </Flex>
                  <Text fontSize={'13px'}>support@travelmedicare.com</Text>
                </Flex>
              </Flex>

     {/* ****************** Mobile **************************** */}


          <Flex zIndex={"9999"} background={"white"}
                position={"fixed"}
                w={"100%"}
                p={"10px"}
                justifyContent={"space-between"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
              <Box w={["48%","48%","18%"]}>
                  <Link to={"/"}>
                    <Image
                      w={"70%"}
                      src="https://travelmedicare.com/public/users/images/logo.png"
                    />
                  </Link>
                </Box>
                <Menu>
                <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                </MenuButton>
                <MenuList>
                  <Link to={'/advisor-generate-quote'}>
                  <MenuItem>Generate</MenuItem>
                  </Link>
                  <Link to={'/advisor-profile'}>
                  <MenuItem>My Account</MenuItem>
                  </Link>
                  <Link to={'/advisor-quotes'}>
                  <MenuItem>My Quotes</MenuItem>
                  </Link>
                  <Link to={'/advisor-policies'}>
                  <MenuItem>My Policies</MenuItem>
                  </Link>
                  <Link to={'/advisor-commission'}>
                  <MenuItem>My Commission</MenuItem>
                  </Link>
                  <Link to={'/advisor-team'}>
                  <MenuItem>My Team</MenuItem>
                  </Link>
                  <Link to={'/support'}>
                  <MenuItem>Support</MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
              </Flex>
            </>)}

      {role === ROLES.AGA && (
      <>
        <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            w={"80%"}
            gap={"40px"}
            fontWeight={"500"}
            fontSize={"14px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"12px"}
          >
            <Link to='/advisor-generate-quote'>
              <Box className="Link_btns">{t('Generate Quote')}</Box>
            </Link>
            <Link to={'/advisor-profile'}>
              <Box className="Link_btns">{t('My Account')}</Box>
            </Link>
            <Link to={'/advisor-team'}>
              <Box className="Link_btns">{t('My Team')}</Box>
            </Link>
            <HeaderDropdown />
          </Flex>
          <Flex  fontSize={'13px'} alignItems={'center'} w={'20%'} direction={'column'}>
            <Flex gap={'10px'} alignItems={'center'}>
            <MdCall />
            <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
            </Flex>
            <Text fontSize={'13px'}>support@travelmedicare.com</Text>
          </Flex>
        </Flex>
      </>)}

      {role === ROLES.PARTNER&& (
        <>
        </>
      )}

      {isAdmin && (
        <>
        </>
      )}

      {isPartner && (
        <>
        </>
      )}

      {role === ROLES.ADMIN && !isAdmin && (
        <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            gap={"40px"}
            fontWeight={"600"}
            fontSize={"16px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"6px"}
          >
            <Link to="/">
              <Box>{t("Home")}</Box>
            </Link>
            <Link to={"/enquiry"}>
              <Box>{t("Contact Us")}</Box>
            </Link>
          </Flex>
          <Flex gap={"40px"} alignItems={"center"}>
            <Link to={"/advisorLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Advisor")}
              </Button>
            </Link>
            <Link to={"/customerLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Customer Login")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      )}

      {role === ROLES.SUPERADMIN && !isAdmin && (
        <Flex
          zIndex={"99"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            gap={"40px"}
            fontWeight={"600"}
            fontSize={"16px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"6px"}
          >
            <Link to="/">
              <Box>{t("Home")}</Box>
            </Link>
            <Link to={"/enquiry"}>
              <Box>{t("Contact Us")}</Box>
            </Link>
          </Flex>
          <Flex gap={"40px"} alignItems={"center"}>
            <Link to={"/advisorLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Advisor")}
              </Button>
            </Link>
            <Link to={"/customerLogin"}>
              <Button
                fontSize={"14px"}
                color={buttonColor}
                backgroundColor={buttonBackgroundColor}
              >
                {t("Customer Login")}
              </Button>
            </Link>
          </Flex>
        </Flex>
      )}

      {role === ROLES.ADVISOR && userStatus?.userStatus === 0 && (
      <>
        <Flex
          zIndex={"9999999"}
          background={"white"}
          position={"fixed"}
          w={"100%"}
          p={"10px"}
          justifyContent={"space-between"}
          boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
          display={['none','none','flex']}
        >
          <Box w={"18%"}>
            <Link to={"/"}>
              <Image
                w={"70%"}
                src="https://travelmedicare.com/public/users/images/logo.png"
              />
            </Link>
          </Box>
          <Flex
            w={"80%"}
            gap={"40px"}
            fontWeight={"500"}
            fontSize={"14px"}
            fontFamily={"poppins"}
            justifyContent={"center"}
            mt={"12px"}
          >
            <Link to={'/advisor-profile'}>
              <Box className="Link_btns">{t('My Account')}</Box>
            </Link>
            <Box className="Link_btns" onClick={handleLogout}>{t('Logout')}</Box>
          </Flex>
          <Flex fontSize={'13px'} alignItems={'center'} w={'20%'} direction={'column'}>
            <Flex gap={'10px'} alignItems={'center'}>
            <MdCall />
            <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
            </Flex>
            <Text fontSize={'13px'}>support@travelmedicare.com</Text>
          </Flex>
        </Flex>



        {/* --------------- mobile ------------------------ */}

        <Flex
            zIndex={"999"}
            background={"white"}
            position={"fixed"}
            w={"100%"}
            p={"10px"}
            justifyContent={"space-between"}
            boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
            alignItems={"center"}
            display={['flex','none','none']}
          >
            <Box w={["48%","48%","18%"]}>
              <Link to={"/"}>
                <Image
                  w={"80%"}
                  src="https://travelmedicare.com/public/users/images/logo.png"
                />
              </Link>
            </Box>
            <Box>
            <IconButton onClick={onOpen} fontSize={'24px'} _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
            <Drawer style={{zIndex:'999999999999'}}
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />
                  <DrawerHeader>Welcome</DrawerHeader>
                  <DrawerBody>
                   <Box p={'10px'}>
                  <Link to={'/'}>
                    <Box mt={'5px'} _hover={{background:'grey'}} background={'#e8e8e8'} fontWeight={'600'} p={'10px'}>
                    <Text>Home</Text>
                    </Box>
                    </Link>
                    <Link to={'/advisor-profile'}>
                    <Box mt={'5px'} _hover={{background:'grey'}} background={'#e8e8e8'} fontWeight={'600'} p={'10px'}>
                    <Text>My Profile</Text>
                    </Box>
                    </Link>
                    <Box mt={'5px'} _hover={{background:'grey'}} background={'#e8e8e8'} fontWeight={'600'} p={'10px'}>
                    <Box className="Link_btns" onClick={handleLogout}>{t('Logout')}</Box>
                    </Box>
                    </Box>
                  </DrawerBody>

                  <DrawerFooter>
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Box>
          </Flex>
      </>)}

      {role === ROLES.ADVISOR && userStatus?.userStatus && (
            <>
              <Flex
                zIndex={"9999999"}
                background={"white"}
                position={"fixed"}
                w={"100%"}
                p={"10px"}
                justifyContent={"space-between"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px"
                display={['none','none','flex']}
              >
                <Box w={"18%"}>
                  <Link to={"/"}>
                    <Image
                      w={"70%"}
                      src="https://travelmedicare.com/public/users/images/logo.png"
                    />
                  </Link>
                </Box>
                <Flex
                  w={"80%"}
                  gap={"40px"}
                  fontWeight={"600"}
                  fontSize={"14px"}
                  fontFamily={"poppins"}
                  justifyContent={"center"}
                  mt={"8px"}
                >
                  <Link to='/advisor-generate-quote'>
                    <Box>{t('Generate Quote')}</Box>
                  </Link>
                  <Link to={'/advisor-profile'}>
                    <Box>{t('My Account')}</Box>
                  </Link>
                  <HeaderDropdown />
                </Flex>
                <Flex  fontSize={'13px'} alignItems={'center'} w={'20%'} direction={'column'}>
                    <Flex gap={'10px'} alignItems={'center'}>
                    <MdCall />
                    <Text fontWeight={'600'} fontFamily={'poppins'}>1844-844-3272</Text>
                    </Flex>
                    <Text fontSize={'13px'}>support@travelmedicare.com</Text>
                  </Flex>


              </Flex>
              {/* ***************** Mobile ******************** */}

              <Flex zIndex={"9999999"} background={"white"}
                position={"fixed"}
                w={"100%"}
                p={"10px"}
                justifyContent={"space-between"}
                boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" display={['flex','none','none']}>
              <Box w={["48%","48%","18%"]}>
                  <Link to={"/"}>
                    <Image
                      w={"70%"}
                      src="https://travelmedicare.com/public/users/images/logo.png"
                    />
                  </Link>
                </Box>
                <Menu>
                <MenuButton as={Button} _hover={{background:'white'}} background={'white'}>
                <IconButton _hover={{background:'white'}} background={'white'} icon={<MdMenu />} aria-label />
                </MenuButton>
                <MenuList>
                  <Link to={'/advisor-generate-quote'}>
                  <MenuItem>Generate</MenuItem>
                  </Link>
                  <Link to={'/advisor-profile'}>
                  <MenuItem>My Account</MenuItem>
                  </Link>
                  <Link to={'/advisor-quotes'}>
                  <MenuItem>My Quotes</MenuItem>
                  </Link>
                  <Link to={'/advisor-policies'}>
                  <MenuItem>My Policies</MenuItem>
                  </Link>
                  <Link to={'/advisor-commission'}>
                  <MenuItem>My Commission</MenuItem>
                  </Link>
                  <Link to={'/support'}>
                  <MenuItem>Support</MenuItem>
                  </Link>
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </MenuList>
              </Menu>
              </Flex>
            </>)} 

    </Box>
  );
}

export default CustomerHeader;
