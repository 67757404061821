import { PENDING_ENDORSEMENT_LIST_TYPE, isLoading } from "../../../../constants/actionConstants";
import { PendingEndoresementService } from "./service";

export const pendingEndoresementList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await PendingEndoresementService.pendingEndoresementList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: PENDING_ENDORSEMENT_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };