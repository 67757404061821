import { Tab, TabList, TabPanel, TabPanels, Tabs, Box, Flex, Input, Textarea, Button, Text, useToast, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import Api from "../../../dependencies/utils/Api";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import TripDetails from "./TripDetails";
import { ADVISOR_SAVE_POLICY } from "../../../constants/ApplicationUrl";
import { primaryBackgroundColor } from "../../../Utils/UniversalColor";



function GetQuickQuote() {
  const [tabIndex, setTabIndex] = useState(0);
  const [dataFromChild, setDataFromChild] = useState(''); 
  const toast = useToast();
  const navigate = useNavigate();
  const [isLargerThanMobile] = useMediaQuery("(min-width: 480px)");
  const [cardDetails, setCardDetails] = useState({
    cardHolderFirstName: '',
    cardHolderLastName: '',
    idQuote: '',
    quotationNo: '',
    cardHolderEmail: '',
    cardHolderAddress: '',
    cardHolderPostal: '',
    cardHolderPhone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: ''
  });
  const [errors, setErrors] = useState({
    cardHolderFirstName: '',
    cardHolderLastName: '',
    cardHolderEmail: '',
    cardHolderPostal: '',
    cardHolderPhone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: ''
  });

  const handleCardChanges = (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
    validateInput(name, value);
  }

  const receiveDataFromChild = (data) => {
    console.log('Received data from child:', data);
    setDataFromChild(data);
    setTabIndex(data.tab);
  };

  const handleSavePolicy = () => {
    const isValid = validateAllFields();
    if (!isValid) {
      return;
    }
    const updateData = {
      ...cardDetails,
      idQuote: dataFromChild.data.idQuote,
      quotationNo: dataFromChild.data.quotationNo,
    };

    Api.POST(`${ADVISOR_SAVE_POLICY}`, updateData).then(
      (res) => {
        console.log(res, "policy save response");
        alert("Policy Created Successfully.");
        // navigate("/customer-policies");
      }
    );
  };

  const validateInput = (name, value) => {
    // Basic validation for required fields
    if (value.trim() === '') {
      setErrors({ ...errors, [name]: `${name} is required` });
    } else {
      setErrors({ ...errors, [name]: '' });
    }
    console.log(updateData,'data sending')
    Api.POST(`${ADVISOR_SAVE_POLICY}`,updateData).then((res)=>{
      console.log(res,'policy save response')
      alert('Policy Created Successfully.')
      // navigate('/customer-policies')
    })
  }

  const validateAllFields = () => {
    let isValid = true;
    const requiredFields = ["cardHolderFirstName", "cardHolderLastName", "cardHolderEmail", "cardHolderPostal", "cardHolderPhone", "cardNumber", "expiryDate", "cvv", "amount"];
    requiredFields.forEach(field => {
      if (cardDetails[field].trim() === '') {
        setErrors({ ...errors, [field]: `${field} is required` });
        isValid = false;
      }
    });
    return isValid;
  };

  const handleCardNumberChange = async (e) => {
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });

    // Retrieve Bearer token from storage
    const { id, token } = JSON.parse(localStorage.getItem("code_dev"));

    // Fetch card type from a different BIN API
    try {
      const response = await axios.get(`https://lookup.binlist.net/${value.substring(0, 6)}`    , {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const cardType = response.data.scheme.toLowerCase(); // Get card type from API response
      console.log("Card Type:", cardType);
      // Display card type name beside the input field
      const cardTypeDisplay = document.getElementById('cardTypeDisplay');
      if (cardTypeDisplay) {
        cardTypeDisplay.textContent = cardType.toUpperCase();
      }
    } catch (error) {
      console.error('Error fetching card type:', error);
    }
  };

return (
    <div>
      <Box paddingTop={'90px'} background='#f1f1f1'>
        <Tabs display={'flex'} flexDirection={['column','column','row']} w={['95%','95%','80%']} m={'auto'} index={tabIndex} variant="unstyled">
          <Box width={["95%","90%","20%"]}>
            <TabList
              style={{
                display: "flex",
                border: "2px solid #088183",
                backgroundColor: primaryBackgroundColor,
                padding: "10px",
                paddingBottom: "10px",
                marginTop: isLargerThanMobile ? "50px" : "20px",    
                flexDirection:isLargerThanMobile?'column':'row',  
                height: 'auto',
                color: "white",
                fontSize: isLargerThanMobile?"16px":'12px',
                fontWeight: "700",
              }}
            >
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Get a Quote
              </Tab>
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
                onClick={() => setTabIndex(0)}
              >
                Confirm & Pay
              </Tab>
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Purchase Policy
              </Tab>
            </TabList>
          </Box>

          <TabPanels marginTop={["20px",'50px','20px']} w={["100%",'100%','80%']}>
            <TabPanel>
              <TripDetails sendDataToParent={receiveDataFromChild} />
            </TabPanel>
            <TabPanel>
              <Box>
                <Text fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}><span style={{ color: 'red' }}>Note :</span>If you are using different Credit Card Make Sure to update Payor’s Card details including Name, Address, email, Cell No and Postal Code or else Card will be declined</Text>
                <Text fontWeight={'600'} mt={'15px'} fontFamily={'poppins'}>Primary Card Details :</Text>
                <Flex w={'90%'} m={'auto'} mt={'20px'} gap={'30px'}>
                  <Box w={'50%'} border={'1px solid #d5d5d5'}>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}>
                      <Text>Customer Information</Text></Flex>
                    <Box p={'10px'}>
                      <Flex fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder First Name</Text>
                          <Input name='cardHolderFirstName' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderFirstName}</Text>
                        </Box>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Last Name</Text>
                          <Input name='cardHolderLastName' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderLastName}</Text>
                        </Box>
                      </Flex>
                      <Box w={'95%'} m={'auto'} mt={'10px'}>
                        <Text fontSize={'12px'}>Card Holder Email:</Text>
                        <Input name='cardHolderEmail' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                        <Text color="red" fontSize="xs">{errors.cardHolderEmail}</Text>
                      </Box>
                      <Flex mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Postal</Text>
                          <Input name='cardHolderPostal' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderPostal}</Text>
                        </Box>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Phone</Text>
                          <Input name='cardHolderPhone' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderPhone}</Text>
                        </Box>
                      </Flex>
                      <Box w={'95%'} m={'auto'} mt={'10px'}>
                        <Text fontSize={'12px'}>Card Holder Address</Text>
                        <Textarea name='cardHolderAddress' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                      </Box>
                    </Box>
                  </Box>

                  <Box w={'40%'} border={'1px solid #d5d5d5'}>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}>
                      <Text>Payment Details</Text></Flex>
                    <Box w={'95%'} m={'auto'} mt={'10px'}>
                      <Text fontSize={'12px'}>Card Number</Text>
                      <Input id="cardNumberInput" name="cardNumber" onChange={handleCardNumberChange} border='1px solid grey' size={'xs'} />
                      <Text color="red" fontSize="xs">{errors.cardNumber}</Text>
                      {/* Display detected card type */}
                      <Text id="cardTypeDisplay" fontSize="sm" mt="2"></Text>
                    </Box>
                    <Flex mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                      <Box w={'45%'}>
                        <Text fontSize={'12px'}>Expiry Date</Text>
                        <Input name='expiryDate' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                        <Text color="red" fontSize="xs">{errors.expiryDate}</Text>
                      </Box>
                      <Box w={'45%'}>
                        <Text fontSize={'12px'}>cvv</Text>
                        <Input name='cvv' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                        <Text color="red" fontSize="xs">{errors.cvv}</Text>
                      </Box>
                    </Flex>
                    <Box w={'95%'} m={'auto'} mt={'10px'}>
                      <Text fontSize={'12px'}>Amount</Text>
                      <Input name='amount' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                      <Text color="red" fontSize="xs">{errors.amount}</Text>
                    </Box>
                    <Flex pl={'5px'} mt={'10px'}>
                      <input type="checkbox" />
                      <Text fontSize={'12px'}>By checking the box, I / We confirm that i/we have given permission to use my credit card for the specified amount</Text>
                    </Flex>
                  </Box>
                </Flex>
                <Flex w={'90%'} m={'auto'} p={'10px'} justifyContent={'space-between'} mt={'20px'} background={'teal'} color={'white'} borderRadius={'5px'}>
                  <Text>Final Payment</Text>
                  <Text>$20</Text>
                </Flex>
                <Flex w={'90%'} m={'auto'} fontSize={'12px'} gap={'10px'} mt={'10px'}>
                  <input type="checkbox" />
                  <Text>By checking the box, I / We agree to the following terms and conditions</Text>
                </Flex>
                <Flex justifyContent={'center'} gap={'30px'} mt={'20px'}>
                  {/* <Button background={'red'} color={'white'}>Previous</Button> */}
                  <Button background={'teal'} color={'white'} onClick={handleSavePolicy}>Pay</Button>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
}
export default GetQuickQuote;
