import { toast } from "react-toastify";
import {  ADMIN_STATUS_EDIT, AGA_MGA_GET_ALL, ALL_ROLES_TYPE, QUESTIONS_MGA_AGA_ADVISOR_EDIT_TYPE, isLoading } from "../../../../constants/actionConstants";
import {  AgaMgaAllService } from "./service";

export const agaMgaAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AgaMgaAllService.agaMgaAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: AGA_MGA_GET_ALL,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const questionsandassingingEdit = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminProfile = await AgaMgaAllService.questionsandassingingEdit(params);
    const {status, message, data} = adminProfile

    if(status === 200 || 201) {
        dispatch({
            type: QUESTIONS_MGA_AGA_ADVISOR_EDIT_TYPE,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }
dispatch(isLoading(false));

    return adminProfile

}

export const allRolesList = (params) => async (dispatch) => {
  dispatch(isLoading(true));
  try {
    const response = await AgaMgaAllService.allRolesList(
      params
    );
    console.log("Response data action:", response);
    dispatch({
      type:ALL_ROLES_TYPE,
      payload: response,
    });
    dispatch(isLoading(false));
    return response.data;
  } catch (error) {
    console.error(error);
    dispatch(isLoading(false));
    throw error;
  }
};

export const adminMgaAgaAdvisorsStatus = (id, params) => async (dispatch) => {
  dispatch(isLoading(true));
  let adminSatatus = await AgaMgaAllService.adminMgaAgaAdvisorsStatus(id, params);
  const { status, message, data } = adminSatatus

  if (status === 201 || status === 200) {
    dispatch({
      type: ADMIN_STATUS_EDIT,
      payload: message, data
    })
    toast.success(" Successfully")
  } else {
    toast.success(message)
  }

  dispatch(isLoading(false));

  return adminSatatus
}