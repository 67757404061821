import React from 'react'
import Sidebar from '../Sidebar'

const Reports = () => {


return (
    <Sidebar headingText={'Reports'}>
      

      
    </Sidebar>
  )
}

export default Reports
