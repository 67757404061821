import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, TabPanels, Tabs,Box,useToast,Flex,Text,Textarea,Input,Button, InputGroup, InputRightElement,Image, useMediaQuery } from "@chakra-ui/react";
import SuperVisa from "../../SuperVisa";
import ViewQuote from "./ViewQuote";
import { advisorSingleGetQuotes } from '../../Advisor/Dependencies/action';
import { useDispatch, useSelector } from 'react-redux';
import Api from "../../../dependencies/utils/Api";
import { buttonBackgroundColor, buttonColor, primaryBackgroundColor } from "../../../Utils/UniversalColor";
import { ADVISOR_SAVE_POLICY } from "../../../constants/ApplicationUrl";
import visa from '../../../assets/img/visa.png'
import masterCard from '../../../assets/img/masterCard.png'
import amex from '../../../assets/img/amex.png'
import JCB from '../../../assets/img/JCB.png'
import diners from '../../../assets/img/diners.png'
import discover from '../../../assets/img/discover.png'
import { CUSTOMER_SAVE_POLICY } from "../../../constants/actionConstants";
import { customerSavePolicy } from "../Dependencies/action";

const CustomerSingleQuoteView = () => {
    const { id } = useParams();
    const [data,setData]=useState([]);
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0)
    const [dataFromChild, setDataFromChild] = useState('');
    const toast=useToast()
    const navigate=useNavigate()
    const [cardDetails,setCardDetails]=useState({
      cardHolderFirstName:'',
      cardHolderLastName:'',
      idQuote:'',
      quotationNo:'',
      cardHolderEmail:'',
      cardHolderAddress:'',
      cardHolderPostal:'',
      cardHolderPhone:'',
      cardNumber:'',
      expiryDate:'',
      cvv:'',
      amount:''
    })
    const [firstDateOfCover,setFirstDateOfCover]=useState('')
    const [cardType, setCardType] = useState('');
    const [isPaymentChecked, setIsPaymentChecked] = useState(false);
    const handleCheckboxChange = (e) => {
      setIsPaymentChecked(e.target.checked);
    };
    const [isLargerThanMobile] = useMediaQuery("(min-width: 480px)");
    useEffect(() => {
      if (id) {
        dispatch(advisorSingleGetQuotes(id))
          .then(response => {
            const policyHolderName = response.data.policyHolderName;
            const nameParts = policyHolderName.split(' ');
    
            const updatedCardData = {
              cardHolderFirstName: nameParts[0], 
              cardHolderLastName: nameParts.slice(1).join(' '), 
              cardHolderEmail: response.data.policyHolderEmail,
              cardHolderAddress: response.data.policyHolderAddress,
              cardHolderPostal: response.data.policyHolderPostalCode,
              cardHolderPhone: response.data.policyHolderPhone,
              amount: response.data.amount
            };
    
            setFirstDateOfCover(response.data.firstDateOfCover);
            setCardDetails(updatedCardData);
            setData([response.data]); 
            setQuoteId(response.data.idQuote);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      }
    }, [dispatch, id]);
    

  const handleCardChanges =(e)=>{
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
    if (name === 'cardNumber') {
      if (/^4/.test(value)) {
        setCardType('Visa');
      } else if (/^5[1-5]/.test(value)) {
        setCardType('MasterCard');
      } else if (/^3[47]/.test(value)) {
        setCardType('AmericanExpress');
      } else if (/^6(?:011|5[0-9]{2})/.test(value)) {
        setCardType('Discover');
      } else if (/^(?:2131|1800|35\d{3})/.test(value)) {
        setCardType('JCB');
      } else if (/^3(?:0[0-5]|[68][0-9])/.test(value)) {
        setCardType('DinersClub');
      } else {
        setCardType('');
      }
    }
    if(name==='expiryDate'){
      e.target.value = formatExpirationDate(e.target.value)
    }
  }

  function clearNumber (value = '') {
    return value.replace(/\D+/g, '')
  }

const formatExpirationDate =(value)=> {
    const clearValue = clearNumber(value)
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
    }
    return clearValue
  }

  const sendDataToParent = (data) => {
    console.log('Received data from child:', data);
    setDataFromChild(data);
    setTabIndex(data.tab)
  };

  const handleSavePolicy = ()=>{
    const tomorrow = new Date();
    tomorrow.setHours(0, 0, 0, 0);
    const firstDate = new Date(firstDateOfCover);
    firstDate.setHours(0, 0, 0, 0);
    if (firstDate < tomorrow) {
      alert("First date of cover must be greater than today.");
      return;
    }
    if(!cardDetails.cardNumber){
      alert('Please enter card Number')
      return;
    }
    if(!cardDetails.expiryDate){
      alert('Please enter expiry date')
      return;
    }
    if(!cardDetails.cvv){
      alert('Please enter card cvv')
      return;
    }
    if(!isPaymentChecked){
      alert("Please check terms & Conditions")
      return;
    }
    const updateData={
      ...cardDetails,
      idQuote:dataFromChild.data.idQuote ,
      quotationNo:dataFromChild.data.quotationNo ,
    }
    console.log(updateData,'data sending')
      dispatch(customerSavePolicy(updateData)).then((res)=>{
        console.log(res)
        if(res.status===200 || res.status===201){
          alert("Policy Created Successfully")
          navigate('/customer-policies')
        }else{
          alert("Something went wrong")
        }
    })
  }


return (
        <Box>
          <SuperVisa />
          <div>
          <Tabs display={'flex'} flexDirection={['column','column','row']} w={['95%','95%','80%']} m={'auto'} index={tabIndex} style={{ marginTop: "10px" }} variant="unstyled">
            <Box width={["95%","20%","20%"]}>
            <TabList 
                style={{
                  display: "flex",
                  border: "2px solid #088183",
                  backgroundColor: primaryBackgroundColor,
                  padding: "10px",
                  paddingBottom: "10px",
                  marginTop: isLargerThanMobile ? "50px" : "0px",    
                  flexDirection:isLargerThanMobile?'column':'row',            
                  height: 'auto',
                  color: "white",
                  fontSize: isLargerThanMobile ? "16px" : "12px",
                  fontWeight: "700",
                }}
            >
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Get a Quote
              </Tab>
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Confirm & Pay
              </Tab>
              <Tab fontSize={['12px','14px','14px']}
                _selected={{
                  color: "black",
                  fontSize:isLargerThanMobile?"16px":'12px',
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Purchase Policy
              </Tab>
            </TabList>
            </Box>
    
            <TabPanels marginTop={["30px",'50px','20px']} w={["100%",'100%','80%']}>
              <TabPanel>
                <ViewQuote id={id} sendDataToParent={sendDataToParent}/>
              </TabPanel>
              <TabPanel>
              <Box>
                <Text fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}><span style={{ color: 'red' }}>Note :</span>If you are using different Credit Card Make Sure to update Payor’s Card details including Name, Address, email, Cell No and Postal Code or else Card will be declined</Text>
                <Flex direction={['column','column','row']} w={'90%'} m={'auto'} mt={'20px'} gap={'30px'}>
                <Box w={['95%','50%','50%']} border={'1px solid #d5d5d5'}>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}> 
                      <Text>Customer Information</Text></Flex>
                    <Box p={'10px'}>
                      <Flex fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder First Name</Text>
                          <Input  value={cardDetails.cardHolderFirstName} name='cardHolderFirstName' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Last Name</Text>
                          <Input value={cardDetails.cardHolderLastName} name='cardHolderLastName' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                      </Flex>
                      <Box w={'95%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Holder Email:</Text>
                          <Input value={cardDetails.cardHolderEmail} name='cardHolderEmail' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                        <Flex mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-around'}>
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Postal</Text>
                          <Input value={cardDetails.cardHolderPostal} name='cardHolderPostal' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box> 
                        <Box w={'45%'}>
                          <Text fontSize={'12px'}>Card Holder Phone</Text>
                          <Input value={cardDetails.cardHolderPhone} name='cardHolderPhone' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                      </Flex>
                        <Box w={'95%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Holder Address</Text>
                          <Textarea value={cardDetails.cardHolderAddress} name='cardHolderAddress' onChange={handleCardChanges} border='1px solid grey' size={'xs'}/>
                        </Box>
                    </Box>
                </Box>

                <Box w={['95%','40%','40%']} border={'1px solid #d5d5d5'}>
                  <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}> 
                      <Text>Payment Details</Text></Flex>
                      <Box w={'90%'} m={'auto'} mt={'10px'}>
                          <Text fontSize={'12px'}>Card Number</Text>
                          <InputGroup size="sm">
                          <Input
                            pr="4.5rem"
                            maxLength={16}
                            placeholder="Enter Card Number"
                            name='cardNumber' onChange={handleCardChanges}
                          />
                          <InputRightElement width="4.5rem">
                            <Image size='xs' src={cardType==='Visa'?visa:cardType==='MasterCard'?masterCard:cardType==='AmericanExpress'?amex:cardType==='JCB'?JCB:cardType==='DinersClub'?diners:cardType==='Discover'?discover:""} />
                          </InputRightElement>
                        </InputGroup>
                        </Box>
                      <Flex w={'90%'} m={'auto'} mt={'10px'} fontFamily={'Open Sans, sans-serif'} justifyContent={'space-between'}>
                        <Box w={'45%'}>
                        <Text fontSize={'12px'}>Expiry Date</Text>
                        <Input placeholder='Valid Thru' type='tel'pattern='[\d| ]{16,22}' maxLength='19' name='expiryDate' onChange={handleCardChanges} size={'sm'}/>
                        </Box> 
                        <Box w={'25%'}>
                          <Text fontSize={'12px'}>cvv</Text>
                          <Input maxLength={3} placeholder='cvc' name='cvv' onChange={handleCardChanges} size={'sm'}/>
                        </Box>
                      </Flex>
                    <Flex w={'90%'} m={'auto'} p={'5px 20px'} justifyContent={'space-between'} mt={'20px'} background={'teal'} color={'white'} borderRadius={'5px'}>
                    <Text>Final Payment</Text>
                    <Text>${Math.round(cardDetails.amount)}</Text>
                    </Flex>
                    <Flex w={'90%'} m={'auto'} fontSize={'12px'} gap={'10px'} mt={'10px'}>
                    <input type="checkbox" onChange={handleCheckboxChange} checked={isPaymentChecked}  />                    
                    <Text>By checking the box, I / We agree to the following terms and conditions</Text>
                    </Flex>
                      </Box>
                  </Flex>
                {/* <Flex w={'90%'} m={'auto'} p={'10px'} justifyContent={'space-between'} mt={'20px'} background={'teal'} color={'white'} borderRadius={'5px'}>
                  <Text>Final Payment</Text>
                  <Text>$20</Text>
                </Flex> */}
                <Flex justifyContent={'center'} gap={'30px'} mt={'20px'}>
                  <Button background={'teal'} color={'white'} onClick={handleSavePolicy}>Pay</Button>
                </Flex>
              </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </div>
        </Box>
      );
    }

export default CustomerSingleQuoteView
