import React, { useState } from 'react'
import Sidebar from '../Sidebar'
import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import CustomInput from '../../../Utils/Input/Index';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { FaRegEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";

const GuestEnquiry = () => {
  const [colDefs, setColDefs] = useState([  
    { field: "Name",width:130,filter:true,},
    { field: "Email",width:150,filter:true},
    { field: "phone",width:140 },
    { field: "message",width:120 },
    { field: "address",width:100 },]);
    const [rowData, setRowData] = useState([
      { id: 1, Name: "Navneet",  Email: 'navneet@gmail.com', phone:'8617555572', address: 'Rajasthan', message: 'Hey this navneet' },
      { id: 1, Name: "Faraz",  Email: 'faraz@gmail.com', phone:'9677926851', address: 'Jharkhand', message: 'Hey this faraz wanna inqure about insurace' },
    ]);
  
  
    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];
  
  
    
  
    

  return (
    <Sidebar headingText={'Guest Details'}>
        
  <Box w={'90%'} m={'auto'} mt={'70px'}>
  <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
    <Flex justifyContent={'space-between'} mt={'10px'} mb={'25px'}>
        
        <div>
         
            <CustomInput/>
          {/* <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} /> */}
        </div>
    </Flex>

    <Box>

    <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            // frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>



    
    </Box>

    </Box>
  </Box>
</Sidebar>
  )
}

export default GuestEnquiry
