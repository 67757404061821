import { AADVISORS_ALL_GET, AGA_ASSING_EDIT, ALL_AGA_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"
export const AsingAgaService = {

    allAdvisorsGet() {
        return Api.GET(AADVISORS_ALL_GET).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    allAGAGet() {
        return Api.GET(ALL_AGA_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    agaAssingEdit(params) {
        return Api.PUT(AGA_ASSING_EDIT,params).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

}