import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text,Flex, Box, Radio } from '@chakra-ui/react';

export const getMaxSelectableDate = () => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return formattedDate;
};

export const getMinSelectableDate = () => {
  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 89);
  return minDate.toISOString().split('T')[0]; 
};

export const BeneficiaryDetailsModal = ({ isOpen, onClose, onSubmit }) => {
  const [isRadioSelected, setIsRadioSelected] = useState(false);

  const handleRadioChange = () => {
    setIsRadioSelected(!isRadioSelected);
  };

  const handleSubmit = () => {
    if (isRadioSelected) {
      onSubmit();
      onClose();
    } else {
      alert("Please confirm that all travellers are eligible to purchase this policy.");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered  zIndex={99999999} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>To be eligible for coverage you, on the effective date, must be:</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>1. s of age; and</Text>
          <Text> 2. ineligible for benefits under a government health insurance plan; and</Text>
          <Text> 3. residing in Canada on a temporary basis; and</Text>
          <Text> 4. one of the following:</Text>
          <></>
          <ul style={{fontSize:'14px',fontFamily:'poppins',paddingLeft:'20px',lineHeight:'22px'}}>
            <li> a student with proof of full-time admission in a recognized Canadian institution of learning; or</li>
            <li> a student completing post doctorate research in a recognized Canadian institution of learning; or</li>
            <li> the spouse or dependent child of the insured student and residing with them on a full-time basis; or</li>
            <li>the parent, legal guardian, teacher or chaperone of the insured student.
                </li>
          </ul>        
          <Flex alignItems={'center'} gap={'15px'}>
            <Radio border={'1px solid grey'} mt={'10px'} size='sm' name='1' colorScheme='blue' isChecked={isRadioSelected} onChange={handleRadioChange}/>
            {/* </Radio> */}
            <Text pt={'9px'} fontWeight={'600'}>I confirm that all travellers are eligible to purchase this policy</Text>   
            </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex gap="50px" justifyContent='center'>
          <Button colorScheme="blue" onClick={handleSubmit}>Yes</Button>
          <Button mr={3} onClick={onClose}>No</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const VTCDetailsModal = ({ isOpen, onClose, onSubmit }) => {
  const [isRadioSelected, setIsRadioSelected] = useState(false);

  const handleRadioChange = () => {
    setIsRadioSelected(!isRadioSelected);
  };

  const handleSubmit = () => {
    if (isRadioSelected) {
      onSubmit();
      onClose();
    } else {
      alert("Please confirm that all travellers are eligible to purchase this policy.");
    }
  };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>To be eligible for coverage you, on the effective date, must be:</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}>
            <Text pt={'5px'}>1. Be a visitor to Canada or a person in Canada under a valid work or student visa, a Canadian or an immigrant not eligible for benefits under a government health insurance plan; and</Text>
            <Text pt={'5px'}>2. be at least 15 days of age and less than 90 years of age (less than 70 years of age for Premium plan); and</Text>
            <Text pt={'5px'}> 3. not be travelling against the advice of a physician and/or not have been diagnosed with a terminal illness; and</Text>
            <Text pt={'5px'}>4. not be experiencing new or undiagnosed signs or symptoms and/or know of any reason to seek medical attention; and</Text>
            <Text pt={'5px'}>5. not require assistance with the activities of daily living(dressing, bathing, eating, using the toilet or getting in or out of a bed or chair).</Text>
            <Flex alignItems={'center'} gap={'15px'}>
            <Radio border={'1px solid grey'} mt={'10px'} size='sm' name='1' colorScheme='blue' isChecked={isRadioSelected} onChange={handleRadioChange}/>
            {/* </Radio> */}
            <Text pt={'9px'} fontWeight={'600'}>I confirm that all travellers are eligible to purchase this policy</Text>   
            </Flex>
            </Box>                    
          </ModalBody>
          <ModalFooter>
            <Flex gap="50px" justifyContent='center'>
              <Button colorScheme="blue" onClick={handleSubmit}>Yes</Button>
              <Button mr={3} onClick={onClose}>No</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};

export const validateMobileNumber = (number) => {
    const mobileRegex = /^[1-9]\d{9,14}$/;
    return mobileRegex.test(number);
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const FamilyModal=({isOpen,onClose,onSubmit})=>{


  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>To be eligible for family coverage you, on the effective date, must be:</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}>
          <Text pt={'5px'}> You and your spouse up to age 69 &</Text>
          <Text pt={'5px'}>Your dependent children</Text>
          <Text pt={'5px'}> -Unmarried & living at home; and</Text>
          <Text pt={'5px'}>-21 years of age; or,</Text>
          <Text pt={'5px'}>-26 years of age if full time student; or, Coverage dates must be the same</Text>
          <Text pt={'5px'}> -Unmarried & living at home; and</Text>
          <Text pt={'5px'}>All family members must live at the same address while in Canada</Text>
          <Flex alignItems={'center'} gap={'15px'}>
          {/* </Radio> */}
          {/* <Text pt={'9px'} fontWeight={'600'}>I confirm that all travellers are eligible to purchase this policy</Text>    */}
          </Flex>
          </Box>                    
        </ModalBody>
        <ModalFooter>
          <Flex gap="50px" justifyContent='center'>
            <Button mr={3} onClick={onClose} colorScheme='green' size={'sm'}>Save</Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}