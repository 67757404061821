import { ADMIN_INUSRED_DETAILS } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminInsuredListOfDetails = {
    adminInsuredList() {
        return Api.GET(ADMIN_INUSRED_DETAILS).then((response) => {
            // console.log(response,'responserviceadvisor')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    }
}