// SuperAdminPanel.js
import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
const SuperAdminDashbaord = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = location.state?.userData;

  const dataarray=userData.listOfMenus

console.log("faraxdsta12",dataarray)
  return (
   <Box p={"200px"}>
    Super Admin Dashboard
    <Flex>
    <Text fontWeight="bold" mb="4">Components</Text>
      {/* Sidebar */}
      <Box w="200px" bg="gray.200" p="4">
      {dataarray.map((item, index) => (
          <Link to={item}>{item}</Link>
        ))}
        
        {/* <Link to="/super-admin-permission">
        Permissions
        </Link>

        <Link to="/admin-create">
        Create Admin
        </Link> */}
      </Box>
      {/* Main Content */}
      <Box flex="1" p="4">
        <h2>Super Admin Dashboard</h2>
        {/* Your main content here */}
      </Box>
      
    </Flex>
   </Box>
  );
};

export default SuperAdminDashbaord;


// return (
//   <Box p={"200px"}>
//    Super Admin Dashboard
//    <Flex>
//      {/* Sidebar */}
//      <Box w="200px" bg="gray.200" p="4">
//        <Text fontWeight="bold" mb="4">Components</Text>
//        <Link to="/super-admin-permission">
//        Permissions
//        </Link>

//        <Link to="/admin-create">
//        Create Admin
//        </Link>
//      </Box>
//      {/* Main Content */}
//      <Box flex="1" p="4">
//        <h2>Super Admin Dashboard</h2>
//        {/* Your main content here */}
//      </Box>
     
//    </Flex>
//   </Box>
//  );