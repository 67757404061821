import React from "react";
import httpService from "./axios-interceptor";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./dependencies/route/index";
import { storage } from "./dependencies/store/storage";
import { useDispatch } from "react-redux";
import { setLogin } from "./dependencies/action";
import Footer from "./Components/Common/Footer";
import { ChakraProvider } from "@chakra-ui/react";
import CustomerHeader from "./Components/Common/Header/CustomerHeader";

httpService.setupInterceptors();

const AppRoutes = ({ HomePage }) => {
  const dispatch = useDispatch();
  const token = storage.getJwtToken();
  if (token) {
    dispatch(setLogin());
  } else {
  }


console.log(HomePage)


  return (
    <BrowserRouter>
      <ChakraProvider>
        <CustomerHeader />
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={index.toString()}
              path={route.path}
              element={route.element}
            />
          ))}
        {HomePage && (
            <Route path="/" element={HomePage} />
          )}
        </Routes>
        <Footer />
      </ChakraProvider>
    </BrowserRouter>
  );
};

export default AppRoutes;
