import { ADMIN_USERS_LIST, AGA_MGA_ALL, CUSTOMER_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const customerAllService = {

    customerAll() {
        return Api.GET(CUSTOMER_LIST).then((response) => {
            console.log(response,'cUSTOMERlIST')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    


}