const initialState = {
    theme: {},
    content: {}
};

const themeReducer = (state = initialState, action) => {
  // console.log(action,'actin')
    switch (action.type) {
      case 'SET_THEME_DATA':
        return {
          ...state,
          theme: action.payload.theme,
          content: action.payload.content,
          homepageComponent:action.payload.homepageComponent
        };
      default:
        return state;
    }
};

export default themeReducer;
