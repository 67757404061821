import { ADMIN_INSURED_GETDETAILS_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminInsuredListOfDetails } from "./service";

export const adminInsuredList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminInsuredListOfDetails.adminInsuredList(
        params
      );
    //   console.log("Response data action:", response);
      dispatch({
        type: ADMIN_INSURED_GETDETAILS_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };