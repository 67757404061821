import { toast } from "react-toastify";
import { ADMIN_PROFILE_EDIT_TYPE, ADMIN_PROLFILE, isLoading } from "../../../../constants/actionConstants";
import { AdminProfileService } from "./service";

export const adminProfileList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminProfileService.adminProfileList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADMIN_PROLFILE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const adminProfileEdet = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminProfile = await AdminProfileService.adminProfileEdet(params);
    const {status, message, data} = adminProfile

    if(status === 201) {
        dispatch({
            type: ADMIN_PROFILE_EDIT_TYPE,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }


   

    dispatch(isLoading(false));

    return adminProfile

}