import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Flex } from '@chakra-ui/react';
import { FaRegEye } from "react-icons/fa6";
import { allAdvisorsGet } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const AssignAga = () => {
  const [advisorsData, setAdvisorsData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(allAdvisorsGet())
      .then((res) => {
        console.log("Advisor profile data fetched successfully", res);
        setAdvisorsData(res);
      })
      .catch((error) => {
        console.error("Error fetching advisor profile data:", error);
      });
  }, [dispatch]);

  const getParentFirstName = (parentId) => {
    const parent = advisorsData.find(elem => elem.idUser === parentId);
    return parent ? parent.firstName : '';
  };

  const handleViewClick = (advisor) => {
    navigate(`/admin/assign-aga/assing-aga-advisor/${advisor}`);
  };

  return (
    <Sidebar headingText={'ADVISOR UNDER AGA'}>
      <Box w={'90%'} m={'auto'} mt={'70px'}>
        <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
              <col style={{ width: '15%' }} />
            </colgroup>
            <thead>
              <tr style={{ borderBottom: '1px solid #dddddd' }}>
                <th style={{ textAlign: 'left', padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Advisor Name</th>
                <th style={{ textAlign: 'left', padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>AGA Name</th>
                <th style={{ padding: '10px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Assign AGA</th>
              </tr>
            </thead>
            <tbody>
              {advisorsData.map((advisor, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                  <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{advisor.firstName}</td>
                  <td style={{ textAlign: 'left', padding: '10px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
  {advisor.parent ? advisor.parent.firstName : ''}
</td>
                  <td style={{ padding: '10px', fontSize: '22px', fontFamily: 'Open Sans, sans-serif' }}>
                    <Flex onClick={() => handleViewClick(advisor.idUser)} color={'#4caf50'} justifyContent={'center'} alignItems='center'>
                      <FaRegEye />
                    </Flex>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default AssignAga;
