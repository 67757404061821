import React from 'react'
import Sidebar from '../../Sidebar'

const UserCommissionReport = () => {



return (
    <Sidebar headingText={'User Commission Report'}>
      
    </Sidebar>
  )
}

export default UserCommissionReport
