import { SEND_POLICY } from "../../constants/ApplicationUrl";
import Api from "../../dependencies/utils/Api";




export const sendPolicy = (policyNo) => {
    const url = `${SEND_POLICY}${policyNo}`;
    return Api.POST(url)
        .then((res) => {
            console.log(res);
        })
        .catch((error) => {
            console.error('Error sending policy:', error);
            alert('Failed to email policy. Please try again.');
        });
};
