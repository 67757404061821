import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, Image, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { FaUsers } from "react-icons/fa";
import { TbUserSquareRounded } from "react-icons/tb";
import { TbUsersGroup } from "react-icons/tb";
import { IoDocumentsOutline } from "react-icons/io5";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Link, useNavigate } from 'react-router-dom';
import { pendingEndoresementList } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { adminPolicesList } from '../Policies/AdminAllPolicies/dependencies/action';
import { customerAll } from '../Customers/dependencies/action';
import { agaMgaAll } from '../MgaAgaAdvisor/dependencies/action';
import { adminRefundRequestList } from '../RefundRequests/dependencies/action';
import { adminRenewalListAll } from '../Policies/RenewalPolicies/dependencies/action';


const AdminDashboard = () => {
const navigate=useNavigate()
const [pendingEndData,setPendingEndData]=useState([])
const dispatch=useDispatch()
const [allPolicies,setAllPolicies]=useState([])
const [customers,setCustomers]=useState([])
const [agaMga,setAgaMga]=useState([])
const [advisor,setAdvisor]=useState([])
const [aga,setAga]=useState([])
const [mga,setMga]=useState([])
const [refund,setRefund]=useState('')
const [renewalData,setRenewalData]=useState([])
const pagination = true;
const paginationPageSizeSelector = [10,20,50,100];

const downloadCellRenderer = (params) => {
  const {data}=params
  console.log(data,'data')
  const handleEndorsement = () => {
    navigate(`/admin/policyEndorsements/${data.id}`)
    console.log('Download clicked for row:', params.data);
  };
return(
  <div>
    <Button _hover={{color:'white',background:'#9c27b0'}} background={'#9c27b0'} color={'white'} size={'xs'} onClick={handleEndorsement}>View</Button>
</div>
)
};

const [colDefs, setColDefs] = useState([
  { field: "employeeName",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:280,filter:true},
  { field: "requestedDate",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:200,filter:true},
  { field: "policyNo",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:260,filter:true},
  { field: "endorsementType",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:220,filter:true},
  { field: "cancellationDate",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:200,filter:true},
  { field: "refundAmount",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:190,filter:true},
  { field: "remarks",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:280,filter:true},
  { field: "action",cellRenderer: downloadCellRenderer,wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:180,},
]);
const [rowData, setRowData] = useState([]);

useEffect(() => {
  getAllPolicies()
  getAllCustomers()
  getAllMga()
  getRefundList()
  dispatch(pendingEndoresementList())
      .then((res) => {
        console.log(res,'endorsement data')
        setPendingEndData(res.data)
        const formattedData = res.data.map((quote, index) => ({
          id: quote.idPolicy,
          // employeeName: quote.policyDetailsEntity.user.firstName ,
          // designation: quote.quote.user.roles.name,
          requestedDate: quote.requestedDate,
          policyNo: quote.policyNo,
          endorsementType: quote.endorsementType,
          cancellationDate: quote.cancellationDate,
          refundAmount: quote.refundAmount,
          remarks:quote.remarks,
        }));
        setRowData(formattedData);
      })
      .catch((error) => {
          console.error("Error fetching data:", error);
      });
}, [dispatch]);

const getAllPolicies =()=>{
  dispatch(adminPolicesList()).then((res)=>{
    // console.log(res.data)
    setAllPolicies(res.data)
  })
}

const getAllCustomers=()=>{
  dispatch(customerAll()).then((res)=>{
    // console.log(res)
    setCustomers(res)
  })
}

const getAllMga=()=>{
  dispatch(agaMgaAll()).then((res)=>{
    console.log(res,'mgaagares')
    const array=res
    const filteredData = array.filter(item => item.roles.name === 'Advisor');
        setAdvisor(filteredData)
    const voidData= array.filter(item=>item.roles.name === "AGA")
        setAga(voidData)
    const cancellationData = array.filter(item=>item.roles.name === "MGA")
        setMga(cancellationData)
    setAgaMga(res)
  })
}

const getRefundList=()=>{
  dispatch(adminRefundRequestList())
    .then(res => {
      console.log(res,'refund requests')
      setRefund(res.length);
    })
}


const handlePedning=()=>{
  navigate("/admin/dashboard/pendingEndorsement")
}


useEffect(() => {
  dispatch(adminRenewalListAll()).then((res) => {
    console.log(res,'renewal data')
        setRenewalData(res)
        });

}, [dispatch]);


  return (
    <Sidebar headingText="Dashboard">
      <Box paddingBottom={'50px'}>
        <Flex w={'95%'} m={'auto'} mt={'50px'} justifyContent={'space-between'}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/all-policies'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Policies</Text>
                <Text fontWeight={'600'}>{allPolicies.length}</Text>
              </Box>
            </Flex>
            <hr />
          </Link>
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'  cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
          <Link to='/admin/customers'>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUsersGroup />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Users</Text>
                <Text fontWeight={'600'}>{customers.length}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to="/admin/mga/aga/advisor">
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <FaUsers />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All MGA</Text>
                <Text fontWeight={'600'}>{mga.length}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to={'/admin/mga/aga/advisor'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
            <Flex fontSize={'26px'}>
            <TbUserSquareRounded />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All AGA</Text>
                <Text fontWeight={'600'}>{aga.length}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
        </Flex>
        <Flex w={'95%'} m={'auto'} mt={'30px'} justifyContent={'space-between'}>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Link to={'/admin/all-advisors'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>All Advisors</Text>
                <Text fontWeight={'600'}>{advisor.length}</Text>
              </Box>
            </Flex>
            </Link>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'14px'} fontFamily={'poppins'}>Refund Requests</Text>
                <Text fontWeight={'600'}>{refund}</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text fontSize={'13px'} fontFamily={'poppins'}>Subscriptions</Text>
                <Text fontWeight={'600'}>381</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
          <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' cursor={'pointer'} _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} w={'22%'} padding={'20px'} background='white' borderRadius={'10px'}>
            <Flex gap={'20px'} pb={'5px'} justifyContent={'space-between'}>
              <Flex fontSize={'26px'}>
              <IoDocumentsOutline />
              </Flex>
              <Box textAlign={'right'}>
                <Text onClick={handlePedning} fontSize={'14px'} fontFamily={'poppins'}>Pending Endorsements</Text>
                <Text fontWeight={'600'}>{pendingEndData.length}</Text>
              </Box>
            </Flex>
            <hr />
          </Box>
        </Flex>

        <Box _hover={{ backgroundColor: 'white' }} borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'95%'} m={'auto'} mt={'50px'}>
      <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton _hover={{ backgroundColor: 'white' }}>
              <Box fontSize={'18px'} p={'5px'} as='span' flex='1' textAlign='left'>
                Policy Endorsement Pending List <span style={{ color: 'red' }}>({pendingEndData.length})</span> <br />
                <Text fontSize={'12px'}>
                  pending for approval
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <div className="ag-theme-quartz" style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
              <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                domLayout='autoHeight'
                paginationPageSizeSelector={paginationPageSizeSelector}
                pagination={pagination}
                paginationPageSize={10}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
              />
            </div>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
        <Box>
          <Flex w={'95%'} m={'auto'} justifyContent={'space-between'}>
            <Box w={'49%'} _hover={{backgroundColor:'white'}} mt={'50px'}>
            <Accordion borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton  _hover={{backgroundColor:'white'}}>
                  <Box fontSize={'14px'} p={'5px'} as='span' flex='1' textAlign='left'>
                  Policy Subscription - Pending Verification <span style={{color:'red'}}>(1)</span>  <br />
                  <Text fontSize={'12px'}>
                  April Month - Pending for verification
                  </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
                  <AccordionPanel pb={4}>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Policy No</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Customer Name</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Installment Date</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount + Installment Charges (CAD)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2023PTM41160</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Satendra Manishankar Dube	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-04-02	</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>311+10</td>
                    </tr>
                </tbody>
                 </table>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>

            <Box w={'49%'} _hover={{backgroundColor:'white'}} mt={'50px'}>
            <Accordion borderRadius={'10px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} defaultIndex={[1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton  _hover={{backgroundColor:'white'}}>
                  <Box fontSize={'14px'} p={'5px'} as='span' flex='1' textAlign='left'>
                  Policy Renewal List - 30 Days <span style={{color:'red'}}>({renewalData.length})</span>  <br />
                  <Text fontSize={'12px'}>
                  2024-04-30 - 2024-05-30
                  </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
                  <AccordionPanel pb={4}>
                  <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Policy No</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Customer Name</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Installment Date</th>
                        <th style={{   textAlign: 'left', padding: '4px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount + Installment Charges (CAD)</th>
                    </tr>
                </thead>
                <tbody>
                {renewalData && renewalData.map((ele)=>(
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.policyNo}</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.quote.policyHolderName}</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.createdAt}</td>
                        <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.quote.quoteAmount}</td>
                    </tr>
                    ))}
                </tbody>
                 </table>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Box>
          </Flex>
        </Box>

      </Box>
    




    </Sidebar>
  );
};

export default AdminDashboard;
