import React from 'react'
import SidebarServices from '../SidebarServices/index'


const ServiceDashboard = () => {




return (
    <SidebarServices headingText='Dashboard'>
      
    </SidebarServices>
  )
}

export default ServiceDashboard
