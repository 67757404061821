    import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { adminProfileEdet, adminProfileList } from './dependencies/action';



const AdminProfile = () => {
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState([]);
    const { id } = JSON.parse(localStorage.getItem("code_dev"))
    const [formData, setFormData] = useState({
        id: id,
        firstName: profileData.firstName || '',
        lastName: profileData.lastName || '',
        email: profileData.email || '',
        address: profileData.address || '',
        phone: profileData.phone || ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const handleSubmit = (e) => {
 
        console.log(formData)
        dispatch(adminProfileEdet(formData)).then(res => {
            const { status, data } = res
            console.log(res)
        })
        // setFormData({
        //     id: id,
        //     firstName: '',
        //     lastName: '',
        //     email: '',
        //     address: "",
        //     phone: ''
        // });
    };

    useEffect(() => {
        dispatch(adminProfileList())
            .then((res) => {
                console.log("Admin profile data fetched successfully", res);
                setProfileData(res)
                
                
                   
                    setFormData({
                        id: id,
                        firstName: res.firstName || '',
                        lastName: res.lastName || '',
                        email: res.email || '',
                        address: res.address || '',
                        phone: res.phone || ''
                    });


                
            })
            .catch((error) => {
                console.error("Error fetching admin profile data:", error);
            });
    }, [dispatch]);

    const handleLogout = () => {
        storage.clear()
        window.location.href = '/'
    }
    return (
        <Sidebar headingText="Profile">
            <Box>
                <Flex w={'90%'} m={'auto'} mt={'60px'} justifyContent={'space-between'}>
                    {/* Edit Profile Section */}
                    <Box boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} p={'15px'}>
                        <Text>Edit Profile</Text>
                        <Text fontSize='12px'>Complete your profile</Text>
                        
                            <Box fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} p={'20px'} pb={'10px'}>
                                <Flex gap={'20px'} pb={'15px'}>
                                    <Box>
                                        <Text>First Name</Text>
                                        <Input id="firstName" name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                                    </Box>
                                    <Box>
                                        <Text>Last Name</Text>
                                        <Input name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                                    </Box>
                                    <Box>
                                        <Text>Email</Text>
                                        <Input name="email"
                                        readOnly
                                            value={formData.email}
                                            onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                                    </Box>
                                    <Box>
                                        <Text>Phone</Text>
                                        <Input name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}  border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                                    </Box>
                                </Flex>
                                <Flex gap={'20px'} mt={'10px'} mb={'10px'}>
                                    <Box>
                                        <Text>Address</Text>
                                        <Input  name="address"
                                            value={formData.address}
                                            onChange={handleChange} placeholder='Address' border={'none'} borderBottom={'1px solid #c9c9c9'} size={'xs'} />
                                    </Box>
                                    
                                </Flex>
                                <Flex justifyContent={'end'}>
                                    <Button onClick={handleSubmit} size={'sm'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'}>Update Profile</Button>
                                </Flex>
                            </Box>
                        
                    </Box>

                    {/* Admin Details Section */}
                    <Box w={'30%'} textAlign={'center'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} p={'15px'}>
                       
                            <>
                                <Flex alignItems={'center'} justifyContent={'center'} mt={'-40px'}>
                                    <Image w={'70px'} src='https://travelmedicare.com/public/assets/admin-img/user.png' />
                                </Flex>
                                {/* <Text pt={'15px'}>{profileData.roles.name}</Text> */}
                                <Text fontFamily={'poppins'} pt={'10px'} fontSize={'14px'}>
                                    {formData.firstName} {formData.lastName}
                                </Text>
                                <Text fontFamily={'Open Sans,sans-serif'} pt={'10px'} fontSize={'12px'}>
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters...
                                </Text>
                                <Flex justifyContent={'center'}>
                                    <Button size={'xs'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'}>
                                        Follow
                                    </Button>
                                </Flex>
                            </>
                        
                    </Box> 
                </Flex>
            </Box>
        </Sidebar>
    );
}

export default AdminProfile;
