import { Box } from '@chakra-ui/react';
import React, { useState } from 'react'
import { MagicMotion } from "react-magic-motion";
import { menuIcons } from '../Admin/AdminSidebar/MenuIcons';
import { Link } from 'react-router-dom';



const ServiceSidebar = ({ onWidthChange }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [menus,setMenus]=useState(['Dashboard','Themes','My Theme','Support'])
    const handleToggleSidebar = () => {
      onWidthChange(isCollapsed ? 14 : 3.3);
      setIsCollapsed(!isCollapsed);
    };

return (
    <Box>
      <MagicMotion>
        <aside
          style={{
            backgroundColor: "white",
            width: isCollapsed ? "3.3rem" : "14rem",
            fontFamily:'Open Sans,sans-serif',
            fontSize:'14px',
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflow: "hidden",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            marginBottom: "50px",
            position: "fixed",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              justifyContent: "space-between",
              padding:"10px",
              paddingLeft: "15px",
            }}
          >
            {!isCollapsed && <h4 style={{ margin: 0 }}>Admin Panel</h4>}

            <button
              style={{ cursor: "pointer", padding: 0, border: 0 }}
              onClick={handleToggleSidebar}
              title={isCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
            >
              {isCollapsed ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12.9999V10.9999H15.4853L12.2427 7.75724L13.6569 6.34303L19.3137 11.9999L13.6569 17.6567L12.2427 16.2425L15.4853 12.9999H1Z"
                    fill="currentColor"
                  />
                  <path
                    d="M20.2877 6V18H22.2877V6H20.2877Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                <svg
                  style={{ minWidth: "24px", minHeight: "24px" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.2877 11.0001V13.0001H7.80237L11.045 16.2428L9.63079 17.657L3.97394 12.0001L9.63079 6.34326L11.045 7.75748L7.80236 11.0001H22.2877Z"
                    fill="currentColor"
                  />
                  <path d="M3 18V6H1V18H3Z" fill="currentColor" />
                </svg>
              )}
            </button>
          </div>
          <Box overflowY={'scroll'} h={'85vh'} scrollbarWidth={'2px'} scrollbarColor={'red'}>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 0,  
                padding: 0,
              }}
            >
              {
                menus.map((menuItem,index)=>(  
                  <Link to={`/service/${menuItem.replace(/\s+/g, "-").toLowerCase()}`}
                    >             
              <li
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  background: activeItem === menuItem ? "#d0ceff" : "white",
                  padding: isCollapsed ? "3px 6px" : "5px 10px",
                  margin: isCollapsed ? "5px 5px" : "5px 15px",
                  borderRadius: '10px',
                  fontSize:isCollapsed?'16px':'12px',
                  color: activeItem === menuItem ? '#5422ed' : 'black',
                  fontFamily:'Open Sans,sans-serif',
                  fontWeight:'500',
                  color:'#262626'
                }}
                
              >
                <svg
                  style={{
                    minWidth:isCollapsed?'5px':'24px',
                    minHeight:isCollapsed?'5px':'24px',
                    width:isCollapsed?'5px':'24px',
                    height:isCollapsed?'5px':'24px',
                    viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",
                    visibility: isCollapsed ? "hidden" : "visible",
                    color:'grey'
                  }}
                  fill="grey"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {menuIcons[menuItem]}
                </svg>
                {menuItem}
              </li> 
              </Link>
               ))
              }
            </ul>
          </Box>
        </aside>
      </MagicMotion>
    </Box>
  )
}

export default ServiceSidebar
