import { VISITOR_QUOTE_LIST } from "../../constants/actionConstants";

const initialState = {
  vistorQuote: [],
};

export default (state = initialState, action)=> {
  switch (action.type) {
    case VISITOR_QUOTE_LIST:
      
        return { ...state, vistorQuote: action.payload };
        
    default:
      return state;
  }
}
