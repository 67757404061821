import { Box, Button, Flex, Grid, GridItem, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, Input, Textarea, useToast, Tooltip } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { primaryBackgroundColor } from '../../../Utils/UniversalColor'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { supporTicketAllById } from '../dependencies/action'
import { useDispatch } from 'react-redux'
import { API_URL, SUPPORT_REPLY_MESSAGE } from '../../../constants/ApplicationUrl'
import Api from '../../../dependencies/utils/Api'


const ViewSingleTicket = () => {
    const dispatch = useDispatch()
    const { id } = useParams();
    const [data, setData] = useState([])
    const [reply, setReply] = useState([])
    const toast = useToast()
    const [ticketStatus, setTicketStatus] = useState('')
    const [formData, setFormData] = useState({
        description: '',
        files: []
    });
    const chatBoxRef = useRef(null)
    const { role } = JSON.parse(localStorage.getItem("code_dev"))
    

    useEffect(() => {
        getTicketDetails()
    }, [dispatch, id]);

    useEffect(() => {
        scrollToBottom()
    }, [data])

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight
        }
    }

    const getTicketDetails = () => {
        if (id) {
            dispatch(supporTicketAllById(id))
                .then(response => {
                    console.log(response.data, 'res')
                    setData(response.data);
                    setTicketStatus(response.data.status)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    const parseJSONMessage = (message) => {
        try {
            const parsedMessage = JSON.parse(message);
            return parsedMessage.message || '';
        } catch (error) {
            console.error('Error parsing JSON message:', error);
            return '';
        }
    }

    const handleSubmit = () => {
        console.log(formData, 'formData');
        const { description, files } = formData;
        const formDataToSend = new FormData();
        formDataToSend.append('description', description);
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formDataToSend.append('file', files[i]);
            }
        }
        const { id } = JSON.parse(localStorage.getItem("code_dev"));
        formDataToSend.append('userId', id);
        const id1 = data.id;
        for (let pair of formDataToSend.entries()) {
            console.log(pair[0] + ': ' + pair[1]);
        }
        Api.POSTDATA(`${SUPPORT_REPLY_MESSAGE}/${id1}`, formDataToSend).then((res) => {
            console.log(res, 'response reply')
            if (res.status === 200 || res.status === 201) {
                setFormData({ description: '', files: [] })
                getTicketDetails();
                toast({
                    title: 'Reply Added',
                    description: "We'll get back to you.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } else {
                alert("Something went wrong.")
            }
        })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    };

    const handleDownload = (filename) => {
        const url = `${API_URL()}/file/downloadFileOfTicket/${filename}`;
        window.open(url, '_blank');
    };

    const handleFileChange = (event) => {
        setFormData({ ...formData, files: event.target.files });
    };

return (
        <Box pb={'120px'}>
            <Box pt={['100px', '80px', '80px']} background={'rgb(247, 247, 247)'}>
                <Box w={'90%'} m={'auto'} pb={'10px'}>
                    <Link to='/all-tickets'>
                        <Text color={'blue'} fontFamily={'poppins'}>Home</Text>
                    </Link>
                    <Flex justifyContent={'space-between'}>
                        <Text fontSize={['22px', '26px', '26px']}>View Support Ticket</Text>
                        {/* <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}}>Create Ticket</Button> */}
                    </Flex>
                </Box>
            </Box>
            <hr />
            <Flex direction={['column', 'column', 'row']} w={'90%'} m={'auto'} mt={'70px'} justifyContent={'space-between'}>
                <Box w={['95%', '85%', '35%']}>
                    <Grid templateColumns={'repeat(2, 1fr)'} pb={'40px'}>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Refrence Number: </Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{data.referenceNo}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Create At:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Create At: {new Date(data.createdAt).toLocaleString()}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Last Updated At:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Last Updated At: {new Date(data.updatedAt).toLocaleString()}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Subject:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Subject: {data.subject}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Issue:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Issue: {data.description}</Text>
                        </GridItem>

                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Status:   </Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Status: {data.status === 1 ? 'Open' : data.status === 2 ? 'In Progress' : 'Resolved'}</Text>

                        </GridItem>
                    </Grid>
                    <hr />
                    <Flex mt={'20px'} justifyContent={'space-between'}>
                        <Text fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>Latest Reply</Text>
                        <Text color={'rgb(144, 144, 144)'} fontSize={'16px'} fontFamily={'Montserrat,Sans-serif'}>From Team</Text>
                    </Flex>
                    <Text mt={'15px'} fontSize={'14px'} fontFamily={'Montserrat, sans-serif'}>
                        {data.description}
                    </Text>
                </Box>
                <Box w={['95%', '85%', '35%']} mt={['30px', '10px', '0px']}>
                    <Text fontFamily={"NeueMontreal, sans-serif"} fontSize={'18px'} pb={'10px'}>Previous Ticket Sessions</Text>
                    <Accordion color={"black"} allowMultiple fontFamily={"NeueMontreal, sans-serif"}>
                        <AccordionItem
                            padding={"5px"}
                            mb={"10px"}
                            _expanded={{
                                borderRadius: "30px",
                            }}
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton
                                            style={{
                                                backgroundColor: primaryBackgroundColor,
                                                color: 'white',
                                            }}
                                        >
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                fontSize={"14px"}
                                            >
                                                Chat History
                                            </Box>
                                            {isExpanded ? (
                                                <MinusIcon fontSize="12px" />
                                            ) : (
                                                <AddIcon fontSize="12px" />
                                            )}
                                        </AccordionButton>

                                    </h2>
                                    <AccordionPanel
                                        fontSize={"14px"}
                                        style={{
                                            padding: '0px',
                                            backgroundColor: "white",
                                        }}
                                        fontFamily={'Montserrat,Sans-serif'}
                                        lineHeight={"22px"}
                                    >
                                        <Box h={'250px'} overflowY="scroll" ref={chatBoxRef}>
                                            <Flex m={'5px'} marginLeft={'48%'}
                                                bg="blue.500"
                                                color="white"
                                                p={3}
                                                justifyContent={'right'}
                                                borderRadius={10}
                                                w={'50%'}
                                            >
                                                <Text>
                                                    Hi, <br />
                                                    Thanks for writing in! We have noted your query. For future reference, do use this incident number {data.referenceNo} <br />
                                                    In case we require any further information to resolve your query, we will reach out to you in 24 hours. <br />
                                                    Warm Regards,<br />
                                                    Travel Medicare Support</Text>
                                            </Flex>
                                            {data.message && data.message.split('}').map((msg, index) => {
                                                if (msg.trim() !== '') {
                                                    const formattedMsg = index < data.message.length - 1 ? msg + '}' : msg;
                                                    const parsedMsg = JSON.parse(formattedMsg);
                                                    const align = role.name === "Admin"|| "Super Admin" ? 'flex-start' : 'flex-end';
                                                    return (
                                                        <Flex key={index} justifyContent={align}>

                                                            <Box
                                                                maxWidth="70%"
                                                                bg={role.name === "Advisor" || "Customer" || "AGA" || "MGA" ? "gray.200" : "blue.500"}
                                                                color={role.name === "Admin"|| "Super Admin" ? "black" : "white"}
                                                                p={3}
                                                                borderRadius={10}
                                                                mb={2}
                                                            >
                                                                {parsedMsg.message}
                                                                  <hr/>
                                                                {parsedMsg.attachment && (
                                                                    <Tooltip label="Click to download attachment" fontSize="md">
                                                                        <Box>
                                                                            Attachment:
                                                                            <a href="#" onClick={() => handleDownload(parsedMsg.attachment)}>
                                                                                {parsedMsg.attachment}
                                                                            </a>
                                                                        </Box>
                                                                    </Tooltip>
                                                                )}
                                                            </Box>
                                                        </Flex>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Box>
                                        <hr />
                                        {
                                            ticketStatus !== 3 ? <Box mt={'10px'} background={'white'} p={'15px'}>
                                                <Textarea placeholder='reply...' size={'sm'} onChange={(e) => setFormData({ ...formData, description: e.target.value })} value={formData.description}></Textarea>
                                                <Flex justifyContent={'space-between'}>
                                                    <input style={{ marginTop: '10px' }} onChange={handleFileChange} isDisabled={ticketStatus === 3} multiple type="file" accept=".pdf,.doc,.docx" />
                                                    <Button size={'sm'} mt={'10px'} background={primaryBackgroundColor} color={'white'} isDisabled={ticketStatus === 3} onClick={handleSubmit}>Reply</Button>
                                                </Flex>
                                            </Box> : ''
                                        }

                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </Accordion>
                </Box>
            </Flex>
        </Box>
    )
}

export default ViewSingleTicket