import React, { useState } from 'react'
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel, Flex, Table, Thead, Th, Tr, Td, Tbody, TableContainer, Button, useToast, Select, Text, Stack, Radio, RadioGroup, VStack, Input } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { primaryColor, primaryBackgroundColor, buttonColor, buttonBackgroundColor, buttonHoverBackground, buttonHoverColor } from "../../Utils/UniversalColor";
import { StudentVisa, SuperVisa, VisitorVisa } from './Dependencies/action';
import { getMaxSelectableDate } from '../Advisor/AdvisorQuote/Validation';
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import RadioOption from '../../Utils/RadioCard';
import Api from '../../dependencies/utils/Api';
import { SUPERVISA_QUICKQUOTE } from '../../constants/ApplicationUrl';
import { useTranslation } from 'react-i18next';


const NewCode = () => {
  const [amount,setAmount]=useState(0)
  const toast = useToast();
  const dispatch = useDispatch();
  const [numTravelers, setNumTravelers] = useState(1);
  const [showTable, setShowTable] = useState(false);
  const [policyForm, setPolicyForm] = useState("Super Visa");
  const [showSuperVisaTable, setShowSuperVisaTable] = useState(false)
  const [showVisitorTable, setShowVisitorTable] = useState(false)
  const [showStudentTable, setShowStudentTable] = useState(false)
  const [deductible, setDeductible] = useState('');
  const [visitorDeductible,setVisitorDeductible]=useState('')
  const navigate = useNavigate();
  const SuperVisadata = useSelector((state) => state.superVisaQuote);
  const Visitorquotedata = useSelector((state) => state.vistorQuote);
  const Studentquotedata = useSelector((state) => state.studentQuote);
  const [policyType,setPolicyType]=useState('')
  console.log(Studentquotedata,'studentquotedata')
  const [tripType,setTripType]=useState('')
  const [selectedValues, setSelectedValues] = useState({});
  const {t}=useTranslation()
  const [selectedRadioOptions, setSelectedRadioOptions] = useState({});
  const [valueStudent, setValueStudent] = React.useState('false')
  const [valueVisitor, setValueVisitor] = React.useState('false')
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      insuredDOB: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);
  const [addVisitorTravelers, setAddVisitorTravelers] = useState([
    {
      name: "",
      gender: "",
      insuredDOB: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);
  const [addStudentTravelers, setAddStudentTravelers] = useState([
    {
      name: "",
      gender: "",
      insuredDOB: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);
  const [travelerAges, setTravelerAges] = useState([{
    name: "",
    gender: "",
    insuredDOB: "",
    relation: "",
    benificiaryDob: "",
    benificiaryName: "",
    selectedKey:''
  }]);
  const [duration,setDuration]=useState('')

  const handleSelect = (value, name) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleGetQuickQuote = (data) => {
    console.log(data, 'passed')
    navigate('/get-quick-quote', { state: { data: data } });
  }
  const [selectedCostOption, setSelectedCostOption] = useState({});  

  // const handleCostOptionChange = (index, value, optionType) => {
  //   console.log(index, value, optionType);
  //   setSelectedCostOption(prevSelectedCostOption => ({
  //     ...prevSelectedCostOption,
  //     [index]: value
  //   }));

  //   const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
  //   const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
  //   const totalValue = totalSelectedValue + value;
  //   const selectedKey = (optionType === 'withPreExisting') ? '2' : '3';
  //   setAmount(totalValue);
  //   setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
  //     if (idx === index) {
  //       return { ...traveler, selectedKey };
  //     }
  //     return traveler;
  //   }));
  // }

  const [selectedRowOption, setSelectedRowOption] = useState({});

  const handleCostOptionChange = (rowIndex, optionIndex, value, optionType) => {
    setSelectedRowOption(prevState => ({
      ...prevState,
      [rowIndex]: { optionIndex, value, optionType },
    }));
  
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [`${rowIndex}-${optionIndex}`]: value
    }));
  
    // Calculate and update the amount if needed
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== `${rowIndex}-${optionIndex}`);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
    const totalValue = totalSelectedValue + parseFloat(value);
    setAmount(totalValue);
    
    // Example: update traveler selection
    setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
      if (idx === rowIndex) {
        const selectedKey = (optionType === 'withPreExisting') ? '2' : '3';
        return { ...traveler, selectedKey };
      }
      return traveler;
    }));
  };
  
  
  console.log(selectedRowOption,'rows')

  const handleVisitorCostOptionChange = (index, value, optionType) => {
    console.log(index, value, optionType);
    
    // Compute the new selected cost options before setting state
    setSelectedCostOption(prevSelectedCostOption => {
      const updatedSelectedCostOption = {
        ...prevSelectedCostOption,
        [index]: value
      };
  
      const selectedValues = Object.values(updatedSelectedCostOption);
      const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
  
      setAmount(totalSelectedValue);
  
      return updatedSelectedCostOption;
    });
  
    const selectedKey = (optionType === 'withPreExisting') ? '2' : '3';
  
    setAddVisitorTravelers(prevTravelers => 
      prevTravelers.map((traveler, idx) => 
        idx === index ? { ...traveler, selectedKey } : traveler
      )
    );
  };
  
  // const handleVisitorCostOptionChange = (index, value, optionType) => {
  //   console.log(index, value, optionType);
  //   setSelectedCostOption(prevSelectedCostOption => ({
  //     ...prevSelectedCostOption,
  //     [index]: value
  //   }));
  //   const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
  //   const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
  //   const totalValue = totalSelectedValue + value;
  //   const selectedKey = (optionType === 'withPreExisting') ? '2' : '3';
  //   setAmount(totalValue);
  //   setAddVisitorTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
  //     if (idx === index) {
  //       return { ...traveler, selectedKey };
  //     }
  //     return traveler;
  //   }));
  // }

  const groupedData = {
    Standard: [],
    Enhanced: [],
    Premium: []
  };

  console.log(groupedData,'grouped Data')

  const groupedVisitorData = {
    Standard: [],
    Enhanced: [],
    Premium: []
  };

  SuperVisadata.superVisaQuote.forEach(item => {
    item.list.forEach(listItem => {
      const existingItem = groupedData[listItem.planType].find(dataItem => dataItem.sumInsured === listItem.sumInsured);
      if (existingItem) {
        existingItem.costwithoutPreExisting.push(listItem.costwithoutPreExisting);
        existingItem.costWithPreExisting.push(listItem.costWithPreExisting);
      } else {
        // If it doesn't exist, add the entire item
        groupedData[listItem.planType].push({
          sumInsured: listItem.sumInsured,
          age: item.age,
          costwithoutPreExisting: [listItem.costwithoutPreExisting],
          costWithPreExisting: [listItem.costWithPreExisting]
        });
      }
    });
  });

  Visitorquotedata.vistorQuote.forEach(item => {
    item.list.forEach(listItem => {
      const existingItem = groupedVisitorData[listItem.planType].find(dataItem => dataItem.sumInsured === listItem.sumInsured);
      if (existingItem) {
        existingItem.costwithoutPreExisting.push(listItem.costwithoutPreExisting);
        existingItem.costWithPreExisting.push(listItem.costWithPreExisting);
      } else {
        // If it doesn't exist, add the entire item
        groupedVisitorData[listItem.planType].push({
          sumInsured: listItem.sumInsured,
          age: item.age,
          costwithoutPreExisting: [listItem.costwithoutPreExisting],
          costWithPreExisting: [listItem.costWithPreExisting]
        });
      }
    });
  });

  const [formData, setFormData] = useState({
    policyStartDate:"",
    policyEndDate:"",
    email:"",
  });

  const [formVisitorData, setFormVisitorData] = useState({
    policyStartDate:"",
    policyEndDate:"",
    email:"",
  });
  const [formStudentData, setFormStudentData] = useState({
    policyStartDate:"",
    policyEndDate:"",
    email:"",
  });

  const [errors, setErrors] = useState({
    policyStartDate: "",
    policyEndDate: "",
    insuredDOB: "",
    email: "",
  });

  const policyStartDate = new Date(formData.policyStartDate);
  const newEnd = formData.policyStartDate
    ? new Date(policyStartDate.setFullYear(policyStartDate.getFullYear() + 1))
    : null;


  const handleChange = (e, value) => {
    if (e && e.target) {
        const { name, value } = e.target;
        setErrors({ ...errors, [name]: "" });

        if (name.startsWith("travelerAge")) {
            const index = parseInt(name.substring(11));
            const newTravelerAges = [...travelerAges];
            newTravelerAges[index] = value;
            setTravelerAges(newTravelerAges);
        } else if (name === 'policyStartDate') {
            const startDate = new Date(value);
            if (!isNaN(startDate.getTime())) {
                const endDate = new Date(startDate);
                endDate.setFullYear(startDate.getFullYear() + 1);
                endDate.setDate(startDate.getDate() - 1);
                
                setFormData({
                    ...formData,
                    [name]: value, // Update policyStartDate
                    policyEndDate: endDate.toISOString().split('T')[0], // Update policyEndDate
                });
            } else {
                // Handle invalid date input if necessary
                setFormData({
                    ...formData,
                    [name]: value, // Update policyStartDate even if invalid for visibility
                    policyEndDate: "", // Clear policyEndDate if invalid start date
                });
            }
        } else {
            setFormData({
                ...formData,
                deductible: value,
                [name]: value,
            });
        }
    } else if (value) {
        setFormData({
            ...formData,
            deductible: value,
        });
    }
  };

  const handleChangeVisitor = (e) => {
    const { name, value } = e.target;
    setFormVisitorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeStudent = (e) => {
    const { name, value } = e.target;
      setFormStudentData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };
  const handleSubmit1 = async (name) => {
    setPolicyType("Supervisa")
    try {
      for (let i = 0; i < addTravelers.length; i++) {
        const dob = addTravelers[i].insuredDOB;
        if (!dob || isNaN(new Date(dob).getTime())) {
            alert(`Traveler ${i + 1} has an invalid or missing insuredDOB!`);
            return false;
        }
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
        if (age >= 89) {
            alert(`Traveler ${i + 1} should be less than 89 years old!`);
            return false;
        }
    }
    
      setTripType('SuperVisa')
      const { policyStartDate, policyEndDate, email, deductible } = formData;
      if(!deductible){
        alert('Please select deductible')
        return;
      }
      if(!policyStartDate){
        alert("Please enter start Date")
        return
      }
      if(!email){
        alert("Please enter email")
        return;
      }
      const dataToSend={
        ...formData,
        insuredDetailsRequests:addTravelers,
        preExisting:valueStudent
      }
      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "Supervisa" ? SuperVisa(dataToSend) : StudentVisa(formData)
        );
      }
      setShowSuperVisaTable(true)
      toast({
        title: "Super Visa Quickquote generated",
      });
      setErrors({
        policyStartDate: "",
        policyEndDate: "",
        email: "",
        insuredDetailsRequests: "",
        deductible: ""
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };

  const handleSubmit2 = async (name) => {
    setPolicyType('VTC')
    try {
      for (let i = 0; i < addVisitorTravelers.length; i++) {
        const dob = addVisitorTravelers[i].insuredDOB;
        if (!dob || isNaN(new Date(dob).getTime())) {
            alert(`Traveler ${i + 1} has an invalid or missing insuredDOB!`);
            return false;
        }
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
        if (age >= 89) {
            alert(`Traveler ${i + 1} should be less than 89 years old!`);
            return false;
        }
    }
      const { policyStartDate, policyEndDate, email, deductible } = formVisitorData;
      if(!policyStartDate){
        alert("Please enter start Date")
        return
      }
      if(!policyEndDate){
        alert("Please Enter EndDate")
        return;
      }
      const startDate = new Date(policyStartDate);
      const endDate = new Date(policyEndDate);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (endDate < startDate) {
        alert("The end date cannot be earlier than the start date!");
        return;
    }
      if (diffDays > 365) {
          alert("The policy duration cannot exceed one year!");
          return;
      }
      if(!email){
        alert("Please enter email")
        return;
      }
      if(visitorDeductible.length===0){
        alert("Please choose deductible")
        return;
      }
      setTripType('Visitor')
      const dataToSend={
        ...formVisitorData,
        insuredDetailsRequests:addVisitorTravelers,
        deductible:visitorDeductible,
        preExisting:valueVisitor
      }
      console.log(dataToSend, 'vistiro')
      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "Visitorvisa" ? VisitorVisa(dataToSend) : StudentVisa(formData)

        );
      }

      setFormData({
        policyStartDate: "",
        policyEndDate: "",
        insuredDetailsRequests: [{ insuredDOB: "" }],
        email: "",
      });
      setShowVisitorTable(true)
      toast({
        title: "Visitor Insuarance Quickquote generated",
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };

  const handleSubmit3 = async (name) => {
    setPolicyType('Studentvisa')
    try {
      for (let i = 0; i < addStudentTravelers.length; i++) {
        const dob = addStudentTravelers[i].insuredDOB;
        if (!dob || isNaN(new Date(dob).getTime())) {
            alert(`Traveler ${i + 1} has an invalid or missing insuredDOB!`);
            return false;
        }
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }
        if (age >= 65) {
            alert(`Traveler ${i + 1} should be less than 65 years old!`);
            return false;
        }
    }

      setTripType('Student')
      const { policyStartDate, policyEndDate, email, insuredDetailsRequests } = formStudentData;
      if(!policyStartDate){
        alert("Please enter start Date")
        return
      }
      if(!policyEndDate){
        alert("Please Enter EndDate")
        return;
      }
      if(!email){
        alert("Please enter email")
        return;
      }
      const startDate = new Date(policyStartDate);
      const endDate = new Date(policyEndDate);
      if (endDate < startDate) {
        alert("The end date cannot be earlier than the start date!");
        return;
    }
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays > 365) {
          alert("The policy duration cannot exceed one year!");
          return;
      }
      const dataToSend={
        ...formStudentData,
        insuredDetailsRequests:addStudentTravelers,
      }
      console.log(dataToSend, 'student')      

      let res;
      if (
        name === "Supervisa" ||
        name === "Visitorvisa" ||
        name === "StudentVisa"
      ) {
        await dispatch(
          name === "StudentVisa" ? StudentVisa(dataToSend) : SuperVisa(formData)

        );
      }

      setFormData({
        policyStartDate: "",
        policyEndDate: "",
        insuredDetailsRequests: [{ insuredDOB: "" }],
        email: "",
      });
      setShowStudentTable(true)
      toast({
        title: "Student Insurance Quickquote generated",
      });
    } catch (error) {
      toast({
        title: "Invalid Details",
      });
      // window.location.reload();
    }
  };

  const handleAddTraveler = (e) => {
    e.preventDefault();
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          insuredDOB: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };

  const handleAddVisiorTraveler = (e) => {
    e.preventDefault();
      setAddVisitorTravelers([
        ...addVisitorTravelers,
        {
          name: "",
          gender: "",
          insuredDOB: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };
  const handleAddStudentTraveler = (e) => {
    e.preventDefault();
      setAddStudentTravelers([
        ...addStudentTravelers,
        {
          name: "",
          gender: "",
          insuredDOB: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };

  const handleRemoveTraveler = (e) => {
    e.preventDefault();
    setAddTravelers((prevTravelers) => {
      if (prevTravelers.length === 0) {
        alert('No travelers to remove');
        console.log('No travelers to remove');
        return prevTravelers;
      }
      const updatedTravelers = prevTravelers.slice(0, -1);
      return updatedTravelers;
    });
  };

  const handleRemoveVisitorTraveler = (e) => {
    e.preventDefault();
    setAddVisitorTravelers((prevTravelers) => {
      if (prevTravelers.length === 0) {
        alert('No travelers to remove');
        console.log('No travelers to remove');
        return prevTravelers;
      }
      const updatedTravelers = prevTravelers.slice(0, -1);
      return updatedTravelers;
    });
  };

  const handleRemoveStudentTraveler = (e) => {
    e.preventDefault();
    setAddStudentTravelers((prevTravelers) => {
      if (prevTravelers.length === 0) {
        alert('No travelers to remove');
        console.log('No travelers to remove');
        return prevTravelers;
      }
      const updatedTravelers = prevTravelers.slice(0, -1);
      return updatedTravelers;
    });
  };

  const handleTravelerDOBChange = (index, e) => {
      const { name, value } = e.target;
      if (name === "insuredDOB") {
          const selectedDate = new Date(value);
          const minDate = new Date();
          minDate.setDate(minDate.getDate() - 15);
          if (selectedDate >= minDate) {
              alert("Users must be at least 15 days older than today.");
              return;
          }
      }
      setAddTravelers((prevState) => {
          const updatedState = [...prevState];
          updatedState[index] = { ...updatedState[index], [name]: value };
          return updatedState;
      });
  };

  const handleVisitorTravelerDOBChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "insuredDOB") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
    setAddVisitorTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
  };

  const handleStudentTravelerDOBChange = (index, e) => {
    const { name, value } = e.target;
    if (name === "insuredDOB") {
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
    }
    setAddStudentTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        return updatedState;
    });
  };

  const handleTravelerAgeChange = (index, age) => {
    const newTravelerAges = [...travelerAges];
    newTravelerAges[index] = age;
    setTravelerAges(newTravelerAges);
  };

  const deductibleDiscounts = {
    0: "Base",
    100: 5,
    500: 15,
    1000: 20,
    3000: 30
  };

  const mapCosts = (data, people) => {
    const { costwithoutPreExisting, costWithPreExisting } = data;
    return people.map((person, index) => {
        const costwithoutPre = costwithoutPreExisting && costwithoutPreExisting[index] !== undefined 
            ? costwithoutPreExisting[index] 
            : null;
        const costWithPre = costWithPreExisting && costWithPreExisting[index] !== undefined 
            ? costWithPreExisting[index] 
            : null;

        return {
            ...person,
            costWithoutPreExisting: costwithoutPre,
            costWithPreExisting: costWithPre,
        };
    });
};

const handleBuy = (data,totalCost) => {

    console.log('Proceeding with purchase for plan:', data);
  const updatedPeople = mapCosts(data, addTravelers);
    const dataToSend = {
      rowData: data,
      travelers:updatedPeople,
      fieldsData: formData,
      amount:totalCost,
      policyType:policyType
    };
    console.log(dataToSend,'dts')
    navigate("/get-quick-quote", { state: { data: dataToSend } });
    };

const handleVisitorBuy=(data)=>{
  const updatedPeople = mapCosts(data, addVisitorTravelers);
  const dataToSend = {
    rowData: data,
    travelers:updatedPeople,
    fieldsData: formVisitorData,
    amount:amount
  };
  navigate("/get-quick-quote", { state: { data: dataToSend } });
}

const handleStudentBuy=(data)=>{
  const updatedPeople = mapCosts(data, addStudentTravelers);
  const dataToSend = {
    rowData: data,
    travelers:updatedPeople,
    fieldsData: formStudentData,
    amount:amount,
    policyType:policyType
  };
  navigate("/get-quick-quote", { state: { data: dataToSend } });
}

const handleRadioSelection = (planType, rowIndex, optionIndex, value) => {
  setSelectedRadioOptions(prevState => ({
    ...prevState,
    [planType]: {
      ...prevState[planType],
      [rowIndex]: {
        optionIndex,
        value,
      },
    },
  }));
};


  

return (
    <div>
      <Box pt={'90px'} pb={'80px'} background={'#f1f1f1'}>
        <Tabs>
          <TabList display={'flex'} flexDirection={['row']} w={['95%','95%','25%']} marginLeft={['10px','20px','30px']}
            style={{
              // display: "flex",
              // flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: "20px",
              padding: "5px",
              fontSize:'12px',
              backgroundColor: primaryBackgroundColor,
              color: "white",
              borderTopRadius: '20px',
              // borderBottomRadius: "1px",
              // width: '25%',
              // marginLeft: '20px'
            }}
          >
            <Tab fontSize={'12px'}
              _selected={{
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                borderRadius: "10px",
                bg: "white",
                backgroundColor: "white",
                padding: '0px 10px'
              }}
            >
              {t('Super Visa')}
            </Tab>
            <Tab fontSize={'12px'}
              _selected={{
                color: "black",
                borderRadius: "10px",
                fontSize: "12px",
                fontWeight: "500",
                bg: "white",
                backgroundColor: "white",
              }}
            >
              {t('Visitors Insurance')}
            </Tab>
            <Tab fontSize={'12px'}
              _selected={{
                color: "black",
                fontSize: "12px",
                fontWeight: "500",
                borderRadius: "10px",
                bg: "white",
                backgroundColor: "white",
              }}
            >
              {t('Student Insurance')}
            </Tab>
          </TabList>
          <TabPanels>

            {/* **** super visa ***** */}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex direction={['column','row','row']}>
                  <Box background={'white'} marginLeft={['10px','20px','30px']} boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={['95%','25%','25%']} h={'auto'} p={'20px'} paddingTop={'5px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                        <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('Start Date')} <span style={{color:'red',fontSize:'16px'}}>*</span></label>
                          <br />
                          <Input size={'sm'}
                            name="policyStartDate"
                            type="date"
                            placeholder={t('Start Date')}
                            value={formData.policyStartDate}
                            onChange={handleChange}
                            min={new Date().toISOString().split('T')[0]}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyStartDate}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('End Date')} <span style={{color:'red',fontSize:'16px'}}>*</span></label>
                          <br />
                          <Input size={'sm'}
                            name="policyEndDate"
                            type="date"
                            placeholder={t('End Date')}
                            value={newEnd ? newEnd.toISOString().split("T")[0] : ""}
                            readOnly
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'5px'} fontFamily={"Open Sans,sans-serif"}>{t('Email')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      <Input size={'sm'} name="email" placeholder={t('Email')} value={formData.email} onChange={handleChange}/>
                      <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                        {errors.email}
                      </p>
                      <Text fontSize={'14px'} mt={'5px'} fontFamily={"Open Sans,sans-serif"}>{t('DOB')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      {addTravelers.map((traveler, index) => (
                        <>
                      <Input size={'sm'} key={index} mt={'5px'} type="date" placeholder={`Traveler ${index + 1} Age`} value={traveler.insuredDOB} max={getMaxSelectableDate()} onChange={(e) =>handleTravelerDOBChange(index, e)
                            }
                            name={`insuredDOB`}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.insuredDOB}
                          </p>
                        </>
                      ))}
                      <p style={{ textAlign: "start", color: "red", fontSize: "sm" }}  >
                        {errors.insuredDetailsRequests}
                      </p>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: primaryBackgroundColor,
                            color: 'white',
                            padding: "2px 10px",
                            fontSize: "12px",
                          }}
                          onClick={handleAddTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: primaryBackgroundColor,
                            padding: "0px 10px",
                            fontSize: "12px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveTraveler}
                        >
                          -
                        </button>
                      </div>
                      <Flex mt={'12px'} justifyContent={'space-between'}>
                        <Text fontSize={'12px'}>Would you like to cover pre-existing</Text>
                        <RadioGroup fontSize={'12px'} onChange={setValueStudent} value={valueStudent}>
                            <Stack direction='row'>
                              <Radio fontSize={'12px'} value='true'><Text fontSize={'12px'}>Yes</Text></Radio>
                              <Radio fontSize={'12px'} value='false'><Text fontSize={'12px'}>No</Text></Radio>
                            </Stack>
                          </RadioGroup>
                      </Flex>
                      <Flex direction="column" mb={'15px'}>
                        <Box mb={'10px'} mt={'7px'}>
                          <Text fontSize={'14px'}>{t('Deductible')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                          <Stack spacing={4} direction='row'>
                            <RadioOption value='0' isSelected={formData.deductible === '0'} onChange={(e) => handleChange(e, '0')} backgroundColor={deductible === '0' ? 'teal' : undefined}>
                              $0
                            </RadioOption>
                            <RadioOption value='100' isSelected={formData.deductible === '100'} onChange={(e) => handleChange(e, '100')} colorScheme={deductible === '100' ? 'teal' : undefined}>
                              $100
                            </RadioOption>
                            <RadioOption value='250' isSelected={formData.deductible === '250'} onChange={(e) => handleChange(e, '250')} colorScheme={deductible === '250' ? 'teal' : undefined}>
                              $250
                            </RadioOption>
                            <RadioOption value='500' isSelected={formData.deductible === '500'} onChange={(e) => handleChange(e, '500')} colorScheme={deductible === '500' ? 'teal' : undefined}>
                              $500
                            </RadioOption>
                          </Stack>
                        </Box>
                        <Flex gap={'15px'}>
                          <RadioOption value='1000' isSelected={formData.deductible === '1000'} onChange={(e) => handleChange(e, '1000')} colorScheme={deductible === '1000' ? 'teal' : undefined}>
                            $1000
                          </RadioOption>
                          <RadioOption value='3000' isSelected={formData.deductible === '3000'} onChange={(e) => handleChange(e, '3000')} colorScheme={deductible === '3000' ? 'teal' : undefined}>
                            $3000
                          </RadioOption>
                        </Flex>
                      </Flex>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                        <button
                          type="button"
                          onClick={()=>handleSubmit1('Supervisa')}
                          // onClick={handleSuperVisaSubmit}
                          style={{ backgroundColor: buttonBackgroundColor, padding: '5px 8px', color: 'white', borderRadius: '10px', fontSize: '14px' }}
                        >
                          {t('Submit')}
                        </button>
                      </div>
                    </form>
                  </Box>
                  <Box background={'white'} marginLeft={['10px','30px','20px']} marginTop={["20px","-70px","-70px"]} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={['95%','65%','65%']} padding={"20px"} p={'10px'} overflowY="auto">
                  {showSuperVisaTable === true ?
                  <TableContainer>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr>
                        <Th p={'3px'} fontSize={'12px'} fontWeight={'600'} fontFamily={'poppins'}>Plan Type</Th>
                        <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}>Sum Insured</Th>
                        <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}>Insured Age</Th>
                        <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>Costs</Th>
                        <Th p={'3px'} fontSize={'11px'} fontWeight={'600'} fontFamily={'poppins'}>Buy Now</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
           {Object.entries(groupedData).map(([planType, data]) => (
            <React.Fragment key={planType}>
              <Tr>
                <Td colSpan="3" fontSize={'12px'} fontWeight={'600'}>{planType}</Td>
                <Td></Td>
              </Tr>
              {data.map((item, rowIndex) => {
                const nonZeroCosts = [
                  ...item.costWithPreExisting,
                  ...item.costwithoutPreExisting,
                ].filter((ele) => ele !== 0);

                const totalCost = nonZeroCosts.reduce((acc, curr) => acc + curr, 0);

                return (
                  <Tr cursor={'pointer'} _hover={{ backgroundColor: '#E9EDEF' }} key={rowIndex} p={'0px'}>
                    <Td p={'3px'}>*</Td>
                    <Td p={'3px'} fontSize={'12px'}>{item.sumInsured}</Td>
                    <Td p={'3px'} fontSize={'12px'}>{item.age} yrs</Td>                     
                    <Td p={'3px'} fontSize={'12px'}>
                      <VStack align="start">
                        {nonZeroCosts.map((ele, optionIndex) => (
                          <Flex key={`${planType}-${rowIndex}-${optionIndex}`}>
                            <Text pl={'10px'}>
                              {parseInt(Number(ele).toFixed(0))} $
                            </Text>
                          </Flex>
                        ))}
                      </VStack>
                    </Td>
                    <Td _hover={{ color: 'teal' }}>
                      <FaRegArrowAltCircleRight onClick={() => handleBuy(item, totalCost)} />
                    </Td>
                  </Tr>
                );
              })}
            </React.Fragment>
          ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                     : 
                     <Box>
                        <Text textAlign={'center'} mt={'30px'} fontWeight={'600'} fontSize={'20px'}>{t('Please fill the form to get Quick Quote.')}</Text>
                     </Box>}
                     </Box>
                </Flex>
              </Box>
            </TabPanel>


            {/* **** visitor insurance **/}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex direction={['column','row','row']}>
                  <Box background={'white'} marginLeft={['10px','20px','30px']} boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={['95%','25%','25%']} h={'auto'} p={'20px'} paddingTop={'15px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                          <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('Start Dates')} <span style={{color:'red',fontSize:'16px'}}>*</span> </label>
                          <br />
                          <Input size={'sm'}
                            name="policyStartDate"
                            type="date"
                            placeholder={t('Start Date')}
                            value={formVisitorData.policyStartDate}
                            onChange={handleChangeVisitor}
                            min={new Date().toISOString().split('T')[0]}
                            max="3000-12-31"
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyStartDate}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('End Date')} <span style={{color:'red',fontSize:'16px'}}>*</span></label>
                          <br />
                          <Input size={'sm'}
                            name="policyEndDate"
                            type="date"
                            placeholder={t('End Date')}
                            min={formVisitorData.policyStartDate}
                            value={formVisitorData.policyEndDate}
                            onChange={handleChangeVisitor}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>{t('Email')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      <Input size={'sm'}
                        name="email"
                        placeholder={t('Email')}
                        value={formVisitorData.email}
                        onChange={handleChangeVisitor}
                      />
                      <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                        {errors.email}
                      </p>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>{t('DOB')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      {addVisitorTravelers.map((traveler, index) => (
                        <>
                      <Input size={'sm'} key={index} mt={'5px'} type="date" placeholder={`Traveler ${index + 1} Age`} value={traveler.insuredDOB} max={getMaxSelectableDate()} onChange={(e) =>handleVisitorTravelerDOBChange(index, e)
                            }
                            name={`insuredDOB`}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.insuredDOB}
                          </p>
                        </>
                      ))}
                      <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                        {errors.insuredDOB}
                      </p>
                      <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 10px",
                            fontSize: "14px",
                            color: buttonColor
                          }}
                          onClick={handleAddVisiorTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 10px",
                            fontSize: "14px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveVisitorTraveler}
                        >
                          -
                        </button>
                      </div>
                      <Flex mt={'12px'} justifyContent={'space-between'}>
                        <Text fontSize={'12px'}>Would you like to cover pre-existing</Text>
                        <RadioGroup fontSize={'12px'} onChange={setValueStudent} value={valueStudent}>
                            <Stack direction='row'>
                              <Radio fontSize={'12px'} value='true'><Text fontSize={'12px'}>Yes</Text></Radio>
                              <Radio fontSize={'12px'} value='false'><Text fontSize={'12px'}>No</Text></Radio>
                            </Stack>
                          </RadioGroup>
                      </Flex>
                      <Flex direction="column" mb={'20px'}>
                        <Box mb={'20px'} mt={'7px'}>
                          <Text fontSize={'14px'}>{t('Deductible')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                          <Stack spacing={4} direction='row'>
                            <RadioOption value='0' isSelected={visitorDeductible === '0'} onChange={(e) => setVisitorDeductible(e)} backgroundColor={deductible === '0' ? 'teal' : undefined}>
                              $0
                            </RadioOption>
                            <RadioOption value='100' isSelected={visitorDeductible === '100'} onChange={(e) => setVisitorDeductible(e)} colorScheme={deductible === '100' ? 'teal' : undefined}>
                              $100
                            </RadioOption>
                            <RadioOption value='250' isSelected={visitorDeductible === '250'} onChange={(e) => setVisitorDeductible(e)} colorScheme={deductible === '250' ? 'teal' : undefined}>
                              $250
                            </RadioOption>
                            <RadioOption value='500' isSelected={visitorDeductible === '500'} onChange={(e) => setVisitorDeductible(e)} colorScheme={deductible === '500' ? 'teal' : undefined}>
                              $500
                            </RadioOption>
                          </Stack>
                        </Box>
                        <Flex gap={'15px'}>
                          <RadioOption value='1000' isSelected={visitorDeductible === '1000'} onChange={(e) => setVisitorDeductible(e)} colorScheme={deductible === '1000' ? 'teal' : undefined}>
                            $1000
                          </RadioOption>
                          <RadioOption value='3000' isSelected={visitorDeductible === '3000'} onChange={(e) => setVisitorDeductible(e)} colorScheme={deductible === '3000' ? 'teal' : undefined}>
                            $3000
                          </RadioOption>
                        </Flex>
                      </Flex>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "10px",
                        }}
                      >
                      <Button background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} size={'sm'} type="button" onClick={() => handleSubmit2("Visitorvisa")}>
                          {t('Submit')}
                        </Button>
                      </div>
                    </form>
                  </Box>
                  <Box background={'white'} marginLeft={['10px','30px','20px']} marginTop={["10px","-70px","-70px"]} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={['95%','25%','65%']} padding={"20px"} p={'10px'} overflowY="auto">
              {showVisitorTable === true ?   
                    <TableContainer>
                    <Table size="sm" variant="simple">
                      <Thead>
                        <Tr>
                          <Th p={'3px'} fontSize={'12px'} fontWeight={'600'} fontFamily={'poppins'}>{t('Plan Type')}</Th>
                          <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}>{t('Sum Insured')}</Th>
                          <Th p={'3px'} fontWeight={'600'} fontFamily={'poppins'}> {t('Insured Age')}</Th>
                          <Th p={'3px'} fontSize={'10px'} fontWeight={'600'} fontFamily={'poppins'}>{t('Costs')}</Th>
                          <Th p={'3px'} fontSize={'11px'} fontWeight={'600'} fontFamily={'poppins'}>{t('Buy Now')}</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                     {Object.entries(groupedData).map(([planType, data]) => (
                      <React.Fragment key={planType}>
                        <Tr>
                          <Td colSpan="3" fontSize={'12px'} fontWeight={'600'}>{planType}</Td>
                          <Td></Td>
                        </Tr>
                        {data.map((item, rowIndex) => {
                          const nonZeroCosts = [
                            ...item.costWithPreExisting,
                            ...item.costwithoutPreExisting,
                          ].filter((ele) => ele !== 0);
          
                          const totalCost = nonZeroCosts.reduce((acc, curr) => acc + curr, 0);
          
                          return (
                            <Tr cursor={'pointer'} _hover={{ backgroundColor: '#E9EDEF' }} key={rowIndex} p={'0px'}>
                              <Td p={'3px'}>*</Td>
                              <Td p={'3px'} fontSize={'12px'}>{item.sumInsured}</Td>
                              <Td p={'3px'} fontSize={'12px'}>{item.age} yrs</Td>                     
                               {/* {item.age.map((age, ageIndex) => (
                                  <div key={ageIndex}>
                                    {age}{ageIndex < item.age.length - 1 ? ', ' : ''}
                                  </div>
                                ))} */}
                              {/* </Td> */}
                              <Td p={'3px'} fontSize={'12px'}>
                                <VStack align="start">
                                  {nonZeroCosts.map((ele, optionIndex) => (
                                    <Flex key={`${planType}-${rowIndex}-${optionIndex}`}>
                                      <Text pl={'10px'}>
                                        {parseInt(Number(ele).toFixed(0))} $
                                      </Text>
                                    </Flex>
                                  ))}
                                </VStack>
                              </Td>
                              <Td _hover={{ color: 'teal' }}>
                                <FaRegArrowAltCircleRight onClick={() => handleBuy(item, totalCost)} />
                              </Td>
                            </Tr>
                          );
                        })}
                      </React.Fragment>
                    ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                   : 
                   <Box>
                        <Text textAlign={'center'} mt={'30px'} fontWeight={'600'} fontSize={'20px'}>{t('Please fill the form to get Quick Quote.')}</Text>
                     </Box>}
                   </Box>
                </Flex>
              </Box>
            </TabPanel>


            {/* ******* Student ***** */}
            <TabPanel padding={'0px'}>
              <Box>
                <Flex direction={['column','row','row']}>
                  <Box background={'white'} marginLeft={['10px','20px','30px']} boxShadow='rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px' w={['95%','25%','25%']} h={'auto'} p={'20px'} paddingTop={'15px'}>
                    <form>
                      <div style={{ display: "flex", marginBottom: "10px" }}>
                        <div style={{ marginRight: "10px", flex: "1" }}>
                          <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('Start Date')} <span style={{color:'red',fontSize:'16px'}}>*</span></label>
                          <br />
                          <Input size={'sm'}
                            name="policyStartDate"
                            type="date"
                            placeholder={t('Start Date')}
                            value={formStudentData.policyStartDate}
                            onChange={handleChangeStudent}
                            min={new Date().toISOString().split('T')[0]}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyStartDate}
                          </p>
                        </div>
                        <div style={{ flex: "1" }}>
                          <label style={{ fontSize: '14px', fontFamily: 'Open Sans,sans-serif' }}>{t('End Date')} <span style={{color:'red',fontSize:'16px'}}>*</span></label>
                          <br />
                          <Input size='sm'
                            name="policyEndDate"
                            type="date"
                            placeholder={t('End Date')}
                            value={formStudentData.policyEndDate}
                            onChange={handleChangeStudent}
                            min={new Date().toISOString().split('T')[0]}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.policyEndDate}
                          </p>
                        </div>
                      </div>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>{t('Email')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      <Input size={'sm'}
                        name="email"
                        placeholder={t('Email')}
                        value={formStudentData.email}
                        onChange={handleChangeStudent}
                      />
                      <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                        {errors.email}
                      </p>
                      <Text fontSize={'14px'} mt={'9px'} fontFamily={"Open Sans,sans-serif"}>{t('DOB')} <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                      {addStudentTravelers.map((traveler, index) => (
                        <>
                      <Input size={'sm'} key={index} mt={'5px'} type="date" placeholder={`Traveler ${index + 1} Age`} value={traveler.insuredDOB} max={getMaxSelectableDate()} onChange={(e) =>handleStudentTravelerDOBChange(index, e)
                            }
                            name={`insuredDOB`}
                          />
                          <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                            {errors.insuredDOB}
                          </p>
                        </>
                      ))}
                      <p style={{ textAlign: "start", color: "red", fontSize: "12px" }}  >
                        {errors.insuredDOB}
                      </p>
                      {/* <div style={{ display: "flex", marginTop: "10px" }}>
                        <button
                          style={{
                            marginRight: "10px",
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor
                          }}
                          onClick={handleAddStudentTraveler}
                        >
                          +
                        </button>
                        <button
                          style={{
                            backgroundColor: buttonBackgroundColor,
                            padding: "2px 15px",
                            fontSize: "18px",
                            color: buttonColor,
                          }}
                          onClick={handleRemoveStudentTraveler}
                        >
                          -
                        </button>
                      </div> */}
                      {/* <Flex direction="column" mb={'10px'}>
                        <Box mb={'10px'} mt={'10px'}>
                          <Text fontSize={'14px'} mb={'7px'}>Deductible <span style={{color:'red',fontSize:'16px'}}>*</span></Text>
                          <Stack spacing={4} direction='row'>
                            <RadioOption value='0' isSelected={formData.deductible === '0'} onChange={(e) => handleChange(e, '0')} backgroundColor={deductible === '0' ? 'teal' : undefined}>
                              $0
                            </RadioOption>
                            <RadioOption value='100' isSelected={formData.deductible === '100'} onChange={(e) => handleChange(e, '100')} colorScheme={deductible === '100' ? 'teal' : undefined}>
                              $100
                            </RadioOption>
                            <RadioOption value='250' isSelected={formData.deductible === '250'} onChange={(e) => handleChange(e, '250')} colorScheme={deductible === '250' ? 'teal' : undefined}>
                              $250
                            </RadioOption>
                            <RadioOption value='500' isSelected={formData.deductible === '500'} onChange={(e) => handleChange(e, '500')} colorScheme={deductible === '500' ? 'teal' : undefined}>
                              $500
                            </RadioOption>
                          </Stack>
                        </Box>
                        <Flex gap={'15px'}>
                          <RadioOption value='1000' isSelected={formData.deductible === '1000'} onChange={(e) => handleChange(e, '1000')} colorScheme={deductible === '1000' ? 'teal' : undefined}>
                            $1000
                          </RadioOption>
                          <RadioOption value='3000' isSelected={formData.deductible === '3000'} onChange={(e) => handleChange(e, '3000')} colorScheme={deductible === '3000' ? 'teal' : undefined}>
                            $3000
                          </RadioOption>
                        </Flex>
                      </Flex> */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "19px",
                        }}
                      >
                        <Button type="button" size={'sm'} background={buttonBackgroundColor} color={buttonColor} onClick={() => handleSubmit3("StudentVisa")}
                        >
                          {t('Submit')}
                        </Button>
                      </div>
                    </form>
                  </Box>
                  <Box background={'white'} marginLeft={['10px','30px','20px']} marginTop={["10px","-70px","-70px"]} boxShadow={'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;'} w={['95%','25%','65%']} padding={"40px"} p={'10px'} overflowY="auto">
                  {Studentquotedata.studentQuote.length > 0 ? (
                    
                      <TableContainer>
                        <Table size="sm" variant="simple">
                          <Thead>
                            <Tr>
                              <Th>{t('Plan Type')}</Th>
                              <Th>{t('Amount')}</Th>
                              <Th>{t('Action')}</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {Studentquotedata.studentQuote.map((item, index) => (
                              <Tr key={index}>
                                <Td>{item.planType}</Td>
                                <Td>{item.amount}</Td>
                                <Td>
                                  <Td _hover={{ color: 'teal' }} onClick={()=>handleStudentBuy(item)}><FaRegArrowAltCircleRight /></Td>
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    
                  ) :
                  <Box background={'white'}>
                  <Text textAlign={'center'} mt={'30px'} fontWeight={'600'} fontSize={'20px'}>{t('Please fill the form to get Quick Quote.')}</Text>
               </Box>}
               </Box>
                </Flex>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

    </div>
  )
}

export default NewCode