import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { primaryColor } from '../../../Utils/UniversalColor';
import { FaDownload } from "react-icons/fa6";
import { Flex, Box, Text, Input, Textarea, Button } from '@chakra-ui/react'
import { advsorComission, advsorComissionList } from './dependencies/action';
import { ROLES } from '../../../constants/role';
import { storage } from '../../../dependencies/store/storage';
import jsPDF from 'jspdf';
import 'jspdf-autotable';



const pagination = true;
const paginationPageSizeSelector = [10, 20, 50, 100];

const AdvisorCommission = () => {
  const [activeTab, setActiveTab] = useState('my-commission');
  const [personalData, setPersonalData] = useState();
  const [licenseData, setLicenseData] = useState();
  const [corporationData, setCorporationData] = useState();
  const [advisorData, setAdvisorData] = useState();
  const [agaData, setAgaData] = useState()
  const [referenceData, setReferenceData] = useState();
  const [commonData, setCommonData] = useState([]);
  const dispatch = useDispatch();
  const role = storage.getUserRole();
  const [moduleData, setModuleData] = useState([])
  console.log("MODULE", moduleData)
  console.log(role, 'roles')

  useEffect(() => {
    dispatch(advsorComissionList())
      .then((res) => {
        console.log(" successfully", res);
        setCommonData(res)
        console.log("COMMSION", res)
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    dispatch(advsorComission())
      .then((res) => {
        console.log(" successfully", res);
        setModuleData(res)
        console.log("COMMSION", res)
      })
      .catch((error) => {
        console.error("Error fetching  data:", error);
      });
  }, [dispatch]);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleSaveData = (data) => {
    switch (activeTab) {
      case 'my-commission':
        setPersonalData(data);
        break;
      case 'pending-commission':
        setLicenseData(data);
        break;
      case 'settled-commission':
        setCorporationData(data);
        break;
      case 'advisor-commission':
        setAdvisorData(data);
        break;
      case 'aga-commission':
        setAgaData(data);
        break;
      default:
        break;
    }
  };

  const renderForm = () => {
    switch (activeTab) {
      case 'my-commission':
        return <MyCommission data={commonData} onSave={handleSaveData} />;
      case 'pending-commission':
        const pendingData = commonData.filter(item => item.isSettled === 0);
        return <PendingCommission data={pendingData} onSave={handleSaveData} />;
      case 'settled-commission':
        const settledData = commonData.filter(item => item.isSettled === 1);
        return <SettledCommission data={settledData} onSave={handleSaveData} />;
      case 'advisors-commission':
        const advisirosData = commonData.filter(item => item.isSettled === 1);
        return <AdvisorsCommission />;
      case 'aga-commission':
        const agaData = commonData.filter(item => item.isSettled === 1);
        return <AgaCommission />;
      default:
        return null;
    }
  };

  return (
    <Box pt={'80px'} background={'#f6f6f6'} h={'auto'} minHeight={'100vh'}>
      <Box padding={['10px', '10px', '90px']}>
        {/* <Flex> */}
          <Flex w={'80%'} m={'auto'} direction={['column', 'column', 'row']} gap={'30px'} className="tablist" justifyContent={'space-around'} pb={'50px'}>
            <Button
              style={{
                position: 'relative',
                padding: '10px 20px',
                backgroundColor: "#23b5b7",
                color: 'white',
                borderRadius: '10px',
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              active={activeTab === 'my-commission'}
              onClick={() => handleTabClick('my-commission')}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '20%',
                  left: '-20px', // Adjust position from the left
                  transform: 'translateY(-50%)',
                  backgroundColor: 'white',
                  color: '#23b5b7',
                  padding: '5px',
                  borderRadius: '100px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                }}
              >
                ${moduleData.totalCommission}
              </span>
              My Commission
            </Button>


            <Button
              style={{
                position: 'relative',
                padding: '10px 20px',
                backgroundColor: "#23b5b7",
                color: 'white',
                borderRadius: '10px',
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              active={activeTab === 'pending-commission'}
              onClick={() => handleTabClick('pending-commission')}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '20%',
                  left: '-10px', // Adjust position from the left
                  transform: 'translateY(-50%)',
                  backgroundColor: 'white',
                  color: '#23b5b7',
                  padding: '5px',
                  borderRadius: '100px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                }}
              >
                ${moduleData.commissionNotSettled}
              </span>
              Pending Commission
            </Button>

            <Button
              style={{
                position: 'relative',
                padding: '10px 20px',
                backgroundColor: "#23b5b7",
                color: 'white',
                borderRadius: '10px',
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              active={activeTab === 'settled-commission'}
              onClick={() => handleTabClick('settled-commission')}
            >
              <span
                style={{
                  position: 'absolute',
                  top: '20%',
                  left: '-20px', // Adjust position from the left
                  transform: 'translateY(-50%)',
                  backgroundColor: 'white',
                  color: '#23b5b7',
                  padding: '5px',
                  borderRadius: '100px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',

                }}
              >
                ${moduleData.commissionSettled}
              </span>
              Settled Commission
            </Button>

            {(role === ROLES.AGA || role === ROLES.MGA) && (
              <Button
                style={{
                  position: 'relative',
                  padding: '20px 20px',
                  backgroundColor: "#23b5b7",
                  color: 'white',
                  borderRadius: '10px',
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                active={activeTab === 'advisors-commission'}
                onClick={() => handleTabClick('advisors-commission')}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '20%',
                    left: '-10px', // Adjust position from the left
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    color: '#23b5b7',
                    padding: '5px',
                    borderRadius: '100px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                  }}
                >
                  ${moduleData.advisorCommision}
                </span>
                Advisors Commission 
              </Button>
            )}

            {/* {
              role === ROLES.MGA && (
                <Button style={{ boxShadow: " rgba(0, 0, 0, 0.35) 0px 5px 15px", backgroundColor: "#23b5b7", color: 'white', borderRadius: '10px' }} active={activeTab === 'aga-commission'} onClick={() => handleTabClick('aga-commission')}>Aga Commission :${moduleData.agaCommission}</Button>
              )
            } */}

{(role === ROLES.MGA || role === ROLES.MGA) && (
              <Button
                style={{
                  position: 'relative',
                  padding: '20px 20px',
                  backgroundColor: "#23b5b7",
                  color: 'white',
                  borderRadius: '10px',
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                active={activeTab === 'aga-commission'}
                onClick={() => handleTabClick('aga-commission')}
              >
                <span
                  style={{
                    position: 'absolute',
                    top: '20%',
                    left: '-10px', // Adjust position from the left
                    transform: 'translateY(-50%)',
                    backgroundColor: 'white',
                    color: '#23b5b7',
                    padding: '5px',
                    borderRadius: '100px',
                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 2px 5px',
                  }}
                >
                  ${moduleData.agaCommission}
                </span>
                AGA Commission 
              </Button>
            )}

          </Flex>
        {/* </Flex> */}
        <div style={{ flex: 2 }}>
          {renderForm()}
          <DisplayData activeTab={activeTab} data={{ personalData, licenseData, corporationData, referenceData }} />
        </div>
      </Box>
    </Box>
  );
};

const Tab = ({ active, onClick, children }) => {
  return (
    <button className={`tab ${active ? 'active' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

const DisplayData = ({ activeTab, data }) => {
  switch (activeTab) {
    case 'my-commission':
      return <pre>{JSON.stringify(data.personalData, null, 2)}</pre>;
    case 'pending-commission':
      return <pre>{JSON.stringify(data.licenseData, null, 2)}</pre>;
    case 'settled-commission':
      return <pre>{JSON.stringify(data.corporationData, null, 2)}</pre>;
    default:
      return null;
  }
};


const MyCommission = ({ commonData }) => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch(advsorComissionList())
      .then((res) => {
        console.log("Admin profile data fetched successfully", res);
        const formattedData = res.map(item => ({
          id: item.id,
          customerName: item.policyDetailsEntity.quote.policyHolderName,
          policyNo: item.policyDetailsEntity.policyNo,
          insuredName: item.insuredName,
          tripType: item.policyDetailsEntity.quote.product,
          issueDate: item.createdAt,
          commissionAmount: item.commissionAmount,
          correctionAmount: item.premiumAmount,
          commissionPercentage: item.commissionPercentage,
          desc: item.description,
          settledStatus: item.isSettled === 1 ? 'Settled' : 'Pending'
        }));
        console.log("formattedData", formattedData);
        setRowData(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching admin profile data:", error);
      });
  }, [dispatch]);

  const [colDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "commissionPercentage" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Commissions", 40, 20);

    // Prepare the data for the table
    const tableData = rowData.map(item => [
      item.customerName,
      item.policyNo,
      item.insuredName,
      item.tripType,
      item.issueDate,
      item.commissionAmount,
      item.correctionAmount,
      item.commissionPercentage,
      item.desc,
      item.settledStatus
    ]);

    // Set table headers
    const headers = [
      'Customer Name',
      'Policy No',
      'Insured Name',
      'Trip Type',
      'Issue Date',
      'Commission Amount',
      'Correction Amount',
      'Commission Percentage',
      'Description',
      'Settled Status'
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 30
    });

    doc.save('commissions.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Commissions');
    XLSX.writeFile(workbook, 'commissions.xlsx');
  };



  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','),
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'commissions.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterData = (data, searchTerm, fromDate, toDate) => {
    let filteredData = data;

    if (searchTerm) {
      filteredData = filteredData.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) >= from);
    }

    if (toDate) {
      const to = new Date(toDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) <= to);
    }

    return filteredData;
  };

  const filteredData = filterData(rowData, searchTerm, fromDate, toDate);

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
      <div>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={['16px', '22px', '22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>My Commission</Text>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <Button onClick={downloadPDF} style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }}>PDF</Button>
            <Button onClick={downloadExcel} style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }}>Excel</Button>
            {/* <Button onClick={downloadCSV} style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }}>CSV</Button> */}
          </div>
          {/* <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <Input
            style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
              type="text"
              id="search"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              
            />
          </Box> */}
          <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={[10, 20, 50, 100]}
            pagination
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </div>
    </Box>
  );
};


const PendingCommission = ({ onSave, data }) => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [rowData, setRowData] = useState([]);
  const gridRef = useRef(null);
  console.log("data", data)
  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formattedData = data.map(item => ({
    id: item.id,
    customerName: item.policyDetailsEntity.quote.policyHolderName,
    policyNo: item.policyDetailsEntity.policyNo,
    insuredName: item.insuredName,
    tripType: item.policyDetailsEntity.quote.product,
    issueDate: item.createdAt,
    commissionAmount: item.commissionAmount,
    correctionAmount: item.premiumAmount,
    commissionPercentage: item.commissionPercentage,
    desc: item.description,
    settledStatus: item.isSettled === 1 ? 'Settled' : 'Pending'
  }));

  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "commissionPercentage" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Pending Commissions", 20, 10);

    // Prepare the data for the table
    const tableData = formattedData.map(item => [
      item.customerName,
      item.policyNo,
      item.insuredName,
      item.tripType,
      item.issueDate,
      item.commissionAmount,
      item.correctionAmount,
      item.commissionPercentage,
      item.desc,
      item.settledStatus
    ]);

    // Set table headers
    const headers = [
      'Customer Name',
      'Policy No',
      'Insured Name',
      'Trip Type',
      'Issue Date',
      'Commission Amount',
      'Correction Amount',
      'Commission Percentage',
      'Description',
      'Settled Status'
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 20
    });

    doc.save('pending_commissions.pdf');
  };



  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','),
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterData = (data, searchTerm, fromDate, toDate) => {
    let filteredData = data;

    if (searchTerm) {
      filteredData = filteredData.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) >= from);
    }

    if (toDate) {
      const to = new Date(toDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) <= to);
    }

    return filteredData;
  };

  const filteredData = filterData(formattedData, searchTerm, fromDate, toDate);

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
      <div>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={['16px', '22px', '22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Pending Commission</Text>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            {/* <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button> */}
          </div>
          <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={[10, 20, 50, 100]}
            pagination
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </div>
    </Box>
  );
};

const SettledCommission = ({ onSave, data }) => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formattedData = data.map(item => ({
    id: item.id,
    customerName: item.policyDetailsEntity.quote.policyHolderName,
    policyNo: item.policyDetailsEntity.policyNo,
    insuredName: item.insuredName,
    tripType: item.policyDetailsEntity.quote.product,
    issueDate: item.createdAt,
    commissionAmount: item.commissionAmount,
    correctionAmount: item.premiumAmount,
    commissionPercentage: item.commissionPercentage,
    desc: item.description,
    settledStatus: item.isSettled === 1 ? 'Settled' : 'Pending'
  }));

  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "commissionPercentage" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Settled Commissions", 20, 10);

    const tableData = filteredData.map(item => [
      item.customerName,
      item.policyNo,
      item.insuredName,
      item.tripType,
      item.issueDate,
      item.commissionAmount,
      item.correctionAmount,
      item.commissionPercentage,
      item.desc,
      item.settledStatus
    ]);

    const headers = [
      'Customer Name',
      'Policy No',
      'Insured Name',
      'Trip Type',
      'Issue Date',
      'Commission Amount',
      'Correction Amount',
      'Commission Percentage',
      'Description',
      'Settled Status'
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 20
    });

    doc.save('settled_commissions.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Settled Commissions');
    XLSX.writeFile(workbook, 'settled_commissions.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(formattedData[0]).join(','),
      ...formattedData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'settled_commissions.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  const filterData = (data, searchTerm, fromDate, toDate) => {
    let filteredData = data;

    if (searchTerm) {
      filteredData = filteredData.filter(item =>
        Object.values(item).some(val =>
          String(val).toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    if (fromDate) {
      const from = new Date(fromDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) >= from);
    }

    if (toDate) {
      const to = new Date(toDate);
      filteredData = filteredData.filter(item => new Date(item.issueDate) <= to);
    }

    return filteredData;
  };

  const filteredData = filterData(formattedData, searchTerm, fromDate, toDate);

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
      <div>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={['16px', '22px', '22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Settled Commission</Text>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            {/* <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button> */}
          </div>
          <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={[10, 20, 50, 100]}
            pagination
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </div>
    </Box>
  );
};

const AdvisorsCommission = () => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [advisorData,setAdvisorData]=useState([])
console.log('advisordata',advisorData)
  const dispatch = useDispatch();
  const gridRef = useRef(null);

  useEffect(() => {
    dispatch(advsorComissionList()).then((res) => {
      console.log(res, "res");
      // Format data and filter where role === 3
      const formattedData = res.map(item => ({
        id: item.id,
        customerName: item.policyDetailsEntity.quote.policyHolderName,
        policyNo: item.policyDetailsEntity.policyNo,
        insuredName: item.insuredName,
        tripType: item.policyDetailsEntity.quote.product,
        issueDate: item.createdAt,
        commissionAmount: item.commissionAmount,
        correctionAmount: item.premiumAmount,
        commissionPercentage: item.commissionPercentage,
        desc: item.description,
        settledStatus: item.isSettled === 1 ? 'Settled' : 'Pending',
        role:item.role
      }));
  
      const filteredData = formattedData.filter(item => item.role === 3);
  
      setAdvisorData(filteredData);
      setRowData(filteredData); // Set rowData for AgGrid
    });
  }, [dispatch]);
  
  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "commissionPercentage" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);


  const [rowData, setRowData] = useState([]);


  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Advisors Commission", 20, 10);

    const tableData = rowData.map(item => [
      item.customerName,
      item.policyNo,
      item.insuredName,
      item.tripType,
      item.issueDate,
      item.commissionAmount,
      item.correctionAmount,
      item.commissionPercentage,
      item.desc,
      item.settledStatus
    ]);

    const headers = [
      'Customer Name',
      'Policy No',
      'Insured Name',
      'Trip Type',
      'Issue Date',
      'Commission Amount',
      'Correction Amount',
      'Commission Percentage',
      'Description',
      'Settled Status'
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 20
    });

    doc.save('advisors_commission.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Advisors Commission');
    XLSX.writeFile(workbook, 'advisors_commission.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','),
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
      <div>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={['16px', '22px', '22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>Advisiors Commission</Text>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            {/* <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button> */}
          </div>
          <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={advisorData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </div>
    </Box>
  );
};

const AgaCommission = () => {
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [agaData,setAgaData]=useState([])
  console.log("aga",agaData)
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  useEffect(() => {
    dispatch(advsorComissionList()).then((res) => {
      console.log(res, "res");
      // Format data and filter where role === 3
      const formattedData = res.map(item => ({
        id: item.id,
        customerName: item.policyDetailsEntity.quote.policyHolderName,
        policyNo: item.policyDetailsEntity.policyNo,
        insuredName: item.insuredName,
        tripType: item.policyDetailsEntity.quote.product,
        issueDate: item.createdAt,
        commissionAmount: item.commissionAmount,
        correctionAmount: item.premiumAmount,
        commissionPercentage: item.commissionPercentage,
        desc: item.description,
        settledStatus: item.isSettled === 1 ? 'Settled' : 'Pending',
        role:item.role
      }));
  
      const filteredData = formattedData.filter(item => item.role === 2);
  
      setAgaData(filteredData);
      setRowData(filteredData); // Set rowData for AgGrid
    });
  }, [dispatch]);
  const [colDefs, setColDefs] = useState([
    { field: "customerName" },
    { field: "policyNo" },
    { field: "insuredName" },
    { field: "tripType" },
    { field: "issueDate" },
    { field: "commissionAmount", filter: 'agNumberColumnFilter' },
    { field: "correctionAmount" },
    { field: "commissionPercentage" },
    { field: "desc" },
    { field: "settledStatus" }
  ]);


  const [rowData, setRowData] = useState([
  
  ]);


  const downloadPDF = () => {
    const MyDoc = (
      <Document>
        <Page>
          <Text>Quotations</Text>
        </Page>
      </Document>
    );

    const blob = new Blob([MyDoc], { type: 'application/pdf' });
    saveAs(blob, 'quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','),
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(','))
    ].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'90%'} m={'auto'}>
      <div>
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={['16px', '22px', '22px']} style={{ color: primaryColor, fontFamily: 'poppins', paddingBottom: '10px' }}>AGA Commission</Text>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
        <hr />
        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button>
            <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button>
            {/* <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</button> */}
          </div>
          <Box mt={['10px', '10px', '0px']} mb={['15px', '15px', '0px']}>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={agaData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </div>
    </Box>
  );
};

export default AdvisorCommission;