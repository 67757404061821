import React from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, Image, Tooltip } from '@chakra-ui/react';
import { FaRegEdit } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';

const AdminAffiliateUsers = () => {
    const navigate=useNavigate()

    const handleBack = () => {
        navigate(-1);
      }


return (
    <Sidebar headingText={'Affiliate Users'}>
      <Box w={'90%'} m={'auto'} mt={'20px'}>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
        </Box>
        <Box w={'90%'} m={'auto'} mt={'50px'}>
    <Flex justifyContent={'end'} mb={'5px'}>
      <Link to={'/admin/add-affiliate-user'}>
        <Button size={'sm'} borderRadius={'20px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'}>Add New</Button>
      </Link>
    </Flex>
  <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>

    <Box>
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '35%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '15%' }} />
            </colgroup>
            <thead>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <th style={{ textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>User Name</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Description</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Email</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Created At</th>
                    <th style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Delete </th>
                </tr>
            </thead>
            <tbody>
                <tr style={{borderBottom:'1px solid #dddddd'}}>
                    <td style={{  textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Ravi Sharma</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>This user have the access of Customer module only.</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>ravi@upscaller.com</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Active</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>05-26-2024</td>
                    <td style={{   textAlign: 'left', padding: '8px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                      {/* <Button size={'sm'} background={'red'} color={'white'} onClick={handleDelete}>Delete</Button> */}
                    </td>
                </tr>
            </tbody>
             </table>
    </Box>

    </Box>
  </Box>
    </Sidebar>
  )
}

export default AdminAffiliateUsers
