import React, { useState } from 'react';
import Sidebar from '../../Sidebar';
import { Box, Text, Input, Button, Flex, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { createDeductibles } from '../dependencies/action';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

const CreateDeductibles = () => {
  const navigate=useNavigate()
    
  const [formData, setFormData] = useState({
    amount: '',
    standardDiscount: '',
    enhancedDiscount: '',
    premiumDiscount: ''
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    // Perform form validation here
    if (formData.amount.trim() === '' || formData.standardDiscount.trim() === '' || formData.enhancedDiscount.trim() === '' || formData.premiumDiscount.trim() === '') {
      toast({
        title: 'Error',
        description: 'All fields are required.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Dispatch action to post data
    const result = await dispatch(createDeductibles(formData));
    if (result.status === 201 || result.status === 200) {
      toast({
        title: 'Success',
        description: result.message || 'Deductibles created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: result.message || 'Failed to create deductibles.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
  const handleBack = () => {
    navigate(-1);
  }
  return (
    <div>
      <Sidebar headingText="Create Deductibles">
      <Button size={'xs'} background={'#9c27b0'} color='white' marginLeft={"20px"} onClick={handleBack} _hover={{color:'white'}}>Back</Button> 
      <Box background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' p={'20px'} w={'60%'} m={'auto'} >
        <Box>
          <Text fontSize={'16px'} style={{ color: '#6c757d', fontFamily: 'poppins', paddingBottom: '10px' }}>Add Deductibles</Text>
          <hr />
          <Flex gap={'20px'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} mt={'25px'}>
            <Box>
              <Text mb={'8px'}>Amount</Text>
              <Input size={'sm'} border={'1px solid grey'} name="amount" value={formData.amount} onChange={handleChange} placeholder='' />
            </Box>
            <Box>
              <Text mb={'8px'}>Standard Discount</Text>
              <Input size={'sm'} border={'1px solid grey'} name="standardDiscount" value={formData.standardDiscount} onChange={handleChange} placeholder='' />
            </Box>
          </Flex>
          <Flex gap={'20px'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} mt={'30px'}>
            <Box>
              <Text mb={'8px'}>Enhanced Discount</Text>
              <Input size={'sm'} border={'1px solid grey'} name="enhancedDiscount" value={formData.enhancedDiscount} onChange={handleChange} placeholder='' />
            </Box>
            <Box>
              <Text mb={'8px'}>Premium Discount</Text>
              <Input size={'sm'} border={'1px solid grey'} name="premiumDiscount" value={formData.premiumDiscount} onChange={handleChange} placeholder='' />
            </Box>
          </Flex>
        </Box>
        <Flex justifyContent={'end'}>
        <Button onClick={handleSubmit} background={'#9124a3'} color={'white'}  marginTop={'20px'}>Create</Button>
        </Flex>
        </Box>
      </Sidebar>
    </div>
  );
};

export default CreateDeductibles;
