import React, { useEffect, useState } from 'react'
import {Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack, Textarea } from "@chakra-ui/react";
import Api from '../../../dependencies/utils/Api';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import { DOWNLOAD_BENEFIT_COMPARISON, GET_QUOTE_BY_QUOTATION_NO } from '../../../constants/ApplicationUrl';


const ViewQuote = ({id,sendDataToParent}) => {
    const [data,setData]=useState([])
    const [dataToSend, setDataToSend] = useState('');
    const [tabNumber,setTabNumber]=useState(0)
    const { token } = JSON.parse(localStorage.getItem("code_dev"));
    const [policyType,setPolicyType]=useState('')

useEffect(()=>{
    getData()
},[])

const getData =()=>{
    Api.GET(`${GET_QUOTE_BY_QUOTATION_NO}/${id}`).then((res)=>{
        console.log(res,'res')
        setData([res.data])
        setPolicyType(res.data.product)
    })
}


const handleProceed = () => {
    if (data.length > 0) {
        const ele = data[0];
        const sendData = {
            amount: ele.quoteAmount,
            data: {
                idQuote: ele.idQuote,
                quotationNo: ele.quotationNo
            },
            tab:1
        };
        sendDataToParent(sendData);
    }
};

const handleBenefitComparisonDownload = async () => {
    try {
      const response = await fetch(`${DOWNLOAD_BENEFIT_COMPARISON}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      saveAs(blob, 'BenefitComparison.pdf'); 
    } catch (error) {
      console.error('Failed to download PDF:', error);
    }
  };


return (
    <>
    {data && data.map(ele=>(  
    <div style={{backgroundColor:"rgb(251 251 251)", width: "100%", padding: "10px",paddingTop:'0px',textAlign:'left', border:'2px solid #c4c4c4'}}>
    <div style={{ display: "flex",flexDirection: "column",alignItems: "center",width:'99%',margin:'auto'}}>
        <Box p={['5px','5px','18px']}
            style={{
            width: "100%",
            margin: "auto",
            }}
        >
            <Flex justifyContent={'space-between'} borderBottom={'2px solid #088183'} mb={'15px'} pb={'12px'} alignItems={'center'}>
            <p style={{ fontFamily: "poppins", fontSize: "16px", fontWeight: 400}}>Trip Details</p>
            <Button onClick={handleProceed} backgroundColor={buttonBackgroundColor} color={buttonColor} size={'sm'} _hover={{color:'white'}}>Proceed</Button>
            </Flex>
            <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
            <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'13px'} pb={'20px'}>
            <Box w={['auto','20%','20%']}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <Input value={ele.firstDateOfCover} readOnly size='xs' type='date' border={'1px solid #d7d7d7'} />
            </Box>
            <Box w={['auto','20%','20%']}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <Input value={ele.lastDateOfCover} readOnly size='xs' type='date' border={'1px solid #d7d7d7'}/>
            </Box>
            <Flex w={['auto','7%','10%']} direction={'column'}>
                <span>Duration</span>
                <Input w={'80px'} value={`${ele.duration} days`} readOnl size='xs' backgroundColor={'#eaeaea'} border={'1px solid #d7d7d7'} />
            </Flex>
            <Box w={['auto','20%','20%']}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
            </Box>
            <Box w={['auto','20%','20%']}>
                <span style={{ marginBottom: "8px" }}>Province</span>
                <p>{ele.policyHolderProvince}</p>
            </Box>
            </Flex>
            <Flex direction={['column','column','row']} gap={['10px','40px','40px']} mt={['0px','20px','10px']} justifyContent={'space-between'} fontSize={'14px'}>
            <Box w={['auto','20%','30%']}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <Input value={ele.departureFromCountryOfOrigin} readOnly size='xs' type='date' border={'1px solid #d7d7d7'} />
            </Box>
            <Flex direction={'column'} w={['auto','20%','20%']}>
                <span>Waiting Period</span>
                <input
                value={ele.waitingPeriod}
                readOnly
                />
            </Flex>
            <Box w={['auto','20%','30%']}>
                <Text>Family Coverage</Text>
            <Checkbox checked={ele.familyCoverage===0?true:false} readOnly size="md" colorScheme="green"></Checkbox>
            </Box>
            </Flex>
            </Box>
        </Box>
    </div>


    <div style={{width: "100%",
        margin: "auto",
        padding: "18px",}}>
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Traveller Details</p>
    {ele.listOfInsured.map(item=>(
    <Box fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
        <Flex mt={'20px'} direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'10px'}>
            <Box w={['auto','20%','30%']}>
            <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                <Input value={item.insuredName} readOnly size='xs' border={'1px solid #d7d7d7'} />
                </Box>
                <Box w={['auto','20%','30%']}>
                <span style={{ marginBottom: "8px" }}>Gender</span>
                <Input color={'grey'} value={item.insuredGender} readOnly background={'white'} size='xs' border={'1px solid #d7d7d7'}/> 
                </Box>
                <Box w={['auto','20%','30%']}>
                <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                <Input value={item.insuredDob} readOnly size='xs' type='date' border={'1px solid #d7d7d7'} />
            </Box>
        </Flex>
        <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'}>
        <Box w={['auto','20%','30%']}>
         <span style={{ marginBottom: "8px" }}>Beneficiary Name</span>
            <Input readOnly value={item.beneficiaryName} size='xs' border={'1px solid #d7d7d7'} />
            </Box>
            <Box w={['auto','20%','30%']}>
            <span style={{ marginBottom: "8px" }}>Relation</span>
            <Input value={item.beneficiaryRelationToInsured} readOnly size='xs' border={'1px solid #d7d7d7'} />
            </Box>
            <Box w={['auto','20%','30%']}>
            <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
            <Input value={item.beneficiaryDob} readOnly size='xs' type='date' border={'1px solid #d7d7d7'} />
        </Box>
        {/* {index > 0 && (
        <Flex justifyContent={'end'}>
                <IoMdCloseCircle
                    style={{fontSize:'22px',color:buttonBackgroundColor}}
                    onClick={() => handleRemoveTraveller(index)}
                    />
                </Flex>
            )} */}
        </Flex>
    </Box>
     ))}
    </div>


    <div style={{width: "100%",margin: "auto",padding: "18px",paddingBottom:'10px'}}>    
    <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Plan Details</p>
     <Flex direction={['column','column','row']} gap={['10px','40px','40px']} justifyContent={'space-around'} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'} pb={'20px'}>
        <Box>
            <Text>Policy Limit</Text>
            <Text size='xs'> {ele.policyLimit}</Text>
        </Box>
        <Box>
          <Text>Deductible</Text>
          <Text size='xs'> {ele.deductible}$</Text>
        </Box>  
        <Box>
            <Text>Plan Type</Text>
            <Text size='xs'> {ele.listOfInsured[0].insuredPlan}</Text>
        </Box>
        <Box>
          <Text>Super Visa</Text>
          <Text size='xs'> {ele.superVisa===1?'YES':'NO'}</Text>
        </Box>  
        { ele.superVisa===1?
                        <Box>
                          <Text fontSize={'12px'}>Payments Frequency</Text>
                            <Select color={'black'} disabled background={'white'} size={'xs'} value={ele.paymentFrequency}>
                              <option value="0">One lump sum</option>
                              <option value="2">Monthly</option>
                            </Select>
                          </Box>:''
                          }
                          { policyType==='STC'?
                        <Box>
                          <Text fontSize={'12px'}>Student Policy Option</Text>
                            <Select color={'black'} disabled background={'white'} size={'xs'} value={ele.paymentFrequency}>
                              <option value="Single Trip">Single Trip</option>
                              <option value="Annual">Annual</option>
                            </Select>
                          </Box>:''
                          }
        </Flex>
        <hr />
    </div>

    <Flex direction={['column','column','row']}>
                    <Box w={['95%','50%','50%']}>
                    <TableContainer >
                    <Table>
                        <Thead>
                            <Tr gap={'50px'} fontSize={'11px'}>
                                <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>User Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>Plan Name</Th>
                                <Th p={'7px'} fontSize={'10px'}>With Pre-existing</Th>
                                <Th p={'7px'} fontSize={'10px'}>Without Pre-existing</Th>
                            </Tr>
                        </Thead>
                        <Tbody p={'7px'}>
                            {
                                ele.listOfInsured.map((item)=>(
                                <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                    <Td p={'2px'}>{item.insuredName}</Td>
                                    <Td p={'2px'}>{item.insuredPlan}</Td>
                                    <Td p={'2px'} display={'flex'}>
                                    <Text pl={'10px'}>
                                    <input
                                      type="radio"
                                      disabled
                                      checked={item.selectedKey===2}
                                      />
                                      {item.costWithPreExisting}
                                      </Text>
                                    </Td>
                                    <Td p={'2px'}>
                                    <Text pl={'10px'}>
                                    <input
                                      type="radio"
                                      disabled
                                      checked={item.selectedKey===3}
                                      />
                                      {item.costWithoutPreExisting}</Text>
                                    </Td>
                                </Tr>
                                ))
                            }
                        </Tbody>
                    </Table>
                    </TableContainer>
                    </Box>
                    <Box w={['95%','50%','50%']}>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th p={'7px'} fontSize={'10px'}>Period</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Net</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Tax</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Adjustment</Th>
                                    <Th p={'7px'} fontSize={'10px'}>Total</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                    <Tr color={'grey'} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                        <Td p={'2px'}>{`${ele.firstDateOfCover} - ${ele.lastDateOfCover}`}</Td> 
                                        <Td p={'2px'}>{policyType==='STC'?ele.quoteAmount-ele.listOfInsured[0].adjAmt:ele.quoteAmount}$</Td>
                                        <Td p={'2px'}>0.00 CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'}>{policyType==='STC'?ele.listOfInsured[0].adjAmt:'0.00'} CAD	</Td>
                                        <Td p={'2px'} textAlign={'center'} fontWeight={'600'}>{ele.quoteAmount}$</Td>
                                    </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer></Box> 
    </Flex>
    <Text color={'red'} fontSize={'11px'}>{ele.message}</Text>
   <div style={{width: "100%",margin: "auto",padding: "18px",}}>  
        <p style={{fontFamily: "poppins",fontSize: "14px",fontWeight: 400,marginBottom: "20px",borderBottom: "2px solid #088183",}}>Contact Details</p>
            <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} mt={'20px'} pl={'15px'}>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner*</Text>
              <Input color={'grey'} value={ele.policyHolderName} size='xs' name="policyOwnerDob" border={'1px solid #d7d7d7'} />  
            </Box>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input type='date' size='xs' value={ele.policyHolderDob} readOnly border={'1px solid #d7d7d7'} />            
            </Box>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' value={ele.policyHolderEmail} readOnly size='xs' border={'1px solid #d7d7d7'}/>    
            </Box>
            </Flex>
            <Flex direction={['column','column','row']} gap={['10px','40px','40px']} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pb={'20px'} mt={'15px'} pl={'15px'}>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner Postal Code*</Text>
              <Input value={ele.policyHolderPostalCode} readOnly size='xs' border={'1px solid #d7d7d7'}/>   
            </Box>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner City*</Text>
              <Input value={ele.policyHolderCity} readOnly placeholder='city' size='xs' border={'1px solid #d7d7d7'} />   
            </Box>
            <Box w={['auto','20%','27%']}>
              <Text>Policy Owner Phone*</Text>
              <Input value={ele.policyHolderPhone} readOnly placeholder='phone' size='xs' border={'1px solid #d7d7d7'}/>            
            </Box>
            </Flex>
            <Box w={['auto','20%','28%']} fontSize={'12px'} fontFamily={'Open Sans, sans-serif'} pl={'15px'}>
              <Text>Policy Owner Address*</Text>
              <Textarea value={ele.policyHolderAddress} readOnly size='xs' border={'1px solid #d7d7d7'}/>     
            </Box>
            <Flex gap='40px'>
            </Flex></div>
    </div>
    ))}
    </>
  )
}

export default ViewQuote
