import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, ChakraProvider, Flex, FormControl, FormLabel, Input, Tooltip } from '@chakra-ui/react';
import CustomInput, { theme } from '../../../Utils/Input/Index';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css"; 
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useDispatch } from 'react-redux';
import { customerAll } from './dependencies/action';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';


const Customers = () => {
  const navigate=useNavigate()
  const [searchQuery, setSearchQuery] = useState('');
  const viewCustomer = (params)=>{
    const handleView = () => {
      navigate(`/admin/customer-dashboard/${params.data.id}`)
    };
  return(
    <div style={{ display: 'flex', justifyContent: 'center',marginTop:'5px'}}>
      <Button onClick={handleView} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
  </div>
  )
  }


  const [colDefs, setColDefs] = useState([  
  { field: "firstName",width:130,filter:true,},
  { field: "lastName",width:100,filter:true},
  { field: "email",width:150,filter:true},
  { field: "phone",width:140 },
  { field: "createdAt",width:100 },
  { field: "Dashboard",width:120,cellRenderer:viewCustomer, },
]);
  const [rowData, setRowData] = useState([]);
  const dispatch=useDispatch()


  useEffect(() => {
    dispatch(customerAll())
      .then(res => {
        console.log(res,'fffff')
          const formattedData =res.map((quote, index) => ({
            id: quote.idUser,
            firstName: quote.firstName,
            lastName: quote.lastName, 
            email: quote.email,
            phone: quote.phone,
          }));
          setRowData(formattedData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [dispatch]);


  const pagination = true;
  const paginationPageSizeSelector = [2, 5, 1000];

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Customers List", 20, 10);
    const tableData = rowData.map(item => [
      item.firstName,
      item.lastName,
      item.email,
      item.phone,
    ]);
    const headers = ['First Name', 'Last Name', 'Email', 'Phone'];
    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 20,
    });
    doc.save('customers_list.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Customers');
    XLSX.writeFile(workbook, 'customers_list.xlsx');
  };

  
      const handleSearch = (value) => {
        console.log("Search Query:", value);
        setSearchQuery(value);
      };
    
 
      const filteredRowData = rowData.filter(item =>
        (item.firstName?.toLowerCase() ?? '').includes(searchQuery.toLowerCase()) ||
        (item.lastName?.toLowerCase() ?? '').includes(searchQuery.toLowerCase()) ||
        (item.email?.toLowerCase() ?? '').includes(searchQuery.toLowerCase()) ||
        (item.phone?.toLowerCase() ?? '').includes(searchQuery.toLowerCase())
      );

return (
  <Sidebar headingText={'Customers'}>   
  <Box w={'90%'} m={'auto'} mt={'70px'}>
  <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
    <Flex justifyContent={'space-between'} mt={'10px'} mb={'25px'}>
        <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
        <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
          <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button></Tooltip>
          <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
          <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button></Tooltip>
        </div>
        <div>
        {/* <ChakraProvider theme={theme}>
      <FormControl variant="floating" id="search">
          <Input borderRadius={5} size={'sm'} background={'white'} onChange={handleSearch} placeholder=" " />
          <FormLabel size={'sm'}>Search</FormLabel>
        </FormControl>
        </ChakraProvider> */}
        <CustomInput onChange={handleSearch}/>
        </div>
    </Flex>

    <Box>

    <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
                rowData={filteredRowData}
                columnDefs={colDefs}
                // frameworkComponents={{ actionCellRenderer }}
                domLayout='autoHeight'
                paginationPageSizeSelector={paginationPageSizeSelector}
                pagination={pagination}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
              />
        </div>
    </Box>

    </Box>
  </Box>
</Sidebar>
  )
}

export default Customers
