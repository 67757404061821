import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { useParams } from 'react-router-dom';
import { adminInsuredList } from '../dependencies/action';
import { useDispatch } from 'react-redux';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Input, Flex, Button } from "@chakra-ui/react";

const ViewTable = () => {
    const [insuredData, setInsuredData] = useState([]);
    const params = useParams();
    const dispatch = useDispatch();
    const { id } = params;

   

    useEffect(() => {
        dispatch(adminInsuredList())
            .then((res) => {
                // console.log("Admin insured details fetched successfully", res);
                setInsuredData(res);
            })
            .catch((error) => {
                console.error("Error fetching admin profile data:", error);
                setLoading(false); // Update loading state in case of error
            });
    }, [dispatch]);

    // Filter the insuredData array based on id (planType)
    const filteredData = insuredData.find(plan => plan.planName === id);

    // Check if filteredData exists before accessing insurancePlanData
    if (!filteredData) {
        return <div>Loading...</div>; // or handle the case when filteredData is not found
    }

    // Convert filteredData to an array
    const dataArray = JSON.parse(filteredData.insurancePlanData);

    //  const EditableCell = ({ value, onChange }) => {
    //     const handleChange = (e) => {
    //         onChange(e.target.value);
    //     };

    //     return (
    //         <Td>
    //             <input type="text" value={value} onChange={handleChange} />
    //         </Td>
    //     );
    // };

    return (
        <div>
        <Sidebar headingText={"View Table"} >
            <Box background={'white'} w={'90%'} m={'auto'} p={'20px'}>
            <Table>
                <Thead>
                    <Tr>
                    <Th w={'200px'}>{filteredData.planName}</Th>
                {filteredData.planPrice.split(',').map((price, index) => (
                    <React.Fragment key={index}>
                        <Th borderLeft={'1px solid grey'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} textAlign={'center'} color={'#9c27b0'} colSpan={2}>{price}</Th>
                    </React.Fragment>
                ))}
                    </Tr> 
                    
                    {filteredData.planName === "Standard" && (
                            <>
                                <Th textAlign={'center'}>Ages</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                            </>
                        )}
                        {filteredData.planName === "Enhanced" && (
                            <>
                                <Th textAlign={'center'}>Ages</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                            </>
                        )}
                        {filteredData.planName === "Premium" && (
                            <>
                                <Th textAlign={'center'}>Ages</Th>
                                
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                                <Th textAlign={'center'}>Pre-X</Th>
                                <Th textAlign={'center'}>No Pre-X</Th>
                            </>
                        )}
                </Thead>
                <Tbody>
               
                {dataArray.map((data, index) => (
    <Tr key={index}>
        {filteredData.planName === "Standard" && (
             <>
             <Box fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} textAlign={'center'} as="td" borderWidth="1px" borderRadius="md">{data.row_data.split(',')[0]} {data.row_data.split(',')[1]} {data.row_data.split(',')[2]}</Box>
             <Box fontFamily={'Open Sans,sans-serif'} fontSize={'12px'} textAlign={'center'} as="td" borderWidth="1px" borderRadius="md" p={'5px'}>
             <Input w={'60px'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[3]}/></Box>
             <Box fontFamily={'Open Sans,sans-serif'} p={'5px'} as="td" borderWidth="1px" borderRadius="md" >
             <Input w={'60px'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[4]}/></Box>
             <Box fontFamily={'Open Sans,sans-serif'} p={'5px'} as="td" borderWidth="1px" borderRadius="md">
             <Input w={'60px'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[5]}/></Box>
             <Box fontFamily={'Open Sans,sans-serif'} p={'5px'} as="td" borderWidth="1px" borderRadius="md"><Input w={'60px'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[6]}/></Box>
             <Box fontFamily={'Open Sans,sans-serif'} p={'5px'} as="td" borderWidth="1px" borderRadius="md">
             <Input w={'60px'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[7]}/></Box>
             <Box fontFamily={'Open Sans,sans-serif'} p={'5px'} as="td" borderWidth="1px" borderRadius="md">
             <Input w={'60px'} m={'auto'} fontSize={'12px'} border={'1px solid grey'} borderRadius={'0px'} value={data.row_data.split(',')[8]}/></Box>
         </>
        )}
        {filteredData.planName === "Enhanced" && (
            <>
                <Td>{data.row_data.split(',')[0]} {data.row_data.split(',')[1]} {data.row_data.split(',')[2]}</Td>
                <Td>{data.row_data.split(',')[3]}</Td>
                <Td>{data.row_data.split(',')[4]}</Td>
                <Td>{data.row_data.split(',')[5]}</Td>
                <Td>{data.row_data.split(',')[6]}</Td>
                <Td>{data.row_data.split(',')[7]}</Td>
                <Td>{data.row_data.split(',')[8]}</Td>
                <Td>{data.row_data.split(',')[9]}</Td>
                <Td>{data.row_data.split(',')[10]}</Td>
            </>
        )}
        {filteredData.planName === "Premium" && (
            <>
                <Td>{data.row_data.split(',')[0]} {data.row_data.split(',')[1]} {data.row_data.split(',')[2]}</Td>
                <Td>{data.row_data.split(',')[3]}</Td>
                <Td>{data.row_data.split(',')[4]}</Td>
                <Td>{data.row_data.split(',')[5]}</Td>
                <Td>{data.row_data.split(',')[6]}</Td>
                <Td>{data.row_data.split(',')[7]}</Td>
                <Td>{data.row_data.split(',')[8]}</Td>
                <Td>{data.row_data.split(',')[9]}</Td>
                <Td>{data.row_data.split(',')[10]}</Td>
                <Td>{data.row_data.split(',')[11]}</Td>
                <Td>{data.row_data.split(',')[12]}</Td>
            </>
        )}
    </Tr>
))}
                
                </Tbody>
            </Table>
            <Flex justifyContent='end' mt={'15px'}>
                <Button background='#9124a3' color='white' size='sm'>Update</Button>
            </Flex>
            </Box>
            </Sidebar>
        </div>
    );
};

export default ViewTable;