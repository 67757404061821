import React from 'react'
import Sidebar from '../Sidebar'

const Policies = () => {


  return (
    <Sidebar headingText={'Policies'}>
      
    </Sidebar>
  )
}

export default Policies
