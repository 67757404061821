import { STUDENTVISA_QUICKNOTE } from "../../constants/actionConstants";

const initialState = {
  studentQuote: [],
};

export default (state = initialState, action)=> {
  switch (action.type) {
    case STUDENTVISA_QUICKNOTE:
      
        return { ...state, studentQuote: action.payload };
        
    default:
      return state;
  }
}
