import { ADMIN_PAGES_EDIT_BYID, ADMIN_PAGES_GET_BY_ID, ALL_PAGES_CONTENT_DETAILS, REFUND_SETTINGS_EDIT, REFUND_SETTINGS_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminRefundSettingsService = {
    adminRefundSettingsEdit(id,params) {
        return Api.PUT(`${REFUND_SETTINGS_EDIT}/${id}`,params).then((response) => {
            console.log("advisorlogin",response)
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

    adminRefundList() {
        return Api.GET(REFUND_SETTINGS_LIST).then((response) => {
            // console.log(response,'responserviceadvisor')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    }
  
}