import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button, space } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import Sidebar from '../Sidebar';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Api from '../../../dependencies/utils/Api';
import { adminQouteList } from './dependencies/action';
import { GET_ALL_QUOTATION, SEND_QUOTE } from '../../../constants/ApplicationUrl';
import jsPDF from 'jspdf';

const Quotes = () => {
  const navigate = useNavigate();
  const pagination = true;
  const paginationPageSizeSelector = [10, 20, 30, 50, 1000];
  const [quotesData, setQuotesData] = useState([]);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [productFilter, setProductFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
 
  const [searchTerm, setSearchTerm] = useState('');
  
  useEffect(() => {
    dispatch(adminQouteList())
      .then((res) => {
        if (res && Array.isArray(res.data)) {
          const formattedData = res.data.map((quote, index) => ({
            id: index + 1,
            employeeName: quote.user.firstName,
            designation: quote.user.roles.name,
            product: quote.product === 'Studentvisa' ? 'STC' : 'VTC',
            quotationNo: quote.quotationNo,
            dateOfIssue: quote.createdAt,
            tripType: quote.tripType,
            customerName: quote.policyHolderName,
            quoteAmount: `$${quote.quoteAmount}`,
          }));
          setRowData(formattedData);
        } else {
          console.error("Invalid response format:", res);
        }
      })
      .catch((error) => {
        console.error("Error fetching admin profile data:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    getAllQuotes();
  }, []);

  const getAllQuotes = () => {
    Api.GET(`${GET_ALL_QUOTATION}`).then((res) => {
      console.log(res, 'data');
      if (res.status === 200) {
        setQuotesData(res.data);
        const formattedData = res.data.map((quote, index) => ({
          id: index + 1,
          employeeName: quote.user.firstName,
          designation: quote.user.roles.name,
          product: quote.product === 'Studentvisa' ? 'STC' : 'VTC',
          quotationNo: quote.quotationNo,
          dateOfIssue: quote.createdAt,
          tripType: quote.tripType,
          customerName: quote.policyHolderName,
          quoteAmount: `$${quote.quoteAmount}`,
        }));
        setRowData(formattedData);
      }
    });
  };

  const downloadCellRenderer = (params) => {
    const handleDownload = () => {
      navigate('/admin/download-quote-documents');
      console.log('Download clicked for row:', params.data);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
      </div>
    );
  };

  const viewTransactions = (params) => {
    const handleView = () => {
      navigate(`/admin/quotePaymentTranscation/${params.data.quotationNo}`);
      console.log('Download clicked for row:', params.data);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <Button onClick={handleView} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
      </div>
    );
  };

  const handleAction = (params) => {
    const handleChange = (value) => {
      if (value === 'edit') {
        navigate(`/admin/editQuote/${params.data.quotationNo}`);
      }
      if (value === 'send') {
        Api.POST(`${SEND_QUOTE}${params.data.quotationNo}`).then((res) => {
          console.log(res, 'quotes sending')
        })
        alert('Quote has been sent successfully')
      }
      console.log('Download clicked for row:', params.data, value);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '5px' }}>
        <select onChange={(e) => handleChange(e.target.value)}>
          <option value="">Select</option>
          <option value="edit">Edit Quote</option>
          <option value="send">Send Quote</option>
        </select>
      </div>
    );
  };

  const [colDefs, setColDefs] = useState([
    { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
    { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true },
    { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 120 },
    { field: "tripType", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "quotationNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 250 },
    { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
    { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "quoteAmount", wrapHeaderText: true, headerClass: 'header', width: 175 },
    { field: "download", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: downloadCellRenderer, width: 140, wrapHeaderText: true },
    { field: "paymentTransactions", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellRenderer: viewTransactions, autoHeight: true },
    { field: "Action", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: handleAction },
  ]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case 'product':
        setProductFilter(value);
        break;
      case 'role':
        setRoleFilter(value);
        break;
      case 'user':
        setUserFilter(value);
        break;
      default:
        break;
    }
  };

  const filteredData = rowData.filter(data => {
    const matchesSearchQuery = data.employeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.designation.toLowerCase().includes(searchQuery.toLowerCase()) ||
      data.customerName.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesProductFilter = productFilter ? data.product === productFilter : true;
    const matchesRoleFilter = roleFilter ? data.designation === roleFilter : true;
    const matchesUserFilter = userFilter ? data.employeeName === userFilter : true;

    return matchesSearchQuery && matchesProductFilter && matchesRoleFilter && matchesUserFilter;
  });

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Quotations", 20, 20);

    // Generate table headers
    const headers = Object.keys(rowData[0])
  .filter(header => !header.toLowerCase().includes('id'))
  .map(header => header.toUpperCase());
    const data = rowData.map(obj => Object.values(obj));

    doc.autoTable({
      startY: 30,
      head: [headers],
      body: data
    });
 
    doc.save('quotations.pdf');
  };

  const downloadExcel = () => {
    // Capitalize headers
    const headers = Object.keys(rowData[0]).map(header => header.toUpperCase());

    const worksheet = XLSX.utils.json_to_sheet(rowData);
    worksheet['!cols'] = [];
    headers.forEach((h, i) => {
      worksheet['!cols'].push({ wch: h.length + 5 });
      worksheet[XLSX.utils.encode_cell({ c: i, r: 0 })] = { v: h };
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','), // Header row
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Sidebar headingText={'Quotes'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select" onChange={(e) => handleFilterChange('product', e.target.value)}>
              <option value="">All Products</option>
              <option value="STC">STC Product</option>
              <option value="VTC">VTC Product</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="role-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="role-select" onChange={(e) => handleFilterChange('role', e.target.value)}>
              <option value="">Select Roles</option>
              <option value="Manager">Manager</option>
              <option value="Executive">Executive</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="user-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="user-select" onChange={(e) => handleFilterChange('user', e.target.value)}>
              <option value="">Select Users</option>
              {quotesData.map((quote) => (
                <option key={quote.user.id} value={quote.user.firstName}>{quote.user.firstName}</option>
              ))}
            </select>
          </Box>
          <Flex>
        <Box>
          <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" />
        </Box>
        <Box>
          <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
          <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="to-date" />
        </Box>
      </Flex>
          <Box mt={'20px'}>
            <Button size={'sm'} background={'#9c27b0'} value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} color='white'>Search</Button>
          </Box>
          <Box mt={'20px'} ml={'15px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' onClick={() => { setProductFilter(''); setRoleFilter(''); setUserFilter(''); setSearchQuery(''); }}>Reset</Button>
          </Box>
        </Flex>
        <br />
      </div>

      <Box w={'95%'} m={'auto'} background={'white'} p={'10px'} mb={'30px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" value={searchQuery} onChange={handleSearch} />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </Box>
    </Sidebar>
  );
};

export default Quotes;
