import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Flex, Heading, Image, Input, Text, Textarea } from '@chakra-ui/react'
import axios from 'axios';
import { buttonBackgroundColor, buttonColor } from '../../../Utils/UniversalColor';
import VTC from '..';
import SuperVisa from '..';

const SuperVisaContactUs = () => {
    const [submitStatus, setSubmitStatus] = useState(null);
  
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: '',
      permission: true, 
    });
  
    const [formErrors, setFormErrors] = useState({});
  
  
    useEffect(() => {
      document.title = 'Contact Us | Connect Today - Upscaller Awaits Your Contact. | Upscaller';
      const metaDescription = document.createElement('meta');
      metaDescription.name = 'Contact us - Upscaller Development Excellence';
      metaDescription.content = 'Get in touch with us for top-notch upscaler development services. Our expert team is ready to elevate your business. Contact Upscaller for customized solutions and seamless collaboration.';
      document.head.appendChild(metaDescription);
    
      return () => {
        document.head.removeChild(metaDescription);
      };
    }, []);

  
    const validateForm = () => {
      const errors = {};
  
      // Validate name
      if (!formData.name.trim()) {
        errors.name = 'Name is required';
      }
  
      // Validate email
      if (!formData.email.trim()) {
        errors.email = 'Email is required';
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Enter a valid email address';
      }
  
      // Validate phone
      if (!formData.phone.trim()) {
        errors.phone = 'Phone is required';
      } else if (!isValidPhone(formData.phone)) {
        errors.phone = 'Enter a valid phone number';
      }
  
      // Validate message
      if (!formData.message.trim()) {
        errors.message = 'Message is required';
      }
  
      // Validate permission checkbox
      if (!formData.permission) {
        errors.permission = 'You must grant permission to submit the form';
      }
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0; // Return true if there are no errors
    };
  
    const isValidEmail = (email) => {
      // Use a regular expression to validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    const isValidPhone = (phone) => {
      // Use a regular expression to validate phone number format
      const phoneRegex = /^[0-9]{10}$/;
      return phoneRegex.test(phone);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (validateForm()) {
        try {
          const response = await axios.post(`'baseUrl'/contact/submit`, formData);
          setSubmitStatus('success');
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
            permission: true,
          });
        } catch (error) {
          console.error('Error:', error);
          setSubmitStatus('error');
        }
      } else {
        console.log('Form has validation errors');
      }
    };


 return (
    <div>
    <SuperVisa />
    <Box w={'85%'} m={'auto'} pt={'50px'} mb={'40px'}>
    <Flex>
        <Box border={'1px solid grey'} textAlign={'left'} w={['95%', '60%', '60%', '50%']} m={'auto'} h={'auto'} pb={'20px'} mt={'10px'}>
              <Box w={['92%', '92%', '62%', '92%']} m={'auto'} mt={'30px'}>
                <Box w={['90%', '90%', '60%', '82%']} m='auto' mt={'15px'}>
                  <form onSubmit={handleSubmit}>
                    <Flex>
<Box w={'50%'}> 
                    <label>
                      Name
                      <br />
                      <Input
                        type="text"
                        name="name"
                        w={'95%'}
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        />
                      {formErrors.name && (
                          <Text color="red" fontSize="sm">
                          {formErrors.name}
                        </Text>
                      )}
                    </label>
                    </Box>
                    <Box w={'50%'}>
                    <label>
                      Phone
                      <br />
                      <Input
                        type="text"
                        mb={'10px'}
                        w={'95%'}
                        name="phone"
                        placeholder="Phone"
                        value={formData.phone}
                        onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                        />
                      {formErrors.phone && (
                          <Text color="red" fontSize="sm">
                          {formErrors.phone}
                        </Text>
                      )}
                    </label>
                    </Box>
                      </Flex>
                    <label>
                      Email <br />
                      <Input
                        mb={'15px'}
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      />
                      {formErrors.email && (
                        <Text color="red" fontSize="sm">
                          {formErrors.email}
                        </Text>
                      )}
                    </label>
                    <label>
                      Message <br />
                      <Textarea
                        mb={'15px'}
                        type="text"
                        name="message"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                      />
                      {formErrors.message && (
                        <Text color="red" fontSize="sm">
                          {formErrors.message}
                        </Text>
                      )}
                    </label>
                    <div>
                      <Button
                        mt={'10px'}
                        background={buttonBackgroundColor}
                        color={buttonColor}
                        minH={'2.5rem'}
                        p={'.4375rem 1.5rem .5625rem'}
                        borderRadius={'1.25rem'}
                        type='submit'
                       
                      >
                        Submit
                      </Button>
                      {submitStatus === 'success' && (
                        <Text color="green" pt={'10px'}>
                         Thank you, {formData.name.split(' ')[0]}! Your submission was successful.
                        </Text>
                      )}
                      {submitStatus === 'error' && (
                        <Text color="red" pt={'10px'}>
                          Submission failed. Please try again.
                        </Text>
                      )}
                    </div>
                  </form>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
        </div>
  )
}

export default SuperVisaContactUs
