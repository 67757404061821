import React from "react";
import {Box,Flex,Image,Text,Button} from '@chakra-ui/react'
import { buttonBackgroundColor, buttonColor } from "../../../Utils/UniversalColor";
import Chatbot from "../../../Utils/Chat/Chatbot";
import { Link } from "react-router-dom";
import SideBox from "../../../Utils/SideBox/SideBox";

function CustomerHomePage(){



return(
        <div style={{background:'#f0f0f0'}}>
            <Chatbot/>
            <SideBox/>
        <Box background={'#f0f0f0'} pt={'120px'} w={'80%'} m={'auto'} pb={'70px'}>
            <Flex gap={'20px'} direction={['column','column','row']} justifyContent={'space-around'}>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/international-passport-3d-vector-illustration-documents-traveling-abroad-cartoon-style-isolated-white-background-summer-tourism-vacation-travel-concept_778687-1721.jpg?t=st=1711104162~exp=1711107762~hmac=e01a7b17d795fc4ca924a7da010ff26390362ff1613a0a8539ff14c5cc53247c&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Super Visa Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Min of CAD 100,000 of Sum Insured for min duration of 365 days.</li>
                        <li> No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/supervisa-getQuote'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'50px'} src="https://img.freepik.com/free-vector/meeting-point-location-pin-design_25030-78591.jpg?t=st=1711019429~exp=1711023029~hmac=a46be01bea14599c1449e4f18a995999f8be3ca97e190f3f135fb53e11f8379e&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Visitor Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li>Policy can be bought for duration of Trip up to max continuous period of 2 years.</li>
                        <li>  No Cancellation Penalty.</li>
                        <li>Age from 15 days to 90 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li>Just 90 days stability condition up to age 74.</li>
                        <li>Monthly Payments Available.</li>
                        <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li>
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/visitor-getquote'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
                <Box _hover={{boxShadow:'rgba(0, 0, 0, 0.35) 0px 5px 15px'}} borderRadius={'5px'} p={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px' background={'white'} w={['96%','30%','30%']}>
                    <Flex justifyContent={'center'} pt={'15px'}>
                        <Image w={'60px'} src="https://img.freepik.com/free-vector/academic-excellence-illustration_24877-52355.jpg?t=st=1711019454~exp=1711023054~hmac=6c7987360f9c7154270147fc984e9bc4655c5f3312fdda7ccd25235103e6cc2a&w=740"/>
                    </Flex>
                    <Text textAlign={'center'} pt={'10px'}>
                        Student Insurance
                    </Text>
                    <Box fontSize={'12px'} p={'20px'}>
                    <ul>
                        <li> Just Pay $495 per year for $2 million Coverage for Standard plan.</li>
                        <li> Age from 15 days to 65 Years & 3 Product Choices Standard, Enhanced and Premium.</li>
                        <li> Parents and Legal Guardians of the Insured Student Can be added to the Policy.</li>
                        <li>Covers you for Emergency Medical & Medical Expenses as per Policy Conditions.</li>
                        <li> Extended Benefits Include Physical Examination, Maternity Coverage, Accidental Death.</li>
                        {/* <li> Covers you for any unforeseen Emergency Medical, Physician & Hospitalization expenses, Diagnostics, Prescriptions and Dental etc.</li> */}
                    </ul>
                    </Box>
                    <Flex justifyContent={'center'} pb={'20px'}>
                    <Link to='/student-travel-getquote'>
                    <Button size={'sm'} _hover={{color:'white'}} backgroundColor={buttonBackgroundColor} color={buttonColor}>Buy Now</Button>
                    </Link>
                    </Flex>
                </Box>
            </Flex>
        </Box>
        </div>
    );
};
export default CustomerHomePage;