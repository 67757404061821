
import { ADVISOR_COMM, GET_ADVISOR_COMMISION_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"



export const AdvisorCommissionService = {
advsorComissionList() {
    return Api.GET(GET_ADVISOR_COMMISION_LIST).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
advsorComission() {
    return Api.GET(ADVISOR_COMM).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},

}