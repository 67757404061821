import React, { useEffect, useState } from 'react'
import SidebarServices from '../../SidebarServices'
import { Box, Button, Flex, Text, useTheme,Grid,GridItem,Heading,Image, Input, Textarea } from '@chakra-ui/react'
import { BsBagPlus } from "react-icons/bs";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { AiFillSlackCircle } from "react-icons/ai";   
import { AiFillFund } from "react-icons/ai";
import Hero from '../../../../assets/img/Hero.png'
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor, primaryColor } from '../../../../Utils/UniversalColor';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaFacebook, FaLinkedin, FaLocationDot, FaTwitter } from 'react-icons/fa6';
import { FaInstagramSquare, FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { HexColorPicker } from "react-colorful";





const CreateTheme = () => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const theme=useTheme()
    const [selectedSection, setSelectedSection] = useState('')
    const [showHeader,setShowHeader]=useState(false)
    const [showHero,setShowHero]=useState(false)
    const [showAbout,setShowAbout]=useState(false)
    const [showProducts,setShowProducts]=useState(false)
    const [formData, setFormData] = useState({
        logo: null,
        logoURL: '',
        heading: 'Here you can add your main heading',
        subHeading: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque quae culpa dolores reiciendis, nemo minima nostrum aliquam aliquid dolorum fuga ut iure sint ducimus accusantium odit obcaecati impedit? Natus quo minus ad.',
        aboutUs: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Itaque quae culpa dolores reiciendis, nemo minima nostrum aliquam aliquid dolorum fuga ut iure sint ducimus accusantium odit obcaecati impedit? Natus quo minus ad.',
        aboutUsHeading:'About TravelMedicare',
        product1Image:null,
        product1ImageURL:'',
        product1Heading:'Quick Quote',
        product1Desc:'customers trust us & have bought their insurance on TravelMedicare',
        product2Image:null,
        product2ImageURL:'Super visa',
        product2Heading:'Super Visa',
        product2Desc:'Super Visa Insurance: CAD 100,000 coverage for 1 year, for ages 15 days to 90.',
        product3Image:null,
        product3ImageURL:'',
        product3Heading:'Visitor Insurance',
        product3Desc:'Visitors Insurance: Up to 2-year coverage for ages 15 days to 90, with no cancellation fees',
        product4Image:null,
        product4ImageURL:'',
        product4Heading:'Student Insurance',
        product4Desc:'Students Medical Insurance: $495/year for $2M coverage (Standard plan) for ages 15 days to 65.',
        benefits1Logo:'',
        benefits1Heading:'The Plan Type ',
        benefits2Logo:'',
        benefits2Heading:'Coverage',
        benefits3Logo:'',
        benefits3Heading:'Age Limits',
        benefits4Logo:'',
        benefits4Heading:'Duration & Waiting period',
        benefits5Logo:'',
        benefits5Heading:'Pre-existing coverage',
        benefits6Logo:'',
        benefits6Heading:'Exclusions',
        benefitsSectionHeading:'Our Policies Are Based On',
        benefitsSectionDesc:'Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus maiores veritatis esse consequuntur inventore nisi perferendis corporis libero, consequatur laudantium qui dignissimos!',
        address:'7895 Tranmere Dr Unit #16 Suite 3 Mississauga ON L5S 1V9',
        phone:'1844-844-3272',
        mail:'support@travelmedicare.com',
        facebook:'',
        instagram:'',
        twitter:'',
        linkdin:'',
        primaryColor:'teal',
        secondaryColor:'teal',
        buttonBackgroundColor:'teal',
        buttonHoverBackgroundColor:'teal',
        buttonColor:'white',
        buttonHoverColor:'white'
      });
      


      const handleChange = (e) => {
        if (typeof e === 'string') {
            setFormData({
                ...formData,
                primaryColor: e
            });
        } else {
            const { name, type, files, value } = e.target;
            if (type === 'file') {
                const file = files[0];
                setFormData({
                    ...formData,
                    [name]: file,
                    logoURL: URL.createObjectURL(file)
                });
            } else {
                setFormData({
                    ...formData,
                    [name]: value
                });
            }
        }
    };

        const handleColorChange = (name, color) => {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: color
            }));
        };

    const handleSubmit=()=>{
        alert('Request Submitted.')
    }
    








    useEffect(() => {
        AOS.init();
      }, [])



return (
    <SidebarServices headingText={'Add Theme Details'}>
        <Box w={'95%'} m={'auto'} mt={'25px'}>

            <Flex justifyContent={'end'} gap={'30px'}>
                <Button onClick={()=>setSelectedSection('color')} _hover={{background:formData.buttonHoverBackgroundColor,color:formData.buttonHoverColor}} background={formData.buttonBackgroundColor} color={formData.buttonColor} mb={'10px'} size={'xs'}>Color Pallate</Button>
                <Button onClick={handleSubmit} _hover={{background:formData.buttonHoverBackgroundColor,color:formData.buttonHoverColor}} background={formData.buttonBackgroundColor} color={formData.buttonColor} mb={'10px'} size={'xs'}>Submit</Button>
            </Flex>

            <Flex justifyContent={'space-between'} gap={'40px'}>
            <Box w={'25%'} fontFamily={'Montserrat, sans-serif'} background={'white'} p={'20px'} borderRadius={'5px'} height={'calc(100vh - 90px)'} fontSize={'14px'} overflowY={'auto'}>
                { selectedSection === 'header' && (<Box>
                        <Text pb={'8px'}>Logo</Text>
                        <input type="file" name="logo" onChange={handleChange} />
                    </Box>)}

                {selectedSection === 'hero' && (
                    <Box>
                        <Text textAlign={'center'}>Hero Section</Text>
                        <hr />
                            <Text mt={'35px'}>Heading</Text>
                            <Textarea mt={'5px'} size={'sm'} name="heading" value={formData.heading} onChange={handleChange} />
                            <Text mt={'15px'}>Sub-heading</Text>
                            <Textarea mt={'5px'} size={'sm'} name="subHeading" value={formData.subHeading} onChange={handleChange} />
                    </Box>)}

                    {
                        selectedSection === 'about' && (
                            <Box>
                                <Text textAlign={'center'}>About Us Section</Text>
                                <hr />
                                <Text mt={'30px'}>Heading</Text>
                                <Input w={'90%'} m={'auto'} size={'sm'} name="aboutUsHeading" value={formData.aboutUsHeading} onChange={handleChange} />
                                <Text mt={'15px'}>Description</Text>
                                <Textarea w={'90%'} m={'auto'} size={'sm'} name="aboutUs" value={formData.aboutUs} onChange={handleChange} />
                            </Box>
                        )
                    }
                    {
                        selectedSection === 'products' && (
                            <>
                            <Text textAlign={'center'}>Products Section</Text>
                                <hr />
                                <Box mt={'15px'} p={'10px'}>
                                    <Text mt={'5px'}>Product 1</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='product1Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    <Textarea name='product1Desc' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Desc'/>
                                    </Box>
                                </Box>
                                <Box p={'10px'}>
                                    <Text>Product 2</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='product2Heading' size={'xs'} mt={'10px'} onChange={handleChange} placeholder='Product Name'/>
                                    <Textarea name='product2Desc' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Desc'/>
                                    </Box>
                                </Box>
                                <Box p={'10px'}>
                                    <Text>Product 3</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='product3Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    <Textarea name='product3Desc' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Desc'/>
                                    </Box>
                                </Box>
                                <Box p={'10px'}>
                                    <Text>Product 4</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='product4Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    <Textarea size={'xs'} mt={'10px'} placeholder='Product Desc' name='product4Desc' onChange={handleChange}/>
                                    </Box>
                                </Box>
                            </>
                            )
                        }
                 { selectedSection === 'benefits' && (<Box>
                    <Text textAlign={'center'}>Benefits Section</Text>
                        <hr />
                        <Box mt={'15px'} p={'10px'} pb={'0px'}>
                                    <Text mt={'5px'}>Box 1</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input size={'xs'} mt={'10px'} placeholder='Product Name' name='benefits1Heading' onChange={handleChange}/>
                                    </Box>
                        </Box>
                        <Box p={'10px'} pb={'0px'}>
                                    <Text>Box 2</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='benefits2Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    </Box>
                        </Box>
                        <Box p={'10px'} pb={'0px'}>
                                    <Text>Box 3</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='benefits3Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    </Box>
                        </Box>
                        <Box p={'10px'} pb={'0px'}>
                                    <Text>Box 4</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='benefits4Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    </Box>
                        </Box>
                        <Box p={'10px'} pb={'0px'}>
                                    <Text>Box 5</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='benefits5Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    </Box>
                        </Box>
                        <Box p={'10px'} pb={'0px'}>
                                    <Text>Box 6</Text>
                                    <hr />
                                    <Box justifyContent={'right'} direction={'column'} w={'90%'}>
                                    <input style={{marginTop:'10px',fontSize:'10px'}} size={'sm'} type="file" placeholder='product 1 image'/>
                                    <Input name='benefits6Heading' onChange={handleChange} size={'xs'} mt={'10px'} placeholder='Product Name'/>
                                    </Box>
                        </Box>
                    </Box>)} 

                    { selectedSection === 'benefitsSection' && (<Box>
                    <Text textAlign={'center'}>Benefits Section</Text>
                        <hr />
                        <Text mt={'35px'}>Heading</Text>
                            <Textarea mt={'5px'} size={'sm'} name="heading" value={formData.heading} onChange={handleChange} />
                            <Text mt={'15px'}>Sub-heading</Text>
                            <Textarea mt={'5px'} size={'sm'} name="subHeading" value={formData.subHeading} onChange={handleChange} />
                    </Box>)}

                    { selectedSection === 'Footer' && (<Box>
                    <Text textAlign={'center'}>Footer Section</Text>
                        <hr />
                        <Text mt={'15px'}>Address</Text>
                            <Textarea mt={'5px'} size={'sm'} name="address" value={formData.address} onChange={handleChange} />
                            <Text mt={'10px'}>Phone</Text>
                            <Input  mt={'5px'} size={'sm'} name="phone" value={formData.phone} onChange={handleChange} />
                            <Text mt={'10px'}>Mail</Text>
                            <Input mt={'5px'} size={'sm'} name="mail" value={formData.mail} onChange={handleChange} />
                            <Text mt={'15px'} textAlign={'center'}>Social Media</Text>
                            <Input mt={'5px'} size={'xs'} placeholder='facebook' name="facebook" value={formData.facebook} onChange={handleChange} />
                            <Input value={formData.instagram} mt={'5px'} size={'xs'} placeholder='instagram' name="instagram" />
                            <Input mt={'5px'} size={'xs'} name="twitter" value={formData.twitter} placeholder='twitter' onChange={handleChange} />
                            <Input mt={'5px'} size={'xs'} name="linkdin" value={formData.linkdin} onChange={handleChange} placeholder='linkdin' />
                            <hr />
                    </Box>)}

                    { selectedSection === 'color' && (<Box>
                    <Text textAlign={'center'}>Color pallate</Text>
                        <hr />
                        <Text mt={'15px'}>Primary Color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.primaryColor}
                         onChange={color => handleColorChange('primaryColor', color)} />
                         <Text mt={'15px'}>Secondary Color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.secondaryColor}
                         onChange={color => handleColorChange('secondaryColor', color)} />
                         <Text mt={'15px'}>Button background color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.buttonBackgroundColor}
                         onChange={color => handleColorChange('buttonBackgroundColor', color)}/>
                         <Text mt={'15px'}>Button Color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.buttonColor}
                         onChange={color => handleColorChange('buttonColor', color)} />
                         <Text mt={'15px'}>Button Hover Color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.buttonHoverColor}
                         onChange={color => handleColorChange('buttonHoverColor', color)}/>
                         <Text mt={'15px'}>Button Hover Background Color</Text>
                         <HexColorPicker style={{height:'100px',marginLeft:'30px'}} color={formData.buttonHoverBackgroundColor}
                         onChange={color => handleColorChange('buttonHoverBackgroundColor', color)}/>
                    </Box>)}
                 </Box>



                <Box w={'75%'} background={'white'} p={'30px'} borderRadius={'8px'} overflowY={'auto'} height={'calc(100vh - 90px)'}>
                <Box boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px">
                <Flex onClick={() => setSelectedSection('header')} _hover={{border:'1px solid blue'}} zIndex={"9999999"} background={"white"} p={"10px"} justifyContent={"space-between"} boxShadow="rgba(0, 0, 0, 0.24) 0px 3px 8px" alignItems={'center'}>
                    <Box w={"18%"}>
                        <Image
                            w={"80%"} h={'50px'}
                            src={formData.logoURL}
                        />
                    </Box>
                    <Flex
                        gap={"40px"}
                        fontWeight={"600"}
                        fontSize={"16px"}
                        fontFamily={"poppins"}
                        justifyContent={"center"}
                        mt={"6px"}
                    >
                        <Box>{t("Home")}</Box>
                        <Box>{t("Contact Us")}</Box>
                    </Flex>
                    <Flex gap={"40px"} alignItems={"center"}>
                        <Button
                            fontSize={"14px"}
                            _hover={{background:formData.buttonHoverBackgroundColor, color:formData.buttonHoverColor}}
                            color={formData.buttonColor}
                            backgroundColor={formData.buttonBackgroundColor}
                            size={'sm'}
                        >
                            {t("Advisor")}
                        </Button>
                        <Button
                            size={'sm'}
                            fontSize={"14px"}
                            _hover={{background:formData.buttonHoverBackgroundColor, color:formData.buttonHoverColor}}
                            color={formData.buttonColor}
                            backgroundColor={formData.buttonBackgroundColor}
                        >
                            {t("Customer Login")}
                        </Button>
                    </Flex>
                    </Flex>
                    <Box onClick={() => setSelectedSection('hero')}  cursor={'pointer'} _hover={{border:'1px solid blue'}} backgroundImage={'https://envato.bdevs.net/tourigo/wp-content/uploads/2024/05/landing-bg.png'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} h={'80vh'}>
                        <Box backgroundImage={'https://envato.bdevs.net/tourigo/wp-content/themes/tourigo/assets/images/shapes/banner-three-map.png'} h={'80vh'}>
                        <Flex direction={['column-reverse','column','row']} w={'90%'} m={'auto'} backgroundSize={'cover'} backgroundRepeat={'no-repeat'}> 
                        <Box w={['95%','40%','40%']} mt={['20px','120px','120px']}>
                            <Text color={'#2E3749'} fontWeight={'550'} fontSize={'28px'} fontFamily={'Spline Sans, sans-serif'}>{formData.heading}</Text>
                            <Text color={'#748289'} mt={'15px'} fontSize={'14px'}>{formData.subHeading}</Text>
                            <Button p={'10px 25px 10px 25px'} _hover={{background:formData.buttonHoverBackgroundColor, color:formData.buttonHoverColor}} color={formData.buttonColor}
                            backgroundColor={formData.buttonBackgroundColor} fontSize={'14px'} mt={'20px'} fontFamily={'Rubik, sans-serif'} fontWeight={'500'} rightIcon={<ArrowForwardIcon />}>Get Quick Quote</Button>
                        </Box>
                        <Flex justifyContent={'center'} w={['95%','40%','40%']} m={'auto'}>
                            <Image src={Hero}/>
                        </Flex>
                        </Flex>
                        </Box>
                    </Box>




                    <Flex direction={['column','column','row']} w={'80%'} m={'auto'} pb={'40px'} gap={'90px'} mt={'60px'}> 
                        <Box _hover={{border:'1px solid blue'}} onClick={() => setSelectedSection('about')}  w={['95%','40%','40%']} m={'auto'} mt={['90px','40px','40px']} fontFamily={'Spline Sans, sans-serif'}>
                            <Text pb={'10px'} fontWeight={'600'} color={'#2E3749'} fontFamily={'Spline Sans, sans-serif'} fontSize={'24px'}>{formData.aboutUsHeading}</Text>
                            <hr />
                            <Text pt={'10px'} fontSize={'14px'} color={'rgb(103 116 113)'}>{formData.aboutUs}</Text>
                            <Button p={'2px 10px'} fontSize={'12px'} _hover={{background:formData.buttonHoverBackgroundColor, color:formData.buttonHoverColor}} color={formData.buttonColor} backgroundColor={formData.buttonBackgroundColor} mt={'30px'} fontFamily={'Spline Sans, sans-serif'} fontWeight={'500'} rightIcon={<ArrowForwardIcon />}>Read More</Button>
                        </Box>
                        <Box _hover={{border:'1px solid blue'}} onClick={() => setSelectedSection('products')}  w={['97%','52%','60%']}>
                            <Flex justifyContent={'space-between'}>
                            <Box w={'50%'} p={['0px','10px','5px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'} gap={'20px'}>
                                <Flex _hover={{backgroundColor:formData.primaryColor}} background={formData.secondaryColor} w={'45px'} p={'10px'} borderRadius={'5px'}>
                                <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                                </Flex>
                                <Box fontFamily={'poppins'}>
                                <Text _hover={{textDecoration:'underline'}} pt={'10px'} fontSize={'14px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{formData.product1Heading}</Text>
                                <Text color={'#253858'} lineHeight={'21px'} fontSize={'12px'}>{formData.product1Desc}</Text>
                                </Box>
                            </Box>
                            <Box w={'50%'}  p={['0px','10px','5px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                                <Flex  _hover={{backgroundColor:formData.primaryColor}} background={formData.secondaryColor} w={'45px'} p={'10px'} borderRadius={'5px'}>
                                <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                                </Flex>
                                <Box fontFamily={'poppins'}>
                                <Text pt={'10px'} fontSize={'14px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{formData.product2Heading}</Text>
                                <Text color={'#253858'} lineHeight={'21px'} fontSize={'12px'}>{formData.product2Desc}</Text>
                                </Box>
                            </Box>
                            </Flex>
                            <Flex justifyContent={'space-between'} mt={'30px'}>
                            <Box w={'50%'} p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                                <Flex _hover={{backgroundColor:formData.primaryColor}} background={formData.secondaryColor} w={'45px'} p={'10px'} borderRadius={'5px'}>
                                <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                                </Flex>
                                <Box fontFamily={'poppins'}>
                                <Text pt={'10px'} fontSize={'14px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{formData.product3Heading}</Text>
                                <Text color={'#253858'} lineHeight={'21px'} fontSize={'12px'}>{formData.product3Desc}</Text>
                                </Box>
                            </Box>
                            <Box w={'50%'} p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                                <Flex _hover={{backgroundColor:formData.primaryColor}} background={formData.secondaryColor} w={'45px'} p={'10px'} borderRadius={'5px'}>
                                <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                                </Flex>
                                <Box fontFamily={'poppins'}>
                                <Text pt={'10px'} fontSize={'14px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{formData.product4Heading}</Text>
                                <Text color={'#253858'} lineHeight={'21px'} fontSize={'12px'}>{formData.product4Desc}</Text>
                                </Box>
                            </Box>
                            </Flex>
                        </Box>
                    </Flex>



                    <Box backgroundImage={'https://themedox.com/insucom/wp-content/uploads/2023/08/service-area.png'} h={'auto'} mt={'50px'} backgroundSize={'cover'} backgroundRepeat={'no-repeat'} p={'15px'} pt={'70px'} pb={'70px'}>
                        <Flex direction={['column-reverse','column-reverse','row']} w={['99%','85%','95%']} m={'auto'} gap={'90px'}>
                            <Box w={['97%','43%','60%']} _hover={{border:'1px solid blue'}} onClick={()=>setSelectedSection('benefits')}>
                                <Grid templateColumns='repeat(3, 1fr)' templateRows='repeat(2, 1fr)' gap={5}>
                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#E1F8E5'} borderRadius={'50px'} p={'15px'}>
                                                <BsBagPlus fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'} fontWeight={'700'} lineHeight={'24px'}>{formData.benefits1Heading}</Text>
                                    </GridItem>
                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#DAF6FF'} borderRadius={'50px'} p={'15px'}>
                                                <AiFillFund fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{formData.benefits2Heading}</Text>
                                    </GridItem>
                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#E5DEFF'} borderRadius={'50px'} p={'15px'}>
                                                <BsBagPlus fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{formData.benefits3Heading}</Text>
                                    </GridItem>

                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#FFE9CF'} borderRadius={'50px'} p={'15px'}>
                                                <BsBagPlus fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'} fontWeight={'700'} lineHeight={'24px'}>{formData.benefits4Heading}</Text>
                                    </GridItem>
                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#FFDAEC'} borderRadius={'50px'} p={'15px'}>
                                                <BsBagPlus fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{formData.benefits5Heading}</Text>
                                    </GridItem>
                                    <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                                            <Flex justifyContent={'center'} mb={'20px'}>
                                                <Box background={'#FFF0E4'} borderRadius={'50px'} p={'15px'}>
                                                <BsBagPlus fontSize={'16px'} />
                                                </Box>
                                            </Flex>
                                            <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{formData.benefits6Heading}</Text>
                                    </GridItem>
                                </Grid>
                            </Box>
                            <Box w={['95%','40%','40%']} _hover={{border:'1px solid blue'}} onClick={()=>setSelectedSection('benefitsSection')} >
                                <Box>
                                    <Flex boxShadow='rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset' color={primaryColor} width={'fit-content'} p={' 5px 15px'} alignItems={'center'} fontSize={'13px'} fontWeight={'700'} background={'white'} justifyContent={'start'} gap={'7px'} borderRadius={'12px'}>
                                    <AiFillSlackCircle />
                                        <Text fontFamily={'Open Sans,sans-serif'} fontSize={'12px'}>OUR BENIFITS</Text>
                                    </Flex>
                                </Box>
                            <Text mt={'10px'} pb={'10px'} fontWeight={'600'} color={primaryColor} fontFamily={'Spline Sans, sans-serif'} fontSize={'22px'}>{formData.benefitsSectionHeading}</Text>
                            <Text pt={'10px'} fontSize={'14px'} color={'rgb(103 116 113)'}>{formData.benefitsSectionDesc}</Text>
                            <Button fontSize={'12px'} p={'2px 10px'} rightIcon={<ArrowForwardIcon />} _hover={{background:formData.buttonHoverBackgroundColor, color:formData.buttonHoverColor}} color={formData.buttonColor} backgroundColor={formData.buttonBackgroundColor}fontFamily={'Open Sans, sans-serif'} fontWeight={'500'} mt={'30px'} >Read More</Button>
                            </Box>
                        </Flex>
                    </Box>



                <Box>
                    <Box w={['89%','49%','49%']} m={'auto'} pt={'90px'} textAlign={'center'} lineHeight={'28px'}>
                    <Heading fontSize={['22px','32px','32px']} fontFamily={'Montserrat'}>{t('Our Policies Are Based On')}</Heading>
                    <Text pt={'15px'} fontSize={'16px'} fontFamily={'poppins'}>{t('Premiums for All')}</Text>
                    </Box>
                <Flex  direction={['column','column','row']} w={'80%'} m={'auto'} pt={'60px'} pb={'30px'} justifyContent={'space-around'} gap={['20px','20px','0px']}>
                <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://www.freeiconspng.com/uploads/leadership-training-icon-1.png'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('The Plan Type')}</Text>  
                    <Box fontFamily={'Assistant,sans-serif'}>
                    <Text pt={'5px'}>{t('Standard')} = $25000, $50000, $100000</Text>
                    <Text pt={'5px'}>{t('Enhanced')} = $25000, $50000, $100000</Text>
                    <Text pt={'5px'}>{t('Premium')} = $25000, $50000, $100000</Text>
                    </Box>
                </Box>
                <Box justifyContent={'center'} textAlign={'center'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://img.freepik.com/free-vector/umbrella-with-circle-check-mark_78370-4180.jpg?t=st=1711446036~exp=1711449636~hmac=d4d0e7ed926b69d9c717bd77d27ca87964b03a2ccac6827236758cf1c0dc9a25&w=740'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Coverage')}</Text>  
                    <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
                    <Text pt={'5px'}>{t('Hospitalization, Physician Charges,')}</Text>
                    <Text pt={'5px'}>{t('Diagnostic Services, Prescriptions')}</Text>
                    <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
                    </Box>
                </Box>
                <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://www.shutterstock.com/image-vector/age-vector-icon-600nw-304741742.jpg'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Age Limits')}</Text>  
                    <Box fontFamily={'Assistant,sans-serif'}>
                    <Text pt={'5px'}>{t('Standard')} = 15 Days to 90 Yrs</Text>
                    <Text pt={'5px'}>{t('Enhanced')} = 15 Days to 90 Yrs</Text>
                    <Text pt={'5px'}>{t('Premium')} = Less then 70 Years of age</Text>
                    </Box>
                </Box>
                </Flex>

                <Flex direction={['column','column','row']} w={'80%'} m={'auto'} pt={'10px'} pb={'100px'} justifyContent={'space-around'} gap={['20px','20px','0px']}>
                <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://img.freepik.com/premium-photo/photo-icons-alarm-clock-icon-green-technology-texture_873925-783472.jpg?w=740'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Duration & waiting period')}</Text>  
                    <Box fontFamily={'Assistant,sans-serif'}>
                    <Text pt={'5px'}>Within 30 days : 48 hours</Text>
                    <Text pt={'5px'}>After 30 days : 48 hours</Text>
                    <Text pt={'5px'}>365 days & Max 2 consecutive years</Text>
                    </Box>
                </Box>
                <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://img.freepik.com/free-vector/check-mark-shield-circle_78370-4405.jpg?t=st=1711446282~exp=1711449882~hmac=75b7dff6a5abf3f247e6002c5658120fcc2831716551fb1b34a5ce33d8c60c6d&w=740'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Pre-Existing Coverage')}</Text>  
                    <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
                    <Text pt={'5px'}>Ages 0-74 = 90 days stability is available till age</Text>
                    <Text pt={'5px'}>Ages 75-84 = Pre-existing conditions are excluded in Plan 1</Text>
                    <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
                    </Box>
                </Box>
                <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
                    <Flex justifyContent={'center'}>
                        <Image w={'50px'} src='https://img.freepik.com/premium-vector/av-green-letters-black-round-av-brand-name-initial-letter-icon_302321-318.jpg?w=740'/>
                    </Flex>
                    <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>Exclusions</Text>  
                    <Box fontFamily={'Assistant,sans-serif'}>
                    <Text pt={'5px'}>{t('Standard')} = 15 Days to 90 Yrs</Text>
                    <Text pt={'5px'}>{t('Enhanced')} = 15 Days to 90 Yrs</Text>
                    <Text pt={'5px'}>{t('Premium')} = Less then 70 Years of age</Text>
                    </Box>
                </Box>
                </Flex>
                </Box>
                
                <Box cursor={'pointer'} _hover={{border:'1px solid blue'}} onClick={()=>setSelectedSection('Footer')} backgroundColor={formData.primaryColor} pt={'30px'} pb={'15px'} color={'white'}>
                    <Flex direction={['column','column','row']} w={'95%'} m={'auto'} pb={'30px'}>
                    <Box w={['99%','30%','30%']} m={'auto'} fontSize={'12px'} borderRight={'1px solid red'}>
                        <Text fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>{t('Contact')} </Text>
                        <Box>
                        <Flex p={'7px'} fontSize={'12px'} gap={'15px'}>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'10px'} fontSize={'20px'}>
                            <FaLocationDot />
                            </Flex>
                            <Text alignSelf={'center'}>{formData.address}</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'10px'} fontSize={'20px'}>
                            <FaPhoneAlt />
                            </Flex>
                            <Text alignSelf={'center'}>{formData.phone}</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Flex justifyContent={'center'} alignItems={'center'} width={'10px'} fontSize={'20px'}>
                            <MdOutlineMail />
                            </Flex>
                            <Text alignSelf={'center'}>{formData.mail}</Text>
                        </Flex>
                        </Box>
                    </Box>

                    <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'} borderRight={'1px solid red'}>
                        <Text fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>Discover TM</Text>
                        <Box fontSize={'12px'}>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>About Us</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Enquiry</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Privacy Policy</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Terms & Condition</Text>
                        </Flex>
                        </Box>
                    </Box>

                    <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'} borderRight={'1px solid red'}>
                        <Text fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>Other</Text>
                        <Box fontSize={'12px'}>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>News & Blogs</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Claims & Refund</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Become a Partner</Text>
                        </Flex>
                        <Flex p={'7px'} gap={'15px'}>
                            <Text alignSelf={'center'}>Affiliate Program</Text>
                        </Flex>
                        </Box>
                    </Box>


                    <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'}>
                        <Text fontFamily={'Assistant,sans-serif'} pb={'10px'} fontSize={'14px'}>Social Media</Text>
                        <Flex justifyContent={'space-around'} w={'70%'} fontSize={'14px'} pb={'15px'}>
                        <a href={formData.facebook} target='_blank'>
                        <FaFacebook />
                        </a>
                        <a href={formData.instagram} target='_blank'>
                            <FaInstagramSquare />
                        </a>
                        <a href={formData.twitter} target='_blank'>
                        <FaTwitter /></a>
                        <a href={formData.linkdin} target='_blank'>
                        <FaLinkedin /></a>
                        </Flex>
                        <Flex>
                        <Image w={'50px'} h={'20%'} src='https://verify.authorize.net/anetseal/images/secure90x72.gif' />
                        </Flex>
                    </Box>
                    </Flex>
                    <Box borderTop={'1px solid white'}>
                    <Text fontSize={'12px'} pt={'5px'} pl={'20px'} fontFamily={'Assistant'}>Copyright  2019 Travel medicare. All Rights Reserved</Text>
                    </Box>
                </Box>

                </Box>
                </Box>


            </Flex>
        </Box>
    </SidebarServices>
  )
}

export default CreateTheme
