import { PAGES_DETAIL, PAGES_DETAILS_ALL, PAGES_DETAILS_EDIT, isLoading } from "../../../../constants/actionConstants";
import { AdminPagesService } from "./service";

import { toast } from "react-toastify";

export const adminPagesEdit = (id,params) => async (dispatch) => {
    dispatch(isLoading(true));
    let pagesList = await AdminPagesService.adminPagesEdit(id,params);
    const {status, message, data} = pagesList

    if(status === 200) {
        dispatch({
            type: PAGES_DETAILS_EDIT,
            payload: data
        })
        toast.success("Page sucessfully updated")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}



export const adminPagesAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminPagesService.adminPagesAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: PAGES_DETAILS_ALL,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };


  export const adminPagesById = (id,params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getPages = await AdminPagesService.adminPagesById(id, params); 
    const { status, message, data } = getPages;

    dispatch({
        type: PAGES_DETAIL,
        payload: { message, data }
    });

    dispatch(isLoading(false));

    return getPages;
}