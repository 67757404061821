import Home from "../Components/Home";
import Home2 from "../Components/Home/Home2";




const component ='Theme2'



export const getHomepageComponent = (component) => {
  switch (component) {
    case 'Theme1':
      return <Home />;
    case 'Theme2':
      return <Home2 />;
    case 'Theme3':
      return <Home3 />;
    case 'Theme4':
      return <Home4 />;
    default:
      return <Home2 />; 
  }
};

const brands = {
    logo: "/logos/logo1.png",
    homepageComponent: getHomepageComponent(component),
    theme:{
      colors: {
        primary: "#285E61",
        secondary: "#C7B198", 
      },
      fontFamily: {
        heading: "Open Sans, sans-serif",
        body: "Roboto, serif",
      },
      button:{
        buttonBackgroundColor : '#0B3948',
        buttonColor:'white',
        buttonHoverBackgroundColor:'#FFD470',
        buttonHoverColor:'black'
      }
    },
    content: {
      homepage: {
        title: "Get the right protection to keep moving forward.",
        description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo recusandae quis ducimus? Illo, itaque sunt pariatur culpa saepe ab corporis. Magni, harum!",
        about:'With an utmost honest approach, we have developed ourselves with integrity as our primary principle, to provide the best experience to all our customers and to provide products and services which our clients can purchase and recommend with confidence.'
      },
      aboutUs: {
        title: "About Brand One",
        description: "Here's a little story about Brand One.",
      },
    },
};

export default brands;
