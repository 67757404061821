
import { AdvisorCommissionService } from "./service";
import { ADVISOR_COMM_TYPE, GET_ADVISOR_COMMISION_LIST_TYPE, isLoading } from "../../../../constants/actionConstants";



export const advsorComissionList = (params) => async (dispatch) => {
 
    dispatch(isLoading(true));
    try {
      const response = await AdvisorCommissionService.advsorComissionList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: GET_ADVISOR_COMMISION_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };


  export const advsorComission = (params) => async (dispatch) => {
 
    dispatch(isLoading(true));
    try {
      const response = await AdvisorCommissionService.advsorComission(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ADVISOR_COMM_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };