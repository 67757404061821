import { Box,Button,Flex,Text } from '@chakra-ui/react'
import React from 'react'
import { FaDownload } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import { DOWNLOAD_POLICY_DOC, DOWNLOAD_POLICY_DOCUMENT, DOWNLOAD_WALLET_CARD } from '../../../../constants/ApplicationUrl';

const CustomerPolicyDocuments = () => {
    const {token}=JSON.parse(localStorage.getItem('code_dev'))
    const navigate=useNavigate()
    const params=useParams()
    const {id}=params;

    
const handleBack = () => {
    navigate(-1);
    }


const handlePolicyDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOCUMENT}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'Policy.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};


const handleWalletCardDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_WALLET_CARD}/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'WalletCard.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};

const handlePolicyDocDownload = async () => {
    try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOC}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'PolicyDoc.pdf');
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
};



return (
    <Box background={'grey'} height={'100vh'}>
    <Box w={['90%','60%','60%']} m={'auto'} pt={'120px'}>
      <Box borderRadius={'5px'} background='white' p={'20px'} boxShadow='rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;'>
            <Button size={'sm'} background={'teal'} color='white' mt={'50px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Box w={'70%'} m={'auto'}>
            <Text mt={'30px'} mb={'30px'} color={'grey'} fontSize={['16px','22px','22px']} fontFamily={'Open Sans,sans-serif'}>Download document for Policy : 2024PTM351215</Text>
            <Flex direction={['column','column','row']} w={'90%'} m={'auto'} mt={'50px'} mb={'70px'} gap={'40px'}>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy</Text>
                    <Flex justifyContent={['start','center','center']} mt={'10px'} color={'teal'}>
                    <FaDownload onClick={handlePolicyDownload} cursor={'pointer'}/>
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Wallet Card</Text>
                    <Flex justifyContent={['start','center','center']} mt={'10px'} color={'teal'}>
                    <FaDownload cursor={'pointer'} onClick={handleWalletCardDownload} />
                    </Flex>
                </Box>
                <Box>
                    <Text fontSize={'16px'} fontFamily={'poppins'} >Policy Doc</Text>
                    <Flex justifyContent={['start','center','center']} mt={'10px'} color={'teal'}>
                    <FaDownload cursor={'pointer'} onClick={handlePolicyDocDownload}/>
                    </Flex>
                </Box>
            </Flex>
            </Box>
        </Box>
    </Box>
    </Box>
  )
}

export default CustomerPolicyDocuments
