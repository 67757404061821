import React, { useState, useEffect, useRef } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button, Tooltip, Input, theme, ChakraProvider, FormControl, FormLabel } from '@chakra-ui/react';
import { useDispatch } from "react-redux";
import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css";
import { buttonBackgroundColor, buttonColor, primaryColor } from '../../../Utils/UniversalColor';
import { FaDownload } from "react-icons/fa6";
import Api from '../../../dependencies/utils/Api';
import { Link, useNavigate } from 'react-router-dom';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa6";
import { BsFiletypeCsv } from "react-icons/bs";
import CustomInput from '../../../Utils/Input/Index';
import { GET_POLICY_BY_USER, SEND_POLICY } from '../../../constants/ApplicationUrl';
import { sendPolicy } from '../../../Utils/Emails';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const AdvisorMyPolicy = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (gridRef.current && gridRef.current.api) {
        if (window.innerWidth > 768) {
          gridRef.current.api.sizeColumnsToFit();
        } else {
          gridRef.current.api.resetColumnState();
        }
      }
    };

    // Initial column fitting based on screen size
    if (gridRef.current && gridRef.current.api) {
      handleResize();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const downloadCellRenderer = (params) => {
    const { data } = params;
    const handleDownload = () => {
      navigate(`/advisor-policy-documents/${data.policyNo}`);
      console.log('Download clicked for row:', data);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <FaDownload color={buttonBackgroundColor} onClick={handleDownload} style={{ cursor: 'pointer' }} />
      </div>
    );
  };

  const handleAction = (params) => {
    const { data } = params;
    const handleChange = (e) => {
      if (e.target.value === 'endorsement') {
        navigate(`/policy-endorsement/${data.id}`);
      }
      if (e.target.value === 'view') {
        navigate(`/single-policy-view/${data.id}`);
      }
      if (e.target.value === 'send') {
        sendPolicy(data.policyNo);
        alert('Policy emailed successfully.');
      }
      if (e.target.value === 're-issue') {
        console.log(data, 'dda');
        navigate(`/re-issue-policy/${data.quotationNo}`);
      }
      console.log('Download clicked for row:', params.data);
    };
    return (
      <Tooltip hasArrow label='You can view, send ,edit and cancel quote here.' bg='gray.300' color='black' placement='right-start' w={'180px'}>
        <Select onChange={(e) => handleChange(e)} size={'xs'} style={{ backgroundColor: '#96ffff', border: '1px solid black' }}>
          <option value="">Select</option>
          <option value="view">View</option>
          <option value="send">Email policy</option>
          {data.status === 'Active' && <option value="endorsement">Endorsement</option>}
          <option value="re-issue">Re-issue</option>
        </Select>
      </Tooltip>
    );
  };

  const [colDefs, setColDefs] = useState([
    { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280, filter: true },
    { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, filter: true },
    { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150, filter: true },
    { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300, filter: true },
    { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220, filter: true },
    { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300, filter: true },
    { field: "tripType", wrapHeaderText: true, width: 175, cellClass: 'cell-class-name' },
    { field: "policyAmount", wrapHeaderText: true, width: 175, cellClass: 'cell-class-name' },
    { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name', filter: true },
    { field: "download", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: downloadCellRenderer, width: 140, wrapHeaderText: true },
    { field: "Action", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: handleAction, width: 250 },
  ]);

  const [rowData, setRowData] = useState([]);

  const actionCellRenderer = (params) => {
    const handleActionChange = (action) => {
      console.log('Selected Action:', action);
    };

    return (
      <select onChange={(e) => handleActionChange(e.target.value)}>
        <option value="view">View Quote</option>
        <option value="view">Send Quote</option>
        <option value="edit">Edit Quote</option>
        <option value="editTrip">Edit Trip Details</option>
        <option value="cancel">Cancel Quote</option>
      </select>
    );
  };

  useEffect(() => {
    getAllAdvisorPolicies();
  }, []);

  const getAllAdvisorPolicies = () => {
    Api.GET(`${GET_POLICY_BY_USER}`).then((res) => {
      console.log(res, 'data');
      if (res.status === 200) {
        const formattedData = res.data.map((quote, index) => {
          let status;
          switch (quote.status) {
            case 1:
              status = 'Active';
              break;
            case 2:
              status = 'Pending Cancellation';
              break;
            case 3:
              status = 'Cancelled';
              break;
            case 4:
              status = 'Pending Void';
              break;
            case 5:
              status = 'Void';
              break;
            case 6:
              status = 'Policy Change Pending';
              break;
            case 7:
              status = 'Matured';
              break;
            default:
              status = 'Inactive';
          }

          let tripType;
          if (quote.quote.product === 'Vistors To Canada') {
            tripType = 'Visitor To Canada';
          } else if (quote.quote.product === 'Student To Canada') {
            tripType = 'Student To Canada';
          } else if (quote.quote.superVisa === 1) {
            tripType = quote.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
          } else {
            tripType = 'Single Trip';
          }

          return {
            id: quote.idPolicy,
            employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
            designation: quote.quote.user.roles.name,
            product: quote.quote.product === 'Studentvisa' ? 'STC' : 'VTC',
            policyNo: quote.policyNo,
            dateOfIssue: quote.quote.createdAt,
            customerName: quote.quote.policyHolderName,
            tripType: tripType,
            policyAmount: `${quote.quote.quoteAmount} $`,
            status: status,
            quotationNo: quote.quote.quotationNo,
          };
        });
        setRowData(formattedData);
      }
    });
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Employee Name", "Designation", "Product", "Policy No", "Date of Issue", "Customer Name", "Trip Type", "Policy Amount", "Status"];
    const tableRows = [];

    rowData.forEach(policy => {
      const policyData = [
        policy.employeeName,
        policy.designation,
        policy.product,
        policy.policyNo,
        policy.dateOfIssue,
        policy.customerName,
        policy.tripType,
        policy.policyAmount,
        policy.status
      ];
      tableRows.push(policyData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
    });

    doc.save('quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(rowData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(rowData[0]).join(','), // Header row
      ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filterData = (data, searchTerm) => {
    if (!searchTerm) return data;
    return data.filter(item =>
      Object.values(item).some(val =>
        String(val).toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  };

  const filteredData = filterData(rowData, searchTerm);

  return (
    <Box background={'#f6f6f6'} h={'auto'} minHeight={'100vh'}>
      <Box w={['90%', '90%', '80%']} m={'auto'} style={{ paddingTop: '80px', paddingBottom: '90px' }}>
        <h3 style={{ color: primaryColor, fontSize: '22px', fontFamily: 'poppins', paddingBottom: '20px' }}>My Policy</h3>

        <Flex direction={['column', 'column', 'row']} justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
            <Flex direction={['column', 'column', 'row']} gap={'10px'}>
              <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <Button size={'sm'} leftIcon={<FaRegFilePdf />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</Button></Tooltip>
              <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <Button leftIcon={<SiMicrosoftexcel />} size={'sm'} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</Button></Tooltip>
            </Flex>
            <Flex direction={['column', 'column', 'row']} gap={'10px'}>
              {/* <Tooltip hasArrow label='Download sheet csv' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <Button size={'sm'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }} onClick={downloadCSV}> CSV</Button></Tooltip> */}
              <Tooltip hasArrow label='Customer Renewal Polices' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <Link to='/advisor-renewal-policies'>
                  <Button size={'sm'} leftIcon={<BsFiletypeCsv />} style={{ color: buttonColor, backgroundColor: buttonBackgroundColor, paddingLeft: '20px', paddingRight: '20px', paddingTop: '7px', paddingBottom: '7px', border: '1px solid black' }}>Renewal</Button>
                </Link>
              </Tooltip>
            </Flex>
          </div>
          <Box mb={['15px', 0, 0]}>
          <ChakraProvider theme={theme}>
  <FormControl variant="floating" id="search">
  {/* <FormLabel size={'sm'}>Search</FormLabel> */}
    <Input
      borderRadius={5}
      size={'sm'}
      background={'white'}
      value={searchTerm}
      placeholder='search...'
      onChange={(e) => setSearchTerm(e.target.value)} // Call handleChange function on input change
    />
   
  </FormControl>
</ChakraProvider>
            {/* <Input
              type="text"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
            /> */}
          </Box>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={filteredData}
            columnDefs={colDefs}
            frameworkComponents={{ actionCellRenderer }}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            enableClipboard={true}
            enableRangeSelection={true}
            rowSelection="single"
            onGridReady={(params) => {
              if (window.innerWidth > 768) {
                params.api.sizeColumnsToFit();
              }
            }}
          />
        </div>
      </Box>
    </Box>
  );
};

export default AdvisorMyPolicy;
