import React, { useState } from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import jsPDF from 'jspdf';


const SubscriptionDue = () => {
    const pagination = true;
    const paginationPageSizeSelector = [2, 5, 1000];
    const [colDefs, setColDefs] = useState([
        { field: "employeeName",wrapHeaderText: true,autoHeaderHeight:true,headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:280},
        { field: "designation", wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true },
        { field: "policyNo",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:330},
        { field: "customerName",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class-name',autoHeight:true,wrapText:true,width:300},
        { field: "subscriptionID",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:150},
        { field: "installmentDate",wrapHeaderText: true,headerClass:'header',headerClass:'header',cellClass: 'cell-class',autoHeight:true,wrapText:true,width:220 },
        { field: "autoDebit",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "transcationID",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "transcationResponse",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "amount",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
        { field: "Action",wrapHeaderText: true,headerClass:'header',width:175,cellClass: 'cell-class-name' },
      ]);
  
        const [rowData, setRowData] = useState([
          {
            id: 20,
            employeeName: "-",
            designation: "-",
            product: "VTC",
            policyNo: "2022PTM21380",
            dateOfIssue: "21-Feb-2022",
            customerName: "Aniket Anil Sawant",
            customerEmail: "aniket.sawant1095@gmail.com",
            customerPhno: "2265030450",
            tripType: "Single Trip",
            policyAmount: "$64",
            status: "Matured",
            docDownload: "View",
            endorsements: "View  Declined List",
            paymentTransactions: "View"
          },
          {
            id: 63,
            employeeName: "-",
            designation: "-",
            product: "VTC",
            policyNo: "2024PTM351215",
            dateOfIssue: "24-Jan-2024",
            customerName: "Aniket Sawant",
            customerEmail: "aniket.sawant1095@gmail.com",
            customerPhno: "2265030450",
            tripType: "Single Trip",
            policyAmount: "$155",
            status: "Matured",
            docDownload: "View",
            endorsements: "View  Declined List",
            paymentTransactions: "View"
          }
        ]
        );

        const downloadPDF = () => {
          const doc = new jsPDF();
          const tableColumns = Object.keys(rowData[0]).map(column => column.charAt(0).toUpperCase() + column.slice(1));
          const tableRows = rowData.map(row => Object.values(row));
      
          doc.autoTable(tableColumns, tableRows, { startY: 10 });
          doc.save('subscription_due.pdf');
      };
  
      const downloadExcel = () => {
          const worksheet = XLSX.utils.json_to_sheet(rowData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Subscription Due');
          XLSX.writeFile(workbook, 'subscription_due.xlsx');
      };
  
      const downloadCSV = () => {
          const csvContent = [
              Object.keys(rowData[0]).join(','), // Header row
              ...rowData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
          ].join('\n');
  
          const blob = new Blob([csvContent], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'subscription_due.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      };


return (
    <Sidebar headingText={'Subscription Dues'}>
     <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color:'#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select">
              <option value="">Select Roles</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
        </Flex>
        </div>

        <Box w={'90%'} m={'auto'} mt={'60px'}>
        <Tabs>
                <TabList>
                    <Tab _selected={{ background:'white' }}>Pending For Verification</Tab>
                    <Tab _selected={{ background:'white' }}>Verified payments</Tab>
                </TabList>
                <TabPanels background={'white'}>
                    <TabPanel>
                    <Flex justifyContent={'space-between'} mt={'15px'}>
                    <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                    </div>
                    <div>
                        <label htmlFor="search">Search:</label>
                        <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                    </div>
                    </Flex>


                    <div
                        className="ag-theme-quartz"
                        style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
                        >
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            domLayout='autoHeight'
                            width='100%'
                            paginationPageSizeSelector={paginationPageSizeSelector}
                            pagination={pagination}
                            onGridReady={(params) => {
                            params.api.sizeColumnsToFit();
                            }}
                        />
                    </div>



                    </TabPanel>
                    <TabPanel>
                    <Flex justifyContent={'space-between'} mt={'15px'}>
                    <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
                        <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
                    </div>
                    <div>
                        <label htmlFor="search">Search:</label>
                        <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }} type="text" id="search" placeholder="Search by name" />
                    </div>
                    </Flex>


                    <div
                        className="ag-theme-quartz"
                        style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
                        >
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={colDefs}
                            domLayout='autoHeight'
                            width='100%'
                            paginationPageSizeSelector={paginationPageSizeSelector}
                            pagination={pagination}
                            onGridReady={(params) => {
                            params.api.sizeColumnsToFit();
                            }}
                        />
                    </div>
                    </TabPanel>

                </TabPanels>
                </Tabs>
        </Box>



    </Sidebar>
  )
}

export default SubscriptionDue
