import { Box, Button, Flex, Grid, GridItem, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import Api from '../../../../dependencies/utils/Api';
import { buttonBackgroundColor, buttonColor, buttonHoverBackground, buttonHoverColor, primaryBackgroundColor } from '../../../../Utils/UniversalColor';
import { GET_POLICY_BY_POLICY_ID } from '../../../../constants/ApplicationUrl';
import { sendPolicy } from '../../../../Utils/Emails';
import { advisorClaimReport } from '../../Dependencies/action';
import { advisorGetPolicyDetails } from '../../Dependencies/action';
import { useDispatch } from 'react-redux';
import { FaDownload } from 'react-icons/fa';
import { RefundAttachementModel } from '../../../Admin/Policies/AdminAllPolicies/PolicyEndorsements/Models';
import { formatDate } from '../../../../Utils/DateFormate';


const AdviosrSinglePolicyView = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const params = useParams()
    const navigate=useNavigate()
    const [policyData,setPolicyData]=useState([])   
    const [formValid, setFormValid] = useState(false);

      const [formData, setFormData] = useState({
        policyHolderAddress: '',
        phone: '',
        incidentDate: '',
        illnessDetails: ''
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    
        // Check if all required fields are filled
        if (
            formData.policyHolderAddress !== '' &&
            formData.phone !== '' &&
            formData.incidentDate !== '' &&
            formData.illnessDetails !== ''
        ) {
            setFormValid(true);
        } else {
            setFormValid(false);
        }
    };
    
    const [correction,setCorrection]=useState([])
    const [voids,setVoids]=useState([])
    const [cancellation,setCancellation]=useState([])
    const [refund,setRefund]=useState([])
    const [isRefundAttachementModelOpen, setIsRefundAttachementModelOpen] = useState({ isOpen: false, refundItem: null });
    const openRefundModalWithItem = (item) => {
        setIsRefundAttachementModelOpen({ isOpen: true, refundItem: item });
    };
      const {id} = params
      const dispatch=useDispatch()
      console.log(voids,'void')
      console.log(policyData,'policy data')
      console.log(cancellation,'cancellation')
      console.log(refund,'refund')
      console.log(correction,'correction')

// useEffect(()=>{
//   getPolicyDetails()
// },[])

useEffect(()=>{
    dispatch(advisorGetPolicyDetails(id)).then((res)=>{
        const array=res.data.endorsementEntity
        const filteredData = array.filter(item => item.endorsementType === 'Correction');
            setCorrection(filteredData)
        const voidData= array.filter(item=>item.endorsementType === "Void")
            setVoids(voidData)
        const cancellationData = array.filter(item=>item.endorsementType === "Cancel")
            setCancellation(cancellationData)
        const refundData=array.filter(item=>item.endorsementType==='Refund')
            setRefund(refundData)
        console.log(filteredData,'data filterd')
        setPolicyData([res.data])
    })
},[dispatch,id])

// const getPolicyDetails = () =>{
//   Api.GET(`${GET_POLICY_BY_POLICY_ID}/${id}`).then((res)=>{
//     console.log(res,'res')
//     setPolicyData([res.data])
//   })
// }


      const handleBack = () => {
        navigate(-1);
      }

      const sendEmail=()=>{
        sendPolicy(policyData[0].policyNo)
        alert('Policy emailed successfully.');
    }

    const handleSubmit = () => {
        if (!formValid) {
            alert('Please fill in all required fields.');
            return;
        }
    
        const payload = {
            id: id,
            policyId: policyData.idPolicy,
            requestDate: new Date().toISOString(),
            userId: policyData.userId,
            policyNo: policyData.policyNo,
            insuredId: policyData.user ? policyData.user.idUser : 0,
            policyHolderAddress: formData.policyHolderAddress,
            phone: formData.phone,
            incidentDate: formData.incidentDate,
            illnessDetails: formData.illnessDetails
        };
        dispatch(advisorClaimReport(payload));
    };
    

return (
    <>
    <Box pt={'90px'}>
      <Flex w={['90%','70%','70%']} m={'auto'} textAlign='right' justifyContent={'space-between'}> 
         <Flex color={'teal'} onClick={handleBack}><IoMdArrowRoundBack  fontSize={'26px'}/></Flex>
         {policyData && policyData.map((ele)=>( 
        <Box fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>
            <Text fontWeight={'600'}>Advisor Details:</Text>
            <Text color={'blue'}>{ele.quote.user.firstName} {ele.quote.user.lastName}</Text>
            <Text>{ele.quote.user.phone}</Text>
            <Text>{ele.quote.user.email}</Text>
            <Text>{ele.quote.user.address}</Text>
        </Box>
                      ))}
      </Flex>
      <Box w={['90%','70%','70%']} m={'auto'}>
      <Tabs size='md' variant='enclosed'>
            <TabList>
                <Tab fontSize={['12px','12px','18px']}>Summary</Tab>
                <Tab fontSize={['12px','12px','18px']}>Transcations</Tab>
                <Tab fontSize={['12px','12px','18px']}> Payment Transcations</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <Flex justifyContent={'space-between'}>
                      <Button onClick={sendEmail} size={'xs'} _hover={{color:'white'}} background={buttonBackgroundColor} color={buttonColor}>Email Policy</Button>
                      <Button onClick={onOpen} size={'xs'} _hover={{color:'white'}} background={buttonBackgroundColor} color={buttonColor}>Report Claim</Button>
                    </Flex>
                    {
                      policyData && policyData.map((ele)=>(   
                  <Box>
                  <Box overflowX={['auto','auto','unset']} whiteSpace={['nowrap','nowrap','normal']} border={'1px solid grey'}>
                    <Box background={primaryBackgroundColor} p={'7px'} textAlign={'center'}>
                      <Text color={'white'} fontFamily={'Open Sans,sans-serif'}>POLICY SUMMARY</Text>
                    </Box>
                    <Box background={'#f2f2f2'} p={'7px'} textAlign={'center'}>
                      <Text color={'black'} fontSize={'12px'} fontWeight={'600'} fontFamily={'Open Sans,sans-serif'}>THIS IS THE CONFIRMATION OF INSURANCE </Text>
                    </Box>
                    <Grid templateColumns='repeat(4, 1fr)'>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Type</Text>
                      </GridItem>
                      <GridItem p={'5px'} colSpan={3} border={'1px solid #ddd'}>
                        <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.product==='VTC'?'Visitor To Canada':ele.quote.product==="STC"?'Student To Canada':'Super visa'}</Text>
                      </GridItem>


                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Holder Name</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.policyHolderName}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Number</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.policyNo}</Text>
                      </GridItem>

                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Holder Address</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.policyHolderAddress}</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Advisor</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.user.firstName} {ele.quote.user.lastName}</Text>
                      </GridItem>

                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Transcation Type</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>New Policy</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Transcation Date</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{formatDate(ele.createdAt)}</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Effective Date</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.firstDateOfCover}</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Policy Expiry Date</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.lastDateOfCover}</Text>
                      </GridItem>

                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Coverage Type</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.listOfInsured[0].insuredPlan}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Coverage Limit</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>${ele.quote.policyLimit}</Text>
                      </GridItem>

                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Departure date from country of origin</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.departureFromCountryOfOrigin}</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Family Coverage</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.familyCoverage===0?'NO':'YES'}</Text>
                      </GridItem>

                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Deductible</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>$ {ele.quote.deductible.amount}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Total Premium</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.quoteAmount} $</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Payment Plan Type</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.paymentFrequency===0?'One Lump Sum':'Monthly'}</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Payment Method</Text>
                      </GridItem>
                      <GridItem p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Credit Card</Text>
                      </GridItem>
                    </Grid>
                    <Box p={'7px'} textAlign={'center'}>
                      <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>List Of Travellers</Text>
                    </Box>
                    <Box>
                    {ele.quote.listOfInsured.map((item, index) => (
                      <Grid templateRows='repeat(2, 1fr)' templateColumns='repeat(8, 1fr)'>
                      <GridItem colSpan={7} background={primaryBackgroundColor} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'white'} fontWeight={'600'} fontSize={'14px'}>Insured {index+1}</Text>
                      </GridItem>
                      <GridItem background={primaryBackgroundColor} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'white'} fontWeight={'600'} fontSize={'14px'}>Status - </Text>
                      </GridItem>

                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Insured Name</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.insuredName}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>DOB</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.insuredDob}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Plan Type</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.insuredPlan}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Daily Rate</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{Math.round(ele.quote.quoteAmount/ele.quote.duration)} $</Text>
                      </GridItem>




                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'13px'}>Beneficiary Name</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.beneficiaryName}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Relation</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.beneficiaryRelationToInsured}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Beneficiary DOB</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'400'} fontSize={'14px'}>{item.beneficiaryDob}</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Total Premium</Text>
                      </GridItem>
                      <GridItem background={'#f2f2f2'} p={'5px'} border={'1px solid #ddd'}>
                          <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>{ele.quote.quoteAmount} $</Text>
                      </GridItem>
                      </Grid> ))
                        }
                      <Box p={'7px'} textAlign={'center'}>
                      <Text color={'#353535'} fontWeight={'600'} fontSize={'14px'}>Premium Receipt</Text>
                    </Box>
                    </Box>
                  </Box>
                  {
                      policyData && policyData.map((ele)=>( 
                        <>
                    <Text mt={'15px'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} color={"#24416b"}>Received $ {ele.paymentDetails[0].amount} for Policy of Mr/ Mrs {ele.quote.policyHolderName} the above Policy No {ele.policyNo}</Text>
                    <hr />
                    <Text fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} mt={'10px'}>It is your responsibility to review the policy documents in order to understand the coverage and exclusions, including the pre-existing conditions exclusion,and how they relate to you and your departure date. You must contact the Travel Medicare office immediately if any information is incorrect or to make any changes to your policy. Misrepresentation or failure to disclose any material fact may void the policy at the option of the insurer.Coverage is not valid until full payment is received. If your credit card is not approved, or if payment is not received for any reason whatsoever, your coverage will not be in effect.</Text>
                    <Text fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} mt={'10px'}>For Further details please contact your advisor at</Text>
                    
                    <Text color={"#24416b"} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} mt={'10px'}>{ele.quote.user.firstName} {ele.quote.user.lastName} <br /> {ele.quote.user.address}</Text>
                    </>))}
                    <Text fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} mt={'10px'}>or contact</Text>
                    <Text color={"#24416b"} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} mt={'10px'}>Travel Medicare <br /> Riskcare Insurance Ltd <br /> 7895, Tranmere Drive <br />Mississauga <br />ON L5S1V9</Text>
                    <Text color={'#000'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} mt={'10px'}>In the event of an emergency or if you require medical treatment you must contact the Assistance Company immediately at:</Text>
                    <Flex justifyContent={'space-around'}>
                      <Text color={'#000'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} mt={'10px'}>1-844-879-8379 <br />Toll-free from Canada and the USA</Text>
                      <Text color={'#000'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} mt={'10px'}>1-844-879-8379 <br />Toll-free from Canada and the USA</Text>
                    </Flex>
                    <Box textAlign={'center'}>
                    <Text color={'#000'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} fontSize={'12px'} mt={'10px'}>Email: assist@epicamericas.com</Text>
                    </Box>
                    <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>It is your responsibility to ensure that the Assistance Company has been contacted prior to receiving treatment. Your benefits will be limited to 80% of eligible expenses to a maximum of $25,000 if you fail to do so, other than in extreme circumstances when treatment is required to resolve a life threatening medical crisis.
              Your claim will be handled within 10 days of the Assistance Company receiving all requested medical documentation. Contact the Assistance Company for the status of your claim.</Text>
              <Text fontWeight={'600'} fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>Waiting Period-If you purchase this coverage after your departure from your country of origin there is no coverage for any sickness that began, or for which you experienced symptoms, during:</Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>a) The 48-hour period following the effective date of the policy if insurance is purchased within 30 days of your arrival to Canada. <br />
              b) the 8-day period following the effective date of the policy if insurance is purchased more than 30 days after your arrival to Canada.</Text>
              <Text fontWeight={'600'} fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>If you purchase this coverage after your departure from your country of origin there is no coverage for any injury that occurred is limited to 50% of all eligible expenses, during:</Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>c) the 48-hour period following the effective date of the policy if insurance is purchased within 30 days of your arrival to Canada. <br />
              d) the 8-day period following the effective date of the policy if insurance is purchased more than 30 days after your arrival to Canada</Text>
              <Text fontWeight={'600'} fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>Pre-Existing Conditions Exclusion <br />This policy does not cover losses or expenses related in whole or in part, directly or indirectly, to any of the following:</Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>Any sickness, injury or medical condition that existed prior to the effective date if you have selected and paid for Plan 1 as indicated on your Confirmation of Insurance. If you have selected and paid for Plan 2 as indicated on your Confirmation of Insurance, there is no coverage for any sickness, injury or medical condition that existed prior to the effective date, other than: <br />
              a) Up to age 74: Any sickness, injury or medical condition that was stable in the 90 days prior to the effective date.
              b) Age 75-84: Any sickness, injury or medical condition that was stable in the 180 days prior to the effective date provided you have accurately answered no to all questions on the medical declaration. If any question on the medical declaration is answered yes, there is no coverage for any sickness, injury or medical condition that existed prior to the effective date, whether or not stable.
              </Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>
               <span style={{color:'red'}}>Note*</span> Cancellation fee 2.3% will be deducted from the refund amount if premium is greater than 20$ for any type of refund.
              </Text>
              <Text fontWeight={'600'} fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>Policy Conditions -
              </Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>
                <ul>
                  <li> Travel insurance is designed to cover losses arising from sudden and unforeseeable circumstances. It is important that you read and understand your policy as your coverage may be subject to certain limitations or exclusions.</li>
                  <li>Your policy may not cover medical conditions and/or symptoms that existed prior to your trip. Check to see how this applies in your policy and how it relates to your effective date.
                  In the event of an accident, injury or sickness, your prior medical history will be reviewed when a claim is reported.</li>
                  <li>Costs incurred in your country of origin are not covered</li>
                  <li> Your policy provides travel assistance; you are required to notify the Assistance Company prior to medical treatment. Your policy may limit benefits should you not contact the Assistance Company before seeking medical treatment.</li>
                  <li> Please take the time to read this policy to ensure that it meets your needs and contact your agent if you have any questions. You may cancel this policy within 10 days of the purchase date for a full refund provided it is before the effective date. Other refunds available are described under Refunds in the General Provisions section of this policy.</li>
                </ul>
              </Text>
              <Text fontSize={'12px'} mt={'10px'} fontFamily={'Open Sans,sans-serif'}>
              If you wish to remain in Canada beyond the expiry date of this policy, you must contact your broker or sales agent prior to the expiry date and have no reason to seek medical attention during the new period of coverage. You may purchase a new policy subject to the policy terms, conditions and premium schedule in effect at the time the new policy is requested. The cost of insurance will be calculated using the age of the insured on the effective date of the new policy provided that: <br />
              a) you remain eligible for insurance; <br />b) you have not experienced any changes in your health since your effective date or arrival date; <br />c) the required premium is paid. <br />Each policy is considered a separate contract subject to all limitations and exclusions. The stability period will be applicable as of the effective date of the new policy.
              </Text>
        </Box>
  ))
}







                </TabPanel>




                <TabPanel>
            
                    {policyData && policyData.map((ele)=>(  
                    <Box overflowX={['auto','auto','unset']} whiteSpace={['nowrap','nowrap','normal']}>
                        <Text fontSize={'18px'} color={'blue'} fontFamily={'Open Sans,sans-serif'}>Policy Transcations for the policy ({policyData[0].policyNo})</Text>
                        <Text mt={'15px'} fontSize={'14px'} color={'grey'} fontFamily={'Open Sans,sans-serif'}>Void policy Transcations</Text>
                        <Box mt={'10px'} h={'150px'}>
                        <table style={{ borderCollapse: 'collapse', width: '100%'}}>
                                    <thead>
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Insured Name</th>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Effective Date</th>
                                    <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amount(CAD)</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { voids && voids.map((item)=>(
                                <tr style={{fontSize:'12px'}}>
                                <td>{ele.quote.policyHolderName}</td>
                                <td>{item.requestedDate}</td>
                                <td style={{color:'red'}}>-{item.refundAmount}$</td>
                                <td>{item.transactionNo}</td>
                                <td>{item.remarks}</td>
                                <td>{item.transactionStatus}</td>
                                </tr>))}
                            </tbody>
                            </table>
                        </Box>
                      
                        <Text mt={'15px'} fontSize={'14px'} color={'grey'} fontFamily={'Open Sans,sans-serif'}>Refund policy Transcations</Text>
                        <Box mt={'10px'} mb={'20px'} h={'150px'}>
                        <table style={{ borderCollapse: 'collapse', width: '100%'}}>
                            <thead>
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Policy Holder Name</th>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Effective Date	</th>
                                    <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Cancellation Date</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amount(CAD)</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No.</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { refund && refund.map((item)=>(
                                <tr style={{fontSize:'13px'}}>
                                <td>{ele.quote.policyHolderName}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                </tr>))}
                            </tbody>
                            </table>
                        </Box>
                        <Text mt={'15px'} fontSize={'14px'} color={'grey'} fontFamily={'Open Sans,sans-serif'}>Cancellation policy Transcations</Text>
                        <Box mb={'20px'} mt={'10px'} h={'150px'}>
                        <table style={{ borderCollapse: 'collapse', width: '100%'}}>
                            <thead>
                                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Policy Holder Name</th>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Effective Date	</th>
                                    <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Cancellation Date</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Refund Amount(CAD)</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation No.</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { cancellation && cancellation.map((item)=>(
                                <tr style={{fontSize:'13px'}}>
                                <td>{ele.quote.policyHolderName}</td>
                                <td>{item.requestedDate}</td>
                                <td>{item.cancellationDate}</td>
                                <td style={{color:'red'}}>-{item.refundAmount}$</td>
                                <td>{item.transactionNo}</td>
                                <td>{item.type}</td>
                                <td>{item.remarks}</td>
                                <td><FaDownload onClick={() => openRefundModalWithItem(item)}/></td>
                                <td>{item.transactionStatus}</td>
                                <td></td>
                                </tr>))}
                            </tbody>
                            </table>
                        </Box>


                        <Text mt={'15px'} fontSize={'14px'} color={'grey'} fontFamily={'Open Sans,sans-serif'}>Correction policy Transcations</Text>
                        <Box mb={'20px'} mt={'10px'} h={'150px'}>
                        <table style={{ borderCollapse: 'collapse', width: '100%'}}>
                               <thead>
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Requested Date</th>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Transcation No	</th>
                                    <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type of correction</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Correction needed in</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Requested Correction</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount(CAD)</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>To/From Customer</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Remarks</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Attachements</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Status</th>
                                    <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { correction && correction.map((item)=>( 
                                <tr style={{fontSize:'12px'}}>
                                <td>{item.requestedDate}</td>
                                <td>{item.transactionNo}</td>
                                <td>{item.endorsementType}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{item.remarks}</td>
                                <td>{item.transactionStatus}</td>
                                </tr>
                                ))
                            }
                            </tbody>
                            </table>
                        </Box>
                    </Box>
                        ))
                    }
                </TabPanel>




                <TabPanel>
                  {
                    policyData && policyData.map((ele)=>(
                      <Box style={{overflowX:'auto', whiteSpace:'nowrap'}} pb={'60px'}>
                     <Text fontSize={'16px'} color={'blue'} fontFamily={'Open Sans,sans-serif'}>Payment Transcations for the policy ({policyData[0].policyNo})</Text>
                      <Box mt={'15px'}>
                      <table style={{ borderCollapse: 'collapse', width: '100%'}}>
                                <thead>
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Card Holder Name</th>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Date	</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Premium Type</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Type</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Amount</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation ID</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Transcation Description</th>
                                <th style={{textAlign:'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ele.paymentDetails.map((item)=>(
                            <tr style={{fontSize:'12px'}}>
                              <td>{item.cardHolderFirstName} {item.cardHolderLastName}ra</td>
                              <td>{formatDate(item.createdAt)}</td>
                              <td></td>
                              <td></td>
                              <td>{item.amount} $</td>
                              <td>{item.transactionId}</td>
                              <td>{item.transactionDescription}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                      </Box>
                      </Box>
                    ))
                  }
                    
                </TabPanel>
            </TabPanels>
            </Tabs>
      </Box>
    </Box>



    <Box>
            <Modal isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Claim Reporting Form</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                    <Box>
                    <Flex>
                        <Box>
                            <Flex mt={'10px'} justifyContent={'space-between'}>
                                <Box w={'45%'}>
                                    <Text>Policy Holder Address*</Text>
                                    <Input
                                        size={'sm'}
                                        name="policyHolderAddress"
                                        value={formData.policyHolderAddress}
                                        onChange={handleChange}
                                    />
                                </Box>
                                <Box w={'45%'}>
                                    <Text>Phone*</Text>
                                    <Input
                                        size={'sm'}
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Flex>
                            <Flex mt={'10px'} justifyContent={'space-between'}>
                                <Box w={'45%'}>
                                    <Text>Date of Incident*</Text>
                                    <Input
                                        size={'sm'}
                                        type='date'
                                        name="incidentDate"
                                        value={formData.incidentDate}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Flex>
                            <Box mt={'10px'}>
                                <Text>Illness Details*</Text>
                                <Textarea
                                    name="illnessDetails"
                                    value={formData.illnessDetails}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Box>
                    </Flex>
                    
                    
                  </Box>
            </ModalBody>
            <ModalFooter>
                <Button size={'sm'} background={buttonBackgroundColor} color={buttonColor} _hover={{background:buttonHoverBackground,color:buttonHoverColor}} onClick={handleSubmit}>Submit</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>          
    </Box>
    <RefundAttachementModel isOpen={isRefundAttachementModelOpen.isOpen} onClose={()=>setIsRefundAttachementModelOpen({ ...isRefundAttachementModelOpen, isOpen: false })} refundItem={isRefundAttachementModelOpen.refundItem}/>
    </>
  )
}

export default AdviosrSinglePolicyView