import { toast } from "react-toastify";
import { CUSTOMER_EDIT_PROFILE, CUSTOMER_LOGIN,CUSTOMER_PROFILE,CUSTOMER_PROFILE_EDIT_TYPE,CUSTOMER_RENEWAL_POLICES_LIST_TYPE,CUSTOMER_SAVE_POLICY,CUSTOMER_SIGNUP, isLoading } from "../../../constants/actionConstants";
import { CustomerEditProfileService, CustomerLoginService, CustomerProfileService, CustomerSignupService} from "./service";

export const customerSignupList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let signUpList = await CustomerSignupService.customerSignupList(params);
    const {status, message, data} = signUpList

    dispatch({
      type: CUSTOMER_SIGNUP,
      payload: message,
      data,
    });
    dispatch(isLoading(false));
    return status
}



export const customerLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await CustomerLoginService.customerLoginList(params);
    const {status, message, data} = loginList
    
    if(status === 200) {
        dispatch({
            type: CUSTOMER_LOGIN,
            payload: data
        })
        toast.success("User Successfully Logged In")
    }else {
        toast.error(message)
    }
    
    dispatch(isLoading(false));

    return {status, data}
}


export const customerProfile = () => async (dispatch) => {
    dispatch(isLoading(true));
    let profile = await CustomerLoginService.customerProfile();
    const {status, message, data} = profile

    dispatch({
        type: CUSTOMER_PROFILE,
        payload: message,data
    })

    dispatch(isLoading(false));

    return profile
}




export const customerEditProfile = (params) => async (dispatch) => {
    console.log(params)
    dispatch(isLoading(true));
    let profile = await CustomerLoginService.customerEditProfile(params);
    const {status, message, data} = profile

    dispatch({
        type: CUSTOMER_EDIT_PROFILE,
        payload: message,data
    })

    dispatch(isLoading(false));

    return status
}

export const customerProfileEdit = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let customerProfileEdit = await CustomerLoginService.customerProfileEdit(params);
    const {status, message, data} = customerProfileEdit

    if(status === 201) {
        dispatch({
            type: CUSTOMER_PROFILE_EDIT_TYPE,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }


   

    dispatch(isLoading(false));

    return customerProfileEdit

}

export const customerSavePolicy = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let customerSavePolicy = await CustomerLoginService.customerSavePolicy(params);
    const {status, message, data} = customerSavePolicy
    if(status === 201) {
        dispatch({
            type: CUSTOMER_SAVE_POLICY,
            payload: message,data
        })
        toast.success(" Successfully")
    }else {
        toast.success(message)
    }
    dispatch(isLoading(false));
    return customerSavePolicy
}

export const customerRenewalListAll = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await CustomerLoginService.customerRenewalListAll(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: CUSTOMER_RENEWAL_POLICES_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };