export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, '0'); // Pad single digits with leading zero
  
    return `${year}-${month}-${day}`;
  };


  const toLocalDate = (date) => {
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - (offset * 60 * 1000));
  };
  
 export const formatDatePicker = (date) => {
    const localDate = toLocalDate(date);
    return localDate.toISOString().split('T')[0];
  };