import React from 'react'
import Sidebar from '../../Sidebar'

const SalesAndTpaReport = () => {



return (
    <Sidebar headingText={'Sales And TPA Report'}>
      
    </Sidebar>
  )
}

export default SalesAndTpaReport
