
import {SUPER_ADMIN_LOGIN} from "../../constants/actionConstants";

const initialState = {
  superAdminLogin: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SUPER_ADMIN_LOGIN:
      return { ...state,superAdminLogin: action.payload };
    default:
      return state;
  }
};




