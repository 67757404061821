import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Input, Text } from '@chakra-ui/react';
import Sidebar from '../Sidebar';
import { adminRefundList, adminRefundSettingsEdit } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { API_URL } from '../../../constants/ApplicationUrl';
import Api from '../../../dependencies/utils/Api';
import { toast } from 'react-toastify';

const RefundChargesSettings = () => {
    const [refundData, setRefundData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminRefundList())
            .then(res => {
                setRefundData(res); // Assuming data contains the refund settings array
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [dispatch]);

    // const handleSave = async (id, amountRefunded) => {
    //     try {
    //         const response = await  dispatch(adminRefundSettingsEdit(id, amountRefunded));
    //         if (response.status === 200) {
    //             // Success actions
    //         } else {
    //             // Failure actions
    //         }
    //     } catch (error) {
    //         console.error('Error updating page:', error);
    //         // Failure actions
    //     }
    // };

    const handleSave = (id, amountRefunded) => {
        // Make sure to replace 'YOUR_API_BASE_URL' with your actual base URL
        const apiUrl = `${API_URL()}/RefundChargesSettings/${id}`;
        
        // Parse commissionPercentage as a number
        const parsedamountRefunded = parseFloat(amountRefunded);
        
        // Send PUT request with updated data
        Api.PUT(apiUrl, { id, amountRefunded: parsedamountRefunded })
            .then(response => {
                // Handle success
                console.log("Settings updated successfully:", response.data);
                toast.success("Settings updated successfully");
            })
            .catch(error => {
                // Handle error
                console.error("Error updating settings:", error);
                toast.error("Error updating settings");
            });
    };

   

    return (
            <Sidebar headingText={'Refund Charges Settings'}>
            <Box w={"60%"} background={'white'} marginLeft={"60px"} padding={'50px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
                {refundData.map((refundSetting, index) => (
                    <Box key={index}>
                        <Text fontSize={'14px'} fontFamily={'Open Sans,sans-serif'}>Refund</Text>
                        <Input border={'1px solid grey'} defaultValue={refundSetting.amountRefunded}
                            onBlur={(e) => handleSave(refundSetting.id, e.target.value)}
                        />
                        <br />
                    </Box>
                ))}
                <Flex justifyContent={'end'} mt={'25px'}>
                <Button size={'sm'} background={'#9124a3'} color={'white'}>Save</Button>
                </Flex>
                </Box>
            </Sidebar>
    );
};

export default RefundChargesSettings;
