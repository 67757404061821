import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Flex, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { supporTicketAllAdminList } from './dependencies/action';

const AdminTickets = () => {
    const pagination = true;
    const paginationPageSizeSelector = [10, 20, 50, 100];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { role } = JSON.parse(localStorage.getItem("code_dev"))
    console.log("rolesname",role)

    useEffect(() => {
        dispatch(supporTicketAllAdminList())
            .then((res) => {
                console.log("Admin profile data fetched successfully", res);
                setTicketData(res);
            })
            .catch((error) => {
                console.error("Error fetching admin profile data:", error);
            });
    }, [dispatch]);

    const viewTicket = (params) => {
        const handleView = () => {
            const referenceNo = params.data.referenceNo; // Extracting referenceNo from params
            navigate(`/admin/view-single-ticket/${referenceNo}`);
            console.log('View clicked for row:', params.data);
        };
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Button onClick={handleView} _hover={{ color: 'white' }} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
            </div>
        )
    }

    const colDefs = [
        { field: "referenceNo", headerName: "Reference No", headerClass: 'header', cellClass: 'cell-class-name', width: 200 },
        { field: "subject", headerName: "Subject", headerClass: 'header', cellClass: 'cell-class-name', width: 200 },
        { field: "description", headerName: "Description", headerClass: 'header', cellClass: 'cell-class-name', width: 305 },
        { field: "status", headerName: "Status", headerClass: 'header', cellClass: 'cell-class-name', width: 175 },
        { field: "updatedAt", headerName: "Last Updated", headerClass: 'header', cellClass: 'cell-class-name', width: 175 },
        { headerName: "Action", headerClass: 'header', cellRenderer: viewTicket },
    ];

    // Filtering and search logic
    const filteredData = ticketData.filter((ticket) =>
        ticket.referenceNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.subject.toLowerCase().includes(searchTerm.toLowerCase()) ||
        ticket.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (ticket.status === 1 ? 'Open' : ticket.status === 2 ? 'In Progress' : 'Resolved').toLowerCase().includes(searchTerm.toLowerCase())
    );

    const rowData = filteredData.map(ticket => ({
        referenceNo: ticket.referenceNo,
        subject: ticket.subject,
        description: ticket.description,
        status: ticket.status === 1 ? 'Open' : ticket.status === 2 ? 'In Progress' : 'Resolved',
        updatedAt: new Date(ticket.updatedAt).toLocaleString(),
        Action: viewTicket,
    }));

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Sidebar headingText={'All Tickets'}>
            <Box w={'95%'} m={'auto'} background={'white'} p={'10px'} mt={'70px'}>
                <Flex justifyContent={'space-between'} mt={'15px'}>
                    <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
                        {/* Add your download buttons here */}
                    </div>
                    <div>
                        <label htmlFor="search">Search:</label>
                        <input
                            style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
                            type="text"
                            id="search"
                            placeholder="Search by ticket"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </div>
                </Flex>
                <div
                    className="ag-theme-quartz"
                    style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
                >
                    <AgGridReact
                        rowData={rowData}
                        columnDefs={colDefs}
                        domLayout='autoHeight'
                        width='100%'
                        paginationPageSizeSelector={paginationPageSizeSelector}
                        pagination={pagination}
                        paginationPageSize={10}
                        onGridReady={(params) => {
                            params.api.sizeColumnsToFit();
                        }}
                    />
                </div>
            </Box>
        </Sidebar>
    )
}

export default AdminTickets;
