import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import CustomInput from '../../../Utils/Input/Index';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { FaRegEdit } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { useDispatch } from 'react-redux';
import { adminMgaAgaAdvisorsStatus, agaMgaAll } from './dependencies/action';
import { Link, useNavigate } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import jsPDF from 'jspdf';

const MgaAgaAdvisor = () => {
  const dispatch = useDispatch();
  const [agaDataAll, setAgaDataAll] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    getAllData();
  }, [dispatch]);

  const getAllData = () => {
    dispatch(agaMgaAll())
      .then(res => {
        const { status, data } = res;
        console.log(res);
        setAgaDataAll(res);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const handleSearch = (value) => {
    setSearchQuery(value);
  };

  const filteredAgaData = agaDataAll.filter(data =>
    (data.firstName ? data.firstName.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.lastName ? data.lastName.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.email ? data.email.toLowerCase() : '').includes(searchQuery.toLowerCase()) ||
    (data.phone ? data.phone.toLowerCase() : '').includes(searchQuery.toLowerCase())
  );

  const downloadPDF = () => {
    const doc = new jsPDF();
    
    doc.text("Advisors List", 20, 10);
    
    const tableData = filteredAgaData.map(data => [
      data.firstName,
      data.lastName,
      data.email,
      data.phone,
      data.roles.name,
      data.roles.created_at,
      data.isProfileComplete,
      data.status === 1 ? "Active" : "Inactive",
      data.isApproved === 1 ? "Approved" : "Not Approved"
    ]);
    
    const headers = [
      'First Name',
      'Last Name',
      'Email',
      'Phone',
      'Role',
      'Created At',
      'Profile Status',
      'Status',
      'Approve Status'
    ];

    doc.autoTable({
      head: [headers],
      body: tableData,
      startY: 20
    });

    doc.save('advisors_list.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredAgaData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Advisors');
    XLSX.writeFile(workbook, 'advisors_list.xlsx');
  };

  const downloadCSV = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredAgaData);
    const csvData = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "advisors_list.csv");
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleViewClick = (data) => {
    navigate(`/admin/editAgaMgaAdvisor/${data}`);
  };

  const handleApproveStatus = (id, index) => {
    const confirmed = window.confirm("Are you sure you want to approve this status?");
    if (confirmed) {
      dispatch(adminMgaAgaAdvisorsStatus(id))
        .then(res => {
          const newList = [...approvedList];
          newList[index] = 1;
          setApprovedList(newList);
          getAllData();
        })
        .catch(error => {
          console.error('Error approving status:', error);
        });
    }
  };

  const handleDisapproveStatus = (id, index) => {
    const confirmed = window.confirm("Are you sure you want to disapprove this status?");
    if (confirmed) {
      dispatch(adminMgaAgaAdvisorsStatus(id))
        .then(res => {
          const newList = [...approvedList];
          newList[index] = 0;
          setApprovedList(newList);
          getAllData();
        })
        .catch(error => {
          console.error('Error disapproving status:', error);
        });
    }
  };

  return (
    <Sidebar headingText={'Advisor List'}>
      <Box w={'90%'} m={'auto'} mt={'70px'}>
        <Box background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
          <Flex justifyContent={'space-between'} mt={'10px'} mb={'25px'}>
            <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '13px', fontFamily: 'poppins' }}>
              <Tooltip hasArrow label='Download sheet pdf' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadPDF}>PDF</button></Tooltip>
              <Tooltip hasArrow label='Download sheet excel' bg='gray.300' color='black' placement='top-start' w={'180px'}>
                <button style={{ color: 'black', backgroundColor: '#e9e9e9', paddingLeft: '20px', paddingRight: '20px', border: '1px solid black' }} onClick={downloadExcel}> Excel</button></Tooltip>
            </div>
            <div>
              <CustomInput onChange={handleSearch} />
            </div>
          </Flex>

          <Box>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '15%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '7%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '5%' }} />
                <col style={{ width: '7%' }} />
              </colgroup>
              <thead>
                <tr style={{ borderBottom: '1px solid #dddddd' }}>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Name</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Role</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Email </th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '14px', fontFamily: 'Open Sans, sans-serif' }}>Phone</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Created At</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Profile Status</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Status</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Approve Status</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Edit</th>
                  <th style={{ textAlign: 'left', padding: '4px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {filteredAgaData.map((data, index) => (
                  <tr key={index} style={{ borderBottom: '1px solid #dddddd' }}>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.firstName} {data.lastName}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.roles.name}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.email}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.phone}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.roles.created_at}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>{data.isProfileComplete}</td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                      {data.status === 1 ? (
                        <Button size="xs" background="#4caf50" color="white">
                          <TiTick />
                        </Button>
                      ) : (
                        <Button size="xs" background="#f44336" color="white">
                          <FaTimes />
                        </Button>
                      )}
                    </td>
                    <div>
                      <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                        {data.isApproved === 1 ? (
                          <Button size={'xs'} background={'#f44336'} color={'white'} onClick={() => handleDisapproveStatus(data.idUser, index)}>
                            <FaTimes />
                          </Button>
                        ) : (
                          <Button size={'xs'} background={'#4caf50'} color={'white'} onClick={() => handleApproveStatus(data.idUser, index)}>
                            <TiTick />
                          </Button>
                        )}
                      </td>
                    </div>
                    <td onClick={() => handleViewClick(data.idUser)} style={{ textAlign: 'left', padding: '8px', fontSize: '20px', fontFamily: 'Open Sans, sans-serif' }}>
                      <FaRegEdit />
                    </td>
                    <td style={{ textAlign: 'left', padding: '8px', fontSize: '12px', fontFamily: 'Open Sans, sans-serif' }}>
                      <Button size={'xs'} background={'red'} color={'white'}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>
    </Sidebar>
  );
};

export default MgaAgaAdvisor;
