import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { FormControl, FormLabel, Button, Box, Flex } from '@chakra-ui/react';
import { menuList } from '../../../SuperAdmin/dependencies/action';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { assingMenus } from '../dependecies/action';

const AssignAdmin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menu, setMenu] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const { id } = useParams();
  

  useEffect(() => {
    dispatch(menuList())
      .then((res) => {
        console.log("Menu list fetched successfully");
        setMenu(res);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, [dispatch]);

  const handleBack = () => {
    navigate(-1);
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedMenus([...selectedMenus, value]);
    } else {
      setSelectedMenus(selectedMenus.filter(item => item !== value));
    }
  };

  const handleSubmit = async () => {
    if (selectedMenus.length === 0) {
      alert("Please select at least one menu.");
      return;
    }
    
    try {
      const payload = {
        id: id,
        menus: selectedMenus
      };
      await dispatch(assingMenus(payload));
      console.log("Menus assigned successfully.");
    } catch (error) {
      console.error("Error assigning menus:", error);
      alert("Error assigning menus.");
    }
  };

  return (
    <Sidebar headingText={'Assign Admin'}>
      <Button size={'xs'} background={'#9c27b0'} color='white' marginLeft={"20px"} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
      <Box w={'80%'} m={'auto'}>
        <FormLabel pt={"10px"} mt={'15px'} fontSize={'14px'} marginLeft={"10px"} fontFamily={'poppins'}>Select Menu11</FormLabel>
        <div style={{background:'white',display: 'grid',margin:"auto", boxShadow:'rgba(0, 0, 0, 0.16) 0px 1px 4px',gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px' ,padding:"50px",marginLeft:"30px",width:"80%"}}>
          {menu.map((menuItem, index) => (
            <FormControl key={index} display="flex" alignItems="center">
              <input
                type="checkbox"
                id={`menu-${index}`}
                name={`menu-${index}`}
                value={menuItem} // Set the value to the menu item itself
                onChange={handleCheckboxChange} // Use the handler function to handle change event
              />
              <FormLabel htmlFor={`menu-${index}`} ml={2} fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}>
                {menuItem}
              </FormLabel>
            </FormControl>
          ))}
        </div>

        <Button marginLeft={"660px"} size={'xs'} fontSize={'14px'} backgroundColor={'rgb(208, 206, 255)'} color={'rgb(84, 34, 237)'} mt={'15px'} onClick={handleSubmit}>Submit</Button>
        <Flex justifyContent={'center'}>
        </Flex>
      </Box>
    </Sidebar>
  );
}

export default AssignAdmin;
