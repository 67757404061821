import { toast } from "react-toastify";
import { ADMIN_CREATE_LIST, ADMIN_LOGIN_TYPE, MENU_LIST_TYPE, SUPER_ADMIN_LOGIN, isLoading } from "../../../constants/actionConstants";
import {AdminCreateLoginService, AdminLoginService, SuperAdminLoginService } from "./service";

export const superadminLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await SuperAdminLoginService.superadminLoginList(params);
    const {status, message, data} = loginList

    if(status === 200 || status===201) {
        dispatch({
            type: SUPER_ADMIN_LOGIN,
            payload: data
        })
        toast.success("Super Admin Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}

export const admincreateLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdminCreateLoginService.admincreateLoginList(params);
    const {status, message, data} = loginList

    if(status === 200 || status===201) {
        dispatch({
            type: ADMIN_CREATE_LIST,
            payload: data
        })
        toast.success("Super Admin Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}


export const menuList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminCreateLoginService.menuList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: MENU_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  export const adminLoginList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let loginList = await AdminLoginService.adminLoginList(params);
    const {status, message, data} = loginList

    if(status === 200 || status===201) {
        dispatch({
            type: ADMIN_LOGIN_TYPE,
            payload: data
        })
        toast.success("Admin Successfully Logged In")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}