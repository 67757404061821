import React, { useEffect, useState } from 'react'
import { Box, Flex, Image, Select, Text } from '@chakra-ui/react'
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { MdDoubleArrow } from "react-icons/md";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { storage } from '../../../dependencies/store/storage';
import { useSelector } from 'react-redux';




function Footer() {
  const { t, i18n } = useTranslation();
  const role = storage.getUserRole();
  const { user } = useSelector((state) => state.user)
  const [isAdmin, setIsAdmin] = useState(false);
  const [isPartner,setIsPartner]=useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  const handleLanguageChange = (e) => {
    const selectedLang = e.target.value;
    setSelectedLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
  };


  const location = useLocation();
  let path = location.pathname.split("/");
  path = path && path[1];
  console.log("path",path)
  useEffect(() => {
    if (path === "admin") {
      setIsAdmin(true);
    }
    if(path === "service"){
      setIsPartner(true)
    }
  }, [path]);


return (
    <>
      {!isAdmin && !isPartner && (
      <Box backgroundColor={'#04242B'} pt={'30px'} pb={'15px'} color={'white'}>
        <Flex direction={['column','column','row']} w={'85%'} m={'auto'} pb={'30px'}>
          <Box w={['99%','30%','30%']} m={'auto'} fontSize={'14px'} borderRight={'1px solid red'}>
            <Text fontFamily={'Assistant,sans-serif'} fontSize={'16px'}>{t('Contact')} </Text>
            <Box>
              <Flex p={'7px'} fontSize={'14px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <FaLocationDot />
                </Flex>
                <Text alignSelf={'center'}>7895 Tranmere Dr Unit #16 Suite 3 Mississauga ON L5S 1V9</Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <FaPhoneAlt />
                </Flex>
                <Text alignSelf={'center'}>1844-844-3272</Text>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Flex justifyContent={'center'} alignItems={'center'} width={'20px'} fontSize={'20px'}>
                  <MdOutlineMail />
                </Flex>
                <Text alignSelf={'center'}>info@travelmedicare.com</Text>
              </Flex>
            </Box>
          </Box>

          <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'} borderRight={'1px solid red'}>
            <Text fontFamily={'Assistant,sans-serif'}>Discover TM</Text>
            <Box fontSize={'14px'} pl={['40px','40px','0px']}>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/about-us'>
                  <Text alignSelf={'center'}>{t('About Us')}</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/enquiry'>
                  <Text alignSelf={'center'}>{t('Enquiry')}</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/privacy-policy'>
                  <Text alignSelf={'center'}>{t('Privacy Policy')}</Text>
                </Link>
              </Flex>
              <Flex p={'7px'} gap={'15px'}>
                <Link to='/terms_condition'>
                  <Text alignSelf={'center'}>{t('Terms & Condition')}</Text>
                </Link>
              </Flex>
            </Box>
          </Box>

          <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'} borderRight={'1px solid red'}>
            <Text fontFamily={'Assistant,sans-serif'}>{t('Other')}</Text>
            <Box fontSize={'14px'} pl={['40px','40px','0px']}>
              <Link to={'/join-us'}>
              <Flex p={'7px'} gap={'15px'}>
                <Text alignSelf={'center'}>{t('Become a Partner')}</Text>
              </Flex>
              </Link>
              <Link to={'/join-us'}>
              <Flex p={'7px'} gap={'15px'}>
                <Text alignSelf={'center'}>{t('Affiliate Program')}</Text>
              </Flex>
              </Link>
              <Select size={'sm'} border={'none'}
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    w={'90%'}
                  >
                  <option value="en" style={{color:'black'}}>Language</option>
                  <option value="en" style={{color:'black'}}>English</option>
                  <option value="hi" style={{color:'black'}}>Hindi</option>
                  <option value="pa" style={{color:'black'}}>Punjabi</option>
                  <option value="fr" style={{color:'black'}}>French</option>
                </Select>
            </Box>
          </Box>


          <Box mt={['15px','15px','0px']} w={['99%','20%','20%']} m={'auto'}>
            {
              role &&<Text fontSize={'13px'}>{t('Welcome you are logged in as :')} {role}</Text>
            }
            <Text fontFamily={'Assistant,sans-serif'} pb={'10px'}>{t('Social Media')}</Text>
            <Flex justifyContent={'space-around'} w={'70%'} fontSize={'22px'} pb={'15px'} pl={['40px','40px','0px']}>
              {/* <FaFacebook /> */}
              <a href="https://www.instagram.com/travelmedicare/" target='_blank'>
                <FaInstagramSquare />
              </a>
              <a href="https://x.com/TravelMedicare" target='_blank'>
              <FaTwitter />
              </a>
              <a href="https://www.linkedin.com/company/103296109" target='_blank'>
              <FaLinkedin />
              </a>
            </Flex>
            <Flex display={['none','none','hidden']}>
              <Image h={'50%'} src='https://verify.authorize.net/anetseal/images/secure90x72.gif' />
            </Flex>
          </Box>


        </Flex>

        <Box borderTop={'1px solid white'}>
          <Text fontSize={'14px'} pt={'5px'} pl={'20px'} fontFamily={'Assistant'}>Copyright  2019 Travel medicare. All Rights Reserved</Text>
        </Box>
      </Box>
      )}
      {
        isAdmin && (
          <></>
        )
      }

      
    {isPartner && (
              <></>
            )
          }
      
    </>
  )
}

export default Footer