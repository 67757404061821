import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Text,Flex, Box,Select,Input } from '@chakra-ui/react';
import { FaDownload } from "react-icons/fa";
import Api from '../../../../../dependencies/utils/Api';
import { FaEye } from "react-icons/fa";
import { ADMIN_ENDORSEMENT_APPROVEL, DOWNLOAD_ENDORSEMENT_ATTACHEMENT } from '../../../../../constants/ApplicationUrl';





export const RefundAttachementModel= ({ isOpen, onClose, onSubmit,refundItem}) => {
  if (!refundItem) return null;
  const attachmentArray = JSON.parse(refundItem.attachment);
  console.log(attachmentArray,'ite,')

  const downloadAttachement = async(type) => {
    try {
      const response = await fetch(`${DOWNLOAD_ENDORSEMENT_ATTACHEMENT}/${type}/${refundItem.idPolicyEndorsement}`);
      console.log(response)
      const blob = await response.blob();
      saveAs(blob, `${type}.pdf`);
  } catch (error) {
      console.error('Failed to download PDF:', error);
  }
  };

  const downloadPolicy = async() => {
    try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOCUMENT}/${id}`);
        console.log(response)
        const blob = await response.blob();
        saveAs(blob, `${id}.pdf`);
    } catch (error) {
        console.error('Failed to download PDF:', error);
    }
    };
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>Attachments For Transaction {refundItem.transactionNo} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}> 
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                                   
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Type</th>
                                <th style={{ textAlign: 'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Attachement</th>
                                <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>Download</th>
                            </tr>
                            
                        </thead>
                        <tbody>
                        {
                                attachmentArray && attachmentArray.map((ele)=>(
                            <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.type}</td>
                                <td style={{textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.type}.pdf</td>
                                <td style={{color:'#9c27b0', textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif',cursor:'pointer'}}><FaDownload onClick={()=>downloadAttachement(ele.type)}/></td>
                            </tr>))
                          }
                        </tbody>
                        </table>
            </Box>                    
          </ModalBody>
          <ModalFooter>
            <Flex gap="50px" justifyContent='center'>
              <Button mr={3} onClick={onClose}>Close</Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };



export const RefundActionModel= ({ isOpen, onClose,refundItem,onSubmit}) => {
    if (!refundItem) return null;
    console.log(refundItem,'approvel modal')
    const [formData,setFormData]=useState({
      idPolicy:refundItem.idPolicy,
      policyNo:refundItem.policyNo,
      idEndosement:refundItem.idPolicyEndorsement,
      endorsementType:refundItem.endorsementType,
      remarks:'',
      status:'',
      idInsured:refundItem.idInsured,
      refundStatus:refundItem.customerPaidStatus,
      idUser:refundItem.idUser,   
    })


    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevData => ({
          ...prevData,
          [name]: value
      }));
  };
  

const handleRefundActionSubmit = ()=>{
  console.log(formData,'submited form data')
  Api.POST(`${ADMIN_ENDORSEMENT_APPROVEL}`,formData).then((res)=>{
    console.log(res)
    if(res.status===200 || res.status===201){
      alert('Transcation updated successfully.')
      onSubmit()
      onClose()
    }else{
      alert('Something went wrong.')
      onClose()
    }
  })
}


return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}> 
                <Box w={'80%'} m={'auto'} mt={'40px'}>
                  <Text>Admin Action</Text>
                  <Select size={'sm'} border={'1px solid grey'} onChange={handleChange} name='status'>
                      <option value=''> Select</option>
                      <option value='approved'>Approve</option>
                      <option value='rejected'>Reject</option>
                  </Select>
                  <Text mt={'10px'}>Remarks</Text>
                  <Input size={'sm'} placeholder='Please enter remarks' border={'1px solid grey'} onChange={handleChange} name='remarks' />
                </Box>
              </Box>                    
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent='center'>
                <Button size={'sm'} background={'#cecece'} _hover={{background:'#cecece'}} mr={3} onClick={onClose}>Close</Button>
              <Button size={'sm'} background={'#9c27b0'} _hover={{color:'white'}} color={'white'} mr={3} onClick={handleRefundActionSubmit}>Update</Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      );
    };


export const RequestedCorrectionModal= ({ isOpen, onClose, onSubmit,correctionItem}) => {
      if (!correctionItem) return null;
      const arr = JSON.parse(correctionItem.correctionRequestedJson);
      const attachmentArray=[]
      attachmentArray.push(arr)
      console.log(attachmentArray,'ite,')
    
      // const downloadAttachement = async(type) => {
      //   try {
      //     const response = await fetch(`${DOWNLOAD_ENDORSEMENT_ATTACHEMENT}/${type}/${refundItem.idPolicyEndorsement}`);
      //     console.log(response)
      //     const blob = await response.blob();
      //     saveAs(blob, `${type}.pdf`);
      // } catch (error) {
      //     console.error('Failed to download PDF:', error);
      // }
      // };
    
      // const downloadPolicy = async() => {
      //   try {
      //       const response = await fetch(`${DOWNLOAD_POLICY_DOCUMENT}/${id}`);
      //       console.log(response)
      //       const blob = await response.blob();
      //       saveAs(blob, `${id}.pdf`);
      //   } catch (error) {
      //       console.error('Failed to download PDF:', error);
      //   }
      //   };
      
return (
          <Modal isOpen={isOpen} onClose={onClose} isCentered zIndex={99999999} size={'xl'}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader fontSize={'14px'} fontFamily={'poppins'}>Requested Correction {correctionItem.transactionNo} </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box fontFamily={'Open Sans,sans-serif'} fontSize={'14px'}> 
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                       
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                    <th style={{ textAlign: 'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}> Field</th>
                                    <th style={{ textAlign: 'left',  padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Old Value</th>
                                    <th style={{textAlign:'left', padding: '5px',fontSize:'12px',fontFamily:'Open Sans, sans-serif'  }}>New Value</th>
                                </tr>
                                
                            </thead>
                            {attachmentArray && attachmentArray.map((ele)=>(
                            <tbody>
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.new_ins_dob!==undefined?'DOB':ele.field}</td>
                                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.old_ins_dob!==undefined?ele.old_ins_dob:ele.oldPolicyFirstDateCover}</td>
                                    <td style={{textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.new_ins_dob!==undefined?ele.new_ins_dob:ele.policyFirstDateCover}</td>
                                </tr>
                                <tr style={{borderBottom:'1px solid #dddddd'}}>
                                <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.new_insuredname!==undefined?'Name':''}</td>
                                    <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.old_insuredname}</td>
                                    <td style={{textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>{ele.new_insuredname}</td>
                                </tr>
                            </tbody>
                                ))
                              }
                            </table>
                </Box>                    
              </ModalBody>
              <ModalFooter>
                <Flex gap="50px" justifyContent='center'>
                  <Button mr={3} onClick={onClose}>Close</Button>
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      };