import { Box, Flex, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { MagicMotion } from "react-magic-motion";
import { Link, useLocation } from "react-router-dom";
import { STORAGE_USER } from "../../../constants/applicationConstants";
import './style.css'
import { menuIcons } from "./MenuIcons";



const AdminSidebar = ({ onWidthChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [isPoliciesOpen, setIsPoliciesOpen] = useState(false); 
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [isAdvisorsOpen,setIsAdvisorsOpen] = useState(false)
  const [isCommissionOpen,setIsCommissionOpen]=useState(false)
  const [isMonthlyPoliciesOpen, setIsMonthlyPoliciesOpen] = useState(false);
  const location = useLocation();
  let obj = JSON.parse(localStorage.getItem(STORAGE_USER));
  const menus = obj.listOfMenus || [];

  const handleToggleSidebar = () => {
    onWidthChange(isCollapsed ? 14 : 3.3);
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const pathname = location.pathname;
    const activeMenuItem = menus.find(menu =>
      pathname.includes(menu.replace(/\s+/g, "-").toLowerCase())
    );
    setActiveItem(activeMenuItem);
  }, [location, menus]);

  const handlePoliciesClick = () => {
    setIsPoliciesOpen(!isPoliciesOpen);
  };

  const handleReportsClick = () => {
    setIsReportsOpen(!isReportsOpen);
  };

  const handleAdvisorsClick = () =>{
    setIsAdvisorsOpen(!isAdvisorsOpen)
  }

  const handleCommissionClick = () =>{
    setIsCommissionOpen(!isCommissionOpen)
  }


  return (
    <Box>
      <MagicMotion>
        <aside
          style={{
            backgroundColor: "white",
            width: isCollapsed ? "3.3rem" : "14rem",
            fontFamily:'Open Sans,sans-serif',
            fontSize:'14px',
            paddingTop: "20px",
            paddingBottom: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflow: "hidden",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            marginBottom: "50px",
            position: "fixed",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
              justifyContent: "space-between",
              padding:"10px",
              paddingLeft: "15px",
            }}
          >
            {!isCollapsed && <h4 style={{ margin: 0 }}>Admin Panel</h4>}

            <button
              style={{ cursor: "pointer", padding: 0, border: 0 }}
              onClick={handleToggleSidebar}
              title={isCollapsed ? "Expand Sidebar" : "Collapse Sidebar"}
            >
              {isCollapsed ? (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 12.9999V10.9999H15.4853L12.2427 7.75724L13.6569 6.34303L19.3137 11.9999L13.6569 17.6567L12.2427 16.2425L15.4853 12.9999H1Z"
                    fill="currentColor"
                  />
                  <path
                    d="M20.2877 6V18H22.2877V6H20.2877Z"
                    fill="currentColor"
                  />
                </svg>
              ) : (
                <svg
                  style={{ minWidth: "24px", minHeight: "24px" }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.2877 11.0001V13.0001H7.80237L11.045 16.2428L9.63079 17.657L3.97394 12.0001L9.63079 6.34326L11.045 7.75748L7.80236 11.0001H22.2877Z"
                    fill="currentColor"
                  />
                  <path d="M3 18V6H1V18H3Z" fill="currentColor" />
                </svg>
              )}
            </button>
          </div>
          <Box overflowY={'scroll'} h={'85vh'} scrollbarWidth={'2px'} scrollbarColor={'red'}>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 0,  
                padding: 0,
              }}
            >
              {menus.map((menuItem, index) => (
                <li key={index}>
                  {menuItem === "Policies" ? (
                    <div>
                      <button
                        onClick={handlePoliciesClick}
                        style={{
                          display: "flex",
                          gap: "0.8rem",
                          alignItems: "center", 
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            visibility: isCollapsed ? "hidden" : "visible",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            transform: isPoliciesOpen ? "rotate(180deg)" : "none",
                            transition: "transform 0.3s ease",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17L7 12L8.41 10.59L12 14.17L15.59 10.59L17 12L12 17Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      {isPoliciesOpen && ( 
                        <>
                        <ul style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 0,  
                          padding: 0,
                          backgroundColor:'#f7f7f7'
                        }}>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                        }}>
                          <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/all-policies">
                              All Policies
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/montly-policies'>
                              Monthly Policies
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to={'/admin/subscription-due'}>
                             Subscription Due
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                        }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/renewal-policies">
                              Renewal Policies
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/claim-requests">
                              Claim Requests
                            </Link>
                          </li>

                        </ul>
                        </>
                      )}
                    </div>
                     ) : menuItem === "Reports" ? (
                    <div>
                      <button
                        onClick={handleReportsClick}
                        style={{
                          display: "flex",
                          gap: "0.8rem",
                          alignItems: "center", 
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            visibility: isCollapsed ? "hidden" : "visible",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            transform: isReportsOpen ? "rotate(180deg)" : "none",
                            transition: "transform 0.3s ease",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17L7 12L8.41 10.59L12 14.17L15.59 10.59L17 12L12 17Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      {isReportsOpen && ( 
                        <>
                        <ul style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 0,  
                          padding: 0,
                          backgroundColor:'#f7f7f7'
                        }}>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/account-payable-report'>
                             Account Payable
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to={'/admin/commission-report'}>
                             User Commission
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                        }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/travel-accounting-report">
                              Travel Accounting
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/sales-and-tpa-report">
                             Sales and TPA 
                            </Link>
                          </li>

                        </ul>
                        </>
                      )}
                    </div>
                     ) : menuItem ==="Commission"?(
                      <div>
                      <button
                        onClick={handleCommissionClick}
                        style={{
                          display: "flex",
                          gap: "0.8rem",
                          alignItems: "center", 
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            visibility: isCollapsed ? "hidden" : "visible",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            transform: isReportsOpen ? "rotate(180deg)" : "none",
                            transition: "transform 0.3s ease",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17L7 12L8.41 10.59L12 14.17L15.59 10.59L17 12L12 17Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      {isCommissionOpen && ( 
                        <>
                        <ul style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 0,  
                          padding: 0,
                          backgroundColor:'#f7f7f7'
                        }}>
                           <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/commissionSettings'>
                             Commission Settings
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/refundSettings'>
                             Refund Settings
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/commission'>
                            Commission
                            </Link>
                          </li>
                        </ul>
                        </>
                      )}
                    </div>):
                      menuItem === "Advisors" ? (
                      <div>
                        <button
                          onClick={handleAdvisorsClick}
                          style={{
                            display: "flex",
                            gap: "0.8rem",
                            alignItems: "center", 
                            padding: isCollapsed ? "3px 6px" : "5px 10px",
                            margin: isCollapsed ? "5px 5px" : "5px 15px",
                            borderRadius: '10px',
                            border: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          <svg
                            style={{
                              minWidth: "24px",
                              minHeight: "24px",
                              visibility: isCollapsed ? "hidden" : "visible",
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {menuIcons[menuItem]}
                          </svg>
                          {menuItem}
                          <svg
                            style={{
                              minWidth: "24px",
                              minHeight: "24px",
                              transform: isReportsOpen ? "rotate(180deg)" : "none",
                              transition: "transform 0.3s ease",
                            }}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 17L7 12L8.41 10.59L12 14.17L15.59 10.59L17 12L12 17Z"
                              fill="currentColor"
                            />
                          </svg>
                        </button>
                        {isAdvisorsOpen && ( 
                          <>
                          <ul style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: 0,  
                            padding: 0,
                            backgroundColor:'#f7f7f7'
                          }}>
                             <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                            margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                              }}>
                           <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                              width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                              <Link to='/admin/all-advisors'>
                               All Advisors
                              </Link>
                            </li>
                            <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                            margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                              }}>
                           <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                              width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                              <Link to='/admin/assign-aga'>
                               Assign AGA
                              </Link>
                            </li>
                            <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                            margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                              }}>
                           <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                              width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                              <Link to={'/admin/assign-mga'}>
                               Asign MGA
                              </Link>
                            </li>
                          </ul>
                          </>
                        )}
                      </div>
                       ) :(
                    <Link
                      to={`/admin/${menuItem.replace(/\s+/g, "-").toLowerCase()}`}
                    >
                      <li
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          background: activeItem === menuItem ? "#d0ceff" : "white",
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          fontSize:isCollapsed?'16px':'12px',
                          color: activeItem === menuItem ? '#5422ed' : 'black',
                          fontFamily:'Open Sans,sans-serif',
                          fontWeight:'500',
                          color:'#262626'
                        }}
                        
                      >
                        <svg
                          style={{
                            minWidth:isCollapsed?'5px':'24px',
                            minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',
                            height:isCollapsed?'5px':'24px',
                            viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",
                            visibility: isCollapsed ? "hidden" : "visible",
                            color:'grey'
                          }}
                          fill="grey"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                      </li>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </Box>



          
          {/* <Box overflowY={'scroll'} h={'85vh'} scrollbarWidth={'2px'} scrollbarColor={'red'}>
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 0,  
                padding: 0,
              }}
            >
              {menus.map((menuItem, index) => (
                <li key={index}>
                  {menuItem === "Reports" ? (
                    <div>
                      <button
                        onClick={handleReportsClicks}
                        style={{
                          display: "flex",
                          gap: "0.8rem",
                          alignItems: "center", 
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          border: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            visibility: isCollapsed ? "hidden" : "visible",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                        <svg
                          style={{
                            minWidth: "24px",
                            minHeight: "24px",
                            transform: isPoliciesOpen ? "rotate(180deg)" : "none",
                            transition: "transform 0.3s ease",
                          }}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17L7 12L8.41 10.59L12 14.17L15.59 10.59L17 12L12 17Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                      {isReportsOpen && ( 
                        <>
                        <ul style={{
                          display: "flex",
                          flexDirection: "column",
                          margin: 0,  
                          padding: 0,
                          backgroundColor:'#f7f7f7'
                        }}>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                        }}>
                          <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/all-policies">
                              Reports
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to='/admin/insuranceCompanyReports'>
                              Insurance Company Reports
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                             Commision Payable
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                        }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/renewal-policies">
                              Settled Commision
                            </Link>
                          </li>
                          <li className="submenu-item" style={{padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",paddingLeft:isCollapsed?"6px":'20px',
                            }}>
                         <svg style={{minWidth:isCollapsed?'5px':'24px',minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',height:isCollapsed?'5px':'24px',viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",visibility: isCollapsed ? "hidden" : "visible",}} fill="none" xmlns="http://www.w3.org/2000/svg" >{menuIcons[menuItem]}</svg>
                            <Link to="/admin/claim-requests">
                              Claim Requests
                            </Link>
                          </li>

                        </ul>
                        </>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={`/admin/${menuItem.replace(/\s+/g, "-").toLowerCase()}`}
                    >
                      <li
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                          background: activeItem === menuItem ? "#d0ceff" : "white",
                          padding: isCollapsed ? "3px 6px" : "5px 10px",
                          margin: isCollapsed ? "5px 5px" : "5px 15px",
                          borderRadius: '10px',
                          fontSize:isCollapsed?'16px':'12px',
                          color: activeItem === menuItem ? '#5422ed' : 'black',
                          fontFamily:'Open Sans,sans-serif',
                          fontWeight:'500',
                          color:'#262626'
                        }}
                        
                      >
                        <svg
                          style={{
                            minWidth:isCollapsed?'5px':'24px',
                            minHeight:isCollapsed?'5px':'24px',
                            width:isCollapsed?'5px':'24px',
                            height:isCollapsed?'5px':'24px',
                            viewBox:isCollapsed?"0 0 10 10":"0 0 24 24",
                            visibility: isCollapsed ? "hidden" : "visible",
                            color:'grey'
                          }}
                          fill="grey"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {menuIcons[menuItem]}
                        </svg>
                        {menuItem}
                      </li>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </Box> */}
        </aside>
      </MagicMotion>
    </Box>
  );
};

export default AdminSidebar;
