import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { advisorSingleGetQuotes } from '../../../Advisor/Dependencies/action'


const QuotePaymentTranscation = () => {
  const [quoteData,setQuoteData]=useState([])
  const params= useParams()
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const {id} = params


  const handleBack = () => {
    navigate(-1);
  }

  useEffect(() => {
    if (id) {
        dispatch(advisorSingleGetQuotes(id))
            .then(response => {
              console.log(response.data)
                setQuoteData([response.data]); 
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
}, [dispatch, id]);
  

return (
    <Sidebar headingText={"Monaris - Quote Payment Transactions"}>
      {
        quoteData && quoteData.map((ele)=>(
        <Box w='95%' m='auto' mt={'70px'}>
            <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Text fontSize={'20px'}>Quote Details</Text>
          <Flex justifyContent={'space-around'}>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Quote No:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Quote Created date:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Amount:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Duration:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}> 
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{params.id}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{ele.createdAt}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>$ {ele.quoteAmount}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{ele.duration}</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>Policy Holder Name:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
                <Text mt={'15px'} color={'#727272'} fontSize={'14px'} fontWeight={'600'}>First Date Of Cover:</Text>
            </Box>
            <Box mt={'25px'} w={'22%'}>
                <Text mt={'10px'}t color={'#3C4858'} fontWeight={'300'}>{ele.policyHolderName}</Text>
                <Text mt={'10px'} color={'#3C4858'} fontWeight={'300'}>{ele.firstDateOfCover}</Text>
                <Text mt={'10px'} textAlign={'left'} color={'#3C4858'} fontWeight={'300'}>{ele.lastDateOfCover}</Text>
            </Box>
          </Flex>



        <Box background={'white'} mt={'50px'} p={'20px'}> 
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <colgroup>
                    <col style={{ width: '15%'   }} />
                    <col style={{ width: '10%'    }} />
                    <col style={{ width: '15%'    }} />
                </colgroup>
                <thead>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Card Holder Name</th>
                        <th style={{ textAlign: 'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif' }}>Date	</th>
                        <th style={{textAlign:'left',padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation ID</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Ref ID</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Auth Code</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Amount CAD</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Transcation Description</th>
                        <th style={{textAlign:'left', padding: '10px',fontSize:'14px',fontFamily:'Open Sans, sans-serif'  }}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{borderBottom:'1px solid #dddddd'}}>
                        {/* <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Aniket Anil Sawant	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>2024-01-24	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>
                        Premium(Policy Issued)</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Credit</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>155</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>120245072055	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>This transaction has been approved.	</td>
                        <td style={{  textAlign: 'left', padding: '10px',fontSize:'12px',fontFamily:'Open Sans, sans-serif' }}>Update</td> */}
                    </tr>
                </tbody>
                 </table>
          </Box>
        </Box>
        ))
      }
    </Sidebar>
  )
}

export default QuotePaymentTranscation
