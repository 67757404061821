import React from 'react'
import { FaDownload } from "react-icons/fa6";
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';



const QutotesDocDownload = () => {
    const navigate=useNavigate()


    const handleBack = () => {
        navigate(-1);
      }


return (
    <Sidebar headingText={'Download Documents'}>
        <Box w={'80%'} m={'auto'}>
        <Button size={'sm'} background={'#9c27b0'} color='white' mt={'50px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Box background='white' p={'20px'} pt={'20px'} mt={'15px'}>
                <Text mt={'30px'} mb={'70px'} color={'grey'} fontSize={'22px'} fontFamily={'Open Sans,sans-serif'}>Download document for Quotation : 2024QTM670232</Text>
                <Flex w={'90%'} m={'auto'} mt={'50px'} mb={'70px'} gap={'40px'}>
                    <Box>
                        <Text fontSize={'16px'} fontFamily={'poppins'} >Quotation</Text>
                        <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                        <FaDownload />
                        </Flex>
                    </Box>
                    <Box>
                        <Text fontSize={'16px'} fontFamily={'poppins'} >TM_Policy</Text>
                        <Flex justifyContent={'center'} mt={'10px'} color={'#9c27b0'}>
                        <FaDownload />
                        </Flex>
                    </Box>

                </Flex>
            </Box>
        </Box>
</Sidebar>
  )
}

export default QutotesDocDownload
