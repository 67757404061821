import { PAGES_DETAIL, PAGES_DETAILS_ALL, PAGES_DETAILS_EDIT, REFUND_SETTINGS_EDIT_TYPE, REFUND_SETTINGS_LIST_TYPE, isLoading } from "../../../../constants/actionConstants";
import { AdminPagesService, AdminRefundSettingsService } from "./service";

import { toast } from "react-toastify";

export const adminRefundSettingsEdit = (id,params) => async (dispatch) => {
    dispatch(isLoading(true));
    let pagesList = await AdminRefundSettingsService.adminRefundSettingsEdit(id,params);
    const {status, message, data} = pagesList

    if(status === 200) {
        dispatch({
            type: REFUND_SETTINGS_EDIT_TYPE,
            payload: data
        })
        toast.success("Page sucessfully updated")
    }else {
        // toast.error(message)
        alert(message)
    }
    dispatch(isLoading(false));
    return {status, data}
}



export const adminRefundList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await AdminRefundSettingsService.adminRefundList(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: REFUND_SETTINGS_LIST_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };


