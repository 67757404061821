import { ADMIN_REFUND_REQUEST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminRefundRequestService = {

    adminRefundRequestList() {
        return Api.GET(ADMIN_REFUND_REQUEST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    }
}