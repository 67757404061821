import { ADMIN_PROFILE_EDIT, ADMIN_PROFILE_LIST } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AdminProfileService = {
adminProfileList() {
    return Api.GET(ADMIN_PROFILE_LIST).then((response) => {
        console.log(response,'responserviceadvisor')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
adminProfileEdet(params) {
    return Api.PUTDATA(ADMIN_PROFILE_EDIT,params).then((response) => {
        // console.log(response,'response')
        const { data, status} = response
        if(status === 201) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},


}