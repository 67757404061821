import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, Image, Text,Input,Textarea } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import Api from '../../../../dependencies/utils/Api'
import { GET_CUSTOMER_BY_ID } from '../../../../constants/ApplicationUrl'

const AdminCustomerProfile = () => {
    const [profileData,setProfileData]=useState([])
    const params = useParams()
    const {id}=params


useEffect(()=>{
    getCustomerData()
},[])


const getCustomerData=()=>{
    Api.GET(`${GET_CUSTOMER_BY_ID}/${id}`).then((res)=>{
        console.log(res)
        setProfileData([res.data])
    })
}


return (
    <Sidebar headingText={'Customer Dashboard'}>
        <Box>
        <Flex boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'90%'} m={'auto'} p={'18px'} fontFamily={'poppins'} justifyContent={'space-around'}>
  <Link to={`/admin/customer-dashboard/${id}`}>
                <Text>Quotes</Text>
                </Link>
                <Link to={`/admin/customer-policies/${id}`}>
                <Text>Policies</Text>
                </Link>
                <Link to={`/admin/customer-renewal-policies/${id}`}>
                <Text>Renewal Policies</Text>
                </Link>
                <Link to={`/admin/customer-profile/${id}`}>
                <Text>Profile</Text>
                </Link>
            </Flex>

        <Box w={'80%'} m={'auto'} mt={'80px'} pb={'60px'} backgroundColor={'white'}>
        <Flex justifyContent={'space-around'} mt={'10px'}>
          <Box w={'30%'} mt={'50px'}>
          <Image borderRadius={'50%'} border={'1px solid grey'} w={'200px'} src='https://www.whitechapelgallery.org/wp-content/uploads/2020/07/blank-head-profile-pic-for-a-man.jpg'/>
          </Box> 
            {profileData && profileData.map((ele)=>(
                <>                
                <Box textAlign={'left'} w={'60%'}>
                  <Box pl={'30px'}>
                    <Flex pt={'30px'} gap={'40px'}>
                      <Box>
                        <Text>First Name</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.firstName} />
                      </Box>
                      <Box>
                        <Text> Last Name</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.lastName} />
                      </Box>
                    </Flex>
                    <Flex pt={'20px'} gap={'40px'}>
                      <Box>
                        <Text>Email</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.email}/>
                      </Box>
                      <Box>
                        <Text>Phone</Text>
                        <Input backgroundColor={'#f1f1f1'} color={'grey'} value={ele.phone} />
                      </Box>
                    </Flex>
                    <Box>
                  <Text mt={'10px'}>Address</Text>
                  <Textarea backgroundColor={'#f1f1f1'} color={'grey'}>{ele.address}</Textarea>
                    </Box>
                  </Box>
                </Box>
                </>
              ))
            }
        </Flex>
      </Box>



        </Box>
    </Sidebar>
  )
}

export default AdminCustomerProfile
