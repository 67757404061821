import { ADMIN_STATUS, ADMIN_USERS_LIST, AGA_MGA_ALL, ALL_ROLES, QUESTIONS_EDIT_MGAAGAADVISOR } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"

export const AgaMgaAllService = {

    agaMgaAll() {
        return Api.GET(AGA_MGA_ALL).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    allRolesList() {
        return Api.GET(ALL_ROLES).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },

      questionsandassingingEdit(params) {
        return Api.PUTDATA(QUESTIONS_EDIT_MGAAGAADVISOR,params).then((response) => {
            // console.log(response,'response')
            const { data, status} = response
            if(status === 200 || 201) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
    adminMgaAgaAdvisorsStatus(id, params) {
        return Api.POST(`${ADMIN_STATUS}/${id}`, params).then((response) => {
          console.log(response,'response')
          const { data, status } = response
          if (status === 200 || status === 201) {
            return { data, status }
          } else {
            const { data: { message } = {} } = response
            return { message, status }
          }
        })
      }
}