import {
  STUDENTVISA_QUICKQUOTE,
  SUPERVISA_QUICKQUOTE,
  VISITORVISA_QUICKQUOTE,
} from "../../../constants/ApplicationUrl";
import Api from "../../../dependencies/utils/Api";
import axios from "axios";

export const supervisaQuicknoteService = {
  // async SupervisaQuicknoteList(params) {
  //   try {
  //     const response = await Api.POST(SUPERVISA_QUICKQUOTE, params);
  //     const { data: { message, status } = {} } = response;
  //     const data = response.data;
  //     if (status) {
  //       return { message, status, data };
  //     } else {
  //       return { message, status };
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // },
  SupervisaQuicknoteList(params) {
    return Api.POST(SUPERVISA_QUICKQUOTE,params).then((response) => {
        console.log(response,'response123')
        const { data, status} = response
        if(status === 200) {
            return {data, status}
        }else {
            const {data: {message} = {}} = response
            return {message, status}
        }
    })
},
};

export const studentQuicknoteService = {
   StudentQuicknoteList(params) {
    // try {
    //   const response = await axios.post(STUDENTVISA_QUICKQUOTE, params);
    //   const { data: { message, status, data } = {} } = response;
    //   if (status) {
    //     return { message, status, data };
    //   } else {
    //     return { message, status };
    //   }
    // } catch (error) {
    //   console.error(error);
    //   throw error;
    // }
    return Api.POST(STUDENTVISA_QUICKQUOTE, params).then((response)=>{
      console.log(response,'response123')
      const { data, status} = response
      if(status === 200) {
          return {data, status}
      }else {
          const {data: {message} = {}} = response
          return {message, status}
      }
    })
  },
};


export const visitorQuicknoteService = {
  VisitorQuicknoteList(params) {
   // try {
   //   const response = await axios.post(STUDENTVISA_QUICKQUOTE, params);
   //   const { data: { message, status, data } = {} } = response;
   //   if (status) {
   //     return { message, status, data };
   //   } else {
   //     return { message, status };
   //   }
   // } catch (error) {
   //   console.error(error);
   //   throw error;
   // }
   return Api.POST(VISITORVISA_QUICKQUOTE, params).then((response)=>{
     console.log(response,'response123')
     const { data, status} = response
     if(status === 200) {
         return {data, status}
     }else {
         const {data: {message} = {}} = response
         return {message, status}
     }
   })
 },
};


