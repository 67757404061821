import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar'
import { Box, Flex, Select, Text, Tooltip } from '@chakra-ui/react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { advisorGetQuotes, advisorRenewalListAll } from '../../../Advisor/Dependencies/action'
import { useDispatch } from 'react-redux'
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Api from '../../../../dependencies/utils/Api'
import { FaDownload } from 'react-icons/fa6'

const AdminAdvisorRenewalPolicies = () => {
  const params = useParams()
  const { id } = params
  const [product, setProduct] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();
  const [policiesData, setPoliciesData] = useState([])
  const [customerData, setCustomerData] = useState([])
  const [rowData, setRowData] = useState([]);
  const [rowDataEmpty, setRowDataEmpty] = useState([])
  const navigate = useNavigate()

  const pagination = true;
  const paginationPageSizeSelector = [2, 5, 1000];

  useEffect(() => {
    dispatch(advisorRenewalListAll())
      .then((res) => {
        console.log("Fetched data:", res); // Log the fetched data
        const transformedData = transformData(res);
        console.log("Transformed data:", transformedData); // Log the transformed data
        setRowData(transformedData); // Update state with transformed data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);

  const transformData = (res) => {
    return res.map(item => ({
      employeeName: `${item.quote.user.firstName} ${item.quote.user.lastName}`,
      designation: item.quote.user.roles.name,
      product: item.quote.product,
      policyNo: item.policyNo,
      dateOfIssue: item.createdAt,
      customerName: item.quote.policyHolderName,
      tripType: item.product,
      policyAmount: item.quote.quoteAmount,
      status: item.status === 1 ? 'Active' : 'Inactive',
    }));
  };
  const [colDefs, setColDefs] = useState([
    { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
    { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true },
    { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150 },
    { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 330 },
    { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
    { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300 },
    { field: "tripType", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "policyAmount", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },

  ]);






  return (
    <Sidebar headingText={'Customer Dashboard'}>
      <Flex boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' background={'white'} w={'90%'} m={'auto'} p={'18px'} fontFamily={'poppins'} justifyContent={'space-around'}>
        <Link to={`/admin/customer-dashboard/${id}`}>
          <Text>Quotes</Text>
        </Link>
        <Link to={`/admin/advisor-policies/${id}`}>
          <Text>Policies</Text>
        </Link>
        <Link to={`/admin/advisor-renewal-policies/${id}`}>
          <Text>Renewal Policies</Text>
        </Link>
        <Link to={`/admin/editAgaMgaAdvisor/${id}`}>
          <Text>Profile</Text>
        </Link>
      </Flex>


      <Box w={'90%'} m={'auto'} mt={'80px'}>
        {rowData.length > 0 ? (
          <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              // frameworkComponents={{ actionCellRenderer }}
              domLayout='autoHeight'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              onGridReady={(params) => {
                setTimeout(() => {
                  params.api.sizeColumnsToFit();
                }, 1000);
              }}
            />
          </div>
        ) : (
          <div className='ag-theme-quartz' style={{ height: 500, width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              domLayout='autoHeight'
              width='100%'
              paginationPageSizeSelector={paginationPageSizeSelector}
              pagination={pagination}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
            />

          </div>
        )}

      </Box>
    </Sidebar>
  )
}

export default AdminAdvisorRenewalPolicies
