// validation.js

// Regular expressions for email and mobile number validation
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const mobileRegex = /^[0-9]{10}$/;

// Function to validate email format
export const validateEmail = (email) => {
    return emailRegex.test(email.trim());
};

// Function to validate mobile number format
export const validateMobile = (mobile) => {
    return mobileRegex.test(mobile.trim());
};

// Function to validate required fields
export const validateRequiredFields = (fields) => {
    for (const field of fields) {
        if (!field.trim()) {
            return false;
        }
    }
    return true;
};
