import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Text, Input, Button, Flex, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { allDeductables, createDeductibles } from '../dependencies/action'; // Assuming there's an update action
import { toast } from 'react-toastify';

const UpdateDeductables = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate=useNavigate()
    const [dedData, setDedData] = useState([]);
    console.log("dedData",typeof(dedData))
    const [formData, setFormData] = useState({
        id:id,
        amount: '',
        standardDiscount: '',
        enhancedDiscount: '',
        premiumDiscount: ''
    });

    useEffect(() => {
        // Fetch Deductibles data
        dispatch(allDeductables())
            .then((res) => {
                console.log("dedData response", res); // Check the structure of the response
                setDedData(res);
                // Find the deductible with the matching id
                const deductible = res.find(item => item.id === parseInt(id));
                // If deductible is found, update the formData state with its values
                if (deductible) {
                    setFormData({
                        id:id,
                        amount: deductible.amount ,
                        standardDiscount: deductible.standardDiscount,
                        enhancedDiscount: deductible.enhancedDiscount,
                        premiumDiscount: deductible.premiumDiscount
                    });
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, [dispatch, id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
   
  const handleSubmit = async () => {
    // Perform form validation here
    if (formData.amount.trim() === '' || formData.standardDiscount.trim() === '' || formData.enhancedDiscount.trim() === '' || formData.premiumDiscount.trim() === '' ) {
      toast({
        title: 'Error',
        description: 'All fields are required.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Dispatch action to post data
    const result = await dispatch(createDeductibles(formData));
    if (result.status === 201 || result.status === 200) {
      toast({
        title: 'Success',
        description: result.message || 'Deductibles created successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Error',
        description: result.message || 'Failed to create deductibles.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
    
  const handleBack = () => {
    navigate(-1);
  }
    
    
    return (
            <Sidebar headingText={'Edit Deductible'}>
                     <Button size={'xs'} background={'#9c27b0'} color='white' marginLeft={"50px"} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
                <Box p={'20px'} background={'white'} boxShadow='rgba(0, 0, 0, 0.24) 0px 3px 8px' w={'60%'} m={'auto'}>
                <Box>
                    <Text fontSize={'16px'} style={{ color: '#6c757d', fontFamily: 'poppins', paddingBottom: '10px' }}>Update Deductibles</Text>
                    <hr />
                    <Flex gap={'20px'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} mt={'25px'}>
                        <Box>
                            <Text mb={'8px'} >Amount</Text>
                            <Input size={'sm'} border={'1px solid grey'} name="amount" value={formData.amount} onChange={handleChange} placeholder='' />
                        </Box>
                        <Box>
                            <Text mb={'8px'}>Standard Discount</Text>
                            <Input border={'1px solid grey'} size={'sm'} name="standardDiscount" value={formData.standardDiscount} onChange={handleChange} placeholder='' />
                        </Box>
                    </Flex>
                    <Flex gap={'20px'} fontSize={'14px'} fontFamily={'Open Sans,sans-serif'} fontWeight={'600'} mt={'30px'}>
                        <Box>
                            <Text mb={'8px'}>Enhanced Discount</Text>
                            <Input border={'1px solid grey'} size={'sm'} name="enhancedDiscount" value={formData.enhancedDiscount} onChange={handleChange} placeholder='' />
                        </Box>
                        <Box>
                            <Text mb={'8px'}>Premium Discount</Text>
                            <Input border={'1px solid grey'} size={'sm'} name="premiumDiscount" value={formData.premiumDiscount} onChange={handleChange} placeholder='' />
                        </Box>
                    </Flex>
                </Box>
                <Flex justifyContent={'end'}>
                    <Button background={'#9124a3'} color={'white'} size={'sm'} onClick={handleSubmit} marginTop={'20px'} >Update</Button>
                </Flex>
                </Box>
            </Sidebar>

    )
}

export default UpdateDeductables;
