import {
  Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { buttonBackgroundColor} from "../../../Utils/UniversalColor";
import { FamilyModal, VTCDetailsModal, getMaxSelectableDate, getMinSelectableDate, validateEmail, validateMobileNumber } from "../../Advisor/AdvisorQuote/Validation";
import Description from "./Description";
import { useDispatch } from "react-redux";
import RadioOption from "../../../Utils/RadioCard";
import { advisorCalculateVtc, advisorGenerateQuote } from "../../Advisor/Dependencies/action";
import { useNavigate } from "react-router-dom";
import { ROLES } from "../../../constants/role";
import { storage } from "../../../dependencies/store/storage";
// import Api from "../../../dependencies/utils/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import '../../Advisor/AdvisorQuote/styles.css'
import HealthQuestions from "../../../Utils/HealthQuestions";
import { formatDatePicker } from "../../../Utils/DateFormate";


function TripDetails({ sendDataToParent }) {
  const toast = useToast();
  const [tripDetailsFirst, setTripDetailsFirst] = useState(true);
  const [tripDetailsSecond, setTripDetailsSecond] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState("0");
  const [checked, setChecked] = useState(false);
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [contactDetails,setContactDetails]=useState(false)
  const [data,setData]=useState([])
  const dispatch=useDispatch()
  const [policyLimit,setPolicyLimit]=useState('25000')
  const [dedecutibles,setDeductibles]=useState ('0')
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('1')
  const [PlanType,setPlanType]=useState('Standard')
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [message,setMessage]=useState('')
  const [duration,setDuration]=useState('')
  const [amount,setAmount]=useState(0)
  const [isQuestionerModalOpen, setIsQuestionerModalOpen] = useState(false);
  const navigate=useNavigate()
  const [dataToSend, setDataToSend] = useState('');
  const role = storage.getUserRole();
  const [questions,setQuestions]=useState([])
  const [isFamilyModel,setIsFamilyModel]=useState(false)
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);
 
    const [travelersError, setTravelersError] = useState([
      {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      },
    ]);
    const [formData, setFormData] = useState({
      firstDate: "",
      lastDate: "",
      departureDate: "",
      departureMaxDate: "",
    });
    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });

    const handlePolicyLimitChange = (value) => {
        setPolicyLimit(value);
        // handleModalSubmit()
    };
    const handleDeductiblesChange = (value) => { 
      setDeductibles(value);
      // handleModalSubmit()
    };
    const handlePlanTypeChange = (value) => { 
      setPlanType(value);
      // handleModalSubmit()
    };

    const isInitialRender = useRef(true);

    useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      handleModalSubmit();
    }, [PlanType, policyLimit, policyTypeSuperVisa, dedecutibles]);

    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
    
    const [errors, setErrors] = useState({
      firstDate: "Enter First Date cover",
      lastDate: "",
      departureDate: "",
    });

    const handleChange = (dateOrEvent, fieldName) => {
      let name, value;
      if (fieldName) {
        name = fieldName;
        value = dateOrEvent;
      } else {
        const event = dateOrEvent;
        name = event.target.name;
        value = event.target.value;
      }     
      setFormData({ ...formData, [name]: value });
      if(formData.lastDate && name ==="firstDate"){
        const endDate = new Date(formData.lastDate);
        const startDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData((prevData) => ({ ...prevData, lastDate: "" }));
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData((prevData) => ({ ...prevData, lastDate: "" }));
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
          setDuration(durationInDays);
        }
        console.log('ravi')
      }
      if (name === "lastDate") {
        if (!formData.firstDate) {
          alert("Please select the first date of cover first");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)+1);
          setDuration(durationInDays);
        }
      }
    };

  const startDate = new Date(formData.firstDate);
  let endDate = null;

  if (formData.firstDate) {
    endDate = new Date(startDate);
    endDate.setFullYear(startDate.getFullYear() + 1);
  }

  let durat = 0;
  if (endDate) {
    const diff = endDate.getTime() - startDate.getTime();
    durat = Math.ceil(diff / (1000 * 60 * 60 * 24));
  }
console.log(formData,'formData')
  const handleToTravellerDetails = () => {
    if(!formData.departureDate || !formData.firstDate || !formData.lastDate){
      alert('Please enter all details.')
      return;
    }
      setTripDetailsFirst(false);
      setTripDetailsSecond(true)
  };

  const handleToContactDetails = () =>{
    if(!selectedCostOption){
      alert("Please select costwithpre-existing or cost withoutpreexisting")
      return;
    }
      setOpenPlan(false)
      setContactDetails(true)
  }

  const handleTripDetailsBackEvent = () => {
    setTripDetailsSecond(false);
    setTripDetailsFirst(true)
  };

  const handlePlanBackEvent = () =>{
    setOpenPlan(false)
    setTripDetailsSecond(true)
  }

  const handleContactDetailsBackEvent = ( ) =>{
    setOpenPlan(true)
    setContactDetails(false)
  }

  const handleCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  const handleRemoveTraveller = (index) => {
    const updatedTravelers = [...addTravelers];
    updatedTravelers.splice(index, 1);
    setAddTravelers(updatedTravelers);
  };

  const handleVtcModalClose = () => {
    setIsVtcModalOpen(false);
  };

  useEffect(() => {
    if (!formData.departureDate) {
      setWaitingPeriod("NA");
    } else {
      const today = new Date(formData.firstDate);
      const thirtyDaysAgo = new Date(today);
      thirtyDaysAgo.setDate(today.getDate() - 30);
      const selectedDate = new Date(formData.departureDate);
      const firstDate = new Date(formData.firstDate)
      const isWithinLastThirtyDays = selectedDate >= thirtyDaysAgo && selectedDate < today;
      const isTodayOrFuture = selectedDate >= today;
    
      if (selectedDate>=firstDate) {
        setWaitingPeriod("NA");
      } else {
        setWaitingPeriod(isWithinLastThirtyDays ? "48 Hour" : "8 days");
      }
    }
  }, [formData.departureDate]);


  const handleAddTraveller = () => {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  
  };

  const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
    let name, value;
    if (fieldName) {
      name = fieldName;
      value = dateOrEvent;
    } else {
      const event = dateOrEvent;
      name = event.target.name;
      value = event.target.value;
    }
    const today = new Date();
    const selectedDate = new Date(value);
    const age = today.getFullYear() - selectedDate.getFullYear();
    if(name === "dob" || name === "benificiaryDob") {
      const dateValue = new Date(value);
        const today = new Date();
        const selectedDate = new Date(value);
        const minDate = new Date();
        minDate.setDate(minDate.getDate() - 15);
        if (selectedDate >= minDate) {
            alert("Users must be at least 15 days older than today.");
            return;
        }
        // const age = today.getFullYear() - selectedDate.getFullYear();
        // const monthDiff = today.getMonth() - selectedDate.getMonth();
        // const dayDiff = today.getDate() - selectedDate.getDate();

        if (age > 75 || (age === 75 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))) {
            setCurrentTravelerIndex(index);
            setCurrentDob(value);
            setIsQuestionerModalOpen(true);
            // return;
        }
        value = dateValue.toISOString().split('T')[0]; 
    }
    setAddTravelers((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = { ...updatedState[index], [name]: value };
        if (name === "dob") {
          const dob = new Date(value);
          const today = new Date();
          const age = today.getFullYear() - dob.getFullYear();
          updatedState[index] = {
              ...updatedState[index],
              age: age,
          };
      }
        return updatedState;
    });
  };

  const handleQuestionersSubmit = (collectedData) => {
    setAddTravelers(prevState => {
      console.log('Previous State:', prevState);
      const updatedState = [...prevState];
      if (currentTravelerIndex !== null && currentDob !== '') {
        updatedState[currentTravelerIndex] = {
          ...updatedState[currentTravelerIndex],
          dob: currentDob, // Ensure currentDob is not empty
        };
        console.log('Updated State:', updatedState);
      }
      return updatedState;
    });
    setQuestions(collectedData);
    setIsQuestionerModalOpen(false); 
  };
  const handleQuestionersClose = () => {
    setAddTravelers((prevState) => {
      if (prevState.length === 0) return prevState;
      const updatedState = [...prevState];
      const lastIndex = updatedState.length - 1;  
      updatedState[lastIndex] = {
        ...updatedState[lastIndex],
        dob: "",
      };
      return updatedState;
    });
  
    setIsQuestionerModalOpen(false); // Close the modal
  };

  const handleSubmitTravellerForm = () => {
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.name === "" ||
        traveler.gender === "" ||
        traveler.dob === "" ||
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      alert("Please fill in all mandatory details.");
      return;
    }
    if (checked) {
      if (addTravelers.length < 3) {
        alert("Select at least 3 members of the family.");
        return;
      }
      const isAllAgeGreaterThanOne = addTravelers.every((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1;
      });
      if (!isAllAgeGreaterThanOne) {
        alert("All family members must be older than one year.");
        return;
      }
      const eldestMember = addTravelers.reduce((eldest, traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        if (!eldest || age > eldest.age) {
          return { age, traveler };
        }
        return eldest;
      }, null);
  
      if (!eldestMember || eldestMember.age < 27 || eldestMember.age > 69) {
        alert("The eldest family member's age should be between 27 and 69 years for family coverage.");
        return;
      }
      const hasChildMember = addTravelers.some((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 1 && age <= 26;
      });
      if (!hasChildMember) {
        alert("You must select at least one child up to 21 or 26 if fully dependent on parents.");
        return;
      }
  
      // Check if there are no more than two family members older than 26 years
      const olderThan26Count = addTravelers.reduce((count, traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        if (age > 26) {
          return count + 1;
        }
        return count;
      }, 0);
      if (olderThan26Count > 2) {
        alert("There should not be more than two family members whose age is more than 26 years.");
        return;
      }
      const dependentChildren = addTravelers.filter((traveler) => {
        const dob = new Date(traveler.dob);
        const age = new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970;
        return age >= 21 && age <= 26;
      });
      if (dependentChildren.length > 0 && olderThan26Count<=2) {
        if (checked === false) {
          setIsFamilyModel(true);
        } else {
          console.log('Already selected');
        }
      }
    }
    setIsVtcModalOpen(true);
  };

  const handleFamilyModel = ()=>{
    setIsFamilyModel(false)
    // setFamilyCoverage(false)
  }

  const handleModalSubmit = async () => {
    setSelectedCostOption('')
    setTripDetailsSecond(false)
    setOpenPlan(true);
        const updatedTravelers = addTravelers.map((traveler) => ({
          insuredName: traveler.name,
          insuredGender: traveler.gender,
          insuredDOB: traveler.dob,
          insuredRelation: traveler.relation,
          beneficiaryName: traveler.benificiaryName,
          beneficiaryDob: traveler.benificiaryDob,
        }));
      const dataToLog = {
          firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
          lastDateOfCover: formatDatePicker(new Date(formData.lastDate)),
          deductible:parseInt(dedecutibles),
          policyLimit:parseInt(policyLimit),
          planType:PlanType,
          departureDate: formatDatePicker(new Date(formData.departureDate)),
          insuredDetailsRequests: updatedTravelers,
          policyType:'VTC',
          familyPolicy:checked,
      };
        console.log(dataToLog,"vtc submit data")
        dispatch(advisorCalculateVtc(dataToLog)).then(res => {
          const {status, data} = res
          if(status===200 || status===201){
            setData(data)
              updateAddTravelers(data.listOfInsuredQutation)
            }else{
              setData([])
            }
        })
  };

  const updateAddTravelers = (response) => {
    console.log(response, 'response');
    setAddTravelers(prevAddTravelers => {
      const updatedTravelers = [...prevAddTravelers];
      response.forEach((responseTraveler, index) => {
        if (index < updatedTravelers.length) {
          updatedTravelers[index] = {
            ...updatedTravelers[index],
            costWithPreExisting: responseTraveler.costWithPreExisting,
            costWithoutPreExisting: responseTraveler.costWithoutPreExisting
          };
        }
      });
      return updatedTravelers;
    });
  };

  const handleCostOptionChange = (index, value, ele) => {
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [index]: value
    }));
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
    const totalValue = totalSelectedValue + value;
    const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
    const selectedKey = (value === ele.costWithPreExisting) ? '2' : '3';
    const message = selectedOption === 'withPreExisting' ? data.messageOfPreExisting : data.messageOfWithoutPreExisting;
    setMessage(message);
    setAmount(totalValue);
    setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
      if (idx === index) {
        return { ...traveler, selectedKey };
      }
      return traveler;
    }));
  };
  
  const handleGetQuote = () =>{
    if(policyHolder.policyOwnerName==="" || policyHolder.policyOwnerAddress==="" || policyHolder.policyOwnerCity===""|| policyHolder.policyOwnerDob==="" || policyHolder.policyOwnerEmail==""){
      alert("Please enter mandatory fields.")
      return;
    }
    if (policyHolder.policyOwnerEmail === 0 || !validateEmail(policyHolder.policyOwnerEmail)){
      alert('Please enter a valid email');
      return;
    }
    if(policyHolder.policyOwnerPhone === 0 || !validateMobileNumber(policyHolder.policyOwnerPhone)){
      alert("Please enter a valid mobile.")
      return;
    }
    const updatedTravelers = addTravelers.map((traveler) => ({
      nameOfInsured: traveler.name,
      gender: traveler.gender,
      dateOfBirth: traveler.dob,
      beneficiaryRelationToInsured: traveler.relation,
      beneficiaryName: traveler.benificiaryName,
      beneficiaryDOB: traveler.benificiaryDob,
      costWithPreExisting:traveler.costWithPreExisting,
      costWithoutPreExisting:traveler.costWithoutPreExisting,
      selectedKey:traveler.selectedKey,
    }));
    const dataToSend = {
      email: policyHolder.policyOwnerEmail,
      firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
      lastDateOfCover:formatDatePicker(new Date(formData.lastDate)),
      policyEndDate: formatDatePicker(new Date(formData.lastDate)),
      duration:parseInt(duration),
      country:1,
      province:'Ontario',
      departureFromCountryOfOrigin:formatDatePicker(new Date(formData.departureDate)),
      waitingPeriod:waitingPeriod,
      policyLimit:policyLimit,
      deductible:dedecutibles,
      paymentFrequency:0,
      policyOwner:policyHolder.policyOwnerName,
      policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
      policyOwnerEmail:policyHolder.policyOwnerEmail,
      policyOwnerAddress:policyHolder.policyOwnerAddress,
      policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
      policyOwnerCity:policyHolder.policyOwnerCity,
      policyOwnerPhone:policyHolder.policyOwnerPhone,
      planType:PlanType,
      insuredTravellerDetailsRequest: updatedTravelers,
      policyType:'VTC',
      amount: amount,
      healthQuestions:questions,
      familyCoverage:checked===true?1:0
  };
      console.log(dataToSend,'dataTo Send')
      if(role === ROLES.CUSTOMER){
        dispatch(advisorGenerateQuote(dataToSend)).then(res => {
        const {status, data} = res
        if(status===200 || status===201){
          console.log(data,'data')
          alert('Quote Generated Successfully')
          navigate(`/customer-view-quote/${data.quotationNo}`)
        }else{
          alert("something went wrong")
        }
      })
      }else{
        Api.POST(CUSTOMER_GENERATE_QUOTE,dataToSend).then((res)=>{
          console.log(res)
          if(res.status===200 || res.status===201){
            storage.setUser(res.data)
            navigate("/customer-dashboard");
          }else{
            navigate('/customerLogin')
            alert('Please login First.')
            console.log('Somthing went worng.')
          }
        })
      }
      // dispatch(advisorGenerateQuote(dataToSend)).then(res => {
      //   const {status, data} = res
      //   if(status===200 || status===201){
      //     alert("Quote generated successfully")
      //     navigate(`/customer-view-quote/${data.quotationNo}`)
      //     // const tabNumber=1
      //     // sendDataToParent({'amount':amount,'data':data,'tab':tabNumber});
      //   }else{
      //     alert("something went wrong")
      //   }
      //   // const {status, data} = res
      //   // console.log(data,'data')
      //   // navigate(`/customer-view-quote/${data.quotationNo}`)
      //   // const tabNumber=1
      //   // sendDataToParent({'amount':amount,'data':data,'tab':tabNumber});
      // })
  }

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 180); // Add 180 days
    return today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
  };

  const datePickerRefs = {
    firstDate: useRef(null),
    lastDate: useRef(null),
    departure: useRef(null),
    studentDeparture: useRef(null),
  };
  const handleDatePickerIconClick = (pickerRef) => {
    console.log('hit',pickerRef)
    if (pickerRef.current) {
      pickerRef.current.setOpen(true); // Open the DatePicker
    }
  };

return (
    <Box padding={['2px','2px','10px']} style={{ width: "100%",paddingTop:'0px',textAlign:'left'}}>
        <Text marginLeft={['10px','40px','40px']} fontFamily={'poppins'} fontWeight={'700'} mb={'15px'}>Visitor Insurance</Text>
      <Box w={['99%','99%','90%']} style={{ display: "flex",flexDirection: "column",alignItems: "center",margin:'auto'}}>

        {tripDetailsFirst && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: 400, marginBottom: "20px",borderBottom: "2px solid #088183"}}>Trip Details</p>
            <div
              style={{
                gap: "20px",
                margin: "auto",
                padding: "18px",
              }}
            >
            <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'13px'} pb={'20px'}>
              <Box w={['95%','auto','25%']}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <div className="date-picker-container">
                    <DatePicker
                        selected={formData.firstDate}
                        onChange={date => handleChange(date, 'firstDate')}                                
                        dateFormat="yyyy-MM-dd"
                        className="custom-date-picker"
                        placeholderText='yyyy-mm-dd'
                        minDate={new Date().toISOString().split("T")[0]}
                        maxDate={getMaxDate()}
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        ref={datePickerRefs.firstDate}
                    />
                    <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.firstDate)} />
                  </div>
              </Box>
              <Box w={['95%','auto','25%']}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <div className="date-picker-container">
                    <DatePicker
                        selected={formData.lastDate}
                        onChange={date => handleChange(date, 'lastDate')}                                
                        dateFormat="yyyy-MM-dd"
                        className="custom-date-picker"
                        placeholderText='yyyy-mm-dd'
                        minDate={formData.firstDate}
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        ref={datePickerRefs.lastDate}
                    />
                    <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.lastDate)} />
                  </div>
              </Box>
              <Flex direction={'column'} w={['95%','auto','25%']} >
                <span>Duration</span>
                <Input w={'80px'} size='xs' backgroundColor={'#eaeaea'} value={`${duration} days`} border={'1px solid #d7d7d7'} />
              </Flex>
              <Box w={['90%','auto','25%']}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
              </Box>
              </Flex>

              <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'13px'}>
              <Box w={['95%','auto','30%']}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <div className="date-picker-container">
                  <DatePicker
                      selected={formData.departureDate}
                      onChange={date => handleChange(date, 'departureDate')}                                
                      dateFormat="yyyy-MM-dd"
                      className="custom-date-picker"
                      placeholderText='yyyy-mm-dd'
                      maxDate={formData.firstDate}
                      showYearDropdown
                      showMonthDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      ref={datePickerRefs.departure}
                  />
                  <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.departure)} />
                </div>
              </Box>
              <Box w={['95%','auto','25%']}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                  placeholder="Basic usage"
                  name="waiting"
                  value={waitingPeriod}
                />
              </Box>
              <Box >
                <Text>Family Coverage</Text>
              <Checkbox pt={'7px'} isChecked={checked} onChange={handleCheckbox} size="md" colorScheme="green"></Checkbox>
              </Box>
              </Flex>
              <Button size={'sm'} mt={'25px'}
                onClick={handleToTravellerDetails}
                style={{
                  gridColumn: "1 / span 1",
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        )}

        {tripDetailsSecond && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "20px",
                borderBottom: "2px solid #088183",
              }}
            >
              Traveller Details family
            </p>
            <Flex fontFamily={'poppins'} fontSize={'14px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'5px'} mb={'5px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add Traveller</Text>
              </Flex>
            {addTravelers.map((item, index) => {
              return (
                <>
                <Flex fontSize='13px' flexDirection={['column','column','row']} gap={['10px','20px','40px']} margin={'auto'} padding={'10px'} key={index}>
                      <Box w={['90%','auto','25%']} >
                        <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                        <Input name="name" value={item.name}
                          onChange={(e) => handleChangeTraveler(index, e)} size='sm' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                      </Box>
                      <Box w={['90%','auto','25%']}>
                        <span style={{ marginBottom: "8px" }}>Gender</span>
                        <Select background={'white'} size={'sm'} border={'1px solid #d7d7d7'}
                          value={item.gender}
                          name="gender"
                          onChange={(e) => handleChangeTraveler(index, e)}>
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Select>
    
                      </Box>
                      <Box w={['90%','auto','25%']}>
                      <Flex justifyContent={'space-between'}>
                          <Text fontWeight={'600'} fontSize={'11px'}>Date of Birth*</Text>
                          <Text fontWeight={'600'} name='age' color={'teal'}>{item.age} yrs</Text>
                        </Flex>
                        <div className="date-picker-container">
                                  <DatePicker
                                    selected={item.dob}
                                    onChange={(date) => handleChangeTraveler(index, date,'dob')}
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-date-picker"
                                    maxDate={getMaxSelectableDate()}
                                    minDate={getMinSelectableDate()} 
                                    placeholderText='yyyy-mm-dd'
                                    showYearDropdown
                                    showMonthDropdown
                                    yearDropdownItemNumber={15}
                                    scrollableYearDropdown
                                  />
                                  <FaCalendarAlt className="date-icon" />
                                </div>
                        {/* <span style={{ marginBottom: "8px" }}>Date of Birth</span>
                        <Input name="dob"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.dob}
                          w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} /> */}
                      </Box>
                  </Flex>

                  <Flex fontSize='13px' flexDirection={['column','column','row']} gap={['10px','20px','40px']} p={'10px'} pt={'5px'} pb={'20px'}>
                      <Box w={['90%','auto','25%']}>
                        <span style={{ marginBottom: "8px" }}>
                          Beneficiary Name
                        </span>
                        <Input name="benificiaryName" placeholder="Name"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.benificiaryName} size='sm' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','auto','25%']}>
                        <span style={{ marginBottom: "8px" }}>Relationship to Insured</span>
                        <Input name="relation"
                          onChange={(e) => handleChangeTraveler(index, e)}
                          value={item.relation}
                          placeholder="Relation" size='sm' border={'1px solid #d7d7d7'} />
                      </Box>
                      <Box w={['90%','auto','25%']}>
                        <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                        <div className="date-picker-container">
                              <DatePicker
                                selected={item.benificiaryDob}
                                onChange={(date) => handleChangeTraveler(index, date,'benificiaryDob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={new Date().toISOString().split('T')[0]}
                                placeholderText='yyyy-mm-dd'
                              />
                              <FaCalendarAlt className="date-icon" />
                            </div>
                        {/* <Input onChange={(e) => handleChangeTraveler(index, e)}
                          name="benificiaryDob"
                          value={item.benificiaryDob}
                          placeholder="Select Date and Time"
                          w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} /> */}
                      
                      </Box>
                    {index > 0 && (
                    <Flex justifyContent={'end'}>
                            <IoMdCloseCircle
                              style={{fontSize:'22px',color:buttonBackgroundColor}}
                              onClick={() => handleRemoveTraveller(index)}
                              />
                          </Flex>
                        )}
                    </Flex>
                   </>
              );
            })}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: "8px",
              }}
            >
              <button
                onClick={handleTripDetailsBackEvent}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Previous
              </button>
              <button
                onClick={handleSubmitTravellerForm}
                style={{
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed & Confirm
              </button>
            </div>
          </div>
        )}

        {openPlan === true && (
        <Box w={['100%','100%','95%']} padding={['10px','5px','10px']} style={{ margin: "auto", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
          <p
            style={{
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: 400,
              marginBottom: "20px",
              borderBottom: "3px solid #088183",
            }}
          >
            Select Plan
          </p>
             <Flex flexDirection={['column','column','row']} gap={['10px','20px','20px']} mt={'15px'} mb={'10px'}>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Policy Limit</Text>
                    <Flex direction={['column','column','row']} gap={'10px'}>
                         <Flex gap={[2,2,4]}>
                          <RadioOption value='25000' isSelected={policyLimit === '25000'} onChange={handlePolicyLimitChange}>
                          $25000
                          </RadioOption>
                          <RadioOption value='50000' isSelected={policyLimit === '50000'} onChange={handlePolicyLimitChange}>
                          $50000
                          </RadioOption>
                          <RadioOption value='100000' isSelected={policyLimit === '100000'} onChange={handlePolicyLimitChange}>
                          $100000
                          </RadioOption>
                          </Flex>
                          <Flex gap={[2,2,4]} mt={['10px','10px','0px']}>
                          <RadioOption value='150000' isSelected={policyLimit === '150000'} onChange={handlePolicyLimitChange}>
                          $150000
                          </RadioOption>
                          <RadioOption value='300000' isSelected={policyLimit === '300000'} onChange={handlePolicyLimitChange}>
                          $300000
                          </RadioOption>
                          </Flex>
                    </Flex>
                  </Box>
                  <Box>
                    <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Select Plan Type</Text>
                    <Stack spacing={[2,2,4]} direction='row'>
                      <RadioOption value='Standard' isSelected={PlanType === 'Standard'} onChange={handlePlanTypeChange}>
                        Standard</RadioOption>
                      <RadioOption value='Enhanced' isSelected={PlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                        Enhanced</RadioOption>
                      <RadioOption value='Premium' isSelected={PlanType === 'Premium'} onChange={handlePlanTypeChange}>
                       Premium </RadioOption>
                    </Stack>
                  </Box>
                  </Flex>
                  
                  {
                    PlanType === 'Premium'? 
                    <Box mb={'30px'}>
                    <Text fontSize={'14px'} >Deductible</Text>
                    <Stack spacing={[2,2,4]} direction='row'>
                      <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                      $500</RadioOption>
                      <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                      $1000</RadioOption>
                      <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                      $3000</RadioOption>
                    </Stack>
                  </Box>:
                      <Box mb={'30px'}>
                      <Text fontSize={'14px'} >Deductible</Text>
                      <Flex direction={['column','column','row']} gap={'10px'}>
                      <Stack spacing={[2,2,4]} direction='row'>
                        <RadioOption value='0' isSelected={dedecutibles === '0'} onChange={handleDeductiblesChange}>
                        $0</RadioOption>
                        <RadioOption value='100' isSelected={dedecutibles === '100'} onChange={handleDeductiblesChange}>
                        $100</RadioOption>
                        <RadioOption value='250' isSelected={dedecutibles === '250'} onChange={handleDeductiblesChange}>
                        $250 </RadioOption>
                        <RadioOption value='500' isSelected={dedecutibles === '500'} onChange={handleDeductiblesChange}>
                        $500</RadioOption>
                        </Stack>
                        <Stack spacing={[2,2,4]} direction='row' mt={['10px','10px','0px']}>
                        <RadioOption value='1000' isSelected={dedecutibles === '1000'} onChange={handleDeductiblesChange}>
                        $1000</RadioOption>
                        <RadioOption value='3000' isSelected={dedecutibles === '3000'} onChange={handleDeductiblesChange}>
                        $3000</RadioOption>
                      </Stack>
                      </Flex>
                    </Box>
                  }

        
        <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>
                      User Name
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      Plan Name
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      With Pre-existing
                    </Th>
                    <Th p={'7px'} fontSize={'10px'}>
                      Without Pre-existing
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                {data?.listOfInsuredQutation?.map((ele, index) => (
                  <>
                    <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                      <Td p={'2px'}>{ele.name}</Td>
                      <Td p={'2px'}>{ele.planType}</Td>
                      <Td p={'2px'} display={'flex'}>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costWithPreExisting}
                          disabled={ele.costWithPreExisting===0}
                          checked={selectedCostOption[index] === ele.costWithPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                        />
                        <Text pl={'10px'}>
                        {Math.round(ele.costWithPreExisting  * 100) / 100}
                        </Text>
                      </Td>
                      <Td p={'2px'}>
                        <Flex>
                        <input
                          type="radio"
                          name={`costOption-${index}`}
                          value={ele.costWithoutPreExisting}
                          disabled={ele.costWithoutPreExisting===0}
                          checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                          onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                          />
                        <Text pl={'10px'}>
                        {Math.round(ele.costWithoutPreExisting  * 100) / 100}
                        </Text>
                          </Flex>
                      </Td>
                    </Tr>
                    </>
                ))}
                </Tbody>
              </Table>
            </TableContainer>
                  
          <Text color={'red'} fontSize={'11px'}>{message}</Text>
          <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            <Button onClick={handlePlanBackEvent} style={{ backgroundColor: "red", color: "white", padding: "4px 10px", fontFamily: "poppins",}}>
              Previous
            </Button>
            <Button
              onClick={handleToContactDetails}
              style={{
                backgroundColor: "rgb(36, 182, 183)",
                color: "white",
                padding: "4px 10px",
                fontFamily: "poppins",
              }}
            >
              Proceed
            </Button>
          </div>
        </Box>
        )}

        { contactDetails === true &&(
            <Box w={['100%','100%','95%']} padding={['15px','5px','18px']} style={{ margin: "auto",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
            <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'14px'} pb={'5px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                <option value="">Select Policy Owner</option>
                {addTravelers.map((traveler, index) => (
                  <option key={index} value={traveler.name}>
                    {traveler.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input readOnly type='date' size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
              <Text color="red.500">{errors.policyOwnerEmail}</Text>        
            </Box>
            </Flex>
            <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'14px'} pb={'20px'} mt={['1px','20px','20px']}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
              <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
              <Text color="red.500">{errors.policyOwnerCity}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
              <Text color="red.500">{errors.policyOwnerPhone}</Text>
            </Box>
            </Flex>
            <Box pb='30px'>
              <Text>Policy Owner Address*</Text>
              <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
              <Text color="red.500">{errors.policyOwnerAddress}</Text>       
            </Box>
            <Flex gap='40px'>
            <button onClick={handleContactDetailsBackEvent} style={{ backgroundColor: "red", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Previous</button>
            <button onClick={handleGetQuote} style={{ backgroundColor: "#24b6b7", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Get Quote</button>
            </Flex></Box>
        )}
        <Description/>
      </Box>
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleModalSubmit} />
      <HealthQuestions isOpen={isQuestionerModalOpen} onClose={handleQuestionersClose} onSubmit={handleQuestionersSubmit}/>
      <FamilyModal isOpen={isFamilyModel} onClose={handleFamilyModel} />
    </Box>
  );
}

export default TripDetails;
