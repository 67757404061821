import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Button,
  FormLabel,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tabs,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Api from "../../../dependencies/utils/Api";
import { PARTNER_LOGIN } from "../../../constants/ApplicationUrl";
import { storage } from "../../../dependencies/store/storage";

const ServiceUserLogin = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [values, setValues] = useState({
      username: "",
      password: "",
    });
    const [errors, setErrors] = useState({
        username: "",
        password: "",
      });
    const handleClick = () => setShow(!show);

    const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
    };

    const handleWhiteLabelLogin = () =>{
        console.log(values,'dataaa')
        Api.POST(`${PARTNER_LOGIN}`,values).then((res)=>{
          console.log(res,'data')
          const {status,data}=res
          if(status===200){
            alert("User Logged In Successfully")
            storage.setUser(data)
            navigate(`/service/dashboard`)
          }
        })
    }

    const handlePartnerLogin =()=>{
      console.log(values,'partner data')
    }



return (
    <Box m="auto" background={'#ecf1ff'} h={'100vh'}>
    <Flex direction="column" pt="120px" alignItems={'center'}>
          {/* <Image
            w={"130px"}
            src={"https://travelmedicare.com/public/users/images/logo.png"}
          /> */}
      <Box borderRadius={'10px'} boxShadow='rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px' background={'white'} p={5} w={["99%", "95%", "40%", "40%"]} pt={"40px"}textAlign={"center"}>
      
<Tabs variant="soft-rounded" colorScheme="teal">
          <TabList>
            <Tab>White Labeled</Tab>
            <Tab>Partners</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
        <Box>
        <FormControl isRequired w={["80%"]} m={"auto"}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Text
                  textAlign={"left"}
                  fontSize={"14px"}
                  color={"grey"}
                  mt={"5px"}
                >
                  Forgot Password?
                </Text>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handleWhiteLabelLogin}
                  color={"#2acb55"}
                  backgroundColor={"#e3feff"}
                >
                Login
                </Button>
              </FormControl>
        </Box>
              
            </TabPanel>
            <TabPanel>
              <FormControl isRequired w={["80%"]} m={"auto"}>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  placeholder="Enter email"
                  name="username"
                  onChange={handleChange}
                />
                <Text color="red.500">{errors.username}</Text>
                <FormLabel pt={"10px"}>Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    name="password"
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Text color="red.500">{errors.password}</Text>
                <Text
                  textAlign={"left"}
                  fontSize={"14px"}
                  color={"grey"}
                  mt={"5px"}
                >
                  Forgot Password?
                </Text>
                <Button
                  mt={4}
                  width="100%"
                  onClick={handlePartnerLogin}
                  color={"#2acb55"}
                  backgroundColor={"#e3feff"}
                >
                Login
                </Button>
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>
        
      </Box>
    </Flex>
  </Box>
  )
}

export default ServiceUserLogin
