import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button, Checkbox, Toast } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Sidebar from '../../Sidebar';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { adminRenewalListAll } from './dependencies/action';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../../../../constants/ApplicationUrl';

const RenewalPolicies = () => {
  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedPolicies, setSelectedPolicies] = useState([]);

  const pagination = true;
  const paginationPageSizeSelector = [2, 5, 1000];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(adminRenewalListAll())
      .then((res) => {
        console.log("Fetched data:", res);
          const formattedData = res.map((quote, index) => {
            let status;
            switch (quote.status) {
              case 1:
                status = 'Active';
                break;
              case 2:
                status = 'Pending Cancellation';
                break;
              case 3:
                status = 'Cancelled';
                break;
              case 4:
                status = 'Pending Void';
                break;
              case 5:
                status = 'Void';
                break;
              case 6:
                status = 'Policy Change Pending';
                break;
              case 7:
                status = 'Matured';
                break;
              default:
                status = 'Inactive';
            }
            let tripType;
            if (quote.quote.product === 'Vistors To Canada') {
              tripType = 'Visitor To Canada';
            } else if (quote.quote.product === 'Student To Canada') {
              tripType = 'Student To Canada';
            } else if (quote.quote.superVisa === 1) {
              tripType = quote.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
            } else {
              tripType = 'Single Trip';
            }
            return {
              employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
                  designation: quote.quote.user.roles.name,
                  product: quote.quote.product,
                  policyNo: quote.policyNo,
                  dateOfIssue: quote.createdAt,
                  customerName: quote.quote.policyHolderName,
                  tripType: tripType,
                  policyAmount: quote.quote.quoteAmount,
                  status:status,
            };
          });
          setRowData(formattedData);
          // setRowData(transformedData);
          setFilteredData(formattedData); // Initialize filteredData with transformedData
        // console.log("Transformed data:", transformedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [dispatch]);

  // const transformData = (res) => {
  //   let status;
  //         switch (res.status) {
  //           case 1:
  //             status = 'Active';
  //             break;
  //           case 2:
  //             status = 'Pending Cancellation';
  //             break;
  //           case 3:
  //             status = 'Cancelled';
  //             break;
  //           case 4:
  //             status = 'Pending Void';
  //             break;
  //           case 5:
  //             status = 'Void';
  //             break;
  //           case 6:
  //             status = 'Policy Change Pending';
  //             break;
  //           case 7:
  //             status = 'Matured';
  //             break;
  //           default:
  //             status = 'Inactive';
  //         }

  //         let tripType;
  //         if (res.quote.product === 'Vistors To Canada') {
  //           tripType = 'Visitor To Canada';
  //         } else if (res.quote.product === 'Student To Canada') {
  //           tripType = 'Student To Canada';
  //         } else if (res.quote.superVisa === 1) {
  //           tripType = res.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
  //         } else {
  //           tripType = 'Single Trip';
  //         }
  //   return res.map(item => ({
  //     employeeName: `${item.quote.user.firstName} ${item.quote.user.lastName}`,
  //     designation: item.quote.user.roles.name,
  //     product: item.quote.product,
  //     policyNo: item.policyNo,
  //     dateOfIssue: item.createdAt,
  //     customerName: item.quote.policyHolderName,
  //     tripType: tripType,
  //     policyAmount: item.quote.quoteAmount,
  //     status:status,
  //   }));
  // };

  // Filter data based on search term
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredData(rowData); // Reset to original data if search term is empty
    } else {
      const filtered = rowData.filter(item =>
        item.employeeName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
    }
  }, [searchTerm, rowData]);

  // Filter data based on selected product and role
  useEffect(() => {
    let filtered = [...rowData];
    if (selectedProduct !== '') {
      filtered = filtered.filter(item => item.product === selectedProduct);
    }
    if (selectedRole !== '') {
      filtered = filtered.filter(item => item.designation === selectedRole);
    }
    setFilteredData(filtered);
  }, [selectedProduct, selectedRole, rowData]);

  const handleCheckboxChange = (policyNo) => {
    setSelectedPolicies(prev => {
      if (prev.includes(policyNo)) {
        return prev.filter(no => no !== policyNo);
      } else {
        return [...prev, policyNo];
      }
    });
  };
  const handleAction = (params) => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }}>
        <input
        type='checkbox'
          onChange={() => handleCheckboxChange(params.data.policyNo)}
          checked={selectedPolicies.includes(params.data.policyNo)}
        />
      </div>
    );
  };
  

  const colDefs = [
    { field: "employeeName", headerName: "Employee Name", width: 150, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, },
    { field: "designation", headerName: "Designation", width: 150, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, },
    { field: "product", headerName: "Product", width: 150, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "policyNo", headerName: "Policy No", width: 180, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, },
    { field: "dateOfIssue", headerName: "Date of Issue", width: 210, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "customerName", headerName: "Customer Name", width: 150, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "tripType", headerName: "Trip Type", width: 150, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "policyAmount", headerName: "Policy Amount", width: 175, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "status", headerName: "Status", width: 90, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true },
    { field: "Action", headerName: "Action", cellRenderer: handleAction, width: 80, wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true},
  ];

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.setFontSize(12);

    // Title
    doc.text("Renewal Policies List", doc.internal.pageSize.width / 2, 20, { align: 'center' });

    // Table headers
    const headers = colDefs.map(col => col.headerName);
    const data = filteredData.map(row => colDefs.map(col => row[col.field]));

    doc.autoTable({
      startY: 40,
      head: [headers],
      body: data,
      theme: 'grid',
      margin: { top: 10 },
      styles: { overflow: 'linebreak', fontSize: 10, cellPadding: 2 },
    });

    doc.save('renewal_policies.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Renewal Policies');
    XLSX.writeFile(workbook, 'renewal_policies.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(filteredData[0]).join(','), // Header row
      ...filteredData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'renewal_policies.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const sendReminderEmails = () => {
    const { token } = JSON.parse(localStorage.getItem("code_dev"));
  
    selectedPolicies.forEach(policyNo => {
      axios.post(
        `${API_URL()}/policy/sendNotificationOfPolicyRenewal?policyNo=${policyNo}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(response => {
        if (response.status === 200 || response.status === 201) {
          console.log(`Reminder email sent for policy number: ${policyNo}`);
          toast(`Reminder email sent for policy number: ${policyNo}`);
          setSelectedPolicies(prev => prev.filter(p => p !== policyNo));
        } else {
          console.warn(`Unexpected status code ${response.status} for policy number: ${policyNo}`);
          toast.error(`Error sending reminder email for policy number: ${policyNo}`);
        }
      })
      .catch(error => {
        console.error(`Error sending reminder email for policy number: ${policyNo}`, error);
        toast.error(`Error sending reminder email for policy number: ${policyNo}`);
      });
    });
  };
  
  
  return (
    <Sidebar headingText={'Renewal Policies List'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex>
          <Box>
            <label htmlFor="product-select"  style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Product</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="product-select" onChange={(e) => setSelectedProduct(e.target.value)}>
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="Student Travel">Student Travel</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="role-select"  style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Role</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="role-select" onChange={(e) => setSelectedRole(e.target.value)}>
              <option value="">Select Role</option>
              <option value="AGA">AGA</option>
              <option value="MGA">MGA</option>
              <option value="ADVISOR">ADVISOR</option>
            </select>
          </Box>
          <Box>
            <label htmlFor="user-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
            <select style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} id="user-select"  onChange={(e) => setSearchTerm(e.target.value)}>
              <option value="">Select Users</option>
              {rowData.map((elem) => (
                <option value={elem.employeeName}>{elem.employeeName}</option>
              ))}
            </select>
          </Box>
        </Flex>
        <br />
      </div>

      <Box w={'95%'} m={'auto'} background={'white'} p={'10px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px" }}>
            <Button size='sm' onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' onClick={downloadExcel}>Excel</Button>
            <Button size='sm' onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}  value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} type="text" id="search" placeholder="Search by name" />
          </div>
        </Flex>
        <div className="ag-theme-quartz" style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}>
          <AgGridReact
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </Box>
      <Flex w={'95%'} m={'auto'} mt={'10px'} justifyContent={'end'} mb={'50px'}>
      <Button background={'teal'} onClick={sendReminderEmails}>Send Reminder Email</Button>
      </Flex>
    </Sidebar>
  );
};

export default RenewalPolicies;
