import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { Box, Button, Flex, Input } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { adminCommisionSettingsList, adminCommisionSettingsEdit } from '../dependencies/action';
import { toast } from 'react-toastify';
import { isLoading } from '../../../../constants/actionConstants';
import axios from 'axios'; // Import Axios
import Api from '../../../../dependencies/utils/Api';
import { API_URL } from '../../../../constants/ApplicationUrl';

const CommisionSettings = () => {
    const [settingsData, setSettingsData] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(adminCommisionSettingsList())
            .then((res) => {
                setSettingsData(res); // Assuming res.data contains the fetched settings
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                toast.error("Error fetching settings");
            });
    }, [dispatch]);

    // Function to handle saving changes
    const saveChanges = (id, commissionPercentage) => {
        // Make sure to replace 'YOUR_API_BASE_URL' with your actual base URL
        const apiUrl = `${API_URL()}/CommissionSettings/${id}`;
        
        // Parse commissionPercentage as a number
        const parsedCommissionPercentage = parseFloat(commissionPercentage);
        
        // Send PUT request with updated data
        Api.PUT(apiUrl, { id, commissionPercentage: parsedCommissionPercentage })
            .then(response => {
                // Handle success
                console.log("Settings updated successfully:", response.data);
                toast.success("Settings updated successfully");
            })
            .catch(error => {
                // Handle error
                console.error("Error updating settings:", error);
                toast.error("Error updating settings");
            });
    };
    return (
        <div>
            <Sidebar headingText={'Commission Settings'}>
    <Box w={"60%"} background={'white'} marginLeft={"20px"} padding={'50px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
{settingsData.map((setting) => (
    <Box key={setting.id}
    >
        <label>Commission %</label>
        <Input border={'1px solid grey'}
            defaultValue={setting.commissionPercentage}
            onBlur={(e) => saveChanges(setting.id, e.target.value)}
        />
        <br />
    </Box>
))}
<Flex justifyContent='end' pt={'50px'}>
                    <Button background={'#9c27b0'} color={'white'} _hover={{color:'white'}}>
                        Save All
                    </Button>
</Flex>
                </Box>
            </Sidebar>
        </div>
    );
};

export default CommisionSettings;
