import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Button, Flex, Grid, GridItem, Text, Textarea, Select, useToast } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { supporTicketAllById } from '../../../Tickets/dependencies/action';
import { useDispatch } from 'react-redux';
import Api from '../../../../dependencies/utils/Api';
import { API_URL, SUPPORT_REPLY_MESSAGE } from '../../../../constants/ApplicationUrl';

const TICKET_OPEN_STATUS = 1;
const TICKET_PENDING_STATUS = 2;
const TICKET_CLOSE_STATUS = 3;

const AdminViewSingleTicket = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [data, setData] = useState([]);
    const { role } = JSON.parse(localStorage.getItem("code_dev"))
    const [ticketStatus, setTicketStatus] = useState('')
    const [reply, setReply] = useState([]);
    const [formData, setFormData] = useState({
        description: '',
        files: [],
        status: TICKET_OPEN_STATUS,
    });
    const toast = useToast();
    const { email } = JSON.parse(localStorage.getItem('code_dev'));

    useEffect(() => {
        getTicketDetails();
    }, [dispatch, id]);

    const getTicketDetails = () => {
        if (id) {
            dispatch(supporTicketAllById(id))
                .then((response) => {
                    setData(response.data);
                    setTicketStatus(response.data.status)
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        status: response.data.status,
                    }));
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: files ? files : value,
        }));
    };

    const handleSubmit = () => {
        const { description, files, status } = formData;
        const formDataToSend = new FormData();
        formDataToSend.append('description', description);
        formDataToSend.append('status', status);
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formDataToSend.append('file', files[i]);
            }
        }
        const { id } = JSON.parse(localStorage.getItem('code_dev'));
        formDataToSend.append('userId', id);
        const id1 = data.id;
        Api.POSTDATA(`${SUPPORT_REPLY_MESSAGE}/${id1}`, formDataToSend)
            .then((res) => {
                setReply(res);
                setFormData({ description: '', files: [], status: TICKET_OPEN_STATUS });
                getTicketDetails();
            })
            .catch((error) => {
                console.error('Error posting data:', error);
            });
    };

    const handleFileChange = (event) => {
        setFormData({ ...formData, files: event.target.files });
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleDownload = (filename) => {
        const url = `${API_URL()}/file/downloadFileOfTicket/${filename}`;
        window.open(url, '_blank');
    };
    

    return (
        <Sidebar headingText={'Ticket'}>
            <Button size={'xs'} background={'#9c27b0'} color="white" marginLeft={'40px'} onClick={handleBack} _hover={{ color: 'white' }} mt={'20px'}>
                Back
            </Button>
            <Flex w={'90%'} m={'auto'} mt={'40px'} justifyContent={'space-between'}>
                <Box w={'45%'} background={'white'} p={'15px'}>
                    <Grid templateColumns={'repeat(2, 1fr)'} pb={'40px'}>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Refrence Number:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{data.referenceNo}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Create At:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{new Date(data.createdAt).toLocaleString()}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Last Updated At:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{new Date(data.updatedAt).toLocaleString()}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Subject:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{data.subject}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Issue:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>{data.description}</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Text>Status:</Text>
                        </GridItem>
                        <GridItem lineHeight={'36px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                            <Select
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value={TICKET_OPEN_STATUS}>Open</option>
                                <option value={TICKET_PENDING_STATUS}>In Progress</option>
                                <option value={TICKET_CLOSE_STATUS}>Resolved</option>
                            </Select>
                        </GridItem>
                    </Grid>
                    <hr />
                    <Flex mt={'20px'} justifyContent={'space-between'}>
                        <Text fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>Latest Reply</Text>
                        <Text color={'rgb(144, 144, 144)'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>From Team</Text>
                    </Flex>
                    <Text mt={'15px'} fontSize={'14px'} fontFamily={'Montserrat,Sans-serif'}>
                        {typeof data.description === 'string' ? data.description : ''}
                    </Text>
                </Box>
                <Box w={'50%'}>
                    <Text fontFamily={'NeueMontreal, sans-serif'} fontSize={'18px'} pb={'10px'}>
                        Previous Ticket Sessions
                    </Text>
                    <Accordion color={'black'} allowMultiple fontFamily={'NeueMontreal, sans-serif'}>
                        <AccordionItem
                            padding={'5px'}
                            mb={'10px'}
                            _expanded={{
                                borderRadius: '30px',
                            }}
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton
                                            style={{
                                                backgroundColor: '#9c27b0',
                                                color: 'white',
                                            }}
                                        >
                                            <Box as="span" flex="1" textAlign="left" fontSize={'14px'}>
                                                Chat History
                                            </Box>
                                            {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        fontSize={'14px'}
                                        style={{
                                            padding: '0px',
                                            backgroundColor: 'white',
                                        }}
                                        fontFamily={'Montserrat,Sans-serif'}
                                        lineHeight={'22px'}
                                    >
                                         {data.message &&
        data.message.split('}').map((msg, index) => {
            if (msg.trim() !== '') {
                const formattedMsg = index < data.message.length - 1 ? msg + '}' : msg;
                const parsedMsg = JSON.parse(formattedMsg);
                const align = role.name === "Advisor" || "Customer" || "AGA" || "MGA" ? 'flex-end' : 'flex-start';

                return (
                    <Flex key={index} justifyContent={align} p={'10px'}>
                        <Box
                            maxWidth="70%"
                            bg={role.name === "Admin"|| "Super Admin" ? 'blue.500' : 'gray.200'}
                            color={role.name === "Advisor" || "Customer" || "AGA" || "MGA" ? 'white' : 'black'}
                            p={3}
                            borderRadius={10}
                            mb={2}
                        >
                            {/* Display message content */}
                            <div>{parsedMsg.message}</div>
                             <hr/>
                            {/* Display attachment link if attachment exists */}
                            
                            {parsedMsg.attachment && (
                                <div>
                                    Attachment: {' '}
                                    <a href="#" onClick={() => handleDownload(parsedMsg.attachment)}>
                                        {parsedMsg.attachment}
                                    </a>
                                </div>
                            )}
                        </Box>
                    </Flex>
                );
            }
            return null;
        })}

                                        <hr />
                                        <Box mt={'10px'} background={'white'} p={'15px'}>
                                            <Textarea
                                                size={'sm'}
                                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                                isDisabled={ticketStatus === 3}
                                            ></Textarea>
                                            <Flex justifyContent={'space-between'}>
                                                <input
                                                    style={{ marginTop: '10px' }}
                                                    onChange={handleFileChange}
                                                    multiple
                                                    type="file"
                                                    accept=".pdf,.doc,.docx"
                                                    isDisabled={ticketStatus === 3} 
                                                />
                                                <Button
                                                    size={'sm'}
                                                    mt={'10px'}
                                                    background={'#9c27b0'}
                                                    color={'white'}
                                                    _hover={{ background: '#9c27b0' }}
                                                    onClick={handleSubmit}
                                                    isDisabled={ticketStatus === 3}
                                                >
                                                    Reply
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </Accordion>
                </Box>
            </Flex>
        </Sidebar>
    );
};

export default AdminViewSingleTicket;
