import { toast } from "react-toastify";
import { ALL_DEDUCTABLES_TYPE, CREATE_DEUCTIBLES_TYPE, isLoading } from "../../../../constants/actionConstants";
import { allDeductiblesService } from "./service";

export const allDeductables = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    try {
      const response = await allDeductiblesService.allDeductables(
        params
      );
      console.log("Response data action:", response);
      dispatch({
        type: ALL_DEDUCTABLES_TYPE,
        payload: response,
      });
      dispatch(isLoading(false));
      return response.data;
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
      throw error;
    }
  };

  
export const createDeductibles = ( params) => async (dispatch) => {
    dispatch(isLoading(true));
    let adminSatatus = await allDeductiblesService.createDeductibles( params);
    const { status, message, data } = adminSatatus
  
    if (status === 201 || status === 200) {
      dispatch({
        type: CREATE_DEUCTIBLES_TYPE,
        payload: message, data
      })
      toast.success(" Successfully")
    } else {
      toast.success(message)
    }
  
    dispatch(isLoading(false));
  
    return adminSatatus
  }