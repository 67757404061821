import { SUPERVISA_QUICKNOTE } from "../../constants/actionConstants";

const initialState = {
  superVisaQuote: [],
};

export default (state = initialState, action)=> {
  switch (action.type) {
    case SUPERVISA_QUICKNOTE:
      
        return { ...state, superVisaQuote: action.payload };
        
    default:
      return state;
  }
}
