import {  ALL_MGA_LIST, ASSING_AGA_MGA } from "../../../../constants/ApplicationUrl"
import Api from "../../../../dependencies/utils/Api"
export const AsingMgaToAgsService = {
    allMgaGet() {
        return Api.GET(ALL_MGA_LIST).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    },
  
    assingAgaToMga(params) {
        return Api.PUT(ASSING_AGA_MGA,params).then((response) => {
            console.log(response,'ADMINUSER')
            const { data, status} = response
            if(status === 200) {
                return {data, status}
            }else {
                const {data: {message} = {}} = response
                return {message, status}
            }
        })
    }

}