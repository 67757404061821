import React from 'react'
import Sidebar from '../../Sidebar'
import { Box, Button, Flex, Image, Input, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const EditProduct = () => {
    const navigate=useNavigate()

    const handleBack = () => {
        navigate(-1);
      }

return (
    <Sidebar headingText={'Edit Products'}>
        <Box w={'70%'} m={'auto'} mt={'70px'} background={'white'} padding={'20px'} boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px'>
        <Button size={'sm'} background={'#9c27b0'} color='white' mb={'20px'} onClick={handleBack} _hover={{color:'white'}}>Back</Button>
            <Flex justifyContent={'center'} w={'70%'} m={'auto'} alignItems={'center'} gap={'70px'}>
                <Image w={'150px'} src='https://travelmedicare.com/public/Product/1567074373travel.png'/>            
                <Input type='file'/>
            </Flex>
            <Flex mt={'50px'} justifyContent={'space-around'}>
                <Box>
                    <Text>Product Name</Text>
                    <Input />
                </Box>
                <Box>
                    <Text>Product Description</Text>
                    <Input />
                </Box>
            </Flex>
            <Flex justifyContent={'center'} mt={'50px'}>
                <Button background={'#9c27b0'} color='white'>Update</Button>
            </Flex>
        </Box>
    </Sidebar>
  )
}

export default EditProduct
