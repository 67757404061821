import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import {
  buttonBackgroundColor,
  buttonColor,
  headingsColor,
  primaryBackgroundColor,
} from "../../../Utils/UniversalColor";
import { DownloadIcon } from "@chakra-ui/icons";
import StudentTravel from "../index";

const StudentPlanCompare = () => {
  return (
    <Box>
      <StudentTravel />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px",
            maxWidth: "800px",
            textAlign: "center",
          }}
        >
          <div>
            <h3
              style={{
                marginBottom: "20px",
                fontFamily: "poppins",
                fontSize: "26px",
                fontWeight: 400,
              }}
            >
              Visitors To Canada Product Overview
            </h3>
            <p
              style={{
                marginBottom: "16px",
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: 300,
              }}
            >
              The following is a summary of the Travel Medicare Students to
              Canada Product underwritten by Berkley Insurance Company. Please
              refer to the policy wording for complete terms, benefits,
              conditions and exclusions.
            </p>
            <Button
              style={{
                fontSize: "16px",
                fontFamily: "poppins",
                padding: "26px 20px",
                backgroundColor: buttonBackgroundColor,
                borderRadius: "50px",
                color: buttonColor,
              }}
            >
              Download Document
              <DownloadIcon
                w={6}
                h={6}
                style={{
                  padding: "4px",
                  border: "1px solid white",
                  borderRadius: "50%",
                  marginLeft: "8px",
                  color: "#088183",
                  backgroundColor: "white",
                }}
              />
            </Button>
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "100px",
            fontWeight: 600,
            maxWidth: "900px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor:primaryBackgroundColor,
              color: "white",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 50px",
              }}
            ></div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 50px",
              }}
            >
              <p>Standard</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 50px",
              }}
            >
              <p>Enhanced</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 50px",
              }}
            >
              <p>Premium</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Policy Limits
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                $25,000
                <br /> $50,000 <br />
                $100,000
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>365 days</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>365 days</p>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Travel Outside Canada
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Travel worldwide is valid as long as the majority (51%) of time
                on the policy is spent in Canada. Your home country is excluded
                unless on a school-sponsored trip, and coverage in the USA is
                limited to 30 days
              </p>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Travel Outside Canada
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                $2,000,000
              </p>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            marginLeft: "100px",
            fontWeight: 600,
            maxWidth: "900px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: primaryBackgroundColor,
              color: "white",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 10px",
              }}
            >
              <p>MEDICAL COVERAGE</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Standard Emergency</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Enhanced Emergency Plus</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Premium Emergency Plus</p>
            </div>
          </div>

          {/* jhbhjbj */}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Hospitalization
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Semi private room
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency room fees
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency outpatient services
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Semi private rate
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency room fees
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency outpatient services
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Semi private rate
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency room fees
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Emergency outpatient services
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Physician Charges
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Medical treatment by a legally licensed physician, surgeon,
                anesthetist or registered graduate nurse
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Diagnostic Services
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                  . Lab tests & x-rays part of emergency medical treatment
                </p>
                <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                  . MRI, CAT, cardiac catheterization, sonograms, ultrasounds,
                  digital x-rays, biopsies with prior approval
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prescriptions
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>30 day supply</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>30 day supply</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>60 day supply</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prescriptions
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>With prior approval $5,000 limit out of hospital</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>With prior approval</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>With prior approval</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "10px 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Paramedical Services: Chiropractor,Physiotherapist, Podiatristor
              Osteopath
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>$500 per practitioner</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>$500 per practitioner</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "60px 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Psychologist/Psychiatrist
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $500 for outpatient visits to a psychiatrist or
                psychologist
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $10,000 for psychiatric hospitalization
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $500 for outpatient visits to a psychiatrist or
                psychologist
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $10,000 for psychiatric hospitalization
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $1000 for outpatient visits to a psychiatrist or
                psychologist
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $15,000 for psychiatric hospitalization
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Trauma Counseling
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>6 sessions</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>6 sessions</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Physical Exam
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>One exam per year up to $150</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>One exam per year up to $150</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Eye Exam
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>One exam per year up to $100</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>One exam per year up to $100</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "60px 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Maternity
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $5,000 for complications; the pregnancy must commence
                during the coverage period.
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . One induced termination per policy period.
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $10,000 for complications; the pregnancy must commence
                during the coverage period.
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . One induced termination per policy period.
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $15,000 for complications; the pregnancy must commence
                during the coverage period.
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . One induced termination per policy period.
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Corrective Device
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>Up to $1,000 to repair or replace</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>Up to $1,000 to repair or replace</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Sexual Health Consultation
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>Up to $100 for elective testing</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "60px 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Dental
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Up to $5,000 for complications; the pregnancy must commence
                during the coverage period.
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: Up to $2,500
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: Up to $4,000
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Pain: $600
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: Up to $4,000
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Pain: $600
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 40px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Medical Appliances
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Purchase or rental of minor appliances with approval: crutches,
                wheelchair, splints, canes etc.
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Purchase or rental of minor appliances with approval: crutches,
                wheelchair, splints, canes etc.
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Purchase or rental of minor appliances with approval: crutches,
                wheelchair, splints, canes etc.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Ambulance Services
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Licensed ground ambulance or taxi
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Emergency Air Transportation
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                With prior approval
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                . Air ambulance
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                . Airline with medical attendant
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                . Additional seats if required
              </p>
              <p
                style={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  marginLeft: "10px",
                }}
              >
                . Economy airfare to Canada or you country of origin
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: primaryBackgroundColor,
              color: "white",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 10px",
              }}
            >
              <p>ADDITIONAL COVERAGE</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Standard Emergency</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Enhanced Emergency Plus</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Premium Emergency Plus</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Tutorial Services
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>Up to $20/hour max of $500</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Repatriation of Remains
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>$5,000 repatriation, cremation or burial at place death</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>$10,000 repatriation, cremation or burial at place death</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>$15,000 repatriation, cremation or burial at place death</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Transportation to Bedside
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>N/A</p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Economy airfare up to $5,000 & $150/day max $1,500 for living
                expenses
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Economy airfare up to $5,000 & $150/day max $1,500 for living
                expenses
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "60px 20px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              AD&D
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "initial",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Air Flight / Common Carrier Accident: $25,000
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: $10,000
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Air Flight / Common Carrier Accident: $100,000
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: $15,000
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Air Flight / Common Carrier Accident: $100,000
              </p>
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                . Accident: $15,000
              </p>

              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: primaryBackgroundColor,
              color: "white",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "30px 10px",
              }}
            >
              <p>EXCLUSIONS</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Standard </p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Enhanced</p>
            </div>
            <div
              style={{
                border: "1px solid white",
                textAlign: "center",
                padding: "10px 10px",
              }}
            >
              <p>Premium</p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 1fr 1fr 1fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 50px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Pre-existing conditions
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Unexpected emergencies covered if stable for 90 days prior to
                the start of the policy.
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Unexpected emergencies covered if stable for 90 days prior to
                the start of the policy.
              </p>
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 20px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p>
                Unexpected emergencies covered if stable for 90 days prior to
                the start of the policy.
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr ",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 30px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              To obtain medical treatment
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr ",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 30px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              In country of origin
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prudent person
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Medical condition that requires treatment prior to departure
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Non-emergency
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Including elective and cosmetic
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prior to effective date
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                For coverage extensions
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Terminal Illness
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Or travelling against advice of physician
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Can be Delayed
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Until your country of origin
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Ongoing Condition
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Chronic condition, home health care, investigative testing or
                rehabilitation
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Travel Outside Canada
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Medical treatment exceeding 30 days in the USA
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              HIV
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Including HIV related conditions and AIDS
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Follow up Visit
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Acute sickness or injury after emergency has ended
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Pregnancy
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Except as provided in the Maternity Benefit
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Non-compliance
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                With any prescribed medical treatment or therapy
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prescriptions
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Over the counter, contraceptive, and not legally registered or
                approve in Canada
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Transplants
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Cornea, organ, bone marrow, artificial joints, prosthetic
                devices, and implants
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Medical Examinations
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Requested by third party, or consultations with a physician by
                phone or e-mail
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Prior Approval not Obtained
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Except emergency upon admission to hospital
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Intoxication
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Alcohol, prohibited drugs, or any other intoxicant
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Criminal or Illegal Act
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Committing or attempting to commit
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Emotional, psychological
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Except as provided in Psychological and Psychiatric Benefit
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Suicide
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Including attempt, whether sane or insane
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Aircraft Operation
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Operating or learning as pilot or crew
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              High Risk Activities
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Rock or mountain climbing, hang gliding, parachuting, bungee
                jumping, skydiving, motor sport/racing, professional sport,
                scuba diving
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Government Warning
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Avoid all travel or to avoid non-essential travel
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Contamination
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Radioactive material, nuclear fuel or waste
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.6fr 3fr",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Other Insurance
            </div>
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: "30px 50px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              <p style={{ fontFamily: "poppins", fontSize: "14px" }}>
                Medical treatment or services normally covered or reimbursable
                under any other insurance
              </p>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr ",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 30px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              War
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr ",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 30px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Terrorism
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr ",
              backgroundColor: "white",
              color: "black",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                textAlign: "center",
                padding: " 30px 10px",
                fontSize: "20px",
                fontWeight: 600,
              }}
            >
              Armed Forces Service
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default StudentPlanCompare;
