import {
  Button,Checkbox,Input,Select,Box,Flex,Text,TableContainer,Table,Thead,Tr,Th,Td,Tbody,Stack } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { IoMdAdd, IoMdCloseCircle } from "react-icons/io";
import { buttonBackgroundColor, buttonColor} from "../../../Utils/UniversalColor";
import { VTCDetailsModal, getMaxSelectableDate, getMinSelectableDate, validateEmail, validateMobileNumber } from "../../Advisor/AdvisorQuote/Validation";
import Description from "./Description";
import { useDispatch } from "react-redux";
import RadioOption from "../../../Utils/RadioCard";
import { advisorCalculateVtc, advisorGenerateQuote } from "../../Advisor/Dependencies/action";
import { studentSingleQuote } from "../dependencies/action";
import { useNavigate } from "react-router-dom";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";
// import Api from "../../../dependencies/utils/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import '../../Advisor/AdvisorQuote/styles.css'
import HealthQuestions from "../../../Utils/HealthQuestions";
import { formatDatePicker } from "../../../Utils/DateFormate";


function TripDetails() {
  const toast = useToast();
  const [tripDetailsFirst, setTripDetailsFirst] = useState(true);
  const [tripDetailsSecond, setTripDetailsSecond] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [waitingPeriod, setWaitingPeriod] = useState("0");
  const [isVtcModalOpen, setIsVtcModalOpen] = useState(false);
  const [contactDetails,setContactDetails]=useState(false)
  const [data,setData]=useState([])
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [checked,setChecked]=useState('')
  const [policyLimit,setPolicyLimit]=useState('2000000')
  const [dedecutibles,setDeductibles]=useState ('0')
  const [policyTypeSuperVisa,setPolicyTypeSuperVisa]=useState('0')
  const [PlanType,setPlanType]=useState('Standard')
  const [selectedCostOption, setSelectedCostOption] = useState('');
  const [duration,setDuration]=useState('')
  const [studentPolicyOption,setStudentPolicyOption]=useState('Single Trip')
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [calculatedData,setCalculatedData]=useState([])
  const [quoteData,setQuoteData]=useState([])
  const [rows, setRows] = useState([]);
  const [amount,setAmount]=useState(0)
  const role = storage.getUserRole();
  const [adjustment,setAdjustment]=useState(0)
  const [amountWithoutAdjustment,setAmountWithoutAdjustment]=useState(0)
  const [addTravelers, setAddTravelers] = useState([
    {
      name: "",
      gender: "",
      dob: "",
      relation: "",
      benificiaryDob: "",
      benificiaryName: "",
      selectedKey:''
    },
  ]);

  console.log(addTravelers,'addTravelers')
 
    const [travelersError, setTravelersError] = useState([
      {
        name: "",
        gender: "",
        dob: "",
        relation: "",
        benificiaryDob: "",
        benificiaryName: "",
      },
    ]);
    const [formData, setFormData] = useState({
      firstDate: "",
      lastDate: "",
      departureDate: "",
      departureMaxDate: "",
    });
    const [policyHolder, setPolicyHolder] = useState({
      policyOwnerName: '',
      policyOwnerDob: '',
      policyOwnerEmail: '',
      policyOwnerAddress: '',
      policyOwnerPostalCode: '',
      policyOwnerCity: '',
      policyOwnerPhone: ''
    });

    const handleStudentPolicyOption = (e) => {
      const { value } = e.target;
      if (!formData.firstDate) {
        alert('Please choose first date of cover.')
        return;
      } 
      if(value==='Annual'){
          const startDate = new Date(formData.firstDate);
          const endDate = new Date(startDate);
          endDate.setFullYear(startDate.getFullYear() + 1);
          endDate.setDate(startDate.getDate());
          setFormData((prevData) => ({
            ...prevData,
            lastDate: endDate.toISOString().split("T")[0],
          }));
          let durat = 0;
          if (endDate) {
            const diff = endDate.getTime() - startDate.getTime();
            durat = Math.ceil(diff / (1000 * 60 * 60 * 24));
            setDuration(durat);
        }
      }
      console.log(value,'value')
      setStudentPolicyOption(value);
    };

    const isInitialRender = useRef(true);

    useEffect(() => {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
      handleModalSubmit();
    }, [PlanType, policyTypeSuperVisa, dedecutibles]);

    const handlePolicyOwnerChange = (e) => {
      const { name, value } = e.target;
      setPolicyHolder({ ...policyHolder, [name]: value });
      const selectedPolicyHolder = addTravelers.find((traveler) => traveler.name === value);
      if (selectedPolicyHolder) {
        setPolicyHolder({ ...policyHolder, policyOwnerDob: selectedPolicyHolder.dob, policyOwnerName: selectedPolicyHolder.name });
      }
    };
    
    const [errors, setErrors] = useState({
      firstDate: "Enter First Date cover",
      lastDate: "",
      departureDate: "",
    });

    const handleChange = (dateOrEvent, fieldName) => {
      let name, value;
      if (fieldName) {
        name = fieldName;
        value = dateOrEvent;
      } else {
        const event = dateOrEvent;
        name = event.target.name;
        value = event.target.value;
      }     
      setFormData({ ...formData, [name]: value });
      if(formData.lastDate && name ==="firstDate"){
        setStudentPolicyOption('Single Trip')
        const endDate = new Date(formData.lastDate);
        const startDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData((prevData) => ({ ...prevData, lastDate: "" }));
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData((prevData) => ({ ...prevData, lastDate: "" }));
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24) + 1);
          setDuration(durationInDays);
        }
        console.log('ravi')
      }
      if (name === "lastDate") {
        setStudentPolicyOption('Single Trip')
        if (!formData.firstDate) {
          alert("Please select the first date of cover first");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
          return;
        }
        const startDate = new Date(formData.firstDate);
        const endDate = new Date(value);
        const diffInMilliseconds = endDate - startDate;
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000;
    
        if (endDate < startDate) {
          alert("Last date cannot be earlier than the first date");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else if (diffInMilliseconds > oneYearInMilliseconds) {
          alert("Duration cannot exceed 1 year");
          setFormData({ ...formData, lastDate: "" });
          setDuration("");
        } else {
          const durationInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24)+1);
          setDuration(durationInDays);
        }
      }
      if(name==="departureDate"){
        if(!formData.firstDate){
          alert("Please select first date of cover first")
          setFormData({ ...formData, departureDate: "" });
          return;
        }
      }
    };

    const handleToTravellerDetails = () => {
      if(!formData.departureDate || !formData.firstDate || !formData.lastDate){
        alert('Please enter all details.')
        return;
      }
        setTripDetailsFirst(false);
        setTripDetailsSecond(true)
    };

    const handleToContactDetails = () =>{
        setOpenPlan(false)
        setContactDetails(true)
    }

    const handleTripDetailsBackEvent = () => {
      setTripDetailsSecond(false);
      setTripDetailsFirst(true)
    };

    const handlePlanBackEvent = () =>{
      setOpenPlan(false)
      setTripDetailsSecond(true)
    }

    const handleContactDetailsBackEvent = ( ) =>{
      setOpenPlan(true)
      setContactDetails(false)
    }

    const handleCheckbox = () => {
      setChecked(true);
    };

    const handleRemoveTraveller = (index) => {
      const updatedTravelers = [...addTravelers];
      updatedTravelers.splice(index, 1);
      setAddTravelers(updatedTravelers);
    };

    const handleVtcModalClose = () => {
      setIsVtcModalOpen(false);
    };  

    useEffect(() => {
      // console.log(formatDatePicker(new Date(formData.firstDate)))
      if (!formData.departureDate) {
        setWaitingPeriod("NA");
      } else {
        if (formatDatePicker(new Date(formData.departureDate)) ===formatDatePicker(new Date(formData.firstDate))) {
          setWaitingPeriod("NA");
        } else {
          setWaitingPeriod("5 days");
        }
      }
    }, [formData.departureDate]);

  const handleAddTraveller = () => {
      setAddTravelers([
        ...addTravelers,
        {
          name: "",
          gender: "",
          dob: "",
          relation: "",
          benificiaryDob: "",
          benificiaryName: "",
        },
      ]);
  };

  const handleChangeTraveler = (index, dateOrEvent, fieldName) => {
    let name, value;
    if (fieldName) {
      name = fieldName;
      value = dateOrEvent;
    } else {
      const event = dateOrEvent;
      name = event.target.name;
      value = event.target.value;
    }
    if (name === "dob" || name === "benificiaryDob") {
      const dateValue = new Date(value);
      const today = new Date();
      const selectedDate = new Date(value);
      const minDate = new Date();
      minDate.setDate(minDate.getDate() - 15);
      if (selectedDate >= minDate) {
          alert("Users must be at least 15 days older than today.");
          return;
      }
      const age = today.getFullYear() - selectedDate.getFullYear();
      const monthDiff = today.getMonth() - selectedDate.getMonth();
      const dayDiff = today.getDate() - selectedDate.getDate();
      if (
          (age > 65) ||
          (age === 65 && monthDiff > 0) ||
          (age === 65 && monthDiff === 0 && dayDiff >= 0)
      ) {
          alert("Eligible for coverage must be at least 15 days old and less than 65 years of age");
          return;
      }
      value = dateValue.toISOString().split('T')[0]; 
  }
    setAddTravelers((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = { ...updatedState[index], [name]: value };
      if (name === "dob") {
        const dob = new Date(value);
        const today = new Date();
        const age = today.getFullYear() - dob.getFullYear();
        updatedState[index] = {
            ...updatedState[index],
            age: age,
        };
    }
      return updatedState;
    });
  };

  const handleSubmitTravellerForm = () => {
    const isAnyBeneficiaryDetailEmpty = addTravelers.some(
      (traveler) =>
        traveler.benificiaryName === "" ||
        traveler.benificiaryDob === "" ||
        traveler.relation === ""
    );
    if (isAnyBeneficiaryDetailEmpty) {
      alert("Please fill in all mandatory details.")
      return;
    }
    setIsVtcModalOpen(true)

  };

  // const handleModalSubmit = async () => {
  //   setTripDetailsSecond(false);
  //   setOpenPlan(true);
  //   const updatedTravelers = addTravelers.map((traveler) => ({
  //       insuredName: traveler.name,
  //       insuredGender: traveler.gender,
  //       insuredDOB: traveler.dob,
  //       insuredRelation: traveler.relation,
  //       beneficiaryName: traveler.benificiaryName,
  //       beneficiaryDob: traveler.benificiaryDob,
  //   }));
  //   const dataToLog = {
  //     policyStartDate: formData.firstDate,
  //     policyEndDate: formData.lastDate,
  //     insuredDetailsRequests: updatedTravelers,
  //   };
  // console.log(dataToLog,'data sending')
  // dispatch(studentSingleQuote(dataToLog))
  // .then((response) => {
  //   setQuoteData(response.data)
  //   console.log(response.data,'results coming')
  //   if (response.status === 200) {
  //     console.log('success')
  //     // toast("sucess")
  //   } else {
  //   }
  // })
  // .catch((error) => {
  //   // Handle error if needed
  //   console.error("Error:", error);
  // });
     
  // };

  const handleModalSubmit = () => {
      setTripDetailsSecond(false);
      setOpenPlan(true);
      const updatedTravelers = addTravelers.map((traveler) => ({
        insuredName: traveler.name,
        insuredGender: traveler.gender,
        insuredDOB: traveler.dob,
        insuredRelation: traveler.relation,
        beneficiaryName: traveler.benificiaryName,
        beneficiaryDob: traveler.benificiaryDob,
      }));
      const dataToLog = {
          firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
          lastDateOfCover: formatDatePicker(new Date(formData.lastDate)),
          planType:PlanType,
          insuredDetailsRequests: updatedTravelers,
          policyType:'Studentvisa',
          tripType:studentPolicyOption
      };
    console.log(dataToLog,"Vtc form submit data")
    dispatch(advisorCalculateVtc(dataToLog)).then(res => {
      const {status, data} = res
        if (status === 200) {
          const response = data.listOfInsuredQutation;
          setAddTravelers(prevAddTravelers => {
            const updatedTravelers = prevAddTravelers.map(traveler => {
              const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
              if (responseTraveler) {
                return {
                  ...traveler,
                  costWithPreExisting: responseTraveler.costWithPreExisting,
                  costWithoutPreExisting: responseTraveler.costWithoutPreExisting,
                  selectedKey: 2,
                };
              }
              return traveler;
            });
            const totalCostWithPreExisting = updatedTravelers.reduce((total, traveler) => {
              return total + (traveler.costWithPreExisting || 0);
            }, 0);
            setTotalCost(totalCostWithPreExisting,res);
            return updatedTravelers;
          });
          const setTotalCost = (totalCost,res) => {
            setAdjustment(res.data.adjustment===null?0:res.data.adjustment)
            setAmount(totalCost+Number(res.data.adjustment))
            setAmountWithoutAdjustment(totalCost); 
          };
        
        // updateAddTravelers(data.listOfInsuredQutation)
      }
      console.log(data,'data')
      setData(data)
    })
  };

  const updateAddTravelers = (response) => {
    setAddTravelers(prevaddTravelers => {
      return prevaddTravelers.map(traveler => {
        const responseTraveler = response.find(rt => rt.insuredDob === traveler.dob);
        if (responseTraveler) {
          return {
            ...traveler,
            costWithPreExisting: responseTraveler.costWithPreExisting,
            costWithoutPreExisting: responseTraveler.costWithoutPreExisting
          };
        }
        return traveler;
      });
    });
  };

  const handleCostOptionChange = (index, value, ele) => {
    setSelectedCostOption(prevSelectedCostOption => ({
      ...prevSelectedCostOption,
      [index]: value
    }));
    const selectedValues = Object.values(selectedCostOption).filter((val, idx) => idx !== index);
    const totalSelectedValue = selectedValues.reduce((acc, curr) => acc + curr, 0);
    const totalValue = totalSelectedValue + value;
    const selectedOption = (value === ele.costWithPreExisting) ? 'withPreExisting' : 'withoutPreExisting';
    const selectedKey = (value === ele.costWithPreExisting) ? '2' : '3';
    const message = selectedOption === 'withPreExisting' ? data.messageOfPreExisting : data.messageOfWithoutPreExisting;
    setAmount(totalValue);
    setAddTravelers(prevTravelers => prevTravelers.map((traveler, idx) => {
      if (idx === index) {
        return { ...traveler, selectedKey };
      }
      return traveler;
    }));
  };
  
  const handleGetQuote = () =>{
    const {policyOwnerName,policyOwnerAddress, policyOwnerEmail,policyOwnerCity,policyOwnerPhone,policyOwnerPostalCode} = policyHolder;
      if(policyOwnerName.length===0){
        alert("Please seclet policy Owner")
        return;
      }
    if (policyOwnerAddress.length === 0) {
      alert('Please enter valid address')
      return;
    }
    if (policyOwnerEmail.length === 0 || !validateEmail(policyOwnerEmail)) {
      alert('Please enter a valid email');
      return;
    }
    if (policyOwnerCity.length === 0) {
      alert('Please enter valid City')
      return
    }
    if (policyOwnerPhone.length === 0 || !validateMobileNumber(policyOwnerPhone)) {
      alert('Please enter valid Phone');
      return;
    }
    if (policyOwnerPostalCode.length === 0) {
      alert('Please enter valid Postal Code')
      return
    }
    const updatedTravelers = addTravelers.map((traveler) => ({
      nameOfInsured: traveler.name,
      gender: traveler.gender,
      dateOfBirth: traveler.dob,
      beneficiaryRelationToInsured: traveler.relation,
      beneficiaryName: traveler.benificiaryName,
      beneficiaryDOB: traveler.benificiaryDob,
      costWithPreExisting:traveler.costWithPreExisting,
      costWithoutPreExisting:traveler.costWithoutPreExisting,
      selectedKey:traveler.selectedKey
    }));
    const dataToSend = {
      email: policyHolder.policyOwnerEmail,
      firstDateOfCover: formatDatePicker(new Date(formData.firstDate)),
      lastDateOfCover:formatDatePicker(new Date(formData.lastDate)),
      policyEndDate: formData.lastDate,
      duration:parseInt(duration),
      country:1,
      province:'Ontario',
      departureFromCountryOfOrigin:formatDatePicker(new Date(formData.departureDate)),
      waitingPeriod:waitingPeriod,
      policyLimit:policyLimit,
      deductible:dedecutibles,
      paymentFrequency:policyTypeSuperVisa,
      policyOwner:policyHolder.policyOwnerName,
      policyOwnerDateOfBirth:policyHolder.policyOwnerDob,
      policyOwnerEmail:policyHolder.policyOwnerEmail,
      policyOwnerAddress:policyHolder.policyOwnerAddress,
      policyOwnerPostalCode:policyHolder.policyOwnerPostalCode,
      policyOwnerCity:policyHolder.policyOwnerCity,
      policyOwnerPhone:policyHolder.policyOwnerPhone,
      planType:PlanType,
      insuredTravellerDetailsRequest: updatedTravelers,
      policyType:'Studentvisa',
      amount:amount,
      familyCoverage:checked===true?1:0,
      tripType:studentPolicyOption,
      adjustment:adjustment
  };
      console.log(dataToSend,'dataTo Send')
      if(role === ROLES.CUSTOMER){
        dispatch(advisorGenerateQuote(dataToSend)).then(res => {
        const {status, data} = res
        if(status===200 || status===201){
          console.log(data,'data')
          alert('Quote Generated Successfully')
          navigate(`/customer-view-quote/${data.quotationNo}`)
        }else{
          alert("something went wrong")
        }
      })
      }else{
        Api.POST(CUSTOMER_GENERATE_QUOTE,dataToSend).then((res)=>{
          console.log(res)
          if(res.status===200 || res.status===201){
            storage.setUser(res.data)
            navigate("/customer-dashboard");
          }else{
            navigate('/customerLogin')
            alert('Please login First.')
            console.log('Somthing went worng.')
          }
        })
      }
      // dispatch(advisorGenerateQuote(dataToSend)).then(res => {
      //   const {status, data} = res
      //   if(status===200 || status===201){
      //     console.log(data,'data')
      //     navigate(`/customer-view-quote/${data.quotationNo}`)
      //     // const tabNumber=1
      //     // sendDataToParent({'amount':amount,'data':data,'tab':tabNumber});
      //   }else{
      //     alert("something went wrong")
      //   }
      //   // const {status, data} = res
      //   // console.log(data,'data')
      //   // const tabNumber=1
      //   // sendDataToParent({'amount':selectedRowData.amount,'data':data,'tab':tabNumber});
      // })
  }

  useEffect(() => {
    const rows = quoteData.map(item => ({
      planType: item.planType,
      policyLimit: '2000000', 
      insuredPlanData: `${item.amount}.00CAD`, 
      adjustment: `${item.adjAmount} CAD`,
      tripType: 'Single',
      amount:item.amount
    }));

    setRows(rows);
  }, [quoteData]);
  

  const handleRowClick = (index, rowData) => {
    setSelectedRowIndex(index);
    setSelectedRowData(rowData);
  };

  const handlePolicyLimitChange = (value) => {
    setPolicyLimit(value);
    // handleModalSubmit()
  };
  const handleDeductiblesChange = (value) => { 
    setDeductibles(value);
    // handleModalSubmit()
  };
  const handlePlanTypeChange = (value) => { 
    setPlanType(value);
    // handleModalSubmit()
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 180); // Add 180 days
    return today.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
  };

  const datePickerRefs = {
    firstDate: useRef(null),
    lastDate: useRef(null),
    departure: useRef(null),
    studentDeparture: useRef(null),
  };
  const handleDatePickerIconClick = (pickerRef) => {
    console.log('hit',pickerRef)
    if (pickerRef.current) {
      pickerRef.current.setOpen(true); // Open the DatePicker
    }
  };


return (
    <Box padding={['2px','2px','10px']} style={{ width: "100%",paddingTop:'0px',textAlign:'left'}}>
        <Text marginLeft={['10px','40px','40px']} fontFamily={'poppins'} fontWeight={'700'} mb={'15px'}>Student Insurance</Text>
      <Box w={['99%','99%','90%']} style={{ display: "flex",flexDirection: "column",alignItems: "center",margin:'auto'}}>

        {tripDetailsFirst && (
          <div
            style={{
              width: "100%",
              maxWidth: "870px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p style={{ fontFamily: "poppins", fontSize: "18px", fontWeight: 400, marginBottom: "20px",borderBottom: "2px solid #088183"}}>Trip Details</p>
            <div
              style={{
                gap: "20px",
                margin: "auto",
                padding: "18px",
              }}
            >
              <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'13px'} pb={'20px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>First Date of Cover</span>
                <div className="date-picker-container">
                    <DatePicker
                        selected={formData.firstDate}
                        onChange={date => handleChange(date, 'firstDate')}                                
                        dateFormat="yyyy-MM-dd"
                        className="custom-date-picker"
                        placeholderText='yyyy-mm-dd'
                        minDate={new Date().toISOString().split("T")[0]}
                        maxDate={getMaxDate()}
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        ref={datePickerRefs.firstDate}
                    />
                    <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.firstDate)} />
                  </div>
                {/* <Input name="firstDate" value={formData.firstDate} min={new Date().toISOString().split("T")[0]}
                  onChange={handleChange} w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} /> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Last Date of Cover</span>
                <div className="date-picker-container">
                    <DatePicker
                        selected={formData.lastDate}
                        onChange={date => handleChange(date, 'lastDate')}                                
                        dateFormat="yyyy-MM-dd"
                        className="custom-date-picker"
                        placeholderText='yyyy-mm-dd'
                        minDate={formData.firstDate}
                        showYearDropdown
                        showMonthDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                        ref={datePickerRefs.lastDate}
                    />
                    <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.lastDate)} />
                  </div>
                {/* <Input name="lastDate" w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} 
                  value={formData.lastDate} onChange={handleChange}/> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Duration</span>
                <Input w={'90px'} size='sm' backgroundColor={'#eaeaea'} value={`${duration} days`} border={'1px solid #d7d7d7'} />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Country</span>
                <p> Canada</p>
              </div>
              </Flex>

              <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'13px'} pb={'20px'}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Plan Option</span>
                <Select size={'sm'} value={studentPolicyOption} onChange={handleStudentPolicyOption}>
                  <option value="Single Trip">International Student Policy -Single Trip</option>
                  <option value="Annual">International Student Policy - Annual</option>
                </Select>
                {/* <Input name="departureDate" max={formData.departureMaxDate}
                  onChange={handleChange} w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} /> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Departure from country of origin</span>
                <div className="date-picker-container">
                  <DatePicker
                      selected={formData.departureDate}
                      onChange={date => handleChange(date, 'departureDate')}                                
                      dateFormat="yyyy-MM-dd"
                      className="custom-date-picker"
                      placeholderText='yyyy-mm-dd'
                      maxDate={formData.firstDate}
                      showYearDropdown
                      showMonthDropdown
                      yearDropdownItemNumber={15}
                      scrollableYearDropdown
                      ref={datePickerRefs.departure}
                  />
                  <FaCalendarAlt className="date-icon" onClick={() => handleDatePickerIconClick(datePickerRefs.departure)} />
                </div>
                {/* <Input name="departureDate" max={formData.firstDate}
                  onChange={handleChange} value={formData.departureDate} w={'220px'} size='sm' type='date' border={'1px solid #d7d7d7'} /> */}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ marginBottom: "8px" }}>Waiting Period</span>
                <input
                  placeholder="Basic usage"
                  name="waiting"
                  value={waitingPeriod}
                  readOnly
                />
              </div>
              </Flex>
              <Button size={'sm'} mt={'20px'}
                onClick={handleToTravellerDetails}
                style={{
                  gridColumn: "1 / span 1",
                  backgroundColor: buttonBackgroundColor,
                  color: buttonColor,
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        )}

        {tripDetailsSecond && (
          <div
            style={{
              width: "100%",
              maxWidth: "800px",
              margin: "auto",
              padding: "18px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            }}
          >
            <p
              style={{
                fontFamily: "poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "20px",
                borderBottom: "2px solid #088183",
              }}
            >
              Traveller Details family
            </p>
            <Flex fontFamily={'poppins'} fontSize={'14px'} gap={'10px'} justifyContent={'end'} cursor={'pointer'} color={'teal'} mt={'10px'} mb={'10px'}>
                <Flex alignItems={'center'}>
                  <IoMdAdd style={{fontWeight:'bold'}}/>
                    </Flex>
                  <Text onClick={handleAddTraveller}>Add Traveller</Text>
              </Flex>
            {addTravelers.map((item, index) => {
              return (
                <>
                <Flex fontSize='13px' flexDirection={['column','column','row']} gap={['10px','20px','40px']} margin={'auto'} padding={'10px'} key={index}>
                  <Box w={['auto','auto','25%']}>
                    <span style={{ marginBottom: "8px" }}>Name Of Insured</span>
                    <Input name="name" value={item.name}
                      onChange={(e) => handleChangeTraveler(index, e)} size='sm' border={'1px solid #d7d7d7'} min={new Date().toISOString().split('T')[0]} />
                  </Box>
                  <Box w={['auto','auto','25%']}>
                    <span style={{ marginBottom: "8px" }}>Gender</span>
                    <Select background={'white'} size={'sm'} border={'1px solid #d7d7d7'}
                       value={item.gender}
                       name="gender"
                      onChange={(e) => handleChangeTraveler(index, e)}>
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </Select>
 
                  </Box>
                  <Box w={['90%','auto','25%']}>
                  <Flex justifyContent={'space-between'}>
                       <Text fontWeight={'600'} fontSize={'11px'}>Date of Birth*</Text>
                       <Text fontWeight={'600'} name='age' color={'teal'}>{item.age} yrs</Text>
                    </Flex>
                    <div className="date-picker-container">
                              <DatePicker
                                selected={item.dob}
                                onChange={(date) => handleChangeTraveler(index, date,'dob')}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"
                                maxDate={getMaxSelectableDate()}
                                minDate={getMinSelectableDate()} 
                                placeholderText='yyyy-mm-dd'
                                showYearDropdown
                                showMonthDropdown
                                yearDropdownItemNumber={15}
                                scrollableYearDropdown
                              />
                              <FaCalendarAlt className="date-icon" />
                      </div>
                  </Box>
                  </Flex>
                <Flex fontSize='13px' flexDirection={['column','column','row']} gap={['10px','20px','40px']} p={'10px'} pt={'5px'} pb={'20px'}>
                    <Box w={['auto','auto','25%']}>
                      <span style={{ marginBottom: "8px" }}>
                        Beneficiary Name
                      </span>
                      <Input name="benificiaryName" placeholder="Name"
                        onChange={(e) => handleChangeTraveler(index, e)}
                        value={item.benificiaryName} size='sm' border={'1px solid #d7d7d7'} />
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <span style={{ marginBottom: "8px" }}>Relationship to Insured</span>
                      <Input name="relation"
                        onChange={(e) => handleChangeTraveler(index, e)}
                        value={item.relation}
                        placeholder="Relation" size='sm' border={'1px solid #d7d7d7'} />
                    </Box>
                    <Box w={['auto','auto','25%']}>
                      <span style={{ marginBottom: "8px" }}>Beneficiary DOB</span>
                      <div className="date-picker-container">
                            <DatePicker
                              selected={item.benificiaryDob}
                              onChange={(date) => handleChangeTraveler(index, date,'benificiaryDob')}
                              dateFormat="yyyy-MM-dd"
                              className="custom-date-picker"
                              maxDate={new Date().toISOString().split('T')[0]}
                              placeholderText='yyyy-mm-dd'
                              showYearDropdown
                              showMonthDropdown
                              yearDropdownItemNumber={15}
                              scrollableYearDropdown
                            />
                            <FaCalendarAlt className="date-icon" />
                          </div>
                      {/* <Input onChange={(e) => handleChangeTraveler(index, e)}
                        name="benificiaryDob"
                        value={item.benificiaryDob}
                        placeholder="Select Date and Time"
                        w={'180px'} size='sm' type='date' border={'1px solid #d7d7d7'} max={new Date().toISOString().split('T')[0]} /> */}
                    
                      </Box>
                      {index > 0 && (
                      <Flex justifyContent={'end'}>
                              <IoMdCloseCircle
                                style={{fontSize:'22px',color:buttonBackgroundColor}}
                                onClick={() => handleRemoveTraveller(index)}
                                />
                            </Flex>
                          )}
                      </Flex>
                    </>
              );
            })}
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: "8px",
              }}
            >
              <button
                onClick={handleTripDetailsBackEvent}
                style={{
                  backgroundColor: "red",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Previous
              </button>
              <button
                onClick={handleSubmitTravellerForm}
                style={{
                  backgroundColor: "#24b6b7",
                  color: "#fff",
                  padding: "6px 12px",
                  fontWeight: 400,
                  cursor: "pointer",
                  borderRadius: "4px",
                  width: "fit-content",
                  alignSelf: "flex-end",
                }}
              >
                Proceed & Confirm
              </button>
            </div>
          </div>
        )}

        {openPlan === true && (
                <div style={{ margin: "auto", width: "95%", maxHeight: "200%", padding: "18px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
                  <p
                    style={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: 400,
                      marginBottom: "20px",
                      borderBottom: "3px solid #088183",
                    }}
                  >
                    Select Plan
                  </p>
                    <Box gap={'70px'} mt={'15px'} mb={'20px'}>
                          <Box>
                            <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Policy Limit</Text>
                            <Stack spacing={4} direction='row'>
                            <Box
                              as='label'
                              cursor='pointer'
                              borderWidth='1px'
                              borderRadius='md'
                              boxShadow='md'
                              fontSize={['10px','12px','12px']}
                              bg= 'teal'
                              color='white'
                              borderColor='teal.600'
                              _hover={{
                                bg: 'gray.100',
                              }}
                              px={3}
                              py={0}>$2000000</Box>
                            </Stack>
                          </Box>
                          <Box>
                            <Text fontSize={'14px'} mt={'15px'} mb={'7px'}>Select Plan Type</Text>
                            <Stack spacing={4} direction='row'>
                              <RadioOption value='Standard' isSelected={PlanType === 'Standard'} onChange={handlePlanTypeChange}>
                                Standard</RadioOption>
                              <RadioOption value='Enhanced' isSelected={PlanType === 'Enhanced'} onChange={handlePlanTypeChange}>
                                Enhanced</RadioOption>
                              <RadioOption value='Premium' isSelected={PlanType === 'Premium'} onChange={handlePlanTypeChange}>
                              Premium </RadioOption>
                            </Stack>
                          </Box>
                          </Box>
                
                <TableContainer>
                      <Table>
                        <Thead>
                          <Tr>
                            <Th p={'7px'} fontWeight={'600'} fontFamily={'poppins'}>
                              User Name
                            </Th>
                            <Th p={'7px'} fontSize={'10px'}>
                              Plan Name
                            </Th>
                            <Th p={'7px'} fontSize={'10px'}>
                              With Pre-existing
                            </Th>
                            <Th p={'7px'} fontSize={'10px'}>
                              Adjustment
                            </Th>
                            <Th p={'7px'} fontSize={'10px'}>
                              Without Pre-existing
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                        {data?.listOfInsuredQutation?.map((ele, index) => (
                          <>
                          <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                                    <Td p={'2px'}>Travler {index+1}</Td>
                                    <Td p={'2px'}>{ele.planType}</Td>
                                    <Td p={'2px'} display={'flex'}>
                                        {/* <input
                                          type="radio"
                                          name={`costOption-${index}`}
                                          disabled={ele.costWithPreExisting===0}
                                          value={ele.costWithPreExisting}
                                          checked={selectedCostOption[index] === ele.costWithPreExisting}
                                          // onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                                        /> */}
                                        <Text pl={'10px'}>
                                          {Math.round(ele.costWithPreExisting * 100) / 100}
                                        </Text>
                                      </Td>
                                      <Td p={'2px'}>{adjustment}</Td>
                                      <Td p={'2px'}>
                                        <Flex>
                                        {/* <input
                                          type="radio"
                                          name={`costOption-${index}`}
                                          value={ele.costWithoutPreExisting}
                                          disabled={ele.costWithoutPreExisting===0}
                                          checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                                          onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                                          /> */}
                                        <Text pl={'10px'}>NA</Text>
                                          </Flex>
                                      </Td>
                                </Tr>
                            {/* <Tr key={index} fontSize={'12px'} p={'2px'} textAlign={'center'}>
                              <Td p={'2px'}>{ele.name}</Td>
                              <Td p={'2px'}>{ele.planType}</Td>
                              <Td p={'2px'} display={'flex'}>
                                <input
                                  type="radio"
                                  name={`costOption-${index}`}
                                  value={ele.costWithPreExisting}
                                  checked={selectedCostOption[index] === ele.costWithPreExisting}
                                  onChange={() => handleCostOptionChange(index, ele.costWithPreExisting,ele)}
                                />
                                <Text pl={'10px'}>
                                {Math.round(ele.costWithPreExisting  * 100) / 100}
                                </Text>
                              </Td>
                              <Td p={'2px'}>
                                <Flex>
                                <input
                                  type="radio"
                                  name={`costOption-${index}`}
                                  value={ele.costWithoutPreExisting}
                                  disabled
                                  checked={selectedCostOption[index] === ele.costWithoutPreExisting}
                                  onChange={() => handleCostOptionChange(index, ele.costWithoutPreExisting,ele)}
                                  />
                                <Text pl={'10px'}>
                                  Not available
                                </Text>
                                  </Flex>
                              </Td>
                            </Tr> */}
                            </>
                        ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                          
                  <div style={{ display: "flex", gap: "20px", marginTop: "40px" }}>
                    <Button onClick={handlePlanBackEvent} style={{ backgroundColor: "red", color: "white", padding: "4px 10px", fontFamily: "poppins",}}>
                      Previous
                    </Button>
                    <Button
                      onClick={handleToContactDetails}
                      style={{
                        backgroundColor: "rgb(36, 182, 183)",
                        color: "white",
                        padding: "4px 10px",
                        fontFamily: "poppins",
                      }}
                    >
                      Proceed
                    </Button>
                  </div>
                </div>
        )}

        {contactDetails === true &&(
            <Box w={['100%','100%','95%']} padding={['15px','5px','18px']} style={{ margin: "auto",boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)"}}>
            <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner*</Text>
              <Select background={'white'} size={'xs'} border={'1px solid #d7d7d7'} name="policyOwnerName" value={policyHolder.policyOwnerName} onChange={handlePolicyOwnerChange}>
                <option value="">Select Policy Owner</option>
                {addTravelers.map((traveler, index) => (
                  <option key={index} value={traveler.name}>
                    {traveler.name}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Text>Policy Owner Date Of Birth*</Text>
              <Input readOnly type='date' size='xs' name="policyOwnerDob" value={policyHolder.policyOwnerDob} onChange={handlePolicyOwnerChange} border={'1px solid #d7d7d7'} />            
            </Box>
            <Box>
              <Text>Policy Owner Email*</Text>
              <Input placeholder='Email' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerEmail" value={policyHolder.policyOwnerEmail} onChange={handlePolicyOwnerChange}/>    
              <Text color="red.500">{errors.policyOwnerEmail}</Text>        
            </Box>
            </Flex>
            <Flex flexDirection={['column','column','row']} gap={['10px','20px','40px']} fontSize={'14px'} pb={'20px'} mt={'20px'}>
            <Box>
              <Text>Policy Owner Postal Code*</Text>
              <Input placeholder='Postal code' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerPostalCode" value={policyHolder.policyOwnerPostalCode} onChange={handlePolicyOwnerChange}/>   
              <Text color="red.500">{errors.policyOwnerPostalCode}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner City*</Text>
              <Input placeholder='city' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerCity" value={policyHolder.policyOwnerCity} onChange={handlePolicyOwnerChange} />   
              <Text color="red.500">{errors.policyOwnerCity}</Text>         
            </Box>
            <Box>
              <Text>Policy Owner Phone*</Text>
              <Input placeholder='phone' size='xs' border={'1px solid #d7d7d7'} name="policyOwnerPhone" value={policyHolder.policyOwnerPhone} onChange={handlePolicyOwnerChange} />            
              <Text color="red.500">{errors.policyOwnerPhone}</Text>
            </Box>
            </Flex>
            <Box pb='30px'>
              <Text>Policy Owner Address*</Text>
              <Input placeholder='Address' size='xs' border={'1px solid #d7d7d7'}  name="policyOwnerAddress" value={policyHolder.policyOwnerAddress} onChange={handlePolicyOwnerChange} />     
              <Text color="red.500">{errors.policyOwnerAddress}</Text>       
            </Box>
            <Flex gap='40px'>
            <button onClick={handleContactDetailsBackEvent} style={{ backgroundColor: "red", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Previous</button>
            <button onClick={handleGetQuote} style={{ backgroundColor: "#24b6b7", color: "#fff", padding: "6px 12px", fontWeight: 400, cursor: "pointer", borderRadius: "4px", width: "fit-content", alignSelf: "flex-end", }} > Get Quote</button>
            </Flex></Box>
        )}
        <Description/>
      </Box>
      <VTCDetailsModal isOpen={isVtcModalOpen} onClose={handleVtcModalClose} onSubmit={handleModalSubmit} />
    </Box>
  );
}

export default TripDetails;