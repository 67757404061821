import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, TabPanels, Tabs,Box, useToast,Flex,Input,Textarea, Button,Text} from "@chakra-ui/react";
import SuperVisa from "../../SuperVisa";
import EditQuote from "./EditQuote";
import Api from "../../../dependencies/utils/Api";
import EditSuperVisaQuote from "./EditSuperVisaQuote";
import { useDispatch } from "react-redux";
import { advisorSingleGetQuotes } from "../../Advisor/Dependencies/action";
import { buttonBackgroundColor, buttonColor, primaryBackgroundColor } from "../../../Utils/UniversalColor";
import { ADVISOR_SAVE_POLICY } from "../../../constants/ApplicationUrl";

const CustomerEditQuote = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0)
  const [dataFromChild, setDataFromChild] = useState('');
  const toast=useToast()
  const navigate=useNavigate()
  const [cardDetails,setCardDetails]=useState({
    cardHolderFirstName:'',
    cardHolderLastName:'',
    idQuote:'',
    quotationNo:'',
    cardHolderEmail:'',
    cardHolderAddress:'',
    cardHolderPostal:'',
    cardHolderPhone:'',
    cardNumber:'',
    expiryDate:'',
    cvv:'',
    amount:''
  })

  const [errors, setErrors] = useState({
    cardHolderFirstName: '',
    cardHolderLastName: '',
    cardHolderEmail: '',
    cardHolderPostal: '',
    cardHolderPhone: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    amount: ''
  });

  const validateInput = (name, value) => {
    let errorMessage = '';
    switch (name) {
      case 'cardHolderFirstName':
      case 'cardHolderLastName':
        if (!value.trim()) errorMessage = `${name} is required`;
        break;
      case 'cardHolderEmail':
        if (!value.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) errorMessage = 'Valid email is required';
        break;
      case 'cardHolderPostal':
        if (!value.trim() || !/^\d{5}$/.test(value)) errorMessage = 'Valid postal code is required';
        break;
      case 'cardHolderPhone':
        if (!value.trim() || !/^\d{10}$/.test(value)) errorMessage = 'Valid phone number is required';
        break;
      case 'cardNumber':
        if (name === 'cardNumber' && /^\d{16}$/.test(value)) {
          errorMessage = ''; // No error message for valid card number
        }
        break;
      case 'expiryDate':
        if (!value.trim()) errorMessage = 'Expiry date is required';
        break;
      case 'cvv':
        if (!value.trim() || !/^\d{3,4}$/.test(value)) errorMessage = 'Valid CVV is required';
        break;
      case 'amount':
        if (!value.trim() || isNaN(value)) errorMessage = 'Valid amount is required';
        break;
      default:
        break;
    }
    setErrors(prevErrors => ({ ...prevErrors, [name]: errorMessage }))
  };

    useEffect(() => {
      if (id) {
          dispatch(advisorSingleGetQuotes(id))
              .then(response => {
                  setData(response.data);
              })
              .catch(error => {
                  console.error('Error fetching data:', error);
              });
      }
  }, [dispatch, id]);

  const handleCardChanges =(e)=>{
    const { name, value } = e.target;
    setCardDetails({ ...cardDetails, [name]: value });
  }

  const sendDataToParent = (data) => {
    console.log('Received data from child:', data);
    setDataFromChild(data);
    setTabIndex(data.tab)
  };

  const handleSavePolicy = ()=>{
    const isValid = validateAllFields();
    if (!isValid) {
      return;
    }
    const updateData={
      ...cardDetails,
      idQuote:dataFromChild.data.idQuote ,
      quotationNo:dataFromChild.data.quotationNo ,
    }
    Api.POST(`${ADVISOR_SAVE_POLICY}`,updateData).then((res)=>{
      alert('Policy Created Successfully.')
      navigate('/customer-policies')
    })
  }

  const validateAllFields = () => {
    let isValid = true;
    const requiredFields = ["cardHolderFirstName", "cardHolderLastName", "cardHolderEmail", "cardHolderPostal", "cardHolderPhone", "cardNumber", "expiryDate", "cvv", "amount"];
    requiredFields.forEach(field => {
      if (cardDetails[field].trim() === '') {
        setErrors(prevErrors => ({ ...prevErrors, [field]: `${field} is required` }));
        isValid = false;
      }
    });
    return isValid;
  };

  const handleCardNumberChange = async (event) => {
    if (!event || !event.target) {
      return;
    }
    const { value } = event.target;
    setCardDetails({ ...cardDetails, cardNumber: value });
    validateInput("cardNumber", value);
    if (event.complete) {
      const cardElement = elements.getElement(CardElement);
      const { token } = await stripe.createToken(cardElement);
      const cardType = token.card.brand;
      console.log("Card Type:", cardType);
      setCardDetails({ ...cardDetails, cardType: cardType });
      const cardTypeDisplay = document.getElementById("cardTypeDisplay");
      if (cardTypeDisplay) {
        cardTypeDisplay.textContent = cardType.toUpperCase();
      }
      setErrors(prevErrors => ({ ...prevErrors, cardNumber: '' }));
    }
  };

return (
        <div>
          <SuperVisa />
          <div>
          <Tabs index={tabIndex} style={{ marginTop: "20px", display:'flex' ,width:'80%', margin:'auto' }} variant="unstyled">
            <Box width="20%">
            <TabList 
              style={{
                display: "flex",
                flexDirection: "column",
                border: "2px solid #088183",
                backgroundColor: primaryBackgroundColor,
                padding: "10px",
                paddingBottom: "10px",
                marginTop:'50px',
                height:'auto',
                color: "white",
                fontSize: "16px",
                fontWeight: "700",
              }}
            >
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Get a Quote
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Confirm & Pay
              </Tab>
              <Tab
                _selected={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  alignItems: "center",
                  borderRadius: "10px",
                  bg: "white",
                }}
              >
                Purchase Policy
              </Tab>
            </TabList>
            </Box>
    
            <TabPanels style={{ marginTop: "10px",width:'80%'}}>
              <TabPanel>
                <EditQuote id={id} sendDataToParent={sendDataToParent}/>
                {/* {data.product==="Supervisa"? <EditSuperVisaQuote id={id}/>:data.product==="VTC"?<EditQuote id={id}/>: <EditSuperVisaQuote id={id}/>} */}
              </TabPanel>
              <TabPanel>
              <Box>
                <Text fontSize={'12px'} fontFamily={'Open Sans,sans-serif'}><span style={{ color: 'red' }}>Note :</span>If you are using different Credit Card Make Sure to update Payor’s Card details including Name, Address, email, Cell No and Postal Code or else Card will be declined</Text>
                {/* <Text fontWeight={'600'} mt={'15px'} fontFamily={'poppins'}>Primary Card Details :</Text> */}

                <Flex w={'90%'} m={'auto'} mt={'20px'} gap={'30px'}>
                  <Box w={'99%'} border={'1px solid #d5d5d5'}>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}>
                      <Text>Customer Information</Text></Flex>
                    <Box p={'10px'}>
                      <Flex w={'90%'} m={'auto'} gap={'30px'} mt={'15px'} fontFamily={'Open Sans, sans-serif'}>
                        <Box w={'30%'}>
                          <Text fontSize={'12px'}>Card Holder First Name</Text>
                          <Input name='cardHolderFirstName' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderFirstName}</Text>
                        </Box>
                        <Box w={'30%'}>
                          <Text fontSize={'12px'}>Card Holder Last Name</Text>
                          <Input name='cardHolderLastName' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderLastName}</Text>
                        </Box>
                        <Box w={'30%'}>
                        <Text fontSize={'12px'}>Card Holder Email:</Text>
                        <Input name='cardHolderEmail' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                        <Text color="red" fontSize="xs">{errors.cardHolderEmail}</Text>
                      </Box>
                      </Flex>
                      <Flex w={'90%'} m={'auto'} gap={'30px'} mt={'25px'} fontFamily={'Open Sans, sans-serif'}>
                        <Box w={'30%'}>
                          <Text fontSize={'12px'}>Card Holder Postal</Text>
                          <Input name='cardHolderPostal' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderPostal}</Text>
                        </Box>
                        <Box w={'30%'}>
                          <Text fontSize={'12px'}>Card Holder Phone</Text>
                          <Input name='cardHolderPhone' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                          <Text color="red" fontSize="xs">{errors.cardHolderPhone}</Text>
                        </Box>
                        <Box w={'30%'}>
                        <Text fontSize={'12px'}>Card Holder Address</Text>
                        <Input name='cardHolderAddress' onChange={handleCardChanges} border='1px solid grey' size={'xs'} />
                      </Box>
                      </Flex>
                    </Box>
                    <Flex alignItems={'center'} p={'10px'} borderBottom={'1px solid #d5d5d5'}>
                      <Text>Payment Details</Text>
                    </Flex>
                    <Box w={'95%'} m={'auto'} mt={'10px'}>
                      <Text fontSize={'12px'}>Card Number</Text>
                      <Box border='1px solid grey' borderRadius='4px' p='10px'>
                        {/* <CardElement name="cardNumber" onChange={handleCardNumberChange} /> */}
                      </Box>
                      <Text color="red" fontSize="xs">{errors.cardNumber}</Text>
                      <Text id="cardTypeDisplay" fontSize="sm" mt="2">{cardDetails.cardType}</Text>
                    </Box>
                   
                    <Flex  pb={'7px'} alignItems={'center'} pl={'25px'} mt={'10px'} gap={'10px'}>
                      <input type="checkbox" />
                      <Text fontSize={'12px'}>By checking the box, I / We confirm that I/we have given permission to use my credit card for the specified amount</Text>
                    </Flex>
                  </Box>
                </Flex>
                <Flex w={'90%'} m={'auto'} p={'10px'} justifyContent={'space-between'} mt={'20px'} background={'teal'} color={'white'} borderRadius={'5px'}>
                  <Text>Final Payment</Text>
                  <Text>$20</Text>
                </Flex>
                <Flex justifyContent={'center'} gap={'30px'} mt={'20px'}>
                  <Button background={'teal'} color={'white'} onClick={handleSavePolicy}>Pay</Button>
                </Flex>
              </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          </div>
        </div>
      );
    }

export default CustomerEditQuote
