import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Grid, GridItem, Heading, Image, Text, useTheme } from '@chakra-ui/react';
import { primaryColor } from '../../Utils/UniversalColor';
import { useTranslation } from 'react-i18next';
import { BsBagPlus } from "react-icons/bs";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { AiFillSlackCircle } from "react-icons/ai";   
import { AiFillFund } from "react-icons/ai";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Hero from '../../assets/img/Hero.png'


const Home2 = () => {
    const {t}=useTranslation()
    const navigate=useNavigate()
    const theme=useTheme()
    useEffect(() => {
        AOS.init();
      }, [])

      console.log(theme,'themes')


const handleQuickQuoteNavigate =()=>{
    navigate('/quick-quote')
}

const handleSuperVisaNavigate =()=>{
    navigate('/supervisa-getQuote')
}

const handleVisitorNavigate = ()=>{
    navigate('/visitor-getquote')
}

const handleStudentNavigate = ()=>{
    navigate('/student-travel-getquote')
}


return (
    <Box pt={'60px'}>
        <Box display={['none','block','block']} backgroundImage={'https://demo.rstheme.com/wordpress/insurigo/wp-content/uploads/2023/10/bnr-h4-bg1.jpg'} backgroundRepeat={'no-repeat'} backgroundSize={'cover'} backgroundPosition={'bottom-right'} h={'90vh'} >
            {/* <Box backgroundImage={'https://images.prismic.io/tugo-com/34ebd3b589475e46239ea7abd3c6a3af1c546677_visitors-to-canada-insurance.jpg?auto=compress,format'} h={'80vh'}> */}
            <Flex direction={['column-reverse','column','row']} w={'90%'} m={'auto'} backgroundSize={'cover'} backgroundRepeat={'no-repeat'}> 
            <Box w={['95%','40%','40%']} mt={['20px','120px','120px']}>
                <Text color={'#2E3749'} fontWeight={'550'} fontSize={'32px'} fontFamily={'Spline Sans, sans-serif'}>{theme.content.homepage.title}</Text>
                <Text color={'#748289'} mt={'15px'} fontSize={'16px'}>{theme.content.homepage.description}</Text>
                <Link to={'/quick-quote'}>
                <Button data-aos='fade-right' p={'20px 35px 20px 35px'}  backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} fontSize={'16px'} mt={'20px'} fontFamily={'Rubik, sans-serif'} fontWeight={'500'} rightIcon={<ArrowForwardIcon />}>{t('Get Quick Quote')}</Button>
                </Link>
            </Box>
            <Flex justifyContent={'center'} w={['95%','50%','50%']} m={'auto'}>
                {/* <Image src={Hero}/> */}
            </Flex>
            </Flex>
        </Box>

         
        <Box backgroundImage={'https://bracketweb.com/insurwp/wp-content/uploads/2024/02/slider-8-bg.png'} backgroundRepeat={'no-repeat'} backgroundSize={'auto'} display={['block','none','none']} w={'90%'} m={'auto'}>
            <Text mt={'45px'} pb={'10px'} lineHeight={'46px'} fontWeight={'600'} color={'#2E3749'} fontFamily={'DM Sans, sans-serif'} fontSize={'34px'}>Insurance <br /> Relaxed with <br /> TravelMedicare</Text>
            <Text fontSize={'16px'} fontFamily={'poppins'} color={'#737092'}>Lorem ipsum dolor sit amet consectetur adipisicing elit. Similique incidunt, voluptatem maxime aperiam consequuntur, consectetur.</Text>
            <Flex gap={'20px'}>
            <Link to={'/advisorLogin'}>
            <Button p={'5px 45px'} size={'sm'} data-aos='fade-right' backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} mt={'30px'} fontFamily={'Spline Sans, sans-serif'} fontWeight={'500'} >{t('Advisor')}</Button></Link>
            <Link to={'/customerLogin'}>
            <Button p={'5px 45px'} size={'sm'} data-aos='fade-right' backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} mt={'30px'} fontFamily={'Spline Sans, sans-serif'} fontWeight={'500'}>{t('Customer')}</Button>
            </Link>
            </Flex>
        </Box>


        <Flex direction={['column','column','row']} w={['90%','90%','80%']} m={'auto'} pb={'40px'} gap={['60px','90px','90px']} mt={['70px','60px','60px']}> 
            <Box w={['95%','40%','40%']} m={'auto'} mt={['20px','40px','40px']}>
                <Text pb={'10px'} fontWeight={'600'} color={'#2E3749'} fontFamily={'Spline Sans, sans-serif'} fontSize={'24px'}>{t('About Us')}</Text>
                <hr />
                <Text pt={'10px'} color={'rgb(103 116 113)'}>{theme.content.homepage.about}</Text>
                <Link to='/about-us'>
                <Button size={'sm'} data-aos='fade-right' backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} mt={'30px'} fontFamily={'Spline Sans, sans-serif'} fontWeight={'500'} rightIcon={<ArrowForwardIcon />}>{t('Read more')}</Button></Link>
            </Box>
            <Box display={['none','block','block']} w={['97%','52%','52%']}>
                <Flex gap={'50px'}>
                <Box onClick={handleQuickQuoteNavigate} data-aos='fade-down' w={'40%'} p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                    <Flex _hover={{backgroundColor:'#FFD5A1'}} background={'rgb(230 243 235)'} w={'45px'} p={'10px'} borderRadius={'5px'}>
                    <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                    </Flex>
                    <Box fontFamily={'poppins'}>
                    <Text _hover={{textDecoration:'underline'}} pt={'10px'} fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Quick Quote')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('customers trust us & have bought their insurance on TravelMedicare')}</Text>
                    </Box>
                </Box>
                <Box onClick={handleSuperVisaNavigate} data-aos='fade-down' w={'40%'}  p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                    <Flex  _hover={{backgroundColor:'#FFD5A1'}} background={'rgb(230 243 235)'} w={'45px'} p={'10px'} borderRadius={'5px'}>
                    <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                    </Flex>
                    <Box fontFamily={'poppins'}>
                    <Text pt={'10px'} fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Super Visa')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('Super Visa Insurance: CAD 100,000 coverage for 1 year, for ages 15 days to 90.')}</Text>
                    </Box>
                </Box>
                </Flex>
                <Flex data-aos='fade-up' gap={'50px'} mt={'30px'}>
                <Box onClick={handleVisitorNavigate} w={'40%'} p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                    <Flex _hover={{backgroundColor:'#FFD5A1'}} background={'rgb(230 243 235)'} w={'45px'} p={'10px'} borderRadius={'5px'}>
                    <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                    </Flex>
                    <Box fontFamily={'poppins'}>
                    <Text pt={'10px'} fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Visitor Insurance')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('Visitors Insurance: Up to 2-year coverage for ages 15 days to 90, with no cancellation fees')}</Text>
                    </Box>
                </Box>
                <Box onClick={handleStudentNavigate} w={'40%'} p={['0px','10px','10px']} _hover={{border:'1px solid grey'}} borderRadius={'10px'} cursor={'pointer'} backgroundColor={'white'}>
                    <Flex _hover={{backgroundColor:'#FFD5A1'}} background={'rgb(230 243 235)'} w={'45px'} p={'10px'} borderRadius={'5px'}>
                    <Image src='https://themedox.com/insucom/wp-content/uploads/2023/08/service-1.svg'/>
                    </Flex>
                    <Box fontFamily={'poppins'}>
                    <Text pt={'10px'} fontSize={'16px'} pb={'8px'} color={primaryColor} fontWeight={'600'}>{t('Student Insurance')}</Text>
                    <Text color={'#253858'} lineHeight={'21px'} fontSize={'14px'}>{t('Students Medical Insurance: $495/year for $2M coverage (Standard plan) for ages 15 days to 65.')}</Text>
                    </Box>
                </Box>
                </Flex>
            </Box>
        </Flex>



<Box display={['none','block','block']} backgroundImage={'https://themedox.com/insucom/wp-content/uploads/2023/08/service-area.png'} h={'auto'} mt={'50px'} backgroundSize={'cover'} backgroundRepeat={'no-repeat'} p={'35px'} pt={'70px'} pb={'70px'}>
    <Flex direction={['column-reverse','column-reverse','row']} w={['99%','85%','85%']} m={'auto'} gap={'90px'}>
        <Box w={['97%','43%','43%']}>
            <Grid data-aos='fade-right' templateColumns='repeat(3, 1fr)' templateRows='repeat(2, 1fr)' gap={5}>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#E1F8E5'} borderRadius={'50px'} p={'15px'}>
                            <BsBagPlus fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'} fontWeight={'700'} lineHeight={'24px'}>{t("Transparent")}<br /> {t('& Honest')}</Text>
                </GridItem>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#DAF6FF'} borderRadius={'50px'} p={'15px'}>
                            <AiFillFund fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{t("Your Safety")} <br />  Our Priority</Text>
                </GridItem>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#E5DEFF'} borderRadius={'50px'} p={'15px'}>
                            <BsBagPlus fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{t('Insurance ')} <br /> Simplified</Text>
                </GridItem>

                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#FFE9CF'} borderRadius={'50px'} p={'15px'}>
                            <BsBagPlus fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'} fontWeight={'700'} lineHeight={'24px'}>{t('Comprehensive ')}  <br /> {t('Coverage')}</Text>
                </GridItem>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#FFDAEC'} borderRadius={'50px'} p={'15px'}>
                            <BsBagPlus fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{t('Affordable ')} <br /> {t('& Flexible ')}</Text>
                </GridItem>
                <GridItem cursor={'pointer'} _hover={{boxShadow:'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset'}} background={'white'} borderRadius={'12px'} p={'27px 5px 27px 5px'} boxShadow={'0px 10px 60px 0px rgba(0, 0, 0, 0.08)'}>
                        <Flex justifyContent={'center'} mb={'20px'}>
                            <Box background={'#FFF0E4'} borderRadius={'50px'} p={'15px'}>
                            <BsBagPlus fontSize={'16px'} />
                            </Box>
                        </Flex>
                        <Text textAlign={'center'} fontWeight={'700'} lineHeight={'24px'} fontFamily={'Open Sans, sans-serif'} color={'#2E3749'} fontSize={'14px'}>{t('24/7 Protection')} <br />Anytime, Anywhere</Text>
                </GridItem>
            </Grid>
        </Box>


        <Box w={['95%','40%','40%']} >
            <Box>
                <Flex boxShadow='rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset' color={primaryColor} width={'fit-content'} p={' 5px 15px'} alignItems={'center'} fontSize={'13px'} fontWeight={'700'} background={'white'} justifyContent={'start'} gap={'7px'} borderRadius={'12px'}>
                <AiFillSlackCircle />
                    <Text fontFamily={'Open Sans,sans-serif'}>{t('OUR BENIFITS')}</Text>
                </Flex>
            </Box>
        <Text data-aos='fade-down' mt={'10px'} pb={'10px'} fontWeight={'600'} color={primaryColor} fontFamily={'Spline Sans, sans-serif'} fontSize={'28px'}>{theme.content.homepage.benefitsSectionHeading}</Text>
        <Text data-aos='fade-down' pt={'10px'} color={'rgb(103 116 113)'}>{theme.content.homepage.benefitsSectionSubHeading}</Text>
        <Link to={'/privacy-policy'}>
        <Button size={'sm'} data-aos='fade-left' rightIcon={<ArrowForwardIcon />} backgroundColor={theme.button.buttonBackgroundColor} color={theme.button.buttonColor} _hover={{backgroundColor:theme.button.buttonHoverBackgroundColor,color:theme.button.buttonHoverColor}} fontFamily={'Open Sans, sans-serif'} fontWeight={'500'} mt={'30px'} >{t('Read more')}</Button></Link>
        </Box>
    </Flex>
</Box>



<Box>
     <Box data-aos='fade-down' w={['89%','49%','49%']} m={'auto'} pt={['20px','90px','90px']} textAlign={'center'} lineHeight={'28px'}>
      <Heading fontSize={['22px','32px','32px']} fontFamily={'Montserrat'}>{t('Our Policies Are Based On')}</Heading>
      <Text pt={'15px'} fontSize={'16px'} fontFamily={'poppins'}>{t('Premiums for All')}</Text>
      </Box>
<Flex  direction={['column','column','row']} data-aos='fade-up' w={'80%'} m={'auto'} pt={'60px'} pb={'30px'} justifyContent={'space-around'} gap={['20px','20px','0px']}>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://www.freeiconspng.com/uploads/leadership-training-icon-1.png'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('The Plan Type')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Enhanced')} = $25000, $50000, $100000</Text>
      <Text pt={'5px'}>{t('Premium')} = $25000, $50000, $100000</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/free-vector/umbrella-with-circle-check-mark_78370-4180.jpg?t=st=1711446036~exp=1711449636~hmac=d4d0e7ed926b69d9c717bd77d27ca87964b03a2ccac6827236758cf1c0dc9a25&w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Coverage')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
      <Text pt={'5px'}>{t('Hospitalization, Physician Charges,')}</Text>
      <Text pt={'5px'}>{t('Diagnostic Services, Prescriptions')}</Text>
      <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://www.shutterstock.com/image-vector/age-vector-icon-600nw-304741742.jpg'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Age Limits')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>{t('Standard')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Enhanced')} = 15 Days to 90 Yrs</Text>
      <Text pt={'5px'}>{t('Premium')} = Less then 70 Years of age</Text>
      </Box>
  </Box>
</Flex>

<Flex direction={['column','column','row']} data-aos='fade-up' w={'80%'} m={'auto'} pt={'10px'} pb={'100px'} justifyContent={'space-around'} gap={['20px','20px','0px']}>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/premium-photo/photo-icons-alarm-clock-icon-green-technology-texture_873925-783472.jpg?w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Duration & waiting period')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>Within 30 days : 48 hours</Text>
      <Text pt={'5px'}>After 30 days : 48 hours</Text>
      <Text pt={'5px'}>365 days & Max 2 consecutive years</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/free-vector/check-mark-shield-circle_78370-4405.jpg?t=st=1711446282~exp=1711449882~hmac=75b7dff6a5abf3f247e6002c5658120fcc2831716551fb1b34a5ce33d8c60c6d&w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Pre-Existing Coverage')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'} fontSize={'14px'}>
      <Text pt={'5px'}>Ages 0-74 = 90 days stability is available till age</Text>
      <Text pt={'5px'}>Ages 75-84 = Pre-existing conditions are excluded in Plan 1</Text>
      <Text pt={'5px'}>{t('Private Duty Nurse, Dental, Vaccines')}</Text>
      </Box>
  </Box>
  <Box justifyContent={'center'} textAlign={'center'} fontSize={'14px'}>
      <Flex justifyContent={'center'}>
          <Image w={'50px'} src='https://img.freepik.com/premium-vector/av-green-letters-black-round-av-brand-name-initial-letter-icon_302321-318.jpg?w=740'/>
      </Flex>
      <Text fontSize={'18px'} mt={'15px'} color={primaryColor} fontWeight={'500'} fontFamily={'Assistant,sans-serif'}>{t('Exclusions')}</Text>  
      <Box fontFamily={'Assistant,sans-serif'}>
      <Text pt={'5px'}>Terminal Illness, Transplants</Text>
      <Text pt={'5px'}>Intoxication, Aircraft Operations</Text>
      <Text pt={'5px'}>High Risk Activites</Text>
      </Box>
  </Box>
</Flex>
</Box>

      
    </Box>
  )
}

export default Home2
