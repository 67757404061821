import { SET_LOGGED_IN, SET_USER } from "../../constants/actionConstants";

const initialState = {
  user: {},
  isLogin: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_LOGGED_IN:
      return { ...state, isLogin: action.payload };
    default:
      return state;
  }
};
