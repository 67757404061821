import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Document, Page, Text } from '@react-pdf/renderer';
import * as XLSX from 'xlsx';
import { Flex, Box, Select, Button } from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { FaDownload } from "react-icons/fa6";
import Sidebar from '../../Sidebar';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../dependencies/utils/Api';
import { adminPolicesList } from './dependencies/action';
import { useDispatch } from 'react-redux';
import { DOWNLOAD_POLICY_DOCUMENT } from '../../../../constants/ApplicationUrl';
import jsPDF from 'jspdf';


const AdminAllPolicies = () => {
  const pagination = true;
  const paginationPageSizeSelector = [10, 20, 50, 100];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = JSON.parse(localStorage.getItem('code_dev'))

  const downloadCellRenderer = (params) => {
    const handleDownload = () => {
      navigate(`/admin/download-documents/${params.data.policyNo}`);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <FaDownload color='grey' onClick={handleDownload} style={{ cursor: 'pointer' }} />
      </div>
    );
  };

  const viewTransactions = (params) => {
    const handleView = () => {
      navigate(`/admin/viewPolicyPaymentTranscation/${params.data.id}`);
    };
    const handleDeclineView = () => {
      navigate(`/admin/viewDeclinedTranscationList/${params.data.id}`);
    };
    return (
      <div className='view'>
        <Button onClick={handleView} _hover={{ color: 'white' }} mb={'3px'} background={'#4caf50'} color={'white'} size={'xs'}>View</Button>
        <Button onClick={handleDeclineView} _hover={{ color: 'white' }} background={'red'} color={'white'} size={'xs'}>Decline</Button>
      </div>
    );
  };

  const handleAction = (params) => {
    const handlePolicyDownload = async () => {
      try {
        const response = await fetch(`${DOWNLOAD_POLICY_DOCUMENT}/${params.data.policyNo}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        saveAs(blob, 'Policy.pdf');
      } catch (error) {
        console.error('Failed to download PDF:', error);
      }
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', direction: 'column', marginTop: '5px' }}>
        <Button onClick={handlePolicyDownload} background={'#9c27b0'} color={'white'} size={'xs'}>View</Button>
      </div>
    );
  };

  const handleEndorsement = (params) => {
    const handleActions = () => {
      navigate(`/admin/policyEndorsements/${params.data.id}`);
    };
    return (
      <div style={{ display: 'flex', justifyContent: 'center', direction: 'column', marginTop: '5px' }}>
        <Button onClick={handleActions} background={'#00bcd4'} color={'white'} size={'xs'}>View</Button>
      </div>
    );
  };

  const [colDefs, setColDefs] = useState([
    { field: "employeeName", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 280 },
    { field: "designation", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
    { field: "product", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 150 },
    { field: "policyNo", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 330 },
    { field: "dateOfIssue", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, width: 220 },
    { field: "customerName", wrapHeaderText: true, headerClass: 'header', cellClass: 'cell-class-name', autoHeight: true, wrapText: true, width: 300 },
    { field: "tripType", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "policyAmount", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "status", wrapHeaderText: true, headerClass: 'header', width: 175, cellClass: 'cell-class-name' },
    { field: "endorsements", wrapHeaderText: true, headerClass: 'header', width: 175, cellRenderer: handleEndorsement, cellClass: 'cell-class-endorsement', autoHeight: true },
    { field: "download", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: downloadCellRenderer, width: 140, wrapHeaderText: true },
    { field: "paymentTransactions", wrapHeaderText: true, autoHeaderHeight: true, headerClass: 'header', cellRenderer: viewTransactions, autoHeight: true },
    { field: "Action", headerClass: 'header', cellClass: 'cell-class', autoHeight: true, wrapText: true, cellRenderer: handleAction },
  ]);

  const [rowData, setRowData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [productFilter, setProductFilter] = useState('');
  const [roleFilter, setRoleFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  useEffect(() => {
    dispatch(adminPolicesList())
      .then((res) => {
        if (res.status === 200) {
          const formattedData = res.data.map((quote) => {
            let status;
            switch (quote.status) {
              case 1:
                status = 'Active';
                break;
              case 2:
                status = 'Pending Cancellation';
                break;
              case 3:
                status = 'Cancelled';
                break;
              case 4:
                status = 'Pending Void';
                break;
              case 5:
                status = 'Void';
                break;
              case 6:
                status = 'Policy Change Pending';
                break;
              case 7:
                status = 'Matured';
                break;
              default:
                status = 'Inactive';
            }
            let tripType;
            if (quote.quote.product === 'Vistors To Canada') {
              tripType = 'Visitor To Canada';
            } else if (quote.quote.product === 'Student To Canada') {
              tripType = 'Student To Canada';
            } else if (quote.quote.superVisa === 1) {
              tripType = quote.quote.paymentFrequency === 2 ? 'Super Visa - Monthly' : 'Super Visa';
            } else {
              tripType = 'Single Trip';
            }
            return {
              id: quote.idPolicy,
              employeeName: `${quote.quote.user.firstName} ${quote.quote.user.lastName}`,
              designation: quote.quote.user.roles.name,
              product: quote.quote.product === 'Studentvisa' ? 'STC' : 'VTC',
              policyNo: quote.policyNo,
              dateOfIssue: quote.quote.createdAt,
              tripType: tripType,
              customerName: quote.quote.policyHolderName,
              policyAmount: `$${quote.quote.quoteAmount}`,
              status: status,
            };
          });
          setRowData(formattedData);
          setFilteredData(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching admin profile data:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    let data = rowData;

    if (productFilter) {
      data = data.filter(item => item.product === productFilter);
    }

    if (roleFilter) {
      data = data.filter(item => item.designation === roleFilter);
    }

    if (userFilter) {
      data = data.filter(item => item.employeeName.includes(userFilter));
    }

    if (searchQuery) {
      data = data.filter(item => item.employeeName.toLowerCase().includes(searchQuery.toLowerCase()));
    }
    if (fromDate && toDate) {
      const from = new Date(fromDate);
      const to = new Date(toDate);
      data = data.filter(row => {
        const issueDate = new Date(row.dateOfIssue);
        return issueDate >= from && issueDate <= to;
      });
    }

    setFilteredData(data);
  }, [productFilter, roleFilter, userFilter, searchQuery, rowData, fromDate, toDate]);

  const handleFilterChange = (filterType, value) => {
    if (filterType === 'product') {
      setProductFilter(value);
    } else if (filterType === 'role') {
      setRoleFilter(value);
    } else if (filterType === 'user') {
      setUserFilter(value);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();

    doc.text("Quotations", 20, 20);

    // Generate table headers
    const headers = Object.keys(rowData[0]).map(header => header.toUpperCase());
    const data = rowData.map(obj => Object.values(obj));

    doc.autoTable({
      startY: 30,
      head: [headers],
      body: data
    });

    doc.save('quotations.pdf');
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Quotations');
    XLSX.writeFile(workbook, 'quotations.xlsx');
  };

  const downloadCSV = () => {
    const csvContent = [
      Object.keys(filteredData[0]).join(','), // Header row
      ...filteredData.map(obj => Object.values(obj).map(val => `"${val}"`).join(',')) // Data rows
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'quotations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Sidebar headingText={'All Policies'}>
      <div style={{ paddingTop: '20px', width: '90%', margin: 'auto', marginBottom: '10px' }}>
        <Flex gap={'20px'}>
          <Box>
            <label htmlFor="product-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Product</label> <br />
            <Select background={'white'}
              id="product-select"
              onChange={(e) => handleFilterChange('product', e.target.value)}
            >
              <option value="">All Products</option>
              <option value="VTC">VTC Product</option>
              <option value="STC">Student Travel</option>
            </Select>
          </Box>
          <Box>
            <label htmlFor="role-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select Role</label> <br />
            <Select background={'white'}
              id="role-select"
              onChange={(e) => handleFilterChange('role', e.target.value)}
            >
              <option value="">Select Roles</option>
              <option value="AGA">AGA</option>
              <option value="MGA">MGA</option>
              <option value="Advisor">Advisor</option>
              <option value="Customer">Customer</option>
            </Select>
          </Box>
          <Box>
            <label htmlFor="user-select" style={{ fontFamily: 'poppins', fontSize: '12px', color: '#999999' }}>Select User</label> <br />
            <Select background={'white'}
              id="user-select"
              onChange={(e) => handleFilterChange('user', e.target.value)}
            >
              <option value="">Select Users</option>
              {filteredData.map(employee => (
                <option key={employee.id} value={employee.employeeName}>{employee.employeeName}</option>
              ))}
            </Select>
          </Box>
          <Flex>
            <Box>
              <label htmlFor="from-date" style={{ fontFamily: 'poppins', fontSize: '14px' }}>From Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date" id="from-date" value={fromDate}
                onChange={(e) => setFromDate(e.target.value)} />
            </Box>
            <Box>
              <label htmlFor="to-date" style={{ fontFamily: 'poppins', fontSize: '14px' }} >To Date:</label> <br />
              <input style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginRight: '20px', marginLeft: '10px', fontSize: '12px' }} type="date"
                id="to-date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)} />
            </Box>
          </Flex>
        </Flex>
        <br />
      </div>

      <Box w={'95%'} m={'auto'} background={'white'} p={'10px'}>
        <Flex justifyContent={'space-between'} mt={'15px'}>
          <div style={{ display: 'flex', marginBottom: '10px', gap: "20px", fontSize: '12px', fontFamily: 'poppins' }}>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadPDF}>Pdf</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadExcel}>Excel</Button>
            <Button size='sm' background={'#eeeeee'} fontFamily={'Open Sans,sans-serif'} fontWeight={'500'} borderRadius='none' onClick={downloadCSV}>CSV</Button>
          </div>
          <div>
            <label htmlFor="search">Search:</label>
            <input
              style={{ border: '1px solid grey', padding: '3px', borderRadius: '5px', marginLeft: '10px' }}
              type="text"
              id="search"
              placeholder="Search by name"
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </Flex>
        <div
          className="ag-theme-quartz"
          style={{ width: '100%', maxWidth: '100vw', overflow: 'hidden' }}
        >
          <AgGridReact
            rowData={filteredData}
            columnDefs={colDefs}
            domLayout='autoHeight'
            width='100%'
            paginationPageSizeSelector={paginationPageSizeSelector}
            pagination={pagination}
            paginationPageSize={10}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
          />
        </div>
      </Box>
    </Sidebar>
  );
};

export default AdminAllPolicies;
